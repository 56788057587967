import { Dispatch } from 'react';
import {
  create,
  createFailure,
  createSuccess,
  getReferralsFailure,
  getReferrals,
  getReferralsSuccess,
  getBloodId,
  getBloodIdSuccess,
  getBloodIdFailure, getValidateAmbulatoryCardSuccess, getValidateAmbulatoryCardFailure, getValidateAmbulatoryCard, createUlidSuccess,
} from '../';
import { Filter, Referral } from '../model';
import axiosInstance from '../../../api/axiosInstance';
import { resetPatient } from '../../patient';
import { setSelectedServiceUser } from '../../serviceUsers';

let lastQuery = '';
let lastData:any;
let timeoutID:any;

export const fetchReferrals = (access: string, params?: Filter) => async (dispatch: Dispatch<unknown>) => {

  let { ulid, searchQuery, nextPage, pageSize, tab, dateCreatedGte, 
    dateCreatedLte, status, cancelled, priority, type, organization } = params ?? {};
  const paramsList: string[] = [];
  const safetyParamsList: string[] = [];

  if (ulid) {
    paramsList.push(`patient__ulid=${ulid}`);
    safetyParamsList.push(`patient__ulid=${ulid}`);
  }
  if (searchQuery) {
    let searchQueryData = searchQuery.split('=')?.[1];
    if (searchQueryData) {
      paramsList.push(searchQuery)
      safetyParamsList.push(searchQuery);
    };
  }
  if (nextPage) {
    paramsList.push(`page=${nextPage}`);
    safetyParamsList.push(`page=1`);
  }
  if (pageSize) {
    paramsList.push(`page_size=${pageSize}`);
    safetyParamsList.push(`page_size=${pageSize}`);
  }
  if (tab && !searchQuery) {
    paramsList.push(`tab=${tab}`);
    safetyParamsList.push(`tab=${tab}`);
  }
  if (dateCreatedGte) {
    paramsList.push(`date_created_gte=${dateCreatedGte}`);
    safetyParamsList.push(`date_created_gte=${dateCreatedGte}`);
  }
  if (dateCreatedLte) {
    paramsList.push(`date_created_lte=${dateCreatedLte}`);
    safetyParamsList.push(`date_created_lte=${dateCreatedLte}`);
  }
  if (status) {
    paramsList.push(`status=${status}`);
    safetyParamsList.push(`status=${status}`);
  }
  if (cancelled === 'True') {
    paramsList.push(`cancelled=${cancelled}`);
    safetyParamsList.push(`cancelled=${cancelled}`);
  }
  if (priority) {
    paramsList.push(`priority=${priority}`);
    safetyParamsList.push(`priority=${priority}`);
  }
  if (type) {
    paramsList.push(`type=${type}`);
    safetyParamsList.push(`type=${type}`);
  }
  if (organization) {
    paramsList.push(`organization=${organization}`);
    safetyParamsList.push(`organization=${organization}`);
  }

    //check for dublicate requests
    if(lastQuery !== `registry/referral/list/?${paramsList.join('&')}`){
      lastQuery = `registry/referral/list/?${paramsList.join('&')}`
      lastData = {};
      !!timeoutID && clearTimeout(timeoutID)
    } else {
      timeoutID =  setTimeout(() => {
        lastQuery = '';
        lastData = {};
      },5000)
      if(lastData && lastData?.data){
        dispatch(getReferralsSuccess(lastData.data))
      };
      return
    } 
  
  
  async function getFilteredListByParams(params: string[]) {
    lastData = await axiosInstance(access).get(`registry/referral/list/?${params.join('&')}`);
    return lastData;
  }

  try {
    dispatch(getReferrals());
    lastData = await getFilteredListByParams(paramsList);
    dispatch(getReferralsSuccess(lastData.data));
  } catch (err: any) {
    try {
      lastData = await getFilteredListByParams(safetyParamsList);
      dispatch(getReferralsSuccess(lastData.data));
    } catch (error: any) {
      dispatch(getReferralsFailure(err?.response?.data));
    }
  }
};

export const createReferral = (ulid: string, access: string, data: Referral) => async (dispatch: Dispatch<unknown>) => {
  dispatch(create());

  try {
    const postData = {
      ...data,
      referralCreator: ulid,
    }

    const response = await axiosInstance(access).post('registry/referral/create/', postData);

    dispatch(createSuccess(response.data.referralId));
    dispatch(createUlidSuccess(response.data.ulid));
    dispatch(resetPatient());
    dispatch(setSelectedServiceUser(null))
  } catch (err: any) {
    dispatch(createFailure(err?.response?.data));
  }
};

export const fetchBloodId = (access: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getBloodId());

  try {
    const response = await axiosInstance(access).get('/bio/blood/latest/');

    dispatch(getBloodIdSuccess(response.data));
  } catch (err: any) {
    dispatch(getBloodIdFailure(err?.response?.data));
  }
};

export const validateAmbulatoryCard = (access: string, postDate: any) => async (dispatch: Dispatch<unknown>) => {
  dispatch((getValidateAmbulatoryCard()));

  try {
    const response = await axiosInstance(access).post('/registry/patient/validate/', postDate);

    dispatch(getValidateAmbulatoryCardSuccess(response.data));
  } catch (err: any) {
    dispatch(getValidateAmbulatoryCardFailure(err?.response?.data))
  }
}