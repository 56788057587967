import { TDiv, TEditableCell } from "../styled";
import BioMaterialEditModal from "../TooltipData/BioMaterialEditModal";
import {FC} from "react";

type EditableTableCellProps = {
    block?: any,
    slide?: any,
    modalCellId: string | null,
    setModalCellId: any,
    cellIdBlock?: string,
    cellIdSlide?: string,
    isEditPageActive: boolean,
    handleAddNewNumber: (type: string, data: any) => void,
    newBioMatNumbers: any,
    color: string,
    rightOfForm?: number,
    transform?: number,
    isError?: boolean,
}
const EditableTableCell: FC<EditableTableCellProps> = (props) => {
    const { block, slide, isEditPageActive, handleAddNewNumber, newBioMatNumbers, modalCellId, setModalCellId, cellIdBlock, cellIdSlide, color, rightOfForm, transform, isError} = props;


    if(!isEditPageActive) return <></>;

    let isEmpty = !block && !slide;

    if(isEditPageActive && isEmpty) return <TEditableCell className={color}></TEditableCell>;

    let isBlock = !!block;
    let ID = isBlock ? block?.block : slide?.slide;

    let openBlock = cellIdBlock === modalCellId;
    let openSlide = cellIdSlide === modalCellId;

    let savedData = isBlock ? newBioMatNumbers.block_cyphers?.[ID] : newBioMatNumbers.slide_cyphers?.[ID] ?? false;

    return(
        <TEditableCell className={color} id={isBlock ? cellIdBlock : cellIdSlide}>
            <BioMaterialEditModal
                block={block ?? false}
                slide={slide ?? false}
                savedData={savedData}
                onAddNewNumber={handleAddNewNumber}
                isOpenBlock={openBlock}
                isOpenSlide={openSlide}
                isOpenModal={!!modalCellId}
                setModalCellId={setModalCellId}
                rightOfForm={rightOfForm}
                transform={transform}
            />
            <TDiv error={isError} id={isBlock ? cellIdBlock : cellIdSlide}       
                  onClick={() => setModalCellId(isBlock ? cellIdBlock : cellIdSlide)}
                  isOpenBlock={openBlock}
                  isOpenSlide={openSlide}>
                {savedData ? savedData?.code : '--'}
            </TDiv>
        </TEditableCell>
    );
};

export default EditableTableCell;