import { FC, useCallback, useLayoutEffect, useMemo, useState } from "react";
import DoubleSelect from "../../../../components/shared/DoubleSelect";
// import { ReferralMarker } from "../../../../store/markers/model";
// import { loadLocalStorageData, removeItemLocalStorage, saveLocalStorageData } from "../../../../utils/localStorageHelpers";
import { TCneckboxWr, TEditButton, TRowWr, TSectionTitle, TTableWrapper, TWrapper } from "../styled";
import useDisabledSave from "./useDisabledSave";
import Checkbox from "../../../../components/shared/Checkbox";
import { IConclusionData } from "../../../../store/molProConclusion/model";
import { useDispatch, useSelector } from "react-redux";
import { getTokens } from "../../../../store/auth/selectors";
import { postOverWriteConclusionModules } from "../../../../store/molProConclusion/thunkActions";
import Textarea from "../../../../components/shared/Textarea";
import { useForm } from "react-hook-form";

interface IComponentProps {
  defaultData: IConclusionData | null;
  stepComplite: boolean;
  hasPermit: boolean;
  referralID: string;
  [index: string]: any;
}

export interface IInputItems {
  value: string;
  label: string;
}
export interface IOptionData {
  firstColumn: IInputItems[];
  secondColumn: IInputItems[];
}

export interface IAgent {
  markers: string[];
  agents: string[];
  [index: string]: string[];
}

export interface IModuleSensitivity {
  conclusion: number;
  type: string;
  agents: IAgent[];
  sensitivityAgents?: IAgent[];
  [index: string]: string | number | IAgent[] | undefined;
}


const sensHeaders = ['Протестированный маркер', 'Агент'];

const PotentialSensitivity: FC<IComponentProps> = ({ stepComplite, defaultData, hasPermit, referralID }) => {
  const { register, handleSubmit, formState: { errors }, setValue, getValues, clearErrors, trigger, setError } = useForm({ mode: "onSubmit" });
  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [noSpecify, setNoSpecify] = useState<boolean>(false);
  const [isFieldsError, setFieldsError] = useState<boolean>(false);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const [positiveAgents, setPositiveAgents] = useState<IOptionData[]>();
  const [negativeAgents, setNegativeAgents] = useState<IOptionData[]>();

  const markersData = useMemo(() => {
    let data: IOptionData[] = [];
    if (!!defaultData?.moduleSensitivity?.options && defaultData?.moduleSensitivity?.options?.length) {
      data = [...defaultData?.moduleSensitivity?.options]
    }

    return data;
  }, [defaultData]);

  const positiveSense = useCallback(async (positiveAgents: IOptionData[]) => {
    if (!positiveAgents?.length) return;
    clearErrors();
    return setPositiveAgents(positiveAgents);
  }, [setPositiveAgents,clearErrors]);

  const negativeSense = useCallback(async (negativeData: IOptionData[]) => {
    if (!negativeData?.length) return;
    clearErrors();
    return setNegativeAgents(negativeData);
  }, [setNegativeAgents,clearErrors]);

  const moduleSensitivity = useCallback(async () => {
    return { restoreDefaultData: { positiveAgents, negativeAgents, comments:getValues('comments') } }
  }, [positiveAgents, negativeAgents, getValues]);

  const { isFieldsNotFilled } = useDisabledSave(positiveAgents, negativeAgents);

  const restoreDefaults = useCallback(async () => {
    if (defaultData?.moduleSensitivity?.restoreDefaultData) {
      let { negativeAgents, positiveAgents, comments } = { ...defaultData?.moduleSensitivity?.restoreDefaultData }
      setPositiveAgents(positiveAgents);
      setNegativeAgents(negativeAgents);
      setValue('comments',comments)
    }
    setResetFormKey(Date.now())

  }, [defaultData?.moduleSensitivity?.restoreDefaultData, setPositiveAgents,
    setNegativeAgents, setResetFormKey,setValue]);

  const [isPositiveAgents, isNegativeAgents] = useMemo(() => {
    let data = defaultData?.moduleSensitivity?.restoreDefaultData
    let isPositiveAgents = !!data?.positiveAgents?.flatMap((colums: IOptionData) => colums.secondColumn)?.filter((option: IInputItems) => option?.label !== 'empty')?.length;
    let isNegativeAgents = !!data?.negativeAgents?.flatMap((colums: IOptionData) => colums.secondColumn)?.filter((option: IInputItems) => option?.label !== 'empty')?.length;
    if(!stepComplite) return [true,true];
      return [isPositiveAgents, isNegativeAgents]
  }, [defaultData, stepComplite]);

  const [isEmptyPositiveAgentsData,isEmptyNegativeAgentsData] = useMemo(() => {

    const isEmptyPAData = !positiveAgents?.filter((data: any) => {
      let firstColumn = data?.firstColumn?.every((fc: { value: string; label: string }) => fc.label !== 'empty');
      let secondColumn = data?.secondColumn?.every((sc: { value: string; label: string }) => sc.label !== 'empty');
      return firstColumn || secondColumn;
    })?.length;

    const isEmptyNAData = !negativeAgents?.filter((data: any) => {
      let firstColumn = data?.firstColumn?.every((fc: { value: string; label: string }) => fc?.label !== 'empty');
      let secondColumn = data?.secondColumn?.every((sc: { value: string; label: string }) => sc?.label !== 'empty');
      return firstColumn || secondColumn;
    })?.length;
    return [isEmptyPAData,isEmptyNAData]
  },[negativeAgents,positiveAgents]);

  const handleSaveSens = useCallback(async (formData?: any,isNoSpecify?: boolean) => {
    let resultValidate = await trigger(undefined,{ shouldFocus: false });
    if(!resultValidate) return;

    const dataForSave: { [index: string]: any; } = { ...defaultData ?? {} };
    let sensitivityData = await moduleSensitivity();
    let { negativeAgents, positiveAgents } = sensitivityData?.restoreDefaultData ?? {};
    sensitivityData.restoreDefaultData.negativeAgents = negativeAgents?.filter((data: any) => {
      let firstColumn = data?.firstColumn?.every((fc: { value: string; label: string }) => fc?.label !== 'empty');
      let secondColumn = data?.secondColumn?.every((sc: { value: string; label: string }) => sc?.label !== 'empty');
      return firstColumn || secondColumn;
    })
    sensitivityData.restoreDefaultData.positiveAgents = positiveAgents?.filter((data: any) => {
      let firstColumn = data?.firstColumn?.every((fc: { value: string; label: string }) => fc?.label !== 'empty');
      let secondColumn = data?.secondColumn?.every((sc: { value: string; label: string }) => sc?.label !== 'empty');
      return firstColumn || secondColumn;
    })

    const { comments } = sensitivityData?.restoreDefaultData || formData;
    
    if(!!(isEmptyPositiveAgentsData && isEmptyNegativeAgentsData) && !comments.trim()?.length && !(noSpecify || isNoSpecify)){
       setError('comments', { type: 'custom', message: 'Укажите причину в комментарии' },{ shouldFocus: false });
       return;
    };

    if (stepComplite || isFieldsNotFilled) {
      setFieldsError(true);
      setTimeout(() => setFieldsError(false), 1000);
    }
    if (noSpecify || isNoSpecify) {
      dataForSave['moduleSensitivity'] = { noSpecify: true, options: markersData };
    } else {
      dataForSave['moduleSensitivity'] = { ...sensitivityData, noSpecify: false, options: markersData };
    }

    if (tokens?.access){
       dispatch(postOverWriteConclusionModules(tokens?.access, referralID, dataForSave));
      };
    
    setEdit(false);
  }, [moduleSensitivity, referralID, stepComplite, isFieldsNotFilled, setFieldsError,setError,trigger,
    noSpecify, defaultData, tokens, dispatch, markersData,isEmptyPositiveAgentsData,isEmptyNegativeAgentsData]);


  useLayoutEffect(() => {
    if (defaultData?.moduleSensitivity?.noSpecify){
      setNoSpecify(true)
      setValue('comments','')
      clearErrors();
    };
    if (defaultData?.moduleSensitivity?.restoreDefaultData) restoreDefaults();
    return () => {
      setValue('comments','')
      clearErrors();
      setResetFormKey(Date.now());
    }
  }, [defaultData?.moduleSensitivity, restoreDefaults, setNoSpecify,setValue,clearErrors]);

  const noSpecifyHandler = useCallback(async () => {
    if (!noSpecify) {
      await handleSaveSens(undefined, true);
      setNoSpecify(true);
      clearErrors();
      setEdit(false)
      setValue('comments','')
      return setResetFormKey(Date.now());
    }
    setNoSpecify(false);
    return setResetFormKey(Date.now());
  }, [noSpecify, handleSaveSens, setResetFormKey,clearErrors,setEdit,setValue]);

  //handle edit tab button
  const handleEditPage = useCallback(async () => {
    if (stepComplite || !hasPermit) return;
    if (isEdit) {
      restoreDefaults();
      clearErrors()
      setResetFormKey(Date.now());
      return setEdit(false);
    }
    setResetFormKey(Date.now());
    setEdit(true);
  }, [isEdit, setEdit, setResetFormKey, stepComplite, hasPermit, restoreDefaults,clearErrors]);



  const isShowComments = useMemo(() => {
    if(!stepComplite) return true;
    let comments = defaultData?.moduleSensitivity?.restoreDefaultData?.comments
    if(comments) return !!(comments?.trim());
    return false;

  },[defaultData,stepComplite]);


  return (
    <>
      {!stepComplite && <TCneckboxWr>
        <Checkbox smallSize label='Не указывать' checked={!!noSpecify} onChange={() => hasPermit ? noSpecifyHandler() : null} />
      </TCneckboxWr>}
      <TTableWrapper key={resertFormKey} onSubmit={handleSubmit(data => handleSaveSens(data))}>
      <TRowWr direction="flex-end" >
        {!stepComplite && hasPermit && <TEditButton type="button" onClick={handleEditPage} active={!isEdit} disabled={stepComplite || !!noSpecify}>
          {!isEdit ? 'Редактировать' : 'Отменить'}
        </TEditButton>}
        {isEdit && !stepComplite &&
          <TEditButton type="submit" disabled={!isEdit} active={isEdit}>
            Сохранить
          </TEditButton>
        }
      </TRowWr>
      </TTableWrapper>

      {isPositiveAgents && (
        <>
          <TSectionTitle>
            Обнаружена потенциальная чувствительность к следующим <br /> лекарственным средствам
          </TSectionTitle>
          <TWrapper>
            <DoubleSelect
              key={resertFormKey}
              isEdit={isEdit}
              isError={isFieldsError}
              tableHeader={sensHeaders}
              optionData={markersData}
              calbackData={positiveSense}
              defaultData={noSpecify ? [] : defaultData?.moduleSensitivity?.restoreDefaultData?.positiveAgents ?? []}
              defaultPlaceholders={['Выберите маркеры', 'Выберите лекарственные средства']}
              firstColumnTextColor
            />
          </TWrapper>
        </>
      )}

      {isNegativeAgents && (
        <>
          <TSectionTitle>
            Не обнаружена потенциальная чувствительность к следующим <br /> лекарственным средствам
          </TSectionTitle>
          <TWrapper>
            <DoubleSelect
              key={resertFormKey}
              isEdit={isEdit}
              isError={isFieldsError}
              tableHeader={sensHeaders}
              optionData={markersData}
              calbackData={negativeSense}
              defaultData={noSpecify ? [] : defaultData?.moduleSensitivity?.restoreDefaultData?.negativeAgents ?? []}
              defaultPlaceholders={['Выберите маркеры', 'Выберите лекарственные средства']}
              firstColumnTextColor
            />
          </TWrapper>
        </>
      )}
      {/* {required: (isEmptyPositiveAgentsData && isEmptyNegativeAgentsData)} */}
      {isShowComments && <Textarea
          key={`comments_${resertFormKey}`}
          {...register('comments')}
          label='Примечание'
          placeholder={'Комментарий'}
          onChange={({ target }) => {
            setValue('comments', target.value);
            if(target?.value?.trim()) clearErrors()
          }}
          disabled={!isEdit}
          readOnly={!isEdit}
          className='nothovered'
          maxLength={300}
          bordered
          error={!!errors?.comments}
          defaultValue={getValues('comments')}
        />}
    </>)
}

export default PotentialSensitivity;