import { FC, useMemo } from "react";
import { AnalysisExperiment, Experiment } from "../../../../../store/analysis/model";

import { TExpWR, THeaderTable, THeaderTableRow, THeaderTableRowContent, THeaderTableRowTitle, TLabTable } from "../styled";
import { useSelector } from "react-redux";
import { checkPermitViewExperement } from "../../../../../store/auth/selectors";

interface IAdvisor {
  advisorUlid: string
  fullName: string
  specialization: string
}
interface IComponentProps {
  experements: AnalysisExperiment[]
  blueprintId: number
  referralID: string;
  executorMap: any;
  advisors?: IAdvisor[];
  goToExperement: (
    experimentID: number,
    experimentMethod: string,
    experimentMarker: string) => void;
  [index: string]: any;
}
const headers: string[] = ['Маркер', 'Референсная последовательность', 'Патогенный вариант/ детектируемое нарушение', 'Результат исследования'];

export const Pcr: FC<IComponentProps> = ({ advisors = [], blueprintId, experements, executorMap, goToExperement }) => {
  //Permissions
  const ableToViewExperiment = useSelector(checkPermitViewExperement);
  //Formeted experimen number
  const experimentNumber = useMemo(() => blueprintId?.toString()?.padStart(6, "0")?.replace(/(?=(?:.{3})*$)/g, ' '),[blueprintId]);
  return <>
    {experements?.map(exp => (
      <TExpWR key={(exp?.id + blueprintId) + 'experement'}>
        <THeaderTable>
          <THeaderTableRow>
            <THeaderTableRowTitle>Исследование: </THeaderTableRowTitle>
            {ableToViewExperiment ? <THeaderTableRowContent className="link" onClick={() => goToExperement(blueprintId, 'pcr', '')}>{experimentNumber}</THeaderTableRowContent> : <div>{experimentNumber}</div>}
          </THeaderTableRow>
          <THeaderTableRow>
            <THeaderTableRowTitle>Исполнитель: </THeaderTableRowTitle>
            <THeaderTableRowContent>{`${executorMap?.[exp?.executor]?.fullName}, ${executorMap?.[exp?.executor]?.title}`}</THeaderTableRowContent>
          </THeaderTableRow>
          {!!(exp?.labAssistant || advisors?.length) && <THeaderTableRow>
            <THeaderTableRowTitle>Другие: </THeaderTableRowTitle>
            <THeaderTableRowContent>
              {exp?.labAssistant && <div>{`${executorMap?.[(exp?.labAssistant ?? '')]?.fullName}, ${executorMap?.[(exp?.labAssistant ?? '')]?.title}`}</div>}
              {advisors?.map((adv: IAdvisor) => <div key={adv?.advisorUlid}>{adv?.fullName}, {adv?.specialization}</div>)}</THeaderTableRowContent>
          </THeaderTableRow>}
        </THeaderTable>
        <TLabTable>
          <tbody>
            <tr>
              <td colSpan={2} className="head violet topRadiusL">Аналитическая чувствительность метода, %</td>
              <td colSpan={2} className="head topRadiusR">{exp?.analyticalSensitivity}</td>
            </tr>
          </tbody>
          <tbody>
            <tr className="borderTop violet">
              {headers?.map((header: string) => <td key={header} className="head">{header}</td>)}
            </tr>
          </tbody>
          <tbody>
            {exp?.experimentRuns?.map((data: Experiment, index: number, allExperements: Experiment[]) => {
              let isDetectOtherGene = allExperements?.[index - 1]?.genePcr !== data.genePcr;
              let rowSpanValue = isDetectOtherGene ? allExperements?.filter((exp: Experiment) => exp?.genePcr === data.genePcr)?.length : allExperements?.length;
              return <tr key={index + 'mutationPcr'}>
                {(!index || isDetectOtherGene) && <td rowSpan={rowSpanValue}>{data.genePcr}</td>}
                {(!index || isDetectOtherGene) && <td rowSpan={rowSpanValue} className="borderRight">{data.reference}</td>}
                <td>{data.mutationPcr}</td>
                <td>{data.resultPcr}</td>
              </tr>
            })}
          </tbody>
          <tbody>
            <tr>
              <td className="head violet" colSpan={4}>
                Клинико-лабораторное заключение
              </td>
            </tr>
            {!!exp?.conclusion?.length && <tr>
              <td className={exp?.notes ? "" : "bottomRadiusR"} colSpan={4}>
                {exp?.conclusion?.split('\n\r')?.map(
                  (text: string, index: number) => <div className="capitalize" key={'conclusion' + index}>{text}.</div>)}
              </td>
            </tr>}
            {!!exp?.notes && (
              <tr>
                <td className="bottomRadiusR" colSpan={4}>
                  {exp?.notes}
                </td>
              </tr>
            )}

          </tbody>
        </TLabTable>
      </TExpWR>)) ?? null
    }
  </>
}

export default Pcr;
