import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';
import { useParams } from "react-router-dom";
import Loader from "../../../components/shared/Loader";
import { FLOW_STEPS_STATUSES } from "../../../fixtures/StepsMolecularProfilingPage";
import { getProfile, getTokens, getUserUlid } from "../../../store/auth/selectors";
import { IBiologicalMaterial, IBlockCypher, IMorphologyCreate, IStepReferral, ISubBlock, ISubBlockCreate, ISubBlockSendUpdate, ISubBlockUpdated } from "../../../store/molecularProfiling/model";
import { getServiceUsers } from '../../../store/serviceUsers/selectors';
import { fetchServiceUsers } from "../../../store/serviceUsers/thunkActions";
import GlassSelector from "./GlassSelector";
import MorphTable from "./MorphTable";
import { TDateOfCompletion, TEditButton, TMorphologicalCharacteristicWrapper, TRowWr } from "./styled";
import SubBlockSelector from "./SubBlockSelector";
import { createNextStep, getMolProCharacteristicSavedData, postMolProCharacteristicSavedData, postMorphologicalCharacteristicCreate, 
  postNewBioMaterialSubBlocksCreate, postNewBioMaterialSubBlocksUpdate } from "../../../store/molecularProfiling/thunkActions";
import { getCreatedSubBlocks, getMolecularProfilingErrors, getMolecularProfilingLoading, readSavedMolProCharacteristic } from "../../../store/molecularProfiling/selectors";
import { loadLocalStorageData, removeItemLocalStorage, saveLocalStorageData } from "../../../utils/localStorageHelpers";
import { addAlert } from "../../../store/notifyAlerts/thunkActions";
import { AlertTypes } from "../../../store/notifyAlerts/model";
import { resetMolecularProfiling, resetSavedMolProCharacteristic } from "../../../store/molecularProfiling";

interface IComponentProps {
  data?: any;
  [index: string]: any;
}

export interface IMorphOptionsList {
  value: number;
  label: string;
  startCode: string;
  endCode?: any;
  year: number;
  cancerousCellPercentage: number | null;
  priority: number;
  quality: string;
  disabled?: boolean;
  review?: boolean;
  originBlkockData?: any
  [key: string | number]: any;
};

export interface ISelectedBlockOption {
  value: string;
  label: string;
  block: number;
  originBlkockData: any;
  blockArray: string[];
}

export interface ISubBlockSelect {
  originBlockId: number;
  originalBlockCode: string;
  cypher: string;
  new: boolean;
  selected: boolean;
}

interface ISublist {
  [key: string]: ISubBlockSelect;
}

export interface IBlockMap {
  [key: string]: ISublist;
}

interface IDataForSend {
  information: IMorphologyCreate;
  tableFields: ISubBlock[];
  referralID: number;
  [index: string]: any;
}

interface IBlockByCypherMap {
  [index: number]: {
    [index: string]: {
      cancerous_cell_percentage: number;
      quality: string;
      priority: number;
      reviewed: boolean;
    }
  }
}

export interface IFormStep {
  rangeOfBlocksDone: boolean;
  rangeOfBlocksSaved: boolean;
  blockCandidatesDone: boolean;
  blockCandidatesSaved: boolean;
  resultsOfMorphCharDone: boolean;
  resultsOfMorphCharSaved: boolean;
  [key: string]: boolean;
}

const defaultSteps: IFormStep = {
  rangeOfBlocksDone: false,
  rangeOfBlocksSaved: false,
  blockCandidatesDone: false,
  blockCandidatesSaved: false,
  resultsOfMorphCharDone: false,
  resultsOfMorphCharSaved: false,
}

const checkTableData = function(tableData:any[], description:string){
  let tableIsComplite:boolean[] = [];
  let tableFields = tableData?.map((data:any) => {
    let tableRow = {...data};
    if(tableRow.cancerousCellPercentage === 0 && tableRow.quality === 'none') tableRow.cancerousCellPercentage = null;
    if(Number.isNaN(tableRow.cancerousCellPercentage)) tableRow.cancerousCellPercentage = null;
    if(tableRow?.cancerousCellPercentage >=0 && ((tableRow?.quality ?? '') === 'bad') && ((tableRow?.priority ?? -1) === 0) && !!description) tableIsComplite.push(true);
    if(tableRow?.cancerousCellPercentage >=0 && ((tableRow?.quality ?? '') === 'good') && ((tableRow?.priority ?? -1) >= 0)) tableIsComplite.push(true);
    return tableRow
  }) ?? [];
  let isTableIsComplite = !!tableIsComplite?.length && !!tableIsComplite?.every((val:boolean) => !!val);
  let normalizedTable = tableFields
  return {isTableIsComplite,normalizedTable};
}

const MorphologicalCharacteristic: FC<IComponentProps> = (data) => {
  const dispatch = useDispatch();

  const { id: referralULID } = useParams();
  const tokens = useSelector(getTokens);

  //Ger user profile
  const profile = useSelector(getProfile);
  const userUlid = useSelector(getUserUlid);
  const doctorsList = useSelector(getServiceUsers);
  const savedMolProCharacteristicFromServer = useSelector(readSavedMolProCharacteristic);
  
  const subBlockCreate: ISubBlockUpdated[] | null = useSelector(getCreatedSubBlocks);
  const molecularProfilingErrors: Record<string, string[] | string> | null  = useSelector(getMolecularProfilingErrors);
  
  //Loader spinner state
  const MolecularProfilingLoading = useSelector(getMolecularProfilingLoading);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => setLoading(!!MolecularProfilingLoading),[MolecularProfilingLoading,setLoading])

  //Edit page button and is edit check
  const [isEdit, setEdit] = useState<boolean>(false);
  const isEditable = useMemo(() => {
    return !data.statusCancellation && data.status === 'morphology_pending'
  }, [data]);

  //Step complited
  const [isComplited, setComplited] = useState<boolean>(false);
  //Select glass options state
  const [glassList, setGlassList] = useState<ISelectedBlockOption[]>([]);
  //Blocks
  const [selectedBlockValues, setSelectedBlockValues] = useState<ISelectedBlockOption[]>([]);
  const [preSelectedBlockValues, setPreSelectedBlockValues] = useState<ISelectedBlockOption[]>([]);
  const [savedBlockValues, setSavedBlockValues] = useState<ISelectedBlockOption[]>([]);
  //Subs
  const [selectedSubValues, setSelectedSubValues] = useState<{ [index: string]: ISubBlockSelect }>({});
  const [savedSubValues, setSavedSubValues] = useState<{ [index: string]: ISubBlockSelect }>({});

  const [confirmedSubBlocks, setConfirmedSubBlocks] = useState<ISubBlock[]>([]);

  //Save changes
  const [allDataSaved, setAllDataSaved] = useState<boolean>(true);                     //all changes have been saved
  const [allDataReadyToConfirm, setAllDataReadyToConfirm] = useState<boolean>(false);  //data redy to be confirmed
  const [recommendations, setRecommendations] = useState<string>('');                  //saved recomendation or from referral data
  const [savedByDoctor, setSavedByDoctor] = useState<string>('');                      //why saved referral data
  const [dateOfCompletion, setDateOfCompletion] = useState<string>('');                //Date when complited 
  const [dateOfSaved, setDateOfSaved] = useState<string>('');                          //Date when saved
  
  //Block selector data and flag
  const [isBlockConfirmed, setBlockConfirmed] = useState<boolean>(false);

  //Form filling steps
  const [formSteps, setFormSteps] = useState<IFormStep>({ ...defaultSteps });
  // const [savedFormSteps, setSavedFormSteps] = useState<IFormStep>({ ...defaultSteps });


  //SAVE TO STORAGE ************************************************************************************

  const deleteFormChanges = useCallback(async () => {
    if (!referralULID) return;
    //load saved data 
    let LocalStorageData = (async () => await loadLocalStorageData('CHANGES_NOT_SAVED_MorphData')?.then((data: any) => data))();
    let savedData = await LocalStorageData;

    let copySavedData = { ...savedData ?? {} };

    if (Object.keys(copySavedData?.[referralULID] ?? {})?.length) {
      delete copySavedData[referralULID]
    }

    if (Object.keys(copySavedData).length) {
      await saveLocalStorageData('CHANGES_NOT_SAVED_MorphData', { ...copySavedData });
    } else {
      await removeItemLocalStorage('CHANGES_NOT_SAVED_MorphData');
    }

    setAllDataReadyToConfirm(false);
    setAllDataSaved(true);
  }, [referralULID, setAllDataReadyToConfirm]);



  const loadFormChanges = useCallback(async (savedData?:any) => {
    if (!referralULID) return;
    if(!savedData) return;
    //load saved data
    if (Object.keys(savedData ?? {})?.length) {
      let { formSteps, glassList, selectedBlockValues,
        selectedSubValues, confirmedSubBlocks, allDataSaved,
        allDataReadyToConfirm, recommendations, savedByDoctor, dateSaved, userUlid } = savedData;
        let selectedSubValuesCopy:{ [index: string]: ISubBlockSelect } = {...selectedSubValues};
        let selectedSubValuesTemp:{ [index: string]: ISubBlockSelect } = {};
        for( const value of Object.values(selectedSubValuesCopy)){
          if(value?.originalBlockCode && value?.cypher) selectedSubValuesTemp[`${value.originalBlockCode}_${value.cypher}`] = value
        }
        if(Object.keys(selectedSubValuesTemp)?.length){
          selectedSubValues = selectedSubValuesTemp;
        }
      setFormSteps(formSteps);
      // setSavedFormSteps(formSteps);
      setGlassList(glassList);
      setSelectedBlockValues(selectedBlockValues);
      setPreSelectedBlockValues([]);
      setSavedBlockValues(selectedBlockValues);

      setSelectedSubValues(selectedSubValues);
      setSavedSubValues(selectedSubValues);

      setRecommendations(recommendations);
      setSavedByDoctor(userUlid ?? savedByDoctor);
      setConfirmedSubBlocks(confirmedSubBlocks?.length ? confirmedSubBlocks : Object?.values(selectedSubValues ?? {}) ?? []);

      setAllDataReadyToConfirm(allDataReadyToConfirm);
      setBlockConfirmed(!!selectedBlockValues?.length);
      setAllDataSaved(allDataSaved);
      if(dateSaved){
        let formatDateStr = format(new Date(+dateSaved), 'dd.MM.yyyy - HH:mm:ss')
        formatDateStr && setDateOfSaved(formatDateStr);
        }
    }
    setLoading(false);
  }, [referralULID, setGlassList, setSelectedBlockValues, setSelectedSubValues, setConfirmedSubBlocks, setPreSelectedBlockValues,setDateOfSaved, setLoading,
    setAllDataSaved, setAllDataReadyToConfirm, setRecommendations, setSavedByDoctor, setBlockConfirmed, setFormSteps]);

  useEffect(() => {
    if(savedMolProCharacteristicFromServer){
      loadFormChanges(savedMolProCharacteristicFromServer)
    }
  },[loadFormChanges,savedMolProCharacteristicFromServer]);

  const saveFormChanges = useCallback(async (type: string, data?: any) => {
    if (!referralULID) return;
    let dataForSave: { [index: string]: any } = {};

    dataForSave[referralULID] = {
      allDataSaved: true,
      formSteps, glassList,
      selectedBlockValues,
      selectedSubValues, 
      confirmedSubBlocks, 
      recommendations,
      savedByDoctor: userUlid,
      dateSaved: Date.now(),
      allDataReadyToConfirm, 
    }

    let allReady = false;
    if (!!data && Object.values(data) && data?.tableFields) {
      let {isTableIsComplite,normalizedTable} = checkTableData(data?.tableFields, data?.description);
      dataForSave[referralULID]['confirmedSubBlocks'] = normalizedTable ?? [];
      dataForSave[referralULID]['recommendations'] = data?.description;
      dataForSave[referralULID]['savedByDoctor'] = userUlid;
      dataForSave[referralULID]['allDataReadyToConfirm'] = isTableIsComplite;
      allReady = isTableIsComplite;
    }

    setAllDataSaved(true);
    setAllDataReadyToConfirm(allReady);
    if(tokens?.access && type === 'resultsOfMorphCharSaved'){
      dispatch(postMolProCharacteristicSavedData(tokens?.access,referralULID,dataForSave[referralULID]))
    }

    loadFormChanges(dataForSave[referralULID]);
    setLoading(false);

    setEdit(false);
  }, [setAllDataSaved, referralULID, recommendations, userUlid,formSteps,selectedBlockValues, setEdit,setLoading,
    glassList, selectedSubValues, confirmedSubBlocks, allDataReadyToConfirm, loadFormChanges, dispatch, tokens?.access]);


  //ADDITIONAL DATA ************************************************************************************

  const doctorWhoCompleted = useMemo(() => {
    if (!data?.stepReferral || !data?.stepReferral?.length) return '';
    let bioComliteObj: IStepReferral = data.stepReferral.find((step: IStepReferral) => step.status === 'morphology_done');
    let { dateSaved, userUlid } = bioComliteObj ?? {};
    //Completion doctor
    let doctorWhoCompleted = '';
    let doctorName;
    if(savedByDoctor){
      doctorName = doctorsList?.find((item) => item.ulid === savedByDoctor)?.userProfile;
    } 
    if(userUlid) {
      doctorName = doctorsList?.find((item) => item.ulid === userUlid)?.userProfile;
    }

    if (doctorName) {
      let { firstName = '', lastName = '', middleName = '', title = '' } = doctorName;
      doctorWhoCompleted = `${lastName} ${firstName} ${middleName}`.trim() + ', ' + title;
    };

    if (!bioComliteObj && !doctorWhoCompleted) return "";
    if (!bioComliteObj && doctorWhoCompleted) return doctorWhoCompleted;


    //Completion date
    let completionDate = dateSaved ? format(new Date(dateSaved), 'dd.MM.yyyy - HH:mm:ss') : '';
    if(completionDate) setDateOfCompletion(completionDate)



    return doctorWhoCompleted;
  }, [data, doctorsList, savedByDoctor, setDateOfCompletion]);

  //Detect chenges

  const checkDifferenceInGlassNumbers = useMemo(() => {
    let detectDifferenceValues = false;
    let detectDifferenceLength = false;

    if (!preSelectedBlockValues?.length) return false;
    if (!savedBlockValues?.length && preSelectedBlockValues?.length) return true;

    let defaultBlocks = savedBlockValues.map((data: ISelectedBlockOption) => data.value);
    let selectBlocks = preSelectedBlockValues.map((data: ISelectedBlockOption) => data.value);

    if (defaultBlocks?.length && selectBlocks?.length) {
      detectDifferenceValues = selectBlocks?.every((value: string) => defaultBlocks.includes(value));
      detectDifferenceLength = selectBlocks?.length === defaultBlocks?.length;
    }

    let result = !(detectDifferenceValues && detectDifferenceLength);

    return result;
  }, [savedBlockValues, preSelectedBlockValues]);

  const checkDifferenceInSubNumbers = useMemo(() => {
    let detectDifferenceValues = false;
    let detectDifferenceLength = false;

    let defaultSubs = Object?.keys(savedSubValues ?? {}) ?? []
    let selectedSubs = Object?.keys(selectedSubValues ?? {}) ?? []

    if (!selectedSubs?.length) return false;
    if (!defaultSubs?.length && selectedSubs?.length) return true;

    if (defaultSubs?.length && selectedSubs?.length) {
      detectDifferenceValues = selectedSubs?.every((value: string) => defaultSubs.includes(value));
      detectDifferenceLength = selectedSubs?.length === defaultSubs?.length;
    }

    let result = !(detectDifferenceValues && detectDifferenceLength);

    return result;
  }, [savedSubValues, selectedSubValues]);

  
  //Clear all chanegs
  const handlerClearPage = useCallback(async (clear?:boolean) => {
    await deleteFormChanges();
    setRecommendations('');
    setSelectedBlockValues([]);
    setSavedBlockValues([]);
    setPreSelectedBlockValues([]);
    setConfirmedSubBlocks([]);
    setSelectedSubValues({});
    setSavedSubValues({});
    setBlockConfirmed(false);
    setAllDataSaved(true);
    setFormSteps({ ...defaultSteps });
    setDateOfSaved('');
    setSavedByDoctor('');
    setEdit(false);
    !!clear && dispatch(addAlert(AlertTypes.ERROR, 'Форма очищена', `Сохраненные данные удалены` ));
    !clear && dispatch(addAlert(AlertTypes.INFO, 'Данные восстановлены', `` ));
  }, [setEdit, setSelectedSubValues, setPreSelectedBlockValues, setSelectedBlockValues, setRecommendations,dispatch,
    setBlockConfirmed, setConfirmedSubBlocks, deleteFormChanges, setAllDataSaved, setSavedBlockValues, setSavedSubValues,
    setDateOfSaved,setSavedByDoctor]);
    
    //Switch edit page
    const handlerEditPage = useCallback(async () => {
      if (!isEdit) return setEdit(true);
      setLoading(true);
      //get saved data
      tokens?.access && referralULID && dispatch(getMolProCharacteristicSavedData(tokens?.access, referralULID ));

      handlerClearPage(false);
    }, [setEdit, isEdit, handlerClearPage, tokens?.access, referralULID,dispatch, setLoading]);


  //ORIGINAL BLOCKS ************************************************************************************



  const handlerBlockConfirm = useCallback(async (type: string, data: ISelectedBlockOption[]) => {
    let formData = {...defaultSteps}
    // let { SSubValue, CSubBlocks } = checkSelectedSubs(preSelectedBlockValues, selectedSubValues, confirmedSubBlocks)
    // CSubBlocks = Object.values(SSubValue);
    setLoading(true);
    if (type === 'rangeOfBlocksDone') {
      formData.rangeOfBlocksSaved = true;
      formData.rangeOfBlocksDone = true;
      setSelectedBlockValues(preSelectedBlockValues);
      setBlockConfirmed(true);
      setPreSelectedBlockValues([]);
    } else {
      formData.rangeOfBlocksSaved = true;
      setBlockConfirmed(false)
    }
    setFormSteps(formData);

    setTimeout(() => {
      setSavedSubValues({});
      setSelectedSubValues({});
      setLoading(false);
    }, 1000);

  }, [setBlockConfirmed, setLoading,setSelectedBlockValues,setSelectedSubValues,
    preSelectedBlockValues,setSavedSubValues,setFormSteps]);


  const handlerSelectBlock = useCallback((blockData: ISelectedBlockOption[]) => {
    setPreSelectedBlockValues(blockData);
  }, [setPreSelectedBlockValues]);


  //Block Options list
  const [optionsData, selectedSubBlocksData] = useMemo(() => {

    if (!data?.biologicalMaterial) return [[], []];
    let options: ISelectedBlockOption[] = [];
    let selectedSubBlocks: ISubBlock[] = [];

    // const isGlassReview = FLOW_STEPS_STATUSES[data.status] > 2;

    const blocks: IBiologicalMaterial[] = data?.biologicalMaterial.filter((blockItem: any) => blockItem.type === 'Block') ?? [];

    if (!blocks?.length) return [[], []];

    for (let rootBlock of blocks) {
      let rootBlockULID = rootBlock.ulid;
      let originalBlocks: IBlockCypher[] = rootBlock.material.blockCypher.filter((blk: IBlockCypher) => !!blk?.original);

      //set originalBlockCode
      let originalBlockCode: string = originalBlocks[0].code;
      let blockSublocks;
      if (rootBlock.material.blockSublocks.length) blockSublocks = rootBlock.material.blockSublocks.map((value: ISubBlock) => ({ ...value, originalBlockCode }))
      if (blockSublocks) selectedSubBlocks = [...selectedSubBlocks, ...blockSublocks];

      for (let originBlk of originalBlocks) {
        let originBlkockData = { ...originBlk, rootBlockULID }
        let getRootBlockYear = originBlk?.year?.toString()?.slice(-2) ?? '';
        let yearPrefix = (subData: string) => !!getRootBlockYear ? `${subData}/${getRootBlockYear}` : subData;
        let blockArrayWithYearPrefix = originBlk?.blockArray?.map((value: string) => yearPrefix(value));
        let { code: value, code: label, block = 0 } = originBlk;
        options.push({ value, label, blockArray: blockArrayWithYearPrefix, block, originBlkockData });
      }
    }

    if (selectedSubBlocks.length) selectedSubBlocks = selectedSubBlocks.sort((a: ISubBlock, b: ISubBlock) => a.cypher.localeCompare(b.cypher));
    return [options, selectedSubBlocks];
  }, [data?.biologicalMaterial]);

  //load first data
  useLayoutEffect(() => {
    if (!data?.status) return;
    const isGlassReview = FLOW_STEPS_STATUSES[data.status];

    //if glass selected by morfologi set its
    if (isGlassReview > 2 && tokens?.access) {
      const doctorDescription = data?.molecularProfilingReferral?.morphology?.recommendations ?? '';
      dispatch(fetchServiceUsers(tokens?.access, { organizationId: 0 }));
      setConfirmedSubBlocks(selectedSubBlocksData);
      setRecommendations(doctorDescription);
      setSelectedBlockValues(optionsData);
      setComplited(true);
    };

    //if status morphology_pending set glass for morfologi choice
    if (isGlassReview === 2) {
      tokens?.access && referralULID && dispatch(getMolProCharacteristicSavedData(tokens?.access, referralULID ));
      return setGlassList(optionsData);
    };


    //if the stage of morphology has not come, then we do nothing

  }, [data, dispatch, tokens?.access, optionsData,setComplited,referralULID,
    selectedSubBlocksData, setRecommendations, loadFormChanges, setConfirmedSubBlocks]);



  //SUB BLOCKS ************************************************************************************


  const handlerSaveSelectedSubs = useCallback(async (type: string) => {
    setLoading(true);
    let SubValuesData = {...selectedSubValues}
    let confirmedSubBlocksMap:{ [index: string]: any } = {};
    for(let csb of confirmedSubBlocks){
      confirmedSubBlocksMap[`${csb.originalBlockCode}_${csb.cypher}`] = csb ?? {};
    }
    for(let [sbKey,sbVal] of Object.entries(SubValuesData)){
      if(!confirmedSubBlocksMap?.[sbKey]){
        confirmedSubBlocksMap[sbKey] = {
                block: sbVal.originBlockId,
                originalBlockCode: sbVal.originalBlockCode,
                cypher: sbVal.cypher,
                quality: "none",
                cancerousCellPercentage: null,
                priority: 0,
                reviewed: false,
              }
      }
    }

    let confirmedSubBlocksData = (Object?.values(confirmedSubBlocksMap) ?? [])//?.sort((a: ISubBlock, b: ISubBlock) => a.cypher.localeCompare(b.cypher))

    await setConfirmedSubBlocks(() => confirmedSubBlocksData);

    await setTimeout(async () => {
      setLoading(false);
    }, 1000);

  }, [selectedSubValues, setConfirmedSubBlocks, setLoading, confirmedSubBlocks]);

  const handlerSubChecked = useCallback(async (checkedSub: ISubBlockSelect) => {
    let copySelectedSubValues = { ...selectedSubValues }
    let copyСonfirmedSubBlocks =  [...confirmedSubBlocks]
    if (!checkedSub?.selected){
      copyСonfirmedSubBlocks = copyСonfirmedSubBlocks.filter((sblc: ISubBlock) => sblc.cypher !== checkedSub.cypher)
      delete copySelectedSubValues?.[`${checkedSub.originalBlockCode}_${checkedSub.cypher}`]
      await setConfirmedSubBlocks(() => copyСonfirmedSubBlocks);
    };

    if (checkedSub?.selected) copySelectedSubValues[`${checkedSub.originalBlockCode}_${checkedSub.cypher}`] = checkedSub;
    setSelectedSubValues(copySelectedSubValues);

  }, [selectedSubValues, setSelectedSubValues,confirmedSubBlocks]);

  const [nowSelectedSubs, setNowSelectedSubs] = useState<string[]>([]);

  useEffect(() => {
    if(nowSelectedSubs?.length && !Object.keys(selectedSubValues)?.length){
      setNowSelectedSubs([]);
      handlerSaveSelectedSubs('rangeOfBlocksDone')
    }
    if(!!selectedSubValues && !!nowSelectedSubs && (Object?.keys(selectedSubValues) ?? [])?.length !== (Object?.keys(nowSelectedSubs) ?? [])?.length ){//&& checkDifferenceInSubNumbers
      setNowSelectedSubs((Object?.keys(selectedSubValues)) ?? [])
      handlerSaveSelectedSubs('blockCandidatesSaved')
      handlerSaveSelectedSubs('blockCandidatesDone')
    }

  },[selectedSubValues,nowSelectedSubs,setNowSelectedSubs,handlerSaveSelectedSubs,checkDifferenceInSubNumbers]);

  //Set Subblocks default and change selected subBlocks
  const subBlockMap: IBlockMap = useMemo(() => {
    if (!isBlockConfirmed) return {};
    let blcMap: IBlockMap = {};
    //By Default
    if(!!selectedBlockValues?.length) {
      for (let root of selectedBlockValues ?? []) {
        if(root?.blockArray?.length) {
          for (let sub of root?.blockArray) {
            if (!blcMap?.[root.value]) blcMap[root.value] = {}
            blcMap[root.value][sub] = { originBlockId: root.block, originalBlockCode: root.value, cypher: sub, new: false, selected: false }
          }
        } else {
          if (!blcMap?.[root.value]) blcMap[root.value] = {}
          blcMap[root.value][root.value] = { originBlockId: root.block, originalBlockCode: root.value, cypher: root.value, new: false, selected: false }
        }
      };
    };

    if (!Object.keys(selectedSubValues).length) return blcMap;

    //Chenge Selected
    let copySelectedSubs = new Map(Object.entries(selectedSubValues).map(([key, value]) => [value.cypher, value]));

    for (let blc in blcMap) {
      for (let sub in blcMap[blc]) {
        let selectedSub = copySelectedSubs.get(sub)
        if (copySelectedSubs.has(sub) && selectedSub) {
          blcMap[blc][sub] = selectedSub;
          copySelectedSubs.delete(sub);
        };
      }
    };

    //Add / Remove new subs
    if (copySelectedSubs.size) {
      copySelectedSubs.forEach((newSub: ISubBlockSelect, newSubNumber: string) => {
        let originalBlockCode = newSub?.originalBlockCode ?? '';
        let isCodeAndNmber = originalBlockCode && newSubNumber && !!(newSub.new || !!newSub.selected);

        if (isCodeAndNmber) {
          if (!blcMap?.[originalBlockCode]) {
            blcMap[originalBlockCode] = {}
            blcMap[originalBlockCode][newSubNumber] = newSub;
          } else {
            blcMap[originalBlockCode][newSubNumber] = newSub;
          }
        }
      });
    };

    copySelectedSubs.clear();
    return blcMap;

    // eslint-disable-next-line
  }, [isBlockConfirmed, selectedBlockValues, selectedSubValues, handlerSubChecked]);


  //SEND TO SERVER ************************************************************************************


  const [confirmedDataToSend, setDataToSend] = useState<IDataForSend>();

  //send data after get subblock id's
  useEffect(() => {
    if (!subBlockCreate || !confirmedDataToSend) return;
    let { information, tableFields, referralID } = confirmedDataToSend;
    const BlockByCypherMap: IBlockByCypherMap = {};
    let MorphologyCreateData = { ...information }
    MorphologyCreateData.subblock_ulids = []

    for (let sub of tableFields) {
      let { cancerousCellPercentage, quality, priority } = sub
      let cancerous_cell_percentage = +(cancerousCellPercentage ?? 0);

      if (!BlockByCypherMap?.[+sub.block]) BlockByCypherMap[+sub.block] = {}
      BlockByCypherMap[+sub.block][sub.cypher] = { cancerous_cell_percentage, quality, priority, reviewed: true };
    }

    let subUpdateData: ISubBlockSendUpdate[] = [];

    for (let subData of subBlockCreate) {
      let { block, cypher, id, bioUlid } = subData;

      MorphologyCreateData.subblock_ulids.push(bioUlid);

      let savedData = BlockByCypherMap?.[block]?.[cypher];

      if (savedData) {
        subUpdateData.push({ id, bio_ulid: bioUlid, block, cypher, ...savedData })
      }
    }

    if (tokens?.access && referralULID) {
      dispatch(postNewBioMaterialSubBlocksUpdate(tokens?.access, subUpdateData));
      setTimeout(() => {
        dispatch(postMorphologicalCharacteristicCreate(tokens?.access, MorphologyCreateData))
        deleteFormChanges();
        dispatch(createNextStep(tokens.access, { referral: referralID, status: 'morphology_done' }));
        setTimeout(() => {
          dispatch(resetSavedMolProCharacteristic());
          dispatch(resetMolecularProfiling());
        },2000);
      },2000);
      //dispatch(patchMorphologicalDataConfirm(tokens?.access,referralULID)) 
      setAllDataSaved(true);
    }


  }, [tokens?.access, dispatch, subBlockCreate, confirmedDataToSend, deleteFormChanges, setAllDataSaved, referralULID]);

  useEffect(() => {
    if(molecularProfilingErrors && molecularProfilingErrors?.length){
      dispatch(addAlert(AlertTypes.ERROR, 'Ошибка', JSON.stringify(molecularProfilingErrors,null,2) ))
    }
  },[molecularProfilingErrors,dispatch]);

  const handlerConfirmCharacteristic = useCallback(async (dataForSend: { description: string, implementer: string, tableFields: ISubBlock[] }) => {
    setLoading(true);
    // setEdit(false);
    let { description, tableFields } = dataForSend;

    const information = {
      type: 'molecularProfiling',
      referral_ulid: data?.molecularProfilingReferral?.ulid,
      subblock_ulids: [],
      recommendations: description,
      morphologist_ulid: profile?.ulid ?? ''
    }

    setDataToSend({ information, tableFields, referralID: data?.id ?? 0 });

    const subBlockCreateData: ISubBlockCreate[] = tableFields.map(({ block, cypher }) => ({ block, cypher }));

    if (tokens?.access) {
      try {
        dispatch(postNewBioMaterialSubBlocksCreate(tokens?.access, subBlockCreateData));
        setAllDataSaved(true);
      } catch (e) {
        console.log(e)
      }
    }

  }, [dispatch, tokens, data, profile, setDataToSend, setAllDataSaved,setLoading]);

  const morphTableChangesTrigger = useCallback((chengedData:{ tableFields:ISubBlock[],description:string}) => {
    let {tableFields,description} = chengedData;
    tableFields && setConfirmedSubBlocks(tableFields);
    description && setRecommendations(description);
    setAllDataSaved(!tableFields || !description);
  },[setConfirmedSubBlocks,setRecommendations,setAllDataSaved])



  return (
    <TMorphologicalCharacteristicWrapper>
      <TRowWr direction={"space-between"}>
        <TDateOfCompletion>{isComplited ? `Дата завершения: ${dateOfCompletion}` : !!dateOfSaved && `Приостановлено: ${dateOfSaved}`}</TDateOfCompletion>
        <div>
        {!dateOfCompletion && isEdit && data?.ableToUdateREFERRAL?.updateMorphology && (
          <TEditButton onClick={() => handlerClearPage(true)} nocolored={true} warning={true} disabled={!isEditable} >
            Очистить
          </TEditButton>
        )}
        {!dateOfCompletion && data?.ableToUdateREFERRAL?.updateMorphology && (
          <TEditButton onClick={handlerEditPage} active={isEdit} disabled={!isEditable} >
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton>
        )}
        </div>
      </TRowWr>
      <Loader enabled={loading}>
        {/* BLOCK SELECTOR */}
        {!isComplited && <GlassSelector
          glassList={glassList}
          disabled={!isEdit}
          glassConfirmHandler={handlerBlockConfirm}
          selectBlockHandler={handlerSelectBlock}
          hasPermit={!!data?.ableToUdateREFERRAL?.updateMorphology}
          defaultBlockValues={savedBlockValues}
          isSaved={formSteps.rangeOfBlocksSaved}
          isDone={formSteps.rangeOfBlocksDone}
          isDifferenceInNumbers={checkDifferenceInGlassNumbers}
        />}
        {/* SUB BLOCK SELECTOR */}
        {!isComplited && formSteps.rangeOfBlocksDone && (
          <SubBlockSelector
            blockList={subBlockMap}
            disabled={!isEdit}
            selectedSubValues={selectedSubValues}
            subCheckedHandler={handlerSubChecked}
            subConfirmHandler={handlerSaveSelectedSubs}
            hasPermit={!!data?.ableToUdateREFERRAL?.updateMorphology}
            isSaved={formSteps.blockCandidatesSaved}
            isDone={formSteps.blockCandidatesDone}
            isDifferenceInNumbers={checkDifferenceInSubNumbers}
          />)}
        {/* MORPHOLOGY TABLE */}
          {!isComplited && formSteps.rangeOfBlocksDone && (
            <MorphTable
              key={`MorphTable_${confirmedSubBlocks?.length}`}
              isEdit={isEdit}
              subBlockList={confirmedSubBlocks}
              doctorName={!!doctorWhoCompleted ? doctorWhoCompleted : ''}
              recommendations={recommendations}
              saveData={saveFormChanges}
              hasChangesTrigger={morphTableChangesTrigger}
              allDataSaved={allDataSaved}
              confirmSend={handlerConfirmCharacteristic}
              readyToConfirm={allDataReadyToConfirm}
              hasPermit={!!data?.ableToUdateREFERRAL?.updateMorphology}
              isComplited={isComplited}
              isSaved={!!dateOfSaved}
            />
          )}
        {(isComplited) &&
            <MorphTable
              key={`${confirmedSubBlocks?.length}_${checkDifferenceInSubNumbers}_${recommendations}`}
              isEdit={isEdit}
              subBlockList={confirmedSubBlocks}
              doctorName={!!doctorWhoCompleted ? doctorWhoCompleted : ''}
              recommendations={recommendations}
              saveData={console.log}
              hasChangesTrigger={(isChange: boolean) => setAllDataSaved(!isChange)}
              allDataSaved={allDataSaved}
              confirmSend={handlerConfirmCharacteristic}
              readyToConfirm={allDataReadyToConfirm}
              hasPermit={!!data?.ableToUdateREFERRAL?.updateMorphology}
              isComplited={isComplited}
              isSaved={!!dateOfSaved}
            />
        }
      </Loader>
    </TMorphologicalCharacteristicWrapper>)
};

export default MorphologicalCharacteristic;