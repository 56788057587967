import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TLink = styled(Link)`
  text-decoration: none;
  color: #c1c1c1;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  
  & > *:first-child {
    margin-right: 3px;
  }
`;