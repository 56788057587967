import { FC, FormHTMLAttributes } from 'react';
import { TForm } from './styled';

interface IFormContainer extends FormHTMLAttributes<any> {
  onSubmit: () => void;
}

const FormContainer: FC<IFormContainer> = ({ children, onSubmit, ...restProps }) => {

  return (
    <div>
      <TForm onSubmit={onSubmit} {...restProps}>
        {children}
      </TForm>
    </div>
  );
};

export default FormContainer;
