import styled from 'styled-components';
import Button from '../../../../../components/shared/Button';
import { ReactComponent as PlusIcon } from '../../../../../icons/plus.svg';

export const TCustomButton = styled(Button)<{ disabled?: boolean }>`
  width: 261px;
  height: 44px;
  color: ${({theme}) => theme.colors.white};
  border-color: ${({theme}) => theme.colors.blue[300]};
  background-color: ${({theme}) => theme.colors.blue[300]};

  &:disabled {
    background-color: transparent;
    border-color: ${({theme}) => theme.colors.gray[300]};
    color: ${({theme}) => theme.colors.gray[300]};
  }
`;

export const TCustomButtonEdit = styled(Button)`
  width: 261px;
  height: 44px;
  border-color: ${({theme}) => theme.colors.blue[300]};
  color: ${({theme}) => theme.colors.blue[300]};

  &:hover:enabled {
    border-color: ${({theme}) => theme.colors.blue[300]};
    color: ${({theme}) => theme.colors.blue[300]};
  }
`;

export const TAmbulatoryCards = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  
  & > p {
    margin-bottom: 12px;
  }

  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
`;

export const TInputsWr = styled.div`
  display: flex;
  
  & > * {
    margin-right: 3px;
    width: 50%;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const TInvertPlusIcon = styled(PlusIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.product.primaryActive};
  }
`;

export const TAddButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin: 20px 0;
  color: ${({ theme, disabled }) => disabled ? theme.colors.gray[200] : theme.colors.gray[200]};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  
  & ${TInvertPlusIcon} {
    path {
      stroke: ${({ theme, disabled }) => disabled ? theme.colors.gray[200] : theme.colors.product.primaryActive};
    }
  }
  
  & > *:first-child {
    margin-right: 16px;
    width: 40px;
    height: 40px;
  }
`;
