import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TLabel, TBlock, TBlocksWr, TItem, TItemWrapper, TBlockTumor, TStageBlock, TCheckbox, TCheckboxWr } from './styled';
import InputSelect from '../../../shared/InputSelect';
import { T_CATEGORY_LIST, N_CATEGORY_LIST, SUB_LIST, M_CATEGORY_LIST, CATEGORY_TYPES, STAGE_LIST } from './constants';
import Tooltip from '../../../shared/Tooltip';
import { getFromSessionStorage} from '../../../../utils/sessionStorageHelpers';
import { TContent, TTitle } from '../../../shared/Tooltip/styled';
import SESSION_STORAGE_KEYS from '../../../../constants/sessionStorageKeys';

interface ITNMBlock {
  label?: string;
}

const { CLINICAL } = SESSION_STORAGE_KEYS;

const TNMBlock: FC<ITNMBlock> = ({ label }) => {
  const { control,register, setValue } = useFormContext();

  const tCategoryOptions = T_CATEGORY_LIST.map(item => ({ value: item, label: item }));
  const nCategoryOptions = N_CATEGORY_LIST.map(item => ({ value: item, label: item }));
  const mCategoryOptions = M_CATEGORY_LIST.map(item => ({ value: item, label: item }));
  const subOptions = SUB_LIST.map(item => ({ value: item, label: item }));
  const stageOption = STAGE_LIST.map(item => ({ value: item, label: item }));

  const [backlightTumour, setBackligthTumour] = useState(false);
  const [backlightNode, setBackligthNode] = useState(false);
  const [backlightMetastatic, setBackligthMetastatic] = useState(false);


  const [isTnmActive, setTnmActive] = useState(false);
  const [isStageActive, setStageActive] = useState(false);

  const [isTnmNonApplicableActive, setTnmNonApplicableActive] = useState(false);
  const [isTnmUnknownActive, setTnmUnknownActive] = useState(false);

  const [isStageNonApplicableActive, setStageNonApplicableActive] = useState(false);
  const [isStageUnknownActive, setStageUnknownActive] = useState(false);


  const [stageTNMUpdateKey,setTNMUpdateKey] = useState<number>(Date.now());
  const [stageStageUpdateKey,setStageUpdateKey] = useState<number>(Date.now());

  const backlighTumourOn = () => setBackligthTumour(true);
  const backlighTumourOff = () => setBackligthTumour(false);
  const backlighNodeOn = () => setBackligthNode(true);
  const backlighNodeOff = () => setBackligthNode(false);
  const backlighMetastaticOn = () => setBackligthMetastatic(true);
  const backlighMetastaticOff = () => setBackligthMetastatic(false);

  const items = [{ value: CATEGORY_TYPES.TUMOUR, title: 'Tumour', backligth: backlightTumour },
  { value: CATEGORY_TYPES.NODE, title: 'Node', backligth: backlightNode },
  { value: CATEGORY_TYPES.METASTATIC_SPREAD, title: 'Metastatic spread', backligth: backlightMetastatic }];

  useLayoutEffect(() => {
    const [tnmNonApplicable, tnmUnknown, stageNonApplicable, stageUnknown ] = getFromSessionStorage(
      CLINICAL.tnmNonApplicable, CLINICAL.tnmUnknown, CLINICAL.stageNonApplicable, CLINICAL.stageUnknown);
      setTnmActive(tnmNonApplicable === 'true' || tnmUnknown === 'true');
      setStageActive(stageNonApplicable === 'true' || stageUnknown === 'true');

      setTnmNonApplicableActive(tnmNonApplicable === 'true');
      setTnmUnknownActive(tnmUnknown === 'true');
      setStageNonApplicableActive(stageNonApplicable === 'true');
      setStageUnknownActive(stageUnknown === 'true');

  },[setTnmActive,setStageActive,setTnmNonApplicableActive,setTnmUnknownActive,setStageNonApplicableActive,setStageUnknownActive])

  useEffect(()=>{
    if(isTnmActive){
      setValue('tCategory','');
      setValue('tSub','');
      setValue('nCategory','');
      setValue('nSub','');
      setValue('mCategory','');
      setValue('mSub','');
      setTNMUpdateKey(Date.now());
    }
  },[isTnmActive,setValue,isStageActive,setTNMUpdateKey]);

  useEffect(()=>{
    if(isStageActive){
      setValue('stage','');
      setValue('stageSub','');
      setStageUpdateKey(Date.now());
    }
  },[isStageActive,setValue,isTnmActive, setStageUpdateKey]);

  const nonTnmHandler = useCallback((type:string) => {
    setValue('tnmNonApplicable',false);
    setTnmNonApplicableActive(false);
    setValue('tnmUnknown',false);
    setTnmUnknownActive(false);
    setTnmActive(false);

    if(type === 'tnmNonApplicable'){
      if(!isTnmNonApplicableActive){
        setTnmNonApplicableActive(true);
        setValue('tnmNonApplicable',true);
        setTnmActive(true);
      }
    }
    if(type === 'tnmUnknown'){
      if(!isTnmUnknownActive){
        setTnmUnknownActive(true);
        setValue('tnmUnknown',true);
        setTnmActive(true);
      }
    }
  },[isTnmNonApplicableActive,setTnmNonApplicableActive,isTnmUnknownActive,setValue,setTnmUnknownActive,setTnmActive]);
  
  const nonStageHandler = useCallback((type:string) => {
    setValue('stageNonApplicable',false);
    setStageNonApplicableActive(false);
    setValue('stageUnknown',false);
    setStageUnknownActive(false);
    setStageActive(false);
    
    if(type === 'stageNonApplicable'){
      if(!isStageNonApplicableActive){
        setValue('stageNonApplicable',true);
        setStageNonApplicableActive(true);
        setStageActive(true);
      }
    }
    if(type === 'stageUnknown'){
      if(!isStageUnknownActive){
        setValue('stageUnknown',true);
        setStageUnknownActive(true);
        setStageActive(true);
      }
    }

  },[isStageNonApplicableActive,setStageNonApplicableActive,setValue,setStageUnknownActive,isStageUnknownActive,setStageActive]);


  return (
    <div>
      {label && <TLabel>{label}</TLabel>}
      <TBlockTumor>
        {items.map((el, index) => <TItemWrapper key={index}><TItem backligth={el.backligth}>{el.title}</TItem></TItemWrapper>)}
      </TBlockTumor>
      <TBlocksWr key={`tnm_update_key${stageTNMUpdateKey}`}>
        <TBlock>
          <Tooltip id="tCategory-tooltip" place='top' overridePosition={({ left, top }) => ({ left: left + 58, top: top - 40 })}>
          <TTitle>Размер опухоли:</TTitle>
          <TContent>
            <br />
            <b>TX</b> – первичную опухоль нельзя оценить
            <br />
            <b>T0</b> – нет первичной опухоли
            <br />
            <b>Tis</b> – карцинома in situ (рак в пределах слизистой)
            <br />
            <b>T1</b> – <b>T4</b> увеличение размеров первичной опухоли и/или локального распространения
            </TContent>
          </Tooltip>
          <Controller
            control={control}
            name="tCategory"
            rules={{ required: !isTnmActive }}
            render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
              <div data-for="tCategory-tooltip" data-tip=""
                onMouseEnter={backlighTumourOn}
                onMouseLeave={backlighTumourOff}>
                <InputSelect
                  inputRef={ref}
                  onChange={(value) => onChange(value)}
                  value={value}
                  options={tCategoryOptions}
                  placeholder="T"
                  disabled={isTnmActive}
                  error={invalid}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="tSub"
            rules={{ required: !isTnmActive }}
            render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
              <div onMouseEnter={backlighTumourOn}
                onMouseLeave={backlighTumourOff}>
                <InputSelect
                  inputRef={ref}
                  onChange={(value) => onChange(value)}
                  value={value}
                  options={subOptions}
                  placeholder="Sub"
                  disabled={isTnmActive}
                  error={invalid}
                />
              </div>
            )}
          />
        </TBlock>
        <TBlock>
          <Tooltip id="nCategory-tooltip" place="top"
            overridePosition={({ left, top }) => ({ left: left + 58, top: top - 40 })}>
            <TTitle>Вовлечение лимфатических узлов:</TTitle>
            <br />
            <TContent>
              <b>NX</b> – региональные лимфатические узлы нельзя оценить
              <br />
              <b>N0</b> – нет метастазов в региональные лимфатические узлы
              <br />
              <b>N1</b> – <b>N3</b> есть метастазы в региональные лимфатические узлы
            </TContent>
          </Tooltip>
          <Controller
            control={control}
            name="nCategory"
            rules={{ required: !isTnmActive }}
            render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
              <div data-for="nCategory-tooltip" data-tip=""
                onMouseEnter={backlighNodeOn}
                onMouseLeave={backlighNodeOff}>
                <InputSelect
                  inputRef={ref}
                  onChange={(value) => onChange(value)}
                  value={value}
                  options={nCategoryOptions}
                  placeholder="N"
                  disabled={isTnmActive}
                  error={invalid}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="nSub"
            rules={{ required: !isTnmActive }}
            render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
              <div onMouseEnter={backlighNodeOn}
                onMouseLeave={backlighNodeOff}>
                <InputSelect
                  inputRef={ref}
                  onChange={(value) => onChange(value)}
                  value={value}
                  options={subOptions}
                  placeholder="Sub"
                  disabled={isTnmActive}
                  error={invalid}
                />
              </div>
            )}
          />
        </TBlock>
        <TBlock>
          <Tooltip id="mCategory-tooltip" place="top"
            overridePosition={({ left, top }) => ({ left: left + 58, top: top - 40 })}>
            <TTitle>Присутствие или отсутствие идентифицируемых метастазов:</TTitle>
            <br />
            <TContent>
            <b>Mx</b> – отдалeнные метастазы нельзя оценить
            <br />
            <b>M0</b> – нет отдалeнных метастазов
            <br />
            <b>M1</b> – установленные отдалeнные метастазы
            </TContent>
          </Tooltip>
          <Controller
            control={control}
            name="mCategory"
            rules={{ required: !isTnmActive }}
            render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
              <div data-for="mCategory-tooltip" data-tip=""
                onMouseEnter={backlighMetastaticOn}
                onMouseLeave={backlighMetastaticOff}
              >
                <InputSelect
                  inputRef={ref}
                  onChange={(value) => onChange(value)}
                  value={value}
                  options={mCategoryOptions}
                  placeholder="M"
                  disabled={isTnmActive}
                  error={invalid} />
              </div>
            )}
          />
          <Controller
            control={control}
            name="mSub"
            rules={{ required: !isTnmActive }}
            render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
              <div onMouseEnter={backlighMetastaticOn}
                onMouseLeave={backlighMetastaticOff}>
                <InputSelect
                  inputRef={ref}
                  onChange={(value) => onChange(value)}
                  value={value}
                  options={subOptions}
                  placeholder="Sub"
                  disabled={isTnmActive}
                  error={invalid}
                />
              </div>
            )}
          />
        </TBlock>

      </TBlocksWr>
      <TCheckboxWr>
        <TCheckbox {...register('tnmNonApplicable')} checked={isTnmNonApplicableActive} label='Неприменимо' onChange={() => nonTnmHandler('tnmNonApplicable')} />
        <TCheckbox {...register('tnmUnknown')} checked={isTnmUnknownActive} label='Не установлена' onChange={() => nonTnmHandler('tnmUnknown')} />
      </TCheckboxWr>
      <TStageBlock key={`stage_update_key${stageStageUpdateKey}`}>
        <Controller
          control={control}
          name='stage'
          rules={{ required: !isStageActive }}
          render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
            <InputSelect
              label='Стадия *'
              disabled={isStageActive}
              placeholder='Stage'
              onChange={(value) => onChange(value)}
              value={value}
              error={invalid}
              options={stageOption} />
          )} />
        <Controller
          control={control}
          name='stageSub'
          rules={{ required: !isStageActive }}
          render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
            <InputSelect
              label=' '
              disabled={isStageActive}
              placeholder='Sub'
              onChange={(value) => onChange(value)}
              value={value}
              error={invalid}
              options={subOptions.map(item => ({ value: item.value.toUpperCase(), label: item.label.toUpperCase() }))} />
          )} />
      </TStageBlock>
      <TCheckboxWr>
        <TCheckbox {...register('stageNonApplicable')} checked={isStageNonApplicableActive} label='Неприменимо' onChange={() => nonStageHandler('stageNonApplicable')} />
        <TCheckbox {...register('stageUnknown')} checked={isStageUnknownActive} label='Не установлена' onChange={() => nonStageHandler('stageUnknown')} />
      </TCheckboxWr>
    </div>
  );
};

export default TNMBlock;
