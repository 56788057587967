import {ErrorsType, Nullable} from "../../types";
import {createSlice} from "@reduxjs/toolkit";
import {Examination, Filter} from "../referral/model";

export interface IResearchInitialState {
  loading: boolean;
  data: Examination[];
  count: number;
  pages: number;
  types: string[];
  // currentPage: Nullable<string>;
  // nextPage: Nullable<string>;
  errors: Nullable<ErrorsType>;
  filter: Filter;

}

export const initialState: IResearchInitialState = {
  loading: false,
  data: [],
  errors: null,
  count: 0,
  pages: 0,
  types: [],

  filter: {
    currentPage: 1,
    pageSize: 10,
    searchQuery: '',
    nextPage: null,
    ulid: '',
    tab: 'all',
    cancelled: '',
    bio: '',
    method: '',
    marker: '',
    executor: '',
    status: '',
    priority: '',
  }
}

const researchSlice = createSlice({
  name: 'research',
  initialState,
  reducers: {
    resetResearchData: state => {
      state.loading = false;
      state.data = [];
      state.count = 0;
      state.pages = 0;
      state.types = [];
      state.filter = {};
      state.errors = null
    },
    getResearch: state => {
      state.loading = true;
    },
    getResearchesSuccess: (state, {payload}) => {
      state.loading = false;
      state.data = payload.data;
      state.count = payload.count;
      state.types = payload.types;
      state.pages = payload.pages;
      state.filter.currentPage = +(payload?.currentPage ?? 1);
      state.errors = null
    },
    getResearchesFailure: (state, {payload}) => {
      state.loading = false;
      state.errors = payload;
    },
    setCurrentResearchPage: (state, {payload}) => {
      state.filter.currentPage = +payload;
    },
    setResearchPageSize: (state, {payload}) => {
      state.filter.pageSize = +payload;
    },
    setResearchSearchQuery: (state, {payload}) => {
      state.filter.searchQuery = payload;
    },
    setResearchNextPage: (state, {payload}) => {
      state.filter.nextPage = +payload;
    },
    setResearchTabFilter: (state, { payload }) => { 
      state.filter.tab = payload;
    },
    setResearchPageSettings: (state, { payload }) => { 
      let filterObj:{ [key: string]: string } = {};
      let {currentPage, pageSize, nextPage, tab} = payload;
      if(currentPage) filterObj.currentPage = currentPage;
      if(pageSize)    filterObj.pageSize = pageSize;
      if(tab)         filterObj.tab = tab;
      if(nextPage || nextPage === null)    filterObj.nextPage = nextPage;
      if(Object.keys(filterObj)?.length) state.filter = {...state.filter,...filterObj}
    },
    setResearchFilterQuery: (state, { payload }) => { 
      let {dateCreatedGte, dateCreatedLte, status, priority, type, executor,cancelled, bio, method, marker} = payload;
      state.filter.dateCreatedGte = dateCreatedGte ?? '';
      state.filter.dateCreatedLte = dateCreatedLte ?? '';
      state.filter.executor = executor ?? '';
      state.filter.cancelled = cancelled ?? '';
      state.filter.priority = priority ?? '';
      state.filter.status = status ?? '';
      state.filter.type = type ?? '';
      state.filter.bio = bio ?? '';
      state.filter.method = method ?? '';
      state.filter.marker = marker ?? '';
      state.filter.executor = executor ?? '';
    },
    resetResearchSearchData : state => {
      state.loading = false;
      state.errors = null;
      state.filter = {
        currentPage: 1,
        pageSize: 10,
        searchQuery: '',
        nextPage: null,
        ulid: '',
        tab: 'all',
        cancelled: '',
        bio: '',
        method: '',
        marker: '',
        executor: '',
        status: '',
        priority: '',
      };
    }
    
  }
})

export const {
  setResearchPageSettings,
  setResearchFilterQuery,
  resetResearchData,
  getResearch,
  getResearchesSuccess,
  getResearchesFailure,
  setResearchNextPage,
  setResearchPageSize,
  setCurrentResearchPage,
  setResearchSearchQuery,
  setResearchTabFilter,
  resetResearchSearchData,
} = researchSlice.actions;
export default researchSlice.reducer;