import {FC, ReactNode, useCallback, useMemo} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  HeaderItem,
  HeaderWrapper,
  ItemsWrapper, RowItem,
  RowItemWrapper,
  RowWrapper,
  TTable,
  TTableWrapper,
  TWrapper,
} from './styles';


export interface IColumn {
  header: string | JSX.Element | ((options?: any) => ReactNode | string | number);
  columnWidth: string;
  renderer: (item: any) => ReactNode | string | number;
  order?: { name: string };
  position?: string;
  margin?: string;
  id: number;
}

interface ITable<T> {
  columns: IColumn[];
  items: T[];
  hasPermit?:boolean;
}

const TableHeader = ({id, header, columnWidth}: any) => {
  return (
    <HeaderItem key={id} columnWidth={columnWidth}>{header}</HeaderItem>
  )
}

export const methodsForUrl: { [index: number]: string; } = {
  1: 'ihc',
  2: 'fish',
  3: 'pcr',
  4: 'ss',
  5: 'fa',
}

const ExaminationTable: FC<ITable<any>> = ({columns, items,hasPermit}) => {
  

  const { id: referralULID, method: methodUrlParam } = useParams();
  const navigate = useNavigate();

  
  const ExperimentId = useMemo(()=>{
    let id = 0;
    if(methodUrlParam)  id = parseInt(methodUrlParam) ?? 0;
    
    return id;
  },[methodUrlParam]);

  const getNextReferral = useCallback((item:any)=>{
    if(!hasPermit) return;
    if(ExperimentId !== item.id){ 
      let pathToExamination = `${item?.id}_${methodsForUrl[item.method]}_${item?.marker.toLowerCase().replace('/','#')}`;
      if(item.status === 'New') pathToExamination = 'referral';
      navigate(`/molecular-profiling/detail/${referralULID}/${pathToExamination}`);
    }
  },[referralULID,navigate,ExperimentId,hasPermit]);

  return (
    <>
      <TWrapper>
        <TTableWrapper>
          <TTable>
            <HeaderWrapper>
              {columns?.map(({id, header, columnWidth},index) => (
                <TableHeader key={id+index} id={id} header={header} columnWidth={columnWidth}/>
              ))}
            </HeaderWrapper>
            <RowWrapper>
              <ItemsWrapper>
                {items?.map((item,index) => {
                  return (
                    <RowItemWrapper key={item.ulid + `${index}RowItemWrapper`} onClick={() => getNextReferral(item)} selected={item.id === ExperimentId}>
                      {columns.map(({renderer, columnWidth}, index) => (
                        <RowItem key={`${index}RowItem` + item.ulid} columnWidth={columnWidth} selected={item.id === ExperimentId}>
                          {renderer(item)}
                        </RowItem>
                      ))}
                    </RowItemWrapper>
                  )
                })}
              </ItemsWrapper>
            </RowWrapper>
          </TTable>
        </TTableWrapper>
      </TWrapper>
    </>
  );
};

export default ExaminationTable;
