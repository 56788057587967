import {FC} from "react";
import CodeTableCell from "./CodeTableCell";
import EditableTableCell from "./EditableTableCell";
import { TCustomRow } from "../styled";

type PropsType = {
    row: any,
    index: number,
    block?: any,
    slide?: any,
    toolTipActive: any,
    setToolTipActive: any,
    tooltipData: any,
    isEditPageActive: boolean,
    handleAddNewNumber: (type:string, data:any) => void;
    newBioMatNumbers: any,
    modalCellId: string | null,
    setModalCellId: any,
    cellIdBlock?: string,
    cellIdSlide?: string,
    blockErrorsArr?: string[],
};

const SlideRow: FC<PropsType> = (props) => {
    const { row,
            index,
            block,
            slide,
            toolTipActive,
            setToolTipActive,
            tooltipData,
            isEditPageActive,
            handleAddNewNumber,
            newBioMatNumbers,
            modalCellId,
            setModalCellId,
            cellIdBlock,
            cellIdSlide,
            blockErrorsArr } = props;

    let organTitle = `${index + 1}. ${row.organ}`;
    let blokCode:string = block?.code ?? '';
    const isBlockErr = (blockErrorsArr ?? []).includes(blokCode)

    return (
        <TCustomRow key={row}>
            <td className='wrapWords maxWidth20'>{organTitle}</td>
            <CodeTableCell
                block={block}
                color={'lightViolet'}
                toolTipActive={toolTipActive}
                setToolTipActive={setToolTipActive}
                tooltipData={tooltipData} />
            <EditableTableCell
                block={block}
                color={'lightViolet'}
                key={`block_${block.code}_${blockErrorsArr}`}
                cellIdBlock={cellIdBlock}
                modalCellId={modalCellId}
                setModalCellId={setModalCellId}
                rightOfForm={3}
                transform={50}
                isEditPageActive={isEditPageActive}
                handleAddNewNumber={handleAddNewNumber}
                newBioMatNumbers={newBioMatNumbers}
                isError={isBlockErr}
            />
            <CodeTableCell
                slide={slide}
                color={'lightOrange'}
                toolTipActive={toolTipActive}
                setToolTipActive={setToolTipActive}
                tooltipData={tooltipData} />
            <EditableTableCell
                slide={slide}
                color={'lightOrange'}
                key={'slide'+ slide.code}
                cellIdSlide={cellIdSlide}
                modalCellId={modalCellId}
                setModalCellId={setModalCellId}
                rightOfForm={0}
                isEditPageActive={isEditPageActive}
                handleAddNewNumber={handleAddNewNumber}
                newBioMatNumbers={newBioMatNumbers}
                isError={false}
            />
        </TCustomRow>
    );
};

export default SlideRow;