export enum VARIANT {
  DEFAULT = 'default',
  TRANSPARENT = 'transparent',
  LINK = 'link',
  BLUE = 'blue',
}

export enum SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}