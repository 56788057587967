import { FC } from 'react';
import { LinkProps } from 'react-router-dom';
import { TLink } from './styled';
import { ReactComponent as LeftArrowIcon } from '../../../icons/left-arrow.svg';

export interface IBackLink extends LinkProps {
  className?: string;
}

const BackLink: FC<IBackLink> = ({ children, ...restProps }) => (
  <TLink {...restProps}>
    <LeftArrowIcon />
    {children}
  </TLink>
);

export default BackLink;
