import styled, { css } from 'styled-components';
import Button from '../../components/shared/Button';
import Tooltip from "../../components/shared/Tooltip";

interface ISuccessBlock {
  sendToPrinter?: boolean;
  successEmail?: boolean;
}

export const TBlock = styled.div`
  padding: 6vh;
  width: 100%;
  height: 88vh;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  box-sizing: content-box;
`

export const TWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 40px 40px 40px;
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #EAEAEB;
`;

export const TRowWr = styled.div<{ direction?:string }>`
  display: flex;
  justify-content:  ${({direction}) => direction ? direction : 'space-around'};
  width: 100%;
  padding-bottom: 16px;
`;

export const TEditButton = styled.button<{ active?: boolean }>`
  width: max-content;
  padding: 0 10px;
  height: 20px;
  margin-right: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: ${({ theme,active }) => active ? theme.colors.white : theme.colors.blue[200]};
  background-color: ${({ theme,active }) => active ? theme.colors.blue[200] : theme.colors.white};

  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
  
  border-radius: 10px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.blue[200] };
  border-width: 1px;

  &:disabled {
    cursor: default;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]} !important;
    color: ${({ theme }) => theme.colors.gray[200]};
  }

  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.product.primaryHover};
    color: ${({ theme }) => theme.colors.white };
    border-color: ${({ theme }) => theme.colors.product.primaryHover};
  }

  &:active, &:focus {
    outline-style: none;
    outline-width: 0px !important;
    outline-color: none !important;

  }
  &::-moz-focus-inner {
    border: 0 !important;
  }
`;

export const TTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TTitle = styled.h1`
  font-weight: 600;
  font-size: 34px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black[200]};
`;

export const TTopButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 24px;
  }

  & > * {
    margin-right: 0;
  }
`;

export const TUID = styled.p<{userUlid: string}>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: ${({userUlid}) => userUlid ? '76px' : '12px'};
  margin-top: 12px;
`;

export const TWarningText = styled.p`
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.alert.warning};
  width: 522px;
  margin-bottom: 64px;
`;

export const TModalContent = styled.div`
  padding: 20px 0;
  color: #000000;
  box-sizing: border-box;
`;

export const TModalTitle = styled.h1`
  margin: 20px 52px;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
`;

export const TModalSubtitle = styled.p<{ align?: string; emailConfirmed?: boolean; saveData?: boolean; dataDone?: boolean}>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 28px 96px 24px;
  text-align: ${({ align = 'left' }) => align};
  color: ${({ theme }) => theme.colors.black[200]};

  ${({ emailConfirmed }) => emailConfirmed && css`
    margin: 32px 62px 16px;
  `}

  ${({ saveData }) => saveData && css`
    margin: 20px 50px 52px;
  `}

  ${({ dataDone }) => dataDone && css`
    margin: 32px 72px 16px;
    text-align: center;
  `}
  
`;

export const TModalList = styled.ul`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 16px 80px 0;
  padding: 0;
  list-style: none;
  color: ${({ theme }) => theme.colors.black[200]};

  & > li {
    margin-bottom: 16px;
    position: relative;
    margin-left: 10px;
  }

  & > li:last-child {
    margin-bottom: 0;
  }

  & > li::before {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.alert.status};
    display: inline-block;
    margin-right: 14px;
    position: absolute;
    left: -28px;
    top:6px;
  }
`;

export const TButton = styled(Button)`
  margin: 50px auto 72px;
`;

export const TButtonsGridContainer = styled.div<{ direction?:string }>`
  display: flex;
  grid-row-start: 6;
  grid-row-end: 6;
  grid-column-start: 2;
  grid-column-end: 4;
  justify-content: ${({direction}) => direction ? direction : 'end'};;
  & > * {
    margin-right: 36px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`

export const TButtonsContainer = styled.div<{ direction?:string }>`
  display: flex;
  width: 100%;
  justify-content: ${({direction}) => direction ? direction : 'center'};
  margin-bottom: 70px;

  & > * {
    margin-right: 40px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export const TSuccessBlock = styled.div<ISuccessBlock>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ sendToPrinter }) => sendToPrinter && css`
    margin-top: 70px;
  `};

  ${({ successEmail }) => successEmail && css`
    margin-top: 20px;
  `}
`;

export const TIconBlock = styled.div`
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
`;

export const TTooltipEdit = styled(Tooltip)`
  border: 2px solid black;
  width: max-content;
  max-width: max-content;
  padding: 18px 30px 18px 30px !important;
`;

export const TButtonBlock = styled.div`
  width: 266px;
  margin: 46px auto 46px;
`

export const SupportContactWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SupportContactBlock = styled.div`
  width: 485px;
`
