import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from '../../components/forms/LoginForm';
import { ROUTES } from '../../constants/routes';
import AuthPageContainer from '../../components/shared/AuthPageContainer/AuthPageContainer';
import { getErrors, getLoading, getPermissions, getTokens } from '../../store/auth/selectors';
import { signIn } from '../../store/auth/thunkActions';
import { 
  loadLocalStorageData, 
  resetLocalStorageData, 
  saveLocalStorageData } from '../../utils/localStorageHelpers';

async function handleLocalStorageSession() {
  let isSessionEnded:boolean = false;
  let localStorageSessionStarted: string = await loadLocalStorageData('dataLastUpdate');

  if (!localStorageSessionStarted) {
    await saveLocalStorageData('dataLastUpdate', Date.now().toString());
  } else {
    isSessionEnded = (Date.now() - +localStorageSessionStarted) > 3_600_000;
  }
  if (isSessionEnded) resetLocalStorageData();
}

const LoginPage: FC = () => {
  const errors = useSelector(getErrors);
  const tokens = useSelector(getTokens);
  const loading = useSelector(getLoading);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const permissions = useSelector(getPermissions);

  const submitForm = useCallback((data) => {
    dispatch(signIn(data));
  }, [dispatch]);

  useEffect(() => {
    if(permissions?.permissionAccess){
      let {advisory,geneticistExecutor,labAssistant,morphologistExecutor} = permissions;
      let {doctor,geneticistCoordinator,morphologistBasic,registrar} = permissions;
      let referralViewFirst = [doctor,geneticistCoordinator,morphologistBasic,registrar]?.some((val:boolean) => val);

      if((advisory || geneticistExecutor || labAssistant || morphologistExecutor) && !referralViewFirst){
        navigate(ROUTES.RESEARCH, { replace: true });
      }else {
        navigate(ROUTES.REFERRALS, { replace: true });
      }
    }
    if (tokens) {
      handleLocalStorageSession();
    }
  }, [tokens, navigate, permissions])

  return (
    <AuthPageContainer>
      <LoginForm errors={errors} formSubmit={submitForm} loading={loading} />
    </AuthPageContainer>
  );
}

export default LoginPage;
