import { Dispatch } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { getMarkers, getMarkersFailure, getMarkersSuccess,
  getMarkerTypesList, getMarkerTypesListSuccess, getMarkerTypesListFailure } from './';
import { loadLocalStorageData, saveLocalStorageData } from '../../utils/localStorageHelpers';
import { ReferralMarkerPriority } from './model';

export const fetchMarkers = (access: string, id: number, bio?: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getMarkers());
  const savedData = await loadLocalStorageData('registry/marker-priority/list/');
  let getLocalization;
  if(savedData){ 
    getLocalization = savedData.filter((marker:ReferralMarkerPriority) => marker.localization === id);
    return dispatch(getMarkersSuccess(getLocalization));
  }

  try {
    const response = await axiosInstance(access).get(`registry/marker-priority/list/`);
    const data = response.data.filter((marker:ReferralMarkerPriority) => marker.localization === id);
    await saveLocalStorageData('registry/marker-priority/list/', response.data);
    
    dispatch(getMarkersSuccess(data));
  } catch (error: any) {
    dispatch(getMarkersFailure(error?.response?.data));
  }
}

export const fetchAllMarkers = (access: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getMarkers());

  try {
    const response = await axiosInstance(access).get(`registry/marker-priority/list/`);
    const data = response.data;
    await saveLocalStorageData('registry/marker-priority/list/', response.data);
    
    dispatch(getMarkersSuccess(data));
  } catch (error: any) {
    dispatch(getMarkersFailure(error?.response?.data));
  }
}

export const fetchMethodTypeList = (access: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getMarkerTypesList());
  const savedData = await loadLocalStorageData('registry/method/list/');
  if(savedData){
    return dispatch(getMarkerTypesListSuccess(savedData));
  }

  try {
    const response = await axiosInstance(access).get(`registry/method/list/`);
    await saveLocalStorageData('registry/method/list/', response.data);

    dispatch(getMarkerTypesListSuccess(response.data));
  } catch (error: any) {
    dispatch(getMarkerTypesListFailure(error?.response?.data));
  }
}