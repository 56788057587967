import styled, {css} from 'styled-components';

interface ITitle {
 smallSizeText?: boolean;
}

export const TTitle = styled.h1<ITitle>`
  text-align: center;
  font-weight: 600;
  margin-bottom: 36px;

  ${({smallSizeText}) => smallSizeText && css`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  `}
`;
