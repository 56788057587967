import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokens } from '../../../store/auth/selectors';
import { getLocalizations } from '../../../store/localizations/selectors';
import { fetchLocalizations } from '../../../store/localizations/thunkActions';
import { TClinicalDataWr, TRowWr } from './styled';
import NotEditedInput from '../../../components/shared/NotEditedInput';

interface IComponentProps {
  data?:any;
  [index: string]: any;
}

const ClinicalData: FC<IComponentProps> = (data) =>{  
  const dispatch = useDispatch();
  const localizations = useSelector(getLocalizations);
  const tokens = useSelector(getTokens);
  
  useEffect(() => {
    if (!localizations.length) {
      tokens?.access && dispatch(fetchLocalizations(tokens.access));
    }
  }, [tokens?.access, localizations, dispatch]);

  const patientLocalization = useMemo(() => {
    if(!data?.diagnosis?.localization) return '';
    if(!localizations.length) return data?.localization;
    let localizationData = localizations.find( localization => localization.id === data?.diagnosis?.localization);
    return localizationData?.typeRus ?? '';

  },[localizations,data?.diagnosis?.localization,data?.localization]);

  const [standartTNM,standartStage] = useMemo(()=> {
    if(!data?.diagnosis?.clinical) return ['',''];
    let {mCategory,mSub,nCategory,nSub,tCategory,tSub,stage,tnmNonApplicable,tnmUnknown,stageNonApplicable,stageUnknown} = data?.diagnosis?.clinical; 
    let TNM_Applicable = tnmNonApplicable ? 'Неприменимо' : '';
    let TNM_Unknown = tnmUnknown ? 'Не установлена' : '';
    let TNM = TNM_Applicable || TNM_Unknown ? TNM_Applicable || TNM_Unknown : `${tCategory ?? ''}${tSub ?? ''}${nCategory ?? ''}${nSub ?? ''}${mCategory ?? ''}${mSub ?? ''}`;

    let Stage_Applicable = stageNonApplicable ? 'Неприменимо' : '';
    let Stage_Unknown = stageUnknown ? 'Не установлена' : '';
    let TNMStage = Stage_Applicable || Stage_Unknown ? Stage_Applicable || Stage_Unknown : (stage ?? '')?.replace('--','');

    return [TNM,TNMStage];
  },[data]);

  return (
    <TClinicalDataWr>
      <NotEditedInput width={'100%'} label='Локализация'>{patientLocalization ?? ''}</NotEditedInput>
      <NotEditedInput width={'100%'} label='Диагноз по МКБ 10'>{data?.diagnosis?.clinical?.icd_10 ?? ''}</NotEditedInput>
      <NotEditedInput width={'100%'} label='Клинический диагноз'>{data?.diagnosis?.clinical?.description ?? ''}</NotEditedInput>
      <TRowWr>
        <NotEditedInput width={'49%'} label='Классификация TNM'>{standartTNM ?? ''}</NotEditedInput>
        <NotEditedInput width={'49%'} label='Стадия'>{standartStage ?? ''}</NotEditedInput>
      </TRowWr>
      <NotEditedInput width={'100%'} label='Гистологическое заключение'>{data?.diagnosis?.histological?.description ?? ''}</NotEditedInput>
    </TClinicalDataWr>
  )
}
export default ClinicalData;