import styled from 'styled-components';

export const TTable = styled.table`
  width: 100%;
  
  border-spacing: 0;
  border: 1px solid ${({ theme }) => theme.colors.gray[500]};
  border-radius: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${({ theme }) => theme.colors.black[200]};

  .blue {
    background-color: ${({ theme }) => theme.colors.blue[50]};
  }

  .violet {
    background-color: ${({ theme }) => theme.colors.violet[100]};
  }

  .lightViolet {
    background-color: ${({ theme }) => theme.colors.violet[50]};
  }

  .orange {
    background-color: ${({ theme }) => theme.colors.orange[50]};
  }

  .lightOrange {
    background-color: ${({ theme }) => theme.colors.orange[25]};
  }

  tr.center th{
    text-align: center;
  }

  tr.center td{
    text-align: center;
  }
  .center {
    text-align: center;
  }
  .head.noRadius {
    border-radius: 0px;
  }

  .head.leftTopRadius {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0;
  }
  .head.rigthTopRadius {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0;
  }
  .head.leftBottomRadius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 10px;
  }
  .head.rigthBottomRadius {
    border-top-right-radius: 0;
    border-bottom-right-radius: 10px;
  }

  .mb20 {
    margin-bottom: 20px;
  }
  .mb40 {
    margin-bottom: 40px;
  }

  th {
    text-align: left;
    padding-left: 16px;
    :last-child {
      border-top-right-radius: 10px;
    }
    :first-child {
      border-top-left-radius: 10px;
    }
  }

  th, td {
    position: relative;
    height: 100%;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient( to bottom, transparent,#CECECE,transparent) 1 100%;
    border-right: none;

    :first-child {
      border-left: none;
    }

    ::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(90deg,transparent,#CECECE 50%,transparent);
    }

  }

  td:last-child {
    border-bottom-right-radius: 10px;
  }
`

export const TThead = styled.thead`
  height: 51px;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray[600]};
  background-color: ${({ theme }) => theme.colors.violet[100]};
  & :first-child, td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0;
  }
  & :last-child, td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0;
  }
  .center {
    text-align: center;
  }
  .violet, th.violet {
    background-color: ${({ theme }) => theme.colors.violet[100]};
  }
  .wrapWords {
    word-wrap: normal;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap; 
    white-space: -pre-wrap; 
    white-space: -o-pre-wrap; 
  }
  .maxWidth10 {
    
    width: 10%;
    min-width: 10%;
    max-width: 10%;
  }
  .maxWidth20 {
    
    width: 20%;
    min-width: 20%;
    max-width: 20%;
  }
  .maxWidth30 {
    
    width: 30%;
    min-width: 30%;
    max-width: 30%;
  }
  .maxWidth40 {
    
    width: 40%;
    min-width: 40%;
    max-width: 40%;
  }
  .maxWidth50 {
    
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }
`

export const TTBody = styled.tbody`
  box-sizing: border-box;
  position: relative;
  tr {
    height: 52px;
    width: 100%;
  }
  td {
    padding: 0 16px;
  }
  tr:last-of-type {
    td::before {
      background-image: none;
    }
  }
  tr:not(:last-of-type) td{
    border-bottom-right-radius: 0;
  }
  tr.error, td.error {
    border: 1px solid ${({ theme }) => theme.colors.alert.warning};
    > div:first-child {
      border-color: transparent;
    }
  }
  td.noRadius {
    border-radius: 0px;
  }
  .wrapWords {
    word-wrap: normal;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap; 
    white-space: -pre-wrap; 
    white-space: -o-pre-wrap; 
  }
  .maxWidth10 {
    width: 10%;
    min-width: 10%;
    max-width: 10%;
  }
  .maxWidth20 {
    width: 20%;
    min-width: 20%;
    max-width: 20%;
  }
  .maxWidth30 {
    width: 30%;
    min-width: 30%;
    max-width: 30%;
  }
  .maxWidth40 {
    width: 40%;
    min-width: 40%;
    max-width: 40%;
  }
  .maxWidth50 {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }

  td.leftTopRadius {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0;
  }
  td.rigthTopRadius {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0;
  }
  td.leftBottomRadius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 10px;
  }
  td.rigthBottomRadius {
    border-top-right-radius: 0;
    border-bottom-right-radius: 10px;
  }
`

