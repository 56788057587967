import styled from 'styled-components';

export const TIconButton = styled.button<{ invert?: boolean }>`
  width: 48px;
  height: 48px;
  display: block;
  border-radius: 10px 10px 2px 10px;
  background-color: ${({ invert, theme }) => invert ? 'transparent' : theme.colors.product.primaryActive};
  border: 2px solid ${({ theme }) => theme.colors.product.primaryActive};
  
  &:hover {
    background-color: ${({ invert, theme }) => invert ? 'transparent' : theme.colors.product.primaryHover};
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
    background-color: ${({ theme, invert }) => invert ? 'transparent' : theme.colors.neutral.disable};
    border-color: ${({ theme, invert }) => invert ? theme.colors.gray[200] : theme.colors.product.primaryActive};
  }
`;
