import { FC, useCallback, useLayoutEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DoubleSelect from "../../../../components/shared/DoubleSelect"
import InputDayPicker from "../../../../components/shared/InputDayPicker";
import { disableAllexceptLastMonth } from "../../../../utils/dateHelpers";
import { TEditButton, TRowWr, TSectionTitle, TTableWrapper, TWrapper } from "../styled"
import { IConclusionData } from "../../../../store/molProConclusion/model";
import { postOverWriteConclusionModules } from "../../../../store/molProConclusion/thunkActions";
import { useDispatch, useSelector } from "react-redux";
import { getTokens } from "../../../../store/auth/selectors";

interface IComponentProps {
  referralID: string;
  signatoryList: IOptionData[];
  calbackDataForSend: () => void;
  stepComplite: boolean;
  hasPermit: boolean;
  defaultData?: IConclusionData | null;
  [index: string]: any;
}

interface IInputItems {
  value: string;
  label: string;
  ulid?: string;
}

interface IOptionData {
  firstColumn: IInputItems[];
  secondColumn: IInputItems[];
}

export const ResponsiblePersons: FC<IComponentProps> = ({ signatoryList, stepComplite, calbackDataForSend, defaultData, hasPermit, referralID }) => {
  const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm({ mode: "onSubmit" });

  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [isNoSignatory, setSignatoryErr] = useState<boolean>(false);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const [conclusionSignatorys, setConclusionSignatorys] = useState<IOptionData[]>();
  const [savedConclusionSignatorys, setSavedConclusionSignatorys] = useState<IOptionData[]>([]);
  const [signatoryOptions, setSignatoryOptions] = useState<IOptionData[]>([...signatoryList]);


  const signatorysConclusion = useCallback(async (signatorys: IOptionData[]) => {
    if (!signatorys?.length) return;

    let selectedValues = new Set();
    signatorys.forEach(inspectr => selectedValues.add(inspectr.secondColumn[0].label));
    let filteredOption = signatoryList.filter((value: IOptionData) => !selectedValues.has(value.secondColumn[0].label));

    setSignatoryOptions(filteredOption);
    await setConclusionSignatorys(signatorys);
  }, [setConclusionSignatorys, signatoryList, setSignatoryOptions]);

  //Load saved options
  const restoreDefaults = useCallback(async () => {
    if (defaultData?.moduleSignatory?.restoreDefaultData) {
      let { incpectorsList, dateConfigured, comments } = { ...defaultData?.moduleSignatory?.restoreDefaultData }
      if(incpectorsList?.length) setSavedConclusionSignatorys(incpectorsList);
      setValue('dateConfigured', !!dateConfigured ? new Date(dateConfigured) : new Date());
      setValue('comments', comments ?? '');
    }else{
      setValue('comments', '');
      setValue('dateConfigured', new Date());
    }
    setResetFormKey(Date.now());
  }, [defaultData, setValue, setResetFormKey,setSavedConclusionSignatorys]);


  const handleSaveData = useCallback(async () => {
    if (stepComplite || !hasPermit) return;
    const dataForSave: { [index: string]: any; } = {...defaultData ?? {}} ;
    let sortedSignatoryOptionData: IOptionData[] = [];

    let incpectorsList: IOptionData[] = conclusionSignatorys ?? [];
    let [comments, dateConfigured] = getValues(['comments', 'dateConfigured']);

    let isSignatoryEmpty: boolean = false;
    sortedSignatoryOptionData = await incpectorsList.filter((value: IOptionData) => {
      let firstColumn = value?.firstColumn?.every((fc: IInputItems) => fc.label !== 'empty');
      let secondColumn = value?.secondColumn?.every((fc: IInputItems) => fc.label !== 'empty');
      if (!firstColumn || !secondColumn) isSignatoryEmpty = true;
      return firstColumn || secondColumn;
    })

    setSignatoryErr(isSignatoryEmpty);
    if (isSignatoryEmpty) return;

    const restoreDefaultData = {
      incpectorsList: sortedSignatoryOptionData,
      dateConfigured: new Date(dateConfigured).toUTCString(),
      comments,
    }

    dataForSave['moduleSignatory'] = { options: signatoryList, restoreDefaultData }

    if(tokens?.access) dispatch(postOverWriteConclusionModules(tokens?.access,referralID,dataForSave));

    calbackDataForSend();

    setEdit(false);
  }, [setSignatoryErr, setEdit, getValues,defaultData,conclusionSignatorys, referralID, hasPermit,dispatch,tokens?.access,
    calbackDataForSend, stepComplite, signatoryList]);

  //handle edit tab button
  const handleEditPage = useCallback(async () => {
    if (stepComplite || !hasPermit) return;
    if (isEdit) {
      await restoreDefaults();
      setSignatoryErr(false);
      setResetFormKey(Date.now());
      return setEdit(false);
    }
    setResetFormKey(Date.now());
    setEdit(true);
  }, [isEdit, setEdit, setResetFormKey, setSignatoryErr, stepComplite, hasPermit,restoreDefaults]);

  useLayoutEffect(() => {
    restoreDefaults();
    return () => setSavedConclusionSignatorys([]);
  }, [restoreDefaults, setValue, setSavedConclusionSignatorys]);

  // const isShowComments = useMemo(() => {
  //   if(!stepComplite) return true;
  //   let comments = defaultData?.moduleSignatory?.restoreDefaultData?.comments
  //   if(comments) return !!(comments?.trim());
  //   return false;

  // },[defaultData,stepComplite]);


  return (
    <TTableWrapper onSubmit={handleSubmit(data => handleSaveData())}>
      {!stepComplite && <TRowWr direction="flex-end" >
        {hasPermit && <TEditButton type="button" onClick={handleEditPage} active={!isEdit} disabled={stepComplite}>
          {!isEdit ? 'Редактировать' : 'Отменить'}
        </TEditButton>}
        {isEdit && !stepComplite &&
          <TEditButton type="submit" disabled={!isEdit} active={isEdit}>
            Сохранить
          </TEditButton>
        }
      </TRowWr>}
      <TSectionTitle>Ответственные лица</TSectionTitle>
      <TWrapper>
        <DoubleSelect
          key={resertFormKey}
          isEdit={isEdit}
          defaultPlaceholders={['Выберите Должность', 'Выберите ФИО']}
          isTextMode={true}
          isError={isNoSignatory}
          tableHeader={['Должность', 'ФИО']}
          optionData={signatoryOptions}
          calbackData={signatorysConclusion}
          defaultData={savedConclusionSignatorys}
          isSelectAllOption={true}
          addCustomOption={true}
        />
        <Controller
          control={control}
          name='dateConfigured'
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <InputDayPicker
              bordered
              id='dateOfAcquisition'
              date={value}
              onChange={onChange}
              dateFormat='dd.MM.yyyy'
              className="dayPicker"
              label='Дата выдачи заключения'
              disabled={!isEdit}
              error={invalid || !!errors?.['dateConfigured']}
              marginTop={12}
              filterDate={disableAllexceptLastMonth}
              placeholderText={'Дата выдачи заключения'}
            />
          )}
        />
      </TWrapper>
    </TTableWrapper>)
}

export default ResponsiblePersons;