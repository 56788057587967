import { createSlice } from '@reduxjs/toolkit';
import { Filter, Referral, ReferralsData } from './model';
import { ErrorsType, Nullable } from '../../types';

export interface IReferralInitialState {
  loading: boolean;
  referral: Referral;
  referrals: ReferralsData;
  bloodId: Nullable<number>;
  errors: Nullable<ErrorsType>;
  createdReferralId: Nullable<string>;
  createdReferralUlid: Nullable<string>;
  filter: Filter;
  ambulatoryCard: any;
}

export const initialState: IReferralInitialState = {
  loading: false,
  referral: {} as Referral,
  referrals: {} as ReferralsData,
  errors: null,
  bloodId: null,
  createdReferralId: null,
  createdReferralUlid: null,
  filter: {
    currentPage: 1,
    pageSize: 10,
    searchQuery: '',
    nextPage: null,
    ulid: '',
    tab: '',
    dateCreatedGte: '',
    dateCreatedLte: '',
    status: '',
    priority: '',
    type: '',
    organization: '',
    cancelled: '',
  },
  ambulatoryCard: null,
};

const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => { state.filter.currentPage = payload },
    setPageSize: (state, { payload }) => { state.filter.pageSize = payload },
    setSearchQuery: (state, { payload }) => { state.filter.searchQuery = payload },
    setFilterQuery: (state, { payload }) => { 
      let {dateCreatedGte, dateCreatedLte, status, priority, type, organization,cancelled} = payload;
      state.filter.dateCreatedGte = dateCreatedGte ?? '';
      state.filter.dateCreatedLte = dateCreatedLte ?? '';
      state.filter.organization = organization ?? '';
      state.filter.cancelled = cancelled ?? '';
      state.filter.priority = priority ?? '';
      state.filter.status = status ?? '';
      state.filter.type = type ?? '';
    },
    setNextPage: (state, { payload }) => { state.filter.nextPage = payload },
    setTabFilter: (state, { payload }) => { state.filter.tab = payload },
    setPageSettings: (state, { payload }) => { 
      let filterObj:{ [key: string]: string } = {};
      let {currentPage, pageSize, nextPage, tab} = payload;
      if(currentPage) filterObj.currentPage = currentPage;
      if(pageSize)    filterObj.pageSize = pageSize;
      if(tab)         filterObj.tab = tab;
      if(nextPage || nextPage === null)    filterObj.nextPage = nextPage;
      if(Object.keys(filterObj)?.length) state.filter = {...filterObj,...state.filter}
    },
    getBloodId: state => { state.loading = true },
    resetBloodId: state => { state.bloodId = null },
    getBloodIdSuccess: (state, { payload }) => {
      state.bloodId = payload;
      state.loading = false;
    },
    getBloodIdFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload
    },
    getReferrals: state => { state.loading = true },
    getReferralsSuccess: (state, { payload }) => {
      state.referrals = payload;
      state.loading = false;
    },
    getReferralsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload
    },
    create: state => { state.loading = true },
    createSuccess: (state, { payload }) => {
      state.loading = false;
      state.createdReferralId = payload;
    },
    createUlidSuccess: (state, { payload }) => {
      state.loading = false;
      state.createdReferralUlid = payload;
    },
    createFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload
    },
    setResearchType: (state, { payload }) => {
      state.referral = { ...state.referral, ...payload };
      state.loading = false;
    },
    setPatient: (state, { payload }) => {
      state.referral = { ...state.referral, patient: payload };
      state.loading = false;
    },
    setReferralStatus: (state, { payload }) => {
      state.referral = { ...state.referral, status: payload };
      state.loading = false;
    },
    setRegistrar: (state, { payload }) => {
      state.referral = { ...state.referral, doctorReferral: payload };
      state.loading = false;
    },
    setPaymentType: (state, { payload }) => {
      state.referral = { ...state.referral, paymentType: payload };
      state.loading = false;
    },
    setSpeedType: (state, { payload }) => {
      state.referral = { ...state.referral, speedType: payload };
      state.loading = false;
    },
    setRegistrarDateOfAcquisition: (state, { payload }) => {
      state.referral = { ...state.referral, dateOfAcquisition: payload };
      state.loading = false;
    },
    setClinicalData: (state, { payload }) => {
      state.referral = { ...state.referral, diagnosis: payload };
      state.loading = false;
    },
    setBiomaterialData: (state, { payload }) => {
      state.referral = { ...state.referral, bioMaterial: payload };
      state.loading = false;
    },
    setMarkers: (state, { payload }) => {
      state.referral = { ...state.referral, markers: payload };
      state.loading = false;
    },
    setCommentary: (state, {payload}) => {
      state.referral = { ...state.referral, commentary: payload };
      state.loading = false;
    },
    resetReferralData: state => {
      state.referral = {} as Referral;
      state.createdReferralId = null;
      state.createdReferralUlid = null;
      state.bloodId = null;
      state.loading = false;
    },
    resetReferrals: state => {
      state.referrals = {} as ReferralsData;
      state.loading = false;
    },
    getValidateAmbulatoryCard: state => {
      state.loading = true;
      state.ambulatoryCard = null;
      state.errors = null;
    },
    getValidateAmbulatoryCardSuccess: (state, {payload}) => {
      state.loading = false;
      state.ambulatoryCard = payload;
      state.errors = null
    },
    getValidateAmbulatoryCardFailure: (state, {payload}) => {
      state.loading = false;
      state.errors = {payload}
    },
    resetReferralSearchData : state => {
      state.loading = false;
      state.errors = null;
      state.filter = {
        currentPage: 1,
        pageSize: 10,
        searchQuery: '',
        nextPage: null,
        ulid: '',
        tab: '',
        dateCreatedGte: '',
        dateCreatedLte: '',
        status: '',
        priority: '',
        type: '',
        organization: '',
        cancelled: '',
      };
      state.referrals = {} as ReferralsData;
    }
  },
});

export const {
  getBloodId,
  getBloodIdFailure,
  getBloodIdSuccess,
  resetBloodId,
  getReferrals,
  getReferralsFailure,
  getReferralsSuccess,
  create,
  createSuccess,
  createUlidSuccess,
  createFailure,
  setResearchType,
  setPatient,
  setRegistrar,
  setPaymentType,
  setSpeedType,
  setRegistrarDateOfAcquisition,
  setReferralStatus,
  setClinicalData,
  setBiomaterialData,
  setMarkers,
  setCommentary,
  resetReferralData,
  setCurrentPage,
  setPageSize,
  setSearchQuery,
  setFilterQuery,
  setNextPage,
  setTabFilter,
  setPageSettings,
  resetReferrals,
  getValidateAmbulatoryCard,
  getValidateAmbulatoryCardSuccess,
  getValidateAmbulatoryCardFailure,
  resetReferralSearchData,
} = referralSlice.actions

export default referralSlice.reducer;
