import { Examination, Speed } from "../../store/referral/model";
import { TIndicator, TPriorityField, TStatusTitle, TStatusWr, TTooltip } from "../ReferralPage/styled";
import { SPEED } from "../ReferralPage/constants";
import { format } from "date-fns";
import defaultTheme from "../../styles/theme";
import { statusLocale } from "../MolecularProfilingDetailsPage/AnalysisExecution/AnalysisExecution";
import { transformBloodId } from "../../utils/stringHelpers";

interface IDoctorWhoCompleted { 
  [key: string]: { fullname: string; shortName: string; title: string; } | undefined 
}

export const researchTableColumns = (doctorWhoCompleted: IDoctorWhoCompleted) => {

  return ([
    {
      header: 'Номер',
      renderer: (item: Examination) => <>{`${item.id}`.padStart(6, "0").replace(/(?=(?:.{3})*$)/g, ' ')}</>,
      columnWidth: '9%',
      id: 1,
    },
    {
      header: 'Маркер',
      renderer: (item: Examination) => item.marker ? item.marker : '-',
      columnWidth: '8%',
      id: 2,
    },
    {
      header: 'Биоматериал',
      renderer: (item: Examination) => {
        if (!item?.bio?.length) return '--';
        // @ts-ignore
        let priority = item?.bio[0]?.material?.priority || item?.bio[1]?.material?.priority;
        if (priority && item?.bio?.length === 1) return `Блок К${priority}`;
        if (priority && item?.bio?.length > 1) return `Блок К${priority} + кровь`;
        if (!priority && item?.bio?.length) return 'Кровь'
        return '--';
      },
      columnWidth: '10%',
      id: 3,
    },
    {
      header: 'Маркировка',
      renderer: (item: Examination) => {
        if (!item?.bio?.length) return '--';
        if (item?.bio?.[0]?.type === 'Sub-Block') {
          //@ts-ignore
          return item?.bio?.[0]?.material?.cypher
        }

        if (item?.bio?.[0]?.type === 'Blood') {
          let bloodId = item?.bio?.[0]?.material?.bloodId;
          if (!bloodId) return '--';
          return transformBloodId(bloodId.toString());
        }
        return '--';

      },
      columnWidth: '9%',
      id: 4,
    },
    {
      header: 'Метод',
      // @ts-ignore
      renderer: (item: Examination) => item?.method?.nameRus ? <> {item?.method?.nameRus} </> : '-',
      columnWidth: '13%',
      id: 5,
    },
    {
      header: 'Исполнитель',
      renderer: (item: Examination) => (
        <>
          <TTooltip id={`patient-table-tooltip-${item.executor ?? item.id}`} place='top'>
            <p style={{
              fontWeight: 600,
              marginBottom: '4px',
              color: defaultTheme.colors.gray[500]
            }}>{item.executor ? doctorWhoCompleted?.[item.executor]?.fullname : 'Исполнителя нет'}</p>
            <p style={{
              fontWeight: 500,
              color: defaultTheme.colors.gray[500]
            }}>{item.executor ? doctorWhoCompleted?.[item.executor]?.title : ''}</p>
          </TTooltip>
          <div style={{ cursor: 'pointer' }}
            data-for={`patient-table-tooltip-${item.executor ?? item.id}`} data-tip=''>
            {item.executor ? doctorWhoCompleted?.[item.executor]?.shortName : '--'}
          </div>
        </>
      ),
      columnWidth: '11%',
      id: 6,
    },
    {
      header: 'Создано',
      renderer: (item: Examination) => item.dateCreated ? format(new Date(item.dateCreated), 'dd.MM.yyyy') : '-',
      columnWidth: '8%',
      id: 7,
    },
    {
      header: 'Статус',
      renderer: (item: Examination) =>
        <TStatusWr data-for={`patient-table-tooltip-${item.id}`} data-tip=''>

          {item.status === 'Cancelled' && (
            <TTooltip id={`patient-table-tooltip-${item.id}`} place='top'>
              <p style={{
                fontWeight: 500,
                marginBottom: '4px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: defaultTheme.colors.gray[500]
              }}>{`${item?.cancellationReason ?? ''}`}</p>
            </TTooltip>)}

          <TIndicator margin={8} status={statusLocale[item.status].code} />
          <TStatusTitle isSmallStatus>{statusLocale[item.status].rus}</TStatusTitle>
        </TStatusWr>,
      columnWidth: '12%',
      id: 8,
    },
    {
      header: 'Пациент',
      renderer: (item: Examination) => (
        <>
          <TTooltip id={`patient-table-tooltip-${item.examination.referral.patient.ulid}`} place='top'>
            <p style={{
              fontWeight: 500,
              marginBottom: '4px',
              color: defaultTheme.colors.gray[500]
            }}>{`${item.examination.referral.patient.firstName} ${item.examination.referral.patient.lastName} ${item.examination.referral.patient.middleName ?? ''}`}</p>
          </TTooltip>
          <div style={{ cursor: 'pointer' }} data-for={`patient-table-tooltip-${item.examination.referral.patient.ulid}`}
            data-tip=''>
            {`${item.examination.referral.patient.lastName} ${item.examination.referral.patient.firstName[0]}.${item.examination.referral.patient.middleName ? ` ${item.examination.referral.patient.middleName?.[0]}.` : ''}`}
          </div>
        </>
      )
      ,
      columnWidth: '13%',
      id: 9,
    },
    {
      header: 'Приоритет',
      renderer: (item: Examination) =>
        <TPriorityField isCommon={item.examination.referral.speedType === Speed.Common}>
          <span>{SPEED[item.examination.referral.speedType]}</span>
        </TPriorityField>,
      columnWidth: '7%',
      id: 10,
    },


  ])
};