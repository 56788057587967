import styled from "styled-components";
import { TMKB10Wrapper } from "../styled";


export const MKB10Wrapper = styled.div<{ hasError: boolean }>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  background: #FFF;
  border-radius: 10px;
  border: ${({ hasError }) => hasError ? `1px solid red` : 'none'};
`;

export const InputWrapper = styled.div`
  width: 91%;
  height: inherit;
`;

export const IconWrapper = styled.div`
  width: 9%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const IconDownWrapper = styled.div`
  transform: rotate(180deg);
  cursor: pointer;
`;


export const IconUpWrapper = styled.div`
  transform: rotate(0deg);
  cursor: pointer;
`;

export const FilteredListWrapper = styled(TMKB10Wrapper)`
  box-sizing: border-box;
  padding: 0 10px 10px 10px;
  height: max-content;
  max-height: 300px;
`;

export const FilteredListItem = styled.div`
  box-sizing: border-box;
  padding: 10px 0 0 0;
`;

export const ListItem = styled.span`
  display: block;
  width: 100%;
  padding-left: 5px;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
  i {
    color: ${({ theme }) => theme.colors.alert.warning};
    font-style: normal;
  }
`;

export const SecondRowComponentWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const SecondRowComponentItemName = styled.div`
  width: 90%;
  box-sizing: border-box;
  padding: 0 0 5px 40px;
  cursor: pointer;
  :hover {
    font-weight: 600;
  }
`;

export const SecondaryRowComponentItem = styled.div`
  box-sizing: border-box;
  padding: 0 0 5px 70px;
  cursor: pointer;
  :hover {
    font-weight: 600;
  }
`;

export const MKB10Title = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #777777;
  font-weight: 500;
  margin-bottom: 12px;
`;

export const MainRow = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 0 0 10px 10px;
  cursor: pointer;
`;

export const MKB10List = styled.div`
  padding: 10px;
`;

export const MainRowItem = styled.div`
  width: 97%;
`;

export const MainRowIconBlock = styled.div`
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.alert.warning};
  font-size: 14px;
`;

export const TSearchTextArea = styled.textarea<{width?:string}>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: ${({ width }) => width ? width : '100%'};
  border-radius: 10px;
  background-color: white;
  box-sizing: border-box;
  padding: 18px 30px 18px 16px;
  border: 1px solid transparent;
  outline: none;
  color: #1A1A22;
  overflow:hidden;
  resize:none;
`;

export const IlluminatedText = styled.span`
  color: red
`;
