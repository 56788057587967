import styled from 'styled-components';
import Input from '../../../components/shared/Input';
import Button from '../../../components/shared/Button';
import Tooltip from '../../../components/shared/Tooltip';
import FormErrorText from "../../../components/shared/FormErrorText";

function getBackgroundColorInput(backgroundColorBlock: string | undefined, theme: any) {
  switch (backgroundColorBlock) {
    case 'block':
      return theme.colors.violet[100];
    case 'slide':
      return theme.colors.orange[50];
    default:
      return '#fff'
  }
}

export const TBioMaterialDataWr = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  width: 100%;
  input {
    color: ${({ theme }) => theme.colors.black[200]};
    margin-top: 10px;
  }
  label {
    line-height: 20px;
  }
`

export const THeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 58px;
  color: ${({ theme }) => theme.colors.product.primaryActive};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: default;
`

export const TRowWr = styled.div<{ direction?: string }>`
  display: flex;
  justify-content:  ${({ direction }) => direction ? direction : 'space-between'};
  width: 100%;
  padding-bottom: 16px;
`

export const TDateOfCompletion = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[500]};
`

export const TEditButton = styled.button<{ active?: boolean }>`
  width: max-content;
  padding: 0 10px;
  height: 20px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue[200]};
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
  border: 0 !important;

  &:disabled {
    cursor: default;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]} !important;
    color: ${({ theme }) => theme.colors.gray[200]};
  }

  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.product.primaryHover};
  }

  &:active, &:focus {
    outline-style: none;
    outline-width: 0px !important;
    outline-color: none !important;

  }
  &::-moz-focus-inner {
    border: 0 !important;
  }
`
export const TBtnWr = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 12px;
  & button {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }
  & :last-child {
    margin-left: 2%;
  }
`
export const TButton = styled(Button)`
  width: 282px;
  border-color: ${({ theme }) => theme.colors.blue[200]};
  color: ${({ theme }) => theme.colors.blue[200]};
  margin-left: 12px;
`

export const CustomButton = styled(Button)`
  padding: 0;
`
export const TTableWr = styled.div`
  width: 100%;
  padding: 16px 0;
  margin-top: 24px;

  thead {
    tr {
      height: 52px;
      th {
        text-align: center;
      }
    }
    th.noBorderRadius {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .noBorderRadius {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`

export const TMenuButton = styled.img<{ active?: boolean, disabled?: boolean }>`
  display: inline-block;
  width: 18px;
  height: 14px;
  filter: ${({ active }) => active ? 'none' : 'grayscale(100%)'};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  :hover {
    filter: none;
  }
`;

export const TTableRowWR = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const TTooltip = styled(Tooltip)`
  padding: 17px 30px !important;
  width: max-content;
`

export const TEditableCell = styled.td`
  text-align: center;
  position: relative;
  box-sizing: border-box;
`

export const TCustomInput = styled(Input) <{ width?: string, background?: string }>`
  width: ${({ width }) => width ? width : '100%'};
  padding-bottom: 16px;
  & :read-only {
    cursor:default;
  }
  & input {
    padding: 18px;
    background-color: ${({ background }) => background ? background : '#fff'};
  }
`

export const TTooltipInput = styled(Input) <{ width?: string, background?: string, isBlockBorder?: boolean, backgroundColorBlock?: string, isError?: boolean }>`
    position: relative;
    width: ${({ width }) => width ? width : '100%'};

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[type="number"]:hover,
    input[type="number"]:focus {
      -moz-appearance: number-input;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    & :read-only {
      cursor: default;
    }

    & input {
      height: 40px;
      padding: 2px;
      background-color: ${({ backgroundColorBlock, theme }) => getBackgroundColorInput(backgroundColorBlock, theme)};
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      border: 1px solid ${({ error, theme, backgroundColorBlock }) => error
    ? theme.colors.alert.warning : backgroundColorBlock ? getBackgroundColorInput(backgroundColorBlock, theme) : theme.colors.gray[300]};
      
      ::placeholder {
        text-align: center;
        color: ${({ theme }) => theme.colors.gray[200]};
        opacity: 1;
      }

      ::-webkit-input-placeholder {
        text-align: center;
      }

      :-moz-placeholder {
        text-align: center;
      }

      ::-moz-placeholder {
        text-align: center;
      }

      :-ms-input-placeholder {
        text-align: center;
      }
    }
`;

export const TArrowWr = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 4px 0px;
  margin: 0 ${({ margin }) => margin}; 
`;

export const TTooltipButton = styled.button<{ active?: boolean }>`
    width: 55px;
    padding: 0 10px;
    height: 30px;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.product.primaryActive};
    outline-style: none;
    outline-width: 0px !important;
    outline-color: none !important;
    border: 0 !important;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-top: 15px; 
    margin-left: 30px;
    cursor: pointer;  

&:disabled {
  cursor: default;
  background-color: ${({ theme }) => theme.colors.gray[300]};
  border: 1px solid ${({ theme }) => theme.colors.gray[300]} !important;
  color: ${({ theme }) => theme.colors.white};
}

&:active, &:focus {
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;

}
&::-moz-focus-inner {
  border: 0 !important;
}
`
export const TCustomRow = styled.tr`
  position: relative;
`

export const TTooltipForm = styled.form<{ isOpenBlock?: boolean, isOpenSlide?: boolean, rightOfForm?: number, transform?: number }>`
  display: ${({ isOpenBlock, isOpenSlide }) => isOpenBlock || isOpenSlide ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: -128px;
  right: ${({ rightOfForm }) => rightOfForm}vw;
  padding: 16px;
  width:  677px;
  max-width: 708px;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  z-index: 5;
  border: 0.3px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 10px;
  transform: translate(${({ transform }) => transform}%);
  box-shadow: 1px -1px 6px 0px rgba(0, 0, 0, 0.07);
`

export const TTooltipFormHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  font-style: normal;
  font-variant-numeric: lining-nums proportional-nums;
  & b {
    text-align: left;
    font-style: normal;
    color: ${({ theme }) => theme.colors.gray[500]};
    font-weight: 600;
  }
  & i {
    text-align: left;
    font-style: normal;
    color: ${({ theme }) => theme.colors.gray[400]};
    font-weight: 500;
  }

`;
export const TTooltipFormContent = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-style: normal;
  justify-content: space-between;
  column-gap: 2px;
  width:  min-content;
`;

export const TTooltipFormGap = styled.div`
  width: 20px;
  height: 20px;
`;

export const TCloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  z-index: 2;
  path, circle {
    stroke: ${({ theme }) => theme.colors.gray[400]};
  }

  &:focus,
  &:hover {
    color: white;
    circle {
      stroke: ${({ theme }) => theme.colors.gray[400]};
      fill: ${({ theme }) => theme.colors.gray[400]};
    }
    path  {
      stroke: white;
    }
  }

  .ReactModal__Overlay--after-open & {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close & {
    opacity: 0;
  }
`;

export const TDiv = styled.div<{ isOpenBlock: boolean, isOpenSlide: boolean, error?: boolean; }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 32px);
  height: 46px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid ${({ theme, error }) => error ? theme.colors.alert.warning : 'transporent'};
  margin-left: -16px;
  
  :before {
    content: '';
    display: ${({ isOpenBlock, isOpenSlide }) => isOpenBlock || isOpenSlide ? 'block' : 'none'};
    position: absolute;
    margin: 0 auto;
    top: 2px;
    left: 50%;
    width: 16px;
    height: 16px;
    transform: rotate(45deg) translate(-70%);
    border-right: .3px solid ${({ theme }) => theme.colors.gray[300]};
    border-bottom: .3px solid ${({ theme }) => theme.colors.gray[300]};
    background-color: ${({ theme }) => theme.colors.gray[100]};
    z-index: 6;
}
`

export const TCustomTd = styled.td`
  background-color: ${({ theme }) => theme.colors.blue[50]};
`

export const TCustomError = styled(FormErrorText)`
  margin-top: 20px;
  text-align: center;
`

export const TDescriptionWr = styled.div<{ column: boolean }>`
  display: flex;
  flex-direction: ${({ column }) => column ? 'column' : 'row'};
  position: relative;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 20px;
  width: 100%;
  textarea {
    border: 1px solid ${({ theme }) => theme.colors.gray[500]};
  }
  .error textarea {
    border: 1px solid ${({ theme }) => theme.colors.alert.warning};
  }
  .description{
    width: ${({ column }) => column ? '100%' : '70%'};
  }

  .nothovered{
    textarea{
        :focus-visible,:hover,:active {
        border: 1px solid ${({ theme }) => theme.colors.gray[500]};
        outline: none;
      }
    }
  }
  .textarea-error{
    textarea{
      border-color: ${({ theme }) => theme.colors.alert.warning};
      :focus-visible,:hover,:active {
        border-color: ${({ theme }) => theme.colors.alert.warning};
        outline: none;
    }
  }
  }
  .implementer {
    position: relative;
    min-height: fit-content;
    width: calc(100% - 32px);
    border: 1px solid ${({theme}) => theme.colors.gray[500]};
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.gray[500]};
    margin-top: 52px;
    font-weight: 400;
    padding: 18px 16px;

    b {
      font-weight: 600;
    }
    ::before {
      content: 'Исполнитель';
      position: absolute;
      top: -28px;
      left: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${({theme}) => theme.colors.gray[500]};
    }
  }
`

