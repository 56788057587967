import {useCallback} from "react";
import {useFormContext} from "react-hook-form";

const useSelectMKB10Option = (setIsOpen: any, setIsActiveIcon: any, clearErrors: any, setMkb10Value: any) => {
  const { setValue } = useFormContext();

  const selectMKB10Option = useCallback((value) => {
    setValue('icd_10', value)
    // setMkb10Value(value)
    setIsOpen(false)
    setIsActiveIcon(false)
    clearErrors('icd_10')
  }, [setValue, setIsOpen, setIsActiveIcon, clearErrors]);

  return {
    selectMKB10Option,
  }

};

export default useSelectMKB10Option;