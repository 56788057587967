import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  TBottomLinkWr,
  TButton,
  TDescription,
  TFieldsContainer,
  TLink,
  TSubtitle,
  TTextEmail,
} from './styled';
import Input from '../../shared/Input';
import { SIZE, VARIANT } from '../../shared/Button';
import { getErrorMessage, validations } from '../../../utils/validations';
import FormTitle from '../../shared/FormTitle';
import FormContainer from '../../shared/FormContainer';
import { ROUTES } from '../../../constants/routes';
import { ErrorsType, Nullable } from '../../../types';
import { signUpType } from '../../../store/auth/thunkActions';
import Loader from '../../shared/Loader';

interface IRestorePasswordForm {
  formSubmit: (data: signUpType) => void;
  errors?: Nullable<ErrorsType>;
  isSubmitted: boolean;
}

const RestorePasswordRequestForm: FC<IRestorePasswordForm> = ({ formSubmit, errors, isSubmitted }) => {
  const { register, handleSubmit, formState } = useForm({ mode: 'onSubmit' });

  const [descriptionText, setDescriptionText] = useState('');
  const [loading, setLoading] = useState(false);
  const [nextAtemptTime, setNextAtemptTime] = useState<number>(Date.now() + 60000);

  // const nextAtemptResetEmail = useMemo(()=>{},[formState?.submitCount])
  const handleFormSubmit = useCallback((data: signUpType) => {
    setLoading(true);
    let currentAttemptTime = Date.now();
    
    if(formState.submitCount > 1 && nextAtemptTime > currentAttemptTime){
      setDescriptionText('Повторите через минуту, если письмо не придет');
      setLoading(false);
      return setTimeout(()=>{
        setDescriptionText('');
      },5000);
    }
    setNextAtemptTime(Date.now() + 60000);
    setDescriptionText('')
    setTimeout(()=>{
      setLoading(false);
      setDescriptionText('Ссылка для сброса пароля отправлена на указанный электронный адрес.')
    },1000);
    formSubmit(data)
  },[formState.submitCount,nextAtemptTime,formSubmit,setLoading,setNextAtemptTime])


  return (
    <FormContainer onSubmit={handleSubmit((data: signUpType) => handleFormSubmit(data))}>
      {
        !isSubmitted ? (
          <>
            <FormTitle smallSizeText>Восстановление пароля</FormTitle>
            <TDescription>
              Введите адрес электронной почты, привязанный к Вашей
              учетной записи.<br/> Вам будет отправлено письмо<br/> со ссылкой
              для сброса пароля.
            </TDescription>
            <Loader enabled={loading}/>
            <TFieldsContainer>
              <Input marginTop={12}
                bordered
                label='Email'
                placeholder='Введите email'
                {...register('email', validations.email)}
                error={!!formState.errors.email || !!(errors?.['email'])}
                errorText={getErrorMessage(formState.errors, 'email', errors)}
              />
              <TButton disabled={formState.isSubmitting}>Отправить ссылку</TButton>
            </TFieldsContainer>
            <TBottomLinkWr>
              <TLink to={ROUTES.SIGN_IN}>Отменить восстановление</TLink>
            </TBottomLinkWr>
          </>
        ) : (
          <>
            <FormTitle smallSizeText>Восстановление пароля</FormTitle>
            <TSubtitle>
              {descriptionText}
            </TSubtitle>
            <TDescription>
              Если Вы не получите письмо в течение нескольких минут, пожалуйста, проверьте папку со спамом. Если его не будет и там, запросите повторную отправку ссылки или свяжитесь с нами по адресу <TTextEmail>support-gl@omr.by</TTextEmail>
            </TDescription>
            <Loader enabled={loading}/>
            <TFieldsContainer>
              <TButton variant={VARIANT.TRANSPARENT} size={SIZE.LARGE}>Отправить ссылку повторно</TButton>
            </TFieldsContainer>
            <TBottomLinkWr>
              <TLink to={ROUTES.SIGN_IN}>Вернуться на страницу входа</TLink>
            </TBottomLinkWr>
          </>
        )
      }
    </FormContainer>
      
  );
};

export default RestorePasswordRequestForm;
