import {FC, MutableRefObject, useCallback, useRef, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {
  TBottomCopyContainer,
  TButton, TContainerEmail,
  TFieldsContainer,
  TLink,
  TLoginLink,
  TNameInputContainer,
  TPasswordContainer,
  TSuccessText,
  TSuccessTextWr,
  TTermsContainer,
  TTermsCopy,
  TText,
  TTitle,
} from './styled';
import Input from '../../shared/Input';
import Button, {SIZE} from '../../shared/Button';
import Checkbox from '../../shared/Checkbox';
import {extendedValidations, getErrorMessage, validations} from '../../../utils/validations';
import {ROUTES} from '../../../constants/routes';
import FormTitle from '../../shared/FormTitle';
import FormContainer from '../../shared/FormContainer';
import FormErrorText from '../../shared/FormErrorText';
import InputPassword from '../../shared/InputPassword';
import useOutsideClick from '../../../hooks/useOutsideClick';
import PasswordHint from './PasswordHint';
import {signUpType} from '../../../store/auth/thunkActions';
import {ErrorsType, Nullable} from '../../../types';

interface ISignUpForm {
  formSubmit: (data: signUpType) => void;
  isSubmitted: boolean;
  errors?: Nullable<ErrorsType>;
}

const SignUpForm: FC<ISignUpForm> = ({ formSubmit, isSubmitted, errors }) => {
  const [showPassHint, setShowPassHint] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  const navigate = useNavigate();

  const { register, handleSubmit, formState, control } = useForm({ mode: 'onSubmit' });
  const password = useWatch({ control, name: 'password' });

  const node = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;

  useOutsideClick(node, () => {
    if (showPassHint) {
      setShowPassHint(false);
    }
  });

  const onCheck = useCallback(() => {
    setTermsChecked(!termsChecked);
  }, [termsChecked]);

  const handleClick = useCallback(() => {
    navigate(ROUTES.SIGN_IN, { replace: true });
  }, [navigate]);

  const showPasswordHint = useCallback(() => {
    setShowPassHint(true)
  }, [setShowPassHint]);

  return (
    <FormContainer onSubmit={handleSubmit((data: signUpType) => termsChecked && formSubmit(data))}>
      {
        !isSubmitted ? (
          <>
            <TTitle>Создание учетной записи</TTitle>
            <TFieldsContainer>
              <TNameInputContainer>
                <Input marginTop={12}
                  bordered
                  label='* Имя'
                  type='text'
                  placeholder='Иван'
                  {...register('firstName', validations.firstName)}
                  error={!!formState.errors.firstName || !!(errors && errors['firstName'])}
                  errorText={getErrorMessage(formState.errors, 'firstName', errors)}
                />
                <Input marginTop={12}
                  bordered
                  label='* Фамилия'
                  type='text'
                  placeholder='Иванов'
                  {...register('lastName', validations.lastName)}
                  error={!!formState.errors.lastName || !!(errors && errors['lastName'])}
                  errorText={getErrorMessage(formState.errors, 'lastName', errors)}
                />
              </TNameInputContainer>
              <TContainerEmail>
                <Input marginTop={12}
                  bordered
                  label='* Email'
                  type='email'
                  placeholder='i.ivanov@gmail.com'
                  {...register('email', validations.email)}
                  error={!!formState.errors.email || !!(errors && errors['email'])}
                  errorText={getErrorMessage(formState.errors, 'email', errors)}
                />
              </TContainerEmail>
              <TPasswordContainer ref={node}>
                <InputPassword marginTop={12}
                  register={register('password', { ...validations.password, ...extendedValidations.password })}
                  label='* Придумайте безопасный пароль'
                  onFocus={showPasswordHint}
                  error={!!formState.errors.password || !!(errors && errors['password'])}
                  errorText={getErrorMessage(formState.errors, 'password', errors)}
                />
                <PasswordHint opened={showPassHint} />
              </TPasswordContainer>
              <InputPassword marginTop={12}
                register={register('rePassword', { ...validations.rePassword, validate: value =>
                    value === password})}
                label='* Введите пароль повторно'
                error={!!formState.errors.rePassword || !!(errors && errors['rePassword'])}
                errorText={getErrorMessage(formState.errors, 'rePassword', errors)}
              />

              {errors && errors['error'] && <FormErrorText>{getErrorMessage(formState.errors, 'error', errors)}</FormErrorText>}

              <TTermsContainer>
                <Checkbox smallSize checked={termsChecked} onChange={onCheck} />
                <TTermsCopy>
                  Я принимаю <TLink target='_blank' to='../user_agreement_genetic_lab.pdf'>пользовательское соглашение</TLink> и подтверждаю, что ознакомлен и согласен
                  с <TLink target='_blank' to='../policy_genetic_lab.pdf'>политикой конфиденциальности</TLink> этой платформы
                </TTermsCopy>
              </TTermsContainer>

              <Button disabled={formState.isSubmitting || !termsChecked} size={SIZE.MEDIUM}  >
                Создать
              </Button>

              <TBottomCopyContainer>
                <TText>У меня уже есть учетная запись</TText>
                <TLoginLink to={ROUTES.SIGN_IN}>Войти</TLoginLink>
              </TBottomCopyContainer>
            </TFieldsContainer>
          </>
        ) : (
          <>
            <FormTitle smallSizeText>Благодарим за регистрацию!</FormTitle>
            <TSuccessTextWr>
              <TSuccessText>
                Мы отправили письмо на указанный Вами email. Пожалуйста, пройдите по содержащейся в нeм ссылке для подтверждения вашего электронного адреса.
              </TSuccessText>
              <TSuccessText>
                Ссылка будет активна не более 7 дней с момента отправки.
              </TSuccessText>
              <TButton size={SIZE.MEDIUM} onClick={handleClick} >ОК</TButton>
            </TSuccessTextWr>
          </>
        )
      }
    </FormContainer>
  );
};

export default SignUpForm;
