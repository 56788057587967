export enum AlertTypes {
  ERROR = 'error',
  INFO = 'info',
  DONE = 'done',
}

export interface IAlertItem {
  type: AlertTypes;
  id: number;
  message: string;
  title?: string;
}