import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import {TRoot, TLabelWr, TFakeRadio, TInput, CustomTooltip, IconWrapper} from './styled';

interface RadioProps {
  selected: string;
  defaultChecked?: boolean;
  value: string;
  id?: string;
  label?: string | null;
  name?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  width?: string;
  activeColor?: string;
  error?: boolean;
  icon?: any;
  tooltip_id?: string;
  tooltipData?: any;
}

const Radio = forwardRef<HTMLInputElement | null, RadioProps>((props, ref) => {
  const { label, className, id, selected, defaultChecked, onChange, value, disabled, width, error, activeColor, icon, tooltip_id, tooltipData,  ...rest } = props;
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef = ref || defaultRef;

  const [active, setActive] = useState(!!defaultChecked);
  const [isActiveIcon, setIsActiveIcon] = useState<boolean>(false);

  const tooltipId = `${tooltip_id}-tooltip`

  useEffect(() => {
    setActive(selected === value);
  }, [selected, value])

  const onChangeHandler = useCallback(() => {
    onChange && onChange(value);
  }, [onChange, value]);

  const isActiveIconHandles = useCallback(() => {
    setIsActiveIcon(!isActiveIcon)
  }, [setIsActiveIcon, isActiveIcon]);

  const isUnActiveIcon = useCallback(() => {
    setIsActiveIcon(!isActiveIcon)
  }, [setIsActiveIcon, isActiveIcon]);

  return (
    <TRoot error={error} width={width} disabled={disabled} active={active} className={className} htmlFor={id}>
      <TInput
        activeColor={activeColor}
        type='radio'
        value={value}
        ref={resolvedRef}
        defaultChecked={defaultChecked}
        onChange={onChangeHandler}
        disabled={disabled}
        checked={active}
        {...rest}
      />
      <TFakeRadio />
      {label && <TLabelWr icon={icon}>{label}</TLabelWr>}

      {icon && <CustomTooltip id={tooltipId} place='right' overridePosition={({ left, top }) => ({ left: left + 20, top: top})}>
        {tooltipData}
      </CustomTooltip>}
      {icon && <><IconWrapper onMouseEnter={isActiveIconHandles} onMouseLeave={isUnActiveIcon} data-for={tooltipId} data-tip=""/>
        {isActiveIcon ? icon.active : icon.unActive}
      </>
      }
    </TRoot>
  );
});

export default Radio;
