import { FC, useMemo } from "react";
import { IAnalisysByMethod } from "../../../../../store/analysis/model";
import { TCheckbox, TDetails, TSummary, TSummaryContent, TSummaryIcon } from "../../../../../components/shared/Details/styled";
import arrowSelectIcon from '../../../../../icons/arrow-select.svg';
import currentStatusIcon from '../../../../../icons/attention.svg';
import defaultStatusIcon from '../../../../../icons/done-gray.svg';
import BioPanel from "../BioPanel";
import { useSelector } from "react-redux";
import { getServiceUsers } from "../../../../../store/serviceUsers/selectors";
import TableIHC from "./TableIHC";
import TableIHCALK from "./TableIHCALK";
import { checkPermitCreateIHCExperement, checkPermitUpdateIHCBio } from "../../../../../store/auth/selectors";
import { IExamExecutors } from "../../CreateExperiment";
import TableIHCClones from "./TableIHCClones";


interface IExecutorOptions {
  value: string;
  label: string;
  disabled: boolean;
  title: string;
  fullName: string;
}

interface IComponentProps {
  flowStepsActiveIndex: any;
  isSelectedOptions: number[];
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  executorOptions?: IExecutorOptions[];
  isActive: boolean;
  defaultValues?: any;
  ableToUpdate?: boolean;
  isReferralComplite: boolean;
  referralULID: string;
  defaultExecutorOptions?:any;
  examExecutors: IExamExecutors;
  [index: string]: any;
}

const ExperimentIHC: FC<IComponentProps> = ({ referralULID,defaultExecutorOptions, isReferralComplite, flowStepsActiveIndex, analisysByMethod, isActive, defaultValues, ableToUpdate, examExecutors }) => {
  const doctorsList = useSelector(getServiceUsers);
  //Permissions
  const ableToCreateIHCExperement = useSelector(checkPermitCreateIHCExperement);
  const ableToUpdateIHCBio = useSelector(checkPermitUpdateIHCBio);
  
  const labAssistantList = useMemo(() => {
    if (!doctorsList) return [];

    const options = doctorsList
      .filter((user: any) => {
        return user?.userRegistryPermissions?.labAssistant
      })
      .map((genetic: any) => {
        let { firstName, lastName, middleName, title } = genetic?.userProfile ?? {};
        let label = `${lastName} ${firstName} ${middleName}, ${title}`.trim();

        return { value: genetic.ulid, label };
      });

      return options
  },[doctorsList]);

  const isALKMarker = useMemo(() => {
    if(!analisysByMethod?.markerIhc?.name) return false;
    return analisysByMethod.markerIhc.name.toLowerCase() === 'alk';
  },[analisysByMethod]);

  const hasMarkerClones = useMemo(() => {
    if(!analisysByMethod?.markerIhc?.name) return false;
    return !!analisysByMethod?.markerIhc?.markerIhcClones?.length;
  },[analisysByMethod]);

  
  return (
    <TDetails open={isActive || !!defaultValues?.acceptance}>
      <TSummary>
        <TSummaryContent isSelected={true}>
          <TCheckbox disabled={true} checked={defaultValues?.acceptance ?? false}/>
          Попытка №{defaultValues?.number ?? flowStepsActiveIndex.index}
          <TSummaryIcon className="arrow" src={arrowSelectIcon} />
        </TSummaryContent> <TSummaryIcon src={!Object?.keys(defaultValues ?? {})?.length ? currentStatusIcon : defaultStatusIcon}  />
      </TSummary>
      
        {!defaultValues?.acceptance && <BioPanel 
          analisysByMethod={analisysByMethod} 
          labAssistantList={labAssistantList}
          flowStepsActiveIndex={defaultValues?.number ?? flowStepsActiveIndex.index}
          isActive={isActive}
          hasPermit={ableToUpdateIHCBio}
        />}
        
        {isALKMarker && 
          <TableIHCALK 
            analisysByMethod={analisysByMethod} 
            flowStepsActiveIndex={defaultValues?.number ?? flowStepsActiveIndex.index}
            isActive={isActive}
            hasPermit={ableToCreateIHCExperement}
            defaultValues={defaultValues}
            ableToUpdate={ableToUpdate}
            isReferralComplite={isReferralComplite}
            referralULID={referralULID ?? ''}
            defaultExecutorOptions={defaultExecutorOptions}
            examExecutors={examExecutors}
          />}
          
        {!isALKMarker && !hasMarkerClones &&
          <TableIHC 
            analisysByMethod={analisysByMethod} 
            flowStepsActiveIndex={defaultValues?.number ?? flowStepsActiveIndex.index}
            isActive={isActive}
            hasPermit={ableToCreateIHCExperement}
            defaultValues={defaultValues}
            ableToUpdate={ableToUpdate}
            isReferralComplite={isReferralComplite}
            referralULID={referralULID ?? ''}
            defaultExecutorOptions={defaultExecutorOptions}
            examExecutors={examExecutors}
          />}
          
        {!isALKMarker && hasMarkerClones &&
          <TableIHCClones 
            analisysByMethod={analisysByMethod} 
            flowStepsActiveIndex={defaultValues?.number ?? flowStepsActiveIndex.index}
            isActive={isActive}
            hasPermit={ableToCreateIHCExperement}
            defaultValues={defaultValues}
            ableToUpdate={ableToUpdate}
            isReferralComplite={isReferralComplite}
            referralULID={referralULID ?? ''}
            defaultExecutorOptions={defaultExecutorOptions}
            examExecutors={examExecutors}
          />}
    </TDetails>
    );
}

export default ExperimentIHC;