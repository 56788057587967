import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import {
  TFormContainer, TFormWrapper,
  TWrapper,
} from './styled';
import { TBottomButtonsContainer } from '../../styled';
import Button, { SIZE, VARIANT } from '../../../../components/shared/Button';
import { IStep } from '..';
import RegistrarForm from '../../../../components/forms/RegistrarForm';
import DoctorReferralForm from '../../../../components/forms/DoctorReferralForm';
import { setReferralRegistrar } from '../../../../store/referral/thunkActions';
import { fetchServiceUsers } from '../../../../store/serviceUsers/thunkActions';
import { getSelectedServiceUser, getSelectedServiceUserData, getServiceUsers, getServiceUsersLoading } from '../../../../store/serviceUsers/selectors';
import InfoModal from '../../../../components/shared/InfoModal';
import { setSelectedServiceUser } from '../../../../store/serviceUsers';
import {
  getPermissions,
  getProfile,
  getTokens,
} from '../../../../store/auth/selectors';
import Loader from '../../../../components/shared/Loader';
import { ServiceUser } from '../../../../store/serviceUsers/model';

const DoctorReferral: FC<IStep> = ({ goNext, onCancelCreation, goBack }) => {
  const serviceUsers = useSelector(getServiceUsers);
  const selectedServiceUser = useSelector(getSelectedServiceUser);
  const profile = useSelector(getProfile);
  // const userEmail = useSelector(getUserEmail);
  const tokens = useSelector(getTokens);
  const ulid = useSelector(getSelectedServiceUserData)?.ulid ?? profile?.ulid;
  const permissions = useSelector(getPermissions);
  const loading = useSelector(getServiceUsersLoading);

  const [showModal, setShowModal] = useState(false);
  const [isCustomDoctorValid, setCustomDoctorValid] = useState(false);
  const [userEmail, setUserEmail] = useState<string>('');

  const dispatch = useDispatch();

  const formMethods = useForm({ mode: 'onSubmit' });

  const setSelectedSpecialist = useCallback((specialist) => {
    dispatch(setSelectedServiceUser(specialist));
  }, [dispatch]);

  useLayoutEffect(() => {
    tokens?.access && dispatch(fetchServiceUsers(tokens.access, { organizationId: profile?.organization ?? 0 },['doctor']));
  },[tokens,dispatch,profile?.organization]);

  const isDoctorReferral = useMemo(() => permissions?.doctor, [permissions?.doctor]);

  useLayoutEffect(() => {
    let currentUser = serviceUsers?.find((userData: ServiceUser) => userData?.userProfile?.ulid === profile?.ulid);
    
    if(currentUser?.ulid){
      !selectedServiceUser && setSelectedSpecialist(currentUser?.ulid);
      setUserEmail(currentUser?.email ?? '')
    }
  },[dispatch,tokens,isDoctorReferral,profile,serviceUsers,setSelectedSpecialist,selectedServiceUser,setUserEmail,profile?.ulid]);


  const onContinue = useCallback((data) => {
    if (!selectedServiceUser && !data.firstName && !isDoctorReferral) {
      setShowModal(true);
      return;
    }

    const specialistUlid = isDoctorReferral ? ulid : (selectedServiceUser?.ulid ?? '');
    const formattedData = {
      specialistUlid: specialistUlid,
      commonOrganization: data.commonOrganization,
      organizationCustom: data.organizationCustom || selectedServiceUser?.userProfile?.organizationCustom,
      fullName: data.fullName,
      organization: selectedServiceUser?.userProfile?.organization || data.organization,
      department: selectedServiceUser?.userProfile?.department || data.department,
      subdepartment: selectedServiceUser?.userProfile?.subdepartment || data.subdepartment,
      firstName: selectedServiceUser?.userProfile?.firstName || data.firstName,
      lastName: selectedServiceUser?.userProfile?.lastName || data.lastName,
      middleName: selectedServiceUser?.userProfile?.middleName || data.middleName,
      email: selectedServiceUser?.email || (data?.email ?? null),
      phoneNumber: selectedServiceUser?.userProfile?.phoneNumber || (data?.phoneNumber ?? ''),
      comment: selectedServiceUser ? '' : data?.comment,
      payment: data.payment,
      speed: data.speed,
      creationDate: format(data.creationDate || new Date(), 'yyyy-MM-dd'),
    };

    dispatch(setReferralRegistrar(formattedData));

    goNext && goNext();
  }, [isDoctorReferral, selectedServiceUser, ulid, goNext, dispatch]);

  const findServiceUsers = useCallback(async (fullName, organizationId?) => {
    if (tokens?.access) {
      dispatch(setSelectedServiceUser(null));
      await dispatch(fetchServiceUsers(tokens.access, { fullName, organizationId },['doctor']));
      // formMethods.unregister('commonOrganization');
    }
  }, [ tokens?.access, dispatch]);

  // useEffect(() => {
  //   const [fullName, commonOrganization] = getFromSessionStorage(REGISTRAR.fullName, REGISTRAR.commonOrganization);
  //
  //   if (fullName && !serviceUsers.length && !requestServiceUserFromSessionStorage) {
  //     const organizationId = organizations.find(({ nameLocalRus }) => nameLocalRus === commonOrganization)?.id;
  //     findServiceUsers(fullName, organizationId);
  //     setRequestServiceUserFromSessionStorage(true);
  //   }
  // }, [organizations, requestServiceUserFromSessionStorage, serviceUsers, findServiceUsers]);

  const onModalClose = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  return (
    <Loader enabled={loading}>
      <TWrapper>
        <TFormWrapper>
          <FormProvider {...formMethods}>
            <TFormContainer>
              {isDoctorReferral ? (
                <DoctorReferralForm
                  profile={profile}
                  userEmail={userEmail}
                />
              ) : (
                <RegistrarForm
                  findServiceUsers={findServiceUsers}
                  serviceUsersList={serviceUsers}
                  setSelectedSpecialist={setSelectedSpecialist}
                  selectedSpecialist={selectedServiceUser}
                  isCustomDoctorValidateHandler={(isValid) => setCustomDoctorValid(isValid)}
                />
              )}
            </TFormContainer>
          </FormProvider>
        </TFormWrapper>
      </TWrapper>
      <TBottomButtonsContainer>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={goBack}>Назад</Button>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onCancelCreation}>Отмена</Button>
        {!isDoctorReferral && <Button size={SIZE.SMALL} disabled={!selectedServiceUser?.ulid && !isCustomDoctorValid} onClick={formMethods.handleSubmit(onContinue)}>Далее</Button>}
        {isDoctorReferral  && <Button size={SIZE.SMALL} disabled={false} onClick={formMethods.handleSubmit(onContinue)}>Далее</Button>}
      </TBottomButtonsContainer>
      <InfoModal
        title='Выберите специалиста или создайте новый профиль'
        showModal={showModal}
        onModalToggle={onModalClose}
        isWarning
        hasCloseButton
        buttons={<Button size={SIZE.SMALL} onClick={onModalClose}>OK</Button>}
      />
    </Loader>
  );
}

export default DoctorReferral;
