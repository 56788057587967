import { FC, useMemo } from "react";

interface IMarkerBlock {
  blockIndex: number;
  items: ReferralMarkerPriority[];
  drugSelectedList?: string[],
  markerSelectedList?: string[]
}

export enum MarkerPriority {
  HIGH = 'High',
  LOW = 'Low'
}

interface ReferralMarkerPriority {
  active: boolean;
  id: number;
  localization: number;
  drugOrder: DrugOrder;
  marker: Marker;
  priority: MarkerPriority;
}

export interface DrugOrder {
  id: number;
  drugId: number;
  order: number;
  drugClass: number;
  drugClassOrder: number;
  drugName: string;
}

export interface Marker {
  id: number;
  ulid: string;
  name: string;
  toxicityAssesment: boolean;
  methods: number[];
}

const MarkerRow: FC<IMarkerBlock> = ({ items, blockIndex, markerSelectedList, drugSelectedList}) => {

  const [drugNames,uniqueDrugNames, markerNames] = useMemo(() => {
    const drugNameList = items.filter((value: ReferralMarkerPriority) => value.active).map(({ drugOrder }) => drugOrder.drugName);
    // @ts-ignore
    const uniqueDrugNameList = [...new Set(drugNameList)];
    const uniqueDrugNames:string[] = uniqueDrugNameList.map((name) => name )
    const rendererDrugNames = uniqueDrugNameList.map((name) => name)?.join(', ');

    const markerNameList:string[] = items.map(({ marker }) => marker.name);
    // @ts-ignore
    const uniqueMarkerNameList = [...new Set(markerNameList)];

    return [rendererDrugNames, uniqueDrugNames, uniqueMarkerNameList];
  }, [items]);



  const checkedList = useMemo(() => {
    const markerNameCollection:string[] = [];
    const isDrugConfirm =  uniqueDrugNames?.some((name:string) => drugSelectedList?.includes(name));

    markerNames?.forEach((marker:string) => {
      let result = markerSelectedList?.includes(marker)
      if(result) markerNameCollection.push(marker);
      return result;
    })
    if(isDrugConfirm && markerNameCollection?.length) return markerNameCollection;
    return []
  },[drugSelectedList,uniqueDrugNames,markerNames,markerSelectedList]);

  return <>
    {markerNames?.map((mk:any, mkIndex:number) => (
      <tr key={`${blockIndex}_${mkIndex}`}>
        {!mkIndex && <td colSpan={5} rowSpan={markerNames?.length}><div className="oneRowLayout">{drugNames}</div></td>}
        <td>{mk}</td>
        <td className="markedCell">{checkedList.includes(mk) ? <span >&#10004;</span> : <span>&#8213;</span>}</td>
      </tr>
    ))}
  </>
}

export default MarkerRow;