import { FC } from 'react';
import {
  TBlock,
  TBlockLeftWrapper,
  TBlockLogoWrapper,
  TBlockRigthWrapper,
  TLogoBlock,
  TWrapper,
} from './styled';
import LogoIcon from '../../../images/logos/genx.png';

const AuthPageContainer: FC = ({ children }) => (
  <>
    <TWrapper>
      <TBlockLeftWrapper />
      <TBlockRigthWrapper />
    </TWrapper>
    <TBlock>
      <TLogoBlock>
        <TBlockLogoWrapper>
          <img src={LogoIcon} alt='aibion logo' width={"500px"}/>
        </TBlockLogoWrapper>
      </TLogoBlock>
      {children}
    </TBlock>
  </>
);

export default AuthPageContainer;
