export enum MarkerPriority {
  HIGH = 'High',
  LOW = 'Low'
}

export interface IMarkerTypeList {
    id: number;
    ulid: string;
    name: string;
    nameRus: string;
    description: string;
  }

export interface DrugOrder {
  id: number;
  drugId: number;
  order: number;
  drugClass: number;
  drugClassOrder: number;
  drugName: string;
}

export interface Marker {
  id: number;
  ulid: string;
  name: string;
  toxicityAssesment: boolean;
  methods: number[];
}

export interface ReferralMarkerPriority {
  active: boolean;
  id: number;
  localization: number;
  drugOrder: DrugOrder;
  marker: Marker;
  priority: MarkerPriority;
}

export interface ReferralMarker {
  id: number;
  ulid?: string;
  markerPriority: ReferralMarkerPriority;
  status: string;
  dateUpdated: Date;
}