import { FC, useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { ISelectedBlockOption } from "./MorphologicalCharacteristic";
import { CustomButton, CustomStyles, TButtonWrapper, TGlassMessage, TInfoTextWrapper, TMultiSelectWrapper, TPromptMessage } from "./styled";

interface IComponentProps {
  glassList: ISelectedBlockOption[];
  disabled: boolean;
  glassConfirmHandler: (type:string,data:ISelectedBlockOption[]) => void;
  selectBlockHandler:  (data:ISelectedBlockOption[]) => void;
  defaultBlockValues:  ISelectedBlockOption[];
  hasPermit: boolean;
  isDone: boolean;
  isSaved: boolean;
  isDifferenceInNumbers: boolean;
  [index: string]: any;
}

const GlassSelector: FC<IComponentProps> = ({
  glassList, disabled,isSaved, isDone, glassConfirmHandler,selectBlockHandler,hasPermit, defaultBlockValues,isDifferenceInNumbers}) => {
  const { control, watch,setValue, handleSubmit } = useForm();

  const glassSelectorValues:ISelectedBlockOption[] = watch('glassSelector',[]);

  useLayoutEffect(() => {
    setValue('glassSelector',defaultBlockValues);
  },[setValue,defaultBlockValues]);


  // const isDifferenceInNumbers = useMemo(() => {
  //   let detectDifferenceValues = false;
  //   let detectDifferenceLength = false;

  //   if(!glassSelectorValues?.length ) return false;
  //   if(!defaultBlockValues?.length && glassSelectorValues?.length ) return true;

  //   let defaultBlocks = defaultBlockValues.map((data: ISelectedBlockOption) => data.value);
  //   let selectBlocks = glassSelectorValues.map((data: ISelectedBlockOption) => data.value);

  //   if(defaultBlocks?.length && selectBlocks?.length){
  //     detectDifferenceValues = selectBlocks?.every((value: string) => defaultBlocks.includes(value));
  //     detectDifferenceLength = selectBlocks?.length === defaultBlocks?.length;
  //   }

  //   let result = !(detectDifferenceValues && detectDifferenceLength);

  //   return result
  // },[defaultBlockValues,glassSelectorValues]);

  useEffect(() => {
    if(disabled){
      // !!isConfirm && setConfirmed(false);
      !!glassSelectorValues?.length && setValue('glassSelector',defaultBlockValues);
    }
    selectBlockHandler(glassSelectorValues);
  // },[disabled,setConfirmed,glassSelectorValues,setValue,selectBlockHandler,isConfirm,defaultBlockValues]);
  },[disabled,glassSelectorValues,setValue,selectBlockHandler,defaultBlockValues]);

  const isGlassSelected = useMemo(() => {
    // setConfirmed(true);
    if(glassSelectorValues && glassSelectorValues?.length) return true;
    return false;
  // }, [glassSelectorValues,setConfirmed]);
  }, [glassSelectorValues]);

  const handlerSelectGlass = useCallback(({ glassSelector }: any) => {
    // let type = 'rangeOfBlocksSaved';
    // glassConfirmHandler('rangeOfBlocksSaved',glassSelector)
    // if(isSaved && !isDone) type = 'rangeOfBlocksDone';

    // glassConfirmHandler(type,glassSelector)
    glassConfirmHandler('rangeOfBlocksDone',glassSelector)
  // }, [glassConfirmHandler,isDone,isSaved]);
  }, [glassConfirmHandler]);
  

  return (
    <>
      <TInfoTextWrapper>
        <TGlassMessage>1. Выберите диапазон(ы) блоков</TGlassMessage>
        <TPromptMessage>Начните ввод номера в поисковой строке или воспользуйтесь выпадающим списком</TPromptMessage>
      </TInfoTextWrapper>
      <TMultiSelectWrapper onSubmit={handleSubmit((data: any) => handlerSelectGlass(data))}>
        <Controller
          control={control}
          name="glassSelector"
          rules={{ required: true }}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              key={`glassSelector_${disabled}_${defaultBlockValues?.length}`}
              isMulti
              onChange={onChange}
              selected={value}
              options={glassSelectorValues?.length && glassSelectorValues.length === 3 ? [] : glassList}
              classNamePrefix='select'
              placeholder={'Начните ввод'}
              styles={CustomStyles}
              isDisabled={disabled}
              isOptionDisabled={(option: any) => option.disabled}
              inputRef={ref}
              noOptionsMessage={() => "нет опций"}
              defaultValue={defaultBlockValues}
            />
          )}
        />
        { hasPermit && (isDifferenceInNumbers || !isDone || !isSaved) && (
          <TButtonWrapper>
            <CustomButton type="submit" disabled={(disabled && !isSaved) || !isGlassSelected}>
              {/* {isSaved && !isDone && !isDifferenceInNumbers ? 'Завершить выбор' : 'Сохранить изменения'} */}
              К выбору кандидатов
            </CustomButton>
          </TButtonWrapper>
        )}
      </TMultiSelectWrapper>
    </>
  )
}

export default GlassSelector;