import {useState} from "react";
import {ReactComponent as ArrowIcon} from "../../../../../icons/arrow-select.svg";
import SecondRowComponent from "./SecondRowComponent";
import {IconDownWrapper, IconUpWrapper, MainRow, MainRowIconBlock, MainRowItem} from "./styled";

const MainRowComponent = ({item, selectMKB10Option}: any) => {
  const[isOpenIcd10Parent, setIsOpenIcd10Parent] = useState(false);
  const isOpenHandler = () => {
    setIsOpenIcd10Parent(!isOpenIcd10Parent)
  }

  return (
    <>
      <MainRow onClick={isOpenHandler}>
        <MainRowItem>{`${item.code} ${item.nameRus}`}</MainRowItem>
        <MainRowIconBlock onClick={isOpenHandler}>
          {isOpenIcd10Parent ? <IconDownWrapper>
              <ArrowIcon/>
            </IconDownWrapper>
            : <IconUpWrapper>
              <ArrowIcon/>
            </IconUpWrapper>}
        </MainRowIconBlock>
      </MainRow>
      {isOpenIcd10Parent &&
        item.icd10Parent.map((item:any) => {
          const key = `${item.code}_${item.nameRus}`;

          return <SecondRowComponent key={key} item={item} selectMKB10Option={selectMKB10Option}/>})
      }
    </>
  )
};

export default MainRowComponent;