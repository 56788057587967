import styled from 'styled-components';

export const TPageContainer = styled.div`
  background-color: #fff;
  padding: 80px 40px 80px 90px;
  box-sizing: border-box;

  & * {
    font-family: Times New Roman;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
  }
`;

export const THeaderWr = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const TTextIndent = styled.p`
  text-indent: 50px;
`;

export const TTopRightBlock = styled.div`
  width: max-content;
  margin-right: 38px;
  line-height: 30px;
`;

export const TMainContent = styled.div`
  margin-top: 120px;
  text-align: justify;
`;

export const TTitle = styled.p`
  font-weight: bold;
  text-align: center;
`;

export const TInfoBlock = styled.div`
  display: grid;
  grid-template-columns: 35% 75%;
  //grid-template-rows: repeat(8, 18px);
  grid-column-gap: 26px;
  grid-row-gap: 1px;
  margin: 28px 0;
  
  & > div:first-child {
    padding-right: 26px;
    font-weight: bold;
  }
`;

export const TBoldedSpan = styled.span`
  font-weight: 700;
`;

export const TAgreedBlock = styled.div`
  margin-bottom: 60px;
`;

export const TFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 52px;
  
  & > div {
    line-height: 30px;
    text-align: center;
  }
`;
