import { createSelector } from '@reduxjs/toolkit';
import { IAppState } from '../';

const getState = (state: IAppState) => state.analysis;

export const getAnalisys = createSelector(getState, state => state.analysis);
export const getAnalisysList = createSelector(getState, state => state.analysisList);
export const getAnalisysLoading = createSelector(getState, state => state.loading);
export const getAnalisysError = createSelector(getState, state => state.errors);
export const getNewExperimentData = createSelector(getState, state => state.newExperimentCompleted);
export const getNewBioStepUpdateData = createSelector(getState, state => state.newBioStepUpdate);
export const getUpdatedConclusion = createSelector(getState, state => state.updatedConclusion);