import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import ThemeContainer from './components/shared/ThemeContainer';
import rootReducer from './store';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";


const store = configureStore({ reducer: rootReducer });

  ReactDOM.render(
    <Provider store={store}>
      <ThemeContainer>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeContainer>
    </Provider>,
    document.getElementById('root')
  )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorkerRegistration.register();