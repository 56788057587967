import styled from 'styled-components';

interface TInfoBlockWrapper {
  title?: string;
  borderRadiusTopRight?: number;
  borderRadiusBottom?: number;
}

interface IDiv {
  borderRadiusTopRight?: number;
  borderRadiusBottom?: number;
}

export const TInfoTabsWrapper = styled.div<TInfoBlockWrapper>`
  position: relative;
  width: 100%;
`
export const TSpan = styled.span`
  padding: 8px 0 10px 24px;
  line-height: 32px;
  width: 220px;
  height: 38px;
  position: absolute;
  top: -56px;
  background-color: #F9F9FB;
  border-top: 1px solid ${({theme}) =>theme.colors.product.primaryActive};
  border-left: 1px solid ${({theme}) =>theme.colors.product.primaryActive};
  border-right: 1px solid ${({theme}) =>theme.colors.product.primaryActive};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-weight: 600;
  font-size: 20px;
  color: ${({theme}) =>theme.colors.product.primaryActive};
  z-index:1;
`

export const TDiv = styled.div<IDiv>`
  position: relative;
  width: 100%;
  min-height: 200px;
  border-top-right-radius: ${({borderRadiusTopRight}) => borderRadiusTopRight ? `${borderRadiusTopRight}px` : ''};
  border-bottom-right-radius: ${({borderRadiusBottom}) => borderRadiusBottom ? `${borderRadiusBottom}px` : ''};
  border-bottom-left-radius: ${({borderRadiusBottom}) => borderRadiusBottom ? `${borderRadiusBottom}px` : ''};
  background-color: ${({theme}) => theme.colors.white};
  ::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-top-right-radius: ${({borderRadiusTopRight}) => borderRadiusTopRight ? `${borderRadiusTopRight}px` : ''};
    border-bottom-right-radius: ${({borderRadiusBottom}) => borderRadiusBottom ? `${borderRadiusBottom}px` : ''};
    border-bottom-left-radius: ${({borderRadiusBottom}) => borderRadiusBottom ? `${borderRadiusBottom}px` : ''};
    padding:1px; 
    background:linear-gradient(160deg,${({theme}) =>theme.colors.product.primaryActive},transparent,transparent); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
  }
`
export const TSubTitle = styled.p`
  font-weight: 500;
  font-size: 9px;
  line-height: 10px;
`;
