import styled from 'styled-components';
import Input from "../../components/shared/Input";
import { ReactComponent as printIcon}  from '../../icons/print.svg';


export const TMolecularProfilingDetailsWrapper = styled.div`
  position:relative;
  display: flex;
  box-sizing: border-box; 
  max-height: 100vh;
  width: 100%;
  min-width:1366px;
  margin: 0;
  overflow: hidden;
  background-color: #F7F7F8;
`

export const TPatientDataBlock = styled.div`
  width: 35%; 
  min-width: 516px;
  height: 100vh;
  max-height: 100vh;
  padding:80px 7px 0 18px;

  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    background: #EFEEFF;
  }
  ::-webkit-scrollbar-thumb {
    background: #B7B5F1;
    box-shadow: inset 0 0 1px #EFEEFF;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  scrollbar-color: #B7B5F1 #EFEEFF;

`

export const TMolecularProfilingBlock = styled.div`
  width: 63%;
  min-width: 550px;
  height: 100vh;
  max-height: 100vh;

  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    background: #EFEEFF;
  }
  ::-webkit-scrollbar-thumb {
    background: #B7B5F1;
    box-shadow: inset 0 0 1px #EFEEFF;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  scrollbar-color: #B7B5F1 #EFEEFF;
`

export const TMolecularProfilingDetailsButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-width: 550px;
  height: 105px;
  & :last-child {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    margin-right: 21px;
    padding: 0 5px;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
  }
`
export const TButton = styled.button<{isActive?:boolean, width?:number, height?:number }>`
  position: relative;
  display: block;
  width: ${({width}) => width ? `${width}px` : `${310}px`};
  min-width: max-content;
  padding: 0 45px;
  height: ${({height}) => height ? `${height}px` : `${55}px`};
  border-radius: 10px;
  background: ${({theme,isActive}) => isActive ? theme.colors.product.primaryActive : 'transparent'};
  margin-left: 24px;

  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  text-align: center;
  border: 2px solid ${({theme}) => theme.colors.violet[500]};
  color:${({theme,isActive}) => isActive ? theme.colors.white : theme.colors.violet[500]};
  cursor: pointer;
  &:hover {
    border: 2px solid ${({theme}) => theme.colors.product.primaryHover};
    color:${({theme,isActive}) => isActive ? theme.colors.white : theme.colors.product.primaryHover};
    background: ${({theme,isActive}) => isActive ? theme.colors.product.primaryHover : 'transparent'};
  }
  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.colors.neutral.disable};
    border: 2px solid ${({ theme }) => theme.colors.neutral.disable};
  }
  
`

export const TButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  button:first-child {
    margin-bottom: 14px;
  }
`;

export const TPatientReferralNumber = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 70px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color:${({theme}) =>theme.colors.black[200]};
  padding-left: 12px;
`
export const TPageTitle = styled.div`
  display:flex;
  justify-content: flex-start ;
  align-items: center;
  height:55px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom:32px;
  color:${({theme}) =>theme.colors.black[200]};
  padding-left: 12px;
`

export const TCheckBoxListWr = styled.div`
  padding-right:20px;
  margin-top: 56px;
`

export const TMenuPrintIcon = styled(printIcon)`
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-left:  0px;
  margin-right: 0px;
  :hover {
    path,line,rect,circle {
      stroke: ${({ theme }) => theme.colors.violet[500]};
    }
  }
`;

export const TPrintMenu = styled.div`
  display: none;
  justify-content: center;
  position: absolute;
  top: 22px;
  right: -16px;
  width: 180px;
  padding: 16px 16px 0 0;
  flex-direction: column;
  align-items: flex-end;
  z-index: 10;
  :hover {
      display: flex;
  } 
  & :first-child {
    padding-top: 16px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: 1px -1px 6px 0px rgba(0, 0, 0, 0.07);
  }
  & :last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-bottom: 16px;
  }
`;

export const TPrintMenuItem = styled.div<{isAvailable?: boolean}>`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 8px 16px;
  width: 140px;
  background-color: #fff;
  box-shadow: 1px -1px 6px -2px rgba(0, 0, 0, 0.07);
  color: ${({ theme, isAvailable }) => isAvailable ? theme.colors.black[200] : theme.colors.gray[400]};
  cursor: ${({ isAvailable }) => isAvailable ? 'pointer' : 'not-allowed'};
  font-variant-numeric: lining-nums proportional-nums;
  :hover {
    color: ${({ theme, isAvailable }) => isAvailable ? theme.colors.violet[500] : theme.colors.gray[400]};
  }
`;

export const TMenuIconWr = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  margin-right: 58px;
  margin-bottom: -5px;
  &:hover {
    & ${TPrintMenu} {
      display: flex;
    }
    & ${TMenuPrintIcon} {
        path,line,rect,circle {
          stroke: ${({ theme }) => theme.colors.violet[500]};
        }
      }
  } 
`;

export const TBackLink = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({theme}) => theme.colors.gray[600]};
  width: max-content;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  margin-top: 8px;
  padding-left: 10px;
  
  & > *:first-child {
    margin: -3px 3px 0;
    font-size: 24px;
    line-height: 14px;
  }`

  export const TModalInfoText = styled.span`
    margin: 12px 0 -6px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  `
  export const TBage = styled.span<{color?:string}>`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background-color: ${({theme, color}) => color ? color : theme.colors.gray[400]};
    color: ${({theme}) => theme.colors.white};
    margin-left: 10px;
    padding: 2px 11px;
    border-radius: 10px;
  `;

export const TCustomInput = styled(Input)<{ width?: string, background?: string, disabled?:boolean }>`
width: ${({width}) => width ? width : '100%'};
padding-bottom: 16px;
position: relative;
margin-top: 20px;
& > div > div {
  display: ${({disabled}) => disabled ? 'none' : 'flex'};
  right: 0;
  top: -2px;
}

& :read-only {
  cursor: default;
}

& input {
  padding: 18px;
  background-color: ${({background}) => background ? background : '#fff'};

}
`;

export const TCancelReasonBlock = styled.div`
  height: max-content;
  border: 2px solid ${({theme}) => theme.colors.product.primaryActive};
  border-radius: 10px;
  padding: 18px 21px;
  margin-bottom: 16px;
  margin-left: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.black[200]};
  cursor: default;

  i {
    color: ${({theme}) => theme.colors.product.primaryActive};
    font-style: normal;
  }
`;


export const TCancelRequestBlock = styled.div<{marginLeft?:number}>`
  display: flex;
  flex-wrap: wrap;
  padding: 18px 30px 18px 16px;
  border-radius: 10px;
  background: ${({theme}) => theme.colors.pink[100]};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${({theme}) => theme.colors.black[200]};
  margin-bottom: 16px;
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : 12}px;
  span {
    width: 100%;
  }
  b {
    display: block;
    width: 100%;
    font-size: 18px;
    margin-bottom: 24px;
  }
  i {
    color: ${({theme}) => theme.colors.gray[600]};
    font-style: normal;
  }
`;