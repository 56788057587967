const SESSION_STORAGE_KEYS = {
  ROOT: {
    type: 'type',
  },
  PATIENT: {
    id: 'patientId',
    ulid: 'patientUlid',
    idPassport: 'patientIdPassport',
    country: 'patientCountry',
    gender: 'patientGender',
    dateOfBirth: 'patientDateOfBirth',
    ambulatoryCards: 'patientambulatoryCards',
    firstName: 'patientFirstName',
    lastName: 'patientLastName',
    middleName: 'patientMiddleName',
  },
  REGISTRAR: {
    commonOrganization: 'registrarCommonOrganization',
    organization: 'registrarOrganization',
    organizationCustom: 'registrarOrganizationCustom',
    fullName: 'registrarFullName',
    creationDate: 'registrarCreationDate',
    comment: 'registrarComment',
    payment: 'registrarPayment',
    speed: 'registrarSpeed',
    department: 'registrarDepartment',
    subdepartment: 'registrarSubdepartment',
    lastName: 'registrarLastName',
    firstName: 'registrarFirstName',
    middleName: 'registrarMiddleName',
    phoneNumber: 'registrarPhoneNumber',
    email: 'registrarEmail',
    specialistUlid: 'registrarSpecialistUlid',
    customFullName: 'registrarCustomFullName',
    isCreatedNewDoctorProfile: 'registrarIsCreatedNewDoctorProfile'
  },
  CLINICAL: {
    localization: 'clinicalLocalization',
    icd_10: 'clinicalIcd_10',
    description: 'clinicalDescription',
    tCategory: 'clinicalTCategory',
    tSub: 'clinicalTSub',
    nCategory: 'clinicalNCategory',
    nSub: 'clinicalNSub',
    mCategory: 'clinicalMCategory',
    mSub: 'clinicalMSub',
    stage: 'clinicalStage',
    stageSub: 'clinicalStageSub',
    histological: 'clinicalHistological',
    tnmNonApplicable: 'clinicalTnmNonApplicable',
    tnmUnknown: 'clinicalTnmUnknown',
    stageNonApplicable: 'clinicalStageNonApplicable',
    stageUnknown: 'clinicalStageUnknown',
  },
  BIO_MATERIAL: {
    blocksChecked: 'bioMaterialBlocksChecked',
    bloodChecked: 'bioMaterialBloodChecked',
    source: 'bioMaterialSource',
    organization: 'bioMaterialOrganization',
    organizationCustom: 'bioMaterialOrganizationCustom',
    doctorOrganization: 'bioMaterialDoctorOrganization',
    doctorCustomOrganization: 'bioMaterialDoctorCustomOrganization',
    dateOfAcquisition: 'materialDateOfAcquisition',
    numbering: 'bioMaterialNumbering',
    bloodDateOfAcquisition: 'bioMaterialBloodDateOfAcquisition',
    bloodId: 'bioMaterialBloodId',
    blocks: 'bioMaterialBlocks',
  },
};

export default SESSION_STORAGE_KEYS;
