import {FC, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import { TWrapper } from './styled';
import { TBottomButtonsContainer, TTitle } from '../../styled';
import Radio from '../../../../components/shared/Radio';
import Button, { SIZE, VARIANT } from '../../../../components/shared/Button';
import { IStep } from '..';
import { setReferralResearchType } from '../../../../store/referral/thunkActions';
import {getValidateAmbulatoryCard} from "../../../../store/referral";
import {resetPatients} from "../../../../store/patient";
import {ReactComponent as ActiveInfoIcon} from '../../../../icons/Activeinfo.svg';
import {ReactComponent as UnActiveInfoIcon} from '../../../../icons/UnActiveinfo.svg';
import prepareTooltipData from "./prepareTooltipData";

const DEFAULT_OPTION = 'molecularProfiling';

const options = [
  {
    label: 'Молекулярное профилирование',
    value: 'molecularProfiling',
    disabled: false,
    tooltip_id: 'molecular_profiling',
    icon: {
      active: <ActiveInfoIcon/>,
      unActive: <UnActiveInfoIcon/>,
    },
  },
  // {
  //   label: 'Оценка наследственных рисков',
  //   value: '2',
  //   disabled: true,
  //   tooltip_id: 'assessment_of_hereditary_risks',
  //   icon: {
  //     active: <ActiveInfoIcon/>,
  //     unActive: <UnActiveInfoIcon/>,
  //   },
  // },
  // {
  //   label: 'Молекулярная диагностика',
  //   value: '3',
  //   disabled: true,
  //   tooltip_id: 'molecular_diagnostics',
  //   icon: {
  //     active: <ActiveInfoIcon/>,
  //     unActive: <UnActiveInfoIcon/>,
  //   },
  // },
  // {
  //   label: 'ИГХ',
  //   value: '4',
  //   disabled: true,
  // },
  // {
  //   label: 'FISH',
  //   value: '5',
  //   disabled: true,
  // },
  // {
  //   label: 'Вирусология',
  //   value: '6',
  //   disabled: true,
  // },
  // {
  //   label: 'Иммунология',
  //   value: '7',
  //   disabled: true,
  // },
];

const CreateReferralPage: FC<IStep> = ({ goNext, onCancelCreation, goBack }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(DEFAULT_OPTION);

  const handleContinue = useCallback(() => {
    dispatch(setReferralResearchType({ type: selected }));
    dispatch(getValidateAmbulatoryCard())
    dispatch(resetPatients())
    goNext && goNext();
  }, [goNext, dispatch, selected]);

  // useEffect(() => {
  //   dispatch(getValidateAmbulatoryCard())
  // }, [])
  //
  // useEffect(() => {
  //   dispatch(resetPatients())
  // }, [])

  return (
    <>
      <TTitle>Тип  направления</TTitle>
      <TWrapper>
        {
          options.map(({ label, value, disabled, icon, tooltip_id }) => {

            const tooltipData = prepareTooltipData(tooltip_id);

              return <Radio
                key={value}
                label={label}
                onChange={setSelected}
                value={value}
                name='researchType'
                selected={selected}
                disabled={disabled}
                icon={icon}
                tooltip_id={tooltip_id}
                tooltipData={tooltipData}
              />
            }
          )}
      </TWrapper>

      <TBottomButtonsContainer>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} disabled onClick={goBack}>Назад</Button>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onCancelCreation}>Отмена</Button>
        <Button size={SIZE.SMALL} onClick={handleContinue}>Далее</Button>
      </TBottomButtonsContainer>
    </>
  );
}

export default CreateReferralPage;
