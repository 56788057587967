import styled from 'styled-components';

export const TSectionTitle = styled.div<{ width?: string, color?: string }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme, color }) => color ?? theme.colors.black[200]};
  width:${({ width }) => width ?? '100%'};
  text-align: center;
  margin: 20px 0 16px;
`;

export const TExpWR = styled.div`
  margin-top: 12px;
  margin-bottom: 54px;
`;

export const THeaderTable = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  position: relative;
  
  font-size: 16px;
  line-height: 16px;
  & :first-child {
    margin-bottom: 6px;
  }

  padding-top: 12px;
  padding-bottom: 12px;
`;

export const THeaderTableRow = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  color: ${({ theme }) => theme.colors.gray[500]};
  margin-bottom: 6px;
  & div:first-child {
    font-weight: 600;
    width: 170px;
    /* margin-bottom: 6px; */
  }
  & div:last-child {
    flex-flow: column;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: -2px;
    & > div {
      width: 100%;
      font-weight: 400;
      margin-bottom: 0px;
    }
  }
  .columnData {
    flex-flow: column;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
  }
  .link {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: ${({ theme, color }) => color ?? theme.colors.product.primaryHover};
      text-decoration-thickness: 1px;
      text-underline-offset: 4px;
      text-decoration-style: dashed;
      text-decoration-skip-ink: auto;
      &:hover {
        color: ${({ theme, color }) => color ?? theme.colors.product.primaryHover};
      }
  }
`;

export const THeaderTableRowTitle = styled.div`
  display: flex;
`;
export const THeaderTableRowContent = styled.div`
  display: flex;
`;

export const TLabTable = styled.table<{ layout?: string }>`
  table-layout: ${({ layout }) => layout ? layout : 'fixed'};
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray[500]}; /* this draws the table border  */
  thead {
    display: table-header-group;
    vertical-align: middle;
    text-align: left;
    border-color: inherit;
  }
  td {
    height: 52px;
    padding: 2px 2px 2px 10px;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    color: ${({ theme }) => theme.colors.black[200]};
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .head {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.gray[500]}; 
    height: 52px;
  }
  .violet {
    background-color: ${({ theme }) => theme.colors.violet[100]}; 
  }
  .borderTop {
    border-top: 1px solid ${({ theme }) => theme.colors.gray[500]};
  }
  .topRadiusR {
    border-top-right-radius: 10px;
  }
  .topRadiusL {
    border-top-left-radius: 10px;
  }
  .bottomRadiusR {
    border-bottom-right-radius: 10px;
  }
  .bottomRadiusL {
    border-bottom-left-radius: 10px;
  }
  .noMarginItems > div {
    margin: 0;
  }
  .itemsTopRadius {
    & :first-child {
      border-top-left-radius: 10px;
    }
    & :last-child {
      border-top-right-radius: 10px;
    }
  }
  .font10 {
    font-size: 10px;
  }
`;