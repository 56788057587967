import styled from 'styled-components';
import Tooltip from '../../../../../components/shared/Tooltip';

export const TInputsWr = styled.div`
  display: flex;
  position: relative;

 & > * {
    margin-left: 3px;
    margin-top: 2px;
    width: 50%;
    height: 62px;
  } 
  & > :first-child {
    width: 6%;
  }
  & > :nth-child(2) {
    width: 48%;
  }
  & > :nth-child(3) {
    width: 48%;
  }
  & > *:last-child {
    margin-right: 0;
  }
  small{
    color: red;
    width: 50%;
    margin-right: 3px;
  }
`;

export const TFieldWr = styled.div`
  width: 690px;  
`
export const TFakeRadio = styled.div<{active?:boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  cursor: pointer;
  div {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    border: 3px solid ${({ theme, active }) => active ? 'white' : theme.colors.gray[100]};
    outline:1px solid ${({ theme, active }) => active ? '#52E0A4' : theme.colors.gray[400]};
    background-color: ${({ theme, active }) => active ? '#52E0A4' : theme.colors.gray[100]};
  }
`;

export const TTooltipPrompt = styled(Tooltip)`
  padding: 20px 16px !important;
  width: 203px;
`

export const TPromptText = styled.ul`
  margin: 0;
  padding: 0 0 0 12px;
  display:block;
  width:200px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  word-wrap: normal;
  color: ${({ theme }) => theme.colors.gray[500]};
  li {
    width: 180px;
  }
`
export const TMenuItem = styled.div<{ disabled?: boolean, visible?: boolean;}>`
  height: 30px;
  width: 30px;

  visibility: ${({ visible }) => visible ? 'hidden' : 'visible'};

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: -38px;
  top: 14px;

  box-sizing:border-box;
  border: 1px solid ${({ theme }) => theme.colors.gray[400]};
  border-radius: 15px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  background-color: ${({ theme }) => theme.colors.gray[100]};

  :hover {
    /* border-color: ${({ theme }) => theme.colors.alert.notification}; */
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const TMenuIcon = styled.img`
  display: inline-block;
  width: 30px;
  height: 30px;
`;

export const HideInput = styled.input`
  display: none;
`

export const ErrorMessage = styled.div`
  margin-top: 5px;
  color: ${({theme}) => theme.colors.alert.warning};
  font-size: 14px;
`