import { FC } from "react";
import { TWarningDescription, TWarningHeader, TWarningWrapper } from "./styled";

interface IComponentProps {
  title?: string;
  description?:string;
}

const NoDataToShow:FC<IComponentProps> = ({title,description}) => {
  return (
    <TWarningWrapper>
      <TWarningHeader>{title? title : 'Нет данных'}</TWarningHeader> 
      <TWarningDescription>{description? description : 'пройдите предыдущие этапы'}</TWarningDescription>
    </TWarningWrapper>
  )
}

export default NoDataToShow;