import styled from 'styled-components';

export const TFormContainer = styled.form`
  
  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TBody = styled.div`
  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TIDInputWr = styled.div`
  position: relative;
`;

export const THint = styled.ul<{ focused: boolean }>`
  position: absolute;
  display: ${({ focused }) => focused ? 'flex' : 'none'};
  flex-direction: column;
  align-items: start;
  width: 300px;
  width: max-content;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray[400]};
  top: -16px;
  right: -280px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 12px;
  border-radius: 10px;
  li {
    margin-left: 12px;
    b {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray[500]};
    }
  } 
  ::before {
    content: '';
    display: block;
    position: absolute;
    left:-12px;
    top:calc(50% - 10px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 14px 10px 0;
    border-color: transparent #fff transparent transparent;
  }
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.07), 1px -1px 6px 0px rgba(0, 0, 0, 0.07);
`;

export const TInputsWr = styled.div`
  display: flex;
  
  & > * {
    margin-right: 3px;
    width: 50%;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const TInfo = styled.p`
  font-style: normal;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[500]};
  margin: 0;
  padding-bottom: 10px;
`;

export const TInfoSecondary = styled.p<{dark? : boolean}>`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme, dark }) => dark ? theme.colors.black[200] : theme.colors.gray[500]};
  margin-bottom: 12px;
`;

export const TTitlePatientData = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black[200]};
  margin-bottom: 18px;
  margin-top: 40px;
  width: 100%;
`;

export const TFormWrapper = styled.div`
  min-height: 470px;
`;

export const TBlockCreateNewPatient = styled.div`
  width: 309px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

export const TMessage = styled.p<{disabled? : boolean}>`
  font-size: 16px;
  margin-left: 5px;
  padding-bottom: 1px;
  color:${({ theme, disabled }) => disabled ? theme.colors.gray[400] : theme.colors.gray[500]};
  font-weight: 500;
`;

export const TRowWr = styled.div<{ direction?:string }>`
  display: flex;
  justify-content:  ${({direction}) => direction ? direction : 'space-around'};
  align-items: baseline;
  width: 100%;
  margin: 40px 0 20px;
`;

export const TEditButton = styled.button<{ active?:boolean }>`
  width: max-content;
  padding: 0 10px;
  height: 20px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue[200]  };
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
  border: 0 !important;

  &:disabled {
    cursor: default;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]} !important;
    color: ${({ theme }) => theme.colors.gray[200]};
  }

  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.product.primaryHover};
  }

  &:active, &:focus {
    outline-style: none;
    outline-width: 0px !important;
    outline-color: none !important;

  }
  &::-moz-focus-inner {
    border: 0 !important;
  }
`;