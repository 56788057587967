import { FC, useMemo } from "react";
import { TExpWR, THeaderTable, THeaderTableRow, THeaderTableRowContent, THeaderTableRowTitle, TLabTable } from "../styled";
import { useSelector } from "react-redux";
import { checkPermitViewExperement } from "../../../../../store/auth/selectors";

interface IAdvisor {
  advisorUlid: string
  fullName: string
  specialization: string
}
interface IComponentProps {
  experements: any[];
  blueprintId: number;
  referralID: string;
  executorMap: any;
  goToExperement: (
    experimentID: number,
    experimentMethod: string,
    experimentMarker: string) => void;
  advisors?:IAdvisor[];
  [index: string]: any;
}

const headers = ['Маркер', 'Клон', 'Результат исследования', 'Характеристика окрашивания'];

export const IhcAlk: FC<IComponentProps> = ({ advisors = [], blueprintId, experements, executorMap, goToExperement }) => {
  //Permissions
  const ableToViewExperiment = useSelector(checkPermitViewExperement);
  //Formeted experimen number
  const experimentNumber = useMemo(() => blueprintId?.toString()?.padStart(6, "0")?.replace(/(?=(?:.{3})*$)/g, ' '),[blueprintId]);
  return <>
    {experements?.map(exp => (
      <TExpWR key={(exp?.id + blueprintId) + 'experement'}>
        <THeaderTable>
          <THeaderTableRow>
            <THeaderTableRowTitle>Исследование: </THeaderTableRowTitle>
            {ableToViewExperiment ? <THeaderTableRowContent className="link" onClick={() => goToExperement(blueprintId, 'ihc', exp?.name)}>{experimentNumber}</THeaderTableRowContent> :<div>{experimentNumber}</div>}
          </THeaderTableRow>
          <THeaderTableRow>
            <THeaderTableRowTitle>Исполнитель: </THeaderTableRowTitle>
            <THeaderTableRowContent>{`${executorMap?.[exp?.executor]?.fullName}, ${executorMap?.[exp?.executor]?.title}`}</THeaderTableRowContent>
          </THeaderTableRow>
          <THeaderTableRow>
            <THeaderTableRowTitle>Другие: </THeaderTableRowTitle>
            <THeaderTableRowContent>
              <div>{`${executorMap?.[exp?.labAssistant]?.fullName}, ${executorMap?.[exp?.labAssistant]?.title}`}</div>
              {advisors?.map((adv: IAdvisor) => <div key={adv?.advisorUlid}>{adv?.fullName}, {adv?.specialization}</div>)}</THeaderTableRowContent>
          </THeaderTableRow>
        </THeaderTable>
        <TLabTable layout='auto'>
          <tbody>
            <tr className="head violet itemsTopRadius">
              {headers?.map((header: string) => <td key={header}>{header}</td>)}
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td style={{width:'10%'}}>{exp?.name ?? '--'}</td>
              <td style={{width:'10%'}}>{exp?.clone ?? '--'}</td>
              <td style={{width:'20%'}}>{exp?.result ?? '--'}</td>
              <td>{exp?.characteristic ?? '--'}</td>
            </tr>
            <tr>
              <td colSpan={4} className={`head violet`}>Клинико-лабораторное заключение</td>
            </tr>
            {!!exp?.conclusion && <tr>
              <td colSpan={4} className={`${!exp?.notes && 'bottomRadiusR'}`}>
                {exp?.conclusion ?? '--'}.
              </td>
            </tr>}
            {!!exp?.notes && 
            <tr>
              <td className="bottomRadiusR" colSpan={4}>{exp?.notes}</td>
            </tr>
            }
          </tbody>
        </TLabTable>
      </TExpWR>)
    ) ?? null}</>
}

export default IhcAlk;