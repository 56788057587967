import { forwardRef, useCallback, useRef } from 'react';
import { CustomInput, CustomSpan, TLabel, TTile } from './styles';

interface ISwitcher {
  title: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onChange?: (checked: boolean) => void;
  isError?: boolean;
}

const Switcher = forwardRef<HTMLInputElement | null, ISwitcher>((props, ref) => {
  const { title, isChecked, isDisabled, onChange, isError } = props;
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef = ref || defaultRef;
  const handleChange = useCallback((checked:string) => { 
    if(!onChange) return;
    onChange(checked === 'on' ? true : false);
  },[onChange])

  return (
    <TLabel htmlFor={title}>
      <CustomInput id={title} type="checkbox"  ref={resolvedRef} disabled={isDisabled ?? true}  onChange={({target}) => handleChange(target.value)}/>
      <CustomSpan isChecked={isChecked} isError={isError}/>
      <TTile isChecked={isChecked}>{title}</TTile>
    </TLabel>
  );
});


export default Switcher;
