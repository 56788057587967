import {TResearchData} from "./styled";
import {TExaminationTable} from './styled';
import {examinationTableColumns} from "./ExaminationTable/examinationTableColumns";
import { WrapperTable } from './ExaminationTable/styles';
import { FC } from "react";

interface IResearchDataProps {
  examination: any;
  hasPermit?:boolean;
}

const ExaminationDataTable: FC<IResearchDataProps> = ({examination,hasPermit}) => {
  return (
    <WrapperTable>
      {examination ?
        <TExaminationTable items={examination} columns={examinationTableColumns()} hasPermit={hasPermit}/>
        : <TResearchData>
          Исследования по выбранному направлению отсутствуют
        </TResearchData>
      }
    </WrapperTable>
  )

}

export default ExaminationDataTable;
