import { FC, useLayoutEffect, useMemo, useState } from 'react';
import { TBiomatContainer, TSection, TSectionHeader } from '../styled';
import { IConclusionData, IModulePatient, IMorphologyData } from '../../../../store/molProConclusion/model';
import NotEditedInput from '../../../../components/shared/NotEditedInput';

interface IComponentProps {
  defaultData: IConclusionData | null;
  [index: string]: any;
}

export const Patient: FC<IComponentProps> = ({defaultData}) => {
  const [patientData,setPatientData] = useState<IModulePatient>();
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());

  useLayoutEffect(() => {
    if(!!defaultData && defaultData?.modulePatient){
      setPatientData(defaultData.modulePatient);
      setResetFormKey(Date.now());
    }
  },[defaultData,setResetFormKey,setPatientData]);

  const [standartTNM,standartStage] = useMemo(()=> {
    if(!patientData) return ['',''];
    let {tnm,tnmStage,tnmNonApplicable,tnmUnknown,stageNonApplicable,stageUnknown} = patientData; 

    let TNM_Applicable = tnmNonApplicable ? 'Неприменимо' : '';
    let TNM_Unknown = tnmUnknown ? 'Не установлена' : '';

    let Stage_Applicable = stageNonApplicable ? 'Неприменимо' : '';
    let Stage_Unknown = stageUnknown ? 'Не установлена' : '';


    let TNM = TNM_Applicable || TNM_Unknown ? (TNM_Applicable || TNM_Unknown) : tnm;
    let TNMStage = Stage_Applicable || Stage_Unknown ? (Stage_Applicable || Stage_Unknown) : tnmStage?.replace('--','');

    return [TNM,TNMStage];
  },[patientData]);


  return <>
      <TSection key={`${resertFormKey}_Patient`}>
        <TSectionHeader>Данные пациента</TSectionHeader>
        <NotEditedInput width={'49%'}   label='Дата регистрации биоматериала'        placeholder={'Дата регистрации не определена'}>{patientData?.bioAcceptDate ?? '--'}</NotEditedInput>
        <NotEditedInput width={'49%'}   label='№ амбулаторной карты направившего УЗ' placeholder={'Номер амбулаторной карты не определен'}>{patientData?.patientAmbulatoryCards ?? '--'}</NotEditedInput>
        <NotEditedInput width={'100%'}  label='ФИО пациента'    placeholder={'ФИО пациента не определено'}>{patientData?.patientFullName ?? '--'}</NotEditedInput>
        <NotEditedInput width={'49%'}   label='Пол'             placeholder={'Пол не определен'}>{patientData?.patientGender ?? '--'}</NotEditedInput>
        <NotEditedInput width={'49%'}   label='Дата рождения'   placeholder={'Дата рождения не определена'}>{patientData?.patientBirthDay ?? '--'}</NotEditedInput>
        <NotEditedInput width={'100%'}  label='Оказание услуг'  >{patientData?.paid ?? '--'}</NotEditedInput>
      </TSection>
      <TSection key={`${resertFormKey}_Clinical`}>
        <TSectionHeader>Клинические данные</TSectionHeader>
        <NotEditedInput width={'100%'}  label='Клинический диагноз' placeholder={'Клинический диагноз отсутствует'} >{patientData?.clinicalDiagnosis}</NotEditedInput>
        {(!!standartTNM || !!standartStage) && <>
          <NotEditedInput width={'49%'}   label='Классификация TNM'>{standartTNM ?? ''}</NotEditedInput>
          <NotEditedInput width={'49%'}   label='Стадия'>{standartStage ?? ''}</NotEditedInput>
      </>}
        <NotEditedInput width={'100%'}  label='Направившее учреждение, отделение' placeholder='Направившее учреждение, отделение не определено' >{patientData?.referralCreator ?? '--'}</NotEditedInput>
      </TSection>
      <TSection key={`${resertFormKey}_Bio`}>
        <TSectionHeader>Материал для проведения исследований</TSectionHeader>
        <NotEditedInput width={'100%'}  label='Тип материала'  placeholder='Тип материала не определен'>{patientData?.materialType ?? '--'}</NotEditedInput>

        {!!patientData?.morphologyData && (patientData?.morphologyData ?? [])?.map((bio: IMorphologyData) => {
          return (
            <TBiomatContainer key={bio.codeNumber + bio.cancerousCellPercentage}>
              <NotEditedInput width={'49%'}   label='Парафиновый блок' placeholder='Парафиновый блок не определен'>{bio.codeNumber}</NotEditedInput>
              <NotEditedInput width={'49%'}   label='Процент опухолевых клеток' placeholder='Процент опухолевых клеток не определен'>{`${bio?.cancerousCellPercentage ?? '0'}%`}</NotEditedInput>
            </TBiomatContainer>
          )
        })}
        {!!patientData?.materialBlood &&
          <NotEditedInput width={'49%'}   label='Кровь' placeholder='Номер крови не определен'>{patientData.materialBlood  ?? '--'}</NotEditedInput>}
      </TSection>
    </>
}

export default Patient;