import styled from 'styled-components';
import { CSSProperties } from "react";
import { defaultThemeType } from "../../../styles/theme";

export const TWrapper = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  table{
    table-layout: fixed;
    width: 100%;
  }

  td {
    position: relative;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    
    color: ${({ theme }) => theme.colors.black[200]};
  }
  
  td.left {
    text-align: left;
    padding-left: 8px;
  }

  div[class*="_menu"] {
    width: max-content;
    min-width: 120px;
  }
  
  .New {
    background-color: ${({ theme }) => theme.colors.orange[100]};
  }
  .IN_PROGRESS {
    background-color: ${({ theme }) => theme.colors.blue[200]};
  }
  .COMPLETE {
    background-color: ${({ theme }) => theme.colors.green[100]};
  }
  .CANCELLED {
    background-color: ${({ theme }) => theme.colors.alert.warning};
  }

  td.error{
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.alert.warning};
  }

  .implementer {
    min-width: 100%;
    max-width: 100%;
    margin: 20px 0;

    label {
      border: none;
      margin-bottom: 4px;
    }
    textarea{
      height: 64px;
      /* padding: 15px 15px 0 15px; */
      padding: 18px 30px 18px 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      outline: none;
      color: ${({theme}) => theme.colors.gray[500]};

      :focus-visible,:hover,:active {
        border: 1px solid ${({theme}) => theme.colors.gray[500]};
        outline: none;
      }
    }
  }

`;

export const TMenuIcon = styled.img<{ disabled?: boolean, size?: number, left?: number, right?: number }>`
  display: inline-block;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  filter: ${({ disabled }) => disabled ? 'grayscale(1)' : 'grayscale(0)'};
  width:  ${({ size }) => size ? `${size}px` : '22px'};
  height: ${({ size }) => size ? `${size}px` : '22px'};
  margin-left:  ${({ left }) => left ? `${left}px` : '0px'};
  margin-right: ${({ right }) => right ? `${right}px` : '0px'};
  :hover {
    background-color: ${({ theme }) => theme.colors.gray[100]};
    filter: grayscale(1);
  }
`;

export const TBtnWr = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 24px;
  /* & button {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
  } */
  & :last-child {
    margin-left: 2%;
  }
`;

export const CustomButton = styled.button<{ disabled: boolean; primary?: boolean; height?: number; width?:number;}>`
  position: relative;
  width: ${({width}) => width ? `${width}px` : `${310}px`};
  min-width: max-content;
  padding: 0 45px;
  height: ${({height}) => height ? `${height}px` : `${55}px`};
  border-radius: 10px;
  border: ${({ theme, disabled }) => disabled ? `2px solid ${theme.colors.gray[300]}` : `2px solid ${theme.colors.blue[200]}`};
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  background-color: ${({ theme, primary, disabled }) => primary ? (disabled ? theme.colors.gray[300] : theme.colors.blue[200]) : 'transparent'};
  color: ${({ theme, primary, disabled }) => primary ? theme.colors.white : (disabled ? theme.colors.gray[300] : theme.colors.blue[200])};
  &:hover {
    border-color: ${({ theme, disabled }) => disabled ? theme.colors.gray[300] : theme.colors.blue[200]};
  }
`


export const TButton = styled.button`
  width: 310px;
  height: 44px;
  border-radius: 10px;
  background: transparent;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.product.primaryActive};
  color:${({ theme }) => theme.colors.product.primaryActive};
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.product.primaryHover};
    color:${({ theme }) => theme.colors.product.primaryHover};
  }
  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.colors.neutral.disable};
    border: 1px solid ${({ theme }) => theme.colors.neutral.disable};
  }
`;

export const TRowWR = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
`

export const TIndicator = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const customStylesOptions = (theme: defaultThemeType): Record<string, unknown> => ({
  control: (state: CSSProperties, { isDisabled }: { isDisabled: boolean }) => ({
    ...state,
    outline: 'none',
    transition: 'all 0.2s ease-in-out 0s',
    boxShadow: 'none',
    cursor: 'default',
    padding: '0',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '28px',
    height: '60px',
    backgroundColor: '#fff',
    border: 'none',
    'div[class$="singleValue"]': {
      color: isDisabled ? theme.colors.gray[500] : theme.colors.black[200],
    },
    '&:hover': {
      borderColor: isDisabled ? 'transparent' : theme.colors.alert.border,
      cursor: isDisabled ? 'default' : 'pointer',
    },
  }),
  option: (state: CSSProperties, { isSelected }: { isSelected: boolean }) => ({
    ...state,
    height: 'auto',
    textAlign: 'center',
    backgroundColor: theme.colors.white,
    border: 'none',
    outline: 'none',
    color: isSelected ? theme.colors.product.primaryActive : theme.colors.black[200],
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out 0s',
    padding: '8px 14px 8px 12px',
    fontSize: '12px',
    lineHeight: '20px',
    width: 'max-content',
    margin: 'auto',
    borderRadius: '10px',
    disabled:{
      color: theme.colors.gray[500]
    },
    '&:hover': {
      backgroundColor: theme.colors.product.primaryHover,
      color: theme.colors.white,
    },
  }),
  dropdownIndicator: (
    state: CSSProperties,
    { selectProps: { menuIsOpen } }: { selectProps: { menuIsOpen: boolean } },
  ) => ({
    ...state,
    cursor: 'pointer',
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'all 0.2s ease-in-out 0s',
    textAlign: 'center',
  }),
  container: () => ({
    position: 'relative',
    textAlign: 'center !important',
  }),
  indicatorsContainer: () => ({}),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    padding: 0,
    textAlign: 'center !important',
    justifyContent: 'center !important',
  }),
  placeholder: (base: CSSProperties) => ({
    ...base,
    color: theme.colors.gray[200],
    textAlign: 'center !important',
  }),
});

export const TSendingErrorMessage = styled.div`
  position: absolute;
  left:-30px;
  top: -90px;
  width: 100%;
  height: max-content;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray[300]};
  border-radius: 10px;
  text-align: left;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  box-shadow: 1px -1px 6px rgba(0, 0, 0, 0.07), -1px 1px 6px rgba(0, 0, 0, 0.07);
  padding: 11px 24px;
  color: ${({ theme }) => theme.colors.gray[600]};
  i {
    display: inline-block;
    font-style: normal;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.alert.warning};
    padding-bottom: 5px;
    margin-left: 0 !important;
  }
  ::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -12px;
    border-width: 12px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.white} transparent transparent transparent;
    
}
`