import { createSlice } from '@reduxjs/toolkit';
import { ErrorsType, Nullable } from '../../types';
import { ReferralMarkerPriority, IMarkerTypeList } from './model';

export interface IMarkerInitialState {
  loading: boolean;
  data: ReferralMarkerPriority[];
  typeList: IMarkerTypeList[];
  errors: Nullable<ErrorsType>;
}

export const initialState: IMarkerInitialState = {
  loading: false,
  data: [],
  typeList:[],
  errors: null,
};

const markersSlice = createSlice({
  name: 'markers',
  initialState,
  reducers: {
    getMarkers: state => {
      state.loading = true;
    },
    getMarkersSuccess: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.errors = null;
    },
    getMarkersFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    resetMarkers: (state) => {
      state.data = [];
    },

    getMarkerTypesList: state => {
      state.loading = true;
    },
    getMarkerTypesListSuccess: (state, { payload }) => {
      state.typeList = payload;
      state.loading = false;
      state.errors = null;
    },
    getMarkerTypesListFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
  },
});

export const { 
  getMarkers, getMarkersSuccess, getMarkersFailure, resetMarkers,
  getMarkerTypesList, getMarkerTypesListSuccess, getMarkerTypesListFailure
} = markersSlice.actions

export default markersSlice.reducer;
