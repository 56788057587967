import { FC } from 'react';
// import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import Input from '../../../shared/Input';
import { TWrapper } from './styled';
import { getDepartmentLocalName, getOrganizationLocalName } from '../../../../store/organizations/selectors';
import { Profile, User } from '../../../../store/auth/model';

interface IRegistrarForm extends
  Pick<User, 'email'>,
  Omit<Profile, 'dateOfBirth' | 'specialization' | 'title' | 'ulid'> {}

const RegistrarBlock: FC<IRegistrarForm> = (
  { email, subdepartment, organizationCustom, department, firstName, lastName, middleName, organization, phoneNumber }
) => {
  const organizationLocalName = useSelector(getOrganizationLocalName(organization));
  const departmentLocalName = useSelector(getDepartmentLocalName(organization, department));

  return (
    <TWrapper>
      <Input
        name='organization'
        readOnly
        defaultValue={organizationLocalName}
      />
      {!!organizationCustom && (
        <Input
          name='organizationCustom'
          readOnly
          defaultValue={organizationCustom}
          marginTop={2}
        />
      )}
      {!!department && (
        <Input
          name='department'
          placeholder='Отделение'
          readOnly
          defaultValue={departmentLocalName}
          marginTop={2}
        />
      )}
      {subdepartment && (
        <Input
          name='subdepartment'
          placeholder='Лабораторное отделение/Кабинет'
          readOnly
          defaultValue={subdepartment}
          marginTop={2}
        />
      )}
      <Input
        name='fullName'
        placeholder='ФИО'
        readOnly
        defaultValue={`${lastName} ${firstName} ${middleName}`}
        marginTop={2}
      />
      {/* <InputMask
        mask="+375 (99) 999 99 99"
        defaultValue={phoneNumber}
        readOnly
        name='phoneNumber'
      >
        {(inputProps: any) => (
          <Input
            {...inputProps}
            readOnly
            placeholder='Телефон'
            type="text"
          />
        )}
      </InputMask>
      <Input
        name='email'
        defaultValue={email}
        type='text'
        readOnly
        placeholder='Email'
      /> */}
    </TWrapper>
  );
};

export default RegistrarBlock;
