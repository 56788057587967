import styled from 'styled-components';
function getColorCircleStatus(status: string, theme: any) {
  switch (status) {
    case 'bio_pending':
      return theme.colors.orange[100];
    case 'morphology_pending' :
      return theme.colors.orange[200];
    case 'markers_validation_pending':
      return theme.colors.alert.notification;
    case 'analysis_pending':
      return theme.colors.blue[200];
    case 'conclusion_pending':
      return theme.colors.product.primaryActive;
    case 'referral_pending' :
      return theme.colors.alert.checkedRadioButton;
    case 'referral_completed':
      return theme.colors.green[100];
    case 'New' :
      return theme.colors.green[100];
    case 'IN_PROGRESS' :
      return theme.colors.orange[100];
    case 'COMPLETE' :
      return theme.colors.product.primaryActive;
    case 'CANCELLED' :
      return theme.colors.alert.warning;
  }
  return theme.colors.alert.warning;
}

export const TReferralCard = styled.div<{ isActive?: boolean }>`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray[500]};
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 18px 12px;
  cursor: ${({ isActive }) => isActive ? 'pointer' : 'default'};

`;

export const TReferralCardId = styled.div`
  flex: 1;
  padding: 0 20px 0 10px;
`;

export const TReferralCardTitle = styled.div`
  flex: 3;
`;

export const TReferralCardDate = styled.div`
  flex: 1;
  padding: 0 20px;
`;

export const TReferralCardStatus = styled.div<{ status?: string }>`
  flex: 3;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 20px ;
  /* ::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-color: ${({ status, theme }) => status ? getColorCircleStatus(status, theme) : theme.colors.alert.warning};
    left: -16px;
    top: 8px;
    border-radius: 50%;
  } */
  .content {
    display: inline-block;
    width: 87%;
  }
  .status {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: ${({ status, theme }) => status ? getColorCircleStatus(status, theme) : theme.colors.alert.warning};
    border-radius: 10px;
  }
  /* ::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-color: ${({ status, theme }) => status ? getColorCircleStatus(status, theme) : theme.colors.alert.warning};
    left: -16px;
    top: 8px;
    border-radius: 50%;
  } */
  /* padding: 0 20px; */
  /* color: ${({ status, theme }) => status ? getColorCircleStatus(status, theme) : theme.colors.alert.warning}; */
`;
