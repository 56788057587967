import styled from 'styled-components';

export const ItemsWrapper = styled.div`
  width: 100%;
`;
export const TWrapper = styled.div`
  width: 100%;
`;

export const TTableWrapper = styled.div`
  padding: 21px 24px 0 24px;
`;
export const TTable = styled.div`
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
 
`;

export const HeaderItem = styled.div<{columnWidth: string}>`
  max-width: ${({ columnWidth }) => columnWidth};
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #96999C;
  padding-bottom: 8px;
  flex: 1;
  &:nth-child(1){
    padding-left: 20px;
  }
  &:nth-child(2) {
      margin-left: -5px;
  }
  &:nth-child(3) {
      margin-left: -10px;
  }
  &:nth-child(4) {
    margin-left: -15px;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const RowItemWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  border: ${({selected}) => selected ? '2px solid #7A78E9' : '1px solid #777777'};
  padding: 0 22px 0 16px;
  border-radius: 10px;
  margin-bottom: 2px;
  position: relative;
  cursor: pointer;
  height: 56px;
`;

export const RowItem = styled.div<{columnWidth: string,selected: boolean}>`
  max-width: ${({ columnWidth }) => columnWidth};
  flex-wrap: wrap;
  font-weight: ${({ selected }) => selected ? 600 : 400};
  color: ${({ selected, theme }) => selected ? theme.colors.black[200] : theme.colors.gray[500]};
  font-size: 12px;
  line-height: 18px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex: 1;
  word-wrap: anywhere;
  text-overflow: ellipsis;
  @media (max-width: 1440px) {
    &:nth-child(1) {
      width: 100px;
    }
    &:nth-child(2) {
      margin-left: 5px;
      max-width: 40px;
    }
    &:nth-child(3) {
      margin-left: 40px;
      max-width: 60px;
    }
    &:nth-child(4) {
      margin-left: 40px;
    }
  }
`;

export const WrapperTable = styled.div`
  padding-bottom: 40px;
  margin-bottom: 100px;
`
