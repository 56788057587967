import { createSlice } from '@reduxjs/toolkit';
import { ErrorsType, Nullable } from '../../types';
import { IAnalisysByMethod, MarkerFish, MarkerFishExperiment, MarkerIhcAlkExperiment, MarkerIhcExperiment } from './model';

export interface IAnalysisInitialState {
  loading: boolean;
  analysis: Nullable<IAnalisysByMethod>,
  analysisList: Nullable<IAnalisysByMethod[]>,
  newExperimentCompleted: Nullable<IAnalisysByMethod>,
  newBioStepUpdate: Nullable<MarkerIhcExperiment | MarkerIhcAlkExperiment | MarkerFishExperiment>,
  analisysupdate: Nullable<MarkerFish>,
  updatedConclusion: boolean
  errors: Nullable<ErrorsType>;
}

export const initialState: IAnalysisInitialState = {
  loading: false,
  analysis: null,
  analysisList: null,
  newExperimentCompleted: null,
  newBioStepUpdate: null,
  analisysupdate: null,
  updatedConclusion: false,
  errors: null,
};

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    getAnalysisByMethod: state => {
      state.loading = true;
    },
    getAnalysisByMethodSuccess: (state, { payload }) => {
      state.analysis = payload;
      state.newExperimentCompleted = null;
      state.loading = false;
      state.errors = null;
    },
    getAnalysisByMethodFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },

    getAnalysisListByMethod: state => {
      state.loading = true;
    },
    getAnalysisListByMethodSuccess: (state, { payload }) => {
      state.analysisList = payload;
      state.newExperimentCompleted = null;
      state.loading = false;
      state.errors = null;
    },
    getAnalysisListByMethodFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },

    resetAnalysis: state => {
      state.analysis = null;
      state.analysisList = null;
      state.newExperimentCompleted = null;
      state.newBioStepUpdate = null;
      state.analisysupdate = null;
      state.updatedConclusion = false;
      state.loading = false;
      state.errors = null;
    },

    saveExperiment: state => {
      state.loading = true;
    },
    saveExperimentSuccess: (state, { payload }) => {
      state.newExperimentCompleted = payload;
      state.loading = false;
      state.errors = null;
    },
    saveExperimentFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },

    updateAnalisys: state => {
      state.loading = true;
    },
    updateAnalisysSuccess: (state, { payload }) => {
      state.analisysupdate = payload;
      state.loading = false;
      state.errors = null;
    },
    updateAnalisysFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    saveExamination: state => {
      state.loading = true;
    },
    saveExaminationSuccess: (state) => {
      state.analysis = null;
      state.newExperimentCompleted = null;
      state.loading = false;
      state.errors = null;
    },
    saveExaminationFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },


    nextConclusionStep: state => {
      state.loading = true;
    },
    nextConclusionStepSuccess: (state) => {
      state.analysis = null;
      state.newExperimentCompleted = null;
      state.loading = false;
      state.errors = null;
    },
    nextConclusionStepFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },


    postConclSensData: state => {
      state.loading = true;
    },
    postConclSensDataSuccess: (state, { payload }) => {
      state.analysis = null;
      state.newExperimentCompleted = payload;
      state.loading = false;
      state.errors = null;
    },
    postConclSensDataFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },


    postConclToxicityData: state => {
      state.loading = true;
    },
    postConclToxicitySuccess: (state, { payload }) => {
      state.analysis = null;
      state.newExperimentCompleted = payload;
      state.loading = false;
      state.errors = null;
    },
    postConclToxicityFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },


    conclusionUpdate: state => {
      state.loading = true;
    },
    conclusionUpdateSuccess: (state, { payload }) => {
      state.updatedConclusion = payload;
      state.newExperimentCompleted = null;
      state.analysis = null;
      state.loading = false;
      state.errors = null;
    },
    conclusionUpdateFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },

    createIHSExperement: state => {
      state.loading = true;
    },
    createIHSExperementSuccess: (state, { payload }) => {
      state.newBioStepUpdate = payload;
      state.loading = false;
    },
    createIHSExperementFailure:(state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    }, 

    createIHSStep: state => {
      state.loading = true;
    },
    createIHSStepSuccess: state => {
      state.analysis = null;
      state.analysisList = null;
      state.newExperimentCompleted = null;
      state.newBioStepUpdate = null;
      state.updatedConclusion = false;
      state.loading = false;
      state.errors = null;
    },
    createIHSStepFailure:(state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    }, 

  }
});

export const {
  getAnalysisByMethod,
  getAnalysisByMethodSuccess,
  getAnalysisByMethodFailure,

  getAnalysisListByMethod,
  getAnalysisListByMethodSuccess,
  getAnalysisListByMethodFailure,

  resetAnalysis,
  saveExperiment,
  saveExperimentSuccess,
  saveExperimentFailure,

  updateAnalisys,
  updateAnalisysSuccess,
  updateAnalisysFailure,

  saveExamination,
  saveExaminationSuccess,
  saveExaminationFailure,

  nextConclusionStep,
  nextConclusionStepSuccess,
  nextConclusionStepFailure,

  postConclSensData,
  postConclSensDataSuccess,
  postConclSensDataFailure,

  postConclToxicityData,
  postConclToxicitySuccess,
  postConclToxicityFailure,

  conclusionUpdate,
  conclusionUpdateSuccess,
  conclusionUpdateFailure,

  createIHSExperement,
  createIHSExperementSuccess,
  createIHSExperementFailure,

  createIHSStep,
  createIHSStepSuccess,
  createIHSStepFailure,
} = analysisSlice.actions;

export default analysisSlice.reducer;