import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSearchParams } from '../../hooks/urlHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail } from '../../store/auth/thunkActions';
import { getTokens } from '../../store/auth/selectors';
import { 
  loadLocalStorageData, 
  resetLocalStorageData, 
  saveLocalStorageData } from '../../utils/localStorageHelpers';
import { ROUTES } from '../../constants/routes';

const EmailVerificationPage: FC = () => {
  const { search } = useLocation();
  const tokens = useSelector(getTokens);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  async function handleLocalStorageSession() {
    let isSessionEnded:boolean = false;
    let localStorageSessionStarted: string = await loadLocalStorageData('dataLastUpdate');
  
    if (!localStorageSessionStarted) {
      await saveLocalStorageData('dataLastUpdate', Date.now().toString());
    } else {
      isSessionEnded = (Date.now() - +localStorageSessionStarted) > 3_600_000;
    }
    if (isSessionEnded) resetLocalStorageData();
  }

  useEffect(() => {
    const token = getSearchParams(search)['token'];
    if (token) {
      dispatch(verifyEmail(token));
    }
  }, [search, dispatch])

  useEffect(() => {
    if (tokens?.access) {
      handleLocalStorageSession();
    
      navigate(ROUTES.UPDATE_PERSONAL_INFORMATION, { replace: true });
    }
  }, [tokens?.access, navigate])

  return null;
}

export default EmailVerificationPage;
