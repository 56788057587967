import { createSlice } from '@reduxjs/toolkit';
import { Localization } from './model';
import { ErrorsType, Nullable } from '../../types';

export interface ILocalizationInitialState {
  loading: boolean;
  data: Localization[];
  errors: Nullable<ErrorsType>;
}

export const initialState: ILocalizationInitialState = {
  loading: false,
  data: [],
  errors: null,
};

const localizationsSlice = createSlice({
  name: 'localizations',
  initialState,
  reducers: {
    getLocalizations: state => {
      state.loading = true;
    },
    getLocalizationsSuccess: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.errors = null;
    },
    getLocalizationsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
  },
});

export const { getLocalizations, getLocalizationsSuccess, getLocalizationsFailure } = localizationsSlice.actions

export default localizationsSlice.reducer;
