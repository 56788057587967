//template for table cell with block or slide number
import {TMenuButton, TTableRowWR, TTooltip} from "../styled";
import tableMenuIconActive from "../../../../icons/table-menu-icon-active.svg";
import {FC} from "react";

type CodeTableCellProps = {
    block?: any,
    slide?: any,
    toolTipActive:any,
    setToolTipActive: any,
    tooltipData:any,
    color: string,
}

const CodeTableCell:FC<CodeTableCellProps> = (props) => {
    const {block, slide, toolTipActive, setToolTipActive, tooltipData, color } = props;
    let isBlock = !!block?.code;
    let isSlide = !!slide?.code;

    if(!isBlock && !isSlide) return <td className={color}><TTableRowWR><span>--</span></TTableRowWR></td>;

    let cellID = isBlock ? `block-${block.code}` : `slide-${slide.code}`;
    return (
        <td className={color}>
            {/* block/slide row */}
            <TTableRowWR>
                {/* tooltip */}
                <TTooltip
                    place='top'
                    id={cellID}
                    afterShow={() => setToolTipActive(cellID)}
                    afterHide={() => setToolTipActive('')}
                />

                {/* block/slide content */}
                <span>{isBlock ? block.code : slide.code}</span>

                {/* menu button */}
                <TMenuButton
                    src={tableMenuIconActive}
                    active={toolTipActive === cellID}
                    data-for={cellID}
                    data-tip={tooltipData(isBlock ? block : slide)}
                />
            </TTableRowWR>
        </td>
    );
};

export default CodeTableCell;

