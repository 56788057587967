import styled from 'styled-components';

export const TWrapper = styled.div`
  display: flex;
  width: max-content;
  
  & > * {
    margin-right: 96px;
  }
  & > *:last-child {
    margin-right: 0;
  }
  &.move-top {
    position: absolute;
    top:37px;
    -webkit-transition:top 1s; 
    -moz-transition: top 1s; 
    -ms-transition: top 1s; 
    -o-transition: top 1s; 
    transition: top 1s;  
    z-index: 4;
  }
  &.move-down {
    position: absolute;
    top:116px;
    -webkit-transition:top 1s; 
    -moz-transition: top 1s; 
    -ms-transition: top 1s; 
    -o-transition: top 1s; 
    transition: top 1s;  
    z-index: 4;
  }
  &.stay-down {
    position: absolute;
    top:116px;
    z-index: 4;
  }
`;

export const TCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  position: relative;

  &:before {
    top: 15px;
    left: -98px;
    display: block;
    width: 98px;
    content: '';
    position: absolute;
    height: 1px;
  }
`;

export const TTitle = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  display: block;
  top: 40px;
  position: absolute;
`;

export const TStepWrapper = styled.div<{ active: boolean, completed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  
  &:first-child {
    & ${TCircle}:before {
      display: none;
    }
  }
  
  & ${TCircle} {
    background-color: ${({ theme, active, completed }) => active || completed ? theme.colors.alert.checkedRadioButton : 'transparent'};
    border: 1px solid ${({ theme, active, completed }) => active || completed ? theme.colors.alert.checkedRadioButton : theme.colors.gray[200]};
  }
  
  & ${TCircle}:before {
    background-color: ${({ theme, active, completed }) => active || completed ? theme.colors.alert.checkedRadioButton : theme.colors.gray[200]};
  }
  
  & ${TTitle} {
    color: ${({ theme, active, completed }) => active || completed ? theme.colors.black[200] : theme.colors.gray[200]};
  }
`;
