import {STATUS} from "./constants";

const getTooltipMessage = (status: string, statusCancellation: boolean, prepareStep?: boolean) => {

  if(status === 'bio_pending' && !statusCancellation) return 'Новое направление, лаборатория ожидает получения биоматериала'
  if(status === 'morphology_pending' && !statusCancellation) return 'Биоматериал принят, ожидается проведение морфологического исследования'
  if(status === 'markers_validation_pending' && !statusCancellation) return 'Морфологическое исследование завершено, ожидается валидация исследуемых маркеров'
  if(status === 'analysis_pending' && !statusCancellation && !prepareStep) return 'Список исследований сформирован, ожидается их реализация'
  if(status === 'analysis_pending' && !statusCancellation && prepareStep) return 'Все исследования завершены, можно начать формирование заключения'
  if(status === 'conclusion_pending' && !statusCancellation) return 'Все исследования завершены, ожидается формирование заключения'
  if(status === 'referral_pending' && !statusCancellation) return  'Направление отменено, пройдите внутрь для просмотра причины'
  if(status === 'referral_canceled' && !statusCancellation) return  'Направление отменено, пройдите внутрь для просмотра причины'
  if(status === 'referral_completed' && !statusCancellation) return  'Направление успешно закрыто, пройдите внутрь для просмотра заключения'
  if(statusCancellation) { // @ts-ignore
    return `Направление отмененено на этапе: ${STATUS[status]}`
  }
};

export default getTooltipMessage;