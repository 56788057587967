import Select from 'react-select';
import styled from 'styled-components';
import { CSSProperties } from 'react';
import { defaultThemeType } from '../../../../../styles/theme';

const INPUT_HEIGHT = 33;

const customStyles = (theme: defaultThemeType, isYearSelect: boolean): Record<string, unknown> => ({
  control: (state: CSSProperties) => ({
    ...state,
    border: 0,
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    outline: 'none',
    minHeight: 'none',
    transition: 'all 0.2s ease-in-out 0s',
    boxShadow: 'none',
    height: `${INPUT_HEIGHT}px`,
    cursor: 'pointer',
    textTransform: 'capitalize',
  }),
  option: (state: CSSProperties, { isSelected }: { isSelected: boolean }) => ({
    ...state,
    height: 'auto',
    textAlign: 'left',
    backgroundColor: theme.colors.white,
    border: 'none',
    outline: 'none',
    color: isSelected ? theme.colors.product.primaryActive : theme.colors.black[200],
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out 0s',
    padding: '8px 14px 8px 12px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    '&:hover': {
      color: theme.colors.black[200],
    },
    textTransform: 'capitalize',
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
    borderRadius: '3px',
    background: theme.colors.white,
    maxHeight: 'none',
    margin: '0 auto',
    zIndex: 6,
  }),
  menuList: (base: CSSProperties) => ({
    ...base,
    borderRadius: '8px',
    background: theme.colors.white,
    padding: '0',
    maxHeight: '224px',
  }),
  dropdownIndicator: (
    state: CSSProperties,
    {selectProps: {menuIsOpen}}: { selectProps: { menuIsOpen: boolean } },
  ) => ({
    ...state,
    cursor: 'pointer',
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    textAlign: 'center',
    transition: 'all 0.2s ease-in-out 0s',
  }),
  container: () => ({
    position: 'relative',
    // textAlign: 'left !important',
  }),
  indicatorsContainer: () => ({}),
  menuPortal: (base: CSSProperties) => ({
    ...base,
    zIndex: 10,
    left: isYearSelect ? '190px' : '68px',
    top: '22px',
  }),
  valueContainer: (base: CSSProperties) => ({
    ...base,
  }),
});

const TSelect = styled(Select)``;

export { customStyles, TSelect };
