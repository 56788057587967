import styled from 'styled-components';
import Select from './Select';

export const TWrapper = styled.div`
  padding: 24px 32px 0;
  display: flex;
  justify-content: center;
`;

export const TMonthSelect = styled(Select)`
  width: 106px;
  height: 40px;
  padding: 0 16px;
`;

export const TYearSelect = styled(Select)`
  width: 80px;
  height: 40px;
  padding: 0 16px;
`;
