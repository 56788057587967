import { forwardRef } from 'react';
import { format } from 'date-fns';
import {
  TAgreedBlock, TBoldedSpan,
  TFooter,
  THeaderWr,
  TInfoBlock,
  TMainContent,
  TPageContainer, TTextIndent,
  TTitle,
  TTopRightBlock,
} from './styled';
import { Profile } from '../../../store/auth/model';

interface IStatementToPrint extends Omit<Profile, 'ulid' | 'subdepartment' | 'organization' | 'department'> {
  email: string;
  organization: string;
  department: string;
}

const StatementToPrint = forwardRef<HTMLDivElement, IStatementToPrint>((props, ref) => {
  const {
    firstName,
    lastName,
    email,
    middleName,
    dateOfBirth,
    organization,
    specialization,
    department,
    title,
    phoneNumber,
  } = props;

  return (
    <div style={{ display: 'none'}}>
      <div ref={ref}>
        <TPageContainer>
          <THeaderWr>
            <TTopRightBlock>
              Приложение ____________
              <br />
              _______________________
              <br />
              _______________________
            </TTopRightBlock>
          </THeaderWr>
          <TMainContent>
            <TTitle>Заявление</TTitle>
            <div style={{ marginTop: '4px'}}>
              <TTextIndent>
                Я, ____________________________________________, прошу предоставить мне доступ к системе автоматизации генетических исследований Genetic Lab на основании следующих данных:
              </TTextIndent>
            </div>

            <TInfoBlock>
              <TBoldedSpan>Фамилия Имя Отчество</TBoldedSpan>
              <span>{lastName} {firstName} {middleName}</span>

              {!!dateOfBirth && (
              <>
                <TBoldedSpan>Дата рождения</TBoldedSpan>
                <span>{format(new Date(dateOfBirth), 'dd.MM.yyyy')}</span>
              </>
              )}

              <TBoldedSpan>Email</TBoldedSpan>
              <span>{email}</span>

              <TBoldedSpan>Телефон</TBoldedSpan>
              <span>{phoneNumber}</span>

              <TBoldedSpan>Учреждение</TBoldedSpan>
              <span>{organization}</span>

              {!!department && (
              <>
                <TBoldedSpan>Отделение</TBoldedSpan>
                <span>{department}</span>
              </>
              )}

              <TBoldedSpan>Специализация</TBoldedSpan>
              <span>{specialization}</span>

              <TBoldedSpan>Должность</TBoldedSpan>
              <span>{title}</span>
            </TInfoBlock>

            <TAgreedBlock>
              <TTextIndent>
                С правилами использования системы и политикой конфиденциальности ознакомлен.
              </TTextIndent>
            </TAgreedBlock>

            <TFooter>
              <div>
                Дата _________________
              </div>
              <div>
                __________________________________
                <br />
                ФИО заявителя / подпись
              </div>
            </TFooter>

          </TMainContent>
        </TPageContainer>
      </div>
    </div>
  );
});

export default StatementToPrint;
