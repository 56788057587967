import styled from "styled-components";

export const SupportWrapper = styled.span`
  display: flex;
  width: 540px;
  margin-top: 20px;
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align:center;
  align-items: stretch;
  color: ${({ theme }) => theme.colors.gray[400]};
`

export const CustomSupportEmail = styled.span`
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.product.primaryActive};
  cursor: pointer;
`

export const CustomCopyedTooltip = styled.div`
  position: absolute;
  bottom: 0;
  left: 150px;
  width: fit-content;
  background: white;
  border: 1px solid #96999C;
  border-radius: 4px;
  color: #96999C;
  padding: 3px;
`