import {Dispatch} from "react";
import {getMKB10, getMKB10Failure, getMKB10Success} from "./index";
import AxiosInstance from "../../api/axiosInstance";
import {loadLocalStorageData, saveLocalStorageData} from "../../utils/localStorageHelpers";

export const fetchMKB10 = (access: string) => async (dispatch: Dispatch<unknown>) => {
    dispatch(getMKB10());
    const saveData = await loadLocalStorageData('registry/mkb10/list/');
    if(saveData) return dispatch(getMKB10Success(saveData))
    try {
        const response = await AxiosInstance(access).get('registry/icd/list/');
        await saveLocalStorageData('registry/mkb10/list/', response.data)
        dispatch(getMKB10Success(response.data))
    } catch (error: any) {
        dispatch(getMKB10Failure(error?.response.data))
    }
}