import { Dispatch } from 'react';
import axiosInstance from '../../api/axiosInstance';
import {
  getPatient,
  getPatientFailure,
  getPatientSuccess,
  updatePatientFailure,
  updatePatient,
  updatePatientSuccess,
  getPatientsSuccess,
} from './';
import { Patient } from './model';

export const fetchPatient = (access: string, id: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getPatient());
  try {
    const response = await axiosInstance(access).get(`registry/patient/identification/passport/${id}`);

    dispatch(getPatientSuccess(response.data ?? null));
  } catch (error: any) {
    dispatch(getPatientFailure(error?.response?.data));
  }
};

export const fetchPatients = (access: string, id?: string, countryIsBLR?:boolean) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getPatient());

  try {
    const response = await axiosInstance(access).get(`registry/patient/list/?search=${id ?? ''}${!!countryIsBLR ? '&country__blr=true' : ''}`);

    dispatch(getPatientsSuccess(response.data ?? []));
  } catch (error: any) {
    dispatch(getPatientFailure(error?.response?.data));
  }
};

export const putPatient = (ulid: string, access: string, data: Patient) => async (dispatch: Dispatch<unknown>) => {
  dispatch(updatePatient());
  try {
    const response = await axiosInstance(access).put(`registry/patient/detail/${ulid}`, data);

    dispatch(updatePatientSuccess(response.data));
  } catch (error: any) {
    dispatch(updatePatientFailure(error?.response?.data));
  }
};
