const currentDate = new Date();

export const disableFutureDays = (date: Date) => date < currentDate;

export const disablePresentAndFutureDay = (date: Date)  => {
  const current = new Date();
  const day = 1; // go back 1 day!

  current.setDate(current.getDate() - day);
  return date < current;
};

export const disableBeforeAndFutureDay = (date: Date, beforeDate?: Date | number)  => {
  const current = new Date();
  const day = 1; // go back 1 day!
  current.setDate(current.getDate());

  if(beforeDate){
    const pastDateValue = new Date(beforeDate);
    pastDateValue.setDate(pastDateValue.getDate() - day);
    return pastDateValue < date && date <= current
  }

  return date < current;
};

export const disableAllexceptLastMonth = (date: Date) => {
  const pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - 30);
  return date < currentDate && date > pastDate
};

export const getTimeBetweenDates = (startDate:number, endDate:number) => {
  const diffInMs = Math.abs(endDate - startDate);
  const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
  const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  let hoursMinutes = !!hours ? `${hours} ч ${minutes.toString().padStart(2, "0")} мин.` : `${minutes}:${seconds.toString().padStart(2, "0")} мин.`;
  if(!days && !hours && !minutes && seconds) hoursMinutes =  `${minutes}:${seconds.toString().padStart(2, "0")} мин.`;
  return { seconds, minutes, hours, days, hoursMinutes };
};