import { createSlice } from '@reduxjs/toolkit';
import { IAlertItem } from './model';

export interface IAlertState {
  alerts: IAlertItem[];
}

export const initialState: IAlertState = {
  alerts: [],
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    createAlert: (state, {payload}) => {
      let alertData = {
        message: payload?.message ?? '',
        type: payload?.type ?? '',
        title: payload?.title ?? '',
        id: Date.now()
      }
      state.alerts = [...state.alerts.slice(-4),alertData ];
    },
    removeAlert: (state, {payload}) => {
      state.alerts = state.alerts.filter((alert:IAlertItem) => {
        return alert.id !== payload;
      });
    },
    resetAlerts: (state) => {
      state.alerts = []
    }
  }
});

export const { createAlert,removeAlert,resetAlerts } = alertSlice.actions;

export default alertSlice.reducer;