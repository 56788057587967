import {useMemo} from "react";

const useShowNoMatchError = (filteredMKB10: string[], currentValue: boolean, isOpen: boolean) => {

  const showNoMatchError = useMemo(() => {
    return currentValue && filteredMKB10.length === 0 && !isOpen;


  }, [filteredMKB10, currentValue, isOpen]);

  return {
    showNoMatchError
  }

};

export default useShowNoMatchError;