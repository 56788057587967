
export const prepareAmbulatoryCardPatientHelper = (ambulatoryCardPatient: any , organizations: any[]) => {
  let newArrayAmbulatoryCardPatient: any[] = [];
  if (ambulatoryCardPatient !== undefined) {
      const prepareAmbulatoryCard = {
        ...ambulatoryCardPatient,
        organizationId: organizations.find((org: any) => org.id === ambulatoryCardPatient.organizationId),
      };
      newArrayAmbulatoryCardPatient.push(prepareAmbulatoryCard)
  }
  return newArrayAmbulatoryCardPatient;
};
