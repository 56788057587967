import styled from 'styled-components';
import warnIcon from '../../../icons/warn-red.svg';



export const Message = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black[200]};
  &.title {
    color: ${({ theme }) => theme.colors.black[200]};
    font-size: 18px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const Content = styled.div`
  padding: 0 16px;
`;

export const TIcon = styled.img.attrs(props => ({
  src: props.src || warnIcon
})) <{ size?: number }>`
  width:  ${({ size }) => size ? size : 16}px;
  height: ${({ size }) => size ? size : 16}px;
`;

export const Wrapper = styled.ul`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  right: 30px;
  bottom: 30px;
  max-height: 50vh;
  width: max-content;
  list-style: none;
  padding: 0;
  z-index: 19;
  `;

export const AlertItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  max-width: 560px;
  background-color: ${({ theme }) => theme.colors.black[100]};
  height: max-content;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid ${({ theme }) => theme.colors.black[200]};
  border-radius: 10px;
  &.info {
    background-color: ${({ theme }) => theme.colors.violet[50]};
    border-color: ${({ theme }) => theme.colors.violet[500]};
  }
  &.error {
    background-color: ${({ theme }) => theme.colors.red[100]};
    border-color: ${({ theme }) => theme.colors.red[400]};
  }
  &.done {
    background-color: ${({ theme }) => theme.colors.green[50]};
    border-color: ${({ theme }) => theme.colors.green[100]};
  }
  box-shadow: 1px -1px 6px rgba(0, 0, 0, 0.1), -2px 2px 6px rgba(0, 0, 0, 0.3);
  animation: enter-leave 1s ease-in-out;
  @keyframes enter-leave {
  0% {
    opacity: 0;
     }
  10% {
    opacity: 0.2;
     }
  90% {
    opacity: 0.9;
     }
  100% {
    opacity: 1;
     }
  }
`;