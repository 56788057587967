import { FC } from 'react';
import { TErrorText } from './styled';

interface IFormErrorText {
  className?: string;
}

const FormErrorText: FC<IFormErrorText> = ({ children, className }) => (
  <TErrorText className={className}>{children}</TErrorText>
);

export default FormErrorText;
