import { FC, useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';
import Select from "react-select";
import { Table, TBody } from "../../../../../components/shared/BiomaterialTable";
import { TDetailsContent } from "../../../../../components/shared/Details/styled";
import Textarea from "../../../../../components/shared/Textarea";
import { ExperimentSteps, IAnalisysByMethod, IMarkerIHCClone, MarkerIhcAlkExperiment, SaveExperimentUpdate } from "../../../../../store/analysis/model";
import { fetchAnalysisByBluprint, patchBioExperementUpdate, patchExperimentUpdate, resetAnalysisByBluprint } from "../../../../../store/analysis/thunkActions";
import { getProfile, getTokens } from "../../../../../store/auth/selectors";
import { TEditButton, TRowWr } from "../../../MarkersValidation/styled";
import { CustomButton, TBioAcquired, TButtonWrapper, TDateOfCompletion, TExecutor, TOtherExecutors, TSectionTitle, TWrapper, customStylesOptions } from "../../styled";
import { getAndOverWriteEperement } from "../../../../../store/molProConclusion/thunkActions";
import InfoModal from "../../../../../components/shared/InfoModal";
import Button, { SIZE, VARIANT } from "../../../../../components/shared/Button";
import { ReactComponent as WarnIcon } from '../../../../../icons/warn-red-circle.svg';
import { IBlueprintAdvisors, IExamExecutors } from "../../CreateExperiment";
import defaultTheme from "../../../../../styles/theme";

interface IComponentProps {
  flowStepsActiveIndex: any;
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  isActive: boolean;
  hasPermit: boolean;
  defaultValues?: any;
  ableToUpdate?: boolean;
  isReferralComplite: boolean;
  referralULID: string;
  defaultExecutorOptions?: any;
  examExecutors: IExamExecutors;
  [index: string]: any;
}

interface ISelectOptions {
  value: string | number;
  label: string;
  id?: number;
}

const notInformativeSampleValues = ['Неинформативно', 'Не выполнялось'];
const stepDate = (date: Date) => format(new Date(date), 'dd.MM.yyyy - HH:mm:ss');

const TableIHCClones: FC<IComponentProps> = ({ referralULID, defaultExecutorOptions, isReferralComplite, flowStepsActiveIndex, analisysByMethod, isActive, hasPermit, defaultValues, ableToUpdate, examExecutors }) => {
  const { register, control, handleSubmit, reset, trigger, watch, formState: { errors }, setValue, clearErrors, getValues } = useForm();
  const watchClone = watch('clone', { value: 0, label: '--' });

  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const profile = useSelector(getProfile);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const [conclusionKey, setConclusionKey] = useState<number>(Date.now());

  const [ihsDataAttempt, setIhsDataAttempt] = useState<MarkerIhcAlkExperiment>();
  const [resultOptions, setResultOptions] = useState<ISelectOptions[]>([]);
  const [notInformativeSample, setNotInformativeSample] = useState(false);

  const setDefaultValues = useCallback(() => {

    if (!!defaultValues && Object.keys(defaultValues)?.length) {
      let { conclusion, clonesMap,clone, resultMap, result, cancerCellLevel, immuneCellLevel } = defaultValues ?? {};
      let cloneValue = !!clonesMap?.[clone] ? { value: +clone, label: clonesMap?.[clone] } : undefined;
      let immuneCellLevelValue = immuneCellLevel ? { value: immuneCellLevel, label: immuneCellLevel } : undefined;
      let cancerCellLevelValue = cancerCellLevel ? { value: cancerCellLevel, label: cancerCellLevel } : undefined;
      let characteristicValues = { value: +result, label: resultMap?.[result] }

      const defaults: { [index: string]: any; } = {
        characteristicResult: result && resultMap?.[result] ? characteristicValues : undefined,
        conclusion: conclusion ?? '',
        clone: cloneValue,
        cancerCellLevel:cancerCellLevelValue,
        immuneCellLevel:immuneCellLevelValue,
      }
      setNotInformativeSample(notInformativeSampleValues.includes(resultMap?.[result]));

      reset({ ...defaults });
      setResetFormKey(Date.now())
      return true;
    }
    return false;
  }, [defaultValues, setResetFormKey, reset, setNotInformativeSample]);

  useLayoutEffect(() => {
    if (!analisysByMethod?.markerIhc?.markerIhcClones?.length) return;

    if (analisysByMethod?.analysisIhcExperiments && flowStepsActiveIndex) {
      let dataAtept: MarkerIhcAlkExperiment[] = analisysByMethod?.analysisIhcExperiments?.filter((ihsData: MarkerIhcAlkExperiment) => {
        return +ihsData.number === +flowStepsActiveIndex
      });
      if (dataAtept.length) setIhsDataAttempt(dataAtept[0]);
    }

    let resultList: ISelectOptions[] = [];

    let { markerIhcClones, markerIhcResults } = analisysByMethod.markerIhc;
    let clonesMap: { [index: number]: string } = {};
    let resultMap: { [index: number]: string } = {};

    //Clone Name
    markerIhcClones?.forEach((clone: IMarkerIHCClone) => {
      clonesMap[clone.id] = clone.name;
    });

    //Options value Map
    for (let { id, content } of markerIhcResults) {
      resultList.push({ value: +id, label: content })
      resultMap[+id] = content;
    }


    setResultOptions(resultList);
    setDefaultValues();
  }, [analisysByMethod, flowStepsActiveIndex,setDefaultValues, setResultOptions]);

  const [executorfullName, executorTitle] = useMemo(() => {
    if (examExecutors?.blueprintExecutors?.length) {
      let data = examExecutors?.blueprintExecutors?.[0]
      let { firstName = '', lastName = '', middleName = '', specialization = '' } = data;
      return [`${lastName} ${firstName} ${middleName}`.trim(), specialization];
    }
    if (!profile) return ['', '']
    let { firstName = '', lastName = '', middleName = '', title = '' } = profile;
    const executorfullName = `${lastName} ${firstName} ${middleName}`.trim();
    return [executorfullName, title];
  }, [profile, examExecutors]);


  const handleEditTable = useCallback(async () => {
    // if(!isActive) return;
    if (!hasPermit) return;
    if (isEdit) {
      if (!setDefaultValues()) {
        reset({}, { keepValues: false });
        setResetFormKey(Date.now());
      }
      return setEdit(false);
    }
    setEdit(true)
  }, [isEdit, setEdit, reset, setResetFormKey, setDefaultValues, hasPermit]);


  const onSubmitTable = useCallback(async (data: any) => {
    const { characteristicResult, conclusion, clone, cancerCellLevel, immuneCellLevel} = data;
    const experementID = ihsDataAttempt?.id;

    const dataForSend = {
      clone: clone?.value,
      cancer_cell_level: cancerCellLevel?.value ?? '',
      immune_cell_level: immuneCellLevel?.value ?? '',
      result: characteristicResult.value,
      conclusion: conclusion ?? '',
      notes: '',
      completed: true,
      date_completed: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
    }

    if (!defaultValues && tokens?.access && experementID) {
      await handleEditTable()
      await dispatch(patchBioExperementUpdate(tokens?.access, experementID, dataForSend, 'ihc'));
    }
    if (!!defaultValues && tokens?.access && ableToUpdate) {
      let data: { acceptance: boolean;[index: string]: any } = { ...dataForSend, acceptance: true, number: defaultValues?.number }
      delete data['date_completed']
      delete data['completed']
      let dataToUpdate: SaveExperimentUpdate = data;
      await dispatch(patchExperimentUpdate(tokens?.access, 'ihc', defaultValues.id, dataToUpdate));
      await dispatch(getAndOverWriteEperement(tokens?.access, referralULID));
    }

    dispatch(resetAnalysisByBluprint());
    tokens?.access && setTimeout(() => dispatch(fetchAnalysisByBluprint(tokens?.access, 'ihc', analisysByMethod?.blueprint)), 100);
    setEdit(false);

  }, [tokens?.access, dispatch, handleEditTable, analisysByMethod, ihsDataAttempt, ableToUpdate,
    defaultValues, referralULID]);

  const experementBioSteps = useMemo(() => {
    const BioSteps: ExperimentSteps[] = defaultValues?.experimentIhcSteps || defaultValues?.experimentIhcAlkSteps || defaultValues?.experimentFishSteps;
    if (!BioSteps) return <></>;
    return BioSteps
      ?.filter(((value: ExperimentSteps) => value.step === 'bio_acquired'))
      ?.map((value: ExperimentSteps) => {
        return (
          <div key={value.id}>
            <span>Материал получен: <b> {stepDate(value.dateCreated)}</b></span>
          </div>
        )
      });
  }, [defaultValues]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const onModalToggle = useCallback(() => setShowConfirmModal(!showConfirmModal), [setShowConfirmModal, showConfirmModal]);

  const dateOfCompletion = useMemo(() => {
    if (!defaultValues?.dateCompleted) return '';

    return format(new Date(defaultValues?.dateCompleted), 'dd.MM.yyyy - HH:mm:ss');
  }, [defaultValues]);

  const cellLevelOptions = useMemo(() => {
    let options:ISelectOptions[] = []
    for( let i = 0; i !== 101; i++){
      if(!i){
        options.push({value: '0', label: '0'});
        options.push({value: '<1', label: '< 1'});
        continue;
      }
      options.push({value: i.toString(), label: i.toString()});
    }
    return options;
  },[]);

  const [cloneOptions, referenceMap] = useMemo(() => {
    let {markerIhcClones = [], markerIhcReferences = [] } = analisysByMethod?.markerIhc ?? {};
    let cloneList: ISelectOptions[] = [];
    let referencesMap: { [index: number]: string[] } = {};
    for(let reference of markerIhcReferences ){
      if(!referencesMap[reference?.cloneIhc]) referencesMap[reference?.cloneIhc] = []
          referencesMap[reference?.cloneIhc].push(reference?.content ?? '');
    }
    for(let cloneData of markerIhcClones ){
      if(cloneData?.id && cloneData?.name) cloneList.push({value:cloneData?.id ,label:cloneData?.name});
    }

    return [cloneList,referencesMap];

  },[analisysByMethod?.markerIhc])

  return (
    <TDetailsContent>
      {!isReferralComplite ? (
        <TRowWr direction={'space-between'}>
          {!dateOfCompletion ?
            <TSectionTitle width="60%" color={ihsDataAttempt?.bioAcquired || isActive ? "#7A78E9" : "#777"}>Результаты исследования биоматериала методом ИГХ</TSectionTitle> :
            <TDateOfCompletion>{!!dateOfCompletion && `Дата завершения: ${dateOfCompletion}`}</TDateOfCompletion>
          }
          {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate ? <TEditButton disabled={!ihsDataAttempt?.bioAcquired} onClick={handleEditTable}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton> : hasPermit && <TEditButton disabled={!ihsDataAttempt?.bioAcquired || !isActive} onClick={handleEditTable}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton>}
        </TRowWr>
      ) : (
        <TRowWr direction="flex-start">
          <TSectionTitle width="60%" color="#7A78E9">Результаты исследования биоматериала методом ИГХ</TSectionTitle>
        </TRowWr>
      )}
      <TWrapper onSubmit={handleSubmit(onSubmitTable)} key={resertFormKey} className="ihc-alk-table">
        <Table>
          {/* header */}
          <TBody>
            <tr className="borderTop violet">
              <td className="head leftTopRadius">Маркер</td>
              <td className="head">Клон</td>
              <td className="head">Показатель экспрессии ОК, %</td>
              <td className="head">Показатель экспрессии ИК, %</td>
              <td className="head rigthTopRadius">Результат исследования</td>
            </tr>
          </TBody>
          <TBody>
            <tr>
              <td style={{ width: '10%' }}>{analisysByMethod?.markerIhc?.name}</td>
              <td className={!!errors?.['clone'] ? 'error noBorderRadius' : 'noBorderRadius'} style={{ width: '15%' }}><Controller
                  control={control}
                  name={'clone'}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      onChange={(option) => onChange(option)}
                      selected={value}
                      options={cloneOptions}
                      placeholder={'--'}
                      isDisabled={!isEdit}
                      noOptionsMessage={() => "нет опций"}
                      isSearchable={true}
                      defaultValue={getValues('clone')}
                      styles={customStylesOptions(defaultTheme)}
                    />
                  )}
                />
              </td>
              <td className={!!errors?.['cancerCellLevel'] ? 'error noBorderRadius' : 'noBorderRadius'} style={{ width: '25%' }} key={`cancerCellLevel${notInformativeSample}`}>
                <Controller
                  control={control}
                  name={'cancerCellLevel'}
                  rules={{ required: !notInformativeSample }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      onChange={(option) => onChange(option)}
                      selected={value}
                      options={cellLevelOptions}
                      placeholder={'--'}
                      isDisabled={!isEdit || notInformativeSample}
                      noOptionsMessage={() => "нет опций"}
                      isSearchable={true}
                      defaultValue={getValues('cancerCellLevel')}
                      styles={customStylesOptions(defaultTheme)}
                    />
                  )}
                />
              </td>
              <td className={!!errors?.['immuneCellLevel'] ? 'error noBorderRadius' : 'noBorderRadius'} style={{ width: '25%' }} key={`immuneCellLevel${notInformativeSample}`}>
                <Controller
                  control={control}
                  name={'immuneCellLevel'}
                  rules={{ required: !notInformativeSample }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      onChange={(option) => onChange(option)}
                      selected={value}
                      options={cellLevelOptions}
                      placeholder={'--'}
                      isDisabled={!isEdit || notInformativeSample}
                      noOptionsMessage={() => "нет опций"}
                      isSearchable={true}
                      defaultValue={getValues('immuneCellLevel')}
                      styles={customStylesOptions(defaultTheme)}
                    />
                  )}
                />
              </td>
              <td className={!!errors?.['characteristicResult'] ? 'error noBorderRadius' : 'noBorderRadius'} style={{ width: '25%' }}>
                <Controller
                  control={control}
                  name={'characteristicResult'}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      onChange={(option) => {
                        let notInformative = notInformativeSampleValues?.includes(option?.label);
                        setNotInformativeSample(notInformative);
                        if(notInformative){
                          setValue('immuneCellLevel','');
                          setValue('cancerCellLevel','');
                        }
                        setValue('conclusion','');
                        clearErrors(['conclusion']);
                        setConclusionKey(Date.now());
                        return onChange(option)
                      }}
                      selected={value}
                      options={resultOptions}
                      placeholder={'--'}
                      isDisabled={!isEdit}
                      noOptionsMessage={() => "нет опций"}
                      isSearchable={false}
                      defaultValue={getValues('characteristicResult')}
                      styles={customStylesOptions(defaultTheme)}
                    />
                  )}
                /></td>
            </tr>
          </TBody>
          <TBody>
            <tr>
              <td colSpan={2} className="head violet">Референсные значения</td>
              <td colSpan={3} key={watchClone?.value}>
                {referenceMap?.[watchClone?.value]?.map((reference: any,index:number) => <div style={{margin:'0px'}} key={`${index}_${reference}`}>{reference}</div>) ?? '--'}
              </td>
            </tr>
          </TBody>
          <TBody>
            <tr>
              <td colSpan={5} className="head violet">Клинико-лабораторное заключение {notInformativeSample ? '*' : ''}</td>
            </tr>
            <tr>
            <td colSpan={5} className={!!errors?.['conclusion'] ? 'error' : ''} key={conclusionKey}>
                <Textarea
                  {...register('conclusion', { maxLength: 300, required: notInformativeSample })}
                  placeholder={'Введите вручную'}
                  className='conclusionDescription'
                  defaultValue=''
                  height={100}
                  readOnly={!isEdit}
                  disabled={!isEdit}
                  maxLength={300}
                />
              </td>
            </tr>
          </TBody>
        </Table>
        {(!isActive || !!defaultValues) && !!executorfullName && <TExecutor>
          <b>{executorfullName ?? 'Исполнитель не определен'}</b>
          {executorTitle ? `, ${executorTitle}` : ''}
        </TExecutor>}
        {!!defaultValues?.labAssistant && (<TBioAcquired height={'84px'}>
          {experementBioSteps}
          <span>Материал подготовил:
            <b> {defaultExecutorOptions?.[defaultValues.labAssistant]?.fullName ?? 'Исполнитель не определен'}</b>
            {`, ${defaultExecutorOptions?.[defaultValues.labAssistant]?.title}`}
          </span>
        </TBioAcquired>)}
        {(!isActive || !!defaultValues) && !!examExecutors?.blueprintAdvisory?.length && <TOtherExecutors height='fit-content'>
          {examExecutors?.blueprintAdvisory?.map((advisor: IBlueprintAdvisors) => (
            <div key={`advisorIHS_ALK${advisor?.fullName}`}><b>{advisor?.fullName ?? 'Исполнитель не определен'}</b>
              {advisor?.specialization ? `, ${advisor?.specialization}` : ''}</div>
          ))}
        </TOtherExecutors>}

        {!isReferralComplite && <TButtonWrapper>
          {hasPermit && isActive && <Button size={SIZE.SMALL} variant={!isEdit ? VARIANT.DEFAULT : VARIANT.BLUE} type="submit" disabled={!isEdit}>
            Завершить попытку
          </Button>}
          {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate && ( 
            <CustomButton 
              type="button" 
              onClick={async () => {
                let result = await trigger()
                if(result) onModalToggle()
                  }} 
            disabled={!isEdit}>Подтвердить изменения</CustomButton>)}
        </TButtonWrapper>}
        <InfoModal
          title='Внесенные изменения будут отображены в заключении. '
          showModal={showConfirmModal}
          isWarning={true}
          icon={<WarnIcon />}
          onModalToggle={onModalToggle}
          buttons={<>
            <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onModalToggle}>Вернуться</Button>
            <Button size={SIZE.SMALL} type="submit" onClick={handleSubmit(data => onSubmitTable(data))}>Продолжить</Button>
          </>}
        />
      </TWrapper>

    </TDetailsContent>
  )
};

export default TableIHCClones;