import { FC, useCallback } from 'react';
import { TPaginate } from './styled';

interface IPagination {
  goToPage: (page: number) => void;
  totalPages?: any;
  currentPage?: any;
}

const Pagination: FC<IPagination> = ({ goToPage, totalPages, currentPage }) => {

  const onPageChange = useCallback(({ selected }) => {
    goToPage(selected + 1);
  }, [goToPage]);

  return (
    <TPaginate
      breakLabel='...'
      nextLabel='>'
      previousLabel='<'
      onPageChange={onPageChange}
      pageRangeDisplayed={1}
      marginPagesDisplayed={4}
      pageCount={totalPages ?? 1}
      initialPage={currentPage - 1}
    />
  );
};

export default Pagination;
