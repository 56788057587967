import { createSelector } from "@reduxjs/toolkit";
import { IAppState } from "..";
import { IModuleDefaultTitle } from "./model";

const getState = (state: IAppState) => state.molProConclusion;

export const getConclModuleDefaultTitle = createSelector(getState, state => {
  if(state?.modDefaultTitle){
    return state?.modDefaultTitle?.map((data: IModuleDefaultTitle) => {
      return {value: data.name, label:data.name, id:data.id};
    }) ?? []
  }
  return [];
});

export const getConclModuleDefaultDepartment = createSelector(getState, state => {
  if(state?.modDefaultDepartment){
    let department: { [index: string]: any } = {};
    for(let data of state?.modDefaultDepartment ?? []){
      department[data.name] = {value: data.name, label: data.name, id: data.id, phoneNumber: data.phoneNumber}
    }
    return department;
  }
  return {}
});

export const getSavedConclusionData = createSelector(getState, state => state.conclusionData)