import { FC, useState } from 'react';
import Input, { IInput } from '../../shared/Input';
import { ReactComponent as ShowPassIcon } from '../../../icons/show-pass.svg';
import { ReactComponent as HidePassIcon } from '../../../icons/hide-pass.svg';

interface IInputPassword extends IInput {
  register: any;
}

const InputPassword: FC<IInputPassword> = ({ register, ...restProps }) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <Input
      bordered
      type={showPass ? 'text' : 'password'}
      iconRight={!showPass ? <HidePassIcon /> : <ShowPassIcon />}
      onRightIconMouseDown={() => setShowPass(!showPass)}
      // onRightIconMouseLeave={() => setShowPass(false)}
      // onRightIconMouseUp={() => setShowPass(false)}
      {...restProps}
      {...register}
    />
  );
};

export default InputPassword;
