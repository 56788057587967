import {Dispatch} from 'react';
import {ServiceUser} from './model'
import axiosInstance from '../../api/axiosInstance';
import {loadLocalStorageData, saveLocalStorageData} from '../../utils/localStorageHelpers';
import {getServiceUsers, getServiceUsersFailure, getServiceUsersSuccess, setSelectedServiceUser,} from './';

type urlParamsType = {
  fullName?: string;
  organizationId?: number;
};

export const fetchServiceUsers = (access: string, { fullName, organizationId }: urlParamsType, roles?: string[]) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getServiceUsers());
  const savedData = await loadLocalStorageData('/account/service-user/list/');

  function isNameExist(lastName:string, firstName:string, middleName:string, nameForFind?:string,) {
    if(!nameForFind) return false;
    let doctorFullName = (lastName + firstName + middleName).toLowerCase();
    const arrayForFind = nameForFind?.toLowerCase().split(' ');
    return arrayForFind.some((text: string) => {
      if (!text) {
        return false
      }
      return doctorFullName.startsWith(text)
    })
  }

  async function localSearch(variant: number, data: ServiceUser[], name?: string, orgId?: number) {
    if (!data) return [];
    if (variant === 1) return data.filter((userData: ServiceUser) => {
      let { lastName, firstName, middleName, organization } = userData.userProfile;
      let nameExist = isNameExist(lastName, firstName, middleName, name);
      let userRegistryPermissions = userData?.userRegistryPermissions;
      let permissions = !!userRegistryPermissions?.doctor //|| !!userRegistryPermissions?.geneticistCoordinator
      if(roles && roles?.length){
          let result = Object.entries(userRegistryPermissions).filter(([role, permit]) => roles.includes(role) && !!permit);
          permissions = !!result?.length;
      }
      return nameExist && organization === organizationId && permissions;
    });
    if (variant === 2) return data.filter((userData: ServiceUser) => {
      let { lastName, firstName, middleName } = userData.userProfile;
      let nameExist = isNameExist(lastName, firstName, middleName, name);
      let permissions = !!userData?.userRegistryPermissions?.doctor
      return nameExist && permissions;
    });
    if (variant === 3) return data?.filter((userData: ServiceUser) => {
      return userData?.userProfile?.organization === orgId;
    });

    return data;//.filter((userData: ServiceUser) => !!userData?.userRegistryPermissions?.doctor);

  }

  let urlParams;
  let localSearchVariant = 0;
  if (fullName && organizationId) {
    localSearchVariant = 1;
    urlParams = `?user_profile__last_name=${fullName}&user_profile__organization__id=${organizationId}
    &registry_permissions__doctor=true`;
  }

  if (fullName && !organizationId) {
    localSearchVariant = 2;
    urlParams = `?user_profile__last_name=${fullName}&registry_permissions__doctor=true`;
  }

  if (!fullName && organizationId) {
    localSearchVariant = 3;
    urlParams = `?user_profile__organization__id=${organizationId}`
  }
  if (!fullName && !organizationId) {
    localSearchVariant = 4;
    urlParams = '?registry_permissions__doctor=true'
  }

  const localSearchResult = await localSearch(localSearchVariant, savedData, fullName, organizationId);

  if (localSearchResult.length) {
    if (localSearchResult.length === 1) {
      dispatch(setSelectedServiceUser(localSearchResult[0]));
    }

    return dispatch(getServiceUsersSuccess(localSearchResult));
  }

  try {

    const response = await axiosInstance(access).get(`/account/service-user/list/`);
    const searchResponceResult = await localSearch(localSearchVariant, response.data, fullName, organizationId);
    await saveLocalStorageData('/account/service-user/list/', response.data);

    if (searchResponceResult.length) {
      if (searchResponceResult.length === 1) {
        dispatch(setSelectedServiceUser(searchResponceResult[0]));
      }

      return dispatch(getServiceUsersSuccess(searchResponceResult));
    }

    const responseUrlParams = await axiosInstance(access).get(`/account/service-user/list/${urlParams}`);

    if (responseUrlParams.data.length === 1) {
      dispatch(setSelectedServiceUser(responseUrlParams.data[0]));
    }

    dispatch(getServiceUsersSuccess(responseUrlParams.data));
  } catch (err: any) {
    dispatch(getServiceUsersFailure(err?.response?.data));
  }
};
