import styled, { keyframes } from 'styled-components';
import Checkbox from '../../shared/Checkbox';
import warnIcon from '../../../icons/warn-red.svg';

export const TFormContainer = styled.form`
  scroll-behavior: smooth;
  & > *:first-child{
    margin-bottom: 28px;
  }
  & > * {
    margin-bottom: 20px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TBoxWithLabel = styled.div`
  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TLabel = styled.label`
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[500]};
`;

export const TCheckbox = styled(Checkbox)<{ disabled?: Boolean }>`
  background-color: #fff;
  width: 100%;
  padding: 19px 16px;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    span {
      cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    }
`;
export const TTooltipContainer = styled.div`
  margin:-10px 10px -15px 0;
  padding:0;
  display: flex;
  flex-direction: row;
  & :first-child {
    display: box;
    width:52px;
    height:52px;
    margin: 0;
    padding:0;
  }
  & :last-child {
    display: box;
    width:230px;
    height:52px;
    margin: 0;
    padding-left:10px;
    padding-top:10px;
    text-align:left;
  }
`

export const TIcon = styled.img.attrs(props => ({
  src: props.src || warnIcon
}))`
  width: 50px;
  height: 50px;
`;

export const TDateInputWr = styled.div`
  position: relative;
`;

export const TInputsWr = styled.div`
  display: flex;
  & > * {
    margin-right: 3px;
    width: 50%;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const TInputsAltWr = styled.div`
  display: flex;
  flex-wrap: wrap;

  .half-width {
    width: 49.5%;
  }
  .ml {
    margin-left: 1%;
  }
  .custom-input {
    margin-top: 2px;
    margin-right: 0;
    width: 100%;
  }
`;

export const TTitle = styled.p`
  width: max-content;
  margin-bottom: 16px;
  margin-top: 38px;
  font-style: normal;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black[200]};
`;

export const TInputWr = styled.div`
  width:100%;
`
export const TTabPanel = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  width:100%;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;

`

export const TPanelButton = styled.div<{ active?:boolean,activeBg?:string, disabled?:boolean, err?:boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 168px;
  height: 52px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-radius: 10px;
  border: 1px solid ${( {active, activeBg, theme }) => active ? activeBg || '#CBECFE' : theme.colors.gray[200]};
  color:      ${( {active, theme }) => active ? theme.colors.black[200] : theme.colors.gray[200]};
  background: ${( {active, activeBg })    => active ?    activeBg || '#CBECFE' : '#fff'};
  cursor:     ${( {disabled })  => disabled ?  'not-allowed' : 'pointer' };
  border-bottom-color: ${( { active, activeBg, theme, err })  => err ?  'red' : active ? activeBg || '#CBECFE' : theme.colors.gray[200]};
  will-change: border;
  will-change: color;
  will-change: background;
  :last-child {
      background: ${( {active, activeBg })    => active ?  activeBg || '#FFCFEC' : '#fff'};
      border: 1px solid ${( {active, disabled, theme }) => active ? '#FFCFEC' : disabled ? theme.colors.gray[100] : theme.colors.gray[200]};
      color:${( {active, disabled, theme }) => active ? theme.colors.black[200] : disabled ? theme.colors.gray[300] : theme.colors.gray[200]};
      will-change: background;
      will-change: border;
    }
  
  `


const slideInLeft = keyframes`
  from { transform: translateX(-1000px); opacity: 0;  }
  to { transform: translateX(0); opacity: 1;}
`
const slideLeftward = keyframes`
  from { transform: translateX(-1100px); opacity: 0;  }
  to { transform: translateX(0); opacity: 1;}
`
const slideInRight = keyframes`
from { transform: translateX(1000px); opacity: 0;  }
to { transform: translateX(0); opacity: 1;}
`
const slideRightward = keyframes`
from { transform: translateX(1100px); opacity: 0;  }
to { transform: translateX(0); opacity: 1;}
`


export const TMaterialFormWrap = styled.div`
  display: flex;
  position:relative;
  width:100%;
  min-height: 200px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  overflow-x: none;
  
  &.left{
    animation-name: ${slideInLeft};
    animation-duration: 1s;
    animation-iteration-count: 1;
    will-change: transform;
  }
  &.leftward{
    animation-name: ${slideLeftward};
    animation-duration: 1s;
    animation-iteration-count: 1;
    will-change: transform;
  }
  &.right{
    animation-name: ${slideInRight};
    animation-duration: 1s;
    animation-iteration-count: 1;
    will-change: transform;
  }
  &.rightward{
    animation-name: ${slideRightward};
    animation-duration: 1s;
    animation-iteration-count: 1;
    will-change: transform;
  }
`

