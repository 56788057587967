import styled from 'styled-components';

export const TWarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 12px;
`

export const TWarningHeader = styled.div`
  color: ${({ theme }) => theme.colors.gray[200]};
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[200]};
`
export const TWarningDescription = styled.div`
  color: ${({ theme }) => theme.colors.gray[200]};
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
`