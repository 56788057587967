import { FC } from 'react';
import Step from './Step';
import { TWrapper } from './styled';

export interface IFlowStep {
  className?: string;
  flowSteps: string[];
  activeIndex: number;
  canceled?: boolean;
  done?: boolean;
  donePoint?: number;
}

const FlowStep: FC<IFlowStep> = ({ className, flowSteps, activeIndex, canceled, done, donePoint = -1 }) => {
  return (
    <TWrapper className={className}>
      {flowSteps.map((item, index, allsteps) => {
        const currentIndex = index + 1;
        return (
          <Step
            key={item}
            index={currentIndex}
            title={item}
            completed={currentIndex === donePoint || currentIndex < activeIndex}
            active={currentIndex !== donePoint && currentIndex === activeIndex }
            canceled={canceled}
            done={(done && currentIndex === allsteps.length) || currentIndex === donePoint}
          />
        )
      })}
    </TWrapper>
  );
};

export default FlowStep;
