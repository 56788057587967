import styled from 'styled-components';

export const TPageContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 50px 80px 90px;

  @media print {
    background-color: #fff;
    padding: 30px 50px 80px 90px;

    & * {
      font-family: Times New Roman;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 26px;
      color: #000000;
    }
}
`;

export const TModulePatient = styled.div`
  padding: 0;
  /* font-size: 18px; */
  margin-top: 10px;
  margin-left: 0;
  padding-left: 0;
  /* line-height: 14px; */
  /* @media print {
    font-size: 14px;
  } */
  ol {
    padding-left: 10px;
    & :first-child {
      margin-bottom: 0;
    }
  }
  & li {
    padding-left: 0;
    font-size: 14px;
    line-height: 20px;
   & span {
      color: ${({ theme }) => theme.colors.black[100]};
      font-size: 14px;
      line-height: 20px;
    }
  }
  ul {
    padding-left: 20px;
    li {
      list-style: none;
      list-style-type: none;
      padding-left: 0;
      font-size: 14px;
      line-height: 20px;
  }}
`

export const TModuleDefaultHeader = styled.table`
  width: 100%;
  border-collapse: collapse;
  caption { 
    margin-top: 24px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    caption-side: bottom; /* Заголовок под таблицей */ 
  }
  .capitalize {
    &:first-letter {
      text-transform: uppercase;
    }
  }

  /* th,td { 
    padding: 10px;
    line-height: 10px;
  } */
  tr th:first-child {
    text-align: left;
    font-size: 18px;
     margin-bottom: 0;
    li {
      margin-bottom: 0;
      line-height: 18px;
    }
    @media print {
      font-size: 18px;
      line-height: 18px;
    }
  }
  tr th:last-child {
    text-align: right;
    font-size: 8px;
    li {
      margin-bottom: 0;
      line-height: 14px;
    }
    @media print {
      font-size: 8px;
      line-height: 14px;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      line-height: 10px;
    }
  }
`

export const TModuleHeader = styled.div`
  /* margin: 10px 0; */
  font-size: 14px;
  line-height: 20px;
`

export const TModuleMarkers = styled.table`
width: 100%;
border-collapse: collapse;
table-layout:fixed;
caption { 
  font-size: 12px;
  /* @media print {
    font-size: 10px;
  } */
  font-weight: 600;
  caption-side: top; /* Заголовок над таблицей */ 
  text-align: left;
  
}
.capitalize {
  &:first-letter {
    text-transform: uppercase;
  }
}
td {
  vertical-align: top;
  padding: 5px;
  line-height: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  font-size: 12px;
  .oneRowLayout {
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
    p {
      padding-right: 5px;
      line-height: 12px;
    }
  }
  @media print {
    font-size: 10px;
    line-height: 10px;
  }
}

thead {
display: table-header-group;
vertical-align: middle;
text-align: left;
border-color: inherit;
background-color: ${({ theme }) => theme.colors.gray[200]};
background-color: lightgray;
font-size: 12px;
th,td { 
  padding: 10px 5px;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  line-height: 10px;
  vertical-align: bottom;
  font-weight: 600;
  }
}

margin: 20px 0;
.markedCell{
  span {
    line-height: 0;
  }
  vertical-align: middle;
}
`;