import styled from 'styled-components';

export const TContainer = styled.div`
  width: 690px;
  margin-top: 120px;
  .mb40 {
    margin-bottom: 40px;
  }
`;

export const TTitle = styled.h3`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black[200]};
  margin-bottom: 16px;
`;
export const TSubTitle = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black[200]};
  margin: 20px 0 10px 0;
`;

export const TReferralListWr = styled.div<{ disabled?: boolean }>`
  margin-top: 32px;
  
  & > * {
    color: ${({ theme, disabled }) => disabled ? theme.colors.gray[200] : theme.colors.black[200]};
  }
  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  & > *:first-child {
    margin-bottom: 12px;
  }
`;

export const TBottomButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 56px;

  & > * {
    margin-right: 45px;
  }
  & > *:last-child {
    margin-right: 0;
  }

  background-color: #F7F7F8;

  position: sticky;
  bottom: 0;
  z-index: 1001;
`;

export const TTitleRecommendedMarkers = styled.p`
  align-items: end;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[200]};
  margin-bottom: 19px;
` ;

export const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  background-color: ${({ theme }) => theme.colors.red[100]};
  padding: 14px 21px;
  margin: 16px 0;
  box-shadow: 1px -1px 6px 0px rgba(0, 0, 0, 0.07);
  svg {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    circle,line {
      stroke: ${({ theme }) => theme.colors.red[500]};
    }
    & :nth-child(3) {
      fill: ${({ theme }) => theme.colors.red[500]};
    }
  }
`

export const TMessage = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${({theme}) => theme.colors.black[200]}
`