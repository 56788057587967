import styled from 'styled-components';

export const TBottomButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 46px;

  & > * {
    margin-right: 40px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const TModalContent = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .error {
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.alert.warning};
  }
`;

export const TModalTitle = styled.h1<{ iconExist: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black[200]};
  text-align: center;
  flex: ${({ iconExist }) => iconExist ? 2 : 'unset'};
  margin-top: 20px;
  white-space: pre-wrap;
`;

export const TAltModalTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.product.primaryActive};
  text-align: center;
  white-space: pre-wrap;
`;

export const TModalDescription = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-top: 20px;
  text-align: center;
  color: ${({theme}) => theme.colors.black[200]};
`;
export const TAltModalDescription = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  margin-top: 14px;
  color: ${({theme}) => theme.colors.black[200]};
`;

export const TModalButtonsContainer = styled(TBottomButtonsContainer)`
  margin-top: 46px;
`;
