import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  TCheckbox, TCheckboxContainer,
  TMarkerBlock,
} from './styled';
import { ReferralMarkerPriority } from '../../../../../store/markers/model';
import Tooltip from '../../../../../components/shared/Tooltip';

const checkedStatus = {
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
  INDETERMINATE: 'indeterminate',
}

interface IMarkerBlock {
  blockIndex: number;
  items: ReferralMarkerPriority[];
  setCheckedMarkersIds?: (data: number[], index: number) => void;
  checkedByDefault?: any[];
  editedMarkers?: (markerName:string[] | string, checkedStatus:string, blockIndex:number) => any;
  isDisabled?: boolean;
  isBloodChecked?: boolean;
}

const MarkerBlock: FC<IMarkerBlock> = ({ items, setCheckedMarkersIds, blockIndex, checkedByDefault,editedMarkers, isDisabled, isBloodChecked }) => {
  const [drugChecked, setDrugChecked] = useState(false);
  const [drugCheckedStatus, setDrugCheckedStatus] = useState(checkedStatus.UNCHECKED);
  const [checkedMarkers, setCheckedMarkers] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState(false);

  const [drugNames, markerNames] = useMemo(() => {
    const drugNameList = items.filter((value: ReferralMarkerPriority) => value.active).map(({ drugOrder }) => drugOrder.drugName);
    // @ts-ignore
    const uniqueDrugNameList = [...new Set(drugNameList)];
    const rendererDrugNames = uniqueDrugNameList.map((name, index) => (
      <p key={index}>{name}</p>
    ));

    const markerNameList = items.map(({ marker }) => marker.name);
    // @ts-ignore
    const uniqueMarkerNameList = [...new Set(markerNameList)];

    return [rendererDrugNames, uniqueMarkerNameList];
  }, [items]);

  const sendEditedMarkers = useCallback((markerName:string[], checkedStatus:string) => {
    if(editedMarkers){
      return editedMarkers(markerName,checkedStatus,blockIndex) 
    }
  }, [blockIndex,editedMarkers]);

  const onDrugCheck = useCallback(() => {
    setDrugChecked(!drugChecked);
    if (!drugChecked) {
      setDrugCheckedStatus(isBloodChecked ? checkedStatus.CHECKED : checkedStatus.INDETERMINATE);

      let filteredMarkerNames = isBloodChecked ? markerNames : markerNames.filter((name: string) => !['UGT1A1','DPYD','MS-статус'].includes(name));
      setCheckedMarkers(filteredMarkerNames);
      sendEditedMarkers(filteredMarkerNames,'CHECKED');
    } else {
      setDrugCheckedStatus(checkedStatus.UNCHECKED);
      sendEditedMarkers(markerNames,'UNCHECKED');
      setCheckedMarkers([]);
    }
    setIsChecked(true);
  }, [drugChecked, markerNames,sendEditedMarkers,isBloodChecked]);

  const onMarkerCheck = useCallback((name) => {
    if (checkedMarkers.indexOf(name) !== -1) {
      const filteredCheckedMarkers = checkedMarkers.filter(item => item !== name);

      setCheckedMarkers(filteredCheckedMarkers);
      setDrugCheckedStatus(filteredCheckedMarkers.length > 0 ? checkedStatus.INDETERMINATE : checkedStatus.UNCHECKED);
      setDrugChecked(false);
      sendEditedMarkers([name],'UNCHECKED');
    } else {
      const newCheckedMarkers = checkedMarkers.concat(name);
      setCheckedMarkers(newCheckedMarkers);
      if (newCheckedMarkers.length === markerNames.length) {
        setDrugChecked(true);
        setDrugCheckedStatus(checkedStatus.CHECKED);
        sendEditedMarkers([name],'CHECKED');
      }
      if (newCheckedMarkers.length > 0 && newCheckedMarkers.length < markerNames.length) {
        setDrugChecked(false);
        setDrugCheckedStatus(checkedStatus.INDETERMINATE);
        sendEditedMarkers([name],'CHECKED');
      }

    }
    setIsChecked(true);
  }, [checkedMarkers, markerNames.length,sendEditedMarkers]);

  useEffect(() => {
    if (isChecked) {
      const ids = items.filter((item) => checkedMarkers.indexOf(item.marker.name) !== -1).map((item) => item.id);
      !!setCheckedMarkersIds && setCheckedMarkersIds(ids, blockIndex);
      setIsChecked(false);
    }
  }, [isChecked, checkedMarkers, items, setCheckedMarkersIds, blockIndex]);

  useLayoutEffect(()=>{
    if(checkedByDefault?.length && !checkedMarkers.length){
      let newCheckedMarkers = [...checkedMarkers];
      let markers = new Set();
      for (let item of items){
        if(checkedByDefault.includes(item.id)){
          markers.add(item.marker.name);
        }
      }
      if(markers.size){ 
        // @ts-ignore
        let uniqMarkerNames:string[] = [...markers];

        setCheckedMarkers(uniqMarkerNames);
        setIsChecked(true);
        
        newCheckedMarkers = checkedMarkers.concat(uniqMarkerNames)
        if (newCheckedMarkers.length === markerNames.length) {
          setDrugChecked(true);
          setDrugCheckedStatus(checkedStatus.CHECKED);
        }
        if (newCheckedMarkers.length > 0 && newCheckedMarkers.length < markerNames.length) {
          setDrugChecked(false);
          setDrugCheckedStatus(checkedStatus.INDETERMINATE);
        }
      };
    }

  },[setCheckedMarkers,checkedMarkers,checkedByDefault,items,onMarkerCheck,markerNames]);

  return (
    <TMarkerBlock>
      <TCheckbox
        disabled={isDisabled ?? false}
        checked={drugChecked}
        indeterminate={drugCheckedStatus === checkedStatus.INDETERMINATE}
        onChange={() => onDrugCheck()}
        reverse
        label={<>{drugNames}</>}
      />
      <TCheckboxContainer >
        {markerNames.map((name, index) => (
        <div data-for={`disabled-marker-tooltip-${!isBloodChecked && ['UGT1A1','DPYD','MS-статус'].includes(name)}`} data-tip="" key={index}>
          <TCheckbox  
            disabled={isDisabled || ((!isBloodChecked && ['UGT1A1','DPYD','MS-статус'].includes(name)) ?? false)}
            checked={drugCheckedStatus === checkedStatus.CHECKED || checkedMarkers.indexOf(name) !== -1}
            onChange={() => onMarkerCheck(name)}
            reverse
            label={name}
          />
        </div>
          
        ))}
      </TCheckboxContainer>
      <Tooltip id="disabled-marker-tooltip-true" place='top' >
        {checkedByDefault?.length ? 'Выбор данного маркера недоступен: направитель не указал кровь в качестве материала' : 
        'Для выбора данного маркера необходимо добавить кровь в качестве материала на предыдущем шаге'}
      </Tooltip>
    </TMarkerBlock>
  );
}

export default MarkerBlock;
