import { FC } from 'react';
import { TCircle, TStepWrapper, TTitle } from './styled';
import { ReactComponent as CheckIcon } from '../../../icons/check.svg';

interface IStep {
  index: number;
  active: boolean;
  completed: boolean;
  title: string;
}

const Step: FC<IStep> = ({ index, title, active, completed }) => {
  return (
    <TStepWrapper active={active} completed={completed}>
      <TCircle>{completed ? <CheckIcon /> : active ? index : null}</TCircle>
      <TTitle>{title}</TTitle>
    </TStepWrapper>
  );
};

export default Step;
