import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TWrapper, THeader, TVisibilityToggle, TArrowIcon, TFormWrapper,
} from './styled';
import {TBottomButtonsContainer, TTitle } from '../../styled';
import MarkerBlock from './MarkerBlock/MarkerBlock';
import Button, { SIZE, VARIANT } from '../../../../components/shared/Button';
import { IStep } from '..';
import InfoModal from '../../../../components/shared/InfoModal';
import { getTokens } from '../../../../store/auth/selectors';
import { getSelectedLocalization } from '../../../../store/referral/selectors';
import { getHighPriorityMarkers, getLowPriorityMarkers } from '../../../../store/markers/selectors';
import { fetchMarkers } from '../../../../store/markers/thunkActions';
import { setReferralMarkers } from '../../../../store/referral/thunkActions';
import { getFromSessionStorage } from '../../../../utils/sessionStorageHelpers';
import SESSION_STORAGE_KEYS from '../../../../constants/sessionStorageKeys';
import { ReferralMarkerPriority } from '../../../../store/markers/model';

const { BIO_MATERIAL } = SESSION_STORAGE_KEYS;


const MarkersSelection: FC<IStep> = ({ goNext, onCancelCreation, goBack }) => {
  const dispatch = useDispatch();

  const tokens = useSelector(getTokens);
  const localization = useSelector(getSelectedLocalization);
  const highPriorityMarkers = useSelector(getHighPriorityMarkers);
  const lowPriorityMarkers = useSelector(getLowPriorityMarkers);

  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [checkedIds, setCheckedIds] = useState<number[][]>([]);
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [isBloodChecked, setBloodChecked] = useState<boolean>(false);

  const onModalToggle = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const desabledMarkerIDs = useMemo(() => {
    if(isBloodChecked) return [];
    let allMarkers = [...highPriorityMarkers,...lowPriorityMarkers].flat();
    let filteredByDisabled = allMarkers.filter((data: ReferralMarkerPriority) => ['UGT1A1','DPYD','MS-статус'].includes(data.marker.name));
    return filteredByDisabled.map((marker: ReferralMarkerPriority) => marker.id);
  },[isBloodChecked,highPriorityMarkers,lowPriorityMarkers]);

  useLayoutEffect(() => {
    if(localization && tokens?.access){
      tokens?.access && dispatch(fetchMarkers(tokens?.access, localization));
    }
    const [bloodChecked] = getFromSessionStorage(BIO_MATERIAL.bloodChecked);
    setBloodChecked(['true'].includes(bloodChecked))
  }, [tokens?.access, dispatch, localization, setBloodChecked]);

  const onConfirm = useCallback(() => {
    const formattedList = checkedIds.filter(i => i).reduce((acc, item) => ([...acc, ...item]), []);
    dispatch(setReferralMarkers(formattedList));
    goNext && goNext();
  }, [checkedIds, dispatch, goNext]);

  const onContinue = useCallback(() => {
    isFormEmpty ? onModalToggle() : onConfirm();
  }, [isFormEmpty, onModalToggle, onConfirm]);

  const setCheckedMarkersIds = useCallback((data:number[], index:number) => {
    const copiedList = [...checkedIds];
    copiedList[index] = data.filter((ids: number) => !desabledMarkerIDs.includes(ids));
    setCheckedIds(copiedList);
    setIsFormEmpty(!copiedList.reduce((acc, item = []) => ([...acc, ...item]), []).length);
  }, [checkedIds,desabledMarkerIDs]);

  return (
    <>
      <TWrapper>
        <TTitle>Выбор маркеров</TTitle>
        <TFormWrapper>
          {lowPriorityMarkers.length > 1 && <p>Рекомендуемые маркеры</p>}
        <THeader>
          <p>Лекарственные средства</p>
          <p>Mаркеры</p>
        </THeader>
        {
          highPriorityMarkers.map((group: any[], index) => (
            <MarkerBlock isBloodChecked={isBloodChecked} blockIndex={index} setCheckedMarkersIds={setCheckedMarkersIds} key={index} items={group} />
          ))
        }

          {!!lowPriorityMarkers.length  && <TVisibilityToggle visible={visible} onClick={() => setVisible(!visible)}>
          Другие маркеры <TArrowIcon />
        </TVisibilityToggle>}

        {visible && (
          lowPriorityMarkers.map((group: any[], index) => (
            <MarkerBlock isBloodChecked={isBloodChecked} blockIndex={index + highPriorityMarkers.length} setCheckedMarkersIds={setCheckedMarkersIds} key={index} items={group} />
          ))
        )}

        <InfoModal
          title='Эта форма не может быть сохранена пустой'
          hasCloseButton
          showModal={showModal}
          isWarning
          onModalToggle={onModalToggle}
          buttons={<Button size={SIZE.SMALL} onClick={onModalToggle}>ОK</Button>}
        />
        </TFormWrapper>
      </TWrapper>

      <TBottomButtonsContainer>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={goBack}>Назад</Button>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onCancelCreation}>Отмена</Button>
        <Button size={SIZE.SMALL} onClick={onContinue}>Создать</Button>
      </TBottomButtonsContainer>
    </>
  );
}

export default MarkersSelection;
