import { FC, useMemo } from "react";
import { MarkerReference } from "../../../../../store/analysis/model";
import { TExpWR, THeaderTable, THeaderTableRow, THeaderTableRowContent, THeaderTableRowTitle, TLabTable } from "../styled";
import { useSelector } from "react-redux";
import { checkPermitViewExperement } from "../../../../../store/auth/selectors";
// import { ExperimentSs } from "../../../../../store/analysis/model";
interface IAdvisor {
  advisorUlid: string
  fullName: string
  specialization: string
}
interface IComponentProps {
  experements: any[]
  blueprintId: number
  referralID: string;
  executorMap: any;
  goToExperement: (
    experimentID:number,
    experimentMethod:string,
    experimentMarker:string) => void;
    advisors?:IAdvisor[];
  [index: string]: any;
}
export const FishApml: FC<IComponentProps> = ({ advisors, blueprintId, experements, executorMap,goToExperement }) => {
   //Permissions
   const ableToViewExperiment = useSelector(checkPermitViewExperement);
   //Formeted experimen number
   const experimentNumber = useMemo(() => blueprintId?.toString()?.padStart(6, "0")?.replace(/(?=(?:.{3})*$)/g, ' '),[blueprintId]);
  return <>
    {experements?.map(exp => (
      <TExpWR key={(exp?.id + blueprintId) + 'experement'}>
        <THeaderTable>
          <THeaderTableRow>
            <THeaderTableRowTitle>Исследование: </THeaderTableRowTitle>
            {ableToViewExperiment ? <THeaderTableRowContent className="link" onClick={() => goToExperement(blueprintId,'fish',exp?.name)}>{experimentNumber}</THeaderTableRowContent> : <div>{experimentNumber}</div>}
          </THeaderTableRow>
          <THeaderTableRow>
            <THeaderTableRowTitle>Исполнитель: </THeaderTableRowTitle>
            <THeaderTableRowContent>{`${executorMap?.[exp?.executor]?.fullName}, ${executorMap?.[exp?.executor]?.title}`}</THeaderTableRowContent>
          </THeaderTableRow>
          <THeaderTableRow>
            <THeaderTableRowTitle>Другие: </THeaderTableRowTitle>
            <THeaderTableRowContent>
              <div>{`${executorMap?.[exp?.labAssistant]?.fullName}, ${executorMap?.[exp?.labAssistant]?.title}`}</div>
              {advisors?.map((adv:IAdvisor) => <div key={adv?.advisorUlid}>{adv?.fullName}, {adv?.specialization}</div>)}</THeaderTableRowContent>
          </THeaderTableRow>
        </THeaderTable>
        <TLabTable>
          <tbody>
            <tr>
              <td colSpan={2} className="head violet topRadiusL">Реагенты (наборы, зонды)</td>
              <td colSpan={4} className="topRadiusR">{exp?.kit}</td>
            </tr>
          </tbody>
          <tbody>
            <tr className="borderTop violet">
              <td className="head">Маркер ampl</td>
              <td className="head">Кол-во проанализ. ядер опухолевых клеток</td>
              <td className="head">Количество сигналов {exp?.name}</td>
              <td className="head">Количество сигналов {exp?.signalName}</td>
              <td className="head">Соотношение {exp?.name}/{exp?.signalName}</td>
              <td className="head">Количество сигналов {`${exp?.name}/клетка`}</td>
            </tr>
            <tr>
              <td>{exp?.name}</td>
              <td>{exp?.amplification?.cancerCellNucleusCount ?? '--'}</td>
              <td>{exp?.amplification?.signalMarkerCount ?? '--'}</td>
              <td>{exp?.amplification?.signalCount ?? '--'}</td>
              <td>{exp?.amplification?.proportion ?? '--'}</td>
              <td>{exp?.amplification?.signalToCellCount ?? '--'}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colSpan={2} className="head violet">Референсные значения</td>
              <td colSpan={4} className="noMarginItems font10">
                {exp?.references?.map((ref: MarkerReference) => <div key={ref.id}>{ref.content}</div>)}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="head violet">Результат исследования</td>
              <td colSpan={4}>
                {exp?.amplification?.result}
              </td>
            </tr>
            <tr>
              <td colSpan={6} className="head violet">Клинико-лабораторное заключение</td>
            </tr>
            {!!exp?.amplification?.conclusion?.length && <tr>
              <td colSpan={6}>
                {exp?.amplification?.conclusion}.
              </td>
            </tr>}
            {!!exp?.amplification?.notes && <tr>
              <td colSpan={6} className="bottomRadiusR">{exp?.amplification?.notes}</td>
            </tr>}
          </tbody>
        </TLabTable>
      </TExpWR>)) ?? null}</>
}

export default FishApml;