import axios, { AxiosRequestHeaders } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import {BASE_URL} from './baseUrl';
import { removeItemSessionStorage, resetSessionStorage } from '../utils/sessionStorageHelpers';
import { removeItemLocalStorage } from '../utils/localStorageHelpers';

const isBeLocal = document.location.host === 'localhost:3001';
const isDev = document.location.hostname === 'gen-x.dev.devnull.express';
const isQa = document.location.hostname === 'gen-x.qa.devnull.express';
const isUat = document.location.hostname === 'gen-x.devnull.express';

const envDetector = () => {
  if (isBeLocal) {
    return 'http://localhost:8070/api/';
  }
  if (isDev) {
    return 'https://gen-x.dev.backend.devnull.express/api/';
  }
  if (isQa) {
    return 'https://gen-x.qa.backend.devnull.express/api/';
  }
  if (isUat) {
    return 'https://gen-x.backend.devnull.express/api/';
  }
  return BASE_URL;
};

const LogOut = async () => {
  console.log('axiosInstance LogOut')
   await resetSessionStorage();
   await removeItemLocalStorage('dataLastUpdate','continue_session_ignored','token');
   for await (let key of Object.keys(sessionStorage)){
     if(isNaN(parseInt(key))) removeItemSessionStorage(key);
   }
   // @ts-ignore
   window.location.replace('/sign-in');
}


const axiosInstance = (accessToken: string | null = null, history = null) => {
  let headers: AxiosRequestHeaders = {};
  

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  const axiosInstance = applyCaseMiddleware(axios.create({
    baseURL: envDetector(),
    headers,
  }), {
    ignoreHeaders: true,
  });

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if(error.response.data.error === 'Access Token has been revoked.'){
        LogOut();
      }

      if (error.response.status === 403) {

        LogOut()
        if (history) {
          // @ts-ignore
          navigate('/sign-in', { replace: true });
        } else {
          // @ts-ignore
          window.location = '/sign-in';
        }
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return axiosInstance;
};

export default axiosInstance;