import styled from 'styled-components';

export const TFormContainer = styled.form`
  & > * {
    margin-bottom: 20px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;
