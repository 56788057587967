import { FC, useCallback, useEffect, useMemo } from "react";
import closeIcon from '../../../icons/cross-in-circle.svg';
import successIcon from '../../../icons/done.svg';
import errorIcon from '../../../icons/UnActiveinfo.svg';
import infoIcon from '../../../icons/UnActiveinfo.svg';
import { Content, TIcon, Message, Wrapper, AlertItem } from "./styled";
import { AlertTypes, IAlertItem } from "../../../store/notifyAlerts/model";
import { useDispatch, useSelector } from "react-redux";
import { getAlerts } from "../../../store/notifyAlerts/selectors";
import { delAlert } from "../../../store/notifyAlerts/thunkActions";

let timerID:any;


const AlertMsg: FC = () => {
  const dispatch = useDispatch();
  const allAlerts = useSelector(getAlerts);
  
  const alerts:IAlertItem[] = useMemo(() => {
    return allAlerts;
  },[allAlerts]);

  useEffect(() => {
    if(allAlerts?.length){
      let firstAlert = ([...allAlerts] ?? [{}])?.shift();
      let id = firstAlert?.id;
      if(!!id && typeof id === 'number'){
        timerID = setTimeout(() => {
          return id ? dispatch(delAlert(+id ?? Date.now())) : null;
        },30_000);
      }
    }
    return () => {
      return clearTimeout(timerID);
    }
  },[allAlerts,dispatch]);

  const onClose = useCallback((target:any) => {
    let id = target?.['id'];
    
    if(id) return dispatch(delAlert(+id));
    return null;
  },[dispatch]);

  const ICONS = {
    [AlertTypes.INFO]:  infoIcon,
    [AlertTypes.DONE]:  successIcon,
    [AlertTypes.ERROR]: errorIcon,
  };

  return <Wrapper>
    {alerts?.map((notify: IAlertItem, index: number) => {
      return (
      <AlertItem className={notify?.type ?? ''} key={notify.id}>
        <TIcon src={ICONS[notify.type]} size={20} />
        <Content>
          {notify?.title && <Message className="title">{notify?.title}</Message>}
          <Message>{notify?.message}</Message>
        </Content>
        <TIcon
          src={closeIcon}
          id={`${notify.id}`}
          size={22}
          onClick={({target}) => onClose(target)}
          style={{ cursor: "pointer" }}
        />
    </AlertItem>
    )
    })}
  </Wrapper>
};
export default AlertMsg;