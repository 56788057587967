import { FC, forwardRef, TextareaHTMLAttributes } from 'react';
import { TTextareaWr, TLabel, TNotEditedTextArea } from './styled';

export interface ITextarea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  placeholder: string;
  error?: boolean | null;
  errorText?: string;
  className?: string;
  label?: string;
  height?: number;
  readonly?:boolean;
  bordered?: boolean;
  disabled?:boolean;
}

const Textarea: FC<ITextarea> = forwardRef((props, ref) => {
  const { placeholder, error, errorText, className, value, label, height, readOnly, bordered,disabled,defaultValue, ...restProps } = props;
  return (
    <div className={className}>
      <TTextareaWr error={error} height={height} bordered={!!bordered} disabled={disabled}>
        {label && <TLabel>{label}</TLabel>}
        {!disabled && <textarea
          // @ts-ignore
          ref={ref}
          autoComplete='off'
          {...restProps}
          placeholder={placeholder}
          readOnly={readOnly}
        />}
        {!!disabled && (
          <TNotEditedTextArea bordered={!!bordered} height={!defaultValue ? height ?? 132 : 0 }>
            {defaultValue}
          </TNotEditedTextArea>
        )}
      </TTextareaWr>
    </div>
  );
});

export default Textarea;
