import { Children, FC, ReactElement, useMemo, useState } from 'react';
import { Ref } from 'react-hook-form';
import { components, IndicatorProps, ValueContainerProps, PlaceholderProps } from 'react-select';
import { ReactComponent as ArrowIcon } from '../../../icons/arrow-select.svg';
import { customStyles, TSelect, TLabel } from './styled';
import defaultTheme from '../../../styles/theme';

export type selectOptionsItemType = {
  value: string | number;
  label: string;
};

export interface IInputSelect {
  placeholder?: string;
  options: selectOptionsItemType[];
  value: string | number | null;
  onChange: (value: string | number) => void;
  hasEmptyValue?: boolean;
  disabled?: boolean;
  bordered?: boolean;
  error?: boolean;
  label?: string;
  isMulti?: boolean;
  inputRef?: Ref;
  isSearchable?: boolean;
  marginTop?: number;
  conteinerClassName?: string;
  classNamePrefix?: string;
  menuIsOpen?: boolean;
  menuPlacement?: string;
}

const DropdownIndicator = ({
                             children,
                             ...props
                           }: IndicatorProps<selectOptionsItemType, false>) => !props.isDisabled ? (
  <components.DropdownIndicator {...props}>
    <ArrowIcon/>
  </components.DropdownIndicator>
) : null;

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({
                                children,
                                ...props
                              }: ValueContainerProps<selectOptionsItemType, false> & PlaceholderProps<selectOptionsItemType, false>) => (
  <ValueContainer {...props}>
    {Children.map(children, (child) => (child && (child as ReactElement).type !== Placeholder ? child :
      <Placeholder {...props}>{props.selectProps.placeholder}</Placeholder>))}
  </ValueContainer>
);

const InputSelect: FC<IInputSelect> = ({
                                         placeholder,
                                         options,
                                         value,
                                         onChange,
                                         hasEmptyValue,
                                         disabled,
                                         bordered,
                                         label,
                                         error,
                                         isSearchable,
                                         marginTop,
                                         conteinerClassName,
                                         classNamePrefix,
                                         ...restProps
                                       }) => {
  const optionsList = useMemo(
    () => [...(hasEmptyValue ? [{ label: 'None', value: '' }] : []), ...options],
    [options, hasEmptyValue],
  );
  const optionValue = useMemo(() => optionsList.find((item) => item.value === value), [optionsList, value]);
  const [placeholderText,setPlaceholderText] = useState(placeholder)

  return (
    <div className={conteinerClassName ?? ''}>
      {label && <TLabel marginTop={marginTop}>{label}</TLabel>}
      <TSelect
        value={optionValue}
        onChange={(item: selectOptionsItemType) => onChange(item.value)}
        onFocus={(e:any) => setPlaceholderText('')}
        onBlur={(e:any) => setPlaceholderText(placeholder ?? '')}
        styles={customStyles(defaultTheme, { bordered, error })}
        options={optionsList}
        components={{
          DropdownIndicator,
          ValueContainer: CustomValueContainer,
        }}
        isSearchable={!!isSearchable ?? false}
        blurInputOnSelect
        menuPlacement="auto"
        placeholder={placeholderText}
        isDisabled={disabled}
        captureMenuScroll={false}
        noOptionsMessage={() => "нет опций"}
        classNamePrefix={classNamePrefix ?? ''}
        {...restProps}
      />
    </div>
  );
};

InputSelect.defaultProps = {
  hasEmptyValue: false,
  placeholder: '',
  disabled: false,
};

export default InputSelect;
