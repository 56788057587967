import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SignUpForm from '../../components/forms/SignUpForm';
import AuthPageContainer from '../../components/shared/AuthPageContainer/AuthPageContainer';
import { signUp } from '../../store/auth/thunkActions';
import { getErrors, getUser } from '../../store/auth/selectors';

const SignUpPage: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const errors = useSelector(getErrors);

  const submitForm = useCallback((data) => {
    dispatch(signUp(data));
  }, [dispatch]);

  return (
    <AuthPageContainer>
      <SignUpForm errors={errors} isSubmitted={!!user} formSubmit={submitForm} />
    </AuthPageContainer>
  );
}

export default SignUpPage;
