import styled from 'styled-components';

export const ItemsWrapper = styled.div`
  width: 100%;
`;
export const TWrapper = styled.div`
  width: 100%;
`;

export const TTableWrapper = styled.div`
  padding: 21px 24px 0 24px;
`;
export const TTable = styled.div`
  width: 100%;
  z-index: 1;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const HeaderItem = styled.div`
  min-width: calc(100% / 4);
  flex-basis: 80px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #96999C;
  padding-bottom: 8px;
  
  &:first-child {
    padding-left: 16px
  }
  
  @media (max-width: 1440px) {
    &:nth-child(2) {
      margin-left: -16px;
    }
    &:nth-child(3) {
      margin-left: 10px;
    }
    &:last-child {
      margin-left: -15px;
    }
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const RowItemWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  border: ${({selected}) => selected ? '2px solid #7A78E9' : '1px solid #777777'};
  padding: 12px 22px 12px 16px;
  border-radius: 10px;
  margin-bottom: 2px;
  position: relative;
  cursor: pointer;
  z-index: 2;
`;

export const RowItem = styled.div<{ selected: boolean }>`
  flex-wrap: wrap;
  font-weight: ${({ selected }) => selected ? 600 : 500};
  color: ${({ selected, theme }) => selected ? theme.colors.black[200] : theme.colors.gray[500]};
  font-size: 12px;
  line-height: ${({ selected }) => selected ? '14px' : '16px'};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  width: calc(100% / 4);
  text-overflow: ellipsis;
  &:nth-child(2) {
    margin-left: 20px;
  }
  &:nth-child(3) {
    margin-left: 20px;
  }
  @media (max-width: 1440px) {
    &:nth-child(2) {
      margin-left: 20px;
    }
    &:nth-child(3) {
      margin-left: 20px;
    }
    &:nth-child(4) {
      margin-right: 16px;
    }
  }

`;
