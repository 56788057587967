import { FC } from "react";
import { TTable, TTBody, TThead } from "./styled";

interface ITableProps {
  children: React.ReactNode;
}

const Table: FC<ITableProps> = ({children}) => (<TTable>{children}</TTable>);
const Thead: FC<ITableProps> = ({children}) => (<TThead><tr>{children}</tr></TThead>);
const TBody: FC<ITableProps> = ({children}) => (<TTBody>{children}</TTBody>);

export  {Table,Thead,TBody};