import {useEffect, useMemo, useState} from "react";

const useDisabledSave = (positiveAgents: any, negativeAgents: any) => {
  const [isFieldsNotFilled, setFieldsError] = useState(true);
  const optionDataArray = useMemo(() => {
    return [positiveAgents, negativeAgents]
  },[positiveAgents, negativeAgents]);


  useEffect(() => {
    const result = [];
    positiveAgents?.forEach((item: any) => {
      if (item.firstColumn[0].label === 'empty') {
        result.push(item)
      }
    })
    negativeAgents?.forEach((item: any) => {
      if (item.firstColumn[0].label === 'empty') {
        result.push(item)
      }
    })

    if (result.length === optionDataArray.length) {
      setFieldsError(true)
    } else {
      setFieldsError(false)
    }

  }, [positiveAgents, negativeAgents, setFieldsError, optionDataArray])

  return {
    isFieldsNotFilled
  }
};

export default useDisabledSave;