import styled from 'styled-components';
import Input from '../../../shared/Input';
import IconButton from '../../../shared/IconButton';
import InputDayPicker from '../../../shared/InputDayPicker';

export const TWrapper = styled.div`
  display:flex;

  & > * {
    margin-top:30px;
    margin-bottom: 20px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TTitle = styled.p`
  margin-bottom: 12px;
  text-decoration: underline;
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.product.primaryActive};
`;

export const TButton = styled.div`
  position: relative;
  display: flex;
  width: 344px;
  height: 36px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 13px 0;
  border-style: none;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  font: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  color: #F36ABC;
  background: #FFFFFF;
  border-radius: 10px;
`;

export const TInput = styled(Input)`
  
  & input {
    width:344px;
    text-align: center;
  }
`;

export const TInputWr = styled.div`
  position: relative;
`;

export const TMenuButton = styled.img<{ active?: boolean,disabled?: boolean}>`
  display: inline-block;
  width: 25px;
  height: 25px;
  filter: ${({ active }) => active ? 'none' : 'grayscale(100%)'};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;

export const TIconButton = styled(IconButton)<{ active?: boolean}>`
  position: absolute;
  bottom: 0px;
  left: 16px;
  svg g circle {
    :first-child {
      fill:${({ active }) => active ? '#F1F0FF' : '#EDEFF1'};
    }
    fill: ${({ active }) => active ? '#7A78E9' : '#777676'};
  }
`;

export const TLoaderContainer = styled.div`
  border-radius: 10px;
  background-color: #fff;
  height: 60px;
  display: grid;
  overflow: hidden;
`;

export const TInputDayPicker = styled(InputDayPicker)`
  width:344px;
  margin-top:8px;
  margin-right:4px;
  > p{
    margin-top:-8px;
    margin-bottom:11px;
  }
`;

export const TMenuWr = styled.div`
  position: absolute;
  top: 0px; 
  right: -90px;
  display:block;
  padding:15px;
  min-width: 64px;
`;

export const TMenuContainer = styled.ul<{ active?: boolean}>`
  display: ${({ active }) => active ? 'block' : 'none'};
  position: absolute;
  top: 12px;
  right: -262px;
  width: max-content;
  height: auto;
  box-sizing:border-box;
  list-style: none;
  background-color: #fff;
  border-radius: 3px;
  margin:0;
  padding:0;
`;

export const TMenuItem = styled.li<{ disabled?: boolean}>`
  display: flex;
  height: 34px;
  box-sizing:border-box;
  width: 100%;
  padding:0 15px;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${({ disabled }) => disabled ? '#CECECE' : '#1A1A22'};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  border-bottom: 1px solid #F7F7F8;;
  :last-child {
    border-bottom: none;
  }
`;

export const TMenuIcon = styled.img`
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 15px;
  margin-top: 1px;
`;

export const TResultWr = styled.div`
  display:flex;
  align-items: center;
  justify-content: start;
  position: absolute;
  top:0;
  left:0;
  width:250px;
  p {
    padding-right:18px;
  }
  svg {
    margin-left:10px;
  }
`;

