import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../../../icons/plus.svg';

export const TWrapper = styled.div`
  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TTitle = styled.p`
  width: max-content;
  margin-bottom: 20px;
  margin-top: 38px;
  border-bottom:1px solid ${({ theme }) => theme.colors.product.primaryActive};
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.product.primaryActive};
`;

export const TInputsWr = styled.div`
  display: flex;
  
  & > * {
    margin-right: 3px;
    width: 50%;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const TInvertPlusIcon = styled(PlusIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.product.primaryActive};
  }
`;
