import { FC, useCallback, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { TWrapper } from './styled';
import { MessageWrapper, TBottomButtonsContainer, TMessage, TTitle } from '../../styled';
import Button, { SIZE, VARIANT } from '../../../../components/shared/Button';
import { IStep } from '..';
import { fetchBloodId, setReferralBiomaterialData } from '../../../../store/referral/thunkActions';
import BiomaterialForm from '../../../../components/forms/BiomaterialForm';
import { getOrganizations } from '../../../../store/organizations/selectors';
import { getTokens } from '../../../../store/auth/selectors';
import { resetBloodId } from '../../../../store/referral';
import { format } from 'date-fns';
import {ReactComponent as WarmIcon}  from '../../../../icons/Activeinfo.svg'

const Biomaterial: FC<IStep> = ({ goNext, onCancelCreation, goBack }) => {
  const dispatch = useDispatch();

  const organizations = useSelector(getOrganizations);
  const tokens = useSelector(getTokens);

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const formMethods = useForm({ mode: 'onChange' });


  const onContinue = useCallback((data) => {
    const formattedData = {
      ...data,
      organization: organizations.find(org => org.id === data.organization)?.nameLocalRus,
      dateOfAcquisition: data.materialDateOfAcquisition ? data.materialDateOfAcquisition : null,//format(data.bloodDateOfAcquisition, 'yyyy-MM-dd') : null,
      bloodDateOfAcquisition: data.bloodDateOfAcquisition ? format(data.bloodDateOfAcquisition, 'yyyy-MM-dd') : null,//data.bloodDateOfAcquisition ? format(data.bloodDateOfAcquisition, 'yyyy-MM-dd') : '',
      doctorOrganization: data.doctorOrganization ? data?.doctorOrganization : '',
    };
    dispatch(setReferralBiomaterialData(formattedData));
    goNext?.();
  }, [organizations, dispatch, goNext]);

  const onFetchBloodId = useCallback(() => {
    tokens?.access && dispatch(fetchBloodId(tokens?.access));
  }, [tokens?.access, dispatch]);

  const onResetBloodId = useCallback(() => {
    dispatch(resetBloodId());
  }, [dispatch]);

  return (
    <>
      <TWrapper>
        <div>
          <TTitle>Регистрация биоматериала</TTitle>
          <MessageWrapper>
          <WarmIcon/>
          <TMessage>Ошибка регистрации номеров блоков может быть основанием для отмены направления</TMessage>
        </MessageWrapper>
          <FormProvider {...formMethods}>
            <BiomaterialForm setButtonDisabled={(value) => setSubmitDisabled(value)} onFetchBloodId={onFetchBloodId} onResetBloodId={onResetBloodId} />
          </FormProvider>
        </div>
      </TWrapper>

      <TBottomButtonsContainer>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={goBack}>Назад</Button>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onCancelCreation}>Отмена</Button>
        <Button size={SIZE.SMALL} disabled={submitDisabled} onClick={formMethods.handleSubmit(onContinue)}>Далее</Button>
      </TBottomButtonsContainer>

    </>
  );
}

export default Biomaterial;
