import { FC, useCallback, useEffect, useState, cloneElement, ReactElement, useLayoutEffect, useRef, RefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../components/shared/PageContainer';
import PageTitle from '../../components/shared/PageTitle';
import BackLink from '../../components/shared/BackLink';
import ProgressStep from '../../components/shared/ProgressStep';
import { PROGRESS_STEPS_DATA } from '../../fixtures/CreateReferralPage';
import { TContainer } from './styled';
import { ROUTES } from '../../constants/routes';
import index from './steps';
import InfoModal from '../../components/shared/InfoModal';
import Button, { SIZE, VARIANT } from '../../components/shared/Button';
import { getCountries } from '../../store/countries/selectors';
import { fetchCountries } from '../../store/countries/thunkActions';
import { getCreatedReferralId, getCreatedReferralUlid, getReferral, getReferralLoading } from '../../store/referral/selectors';
import { Referral } from '../../store/referral/model';
import { createReferral } from '../../store/referral/thunkActions';
import { resetReferralData, resetReferralSearchData } from '../../store/referral';
import { getTokens, getUserUlid } from '../../store/auth/selectors';
import { removeItemSessionStorage } from '../../utils/sessionStorageHelpers';
import { ReactComponent as SuccessModalIcon } from '../../icons/success-modal.svg';
import { useReactToPrint } from 'react-to-print';
import ReferralToPrint from '../../components/documents/ReferralToPrint';
import { fetchMolecularProfiling } from '../../store/molecularProfiling/thunkActions';
import { getMolecularProfiling } from '../../store/molecularProfiling/selectors';
import { resetMolecularProfiling } from '../../store/molecularProfiling';
import Loader from '../../components/shared/Loader';

const CreateReferralPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const countries = useSelector(getCountries);
  const loading = useSelector(getReferralLoading);
  const referral = useSelector(getReferral);
  const molecularProfilingData = useSelector(getMolecularProfiling);
  const tokens = useSelector(getTokens);
  const ulid = useSelector(getUserUlid);
  const createdReferralId = useSelector(getCreatedReferralId);
  const createdReferralUlid = useSelector(getCreatedReferralUlid);
  const appBody = document.getElementById('app-body');

  const [scrollClass, setScrollClass] = useState('move-down');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const [stepsList] = useState<any>(index());
  const [currentStep, setCurrentStep] = useState<any>(null);

  const onCloseCancelModal = useCallback(() => setShowCancelModal(false), []);
  const onCloseConfirmModal = useCallback(() => setShowConfirmModal(false), []);
  const onCloseCompleteModal = useCallback(() => setShowCompleteModal(false), []);

  const onOpenCancelModal = useCallback(() => setShowCancelModal(true), []);

  const goBack = useCallback(() => currentStep.prev && setCurrentStep(currentStep.prev), [currentStep?.prev, setCurrentStep]);
  const goNext = useCallback(() => currentStep.next ? setCurrentStep(currentStep.next) : setShowConfirmModal(true), [currentStep?.next]);

  const printReferralRef = useRef(null) as RefObject<HTMLInputElement>;
  const handlePrint = useReactToPrint({
    documentTitle: `${molecularProfilingData?.referralId} Направление на молекулярное профилирование`,
    content: () => printReferralRef.current,
  }) ?? (() => null);

  useEffect(() => {
    !currentStep && setCurrentStep(stepsList.researchType);
  }, [currentStep, stepsList]);

  useEffect(() => {
    !!createdReferralUlid && tokens?.access && dispatch(fetchMolecularProfiling(tokens.access, createdReferralUlid));
  }, [tokens?.access, dispatch, createdReferralUlid]);

  useEffect(() => {
    !countries.length && tokens?.access && dispatch(fetchCountries(tokens.access));
  }, [tokens?.access, dispatch, countries]);

  const onConfirm = useCallback(() => {
    onCloseConfirmModal();
    if(tokens?.access){
      dispatch(createReferral(ulid, tokens?.access, referral as Referral))
      dispatch(resetReferralSearchData());
    }
  }, [dispatch, ulid, tokens?.access, referral, onCloseConfirmModal]);

  const redirectToMainRoute = useCallback(() => {
    let sessionStorageKeys: string[] = Object.keys(sessionStorage).filter((key: string) => !Number.isInteger(+key));
    dispatch(resetReferralData());
    navigate(ROUTES.REFERRALS, { replace: true });
    removeItemSessionStorage(...sessionStorageKeys);
  }, [dispatch, navigate]);

  useEffect(() => {
    if (createdReferralId) {
      showConfirmModal && onCloseConfirmModal();
      !showCompleteModal && setShowCompleteModal(true);
    }
  }, [createdReferralId, onCloseConfirmModal, showCompleteModal, showConfirmModal]);

  useEffect(() => {
    if (appBody) {
      setScrollClass('stay-down');
      appBody.scrollTo({ top: 0 });
      setTimeout(() => setScrollClass('move-down'), 0);
    }

  }, [currentStep, appBody]);

  const scrollHandler = useCallback((event) => {
    const scrollTop = event.currentTarget.scrollTop;
    setScrollClass(scrollTop > 20 ? 'move-top' : 'move-down');
  }, [setScrollClass]);

  useLayoutEffect(() => {
    appBody?.addEventListener('scroll', scrollHandler);
    return () => {
      dispatch(resetMolecularProfiling());
      return appBody?.removeEventListener('scroll', scrollHandler)
    };
  }, [scrollHandler, appBody, dispatch]);

  return (
    <PageContainer className='stiky-header'
      header={
        <>
          <PageTitle>Создание направления</PageTitle>
          <BackLink to={ROUTES.REFERRALS}>К списку направлений</BackLink>
        </>
      }
    >
        {currentStep && <ProgressStep className={scrollClass} activeIndex={currentStep.index} progressSteps={PROGRESS_STEPS_DATA} />}

        <TContainer>
        <Loader enabled={loading}>
          {currentStep && cloneElement(currentStep.render() as ReactElement, { goNext, onCancelCreation: onOpenCancelModal, goBack })}
        </Loader>
        </TContainer>

        <InfoModal
          showModal={showCancelModal}
          onModalToggle={onCloseCancelModal}
          title='Хотите отменить создание направления?'
          isWarning
          description='Данные будут удалены'
          hasCloseButton
          buttons={
            <>
              <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={redirectToMainRoute}>Да</Button>
              <Button size={SIZE.SMALL} onClick={onCloseCancelModal}>Нет</Button>
            </>
          }
        />

        <InfoModal
          hasCloseButton
          showModal={showConfirmModal}
          onModalToggle={onCloseConfirmModal}
          title={'Удалить созданную запись будет невозможно.\nСоздать направление?'}
          buttons={
            <>
              <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onCloseConfirmModal}>Нет</Button>
              <Button size={SIZE.SMALL} onClick={onConfirm}>Да</Button>
            </>
          }
        />

        <InfoModal
          key={createdReferralUlid ?? 'modalKey'}
          showModal={showCompleteModal}
          icon={<SuccessModalIcon />}
          onModalToggle={onCloseCompleteModal}
          title={`Направление № ${createdReferralId} \n успешно создано`}
          buttons={
            <>
              <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={redirectToMainRoute}>ОК</Button>
              <Button size={SIZE.SMALL} onClick={() => handlePrint()} disabled={!molecularProfilingData}>Распечатать</Button>
            </>
          }
        />
        {!!referral && !!molecularProfilingData && <div style={{ display: "none" }} key={Object.keys(molecularProfilingData ?? {})?.length}>
          <ReferralToPrint ref={printReferralRef} referral={{ ...molecularProfilingData, createdReferralId }} />
        </div>}
    </PageContainer>
  );
}

export default CreateReferralPage;
