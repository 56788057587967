import { Controller, useForm } from "react-hook-form";
import { TAddOptionButton, TCustomInput, TFieldWr, TForm, TMenuIcon } from "./styled";
import { FC, useCallback } from "react";

interface IInputItems {
  value: string;
  label: string;
}
interface IOptionData {
  firstColumn: IInputItems[];
  secondColumn: IInputItems[];
}

interface IComponentProps {
  openManualInput: boolean;
  closeManualInput: (data: boolean) => void;
  setManualInputData?: (data: IOptionData) => void;
  [index: string]: any;
}

const regexManualInputPattern = new RegExp(/[^a-zA-Zа-яА-ЯЁё'-.()\s]/g);

function checkForPattern(value: string) {
  return new Promise(function (resolve) {
    return resolve(!regexManualInputPattern.test(value));
  })
};

export const CustomOptionForm: FC<IComponentProps> = ({ openManualInput, setManualInputData, closeManualInput }) => {
  const { getValues, trigger, control, formState: { errors }, reset, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      full_name: '',
      specialization: '',
    }
  });

  const watchFullNameInput = watch('full_name');
  const watchSpecializationInput = watch('specialization');

  const manualInputHandler = useCallback(({ value }: any) => (value?.trimLeft() ?? value)?.replace(regexManualInputPattern, ""), []);

  const onManualSubmit = useCallback(async () => {
    let result = await trigger(["full_name", "specialization"]);
    if(result){
      let { full_name, specialization } = getValues();
      if(setManualInputData){
        setManualInputData({
          firstColumn: [{ value: specialization ?? '- - -', label: specialization ?? 'empty' }],
          secondColumn: [{ value: full_name ?? '- - -', label: full_name ?? 'empty' }]
        })
      }
      reset();
      closeManualInput(true)
    }
    
  }, [reset,trigger,getValues,closeManualInput,setManualInputData]);

  return <TForm>
    <TFieldWr>
      <Controller
        control={control}
        name="specialization"
        rules={{
          required: { value: !!openManualInput, message: 'Должность: это поле обязательно' },
          minLength: { value: 3, message: 'Должность: это поле должно содержать больше трех символов' },
          maxLength: { value: 50, message: 'Должность: превышена максимальная длина поля' },
          validate: {
            checkPattern: async (v: string) => !!(await checkForPattern(v)),
            empty: async (v: string) => !!(v.trim())
          }
        }}
        render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
          <TCustomInput
            disabled={!openManualInput}
            bordered
            label='Должность *'
            placeholder='Заведующий лабораторией'
            onChange={(value) => onChange(value)}
            onKeyUp={async ({ target }) => onChange(await manualInputHandler(target))}
            value={value}
            width={'45%'}
            type="text"
            maxLength={50}
            minLength={3}
            error={!!errors?.specialization || invalid}
          />)}
      />
      <Controller
        control={control}
        name="full_name"
        rules={{
          required: { value: !!openManualInput, message: 'ФИО: это поле обязательно' },
          minLength: { value: 3, message: 'ФИО: это поле должно содержать больше трех символов' },
          maxLength: { value: 50, message: 'ФИО: превышена максимальная длина поля' },
          validate: {
            checkPattern: async (v: string) => !!(await checkForPattern(v)),
            empty: async (v: string) => !!(v.trim())
          }
        }}
        render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
          <TCustomInput
            disabled={!openManualInput}
            bordered
            placeholder='Владимиров Алексей Сергеевич'
            label='ФИО *'
            onChange={(value) => onChange(value)}
            onKeyUp={async ({ target }) => onChange(await manualInputHandler(target))}
            value={value}
            width={'45%'}
            type="text"
            maxLength={50}
            minLength={3}
            error={!!errors?.full_name || invalid}
          />)}
      />
      <TMenuIcon onClick={() => !!closeManualInput ? closeManualInput(true) : null} />
    </TFieldWr>
    <TAddOptionButton 
      type="button"
      marginBottom={24} 
      disabled={!watchFullNameInput.trim() || !watchSpecializationInput.trim() || !!errors?.specialization || !!errors?.full_name} 
      onClick={onManualSubmit}
      >Добавить</TAddOptionButton>
  </TForm>
}