import styled from 'styled-components';

export const TButtonText = styled.span<{error?:boolean,disabled?:boolean}>`
  position: absolute;
  left: unset;
  right: -22px;
  top: 4px;
  bottom: 2px;
  width: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  border: 1px solid ${({theme,error,disabled}) => error || disabled ? 'transparent' : theme.colors.violet[500]};
  border-left: 1px solid ${({theme,error,disabled}) => error || disabled ? theme.colors.gray[200] : theme.colors.violet[500]};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 0px !important;

  background-color: ${({theme,error, disabled}) => error || disabled ? theme.colors.white : theme.colors.violet[500]};
  padding: 8px 20px;
  cursor: pointer;
  color: ${({theme,error, disabled}) => error || disabled ? theme.colors.gray[400] : theme.colors.white};
  :hover {
    /* border: 1px solid ${({theme,error, disabled}) => error || disabled ? theme.colors.gray[400] : theme.colors.product.primaryHover}; */
    background-color: ${({theme,error, disabled}) =>  error || disabled ? theme.colors.white :theme.colors.product.primaryHover};
    cursor: ${({error, disabled}) => error || disabled ? 'default' : 'pointer'};
  }
  svg {
    path,circle {
      stroke: ${({theme,error, disabled}) => error || disabled ? theme.colors.gray[400] : theme.colors.white};
    }

  }
`
