import styled from 'styled-components';

export const THeader = styled.div`
  padding: 20px 0 24px;
`;

export const TBody = styled.div<{ headerHeight: number }>`
  display: flex;
  flex-direction: column;
  align-items:center;
  height: calc(100vh - ${({ headerHeight = 0 }) => headerHeight}px);
  overflow-y: auto;
  padding-bottom: 24px;
  box-sizing: border-box;
`;

export const TContainer = styled.div<{ headerHeight?: number }>`
  padding: 0 46px;
  box-sizing: border-box;
  width: 100%;
  &.stiky-header {
    position: relative;
    width: 100vw;
    padding: 0 0 0 56px;
    ${THeader} {
      position: absolute;
      top: 0;
      left:44px;
      right: 24px;
      z-index: 2;
      ::after {
        content: "";
        position: absolute;
        top: 0;
        left:0px;
        right: 0px;
        width: 100%;//calc(100vw - (100vw - 100%));
        height: ${({ headerHeight = 0 }) => headerHeight + 'px'};
        background: linear-gradient(to bottom, rgba(249, 249, 250, 1),rgba(249, 249, 250, .9),rgba(247, 247, 248, .5));
        background-attachment: fixed;
        -webkit-backdrop-filter: blur(2px);
        -o-backdrop-filter: blur(2px);
        -moz-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        -moz-filter: blur(2px);
        filter: blur(2px);
        z-index: -2;
        transform: translateZ(0);
      }
    }
    ${TBody} {
      height:100vh;
      padding-top: ${({ headerHeight = 0 }) => headerHeight + 'px'};
    }
  }
`;

