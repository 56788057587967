import { FunctionComponent, useMemo } from 'react';
import { components, IndicatorProps } from 'react-select';
import { ReactComponent as ArrowIcon } from '../../../../../icons/arrow-down.svg';
import { customStyles, TSelect } from './styled';
import defaultTheme from '../../../../../styles/theme';

export type selectOptionsItemType = {
  value: string | number;
  label: string;
};

export interface IInputSelect {
  placeholder?: string;
  options: selectOptionsItemType[];
  value: string | number;
  onChange: (value: string) => void;
  hasEmptyValue?: boolean;
  disabled?: boolean;
  className?: string;
  isYearSelect?: boolean;
}

const DropdownIndicator = ({ children, ...props }: IndicatorProps<selectOptionsItemType, false>) => (
  <components.DropdownIndicator {...props}>
    <ArrowIcon />
  </components.DropdownIndicator>
);

const Select: FunctionComponent<IInputSelect> = ({
  isYearSelect = false,
  className,
  placeholder,
  options,
  value,
  onChange,
  disabled,
}) => {
  const optionValue = useMemo(() => options.find((item) => item.value === value), [options, value]);

  return (
    <TSelect
      className={className}
      value={optionValue}
      onChange={(item: selectOptionsItemType) => onChange(`${item.value}`)}
      styles={customStyles(defaultTheme, isYearSelect)}
      options={options}
      components={{
        DropdownIndicator,
      }}
      isSearchable={false}
      blurInputOnSelect
      menuPlacement='auto'
      placeholder={placeholder}
      isDisabled={disabled}
      captureMenuScroll={false}
      noBorder
    />
  );
};

Select.defaultProps = {
  hasEmptyValue: false,
  placeholder: '',
  disabled: false,
};

export default Select;
