import { FC } from 'react';
import ResearchType from './ResearchType';
import Patient from './Patient';
import ClinicalData from './ClinicalData';
import MarkersSelection from './MarkersSelection';
import DoctorReferral from './DoctorReferral';
import Biomaterial from './Biomaterial';

export interface IStep {
  goNext?: () => void;
  goBack?: () => void;
  onCancelCreation?: () => void;
}

export type stepNameType = 'researchType' | 'patient' | 'doctorReferral' | 'clinicalData' | 'biomaterial' | 'markersSelection'

export interface IStepConfig {
  name: stepNameType;
  component: FC;
  next?: any;
  prev?: any;
  index: number;
}

class Step implements IStepConfig {
  name: stepNameType;
  component: FC;
  next?: any;
  prev?: any;
  index: number;

  constructor(config: IStepConfig) {
    const { next = null, name, component, index, prev } = config;

    this.name = name;
    this.prev = prev;
    this.next = next;
    this.component = component;
    this.index = index;
  }

  render() {
    const Component = this.component;
    if (Component) {
      return <Component />;
    }
  }
}

export const RESEARCH_TYPE = new Step({
  name: 'researchType',
  component: ResearchType,
  index: 1,
});

export const PATIENT = new Step({
  name: 'patient',
  component: Patient,
  index: 2,
});

export const DOCTOR_REFERRAL = new Step({
  name: 'doctorReferral',
  component: DoctorReferral,
  index: 3,
});

export const CLINICAL_DATA = new Step({
  name: 'clinicalData',
  component: ClinicalData,
  index: 4,
});

export const BIOMATERIAL = new Step({
  name: 'biomaterial',
  component: Biomaterial,
  index: 5,
});

export const MARKERS_SELECTION = new Step({
  name: 'markersSelection',
  component: MarkersSelection,
  index: 6,
});

const index = () => {
  RESEARCH_TYPE.prev = null;
  RESEARCH_TYPE.next = PATIENT;
  PATIENT.prev = RESEARCH_TYPE;
  PATIENT.next = DOCTOR_REFERRAL;
  DOCTOR_REFERRAL.prev = PATIENT;
  DOCTOR_REFERRAL.next = CLINICAL_DATA;
  CLINICAL_DATA.prev = DOCTOR_REFERRAL;
  CLINICAL_DATA.next = BIOMATERIAL;
  BIOMATERIAL.prev = CLINICAL_DATA;
  BIOMATERIAL.next = MARKERS_SELECTION;
  MARKERS_SELECTION.prev = BIOMATERIAL;
  MARKERS_SELECTION.next = null;

  return {
    researchType: RESEARCH_TYPE,
    patient: PATIENT,
    doctorReferral: DOCTOR_REFERRAL,
    clinicalData: RESEARCH_TYPE,
    markersSelection: MARKERS_SELECTION,
  }
};

export default index;