import { FC, useCallback, useMemo } from 'react';
import Input, { IInput } from '../../shared/Input';
import { ReactComponent as SearchIcon } from '../../../icons/search.svg';
import { ReactComponent as CrossIcon } from '../../../icons/cross-gray-32x32.svg';
import { TButtonText } from './styled';

interface IInputSearch extends IInput {
  register?: any;
  cb: (...args: any) => void;
  buttonText?: string;
  buttonDisabled?: boolean;
  isClearButtonActive?: boolean;
}

const InputSearch: FC<IInputSearch> = ({ register,buttonText,buttonDisabled, onBlur, cb,isClearButtonActive, ...restProps }) => {

  const onKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      cb();
    }
  }, [cb]);

  const iconRight = useMemo(() => {
    if(buttonText){
      return isClearButtonActive ? <CrossIcon /> : <TButtonText disabled={buttonDisabled} error={!!restProps?.error}>{buttonText} <SearchIcon /></TButtonText>
    }
    return isClearButtonActive ? <CrossIcon /> : <SearchIcon />

  },[isClearButtonActive,buttonText,buttonDisabled,restProps?.error]);

  return (
    <Input
      iconRight={iconRight}
      onKeyDown={onKeyDown}
      onRightIconClick={cb}
      {...restProps}
      {...register}
      onBlur={onBlur}
      maxLength={56}
      style={{marginTop:'2px'}}
    />
  );
};

export default InputSearch;
