import {ErrorsType, Nullable} from "../../types";
import {createSlice} from "@reduxjs/toolkit";

export interface MKB_10 {
    loading: boolean,
    data: any,
    error: Nullable<ErrorsType>
}

export const initialState: MKB_10 = {
    loading: true,
    data: [],
    error: null
};

const mkb10Slide = createSlice({
    name: 'mkb_10',
    initialState,
    reducers: {
        getMKB10: state => {
            state.loading = true;
        },
        getMKB10Success: (state, {payload}) => {
            state.loading = false;
            state.data = payload;
            state.error = null;
        },
        getMKB10Failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload;
        }
    }
})

export const {getMKB10, getMKB10Success, getMKB10Failure} = mkb10Slide.actions;
export default mkb10Slide.reducer;