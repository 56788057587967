import styled from 'styled-components';

export const TPageContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;

  @media print {
    background-color: #fff;
    padding: 40px 40px 80px 90px;

    & * {
      font-family: Times New Roman;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 26px;
      color: #000000;
    }
}
`;

export const TModulePatient = styled.div`
  padding: 0;
  font-size: 12px;
  @media print {
    font-size: 10px;
  }
  ol {
    padding: 0 10px;
    & :first-child {
      margin-bottom: 0;
    }
  }
  & li {
    line-height: 15px;
  }
`

export const TModuleHeader = styled.div`
  font-size: 12px;
  margin: 24px 0;
`

export const TModuleSignatory = styled.div`
  margin: 24px 0;
  border-color: transparent;
  .title {
    font-size: 12px;
    line-height: 14px;
  }
  @media print {
    font-size: 10px;
    line-height: 10px;
  }
`
export const TModuleSignatoryTable = styled.table`
  display: table;
  /* table-layout:fixed; */
  width: 100%;
  /* border: none; */
  /* border: 1px solid ${({ theme }) => theme.colors.gray[200]}; */
  border-collapse: collapse;
  vertical-align: bottom;
  thead {
  display: table-header-group;
  vertical-align: middle;
  text-align: left;
  border-color: inherit;
}
  td { 
    :first-child {
      padding-left: 0;
    }
    padding: 10px;
    text-align: left;
    /* border: 1px solid ${({ theme }) => theme.colors.gray[100]}; */
    line-height: 10px;
    vertical-align: bottom;
    }
  .name {
    width: 25%;
    vertical-align: middle;
    font-size: 12px;
    @media print {
      font-size: 12px;
    }
  }
  .title {
    font-size: 12px;
    width: 50%;
  }
  @media print {
    font-size: 10px;
  }
`

export const TModuleDefaultHeader = styled.table`
  width: 100%;
  border-collapse: collapse;
  caption { 
    font-size: 16px;
    line-height: 38px;
    @media print {
      font-size: 12px;
      line-height: 12px;
    }
    font-weight: 600;
    caption-side: bottom; /* Заголовок под таблицей */ 
  }
  .capitalize {
    &:first-letter {
      text-transform: uppercase;
    }
  }
  th,td { 
    padding: 10px;
    /* border: 1px solid ${({ theme }) => theme.colors.gray[200]}; */
    line-height: 10px;
    }
  tr th:first-child {
    text-align: left;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 12px;
    @media print {
      font-size: 10px;
    }
    
    li {
      margin-bottom: 0;
      line-height: 14px;

      @media print {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
  tr th:last-child {
    text-align: right;
    font-size: 10px;
    @media print {
      font-size: 8px;
    }
    li {
      margin-bottom: 0;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      line-height: 10px;
    }
  }
`

export const TModuleMorphology = styled.table`
  table-layout:fixed;
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  td {
    :first-child {
      padding-left: 0;
    }
    vertical-align: top;
    padding: 10px;
    line-height: 10px;
    /* border: 1px solid ${({ theme }) => theme.colors.gray[200]}; */
  }
  @media print {
    font-size: 10px;
    line-height: 10px;
  }
  margin: 20px 0;
  .title {
    font-weight: 600;
  }
  .conclusion {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const TModuleSensitivityWr = styled.div`
  margin: 20px 0;
`;

export const TModuleSensToxTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout:fixed;
  margin: 20px 0;
  caption { 
    font-size: 12px;
    @media print {
      font-size: 10px;
    }
    font-weight: 600;
    caption-side: top; /* Заголовок над таблицей */ 
    text-align: left;
  }
  .capitalize {
    &:first-letter {
      text-transform: uppercase;
    }
  }
  td {
    vertical-align: top;
    padding: 5px;
    line-height: 10px;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    font-size: 12px;
    @media print {
      font-size: 10px;
      line-height: 10px;
    }
  }

  thead {
  display: table-header-group;
  vertical-align: middle;
  text-align: left;
  border-color: inherit;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  font-size: 12px;
  th,td { 
    padding: 10px;
    text-align: left;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    line-height: 10px;
    vertical-align: bottom;
    }
  }

  margin: 20px 0;
`;

export const TModuleLabDetailTable = styled.table`
  break-before: avoid-page;
  width: 100%;
  border-collapse: collapse;
  table-layout:fixed;
  font-size: 10px;
  line-height: 12px;

  @media print {
    font-size: 10px;
    line-height: 10px;
  }
  caption { 
    font-size: 12px;
    margin: 2px 0;
    padding-bottom: 5px;
    margin-top: 20px;
    @media print {
      font-size: 10px;
      line-height: 10px;    
    }
    font-weight: 600;
    caption-side: top; /* Заголовок над таблицей */ 
    text-align: left;
  }
  & :first-child {
    margin-bottom: 0;
  }
  .capitalize {
    /* text-transform: lowercase; */
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .head {
    vertical-align: middle;
    text-align: left;
    border-color: inherit;
    background-color: ${({ theme }) => theme.colors.gray[100]};
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    line-height: 10px;
    vertical-align: middle;
    padding: 5px;
  }
  .reference {
    font-size: 10px;
  }
  .wideCell {
    width: 30%;
  }

  thead {
    display: table-header-group;
    vertical-align: middle;
    text-align: left;
    border-color: inherit;
    background-color: ${({ theme }) => theme.colors.gray[100]};
    th,td { 
      padding: 5px;
      text-align: left;
      border: 1px solid ${({ theme }) => theme.colors.gray[200]};
      line-height: 10px;
      vertical-align: middle;
      }
  }
  td {
    vertical-align: top;
    padding: 5px;
    line-height: 10px;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    * {
      line-height: 12px;
    }
  }
`;

export const TModuleLabDetailConclusionTable = styled.table`
break-before: avoid-page;
  width: 100%;
  border-collapse: collapse;
  table-layout:fixed;
  line-height: 12px;
  margin: 12px 0;
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
    .title {
      line-height: 14px;
      font-size: 12px;
      @media print {
        font-size: 10px;
        line-height: 12px;
      }
      font-weight: 600;
  }
  .conclusion {
    line-height: 12px;
    @media print {
      font-size: 11px;
      line-height: 12px;
    }
  }
`;

export const TSectionSensToxityHeader = styled.div`
  margin-top: 15px;
  line-height: 14px;
  font-size: 12px;
  font-weight: 600;
  @media print {
      font-size: 11px;
      line-height: 12px;
  }
`;