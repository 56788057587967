import styled from 'styled-components';

export const TIcon = styled.div``;

export const TIconHover = styled.div``;

export const TIconDisabled = styled.div``;

export const TButton = styled.button<{ isHoverIcon: boolean, isDisabledIcon: boolean, size: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0;
  cursor: pointer;
  outline: none;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  background-color: transparent;
  svg {
    /* background-color: blueviolet; */
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    rect,line,circle,path {
        stroke:#CECECE;
      }
    :hover {
      rect,line,circle,path {
        stroke:#7A78E9;
      }
    }
  }

  & ${TIconHover}, & ${TIconDisabled} {
    display: none;
  }
  
  ${({ isHoverIcon }) => isHoverIcon && (
    `
      &:hover {
        & ${TIcon}, & ${TIconDisabled} {
          display: none;
        }
        & ${TIconHover} {
          display: block;
        }
      }
    `
  )}
  ${({ isDisabledIcon }) => isDisabledIcon && (
    `
      &:disabled {
        & ${TIcon}, & ${TIconHover} {
          display: none;
        }
        & ${TIconDisabled} {
          display: block;
        }
      }
    `
  )}
`;
