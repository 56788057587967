import { useLayoutEffect, useState } from 'react';

function useHeaderSize() {
  const [headerSize, setHeaderSize] = useState(0);
  const [bodySize, setBodySize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      const headerEl = document.getElementById('app-header');
      const bodyEl = document.getElementById('app-body');
      if (headerEl) setHeaderSize(headerEl.offsetHeight + 6);
      if (bodyEl)   setBodySize(bodyEl.offsetHeight);
      
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return {headerSize,bodySize};
}

export default useHeaderSize;
