import { TBlock, TData, TLabel, TPatientData } from './styled';

interface IData {
  data?:any;
  country?: string;
  patientAmbulatoryCard?: any[]
}


const PatientData = ({data, country, patientAmbulatoryCard}:IData) => {
  return (
    <TPatientData>
      <TBlock>
        <TLabel>Фамилия</TLabel>
        <TData>{data?.lastName ?? '--'}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Имя</TLabel>
        <TData>{data?.firstName ?? '--'}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Отчество</TLabel>
        <TData>{!!data?.middleName ? data?.middleName : '--'}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Личный номер / паспорт / ID  </TLabel>
        <TData>{data.idPassport}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Дата рождения</TLabel>
        <TData>{data?.dateOfBirth ?? '--'}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Гражданство</TLabel>
        <TData>{country ?? '--'}</TData>
      </TBlock>
      <TBlock>
        {patientAmbulatoryCard?.map((item) => {
          const ambulatoryCard = item.customOrganization ? item.customOrganization : item.organizationId?.nameLocalRus;

         return <div key={item.number + ambulatoryCard}><TLabel>Амбулаторная карта</TLabel> <TData>{item.number}, {ambulatoryCard}</TData></div>
        })}
      </TBlock>
      <TBlock>
        <TLabel>Пол</TLabel>
        <TData>{data.gender === 'Male' ? 'Мужчина' : 'Женщина'}</TData>
      </TBlock>
    </TPatientData>
  );
};

export default PatientData;
