import { FC, useCallback, useEffect, useMemo, useState, useRef, MutableRefObject } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getYear } from 'date-fns';
import { TArrowWr, TCustomInput, TCustomSelect, TMenuButton, TInputsWr, TMenuContainer, TMenuIcon, TMenuItem, TMenuWr, TWrapper, TGap } from './styled';
import { extendedValidations, validations } from '../../../../utils/validations';
import { ReactComponent as ArrowRight } from '../../../../icons/arrow-right.svg';
import dottedIcon  from '../../../../icons/dotted.svg';
import deleteIcon from '../../../../icons/trash-bin.svg';
import clearIcon from '../../../../icons/cross-btn.svg';
import copyIcon from '../../../../icons/copy-done.svg';
import addIcon from '../../../../icons/plus-btn.svg';
import useOutsideClick from '../../../../hooks/useOutsideClick';

interface IOrganBlock {
  blockIndex: number;
  onRemove: (index: number) => void;
  onAddBlock: (prefix:string,index:number) => void;
  namePrefix: string;
  standardNumbering: boolean;
}

const currentYear = getYear(new Date());
const START_YEAR = 2000;

const OrganBlock: FC<IOrganBlock> = ({ standardNumbering, namePrefix, blockIndex, onRemove, onAddBlock }) => {
  const { control, register, setValue, formState, getValues, setError, clearErrors } = useFormContext();


  const startCodeFieldValue = useWatch({ control, name: `${namePrefix}.${blockIndex}.startCode` });
  const endCodeFieldValue   = useWatch({ control, name: `${namePrefix}.${blockIndex}.endCode`   });
  const yearFieldValue      = useWatch({ control, name: `${namePrefix}.${blockIndex}.year`      });
  const amountFieldValue    = useWatch({ control, name: `${namePrefix}.${blockIndex}.amount`    });
  const codeBlockValue      = useWatch({ control, name: `${namePrefix}.${blockIndex}.code`      });
  const codeNumberBlockValue= useWatch({ control, name: `${namePrefix}.${blockIndex}.codeNumber`});

  const [isOpenMenu, setOpenMenu] = useState(false);
  const [codeKey, updateCodeKey] = useState(Date.now());

  const node = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;

  useOutsideClick(node, () => {
    if (isOpenMenu) {
      setOpenMenu(false);
    }
  });

  const yearOptions = useMemo(() => {
    let yearsList: { value: string; label: string }[] = [];
    let accYear = currentYear;
    while (accYear >= START_YEAR) {
      yearsList = [...yearsList, { value: `${accYear}`, label: `${accYear}` }];
      accYear -= 1;
    }
    return yearsList.reverse();
  }, []);

  const onBlockRemove = useCallback((e) => {
    e.preventDefault();
    if(!blockIndex) return;
    onRemove(blockIndex);
  }, [blockIndex, onRemove]);

  const onBlockAdd = useCallback((e) => {
    e.preventDefault();
    onAddBlock(namePrefix,blockIndex);
  }, [blockIndex,namePrefix, onAddBlock]);

  const onClearFields = useCallback((e) => {
    e.preventDefault();
    setValue(`${namePrefix}.${blockIndex}`, {amount: "1",code: "",codeNumber: "",endCode: "",organ: "",startCode: "",year: `${currentYear}`});
  },[setValue,namePrefix,blockIndex])

  const toggleMenu = useCallback((e) => {
    e.preventDefault();
    setOpenMenu(!isOpenMenu)
  }, [isOpenMenu,setOpenMenu]);

  const copyToClipboard = useCallback((e) => {
    e.preventDefault();
    try {
      navigator.clipboard.writeText(`${codeNumberBlockValue || codeBlockValue}`);
    } catch (err) {
      console.log("Copy Failed")
    }
  },[codeNumberBlockValue,codeBlockValue])


  useEffect(() => {
    let errors = formState.errors?.[namePrefix]?.[blockIndex];
    let isHaveCodeError = errors?.startCode || errors?.endCode || errors?.amount;
    let codeOldResult = getValues(`${namePrefix}.${blockIndex}.code`)
    let codeNewResult = `${startCodeFieldValue}${endCodeFieldValue ? '-' + endCodeFieldValue.slice(-2) : ''}${yearFieldValue ? '/' + yearFieldValue.toString().slice(-2) : ''}`
    if (standardNumbering){
      if (startCodeFieldValue && !isHaveCodeError ) {
        setValue(`${namePrefix}.${blockIndex}.code`,codeNewResult);
        // updateCodeKey(Date.now());
      } else {
        codeOldResult !== '' && setValue(`${namePrefix}.${blockIndex}.code`, '');
      }
      updateCodeKey(Date.now());
    }

  }, [standardNumbering,codeBlockValue, startCodeFieldValue, setValue,getValues, blockIndex, 
    namePrefix, endCodeFieldValue, yearFieldValue, amountFieldValue,formState,updateCodeKey]);

    const setStartCode = useCallback((value) => {
    //NEGATIVE
    if(endCodeFieldValue){
      if(+value > endCodeFieldValue){
        setValue(`${namePrefix}.${blockIndex}.endCode`, ``);
        setValue(`${namePrefix}.${blockIndex}.amount`, `1`);
      }
    } 

    //POSITIVE
    if (amountFieldValue && +amountFieldValue > 1) {
      setValue(`${namePrefix}.${blockIndex}.endCode`, `${+value + +amountFieldValue - 1}`);
      clearErrors(`${namePrefix}.${blockIndex}.endCode`);
    }

    return true;
  }, [clearErrors, amountFieldValue, setValue, namePrefix, blockIndex,endCodeFieldValue]);

  const setEndCode = useCallback((value) => {
    //NEGATIVE
    if(!!value && isNaN(value)) return false;

    if((value && (+startCodeFieldValue >= +value)) || (value && (+value - +startCodeFieldValue) > 200)){
      setValue(`${namePrefix}.${blockIndex}.amount`, '');
      setError(`${namePrefix}.${blockIndex}.endCode`, { type: 'custom', message: 'endCode' });
      return false
    };

    //POSITIVE
    if(!value){ 
      setValue(`${namePrefix}.${blockIndex}.amount`, '1');
      clearErrors([`${namePrefix}.${blockIndex}.endCode`,`${namePrefix}.${blockIndex}.amount`]);
    };
    if (startCodeFieldValue && +value > +startCodeFieldValue){
      setValue(`${namePrefix}.${blockIndex}.amount`, `${+value - +startCodeFieldValue + 1}`)
      clearErrors(`${namePrefix}.${blockIndex}.amount`);
      clearErrors(`${namePrefix}.${blockIndex}.endCode`);
    };

    return true;
  }, [clearErrors,setError, startCodeFieldValue, setValue, namePrefix, blockIndex]);
  

  // const setOrgan = useCallback((value = '') => {
  //   let inputLength = value?.trimLeft()?.length
  //   if(!!inputLength){ 
  //     clearErrors(`${namePrefix}.${blockIndex}.organ`);
  //     if(inputLength > 80){
  //       setError(`${namePrefix}.${blockIndex}.organ`, { type: 'custom', message: 'Превышено макс. кол-во символов' });
  //       return false;
  //     }
  //     return true
  //   };

  //   setError(`${namePrefix}.${blockIndex}.organ`, { type: 'custom', message: 'Это поле не может быть пустым.' });
  //   return false;
  // },[clearErrors, namePrefix, blockIndex, setError]);


  const manualInputHandler = useCallback(({value}:any) => {
    let result = parseInt(value.trim())
    return isNaN(result) ? '' : result.toString();
  },[]);

  const manualOrganInputHandler = useCallback(({value}:any) => {
    let result = value.trimLeft();
    return !result ? '' : result.toString();
  },[]);


  
  return (
    <TWrapper>
      {standardNumbering ? (
        <TInputsWr>
          <div>
            <span>{`${blockIndex + 1}.`}</span>
            <TCustomInput
              {...register(`${namePrefix}.${blockIndex}.organ`, {
                required: "Это поле обязательно.",
                maxLength: {
                  value: 100,
                  message: "Превышено макс. кол-во символов",
                },
              })}
              width='228px'
              placeholder='Орган *'
              error={!!formState.errors[namePrefix]?.[blockIndex]?.organ}
              errorText={formState.errors[namePrefix]?.[blockIndex]?.organ?.message}
              onKeyUp={async ({target}) => setValue(`${namePrefix}.${blockIndex}.organ`,await manualOrganInputHandler(target))}
              maxLength={101}
            />
          </div>
          <TCustomInput
            {...register(`${namePrefix}.${blockIndex}.startCode`, { ...validations.startCode, ...extendedValidations.blockCode,
              validate: value => setStartCode(value)
            })}
            placeholder='Начальный № *'
            width='176px'
            type='number'
            min="1"
            step="1"
            maxLength={12}
            error={!!formState.errors[namePrefix]?.[blockIndex]?.startCode}
            onKeyUp={async ({target}) => setValue(`${namePrefix}.${blockIndex}.startCode`,await manualInputHandler(target))}
            textAlighn={'center'}
          />
          <TCustomInput
            {...register(`${namePrefix}.${blockIndex}.endCode`, { ...extendedValidations.blockCode, validate: value => setEndCode(value) })}
            placeholder='Конечный №'
            width='176px'
            type='number'
            min="1"
            step="1"
            maxLength={12}
            error={!!formState.errors[namePrefix]?.[blockIndex]?.endCode}
            onKeyUp={async ({target}) => setValue(`${namePrefix}.${blockIndex}.endCode`,await manualInputHandler(target))}
            textAlighn={'center'}
          />
          <TGap />
          <Controller
            control={control}
            name={`${namePrefix}.${blockIndex}.year`}
            defaultValue={`${currentYear}`}
            render={({ field: { onChange, value, ref } }) => (
              <TCustomSelect
                inputRef={ref}
                onChange={(value) => onChange(value)}
                value={value?.toString()}
                options={yearOptions}
                placeholder='год *'
                menuPlacement={'top'}
              />
            )}
          />
          <TArrowWr>
            <ArrowRight />
          </TArrowWr>
          <TCustomInput
            key={`code_${codeKey}_${blockIndex}`}
            {...register(`${namePrefix}.${blockIndex}.code`)}
            placeholder='Результат'
            width='174px'
            background='#CBECFE'
            readOnly
            textAlighn={'center'}
          />
        </TInputsWr>
      ) : (
        <>
          <TCustomInput
            {...register(`${namePrefix}.${blockIndex}.organ`, {
              required: "Это поле обязательно.",
              maxLength: {
                value: 100,
                message: "Превышено макс. кол-во символов",
              },
            })}
            width='336px'
            placeholder='Орган *'
            error={!!formState.errors[namePrefix]?.[blockIndex]?.organ}
            errorText={formState.errors[namePrefix]?.[blockIndex]?.organ?.message}
            onKeyUp={async ({target}) => setValue(`${namePrefix}.${blockIndex}.organ`,await manualOrganInputHandler(target))}
            maxLength={101}
          />

          <TArrowWr>
            <ArrowRight />
          </TArrowWr>
        <TCustomInput
          {...register(`${namePrefix}.${blockIndex}.codeNumber`, { value: codeBlockValue })}
          width='336px'
          placeholder='Номер'
          maxLength={25}
          background='#CBECFE'
          error={!!formState.errors[namePrefix]?.[blockIndex]?.codeNumber}
          onKeyUp={async ({target}) => setValue(`${namePrefix}.${blockIndex}.codeNumber`,await manualOrganInputHandler(target))}
        />
        </>
      )}

      <TMenuWr onClick={toggleMenu} ref={node}>
        <TMenuButton
          onClick={toggleMenu}
          src={dottedIcon}
          active={isOpenMenu}
        /> 

        <TMenuContainer active={isOpenMenu}>
          <TMenuItem onClick={onBlockAdd}>Добавить блок <TMenuIcon src={addIcon}/></TMenuItem>
          <TMenuItem onClick={onClearFields} >Очистить содержимое <TMenuIcon src={clearIcon}/></TMenuItem>
          <TMenuItem onClick={onBlockRemove} disabled={!blockIndex}>Удалить блок <TMenuIcon src={deleteIcon}/></TMenuItem>
          <TMenuItem onClick={copyToClipboard}>Скопировать результат <TMenuIcon src={copyIcon}/></TMenuItem>
        </TMenuContainer>
      </TMenuWr>

    </TWrapper>
  );
}

export default OrganBlock;
