import styled from 'styled-components';

export const TInfoBlockTitle = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.gray[400]};
  margin-bottom: 4px;
`;

export const TInfoBlockValue = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black[200]};
`;
