import {useState} from "react";
import {ReactComponent as ArrowIcon} from "../../../../../icons/arrow-select.svg";
import {
  IconDownWrapper,
  IconUpWrapper,
  SecondaryRowComponentItem,
  SecondRowComponentItemName,
  SecondRowComponentWrapper
} from "./styled";

const SecondRowComponent = ({item, selectMKB10Option}: any) => {

  const[isOpenIcd10Child, setIsOpenIcd10Child] = useState(false);
  const isOpenHandler = () => setIsOpenIcd10Child(!isOpenIcd10Child)
  const rowName = `${item.code} ${item.nameRus}`

  return (
    <>
      <SecondRowComponentWrapper>
        <SecondRowComponentItemName onClick={() => selectMKB10Option(rowName)}>
          {rowName}
        </SecondRowComponentItemName>
        <div onClick={isOpenHandler}>
        {isOpenIcd10Child ? <IconDownWrapper>
            <ArrowIcon/>
          </IconDownWrapper>
          : <IconUpWrapper>
            <ArrowIcon/>
          </IconUpWrapper>}
        </div>
      </SecondRowComponentWrapper>
      {isOpenIcd10Child && item.icd10Children.map((item: any) => {
        const name = `${item.code} ${item.nameRus}`
        return <SecondaryRowComponentItem key = {name} onClick={() => selectMKB10Option(name)}>{name}</SecondaryRowComponentItem> })
      }
    </>
  )
};


export default SecondRowComponent;