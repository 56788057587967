import styled from 'styled-components';

function getCircleColor(theme:any, active: boolean, completed: boolean, done?:boolean){

  if(done)      return theme.colors.alert.checkedRadioButton;
  if(active)    return theme.colors.orange[100];
  if(completed) return theme.colors.alert.checkedRadioButton;

  return theme.colors.gray[200];
}
function getCircleLineColor(theme:any, active: boolean, completed: boolean){

  if(active) return {
    before: theme.colors.steps.active.before,    
    main:   theme.colors.steps.active.main,      
    after:  theme.colors.steps.default.after};

  if(completed) return {
    before: theme.colors.steps.completed.before, 
    main:   theme.colors.steps.completed.main,   
    after:  theme.colors.steps.completed.after};

  return {
    before: theme.colors.steps.default.before, 
    main:   theme.colors.steps.default.main, 
    after:  theme.colors.steps.default.after};
}

export const TWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
`;

export const TCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  width: 14px;
  height: 14px;
  border-radius: 50%;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  position: relative;
`;

export const TTitle = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  
  text-align: center;
  display: block;
  top: 26px;
  position: absolute;
  width:80%;
`;

export const TStepWrapper = styled.div<{ active: boolean, completed: boolean, canceled?:boolean, done?:boolean }>`
  display: flex;
  width:16.7%;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  align-self:stretch;
  position: relative;

  &:first-child:before {
    display: none;
  }
  &:last-child:after {
    display: none;
  }
  
  & ${TCircle} {
    outline-style: solid;
    outline-offset: 3px;
    outline: 1px solid  ${({ theme,active, completed,done }) => getCircleColor(theme, active, completed,done)};
    background-color:   ${({ theme,active, completed,done }) => getCircleColor(theme, active, completed,done)};
    border: 1px solid   ${({ theme,active, completed,done }) => getCircleColor(theme, active, completed,done)};
  }

  & ${TTitle} {
    color: ${({ theme, active, completed, canceled }) => active || completed  ? theme.colors.black[200] : theme.colors.gray[200]};
  }

  &:last-child ${TCircle} {
    outline: 1px solid   ${({ theme,active, completed,canceled,done }) => canceled && !active && !completed ? theme.colors.alert.warning : getCircleColor(theme, active, completed,done)};
    background-color:    ${({ theme,active, completed,canceled,done }) => canceled && !active && !completed ? theme.colors.alert.warning : getCircleColor(theme, active, completed,done)};
    border: 1px solid    ${({ theme,active, completed,canceled,done }) => canceled && !active && !completed ? theme.colors.alert.warning : getCircleColor(theme, active, completed,done)};
  }

  &:last-child ${TTitle} {
    color: ${({ theme, completed, canceled, done }) => completed || canceled || done ? theme.colors.black[200] : theme.colors.gray[200]};
  }
  

  ::before {
    content:'';
    position:absolute;
    top: 7px;
    left: -5px;
    height: 0px;
    width: calc(50% - 8px);
    padding:.5px; 
    background:linear-gradient(-90deg,
      ${({ theme, active, completed }) => getCircleLineColor(theme, active, completed).main},
      ${({ theme, active, completed }) => getCircleLineColor(theme, active, completed).main},
      ${({ theme, active, completed }) => getCircleLineColor(theme, active, completed).before}); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
  }
  ::after { 
    content:'';
    position: absolute;
    top: 7px;
    right: -5px;
    height: 0px;
    width: calc(50% - 8px);
    padding:.5px; 
    background:linear-gradient(90deg,
      ${({ theme, active, completed }) => getCircleLineColor(theme, active, completed).main},
      ${({ theme, active, completed }) => getCircleLineColor(theme, active, completed).main},
      ${({ theme, active, completed }) => getCircleLineColor(theme, active, completed).after}); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }
`;
