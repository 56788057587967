import styled, { createGlobalStyle } from 'styled-components';
import ReactModal from 'react-modal';

export const Styles = createGlobalStyle`
  .ReactModalPortal {
    z-index: 1000;
  }
  
  .ReactModal__Body--open {
    overflow: hidden;
  }
  
  .ReactModal__Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    background-color: transparent !important;
    transition: background-color 0.2s ease-out;
    touch-action: cross-slide-y pinch-zoom double-tap-zoom;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ReactModal__Overlay--after-open {
    background-color: rgba(27, 30, 36, 0.7) !important;
    backdrop-filter: blur(6px);
    z-index: 25;
    overflow-y: hidden;
  }
  
  .ReactModal__Overlay--before-close { 
    background-color: rgba(0, 0, 0, 0);
  }
  
  .ReactModal__Content { 
    display: block;
    position: relative;
    box-sizing: border-box;
    margin: 32px auto;
    max-width: calc(100% - 40px);
    opacity: 0;
    transform: translateY(-100px);
    background: white;
    transition: opacity 0.4s ease-out, transform 0.4s ease-out;
    max-height: 90vh;
    overflow: hidden;
  
    &:focus {
      outline: none;
    }
  
    .ReactModal__Overlay--after-open & {
      opacity: 1;
      transition: opacity 0.25s ease-out, transform 0.25s ease-out;
      transform: translateY(0);
      border-radius: 10px;
      box-shadow: 0 4px 26px rgba(0, 0, 0, 0.12);
    }
  
    .ReactModal__Overlay--before-close & {
      opacity: 0;
      transform: translateY(-100px);
      transition: opacity 0.4s ease-out, transform 0.4s ease-out;
    }
  }
`;

export const TModal = styled(ReactModal)`
  position: ${({ isOpen }) => (isOpen ? 'relative' : 'fixed')};
`;

export const TModalContent = styled.div`
  max-width: 100%;
`;

export const TCloseButton = styled.button`
  position: fixed;
  top: 21px;
  right: 21px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  z-index: 2;
  path, circle {
    stroke: ${({ theme }) => theme.colors.gray[400]};
  }
  opacity: 0;
  padding: 0;

  &:focus,
  &:hover {
    color: white;
    circle {
      stroke: ${({ theme }) => theme.colors.gray[400]};
      fill: ${({ theme }) => theme.colors.gray[400]};
    }
    path  {
      stroke: white;
    }
  }

  .ReactModal__Overlay--after-open & {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close & {
    opacity: 0;
  }
`;
