import styled from 'styled-components';
import Checkbox from '../../../shared/Checkbox';

export const TLabel = styled.label`
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[500]};
`;

export const TBlocksWr = styled.div`
  display: flex;
  margin: 2px 0;
  
  & > * {
    margin-right: 6px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const TBlock = styled.div`
  display: flex;
  width: 100%;
  
  & > * {
    margin-right: 2px;
    width: 100%;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const TBlockTumor = styled.div`
  background: #fff;
  border-radius: 10px;
  display: flex;
`

export const TItemWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const TItem = styled.div<{backligth?: boolean}>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color:${({ theme, backligth }) => backligth ? theme.colors.product.primaryActive : theme.colors.gray[200]};
`

export const TStageBlock = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  & > * {
    margin-right: 2px;
    width: 100%;
  }
  & > *:last-child {
    margin-right: 0;
    margin-top: 20px;
  }
`
export const TCheckboxWr = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
` 
export const TCheckbox = styled(Checkbox)<{ disabled?: Boolean }>`
  display: flex;
  flex-direction: row;
  width: max-content;
  padding: 6px 0px;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    span {
      cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    }
  margin-right: 30px;
`;