import { useSelector } from "react-redux";
import { getSavedConclusionData } from "../../../store/molProConclusion/selectors";
import NoDataToShow from "../NoDataToShow";
import { RefObject, useMemo, useRef, useState } from "react";
import ConclusionToPrint from "../../../components/documents/ConclusionToPrint";

const ResultFormDoc = () => {
  const savedConclusionData = useSelector(getSavedConclusionData);
  const [modulesReadyToPrint, setModulesReadyToPrint] = useState<{ [index: string]: string }>({});
  const printTemplateRef = useRef() as RefObject<HTMLInputElement>;


  const checkAllIsDone = useMemo(() => {
    let isDone = false;
    let conclusionDataFromServer:{[index: string]: any;} | null = {...savedConclusionData ?? {}} ;

    if(!conclusionDataFromServer || !Object.keys(conclusionDataFromServer ?? {})?.length) return isDone;
    
    isDone = !!Object?.keys(conclusionDataFromServer ?? {})?.length

    if(isDone){
      setModulesReadyToPrint(conclusionDataFromServer);
    }

    return isDone;
  }, [savedConclusionData, setModulesReadyToPrint]);

  if(!!checkAllIsDone) return <ConclusionToPrint ref={printTemplateRef} modules={modulesReadyToPrint}/>
  return <NoDataToShow title='Загружаем данные результатов исследования' description='перезагрузите страницу, если долго видите это сообщение'/>
}

export default ResultFormDoc;