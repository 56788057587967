import styled from 'styled-components';

export const TWrapper = styled.div`
  min-height: 470px;
  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;
