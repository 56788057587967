import { useEffect, useState } from "react";

let resizeTimeoutID: null | ReturnType<typeof setTimeout> = null;
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  const [resizeTime, setResizeTime] = useState<number>(Date.now());

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      if (resizeTimeoutID) clearTimeout(resizeTimeoutID)
      if ((Date.now() - resizeTime) > 100) resizeTimeoutID = setTimeout(() => {
            setResizeTime(Date.now())
          }, 1000);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [resizeTime]); // Empty array ensures that effect is only run on mount

  return {windowSize, resizeTime};
}

export default useWindowSize;