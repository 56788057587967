import { FC } from "react";
import arrowSelectIcon    from '../../../icons/arrow-select.svg';
import { TDetails, TDetailsContent, TSummary, TSummaryContent, TSummaryIcon } from "./styled";


interface IConclusionTab {
  // config: any,
  title: string,
  // isActive: boolean,
  // isDone: boolean,
  // tabName: string;
  // done: boolean;
}

const ConclusionTab: FC<IConclusionTab> = ({title,children}) => {
  // const {title, isActive, isDone} = config;
  // const {title} = config;

  return (
    <TDetails>
      <TSummary>
        <TSummaryContent done={false}>
          <TSummaryIcon className="arrow-img" src={arrowSelectIcon}/>
          <header>{title}</header>
        </TSummaryContent> 
      </TSummary>
      <TDetailsContent>
        {children}
      </TDetailsContent> 
    </TDetails>
  )

}

export default ConclusionTab