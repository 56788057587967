import { FC, useCallback, useLayoutEffect, useMemo } from 'react';
import { getYear } from 'date-fns';
import OrganBlock from '../OrganBlock';
import { getFromSessionStorage } from '../../../../utils/sessionStorageHelpers';
import SESSION_STORAGE_KEYS from '../../../../constants/sessionStorageKeys';
import { NUMBERING } from '../constants';
import { useFormContext, useFieldArray, useWatch} from 'react-hook-form';
import { TWrapper } from './styled';

const { BIO_MATERIAL } = SESSION_STORAGE_KEYS;

const PREFIX = 'blocks';
const currentYear = getYear(new Date());

const BlockRegistration: FC<{checkErrors:string}> = ({checkErrors}) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: PREFIX
  });

  const numberingFieldValue = useWatch({ control, name: 'numbering' });
  const fieldsValue = useWatch({ control, name: PREFIX });

  const getStartCode = useMemo(() => {
    if (fieldsValue.length) {
      const lastBlock = fieldsValue[fieldsValue.length - 1];
      return lastBlock.endCode ? `${+lastBlock.endCode + 1}` : lastBlock.startCode ? `${+lastBlock.startCode + 1}` : '';
    } else {
      return '';
    }
  }, [fieldsValue]);

  const getOrganName = useCallback(() => {
    if (fieldsValue.length) {
      const lastBlock = fieldsValue[fieldsValue.length - 1];
      return lastBlock.organ;
    } else {
      return '';
    }
  }, [fieldsValue]);

  const appendBlock = useCallback(() => {
    append({
      organ: getOrganName(),
      startCode: getStartCode,
      endCode: '',
      amount: '1',
      year: `${currentYear}`,
      code: '',
      codeNumber: '',
      glasses: [
        {
          startCode: '',
          endCode: '',
          amount: '1',
          year: `${currentYear}`,
          code: '',
          codeNumber: '',
        }
      ]
    }, { shouldFocus: false });
  }, [append, getStartCode, getOrganName]);

  useLayoutEffect(() => {
    const [blocksJson] = getFromSessionStorage(BIO_MATERIAL.blocks);
    if (!blocksJson && !fields.length) {
      appendBlock();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const addBlock = useCallback((namePrefix:string,blockIndex:number) => {
    appendBlock();
  }, [appendBlock]);

  return (
    <TWrapper>
      {fields.map((item, index) => (
        <OrganBlock standardNumbering={numberingFieldValue === NUMBERING.STANDARD} namePrefix={PREFIX} key={item.id} blockIndex={index} onRemove={remove} onAddBlock={addBlock} />
      ))}

    </TWrapper>
  );
}

export default BlockRegistration;
