import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../../../icons/plus.svg';
import Button from '../../../shared/Button';

export const TWrapper = styled.div`
  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-bottom: 40px;
  }
`;

export const TTitle = styled.p`
  margin-bottom: 12px;
  text-decoration: underline;
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.product.primaryActive};
`;

export const TInvertPlusIcon = styled(PlusIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.product.primaryActive};
  }
`;

export const TButton = styled(Button)`
  width: 100%;
`;
