import {useCallback} from "react";
import {format} from "date-fns";

const usePrepareDataToValidateAmbulatoryCards = () => {

  const prepareDataToValidateAmbulatoryCards = useCallback((patient) => {
    const filteredAmbulatoryCards = patient?.ambulatoryCards?.filter((item: any) => typeof item?.id !== 'number');
    const oldAmbulatoryCards = patient?.ambulatoryCards?.filter((item: any) => typeof item?.id === 'number')
    let newAmbulatoryCard: {number:string; custom_organization: string; organizationId: number;};
    let result: { number: string; custom_organization: string; organizationId: number; }[] = [];

    filteredAmbulatoryCards?.forEach((item: any) => {
      newAmbulatoryCard = {
        number: item.number,
        custom_organization: item.customOrganization,
        organizationId: item.organizationId
      }
      result.push(newAmbulatoryCard)
    });

    let resultPatientAmbulatoryCardsToValidate: any[] = [];

    if(Array.isArray(oldAmbulatoryCards) && Array.isArray(result)) {
      resultPatientAmbulatoryCardsToValidate = [...oldAmbulatoryCards, ...result]
    }

    let patientData: { country: number; ulid: string; gender: string; date_of_death: null; ambulatory_cards: any[]; date_of_birth: string; last_name: string; middle_name: string | null; first_name: string; id_passport: string };
    patientData = {
      ulid: patient?.ulid || '' ,
      id_passport: patient?.idPassport,
      ambulatory_cards: resultPatientAmbulatoryCardsToValidate,
      country:  patient?.country ? patient?.country : 112 ,
      gender: patient?.gender ,
      date_of_birth: patient?.dateOfBirth ? format(patient?.dateOfBirth, 'yyyy-MM-dd') : '',
      date_of_death: null,
      last_name: patient?.lastName ,
      middle_name: patient?.middleName ,
      first_name: patient?.firstName,
    }
    return patientData;

  }, []);

  return {
    prepareDataToValidateAmbulatoryCards
  }

};

export default usePrepareDataToValidateAmbulatoryCards;