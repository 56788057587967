import { useState, useEffect, useCallback } from 'react';
import InfoModal from '../components/shared/InfoModal';
import Button, { SIZE } from '../components/shared/Button';
import { ReactComponent as WarnIcon } from '../icons/warn-red-circle.svg';

const NoInternetConnection = () => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);
  const [showModal, setShowModal] = useState(!isOnline);

  const styleModalText = {
    textAlign: 'center' as 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    color: '#1A1A22',
    marginTop: '46px'
  }

  const onModalClose = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const navigatorOnlineHandler = useCallback(() => {
    setShowModal(false);
    setOnline(true);
  },[setShowModal,setOnline]);

  const navigatorOfflineHandler = useCallback(() => {
    setShowModal(true);
    setOnline(false);
  },[setShowModal,setOnline]);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);

    return () => {
      window.removeEventListener('online',  navigatorOnlineHandler);
      window.removeEventListener('offline', navigatorOfflineHandler);
    }

  }, [navigatorOfflineHandler, navigatorOnlineHandler])

  // event listeners to update the state 
  window.addEventListener('online', navigatorOnlineHandler);
  window.addEventListener('offline', navigatorOfflineHandler);

  return <div>
    <InfoModal
      title='Потеряно соединение с интернетом! '
      showModal={showModal}
      onModalToggle={onModalClose}
      isWarning={true}
      icon={<WarnIcon />}
      hasCloseButton
      buttons={<Button size={SIZE.SMALL} onClick={onModalClose}>OK</Button>}
    >
     <div style={styleModalText}> Если вы редактировали информацию, то по возможности <span style={{color:'#7A78E9'}}>сохраните</span> последние изменения. Несохраненные данные могут быть утеряны.
 Проверьте подключение к интернету.</div>
    </InfoModal>
  </div>

}

export default NoInternetConnection;