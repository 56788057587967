import { createSlice } from '@reduxjs/toolkit';
import { Country } from './model';
import { ErrorsType, Nullable } from '../../types';

export interface ICountryInitialState {
  loading: boolean;
  data: Country[];
  errors: Nullable<ErrorsType>;
}

export const initialState: ICountryInitialState = {
  loading: false,
  data: [],
  errors: null,
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    getCountries: state => {
      state.loading = true;
    },
    getCountriesSuccess: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.errors = null;
    },
    getCountriesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
  },
});

export const { getCountries, getCountriesSuccess, getCountriesFailure } = countriesSlice.actions

export default countriesSlice.reducer;
