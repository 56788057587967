export const MONTHS = {
  'JANUARY': 'январь',
  'FEBRUARY': 'февраль',
  'MARCH': 'март',
  'APRIL': 'апрель',
  'MAY': 'май',
  'JUNE': 'июнь',
  'JULY': 'июль',
  'AUGUST': 'август',
  'SEPTEMBER': 'сентябрь',
  'OCTOBER': 'октябрь',
  'NOVEMBER': 'ноябрь',
  'DECEMBER': 'декабрь',
}

export enum WEEK_DAYS {
  'MO' = 'пн',
  'TU' = 'вт',
  'WE' = 'ср',
  'TH' = 'чт',
  'FR' = 'пт',
  'SA' = 'сб',
  'SU' = 'вс',
}
