import styled from 'styled-components';
import Input from '../../../components/shared/Input';
import Textarea from '../../../components/shared/Textarea';

export const TWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  .nothovered{
    textarea{
        :focus-visible,:hover,:active {
        border: 1px solid ${({ theme }) => theme.colors.gray[500]};
        outline: none;
      }
    }
  }
  .dayPicker {
    width: 35%;
    margin-top: 20px;

    input{
      cursor: pointer;
      border-radius: 10px;
    }
    input:disabled{
      cursor: default;
    }
    button[class*="close-icon"]{
      top: 2px;
    }
  }
  tr.tableData td{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color:${({ theme }) => theme.colors.black[200]};
    &.toxity_select {
      color: ${({ theme }) => theme.colors.product.primaryActive};
    }
  }

  .no-padding-cell > div {
    padding-bottom: 0;
  }
  .pb34{
    padding-bottom: 34px;
  }
  .mb40{
    margin-bottom: 40px;
  }
`;

export const TRowWr = styled.div<{ direction?: string, height?: number }>`
  display: flex;
  justify-content:  ${({ direction }) => direction ? direction : 'space-around'};
  width: 100%;
  min-height: ${({ height }) => height ? `${height}px` : 'inherit'};
  padding-bottom: 16px;
`;

export const TBage = styled.span<{ color?: string }>`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background-color: ${({ theme, color }) => color ? color : theme.colors.gray[400]};
  color: ${({ theme }) => theme.colors.white};
  padding: 0 10px;
  border-radius: 10px;
`;

export const TSection = styled.section`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
  padding-bottom: 20px;
  width: 100%;
`;

export const TSectionHeader = styled.span`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.black[200]};
  font-weight: 600;
  padding-bottom: 16px;
  justify-content: center;
`;

export const TBiomatContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
`;


export const TCustomInput = styled(Input) <{ width: string, background?: string }>`
  position: relative;
  width: ${({ width }) => width};
  height: 60px;
  padding: 20px 0;
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: number-input;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & :read-only {
    cursor:default;
  }
  & input {
    padding: 18px;
    background-color: ${({ background }) => background ? background : '#fff'};
  }
`;

export const TCustomTextarea = styled(Textarea) <{ width: string, background?: string }>`
  position: relative;
  width: ${({ width }) => width};
  margin-top: 20px;
  & :read-only {
    cursor:default;
  }
  
  & textarea {
    :focus,:focus-visible,:hover,:active {
      border-color: ${({ theme }) => theme.colors.gray[500]};
      outline: none;
    }
    background-color: ${({ background }) => background ? background : '#fff'};
  }
`;

export const TDetailsItemWr = styled.div`
  position: relative;
  
  details[open]  {
    margin-bottom: 24px;
    .arrow-img {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
  }
`;

export const TDetails = styled.details`
  box-sizing: border-box;
  
  summary { 
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[100]};
    border-radius: 2px;
    color:${({ theme }) => theme.colors.gray[500]};
  }
  &[open] summary {
    color: ${({ theme }) => theme.colors.violet[500]};
  }

  & :first-child {
    margin-bottom: 4px;
  }
  & :last-child {
    margin-bottom: 0;
  }
`;

export const TDetailsContent = styled.div`
  position: relative;
  background-color:${({ theme }) => theme.colors.white};
  height: max-content;
  border-radius: 10px;
  padding: 30px 21px;
`;

export const TSummary = styled.summary`
  display:flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color:${({ theme }) => theme.colors.black[200]};
  background-color:${({ theme }) => theme.colors.white};
  list-style: none;
  height: 60px;
  border-radius: 10px;
  :hover {
    color: ${({ theme }) => theme.colors.violet[500]};
  }
`;

export const TSummaryContent = styled.span<{ done?: boolean }>`
  display: flex;
  align-items: baseline;
  box-sizing: border-box;
  /* color:${({ theme, done }) => done ? theme.colors.gray[500] : theme.colors.product.primaryActive}; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  padding-left: 22px;
  header {
    display: inline;
    padding-left: 16px;
  }
`;

export const TSummaryIcon = styled.img<{ done?: boolean }>`
  display: inline-block;
  width: 30px;
  height: 30px;
  filter: ${({ done }) => done ? 'none' : 'grayscale(1)'};
  &.arrow-img {
    padding:0;
    width: 12px;
    height: 12px;
  }
`;

export const TTableWrapper = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 32px;

  table {
    table-layout: fixed;
    width: 100%;
    & th {
    color: ${({ theme }) => theme.colors.gray[600]}; 
    }
  }

  td {
    position: relative;
    text-align: left;
    font-size: 12px;
    /* font-weight: 500; */
    line-height: 16px;
    letter-spacing: 0em;

    /* color: ${({ theme }) => theme.colors.gray[600]}; */
  }



  td .left {
    text-align: left;
    padding-left: 16px;
  }

  td .conclusionDescription{ 
    margin:0;
    padding: 0;
    border-top: 1px solid ${({theme}) => theme.colors.gray[200]};
    /* margin: 0 -14px; */
    textarea{
      font-size: 12px;
      border-radius: 0;
      border: none;
      padding-left: 16px;
      ::placeholder {
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;
        color: ${({theme}) => theme.colors.gray[200]};
        opacity: 1;
      }
  }
}
  td .conclusionDescription div[disabled] textarea{
    padding-left: 16px;
    padding-right: 0;
    :focus-visible,:hover,:active {
        border: 1px solid transparent;
        outline: none;
      }
  }

  div[class*="-menu"] {
    width: 100%;
    min-width: min-content;
  }

  td.error {
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.alert.warning};
  }

  .conclusionDescription {
    margin-bottom: 12px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray[200]};
  }

  .marginBottom1 {
    margin-bottom: 1px;
  }


  .leftTopRadius {
    border-top-left-radius: 10px;
  }

  .leftBotRadius {
    border-bottom-left-radius: 10px;
  }

  .violet {
    background-color: ${({ theme }) => theme.colors.violet[100]};
  }

  .head {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.gray[500]};
  }

  td.rigthTopRadius {
    border-radius: 0;
    border-top-right-radius: 10px;
  }

  .borderTop {
    border-top-style: solid;
    border-top-width: 1px;

  }

  .borderRight {
    border-right-style: solid;
    border-right-width: 1px;
  }

  .borderLeftNone {
    border-left: none;
  }
  
  td.noBorderRadius {
    border-radius: 0;
  }
  td.topRadius {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  td.lastExpConclusion{
    padding: 12px 17px;
  }

    /* Custom multiselect style */

    div[class$=-control] {
    border-color: ${({theme}) => theme.colors.gray[400]};
    background-color: ${({theme}) => theme.colors.white};
    box-shadow: none;

    div[class$=-multiValue] {
      border-color: ${({theme}) => theme.colors.white};
      padding: 8px 11px;
      background: ${({theme}) => theme.colors.white};
      border-radius: 10px;
      border: 1px solid ${({theme}) => theme.colors.product.primaryActive};
      text-overflow: ellipsis;
      & :first-child {
        white-space: unset;
      }

      & :last-child {
        :hover {
          background-color: transparent;
        }

        svg {
          border-radius: 50%;
          color: ${({theme}) => theme.colors.white};
          border: 1px solid ${({theme}) => theme.colors.gray[200]};
          background-color: ${({theme}) => theme.colors.gray[200]};

          :hover {
            border-color: ${({theme}) => theme.colors.alert.warning};
            color: ${({theme}) => theme.colors.alert.warning};
          }
        }
      }
    }
  }


  & div.formSelect {
    width: 100%;
    margin-bottom: 30px;

    div[class$=SelectContainer]{
      div[class$=control] {
        padding-left: 16px;
        padding-right: 16px;
        border: 1px solid ${({theme}) => theme.colors.gray[500]};
        border-radius: 10px;

      }
      div[class$=MenuList] > *{
        :hover {
          background-color: ${({ theme }) => theme.colors.product.primaryHover};
          color: ${({ theme }) => theme.colors.white};
          border-radius: 10px;
        }
      }
    }
  }
  .error > div {
    box-sizing: border-box;
    border: 1px solid;
    border-radius: 10px;
    border-color: ${({ theme }) => theme.colors.alert.warning};
  }

`;

export const TEditButton = styled.button<{ active?: boolean }>`
  width: max-content;
  padding: 0 10px;
  height: 20px;
  margin-right: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: ${({ theme,active }) => active ? theme.colors.white : theme.colors.blue[200]};
  background-color: ${({ theme,active }) => active ? theme.colors.blue[200] : theme.colors.white};

  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
  
  border-radius: 10px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.blue[200] };
  border-width: 1px;

  &:disabled {
    cursor: default;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]} !important;
    color: ${({ theme }) => theme.colors.gray[200]};
  }

  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.product.primaryHover};
    color: ${({ theme }) => theme.colors.white };
    border-color: ${({ theme }) => theme.colors.product.primaryHover};
  }

  &:active, &:focus {
    outline-style: none;
    outline-width: 0px !important;
    outline-color: none !important;

  }
  &::-moz-focus-inner {
    border: 0 !important;
  }
`;

export const THeader = styled.div`
  display: flex;
  align-items: start;
  margin: 16px 0 9px;
  
  & > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray[500]};
    width: 510px;
  }
  & > p:last-child {
    width: 176px;
    padding-left: 10px;
  }
`;

export const TSectionTitle = styled.div<{ width?: string, color?: string }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme, color }) => color ?? theme.colors.black[100]};
  width:${({ width }) => width ?? '100%'};
  text-align: center;
  /* margin: 16px 0 16px; */
  padding-bottom: 16px;
`;

export const TWarningText = styled.span<{ width?: string, color?: string }>`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme, color }) => color ?? theme.colors.black[100]};
  width:${({ width }) => width ?? '100%'};
`;


export const TExecutor = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray[500]};
  padding-bottom: 23px;
  span {
    font-weight: 400;
  }
`;

export const CustomButton = styled.button<{ disabled: boolean, width?: string }>`
  width: ${({ width }) => width ? width : '282px'};
  height: 44px;
  border-radius: 10px;
  border: ${({
  theme,
  disabled
}) => disabled ? `2px solid ${theme.colors.gray[200]}` : `2px solid ${theme.colors.blue[200]}`};
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  background-color: transparent;
  color: ${({ theme, disabled }) => disabled ? theme.colors.gray[200] : theme.colors.blue[200]};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;

export const TButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 50px;
  margin: 30px 0;
  width: 100%;
`;

export const TButtonColumnWr = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  > button:first-child {
    margin-bottom: 12px;
  }
`;

export const TCneckboxWr = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: baseline;
  justify-content: start;
  label span {
    color: ${({ theme }) => theme.colors.black[200]};
  }
  label {
    div:first-child {
      margin-bottom: 0;
    }
  }
`;

export const TMorphConclusionWr = styled.div<{isEdit?:boolean}>`
  display: flex;
  position: relative;
  width: 100%;
  color: ${({ theme,isEdit }) => isEdit ? theme.colors.black[200] : theme.colors.gray[500]  };
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;

  & :first-child {
    width: 40%;
    padding-left: 16px;
    padding-top: 18px;
    margin-bottom: inherit;
    border-color: ${({ theme }) => theme.colors.gray[400]};
    border-width: 1px;
    border-style: solid;
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: 700;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.gray[500] };
    cursor: default;
  }

  & :last-child {
    width: 60%;
    padding: ${({ isEdit }) => !!isEdit ? '16px 0 0 16px' : '16px 0 16px 16px'};
    margin-bottom: inherit;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.gray[400]};
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;  
    cursor: ${({ isEdit }) => isEdit ?  'text' : 'default'};
    color: ${({ theme,isEdit }) => isEdit ? theme.colors.black[200] : theme.colors.gray[500]  };
    textarea {
      width: 100%;
      border-color: transparent;
      height: 100%;
      min-height: 165px;
      resize: vertical;
      padding: 0 16px 0 0;
      border: none;
      outline: none;
      font-size: 16px;
      line-height: 1.5;
      overflow-y: auto;
      scrollbar-width: thin;

      ::-webkit-scrollbar {
          width: 6px;
      }
      ::-webkit-scrollbar {
        scrollbar-color: #B7B5F1 #EFEEFF;
      }
        
      scrollbar-color: #B7B5F1 #EFEEFF;
        

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
      }
    }
  }
`;

export const TMorphConclusionCol = styled.div<{isTitle?:boolean; }>`
  background-color: ${({ theme, isTitle }) => !!isTitle ? '#F1F0FF' : theme.colors.white};
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
`;

export const TImplementor = styled.div<{isEdit?:boolean}>`
    width: 100%;
    margin-top: 13px;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    color: ${({ theme, isEdit }) => !!isEdit ? theme.colors.black[200] : theme.colors.gray[500] };
    font-weight: 400;
  b {
    font-weight: 600;
  }
`;

export const TDateOfCompletion = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({theme}) => theme.colors.gray[500]};
`;