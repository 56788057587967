import { forwardRef, useRef, useEffect, ChangeEvent, ReactNode, RefObject } from 'react';
import { TRoot, TLabelWr, TFakeCheckbox, TInput, TCheckIcon, TSmallCheckIcon } from './styled';

interface CheckboxProps {
  indeterminate?: boolean;
  disabled?: boolean;
  error?: boolean | null;
  label?: string | null | ReactNode;
  className?: string;
  checked?: boolean;
  id?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  smallSize?: boolean;
  reverse?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement | null, CheckboxProps>((props, ref) => {
  const { indeterminate, label, className, id, checked, smallSize, reverse, disabled, ...rest } = props;
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    if (resolvedRef && (resolvedRef as RefObject<HTMLInputElement>).current) {
      (resolvedRef as RefObject<HTMLInputElement>)!.current!.indeterminate = indeterminate || false;
    }
  }, [resolvedRef, indeterminate]);

  return (
    <TRoot className={className} htmlFor={id} checked={checked} reverse={reverse} disabled={disabled}>
      <div>
        <TInput id={id} type='checkbox' ref={resolvedRef} checked={checked} disabled={disabled} {...rest} />
        <TFakeCheckbox smallSize={smallSize}>
          {smallSize ? <TSmallCheckIcon /> : <TCheckIcon />}
        </TFakeCheckbox>
      </div>

      {label && (
        <TLabelWr>
          {typeof label === 'string' || label instanceof String ? <span>{label}</span> : label}
        </TLabelWr>
      )}
    </TRoot>
  );
});

Checkbox.defaultProps = {
  indeterminate: false,
  label: null,
  error: false,
  checked: false,
  onChange: () => null,
};

export default Checkbox;
