import { Dispatch } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { loadLocalStorageData, saveLocalStorageData } from '../../utils/localStorageHelpers';
import {
  getOrganizations,
  getOrganizationsFailure,
  getOrganizationsSuccess,
} from './';

export const fetchOrganizations = (access: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getOrganizations());
  const savedData = await loadLocalStorageData('account/organization/list/');
  if(savedData) return dispatch(getOrganizationsSuccess(savedData));

  try {
    const response = await axiosInstance(access).get('account/organization/list/');
    await saveLocalStorageData('account/organization/list/', response.data);
    dispatch(getOrganizationsSuccess(response.data));
  } catch (error: any) {
    dispatch(getOrganizationsFailure(error?.response?.data));
  }
}