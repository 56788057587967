import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { TWrapper } from './styled';
import { TBottomButtonsContainer, TTitle } from '../../styled';
import Button, { SIZE, VARIANT } from '../../../../components/shared/Button';
import { IStep } from '..';
import { getLocalizations } from '../../../../store/localizations/selectors';
import ClinicalDataForm from '../../../../components/forms/ClinicalDataForm';
import { fetchLocalizations } from '../../../../store/localizations/thunkActions';
import { setReferralClinicalData } from '../../../../store/referral/thunkActions';
import { getTokens } from '../../../../store/auth/selectors';
import {fetchMKB10} from "../../../../store/mkb10/thunkActions";
import {getMkb10Selector} from "../../../../store/mkb10/selectors";
import useShowNoMatchError from "./MKB_10/hooks/useShowNoMatchError";
import usePrepareMKB10List from "./MKB_10/hooks/usePrepareMKB10List";

const ClinicalData: FC<IStep> = ({ goNext, onCancelCreation, goBack }) => {
  const dispatch = useDispatch();
  const formMethods = useForm({ mode: 'onChange' });

  const localizations = useSelector(getLocalizations);
  const tokens = useSelector(getTokens);
  const mkb10 = useSelector(getMkb10Selector);
  //state to input mkb 10 value
  const[mkb10Value, setMkb10Value] = useState('');

  const[currentValue, setCurrentValue] = useState(false);
  const[isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if(mkb10Value.length >= 3) {
      setCurrentValue(true)
    }
  }, [mkb10Value.length])

  //hook to expand the array into a flat structure
  const {preparedMKB10List} = usePrepareMKB10List(mkb10);

  const filteredMKB10 = useMemo(() => {
    return preparedMKB10List.filter((item) => {
      if(mkb10Value.length >= 3) {
        return item.includes(mkb10Value);
      }
      return false;
    })
  }, [mkb10Value, preparedMKB10List])

  //hook to show error if value not correct
  const {showNoMatchError} = useShowNoMatchError(filteredMKB10, currentValue, isOpen)

  const { handleSubmit } = formMethods;

  useEffect(() => {
    if (!localizations.length) {
      tokens?.access && dispatch(fetchLocalizations(tokens.access));
    }
  }, [tokens?.access, localizations, dispatch])

  const onContinue = useCallback((data) => {
    dispatch(setReferralClinicalData(data));
    goNext && goNext();
  }, [dispatch, goNext]);


  useEffect(() => {
    tokens?.access && dispatch(fetchMKB10(tokens?.access))
  }, [tokens?.access, dispatch])

  return (
    <>
      <TWrapper>
        <div>
          <TTitle>Клинические данные</TTitle>
          <FormProvider {...formMethods}>
            <ClinicalDataForm
              localizationsData={localizations}
              items={mkb10}
              mkb10Value={mkb10Value}
              setMkb10Value={setMkb10Value}
              filteredMKB10={filteredMKB10}
              showNoMatchError={showNoMatchError}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </FormProvider>
        </div>
      </TWrapper>

      <TBottomButtonsContainer>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={goBack}>Назад</Button>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onCancelCreation}>Отмена</Button>
        <Button size={SIZE.SMALL} onClick={handleSubmit(onContinue)} disabled={showNoMatchError}>Далее</Button>
      </TBottomButtonsContainer>
    </>
  );
}

export default ClinicalData;
