import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Table, Thead } from "../../../components/shared/BiomaterialTable/BiomaterialTable";
import Textarea from "../../../components/shared/Textarea";
import { ISubBlock } from "../../../store/molecularProfiling/model";
import { CustomButton, TButtonWrapper, TDescriptionWr, TGlassMessage, TInfoTextWrapper, TTableWr, TTBodyMorphological } from "./styled";
import InfoModal from "../../../components/shared/InfoModal";
import Button, { SIZE, VARIANT } from "../../../components/shared/Button";
import MorphTableRow from "./MorphTableRow";
import { useSelector } from "react-redux";
import { getMolecularProfilingLoading } from "../../../store/molecularProfiling/selectors";

interface IComponentProps {
  isEdit: boolean;
  allDataSaved: boolean;
  readyToConfirm: boolean;

  doctorName: string;
  recommendations: string;

  subBlockList: ISubBlock[];
  saveData: (type:string,data: { description: string, implementer: string, tableFields: ISubBlock[] }) => void;
  hasChangesTrigger: (isChange:any) => void;
  confirmSend: (data: { description: string, implementer: string, tableFields: ISubBlock[] }) => void;

  hasPermit: boolean;
  isComplited: boolean;
  isSaved: boolean;
  [index: string]: any;
}

interface ITableHeader {
  id?: number;
  title: string;
  class?: string;
}

const shortTableHeaders: ITableHeader[] = [
  { id: 1, title: 'Номер блока', },
  { id: 2, title: 'Опухолевых клеток, %' },
  { id: 3, title: 'Качество образца', },
  { id: 4, title: 'Приоритет кандидата', },
];

let timeoutID:any;


const MorphTable: FC<IComponentProps> = ({ 
    isEdit, subBlockList, doctorName, recommendations, allDataSaved, isComplited,
    saveData, hasChangesTrigger, confirmSend, readyToConfirm, hasPermit, isSaved }) => {

  const { control, register, watch, setValue,getValues, handleSubmit, formState: { errors }, clearErrors, trigger, setError } = useForm({
    defaultValues: {
      description: recommendations ?? '',
      implementer: doctorName,
      tableFields: [...subBlockList]
    }
  });

  const { fields } = useFieldArray({ control, name: 'tableFields' });
  const MolecularProfilingLoading = useSelector(getMolecularProfilingLoading);

  const [allQalityBad, setAllQalityBad] = useState<boolean>(false);

  //Save changes and confirm
  const [isShowConfirm, setShowConfirm] = useState<boolean>(false);

  const onCloseConfirmModal = useCallback(() => setShowConfirm(false), []);
  const [reqSubmitted, setReqSbmitted] = useState<boolean>(false);

  const [checkIsTableFieldsFilled, setCheckIsTableFieldsFilled] = useState<boolean>(false);
  const [dataChenged, setDataChenged] = useState<number>(Date.now());

  useEffect(() => {
    let isDataChanged = false;
    clearErrors();

    const subscription = watch((value, { name, type }) => {
      //CELL PERCENTAGE
      if (name?.includes('cancerousCellPercentage') && type === 'change') {

        let tableData = value?.['tableFields'] ?? []
        for(let i = 0; i < tableData.length; i++){
          if(tableData?.[i]?.cancerousCellPercentage === null){
            setValue(`tableFields.${i}.cancerousCellPercentage`,null);
            setValue(`tableFields.${i}.priority`,0);
            setValue(`tableFields.${i}.quality`,'none');
          }
        }
        isDataChanged = true;
      }
      //QUALITY
      if (name?.includes('quality') && type === 'change') {
        let selectedQualityVal: string[] = []
        value?.['tableFields']?.forEach((fild, index: number) => {
          selectedQualityVal.push(fild?.quality ?? 'none');
          if(fild?.quality === 'bad'){
            setValue(`tableFields.${index}.priority`,0)
          }
        })
        let isAllBad = selectedQualityVal.every((value: string) => value === 'bad');
        setAllQalityBad(isAllBad);
        isDataChanged = true;
      }
      //PRIORITY
      if (name?.includes('priority') && type === 'change') {
        isDataChanged = true;
      }
      
      if (name?.includes('description') && type === 'change') {
        isDataChanged = true;
      }
      if(isDataChanged){
        clearTimeout(timeoutID);
        timeoutID = setTimeout(()=> hasChangesTrigger({tableFields:getValues('tableFields'),description:getValues('description')}),1000);
        setDataChenged(Date.now())
        isDataChanged = false;
      }
    });
    return () => {
      subscription.unsubscribe();
      clearTimeout(timeoutID);
    };
  }, [watch, setValue,getValues, setAllQalityBad, hasChangesTrigger,clearErrors, setDataChenged]);


  const handleValidateForm = useCallback(async () => {
    let result = await trigger(undefined,{ shouldFocus: true });
    if(result) setShowConfirm(true);
    return result
  }, [setShowConfirm,trigger]);

  const handlerCharacteristicForm = useCallback(async (formData) => {
    clearErrors();
    await trigger(undefined,{ shouldFocus: true });
    let errorMessage = { type: 'custom', message: 'Заполните обязательное поле' };
    let errorCount = 0;
    //check data
    formData.tableFields.forEach((field:ISubBlock, index:number) => {
      if(field.quality === 'none'){
        ++errorCount;
        setError(`tableFields.${index}.quality`,errorMessage)
      };
      if(field.quality === 'good' && !(+field.priority)){
        ++errorCount;

        setError(`tableFields.${index}.priority`,errorMessage)
      };
    })
    if(!errorCount) saveData('resultsOfMorphCharSaved',formData);
  }, [clearErrors,trigger,setError,saveData]);

  const handleSaveData = useCallback(() => {
    let [description,tableFields,implementer] = getValues(['description','tableFields','implementer']);
    saveData('resultsOfMorphCharSaved',{description,tableFields,implementer});

  },[getValues,saveData]);


  useLayoutEffect(() => {
    let result = false;
    if(!!subBlockList?.length){
      let quality:string[] = [];
      let isCorrectFilledTable = subBlockList.every((field:ISubBlock) => {
        quality.push(field.quality);
        if(field.quality === 'none')  return false;
        if(field.quality === 'good')  return !!(field.cancerousCellPercentage !== null && field.priority);
        if(field.quality === 'bad')   return !!(field.cancerousCellPercentage !== null)
        return false;
      });
      let isAllQualityBad = quality.every((q:string) => q === 'bad');
      if(isCorrectFilledTable && isAllQualityBad){
        if(recommendations) result = true;
      }
      if(isCorrectFilledTable && !isAllQualityBad){
        result = true;
      }
    };
    setCheckIsTableFieldsFilled(result);
  },[subBlockList,recommendations]);

  const IsTableFieldsChanged = useMemo(() => {
    if(!dataChenged) return false;
    let result = false;
    let quality:string[] = [];

    let [tableFields,description] = getValues(['tableFields','description']);

    if(!!tableFields?.length){
      let isCorrectFilledTable = tableFields.every((field:ISubBlock) => {
        quality.push(field.quality);
        if(field.quality === 'none')  return false;
        if(field.quality === 'good')  return !!(field.cancerousCellPercentage !== null && field.priority);
        if(field.quality === 'bad')   return !!(field.cancerousCellPercentage !== null)
        return false;
      });
  
      let isAllQualityBad = quality.every((q:string) => q === 'bad');
      if(isCorrectFilledTable && isAllQualityBad){
        if(description) result = true;
      }
      if(isCorrectFilledTable && !isAllQualityBad){
        result = true;
      }
    }

    
    if(checkIsTableFieldsFilled !== result) setCheckIsTableFieldsFilled(result);
    return result
  },[dataChenged,checkIsTableFieldsFilled,setCheckIsTableFieldsFilled,getValues]);

  return <>
    {!!fields?.length && <TInfoTextWrapper>
      <TGlassMessage>{isComplited ? 'Результаты морфологической характеристики' : '3. Зафиксируйте результаты морфологической характеристики'}</TGlassMessage>
    </TInfoTextWrapper>}
    <TTableWr onSubmit={handleSubmit((data: any) => hasPermit && handlerCharacteristicForm(data))}>
      {!!fields?.length && <Table>
        <Thead>
          {shortTableHeaders.map((item: any) => <th className='violet' key={item.id}>{item.title}</th>)}
        </Thead>
        <TTBodyMorphological>
          {fields.map((sub: ISubBlock, index: number) => (
            <MorphTableRow
              key={sub.id}
              index={index}
              register={register} 
              control={control}
              errors={errors}
              isDisabled={!isEdit}       
              subBlock={sub}
            />
          ))}
        </TTBodyMorphological>
      </Table>}
        
      <TDescriptionWr column={true}>

        {(!isComplited || (isComplited && !!getValues('description'))) && <Textarea
          {...register('description', { required: allQalityBad })}
          bordered={true}
          label='Комментарий'
          className={`description`}
          placeholder={`Комментарий обязателен, если все блоки неудовлетворительного качества`}
          readOnly={!isEdit}
          disabled={!isEdit}
          error={!!errors?.description}
          defaultValue={getValues('description')}
        />}

        {doctorName && (isSaved || isComplited) && <div className='implementer'><b>{`${doctorName?.split(',')[0]}`.trim()}</b>, {`${doctorName?.split(',')[1]}`.trim()}</div>}

      </TDescriptionWr>
      {hasPermit && !isComplited &&(
        <TButtonWrapper>
          <CustomButton type="button" disabled={allDataSaved} onClick={handleSaveData}>Приостановить работу</CustomButton>
          <CustomButton type="submit" primary={true} disabled={(!checkIsTableFieldsFilled || !IsTableFieldsChanged)} onClick={() => handleValidateForm()}>Завершить характеристику</CustomButton>
        </TButtonWrapper>
      )}
    </TTableWr>

    <InfoModal
      showModal={isShowConfirm}
      onModalToggle={onCloseConfirmModal}
      isWarning={true}
      title={'Хотите завершить морфологическую характеристику?'}
      hasCloseButton
      buttons={
        <>
          <Button size={SIZE.SMALL} disabled={!!MolecularProfilingLoading || reqSubmitted} variant={VARIANT.TRANSPARENT} onClick={onCloseConfirmModal}>Нет</Button>
          <Button size={SIZE.SMALL} disabled={!!MolecularProfilingLoading || reqSubmitted} onClick={() => {
            setReqSbmitted(true);
            confirmSend(getValues())
            }}>Да</Button>
        </>
      } />
  </>
};

export default MorphTable;
