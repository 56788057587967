import styled from 'styled-components';

const TContainer = styled.div`
  width: 100%;
  position: relative;
`;

const TBackgroundWr = styled.div<{ transparent?: boolean; enabled?: boolean }>`
  background: rgba(220,220,220,0.1);;
  z-index: 10;
  overflow: ${({ enabled }) => (enabled ? 'visible' : 'hidden')};
  display: ${({ enabled }) => (enabled ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const TSimpleLoader = styled.div`
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;

  & > div {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */

    & > div {
      height: 42px;
      width: 42px;
      border-left: 4px solid ${({theme}) => theme.colors.product.primaryActive};
      border-bottom: 2px solid ${({theme}) => theme.colors.product.primaryActive};
      border-right: 1px solid ${({theme}) => theme.colors.product.primaryActive};
      border-top: 3px solid transparent;
      border-radius: 50%;
      animation: spinner 2s linear infinite;
    }
  }
`;

export { TContainer, TBackgroundWr, TSimpleLoader };
