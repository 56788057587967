import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { IAnalisysByMethod, IResultOptions, Kit, MarkerFishExperiment, MarkerReference, SaveExperimentUpdate, Signal } from "../../../../../store/analysis/model";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';

import Select from "react-select";
import Textarea from "../../../../../components/shared/Textarea";
import { Table, TBody } from "../../../../../components/shared/BiomaterialTable";
import defaultTheme from "../../../../../styles/theme";
import { TEditButton, TRowWr } from "../../../MarkersValidation/styled";
import { TDetailsContent } from "../../../../../components/shared/Details/styled";
import { TWrapper, TExecutor, TButtonWrapper, CustomButton, customStylesOptions, TSectionTitle, TCustomInput, TOtherExecutors, TBioAcquired, TDateOfCompletion } from "../../styled";
import { getProfile, getTokens } from "../../../../../store/auth/selectors";
import { fetchAnalysisByBluprint, patchBioExperementUpdate, patchExperimentUpdate, resetAnalysisByBluprint } from "../../../../../store/analysis/thunkActions";
import { ReactComponent as BlockingIcon }  from '../../../../../icons/blocking-icon.svg';
import { TBlockFieldButton } from "./styles";
import Tooltip from "../../../../../components/shared/Tooltip";
import { getAndOverWriteEperement } from "../../../../../store/molProConclusion/thunkActions";
import InfoModal from "../../../../../components/shared/InfoModal";
import Button, { SIZE, VARIANT } from "../../../../../components/shared/Button";
import { ReactComponent as WarnIcon } from '../../../../../icons/warn-red-circle.svg';
import { IBlueprintAdvisors, IExamExecutors } from "../../CreateExperiment";

interface IComponentProps {
  flowStepsActiveIndex: any;
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  isActive:boolean;
  hasPermit:boolean;
  defaultValues?: any;
  ableToUpdate?: boolean;
  isReferralComplite: boolean;
  labAssistantMap?:{ [index: string]: {fullname:string, specialization:string} } | undefined;
  experementBioSteps: JSX.Element | JSX.Element[];
  referralULID:string;
  defaultExecutorOptions?: any;
  examExecutors: IExamExecutors;
  [index: string]: any;
}

interface ISelectOptions {
  value: string | number;
  label: string;
}

interface SignalValidator {
  min?: number;
  max?: number;
}
interface SignalValues {
  signalCountTitle: string;
  proportionTitle: string;
  name: string;
  signalCountValidator: SignalValidator;
  signalCountMarkerValidator: SignalValidator;
  cancerCellsCountValidator: SignalValidator;
}

const regexManualInputPattern = new RegExp(/[^0-9]/g );
const amplificReasonStatus = [19,20,22,'Неопределенный','Неинформативно','Не выполнялось']


const TablelFISHAmplific: FC<IComponentProps> = ({ referralULID, isReferralComplite, flowStepsActiveIndex, analisysByMethod,isActive,hasPermit, 
  defaultValues, ableToUpdate, labAssistantMap, experementBioSteps, examExecutors }) => {
  const { register, control, watch, handleSubmit, formState: { errors },  reset, setValue,getValues,clearErrors,setError} = useForm();
  const watchExamResult = watch('examResult', { value: 0, label: '--' });
  const watchSignal = watch('signal');

  
  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const profile = useSelector(getProfile);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [dataAttempt, setDataAttempt] = useState<MarkerFishExperiment>();
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const [resertCellsForm, setResertCellsForm] = useState<number>(Date.now());
  
  const [resultOptions, setResultOptions] = useState<ISelectOptions[]>([]);
  const [reagentKitOptions, setReagentKitOptions] = useState<ISelectOptions[]>([]);
  const [conclusionOptions, setConclusionOptions] = useState<{ [index: number]: ISelectOptions[] }>({});
  const [signalData, setSignalData] = useState<{ [index: string]: Signal }>({});
  const [notInformativeSample, setNotInformativeSample] = useState<boolean>(false);
  
  const setDefaultValues = useCallback(() => {
    if (!!defaultValues && Object.keys(defaultValues)?.length) {
      let { signalsMap,resultMap,kitMap,kit,conclusionMap,name,amplification = {} } = defaultValues ?? {};
      let {signalToCellCount:signal_to_cell_count,signalMarkerCount:signal_marker_count,signalCount:signal_count,
        result,proportion,notes:description,conclusion,cancerCellNucleusCount:cancer_cell_nucleus_count} = amplification;
        let kitValues = {value:kit,label:kitMap?.[kit]}
        let resultValues = {value:result,label:resultMap[result ?? 0]}
        let conclusionValues = { value: +conclusion, label: conclusionMap?.[+conclusion ?? 0] }
        let signalValues = (signalsMap?.[kit])
        let examConclusionManual = !(+conclusion) ? conclusion : ''
        
        const defaults: { [index: string]: any; } = {
        cancer_cell_nucleus_count,
        signal_marker_count,
        signal_count,
        proportion,
        signal_to_cell_count,
        description,
        reagentKits: kitMap && kit ? kitValues : undefined,
        examResult: resultMap && !!result ? resultValues : undefined, 
        examConclusion: !!conclusion && conclusionMap ? conclusionValues : undefined,
        name,
        signal: kit && kitMap ? kitValues?.label ?? '' : undefined,
        examConclusionManual
      }
      
      setSignalData(signalValues)

      reset({ ...defaults });
      setResetFormKey(Date.now())
      return true;
    }
    return false;
  }, [defaultValues, setResetFormKey, reset]);

  useLayoutEffect(() => {
    if(!analisysByMethod?.markerFish?.analysisFishExperiments && !flowStepsActiveIndex) return;

    //Register non editable fields
    register('proportion', { value: '--' });
    register('signal_to_cell_count', { value: '--' });

    //Curent atempt
    let attempt:MarkerFishExperiment = analisysByMethod?.analysisFishExperiments?.filter((fishData:MarkerFishExperiment) => +fishData.number === +flowStepsActiveIndex)?.[0];
    if(attempt) setDataAttempt(attempt);

    //Signals
    let signalsMap:{ [index: string]: Signal } = {};

    //Kits, zonds
    const KitOptions:ISelectOptions[] = analisysByMethod?.markerFish?.kit?.map((kit:Kit) => {
      signalsMap[kit.name] = kit.signals;
      return {value:kit.id,label:kit.name}}) ?? [];
    

    setSignalData(signalsMap);
    if(KitOptions?.length) setReagentKitOptions(KitOptions);
    //Conclusions & Results
    const ConclusionOptionsMap:{ [index: number]: ISelectOptions[] } = {};
    const ResultOptions: ISelectOptions[] = [];

    let examResultList: IResultOptions[] = analisysByMethod?.markerFish?.results?.map((data: IResultOptions) => data) ?? [];

    for(let examResult of examResultList){
      ResultOptions.push({value:examResult.id,label:examResult.content});

      for(let conclusion of examResult.results) {
        if(!ConclusionOptionsMap?.[conclusion.id]) ConclusionOptionsMap[conclusion.id] = [{value:conclusion.id,label:conclusion.content}]
        else ConclusionOptionsMap[conclusion.id].push({value:conclusion.id,label:conclusion.content});
      }
    }

    if(Object.keys(ConclusionOptionsMap)?.length) setConclusionOptions(ConclusionOptionsMap);
    if(ResultOptions?.length) setResultOptions(ResultOptions);
    setDefaultValues()
  },[flowStepsActiveIndex,setDataAttempt,setReagentKitOptions,setConclusionOptions,setResultOptions,
    analisysByMethod,setSignalData,register,setDefaultValues]);

    const [executorfullName, executorTitle] = useMemo(()=> {
      if(examExecutors?.blueprintExecutors?.length){
        let data = examExecutors?.blueprintExecutors?.[0]
        let {firstName = '', lastName = '',middleName = '',specialization = ''} = data;
        return [`${lastName} ${firstName} ${middleName}`.trim(), specialization];
      }
      if(!profile) return ['','']
      let {firstName = '', lastName = '',middleName = '',title = ''} = profile;
      const executorfullName = `${lastName} ${firstName} ${middleName}`.trim();
      return [executorfullName,title];
    },[profile,examExecutors]);

  //handle edit tab button
  const handleEditTable = useCallback(async () => {
    // if(!isActive || !hasPermit) return;
    if(!hasPermit) return;
    if (isEdit) {
      if(!setDefaultValues()){
        reset({}, { keepValues: false });
        setResetFormKey(Date.now());
      }
      return setEdit(false);
    }
    setNotInformativeSample(false);
    setEdit(true);
  }, [isEdit, setEdit,reset,setResetFormKey,hasPermit,setNotInformativeSample, setDefaultValues]);

  const signalDataValues = useMemo(() => {
    let values:SignalValues = {
      signalCountTitle: '',
      proportionTitle: '',
      name:'',
      signalCountValidator: {},
      signalCountMarkerValidator: {},
      cancerCellsCountValidator: {},
    }
    let data = signalData?.[watchSignal] ?? defaultValues?.signalsMap?.[defaultValues?.kit]
    if(data){
      values = { 
        name: data?.name ?? '',
        signalCountTitle: data?.name ?? '',
        proportionTitle: `${analisysByMethod?.markerFish?.name}/${data?.name}`,
        signalCountValidator: {min:data.signalCountMin,max:data.signalCountMax},
        signalCountMarkerValidator: {min:data.signalCountMarkerMin,max:data.signalCountMarkerMax},
        cancerCellsCountValidator: {min:data.cancerCellsCountMin,max:data.cancerCellsCountMax},
      }
    }

    return values;
  },[watchSignal,signalData,analisysByMethod, defaultValues]); 

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      let A,B,C,errorCount = 0;
      if(value?.['cancer_cell_nucleus_count']){
        let fildValue = +(parseInt(value?.['cancer_cell_nucleus_count']));
        if(fildValue) A = fildValue;
        if(name === 'cancer_cell_nucleus_count' && !notInformativeSample){
          let errorMessage = '';
          clearErrors('cancer_cell_nucleus_count');
          if(!fildValue) errorMessage = 'Заполните обязательное поле';
          if(fildValue && (fildValue < (signalDataValues?.cancerCellsCountValidator?.min ?? 50)))  errorMessage = `Значение должно быть больше ${signalDataValues?.cancerCellsCountValidator?.min ?? 50}`;
          if(fildValue && (fildValue > (signalDataValues?.cancerCellsCountValidator?.max ?? 200))) errorMessage = `Значение должно быть меньше ${signalDataValues?.cancerCellsCountValidator?.max ?? 200}`;
          
          !!errorMessage && setError('cancer_cell_nucleus_count', { type: 'custom', message: errorMessage });
          !!errorMessage && (errorCount = errorCount +1);
        } 
      } 
      if(value?.['signal_marker_count']){
        let fildValue = +(parseInt(value?.['signal_marker_count']));
        if(fildValue) B = fildValue;
        if(name === 'signal_marker_count' && !notInformativeSample){
          let errorMessage = '';
          clearErrors('signal_marker_count');
          if(!fildValue) errorMessage = 'Заполните обязательное поле';
          if(fildValue && (fildValue < (signalDataValues?.signalCountMarkerValidator?.min ?? 50)))   errorMessage = `Значение должно быть больше ${signalDataValues?.signalCountMarkerValidator?.min ?? 50}`;
          if(fildValue && (fildValue > (signalDataValues?.signalCountMarkerValidator?.max ?? 5000))) errorMessage = `Значение должно быть меньше ${signalDataValues?.signalCountMarkerValidator?.max ?? 5000}`;
          
          !!errorMessage && setError('signal_marker_count', { type: 'custom', message: errorMessage });
          !!errorMessage && (errorCount = errorCount +1);
        }

      } 
      if(value?.['signal_count']){
        let fildValue = +(parseInt(value?.['signal_count']));
        if(fildValue) C = fildValue;
        if(name === 'signal_count' && !notInformativeSample){
          let errorMessage = '';
          clearErrors('signal_count');
          if(!fildValue) errorMessage = 'Заполните обязательное поле';
          if(fildValue && (fildValue < (signalDataValues?.signalCountValidator?.min ?? 50)))   errorMessage = `Значение должно быть больше ${signalDataValues?.signalCountValidator?.min ?? 50}`;
          if(fildValue && (fildValue > (signalDataValues?.signalCountValidator?.max ?? 5000))) errorMessage = `Значение должно быть меньше ${signalDataValues?.signalCountValidator?.max ?? 5000}`;
          
          !!errorMessage && setError('signal_count', { type: 'custom', message: errorMessage });
          !!errorMessage && (errorCount = errorCount +1);
        }

      } 
      if(A && B){
        let signalToCellCount = +(B / A)?.toFixed(1);
        if(value?.['signal_to_cell_count'] !== signalToCellCount) setValue('signal_to_cell_count',signalToCellCount);
      }
      if((!A || !B) && value?.['signal_to_cell_count']) setValue('signal_to_cell_count',undefined);

      if(C && B){
        let proportion = +(B / C)?.toFixed(1);
        if(value?.['proportion'] !== proportion) setValue('proportion',proportion);
      }
      if((!C || !B) && value?.['proportion']) setValue('proportion',undefined);
      
    });
    return () => subscription.unsubscribe();
  }, [watch,setValue,clearErrors,setError,signalDataValues,notInformativeSample]);

  const onSubmitTable = useCallback(async (data:any) => {
    const experementID = dataAttempt?.id;
    
    const {cancer_cell_nucleus_count,signal_marker_count,signal_count,proportion,
      signal_to_cell_count,description,reagentKits,examResult, examConclusionManual} = data;

    const dataForSend = {
      completed: true,
      date_completed: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      kit:reagentKits.value,
      amplification:{
        experiment: experementID,
        cancer_cell_nucleus_count: isNaN(cancer_cell_nucleus_count) ? null : +cancer_cell_nucleus_count,
        signal_marker_count: isNaN(signal_marker_count) ? null : +signal_marker_count,
        signal_count: isNaN(signal_count) ? null : +signal_count,
        proportion: isNaN(proportion) ? null : +proportion,
        signal_to_cell_count: isNaN(signal_to_cell_count) ? null : +signal_to_cell_count,
        result: +examResult?.value,
        conclusion: (examConclusionManual || conclusionOptions?.[examResult?.value ?? '']?.[0]?.label) ?? null,
        notes:description
      },
      lab_assistant: dataAttempt?.labAssistant,
  }

  if(!defaultValues && tokens?.access && experementID){
      await handleEditTable()
      await dispatch(patchBioExperementUpdate(tokens?.access, experementID, dataForSend,'fish'));
    }  
    if(!!defaultValues && tokens?.access && ableToUpdate) {
      let data:{acceptance:boolean; [index:string]: any} = {...dataForSend,acceptance: true, number:defaultValues?.number}
      delete data['date_completed']
      delete data['completed']
      delete data['amplification']['experiment']
      data['amplification']['id'] = defaultValues?.amplification?.id ?? null;
      let dataToUpdate:SaveExperimentUpdate = data
      await dispatch(patchExperimentUpdate(tokens?.access, 'fish', defaultValues.id, dataToUpdate));
      await dispatch(getAndOverWriteEperement(tokens?.access, referralULID));
    }

    dispatch(resetAnalysisByBluprint());
    tokens?.access && setTimeout(() => dispatch(fetchAnalysisByBluprint(tokens?.access, 'fish', analisysByMethod?.blueprint)), 100);
    setEdit(false);

  },[tokens?.access,dispatch,dataAttempt,handleEditTable,analisysByMethod,conclusionOptions,defaultValues,ableToUpdate,referralULID]);
  

  const manualInputHandler = useCallback(({value}:any) => {
    return (value?.trimLeft() ?? value)?.replace(regexManualInputPattern, "")
  },[]);


  const notInformativeSampleHandler = useCallback(() => {
    setNotInformativeSample(!notInformativeSample)
  },[notInformativeSample, setNotInformativeSample]);

  const clearNumberFields = useCallback(()=>{
    setValue('cancer_cell_nucleus_count',undefined);
    setValue('signal_marker_count',undefined);
    setValue('signal_count',undefined);
    setValue('proportion',undefined);
    setValue('signal_to_cell_count',undefined);
    setResertCellsForm(Date.now());
    clearErrors();
    notInformativeSampleHandler();
  },[setValue,setResertCellsForm,clearErrors,notInformativeSampleHandler]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const onModalToggle = useCallback(() => setShowConfirmModal(!showConfirmModal), [setShowConfirmModal,showConfirmModal]);

  const dateOfCompletion = useMemo(() => {
    if (!defaultValues?.dateCompleted) return '';

    return format(new Date(defaultValues?.dateCompleted), 'dd.MM.yyyy - HH:mm:ss');
  }, [defaultValues]);


  return (
    <TDetailsContent>
        {!isReferralComplite ? (
        <TRowWr direction={'space-between'}>
          {!dateOfCompletion ? 
            <TSectionTitle width="60%" color={dataAttempt?.bioAcquired || isActive ? "#7A78E9" : "#777"}>Результаты исследования биоматериала методом FISH</TSectionTitle> : 
            <TDateOfCompletion>{!!dateOfCompletion && `Дата завершения: ${dateOfCompletion}`}</TDateOfCompletion>
            }
          {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate ? <TEditButton disabled={!dataAttempt?.bioAcquired} onClick={handleEditTable}>
          {!isEdit ? 'Редактировать' : 'Отменить'}
        </TEditButton> : hasPermit && <TEditButton disabled={!dataAttempt?.bioAcquired || !isActive} onClick={handleEditTable}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton>}
        </TRowWr>
        ) : (
        <TRowWr direction="flex-start">
          <TSectionTitle width="60%" color="#7A78E9">Результаты исследования биоматериала методом FISH</TSectionTitle>
        </TRowWr>
        )}
        <TWrapper onSubmit={handleSubmit(onSubmitTable)} key={resertFormKey}>
          <Table>
            <TBody>
              <tr>
                <td colSpan={2} className="head violet leftTopRadius">Реагенты (наборы, зонды)</td>
                <td colSpan={4} className={!!errors?.['reagentKits'] ? 'error' : ''}>
                  <Controller
                    control={control}
                    name={'reagentKits'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        onChange={(val) => {
                          setValue('signal',val?.label);
                          setResertCellsForm(Date.now())
                          return onChange(val)}}
                        selected={value}
                        options={reagentKitOptions}
                        placeholder={'--'}
                        styles={customStylesOptions(defaultTheme)}
                        isDisabled={!isEdit}
                        noOptionsMessage={() => "нет опций"}
                        isSearchable={false}
                        components={!isEdit ? ({ DropdownIndicator: () => null, IndicatorSeparator: () => null }) : undefined}
                        defaultValue={getValues('reagentKits')}
                      />
                    )}
                  />
                </td>
              </tr>
            </TBody>
            {/* header */}
            <TBody key={`table_header_${resertCellsForm}`}>
              <tr className="borderTop violet">
                <td className="head flex-content">Маркер</td>
                <td className="head flex-content">
                  <div>Кол-во проанализ. ядер опухолевых клеток </div>
                  <div>{signalDataValues.cancerCellsCountValidator?.min ? `[${signalDataValues.cancerCellsCountValidator?.min} - ${signalDataValues.cancerCellsCountValidator?.max}]` : ''}</div>
                </td>
                <td className="head flex-content">
                  <div>Количество сигналов {analisysByMethod?.markerFish?.name}</div>
                  <div>{signalDataValues.signalCountMarkerValidator?.min ? `[${signalDataValues.signalCountMarkerValidator?.min} - ${signalDataValues.cancerCellsCountValidator?.max}]` : ''}</div>
                </td>
                <td className="head flex-content">
                  <div>Количество сигналов {signalDataValues?.signalCountTitle} </div>
                  <div>{signalDataValues.signalCountValidator?.min ? `[${signalDataValues.signalCountValidator?.min} - ${signalDataValues.signalCountValidator?.max}]` : ''}</div>
                </td>
                <td className="head flex-content">
                  <div>Соотношение</div>
                  <div>{signalDataValues?.proportionTitle}</div>
                </td>
                <td className="head flex-content noRadius">
                  <div>Количество сигналов</div>
                  <div>{signalDataValues?.name && `${analisysByMethod?.markerFish?.name}/клетка`}</div>
                </td>
              </tr>
            </TBody>
            <TBody key={`table_values_${resertCellsForm}`}>
              <tr>
                <td>{analisysByMethod?.markerFish?.name ?? "--"}</td>
                <td className="no-padding-cell"><Tooltip className="tooltip" id="form-tooltip" place="top" overridePosition={({ left, top }) => ({ left: left, top: top })}>
                    {!notInformativeSample ? 'Используйте в случае неинформативности образца' : 'Образец неинформативен'}
                  </Tooltip>
                  <TCustomInput
                    {...register('cancer_cell_nucleus_count', { required: !notInformativeSample,
                      validate: (value:number) => {
                        if(notInformativeSample) return true;
                        if(isNaN(value)) return false;
                        return value >= (signalDataValues?.cancerCellsCountValidator?.min ?? 50) &&
                        value <= (signalDataValues?.cancerCellsCountValidator?.max ?? 200)}
                    })}
                    type='text'
                    iconRight={<TBlockFieldButton active={notInformativeSample} data-for="form-tooltip" data-tip=""><BlockingIcon/></TBlockFieldButton>}
                    onRightIconClick={() => clearNumberFields()}
                    onChange={({target}) => !notInformativeSample && setValue('cancer_cell_nucleus_count',target.value)}
                    onKeyUp={async ({target}) => !notInformativeSample && setValue('cancer_cell_nucleus_count',await manualInputHandler(target))}
                    placeholder='--'
                    disabled={!isEdit || notInformativeSample}
                    error={!!errors?.['cancer_cell_nucleus_count']}
                    defaultValue={getValues('cancer_cell_nucleus_count')}
                  />{notInformativeSample && 
                  <TBlockFieldButton 
                    onClick={() => clearNumberFields()} 
                    active={notInformativeSample} 
                    show={notInformativeSample} 
                    data-for="form-tooltip" 
                    data-tip="">
                    <BlockingIcon/>
                  </TBlockFieldButton>}</td>

                <td className="no-padding-cell"><TCustomInput
                  {...register('signal_marker_count', { required: !notInformativeSample,
                    validate: (value:number) => {
                      if(notInformativeSample) return true;
                      if(isNaN(value)) return false;
                      return  value >= (signalDataValues?.signalCountMarkerValidator?.min ?? 50) &&
                      value <= (signalDataValues?.signalCountMarkerValidator?.max ?? 5000)}
                  })}
                  type='text'
                  onChange={({target}) => setValue('signal_marker_count',target.value)}
                  onKeyUp={async ({target}) => setValue('signal_marker_count',await manualInputHandler(target))}
                  placeholder='--'
                  disabled={!isEdit || notInformativeSample}
                  error={!!errors?.['signal_marker_count']}
                  defaultValue={getValues('signal_marker_count')}
                /></td>

                <td className="no-padding-cell"><TCustomInput
                  {...register('signal_count', { required: !notInformativeSample,
                    validate: (value:number) =>  {
                      if(notInformativeSample) return true; 
                      if(isNaN(value)) return false;
                      return value >= (signalDataValues?.signalCountValidator?.min ?? 50) &&
                      value <= (signalDataValues?.signalCountValidator?.max ?? 5000)}
                  })}
                  type='text'
                  onChange={({target}) => setValue('signal_count',target.value)}
                  onKeyUp={async ({target}) => setValue('signal_count',await manualInputHandler(target))}
                  placeholder='--'
                  disabled={!isEdit || notInformativeSample}
                  error={!!errors?.['signal_count']}
                  defaultValue={getValues('signal_count')}
                /></td>
                <td className="no-padding-cell">
                  <TCustomInput
                  {...register('proportion')}
                  defaultValue={getValues('proportion')}
                  placeholder='--'
                  disabled={true}
                  error={!!errors?.['proportion']}
                />
                </td>
                <td className="no-padding-cell" key={`signal_to_cell_count_${resertCellsForm}`}><TCustomInput
                  {...register('signal_to_cell_count')}
                  defaultValue={getValues('signal_to_cell_count')}
                  placeholder='--'
                  disabled={true}
                  error={!!errors?.['signal_to_cell_count']}
                /></td>
              </tr>
            </TBody>
            <TBody>
              <tr>
                <td colSpan={2} className="head violet leftRadius">Референсные значения</td>
                <td colSpan={4}>
                  {analisysByMethod?.markerFish?.references?.map((ref:MarkerReference) => <div key={ref.id}>{ref.content}</div>)}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="head violet leftRadius">Результат исследования</td>
                <td colSpan={4} className={!!errors?.['examResult'] ? 'error' : ''}>
                <Controller
                    control={control}
                    name={'examResult'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        onChange={(e) => {
                          setValue('examConclusion',conclusionOptions?.[value?.value]?.[0]);
                          setValue('examConclusionManual',undefined);
                          return onChange(e)}}
                        selected={value}
                        options={resultOptions}
                        placeholder={'--'}
                        styles={customStylesOptions(defaultTheme)}
                        isDisabled={!isEdit}
                        noOptionsMessage={() => "нет опций"}
                        isSearchable={false}
                        defaultValue={getValues('examResult')}
                        components={!isEdit ? ({ DropdownIndicator: () => null, IndicatorSeparator: () => null }) : undefined}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={6} className="head violet leftRadius">Клинико-лабораторное заключение</td>
              </tr>
              <tr>
                <td colSpan={6} className={!!errors?.['examConclusionManual'] ? 'error' : ''}>
                  {!amplificReasonStatus?.includes(watchExamResult?.label) ? (
                    conclusionOptions?.[watchExamResult?.value]?.[0]?.label ?? ''
                  ) : (
                    <Textarea
                      {...register('examConclusionManual', {
                        maxLength: 999,
                        required: (conclusionOptions?.[watchExamResult?.value]?.[0]?.label ?? '')?.toLowerCase() === 'иное'
                      })}
                      placeholder={'Текст клинико-лабораторного заключения'}
                      className='conclusionDescription'
                      defaultValue=''
                      height={100}
                      readOnly={!isEdit}
                      disabled={!isEdit}
                      maxLength={300}
                    />
                  )}
                </td>
              </tr>
              {!amplificReasonStatus?.includes(watchExamResult?.label) && ( <tr>
                <td colSpan={6} className={!!errors?.['description'] ? 'error' : ''}>
                  <Textarea
                    {...register('description', {
                      required: (conclusionOptions?.[watchExamResult?.value]?.[0]?.label ?? '')?.toLowerCase() === 'иное',
                      maxLength: 999
                    })}
                    placeholder={'Дополните заключение вручную, \nесли это требуется\n'}
                    className='conclusionDescription'
                    defaultValue={getValues('description') ?? ''}
                    height={100}
                    readOnly={!isEdit}
                    disabled={!isEdit}
                    maxLength={300}
                  />
                </td>
              </tr>)}
            </TBody>
          </Table>
          {(!isActive || !!defaultValues) && !!executorfullName && <TExecutor>
          <b>{executorfullName ?? 'Исполнитель не определен'}</b>
          {executorTitle ? `, ${executorTitle}` : ''}
        </TExecutor>}
        {defaultValues?.labAssistant && (<TBioAcquired height={'84px'}>
          {experementBioSteps}
          <span>Материал подготовил: 
            <b> {labAssistantMap?.[defaultValues?.labAssistant]?.fullname ?? 'Исполнитель не определен'}</b>
            {`, ${labAssistantMap?.[defaultValues?.labAssistant]?.specialization}`}
          </span>
        </TBioAcquired>)}
          {(!isActive || !!defaultValues) && !!examExecutors?.blueprintAdvisory?.length && <TOtherExecutors height='fit-content'>
            {examExecutors?.blueprintAdvisory?.map((advisor: IBlueprintAdvisors) => (
              <div key={`advisorFISH_Ampl${advisor?.fullName}`}><b>{advisor?.fullName ?? 'Исполнитель не определен'}</b>
              {advisor?.specialization ? `, ${advisor?.specialization}` : ''}</div>
            ))}
          
        </TOtherExecutors>}

          <TButtonWrapper>
          {hasPermit && isActive && <Button size={SIZE.SMALL} variant={!isEdit ? VARIANT.DEFAULT : VARIANT.BLUE} type="submit" disabled={!isEdit}>
            Завершить попытку
          </Button>}
          {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate && <CustomButton type="button" onClick={onModalToggle} disabled={!isEdit}>
            Подтвердить изменения
          </CustomButton>}
          </TButtonWrapper>
          <InfoModal
            title='Внесенные изменения будут отображены в заключении. '
            showModal={showConfirmModal}
            isWarning={true}
            icon={<WarnIcon />}
            onModalToggle={onModalToggle}
            buttons={<>
              <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onModalToggle}>Вернуться</Button>
              <Button  size={SIZE.SMALL} type="submit" onClick={handleSubmit(data => onSubmitTable(data))}>Продолжить</Button>
            </>}
          />
        </TWrapper>

      </TDetailsContent>
  )
}

export default TablelFISHAmplific