import styled from 'styled-components';

export const TInputsWr = styled.div`
  display: flex;
  
  & > * {
    margin-right: 3px;
    width: 50%;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
