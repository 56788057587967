import { FC, useCallback, useEffect, useLayoutEffect, useState } from "react";
import InfoModal from "../components/shared/InfoModal";
import { ReactComponent as ClockIcon } from '../icons/clock.svg';
import Button, { SIZE, VARIANT } from "../components/shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { continueSession, signOut } from "../store/auth/thunkActions";
import { getTokenExpiration, getTokens, getUser, isSessionContinued } from "../store/auth/selectors";
import { addAlert } from "../store/notifyAlerts/thunkActions";
import { AlertTypes } from "../store/notifyAlerts/model";
import { resetContinueSession } from "../store/auth";
import { getTimeBetweenDates } from "./dateHelpers";
import { loadLocalStorageData, removeItemLocalStorage, resetLocalStorageData, saveLocalStorageData } from "./localStorageHelpers";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { resetAlerts } from "../store/notifyAlerts";
import { resetSessionStorage } from "./sessionStorageHelpers";

const styleModalText = {
  textAlign: 'center' as 'center',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '32px',
  color: '#1A1A22',
  marginTop: '46px'
}

let sessionTimerID:any;
let checkSessionID:any;

const RefreshToken: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const user = useSelector(getUser);
  const tokenExpiration = useSelector(getTokenExpiration);
  const isSessionRefresh = useSelector(isSessionContinued);
  const [showModal, setShowModal] = useState(false);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [ignorSessionWarning, setIgnorSessionWarning] = useState(false);

  const onSignOut = useCallback(async() => {
    dispatch(resetAlerts())
    clearInterval(sessionTimerID);
    await removeItemLocalStorage('dataLastUpdate','continue_session_ignored');
    tokens?.refresh && tokens?.access && await dispatch(signOut({ refresh: tokens.refresh, access: tokens.access }));
    return navigate(ROUTES.SIGN_IN, { replace: true });
  }, [tokens, dispatch, navigate]);

  const onModalToggle = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const onSignOutModalToggle = useCallback(() => {
    setShowModal(false);
    if(showSignOutModal){
      setShowSignOutModal(false);
      onSignOut();
    } else {
      setShowSignOutModal(true);
    }
  }, [showSignOutModal, setShowSignOutModal,onSignOut,setShowModal]);

  const getFromLocaleStorageAsync = useCallback(async (fieldName:string) => {
    let LocalStorageData = (async () => await loadLocalStorageData(fieldName)?.then((data: any) => data))();
    let result = await LocalStorageData;
    return result;
  },[]);

  const timeExpSession = useCallback(() => {
    if(tokenExpiration){
      return `Время вашей сессии истекает через ${getTimeBetweenDates(Date.now(), +tokenExpiration ).hoursMinutes}`
    }
    return 'Время вашей сессии скоро истечет';

  },[tokenExpiration]);

  const sessionTimerMSG = useCallback(async(msgType:AlertTypes,timeToEnd:string) => {
    let lastUpdate = await getFromLocaleStorageAsync('dataLastUpdate');
    let isUpdated = (Date.now() - +lastUpdate) > 60_000;
    if(isUpdated){
      // if(!ignorSessionWarning) setShowModal(true);
      await saveLocalStorageData('dataLastUpdate',Date.now());
      dispatch(addAlert(AlertTypes.INFO, '', `Сохраните последние измения до завершения сессии` ));
      dispatch(addAlert(msgType, '', `Сессия истекает через ${timeToEnd}` ));
    }
  },[dispatch,getFromLocaleStorageAsync]);

  const checkIgnorSession = useCallback(async () => {
    let isSessionModalIgnored = await getFromLocaleStorageAsync('continue_session_ignored') ?? false;
    setIgnorSessionWarning(isSessionModalIgnored);
    if(!isSessionModalIgnored) setShowModal(true);
  },[getFromLocaleStorageAsync,setIgnorSessionWarning,setShowModal]);


  const checkSessionTimer = useCallback(async () => {
    let timeText = '';
    let timeToEnd = 0;
    
    if(tokenExpiration){
      let timeData = getTimeBetweenDates(Date.now(), +tokenExpiration );
      if((+tokenExpiration - Date.now()) > 0){
        timeText = timeData?.hoursMinutes ?? '';
        timeToEnd = (((timeData.hours * 60) + timeData.minutes) ?? 0);
      } else {
        timeText = '';
        timeToEnd = 0;
      }
    }
    console.log('минут до конца сессии',timeToEnd) //for test

    if(tokenExpiration && timeToEnd === 59) await sessionTimerMSG(AlertTypes.INFO, timeText);
    if(tokenExpiration && timeToEnd === 30) await sessionTimerMSG(AlertTypes.INFO, timeText);
    if(tokenExpiration && timeToEnd === 10) await sessionTimerMSG(AlertTypes.INFO, timeText);
    if(tokenExpiration && timeToEnd === 5)  await sessionTimerMSG(AlertTypes.INFO, timeText);
    if(tokenExpiration && timeToEnd === 2){
      checkSessionID = setTimeout(() => checkIgnorSession(),300);
      await sessionTimerMSG(AlertTypes.ERROR,timeText);
    };
    
    // if(tokenExpiration && 0 >= timeToEnd) onSignOut();
    if(tokenExpiration && 0 >= timeToEnd){
      resetSessionStorage();
      resetLocalStorageData();
      if(showModal) setShowModal(false);
      if(!showSignOutModal) onSignOutModalToggle();
      window.history.pushState(
        { isBackPage: false },
        "sign-in",
        window.location.pathname
    );
    };
  return timeToEnd;
       
  },[tokenExpiration,sessionTimerMSG,checkIgnorSession,onSignOutModalToggle,showSignOutModal,setShowModal,showModal]);

  const visibilitychangeFunc = useCallback(() => {
    if (document.hidden === true) return;
    return  checkSessionTimer()
  },[checkSessionTimer]);



  useLayoutEffect(() => {
    checkSessionTimer();
    sessionTimerID = setInterval(() => checkSessionTimer(), 19000);
    window.addEventListener('visibilitychange', visibilitychangeFunc, false);
    
    return () => {
      sessionTimerID && clearInterval(sessionTimerID);
      checkSessionID && clearTimeout(checkSessionID);
      window.removeEventListener('visibilitychange', visibilitychangeFunc);
    }
    
  },[checkIgnorSession,checkSessionTimer,visibilitychangeFunc]);



  const ignoreSessionHandler = useCallback((answer:boolean) => {
    saveLocalStorageData('continue_session_ignored',answer);
    setShowModal(false);
    setIgnorSessionWarning(answer);
  },[setShowModal,setIgnorSessionWarning]);



  const onIgnorSession = useCallback(() => {
    ignoreSessionHandler(true);
    setShowModal(false);
    let msg = 'Сессия скоро истекает, успейте сохранить свою работу'
    if(tokenExpiration){
      msg = `Сессия истекает через ${getTimeBetweenDates(Date.now(), +tokenExpiration ).hoursMinutes}`
    } 
    dispatch(addAlert(AlertTypes.INFO, '', msg ))
  },[ignoreSessionHandler,setShowModal,dispatch,tokenExpiration])

  const onRefreshToken = useCallback( () => {
    ignoreSessionHandler(false);
    tokens?.refresh && user && dispatch(continueSession(tokens?.access,tokens?.refresh, user));
    
  },[dispatch,tokens,user,ignoreSessionHandler]);

  useEffect(() => {
    if(isSessionRefresh){
      setShowModal(false);
      ignoreSessionHandler(false);
      dispatch(addAlert(AlertTypes.DONE, '', 'Сессия продлена'))
      dispatch(resetContinueSession())
    }
  },[isSessionRefresh,setShowModal,dispatch,ignoreSessionHandler]);

  return (<>
  
    <InfoModal
      title={timeExpSession()}
      showModal={showModal && !ignorSessionWarning}
      onModalToggle={onModalToggle}
      isWarning={true}
      icon={<ClockIcon />}
      buttons={
        <>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onIgnorSession}>Игнорировать</Button>
        <Button size={SIZE.SMALL} onClick={onRefreshToken}>Продлить</Button>
      </>
      }
    >
     <div style={styleModalText}> Хотите продлить сессию? <div>В ином случае данные могут быть утеряны.</div></div>
    </InfoModal>
    <InfoModal
      title={'Время вашей сессии истекло, войдите в систему'}
      showModal={showSignOutModal}
      onModalToggle={() => null}
      isWarning={true}
      icon={<ClockIcon />}
      buttons={
        <Button size={SIZE.SMALL} onClick={onSignOut}>Войти</Button>
      }
    >
    </InfoModal>
    </>
  )
};
export default RefreshToken;