import styled from "styled-components";
import ExaminationTable from "./ExaminationTable/ExaminationTable";

export const TWrapper = styled.div`
    width: 100%;
`

export const TResearchData = styled.div`
  padding: 16px 0 0 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({theme}) => theme.colors.gray[500]};
`;

export const TExaminationTable = styled(ExaminationTable)`
`;
