import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../../../icons/arrow-select.svg';

export const TWrapper = styled.div`
  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const THeader = styled.div`
  display: flex;
  align-items: start;
  margin: 16px 0 9px;
  
  & > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray[500]};
    width: 510px;
  }
  & > p:last-child {
    width: 176px;
    padding-left: 10px;
  }
`;

export const TArrowIcon = styled(ArrowIcon)``;

export const TVisibilityToggle = styled.div<{ visible: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[400]};
  cursor: pointer;
  margin: 18px 0 18px;
  display: flex;
  align-items: center;
  
  & ${TArrowIcon} {
    margin-left: 6px;
    transform: rotate(${({ visible }) => visible ? 180 : 0}deg);
  }
`;

export const TFormWrapper = styled.div`
  min-height: 470px;
`
