import { Dispatch } from 'react';
import { 
  getAnalysisByMethod,
  getAnalysisByMethodSuccess,
  getAnalysisByMethodFailure,
  getAnalysisListByMethod,
  getAnalysisListByMethodSuccess,
  getAnalysisListByMethodFailure,
  resetAnalysis,
  saveExperiment,
  saveExperimentSuccess,
  saveExperimentFailure,
  saveExamination,
  saveExaminationSuccess,
  saveExaminationFailure,
  nextConclusionStep,
  nextConclusionStepSuccess,
  nextConclusionStepFailure,
  postConclSensData,
  postConclSensDataSuccess,
  postConclSensDataFailure,
  postConclToxicityData,
  postConclToxicitySuccess,
  postConclToxicityFailure,
  conclusionUpdate,
  conclusionUpdateSuccess,
  conclusionUpdateFailure,
  createIHSExperement,
  createIHSExperementSuccess,
  createIHSExperementFailure,
  createIHSStep,
  createIHSStepSuccess,
  createIHSStepFailure,
  updateAnalisys,
  updateAnalisysSuccess,
  updateAnalisysFailure,
   } from './';
import AxiosInstance from "../../api/axiosInstance";
import { Imodule_sensitivityitivity, SaveAnalysisExperiment, SaveExperimentUpdate, ISendToxicityMarker, IResponsiblePerson, MarkerIhcExperementUpdate } from './model';

export const resetAnalysisByBluprint = () => async (dispatch: Dispatch<unknown>) => {
  dispatch(resetAnalysis());
}

export const fetchAnalysisByBluprint = (access: string, method: string, bluprintUlid:string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getAnalysisByMethod());
  let prefix = method // === 'ihc' ? 'ihc/ihc' : method;
  try {
    const response = await AxiosInstance(access).get(`${prefix}/analysis/${bluprintUlid}`);
    dispatch(getAnalysisByMethodSuccess(response.data))
  } catch (error: any) {
    dispatch(getAnalysisByMethodFailure(error?.response?.data));
  }
};

export const fetchAnalysisByBluprintList = (access: string, bluprintUrlList:string[]) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getAnalysisListByMethod());
  try {
    const response = await Promise.all(bluprintUrlList.map((bluprintUrl:string) => AxiosInstance(access).get(bluprintUrl)));
    dispatch(getAnalysisListByMethodSuccess(response.map(resp => resp.data)));
  } catch (error: any) {
    dispatch(getAnalysisListByMethodFailure(error?.response?.data));
  }
};


export const postSaveExperiment = (access: string, method:string, data: SaveAnalysisExperiment) => async (dispatch: Dispatch<unknown>) => {
  dispatch(saveExperiment());
  
  try {
    const response = await AxiosInstance(access).post(`${method}/experiment/create/`,data);
    dispatch(saveExperimentSuccess(response.data))
  } catch (error: any) {
    dispatch(saveExperimentFailure(error?.response?.data));
  }
};

export const putExperimentUpdate = (access: string, method:string, experementId:string | number, data:SaveExperimentUpdate) => async (dispatch: Dispatch<unknown>) => {
  
  try {
    await AxiosInstance(access).put(`${method}/experiment/update/${experementId}`, data);
  } catch (error: any) {
    dispatch(getAnalysisByMethodFailure(error?.response?.data));
  }
};
export const patchExperimentUpdate = (access: string, method:string, experementId:string | number, data:SaveExperimentUpdate) => async (dispatch: Dispatch<unknown>) => {
  
  try {
    await AxiosInstance(access).patch(`${method}/experiment/update/${experementId}`, data);
  } catch (error: any) {
    dispatch(getAnalysisByMethodFailure(error?.response?.data));
  }
};

export const putSaveExamination = (access: string, id:number) => async (dispatch: Dispatch<unknown>) => {
  dispatch(saveExamination());
  
  try {
    await AxiosInstance(access).put(`registry/examination/complete/${id}`);
    dispatch(saveExaminationSuccess())
  } catch (error: any) {
    dispatch(saveExaminationFailure(error?.response?.data));
  }
};

export const postmodule_sensitivityitivity = (access: string, data:Imodule_sensitivityitivity[]) => async (dispatch: Dispatch<unknown>) => {
  dispatch(postConclSensData());

  try {
    const response = await AxiosInstance(access).post('registry/conclusion/sensitivity/', data);
    dispatch(postConclSensDataSuccess(response?.data))
  } catch (error: any) {
    dispatch(postConclSensDataFailure(error?.response?.data));
  }
};

export const postmodule_toxicity = (access: string, data:ISendToxicityMarker[]) => async (dispatch: Dispatch<unknown>) => {
  dispatch(postConclToxicityData());

  try {
    const response = await AxiosInstance(access).post('registry/conclusion/toxicity/', data);
    dispatch(postConclToxicitySuccess(response?.data))
  } catch (error: any) {
    dispatch(postConclToxicityFailure(error?.response?.data));
  }
};

export const putConclusionUpdate = (access: string, conclusionID:number, data:IResponsiblePerson) => async (dispatch: Dispatch<unknown>) => {
  dispatch(conclusionUpdate());

  try {
    const response = await AxiosInstance(access).put(`registry/conclusion/update/${conclusionID}`, data);
    dispatch(conclusionUpdateSuccess(!!response?.data))
  } catch (error: any) {
    dispatch(conclusionUpdateFailure(error?.response?.data));
  }
};

export const postConclusionStep = (access: string, referralID:number, stepName:string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(nextConclusionStep());
  const data = {
    conclusion: referralID,
    type: stepName
  }
  try {
    await AxiosInstance(access).post('registry/conclusion/step/create/',data);
    dispatch(nextConclusionStepSuccess())
  } catch (error: any) {
    dispatch(nextConclusionStepFailure(error?.response?.data));
  }
};

export const postBioExperementCreate = (access: string, analysis:number, number:number, lab_assistant:string, path?:string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(createIHSExperement());
  const data = { analysis, number, lab_assistant }
  // const routePath = !path ? 'ihc/ihc' : path;
  const routePath = !path ? 'ihc' : path;
  try {
    const response = await AxiosInstance(access).post(`${routePath}/experiment/create`,data);
    dispatch(createIHSExperementSuccess(response?.data));
  } catch (error: any) {
    dispatch(createIHSExperementFailure(error?.response?.data));
  }
};

export const postBioStepCreate = (access: string, experiment:number, step:string, path?:string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(createIHSStep());
  const data = { experiment, step }
  // const routePath = !path ? 'ihc/ihc' : path;
  const routePath = !path ? 'ihc' : path;
  try {
    await AxiosInstance(access).post(`${routePath}/experiment/step/create`,data);
    dispatch(createIHSStepSuccess());
  } catch (error: any) {
    dispatch(createIHSStepFailure(error?.response?.data));
  }
};

export const patchBioExperementUpdate = (access: string, experiment:number, data:MarkerIhcExperementUpdate, path?:string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(saveExperiment());
  // const routePath = !path ? 'ihc/ihc' : path;
  const routePath = !path ? 'ihc' : path;
  try {
    const response = await AxiosInstance(access).patch(`${routePath}/experiment/update/${experiment}`,data);
    if(Object.keys(data).length === 2 ) dispatch(createIHSExperementSuccess(response?.data))
    else dispatch(saveExperimentSuccess(response?.data));
    
  } catch (error: any) {
    if(Object.keys(data).length === 2 ) dispatch(createIHSExperementFailure(error?.response?.data));
    else dispatch(saveExperimentFailure(error?.response?.data));
  }
};
export const patchAnalisisExperementUpdate = (access: string, blueprint:string, data:any, path?:string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(updateAnalisys());
  try {
    const response = await AxiosInstance(access).patch(`${path}/analysis/update/${blueprint}`,data);
    dispatch(updateAnalisysSuccess(response?.data));
    
  } catch (error: any) {
    if(Object.keys(data).length === 2 ) dispatch(createIHSExperementFailure(error?.response?.data));
    else dispatch(updateAnalisysFailure(error?.response?.data));
  }
};

export const postAnalysisAdvisorCreate = (access: string, advisorList:any) => async (dispatch: Dispatch<unknown>) => {
  try {
    await AxiosInstance(access).post(`registry/blueprint/advisory/list/create/`,advisorList);
  } catch (error: any) {
    dispatch(updateAnalisysFailure(error?.response?.data));
  }
};