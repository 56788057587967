import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Radio from '../../../shared/Radio';
import { NUMBERING } from '../constants';
import NumberingTooltip from '../NumberingTooltip';

interface IComponentProps {
  numberingType?: string;
  numberingTypeHandler: (numberingType: string) => void;
  [index: string]: any;
}

const NumberingRadioBlock: FC<IComponentProps> = ({numberingType, numberingTypeHandler}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name='numbering'
      defaultValue={numberingType ?? NUMBERING.STANDARD}
      render={({ field: { onChange, value } }) => (
        <>
          <div>
            <NumberingTooltip standard />
            <div data-for='standard-numbering-tooltip' data-tip='' >
              <Radio selected={value} value={NUMBERING.STANDARD} label='Стандартная нумерация' onChange={(e:any) => {
                numberingTypeHandler(NUMBERING.STANDARD)
                return onChange(e)}} />
            </div>
          </div>
          <div>
            <NumberingTooltip />
            <div data-for='nonstandard-numbering-tooltip' data-tip='' >
              <Radio selected={value} value={NUMBERING.NONSTANDARD} disabled={false} label='Нестандартная нумерация' onChange={(e:any) => {
                numberingTypeHandler(NUMBERING.NONSTANDARD)
                return onChange(e)}} />
            </div>
          </div>
        </>
      )}
    />
  );
}

export default NumberingRadioBlock;
