import styled from 'styled-components';
import Input from '../../../shared/Input';
import InputSelect from '../../../shared/InputSelect';

export const TWrapper = styled.div`
  position: relative;
  display:flex;
  
  
  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TGap = styled.div`
  width: 20px;
  height: 62px;
`

export const TInputsWr = styled.div`
  position: relative;
  display: flex;
  & > :first-child {
    position: absolute;
    top: 0px; 
    left: -252px;
    span {
      position: absolute;
      top:21px;
      left:-24px;
      font-size: 16px;
      font-weight: 500;
      color: ${({theme}) => theme.colors.gray[400]}
    }
  }
  
  & > * {
    margin-right: 2px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const TCustomInput = styled(Input)<{ width: string, background?:string, textAlighn?:string  }>`
  position: relative;
  width: ${({ width }) => width};
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: number-input;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & :read-only {
    cursor:default;
  }
  & input {
    padding: 18px;
    background-color: ${({ background }) => background ? background : '#fff'};
    text-align: ${({ textAlighn }) => textAlighn ? textAlighn : 'left'};
  }
  span:first-of-type {
    top: 64px;
    left: 0;
    color: ${({theme}) => theme.colors.alert.warning};
    font-size: 12px;
    line-height: 10px;
    /* width: max-content; */
    padding: 0;
    z-index: 100;
  }
`;

export const TCustomSelect = styled(InputSelect)`
  width: 112px;
  & > div {
    padding: 18px 10px 18px 18px;
  }
`;

export const TArrowWr = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 4px 6px 4px;
`;

export const TMenuButton = styled.img<{ active?: boolean,disabled?: boolean}>`
  display: inline-block;
  width: 25px;
  height: 25px;
  filter: ${({ active }) => active ? 'none' : 'grayscale(100%)'};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;

export const TMenuWr = styled.div`
  position: relative;
  display:block;
  padding:15px;
  min-width: 30px;
`;

export const TMenuContainer = styled.ul<{ active?: boolean}>`
  display: ${({ active }) => active ? 'block' : 'none'};
  position: absolute;
  top: 0px; 
  left: 62px;
  width: max-content;
  height: auto;
  box-sizing:border-box;
  list-style: none;
  background-color: #fff;
  border-radius: 3px;
  margin:0;
  padding:0;
`;

export const TMenuItem = styled.li<{ disabled?: boolean}>`
  display: flex;
  height: 34px;
  box-sizing:border-box;
  width: 100%;
  padding:0 15px;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${({ disabled }) => disabled ? '#CECECE' : '#1A1A22'};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  border-bottom: 1px solid #F7F7F8;;
  :last-child {
    border-bottom: none;
  }
`;

export const TMenuIcon = styled.img`
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 15px;
  margin-top: 1px;
`;

export const TResultWr = styled.div`
  display:flex;
  align-items: center;
  justify-content: start;
  position: absolute;
  top:0;
  left:0;
  width:174px;
  p {
    padding-right:18px;
  }
  svg {
    margin-left:10px;
  }
`;
