import { FC, ReactNode } from 'react';
import {
  TModalButtonsContainer,
  TModalContent,
  TModalTitle,
  TModalDescription,
  TAltModalTitle,
  TAltModalDescription,
} from './styled';
import Modal from '../Modal';
import { ReactComponent as BellIcon } from '../../../icons/bell_small.svg';

interface IInfoModal {
  showModal: boolean;
  onModalToggle: () => void;
  title?: string;
  description?: string;
  altTitle?: string;
  altDescription?: string;
  buttons: ReactNode;
  isWarning?: boolean;
  icon?: ReactNode;
  hasCloseButton?:boolean;
  width?: number;
  shouldCloseOnOverlayClick?: boolean;
}

const InfoModal: FC<IInfoModal> = ({ isWarning = false, icon, title, altTitle, description, altDescription, showModal, onModalToggle, buttons,children, hasCloseButton,shouldCloseOnOverlayClick, width}) => {
  return (
    <Modal width={width || 568} isOpen={showModal} handleClose={onModalToggle} shouldCloseOnOverlayClick={shouldCloseOnOverlayClick} hasCloseButton={hasCloseButton}>
      <TModalContent className='infoModalContent'>
        {(isWarning || icon) && (icon || <BellIcon />)}
        {!!title && <TModalTitle iconExist={isWarning || !!icon}>{title}</TModalTitle>}
        {!!altTitle && <TAltModalTitle>{altTitle}</TAltModalTitle>}
        {!!description && <TModalDescription>{description}</TModalDescription>}
        {!!altDescription && <TAltModalDescription>{altDescription}</TAltModalDescription>}
        {children}
        <TModalButtonsContainer>
          {buttons}
        </TModalButtonsContainer>
      </TModalContent>
    </Modal>
  );
}

export default InfoModal;
