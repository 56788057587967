import { Dispatch } from 'react';
import axiosInstance from '../../api/axiosInstance';

import {
  getModuleDefaultDepartment,
  getModuleDefaultDepartmentSuccess,
  getModuleDefaultDepartmentFailure,
  getModuleDefaultTitle,
  getModuleDefaultTitleSuccess,
  getModuleDefaultTitleFailure,
  getConclusionData,
  getConclusionDataSuccess,
  getConclusionDataFailure,
  resetConclusionData,
} from '.';

// import {
//   IModuleDefaultDepartment,
//   IModuleDefaultTitle,
// } from './model';

export const fetchConclusionModuleDefault = (access: string) => async (dispatch: Dispatch<unknown>) => {
  //get Deportament options
  try{
    dispatch(getModuleDefaultDepartment());
    const response = await axiosInstance(access).get(`registry/conclusion/department`);
    dispatch(getModuleDefaultDepartmentSuccess(response.data));
  } catch (error:any) {
    dispatch(getModuleDefaultDepartmentFailure(error?.response?.data));
  }
  //get Title options
  try{
    dispatch(getModuleDefaultTitle());
    const response = await axiosInstance(access).get(`registry/conclusion/title`);
    dispatch(getModuleDefaultTitleSuccess(response.data));
  } catch (error:any) {
    dispatch(getModuleDefaultTitleFailure(error?.response?.data));
  }
};

export const fetchSavedConclusionModules = (access: string, ref: string) => async (dispatch: Dispatch<unknown>) => {
  resetConclusionData();
  getConclusionData();
  let response;
  try{
    response = await axiosInstance(access).get(`registry/conclusion/scratch/${ref}`);
  }catch (error:any) {
    dispatch(getConclusionDataFailure(error?.response?.data));

    try{
      response = await axiosInstance(access).get(`registry/conclusion/get/${ref}`);
    }catch (error:any) {
      dispatch(getConclusionDataFailure(error?.response?.data));
    }
  }
  dispatch(getConclusionDataSuccess(response?.data ?? null));
}

export const postOverWriteConclusionModules = (access: string, referralUlid: string, modules:any) => async (dispatch: Dispatch<unknown>) => {
  resetConclusionData();
  let data = {referralUlid,...modules}
  getConclusionData();

  try{
    const response = await axiosInstance(access).post(`registry/conclusion/scratch/${referralUlid}`,data);
    dispatch(getConclusionDataSuccess(response?.data ?? null));
  }catch (error:any) {
    dispatch(getConclusionDataFailure(error?.response?.data));
  }
}

export const getAndOverWriteEperement = (access: string, referralUlid: string) => async (dispatch: Dispatch<unknown>) => {
  let conclusionData;
  let experementsData;

  try{
    conclusionData = (await axiosInstance(access).get(`registry/conclusion/scratch/${referralUlid}`))?.data;
  }catch (error:any) {
    dispatch(getConclusionDataFailure(error?.response?.data));
  }
  try{
    experementsData = (await axiosInstance(access).get(`registry/conclusion/get/${referralUlid}`))?.data;
  }catch (error:any) {
    dispatch(getConclusionDataFailure(error?.response?.data));
  }

  if(conclusionData?.moduleLabDetail){
    let moduleLabDetail = experementsData?.moduleLabDetail;
    if(!!moduleLabDetail){
      let updatedData = {...conclusionData,moduleLabDetail}
      try{
        const response = await axiosInstance(access).post(`registry/conclusion/scratch/${referralUlid}`,updatedData);
        resetConclusionData();
        dispatch(getConclusionDataSuccess(response?.data ?? null));
      }catch (error:any) {
        dispatch(getConclusionDataFailure(error?.response?.data));
      }
    }
  }
}