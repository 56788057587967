import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { format } from 'date-fns';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { TDetailsContent } from "../../../../components/shared/Details/styled";
import InputSelect from "../../../../components/shared/InputSelect";
import Switcher from "../../../../components/shared/Switcher";
import { IAnalisysByMethod, MarkerFishExperiment, MarkerIhcAlkExperiment, MarkerIhcExperiment } from "../../../../store/analysis/model";
import { fetchAnalysisByBluprint, patchBioExperementUpdate, postBioExperementCreate, postBioStepCreate, resetAnalysisByBluprint } from "../../../../store/analysis/thunkActions";
import { getTokens } from "../../../../store/auth/selectors";
import { TEditButton, TRowWr } from "../../MarkersValidation/styled";
import { CustomButton, TBioDateConfirm, TButtonWrapper, TLaborantWr, TSectionTitle, TWrapper } from "../styled";
import { getNewBioStepUpdateData } from "../../../../store/analysis/selectors";

interface ISelectOptions {
  value: string | number;
  label: string;
}
interface IComponentProps {
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  labAssistantList: ISelectOptions[];
  flowStepsActiveIndex: number;
  isActive:boolean;
  hasPermit:boolean;
  [index: string]: any;
}

const stepDate = (date:Date) => format(new Date(date), 'dd.MM.yyyy - HH:mm:ss');

const BioPanel: FC<IComponentProps> = ({analisysByMethod, labAssistantList, flowStepsActiveIndex,isActive,hasPermit}) => {
  const { control, handleSubmit, reset, formState: {errors}, clearErrors, setValue, getValues, watch } = useForm();

  const watchLabAssistant = watch('labAssistant', undefined);

  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const BioStepUpdateData = useSelector(getNewBioStepUpdateData);

  const [isStepEdit, setStepEdit] = useState<boolean>(false);
  // const [isBioTransferedChecked, setBioTransferedChecked] = useState<boolean>(false);
  const [isBioAcquiredChecked, setBioAcquiredChecked] = useState<boolean>(false);
  const [markerAttemptData, setMarkerAttemptData] = useState<MarkerIhcExperiment | MarkerIhcAlkExperiment | MarkerFishExperiment>();
  const [bioAcquiredDate, setBioAcquiredDate] = useState<string>();


  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());

  const assistentDefaultValue = useMemo(() => {
    let assistent = '';
    //IHC
    if(analisysByMethod?.analysisIhcExperiments && flowStepsActiveIndex){
      let MarkerIhcExperiment: MarkerIhcExperiment = analisysByMethod?.analysisIhcExperiments
        ?.find((ihsData:MarkerIhcAlkExperiment) => +ihsData.number === +flowStepsActiveIndex);
        if(MarkerIhcExperiment?.labAssistant) assistent = MarkerIhcExperiment?.labAssistant;
    }
    if(analisysByMethod?.analysisIhcAlkExperiments && flowStepsActiveIndex){
      let MarkerIhcAlkExperiment: MarkerIhcAlkExperiment = analisysByMethod?.analysisIhcAlkExperiments
        ?.find((ihsData:MarkerIhcAlkExperiment) => +ihsData.number === +flowStepsActiveIndex);
        if(MarkerIhcAlkExperiment?.labAssistant) assistent = MarkerIhcAlkExperiment?.labAssistant;
    }
    //FISH
    if(analisysByMethod?.analysisFishExperiments && flowStepsActiveIndex){
      let MarkerFishExperiment: MarkerFishExperiment = analisysByMethod?.analysisIhcAlkExperiments
      ?.find((fishData:MarkerFishExperiment) => +fishData.number === +flowStepsActiveIndex);
      if(MarkerFishExperiment?.labAssistant) assistent = MarkerFishExperiment?.labAssistant;
    }

    return assistent;
  },[analisysByMethod,flowStepsActiveIndex]);

 

  useLayoutEffect(() => {
    
    let dataAttempt;

    if(+flowStepsActiveIndex){
      let FISH_DATA     = analisysByMethod?.analysisFishExperiments?.filter((fishData:MarkerFishExperiment)    => +fishData.number === +flowStepsActiveIndex)?.[0];
      let IHC_DATA      = analisysByMethod?.analysisIhcExperiments?.filter((ihcData:MarkerIhcExperiment)       => +ihcData.number  === +flowStepsActiveIndex)?.[0];
      let IHC_ALK_DATA  = analisysByMethod?.analysisIhcAlkExperiments?.filter((ihcData:MarkerIhcAlkExperiment) => +ihcData.number  === +flowStepsActiveIndex)?.[0];

      dataAttempt = FISH_DATA || IHC_DATA || IHC_ALK_DATA;
    }

    if(dataAttempt){
      let bioAcquired;
      let stepsData = dataAttempt?.experimentFishSteps || dataAttempt?.experimentIhcSteps || dataAttempt?.experimentIhcAlkSteps;
      if(stepsData){
        for(let value of stepsData){
          if(value.step === 'bio_acquired') bioAcquired = stepDate(value.dateCreated);
        }
      }
      setMarkerAttemptData(dataAttempt);
      setBioAcquiredChecked(true);
      setValue('labAssistant',dataAttempt?.labAssistant);
      if(bioAcquired)   setBioAcquiredDate(bioAcquired);
    }
    
  },[analisysByMethod,flowStepsActiveIndex,setMarkerAttemptData,
    setValue,setBioAcquiredDate]);

  const isSaveChanges = useMemo(() => {
    let isSave = false;

    if(!isStepEdit) return isSave;
    if(!markerAttemptData && isBioAcquiredChecked) isSave = true;
    if(!!watchLabAssistant) isSave = true;
    return isSave;

  },[isBioAcquiredChecked,isStepEdit,markerAttemptData,watchLabAssistant]);


  const handleStepEdit = useCallback(async () => {

    if(!isActive || (markerAttemptData && markerAttemptData?.bioAcquired)) return false;
    if (isStepEdit) {
      reset({labAssistant:assistentDefaultValue});
      setValue('labAssistant', assistentDefaultValue)
      setResetFormKey(Date.now())
      if(!!markerAttemptData?.bioAcquired) setBioAcquiredChecked(markerAttemptData?.bioAcquired);
      else setBioAcquiredChecked(false);
      if(errors?.['labAssistant']) clearErrors('labAssistant');
      return setStepEdit(false);
    }
    setStepEdit(true)
  }, [isStepEdit, setStepEdit, reset, setResetFormKey,markerAttemptData,
    errors,clearErrors, setBioAcquiredChecked,setValue,assistentDefaultValue,isActive]);

  const handleBioAcquired = useCallback(() => {
    if(isBioAcquiredChecked){
      setBioAcquiredChecked(false);
      
      if(errors?.['labAssistant']) clearErrors('labAssistant');
      return setResetFormKey(Date.now());
    }
    return setBioAcquiredChecked(true);

  },[setBioAcquiredChecked,isBioAcquiredChecked,setResetFormKey,clearErrors,errors]);


  const onSubmitStep = useCallback(async (data:any) => {
    const markerIHCName: string = analisysByMethod?.markerIhc?.name.toLowerCase();
    const markerFISHName: string = analisysByMethod?.markerFish?.name.toLowerCase();
    const analysis: number = analisysByMethod.id;
    const number: number = +flowStepsActiveIndex;
    const lab_assistant: string = data.labAssistant;
    let routePath: string = '';
    if(markerFISHName) routePath = 'fish';
    if(markerIHCName)  routePath = 'ihc'//markerIHCName.includes('alk') ? 'ihc/alk' : 'ihc/ihc';

    if(tokens?.access && !markerAttemptData && isBioAcquiredChecked && !!lab_assistant && routePath){
      await dispatch(postBioExperementCreate(tokens?.access, analysis, number, lab_assistant, routePath));
    }

  },[tokens?.access,dispatch,markerAttemptData,isBioAcquiredChecked,analisysByMethod,flowStepsActiveIndex]);


  useEffect(() => {
    const markerIHCName: string = analisysByMethod?.markerIhc?.name.toLowerCase();
    const markerFISHName: string = analisysByMethod?.markerFish?.name.toLowerCase();
    const analysisID: number | undefined = BioStepUpdateData?.id;
    const blueprintULID: string = analisysByMethod.blueprint;
    const dataAttemptID = markerAttemptData?.id;
    const lab_assistant: string = getValues('labAssistant')
    let routePath: string = '';
    if(markerFISHName) routePath = 'fish';
    if(markerIHCName)  routePath = 'ihc'//markerIHCName.includes('alk') ? 'ihc/alk' : 'ihc/ihc';

    if(!analysisID) return;

    //Bio Acquired
    if(!!analysisID && tokens?.access && !dataAttemptID && isBioAcquiredChecked ){
      dispatch(postBioStepCreate(tokens?.access, analysisID,'bio_acquired',routePath));
      dispatch(patchBioExperementUpdate(tokens?.access, analysisID,{bio_acquired: true,lab_assistant}, routePath));
    }

    //update page
    handleStepEdit();
    setTimeout(() => {
      tokens?.access && dispatch(fetchAnalysisByBluprint(tokens?.access, routePath, blueprintULID));
    },500)
    dispatch(resetAnalysisByBluprint());

  },[dispatch,tokens?.access, BioStepUpdateData ,analisysByMethod, handleStepEdit,markerAttemptData,isBioAcquiredChecked,getValues]);



  return (
    <TDetailsContent key={resertFormKey}>
      <TRowWr direction={hasPermit ? "space-between" : "flex-start"}>
        <TSectionTitle width="60%" color={bioAcquiredDate ? "#777" : "#7A78E9"}>Окрашивание биоматериала</TSectionTitle>
        {hasPermit && <TEditButton disabled={!!markerAttemptData?.bioAcquired || !isActive} onClick={handleStepEdit}>
          {!isStepEdit ? 'Редактировать' : 'Отменить'}
        </TEditButton>}
      </TRowWr>
      <TWrapper onSubmit={handleSubmit(onSubmitStep)} >
        <TRowWr direction="space-between">
          <Switcher
            title="Биоматериал получен"
            isChecked={!!markerAttemptData?.bioAcquired || isBioAcquiredChecked}
            isDisabled={!isStepEdit && !!markerAttemptData?.bioAcquired}
            onChange={() => isStepEdit && !markerAttemptData?.bioAcquired ? handleBioAcquired() : null}
            isError={!!getValues('labAssistant') && (!markerAttemptData?.bioAcquired || !isBioAcquiredChecked)}
          />
          <TBioDateConfirm>{bioAcquiredDate}</TBioDateConfirm>
        </TRowWr>
        <TLaborantWr error={!!errors?.['labAssistant']}>
        <Controller
          control={control}
          name={'labAssistant'}
          rules={{ required: !markerAttemptData?.bioTransfered }}
          render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
            <InputSelect
              inputRef={ref}
              onChange={(value) => {
                clearErrors('labAssistant')
                return onChange(value)}}
              value={value || assistentDefaultValue}
              options={labAssistantList ?? []}
              placeholder='--'
              label='ФИО и должность исполнителя'
              error={errors?.['labAssistant'] || invalid}
              bordered={true}
              disabled={!!markerAttemptData?.bioAcquired || !isStepEdit}
            />
          )}
        />
        </TLaborantWr>
        <TButtonWrapper>
          {hasPermit && <CustomButton type="submit" disabled={!isSaveChanges}>
            Сохранить изменения
          </CustomButton>}
        </TButtonWrapper>
      </TWrapper>
    </TDetailsContent>
  )
}

export default BioPanel;
