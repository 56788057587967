import {
  ErrorMessage,
  FilteredListItem,
  FilteredListWrapper,
  IconDownWrapper,
  IconUpWrapper,
  IconWrapper,
  InputWrapper, ListItem, MKB10Title,
  MKB10Wrapper,
  TSearchTextArea
} from "./styled";
import { ReactComponent as ArrowIcon } from '../../../../../icons/arrow-select.svg';
// import Input from "../../../../../components/shared/Input";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import Tooltip from "../../../../../components/shared/Tooltip";
import Mkb10TreeComponent from "./Mkb10TreeComponent";
import useSelectMKB10Option from "./hooks/useSelectMKB10Option";
import { TContent, TTitle } from "../../../../../components/shared/Tooltip/styled";


const MKB_10 = (props: any) => {
  const { items, mkb10Value, setMkb10Value, filteredMKB10, showNoMatchError, isOpen, setIsOpen } = props;

  const { register, formState, clearErrors, getValues, setValue } = useFormContext();

  const [updateTextArea, setUpdateTextArea] = useState<number>(Date.now());
  //в этом блоке все что связано с открытие и закрытие блоков выбора мкб и сета значения в инпут
  const [isActiveIcon, setIsActiveIcon] = useState(false);
  
  const { selectMKB10Option } = useSelectMKB10Option(setIsOpen, setIsActiveIcon, clearErrors, setMkb10Value);
  

  const showListHandler = useCallback(() => {
    setIsActiveIcon(!isActiveIcon)
  }, [setIsActiveIcon, isActiveIcon]);

  const hasFormError = formState.errors.icd_10;

  //функция чейндж
  const onChange = useCallback(({ value }: any) => {
    let isNewLine = value.indexOf('\n')
    let newValue = value.replace('\n', '')

    if (isNewLine < 0) {
      setMkb10Value(newValue)
    } else {
      setValue('icd_10', mkb10Value)
      setUpdateTextArea(Date.now())
    }

  }, [setMkb10Value, setUpdateTextArea, setValue, mkb10Value]);

  useEffect(() => {
    if (mkb10Value) {
      setIsActiveIcon(false)
    }
  }, [mkb10Value])

  useEffect(() => {
    if (!mkb10Value) {
      setIsOpen(true)
    }
  }, [mkb10Value, setIsOpen])

  useEffect(() => {
    if (!filteredMKB10.includes(mkb10Value)) {
      setIsOpen(true)
    }
  }, [mkb10Value, filteredMKB10, setIsOpen]);

  const selectMKB10OptionHandler = useCallback((value: any) => {
    setMkb10Value(value)
    return selectMKB10Option(value);
  }, [selectMKB10Option, setMkb10Value]);

  const rowSize = useMemo(() => {
    let strLength = mkb10Value?.length ?? 0;
    if (!strLength || strLength < 52) return 1;
    let result = parseInt(((strLength / 52) + 1).toString());
    return result
  }, [mkb10Value]);

  return (
    <>
      <Tooltip key={`tooltip-${mkb10Value?.length}`} id={mkb10Value?.length ? 'mkb_10' : "mkb_10-tooltip"} place='top' overridePosition={({ left, top }) => ({ left: left, top: top })}>
        <TTitle>Для поиска диагноза воспользуйтесь любым из следующих способов поиска:</TTitle>
        <TContent>
          <ul>
            <li>выбор из выпадающего списка</li>
            <li>поиск по слову или по части слова — используйте <b>кириллицу</b></li>
            <li>поиск по коду — используйте <b>латиницу</b>.</li>
          </ul>
          В случае ошибки удалите выбранную опцию и повторите поиск.
        </TContent>
      </Tooltip>
      <MKB10Title>Диагноз по МКБ 10 *</MKB10Title>
      <MKB10Wrapper data-for={"mkb_10-tooltip"} data-tip="" hasError={hasFormError}>
        <InputWrapper>
          <TSearchTextArea
            {...register('icd_10', {
              required: true,
              validate: {
                chekValue: v => filteredMKB10.includes(v)
              }
            })}
            key={updateTextArea}
            onChange={({ target }) => onChange(target)}
            disabled={isActiveIcon}
            defaultValue={getValues('icd_10') || mkb10Value}
            wrap="soft"
            rows={rowSize}
          ></TSearchTextArea>
        </InputWrapper>
        <IconWrapper onClick={showListHandler}>
          {isActiveIcon ? <IconDownWrapper>
            <ArrowIcon />
          </IconDownWrapper>
            : <IconUpWrapper>
              <ArrowIcon />
            </IconUpWrapper>}
        </IconWrapper>
      </MKB10Wrapper>
      {showNoMatchError && <ErrorMessage>{'Проверьте введенные значения!'}</ErrorMessage>}

      {(isActiveIcon) && <Mkb10TreeComponent items={items} selectMKB10Option={selectMKB10OptionHandler} />}
      {(mkb10Value && filteredMKB10.length > 0 && isOpen) &&
        <FilteredListWrapper key={mkb10Value.length}>
          {filteredMKB10?.map((item: any, index: number) => {
            const key = `${item}_${index}`;
            const regex = new RegExp(`${mkb10Value}*`, 'gi');
            let regexValueArr: RegExpMatchArray | null = item.match(regex);
            let content;
            regexValueArr?.forEach((val: string) => content = item.replace(val, `<i>${val}</i>`))

            return <FilteredListItem key={key} onClick={() => selectMKB10OptionHandler(item)}>
              <ListItem dangerouslySetInnerHTML={{ __html: content ?? '' }}></ListItem>
            </FilteredListItem>
          })}
        </FilteredListWrapper>
      }
    </>
  )
};

export default MKB_10;

// const itemParts = item.split(value);
//
// return <FilteredListItem key={key} onClick={() => selectMKB10Option(item)}><ListItem>
//   <span>{itemParts[0]}</span>
//   <IlluminatedText>{value}</IlluminatedText>
//   <span>{itemParts[1]}</span>