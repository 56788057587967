import { FC } from 'react';
import { TIconButton } from './styled';

interface IIconSquareButton {
  disabled?: boolean;
  invert?: boolean;
  className?: string;
  onClick?: (e: any) => void;
  type?: "reset" | "button" | "submit";
}

const IconSquareButton: FC<IIconSquareButton> = ({ children, invert, ...restProps }) => {
  return (
    <TIconButton invert={invert} {...restProps} >
      {children}
    </TIconButton>
  );
}

export default IconSquareButton;
