import { FC, useCallback, useEffect, useMemo, useState, useRef, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { Controller, useFormContext } from 'react-hook-form';
import { getBloodId } from '../../../../store/referral/selectors';
import { getFromSessionStorage } from '../../../../utils/sessionStorageHelpers';
import SESSION_STORAGE_KEYS from '../../../../constants/sessionStorageKeys';
import Loader from '../../../shared/Loader';
import { disableFutureDays } from '../../../../utils/dateHelpers';
import copyIcon from '../../../../icons/copy-done.svg';
import dottedIcon  from '../../../../icons/dotted.svg';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { 
  TWrapper, 
  TButton, 
  TInput, 
  TInputWr, 
  TLoaderContainer, 
  TInputDayPicker, 
  TMenuContainer, 
  TMenuIcon, 
  TMenuItem, 
  TMenuWr, 
  TMenuButton} from './styled';

const { BIO_MATERIAL } = SESSION_STORAGE_KEYS;

interface IBloodRegistration {
  onFetchBloodId: () => void;
  onResetBloodId?: () => void;
}

const BloodRegistration: FC<IBloodRegistration> = ({ onFetchBloodId, onResetBloodId }) => {
  const { control, setValue, clearErrors, getValues } = useFormContext();

  const [loading, setLoading] = useState(false);
  const [sessionBloodId, setSessionBloodId] = useState('');
  const [isOpenMenu, setOpenMenu] = useState(false);

  const bloodId = useSelector(getBloodId);
  // console.log('bloodId',bloodId)

  const node = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;

  useOutsideClick(node, () => {
    if (isOpenMenu) {
      setOpenMenu(false);
    }
  });

  const onClick = useCallback((e) => {
    e.preventDefault();
    onFetchBloodId();
    setLoading(true);
  }, [onFetchBloodId]);


  const copyToClipboard = useCallback((e) => {
    e.preventDefault();
    try {
      return navigator.clipboard.writeText(`${getValues('bloodId')}`);
    } catch (err) {
      console.log("Copy Failed")
    }
    setOpenMenu(false)
  },[setOpenMenu,getValues])


  const toggleMenu = useCallback((e) => {
    e.preventDefault();
    setOpenMenu(!isOpenMenu)
  }, [isOpenMenu,setOpenMenu]);

  useEffect(() => {
    if (bloodId) {
      setLoading(false);
      setValue('bloodId', bloodId);
      setTimeout(() => clearErrors(), 1);
    }
  }, [bloodId, setValue, clearErrors]);

  useEffect(() => {
    const [bloodDateOfAcquisition, bloodId] = getFromSessionStorage(
      BIO_MATERIAL.bloodDateOfAcquisition, BIO_MATERIAL.bloodId
    );
    if (bloodDateOfAcquisition && new Date(bloodDateOfAcquisition).toString() !==  "Invalid Date") {
      setValue('bloodDateOfAcquisition', new Date(Date.parse(bloodDateOfAcquisition)));
      setTimeout(() => clearErrors(), 1);
    }
    // if (bloodDateOfAcquisition) {
    //   setValue('bloodDateOfAcquisition', new Date(bloodDateOfAcquisition));
    //   setTimeout(() => clearErrors(), 1);
    // }else {
    //   setValue('bloodDateOfAcquisition', new Date());
    // }
    if (bloodId) {
      setValue('bloodId', bloodId);
      setTimeout(() => clearErrors(), 1);
      setSessionBloodId(bloodId);
    }
  }, [setValue, clearErrors]);

  const showBloodIdField = useMemo(() => bloodId || sessionBloodId, [bloodId, sessionBloodId]);

  return (
    <TWrapper>
      <Controller
        control={control}
        name='bloodDateOfAcquisition'
        render={({ field: { onChange, value } }) => {
          return (
          <TInputDayPicker
            date={value}
            onChange={onChange}
            popperPlacement='right-start'
            placeholderText='дд.мм.гг'
            filterDate={disableFutureDays}
            label='Дата забора крови *'
          />
        )}}
      />
      {!loading && !showBloodIdField && (
        <TButton onClick={onClick}>Присвоить номер</TButton>
      )}
      {loading && (
        <TLoaderContainer>
          <Loader enabled />
        </TLoaderContainer>
      )}
      {!loading && showBloodIdField && (
        <TInputWr>
          <Controller
            control={control}
            name="bloodId"
            render={({ field: { value, ref } }) => (
              <InputMask
                mask="999 999 999"
                value={value || ''}
                readOnly
              >
                {(inputProps: any) => (
                  <TInput
                    {...inputProps}
                    ref={ref}
                    readOnly
                  />
                )}
              </InputMask>
            )}
          />
          <TMenuWr onClick={toggleMenu} ref={node}>
          <TMenuButton
            onClick={toggleMenu}
            src={dottedIcon}
            active={isOpenMenu}
          /> 
              
            </TMenuWr>
            <TMenuContainer active={isOpenMenu}>
              <TMenuItem onMouseDown={copyToClipboard}>Скопировать результат<TMenuIcon src={copyIcon}/></TMenuItem>
            </TMenuContainer>       
        </TInputWr>
      )}
    </TWrapper>
  );
}

export default BloodRegistration;
