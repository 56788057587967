import { forwardRef, useMemo } from "react";
import {
  TModuleDefaultHeader, TModuleHeader, TModuleLabDetailConclusionTable, TModuleLabDetailTable, TModuleMorphology, TModulePatient, TModuleSensToxTable,
  TModuleSensitivityWr, TModuleSignatory, TModuleSignatoryTable, TPageContainer, TSectionSensToxityHeader
} from "./styled";
import { format } from "date-fns";
import { AnalysisExperiment, Experiment, ExperimentSs, ExperimentSsConclusion, MarkerReference } from "../../../store/analysis/model";

interface IConclusionToPrint {
  modules: any;
  [index: string]: any;
}

const ConclusionToPrint = forwardRef<HTMLDivElement, IConclusionToPrint>((props, ref) => {
  let modules = props.modules;

  const [module_default_header, module_default_footer] = useMemo(() => {
    if (!modules?.moduleDefault) return [<></>, <></>]
    const { formDepartment, formTitle, phoneNumber } = modules?.moduleDefault?.restoreDefaultData ?? {};

    const department = formDepartment === 'Не указывать отделение' ? '' : formDepartment;
    let haderComponent = (
      <TModuleDefaultHeader>
        <caption className="capitalize">{formTitle}</caption>
        <tbody>
          <tr>
            <th>
              <ul>
                <li>РНПЦ ОМР им. Н.Н. Александрова</li>
                <li>Республиканская молекулярно-генетическая лаборатория канцерогенеза</li>
                <li className="capitalize">{department}</li>
              </ul>
            </th>
            <th>
              <ul>
                <li>Приложение 11 к приказу</li>
                <li>Министерства здравоохранения</li>
                <li>Республики Беларусь</li>
                <li>28.09.2007 №787</li>
                <li>Форма № 210/у-07</li>
              </ul>
            </th>
          </tr>
        </tbody>
      </TModuleDefaultHeader>
    )

    const footerComponent = (
      <TModuleHeader>Телефон: {phoneNumber ?? ''}</TModuleHeader>
    )

    return [haderComponent, footerComponent];

  }, [modules?.moduleDefault])

  const modulePatient = useMemo(() => {
    if (!modules?.modulePatient) return <></>;
    const { bioAcceptDate, clinicalDiagnosis, materialBlood, materialType, paid,tnm,tnmStage,tnmNonApplicable,tnmUnknown,stageNonApplicable,stageUnknown,
      patientAmbulatoryCards, patientBirthDay, patientFullName, patientGender, referralCreator, morphologyData } = modules?.modulePatient;

    let patientGenderInfo = patientGender ?? '--';
    if (patientGenderInfo.toLowerCase() === 'male') patientGenderInfo = 'мужской';
    if (patientGenderInfo.toLowerCase() === 'female') patientGenderInfo = 'женский';

    let paidInfo = paid ?? '--';
    if (paidInfo.toLowerCase() === 'paid') paidInfo = 'платно';
    if (paidInfo.toLowerCase() === 'free') paidInfo = 'бесплатно';

    let TNM_Applicable = tnmNonApplicable ? 'неприменимо' : '';
    let TNM_Unknown = tnmUnknown ? 'не установлена' : '';

    let Stage_Applicable = stageNonApplicable ? 'неприменимо' : '';
    let Stage_Unknown = stageUnknown ? 'не установлена' : '';


    let TNM = TNM_Applicable || TNM_Unknown ? (TNM_Applicable || TNM_Unknown) : tnm;
    let TNMStage = Stage_Applicable || Stage_Unknown ? (Stage_Applicable || Stage_Unknown) : tnmStage?.replace('--','');

    let TNMResult = '';
    if(TNM)      TNMResult = `, стадия: ${TNM}`;
    if(TNMStage) TNMResult += `, стадия${TNM ? '' : ': стадия'} ${TNMStage}`;

    return (
      <TModulePatient>
        
        <TModuleHeader>
          Направление № {modules?.referralId ?? '--'} <br />
          Дата регистрации (дд.мм.гггг): {bioAcceptDate}</TModuleHeader>
        <ol>
          <li>Ф.И.О. пациента: {patientFullName}</li>
          <li>Число, месяц, год рождения: {patientBirthDay}</li>
          <li>Пол: {patientGenderInfo}</li>
          <li>Направившее учреждение, отделение: {referralCreator}</li>
          <li>№ амбулаторной карты направившего учреждения: {patientAmbulatoryCards}</li>
          <li>Клинический диагноз: {clinicalDiagnosis}</li>
          {!!TNMResult && <li><span>TNM</span>{TNMResult}</li>}
          <li>Вид биологического материала: парафиновый блок{materialBlood ? ', кровь' : ''}</li>
          <li>Материал для проведения исследования: {(materialType ?? '--')?.toLowerCase()}
            <ul>
              {morphologyData?.map((data: { codeNumber: string, cancerousCellPercentage: number }) => (
                <li key={data?.codeNumber}>парафиновый блок № {data?.codeNumber ?? '--'}, опухолевых клеток {data?.cancerousCellPercentage}%;</li>)
              )}
              {materialBlood && <li>кровь № {materialBlood};</li>}
            </ul>
          </li>
          <li>Оказание услуг: {paidInfo}</li>
        </ol>
      </TModulePatient>
    )
  }, [modules?.modulePatient,modules?.referralId]);

  const moduleMorphology = useMemo(() => {
    if (Object.keys(modules?.moduleLabDetail ?? {})?.length || !modules?.moduleMorphology) return <></>;

    const { conclusion } = modules?.moduleMorphology?.restoreDefaultData;
    return (
      <TModuleMorphology>
        <tbody>
          <tr>
            <td className="title">Клинико-лабораторное заключение:</td>
            <td colSpan={2} className="conclusion">{conclusion}</td>
          </tr>
        </tbody>
      </TModuleMorphology>
    )
  }, [modules?.moduleMorphology,modules?.moduleLabDetail]);

  const moduleSignatory = useMemo(() => {
    if (!modules?.moduleSignatory) return <></>;
    const { dateConfigured, incpectorsList } = modules?.moduleSignatory?.restoreDefaultData ?? {};

    return (
      <TModuleSignatory>
        {incpectorsList?.length && (
          <TModuleSignatoryTable>
            <tbody>
              {incpectorsList?.map((data: { firstColumn: any[], secondColumn: any[] }) => {
                return (
                  <tr key={data?.firstColumn?.[0]?.ulid}>
                    <td className="title">{data?.firstColumn?.[0]?.value}</td>
                    <td><hr /></td>
                    <td className="name">{(data?.secondColumn?.[0]?.value ?? '').split('. ').reverse().join(' ')}</td>
                  </tr>)
              })}
            </tbody>
          </TModuleSignatoryTable>)}
        <TModuleHeader>Дата выдачи результатов исследования (дд.мм.гггг): {dateConfigured ? format(new Date(dateConfigured), 'dd.MM.yyyy') : '--'}</TModuleHeader>
      </TModuleSignatory>
    )
  }, [modules?.moduleSignatory]);

  const moduleSectionHeader = useMemo(() => {
    const { positiveAgents = [], negativeAgents = [], comments = '' } = modules?.moduleSensitivity?.restoreDefaultData ?? {};
    const positiveAgentsData = positiveAgents.filter((data: any) => {
      let firstColumn = data?.firstColumn?.every((fc: { value: string; label: string }) => fc.label !== 'empty');
      let secondColumn = data?.secondColumn?.every((sc: { value: string; label: string }) => sc.label !== 'empty');
      return firstColumn || secondColumn;
    })
    const negativeAgentsData = negativeAgents.filter((data: any) => {
      let firstColumn = data?.firstColumn?.every((fc: { value: string; label: string }) => fc?.label !== 'empty');
      let secondColumn = data?.secondColumn?.every((sc: { value: string; label: string }) => sc?.label !== 'empty');
      return firstColumn || secondColumn;
    })

    const hasSensAgents = !!(Object.keys(positiveAgentsData)?.length || Object.keys(negativeAgentsData)?.length);
    const hasSensComments = !!comments?.length;
    const hasToxicityMarkers = !!Object.keys(modules?.moduleToxicity?.restoreDefaultData ?? {})?.length;
    const hasToxicityComments = !!modules?.moduleToxicity?.comments?.length;

    if(hasSensAgents || hasSensComments || hasToxicityMarkers || hasToxicityComments) return <TSectionSensToxityHeader>Интерпретация результатов</TSectionSensToxityHeader>
    return <></>
  },[modules]);


  const moduleSensitivity = useMemo(() => {
    if (!modules?.moduleSensitivity) return <></>;
    if (modules?.moduleSensitivity?.noSpecify) return <></>;
    const { positiveAgents = [], negativeAgents = [], comments = '' } = modules?.moduleSensitivity?.restoreDefaultData ?? {};

    const positiveAgentsData = positiveAgents.filter((data: any) => {
      let firstColumn = data?.firstColumn?.every((fc: { value: string; label: string }) => fc.label !== 'empty');
      let secondColumn = data?.secondColumn?.every((sc: { value: string; label: string }) => sc.label !== 'empty');
      return firstColumn || secondColumn;
    })
    const negativeAgentsData = negativeAgents.filter((data: any) => {
      let firstColumn = data?.firstColumn?.every((fc: { value: string; label: string }) => fc?.label !== 'empty');
      let secondColumn = data?.secondColumn?.every((sc: { value: string; label: string }) => sc?.label !== 'empty');
      return firstColumn || secondColumn;
    })



    return (
      <TModuleSensitivityWr>
        
        {!!positiveAgentsData?.length && (
          <TModuleSensToxTable>
            <caption className="capitalize">
              Обнаружена потенциальная чувствительность к следующим лекарственным средствам:
            </caption>
            <thead>
              <tr>
                <th>Протестированный маркер</th>
                <th>Агент</th>
              </tr>
            </thead>
            <tbody>
              {positiveAgentsData?.map((agent: { firstColumn: any[]; secondColumn: any[] }) => {
                return (
                  <tr key={agent?.firstColumn?.[0]?.value}>
                    <td>{agent?.firstColumn?.[0]?.value}</td>
                    <td>{agent?.secondColumn?.map((option: { value: string, label: string }) => option.value)?.join(', ')}</td>
                  </tr>)
              })}
            </tbody>
          </TModuleSensToxTable>
        )}
        {!!negativeAgentsData?.length && (
          <>
          <TModuleSensToxTable>
            <caption className="capitalize">
              Не обнаружена потенциальная чувствительность к следующим лекарственным средствам:
            </caption>
            <thead>
              <tr>
                <th>Протестированный маркер</th>
                <th>Агент</th>
              </tr>
            </thead>
            <tbody>
              {negativeAgentsData?.map((agent: { firstColumn: any[]; secondColumn: any[] }) => {
                return (
                  <tr key={agent?.firstColumn?.[0]?.value}>
                    <td>{agent?.firstColumn?.[0]?.value}</td>
                    <td>{agent?.secondColumn?.map((option: { value: string, label: string }) => option.value)?.join(', ')}</td>
                  </tr>)
              })}
            </tbody>
          </TModuleSensToxTable>
          </>
        )}
        {!!comments && (
          <TModuleLabDetailConclusionTable>
            <tbody>
              <tr key={`${comments}`}>
                <td colSpan={1} className="title">{'Примечание:'}</td>
                <td colSpan={3} className="conclusion">{comments}</td>
              </tr>
            </tbody>
          </TModuleLabDetailConclusionTable>)}
      </TModuleSensitivityWr>)

  }, [modules?.moduleSensitivity]);

  const moduleToxicity = useMemo(() => {
    if (!modules?.moduleToxicity) return <></>;
    if (modules?.moduleToxicity?.noSpecify) return <></>;
    const toxicityDataMap: { [index: string]: { agents: string; name: string }; } = {};
    const toxicityMarkers = modules?.moduleToxicity?.restoreDefaultData ?? {};
    const comments = modules?.moduleToxicity?.comments;
    const toxicityData = modules?.moduleToxicity?.toxicityData ?? [];
    for (let data of toxicityData) {
      if (data.marker) toxicityDataMap[data.marker] = { agents: data?.toxicityAgentsNames?.join(', '), name: data.markerName }
    }
    return (
      <>
      {!!Object.keys(toxicityMarkers)?.length && <TModuleSensToxTable>
        <caption className="capitalize">
          Потенциальная токсичность лекарственных средств:
        </caption>
        <thead>
          <tr>
            <th>Протестированный маркер</th>
            <th>Агент</th>
            <th>Токсичность</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(toxicityMarkers)?.map((marker: string) => {
            return (
              <tr key={marker}>
                <td>{toxicityDataMap[marker]?.name}</td>
                <td>{toxicityDataMap[marker]?.agents}</td>
                <td>{toxicityMarkers?.[marker]?.label}</td>
              </tr>
            );
          })}
        </tbody>
      </TModuleSensToxTable>}
      {!!comments && (
        <TModuleLabDetailConclusionTable>
          <tbody>
            <tr key={`${comments}`}>
              <td colSpan={1} className="title">{'Примечание:'}</td>
              <td colSpan={3} className="conclusion">{comments}</td>
            </tr>
          </tbody>
        </TModuleLabDetailConclusionTable>)}
      </>
    )


  }, [modules?.moduleToxicity]);

  const moduleLabDetail = useMemo(() => {
    if (!modules?.moduleLabDetail) return <></>;
    const data = modules?.moduleLabDetail ?? {}


    const ihcData = data?.['ihc'] ?? []
    const IhcExperiments = [];
    const IhcCloneExperiments = [];
    const IhcAlkExperiments = [];
    for(let {blueprintId,experements} of ihcData){
      if(experements[0]?.name === 'ALK'){
        IhcAlkExperiments.push({blueprintId,experements})
        continue;
      } else if(experements[0]?.clone){ 
        IhcCloneExperiments.push({blueprintId,experements})
        continue;
      } else IhcExperiments.push({blueprintId,experements})
    }
  
 

    const methodConclusionTemplate = ((conclData:string[] | undefined) => {
      if(!conclData || !conclData?.length) return <></>
      return <TModuleLabDetailConclusionTable>
        <tbody>
          {conclData?.map((conclusion: string, index: number) => (
            <tr key={`${conclusion}_${index}`}>
              <td colSpan={1} className="title">{!index && 'Клинико-лабораторное заключение:'}</td>
              <td colSpan={3} className="conclusion">{conclusion}</td>
            </tr>))
          }
        </tbody>
      </TModuleLabDetailConclusionTable>
    })

    const pcrResults: string[]  = [];
    const faResults:  string[]  = [];
    const ssResults:  string[]  = [];
    const ihcResults: string[]  = [];
    const ihcCloneResults: string[]  = [];
    const ihcAlkResults: string[]  = [];
    const fishAmplResults: string[]  = [];
    const fishRearResults: string[]  = [];

    const PCR = data?.['pcr']
      ?.map((data: { blueprintId: number, experements: AnalysisExperiment[] }, indexPcr: number) => {
        let { experements } = data;
        return experements?.map((exp:AnalysisExperiment, index: number) => {
          if (exp?.conclusion) pcrResults.push(exp?.conclusion.replaceAll('\n\r', '. ') + '.');
          let notes = exp?.notes;
        let lastSymbol = notes?.length ? notes[notes?.length - 1] : '';
        if(notes && !lastSymbol.includes('.')) notes = `${notes}.`
        if (notes) pcrResults.push(notes);
          return <TModuleLabDetailTable key={exp?.id}>
            {!index && !indexPcr && <caption className="capitalize">
              Детализация выполненных исследований методом ПЦР:
            </caption>}
            {!index && !indexPcr &&<thead>
              <tr>
                <th>Маркер</th>
                <th>Референсная последовательность</th>
                <th>Патогенный вариант/ Детектируемое нарушение</th>
                <th>Результат</th>
              </tr>
            </thead>}
            <tbody>
              <tr>
                <td colSpan={3} className="head">Аналитическая чувствительность метода, %</td>
                <td colSpan={1} >{exp?.analyticalSensitivity}</td>
              </tr>
            </tbody>
            <tbody>
              {exp?.experimentRuns?.map((data: Experiment, index: number, allExperements: Experiment[]) => {
                return <tr key={index + 'mutationPcr'}>
                  {!index && <td rowSpan={allExperements.length}>{data.genePcr}</td>}
                  {!index && <td rowSpan={allExperements.length}>{data.reference}</td>}
                  <td>{data.mutationPcr}</td>
                  <td>{data.resultPcr}</td>
                </tr>
              })}
            </tbody>
          </TModuleLabDetailTable>
        })
      });

    const FA = data?.['fa']?.map((data: { blueprintId: number, experements: AnalysisExperiment[] }, indexFa: number) => {
      let { experements } = data;
      return experements?.map((exp:AnalysisExperiment, index: number) => {
        if (exp?.conclusion) faResults.push(exp?.conclusion.replaceAll('\n\r', '. ') + '.');
        let notes = exp?.notes;
        let lastSymbol = notes?.length ? notes[notes?.length - 1] : '';
        if(notes && !lastSymbol.includes('.')) notes = `${notes}.`
        if (notes) faResults.push(notes);
        let markerName = exp?.name ?? '';
        return (
          <TModuleLabDetailTable key={exp?.id}>
            {!index && !indexFa && <caption className="capitalize">
              Детализация выполненных исследований методом фрагментного анализа:
            </caption>}
            {!index && !indexFa && <thead>
              <tr>
                <th>Маркер</th>
                <th>Микросателлитный маркер</th>
                <th>Результат</th>
              </tr>
            </thead>}
            <tbody>
              <tr>
                <td colSpan={2} className="head">Аналитическая чувствительность метода, %</td>
                <td colSpan={1} >{exp?.analyticalSensitivity}</td>
              </tr>
            </tbody>
            <tbody>
              {exp?.experimentRuns?.map((data: Experiment, index: number, allExperements: Experiment[]) => {
                if (data?.microsatelliteFa) return (
                  <tr key={index + 'microsatellite'}>
                    {!index && <td rowSpan={allExperements.length}>{markerName}</td>}
                    <td>{data.microsatelliteFa}</td>
                    <td>{data.resultFa}</td>
                  </tr>
                )

                return <tr></tr>
              })}
            </tbody>
          </TModuleLabDetailTable>)
      })
    });

    const SS = data?.['ss']?.map((data: { blueprintId: number, experements: AnalysisExperiment[] }, indexSS: number) => {
      let { experements } = data;
      return experements?.map((exp:AnalysisExperiment, index: number) => {
          exp?.experimentSsConclusions
            ?.forEach((data: ExperimentSsConclusion) => ssResults.push(data?.conclusion?.replaceAll(';', '. ')));
            let notes = exp?.notes;
            let lastSymbol = notes?.length ? notes[notes?.length - 1] : '';
            if(notes && !lastSymbol.includes('.')) notes = `${notes}.`
            if (notes) ssResults.push(notes);
        return <TModuleLabDetailTable key={exp?.id}>
          {!index && !indexSS && <caption className="capitalize">
          Детализация выполненных исследований методом молекулярного секвенирования по Сэнгеру:
          </caption>}
          {!index && !indexSS && <thead>
            <tr>
              <th>Маркер</th>
              <th>Референсная последовательность</th>
              <th>Экзон / Кодон / Патогенный вариант</th>
              <th>Результат</th>
            </tr>
          </thead>}
          <tbody>
            <tr>
              <td colSpan={3} className="head">Аналитическая чувствительность метода, %</td>
              <td colSpan={1} >{exp?.analyticalSensitivity}</td>
            </tr>
          </tbody>
          <tbody>
            {exp?.experimentSsRuns?.map((data: ExperimentSs, index: number, allExperements: ExperimentSs[]) => {
              if (data?.identifier) return (
                <tr key={index + 'identifier'}>
                  {!index && <td rowSpan={allExperements.length}>{data.gene}</td>}
                  {!index && <td rowSpan={allExperements.length} className="borderRight">{data.reference}</td>}
                  <td>{data.identifier}</td>
                  <td>{data.result}</td>
                </tr>
              )
              return <tr></tr>
            })}
          </tbody>
        </TModuleLabDetailTable>
      })
    });

    const IHC = IhcExperiments?.map((data: { blueprintId: number, experements: any[] }, indexIHC: number) => {
      let { experements } = data;
      return experements?.map((exp:any, index: number) => {
        let conclusion = exp?.conclusion;
        let lastSymbol = conclusion?.length ? conclusion[conclusion?.length - 1] : '';
        if(conclusion && !lastSymbol.includes('.')) conclusion = `${conclusion}.`
        if (conclusion) ihcResults.push(conclusion);

        return <TModuleLabDetailTable key={exp?.id}>
          {!index && !indexIHC &&  <caption className="capitalize">
            Детализация выполненных иммуногистохимических исследований:
          </caption>}
          {!index && !indexIHC && <thead>
            <tr>
              <th style={{width:'16%'}}>Маркер</th>
              <th colSpan={2} >Референсные значения</th>
              <th style={{width:'20%'}}>Интенсивность окрашивания</th>
              <th style={{width:'20%'}}>Окрашенных клеток, %</th>
              <th style={{width:'14%'}}>Результат</th>
            </tr>
          </thead>}
          <tbody>
            <tr>
              <td style={{width:'16%'}}>{exp?.name ?? '--'}</td>
              <td colSpan={2} className="reference">{exp?.markerIhcReferences?.map((reference: MarkerReference) => <div key={reference?.id}>{reference.content}</div>) ?? '--'}</td>
              <td style={{width:'20%'}}>{exp?.stainIntensity ?? '--'}</td>
              <td style={{width:'20%'}}>{exp?.cellStainPercentage ?? '--'}</td>
              <td style={{width:'14%'}}>{exp?.result ?? '--'}</td>
            </tr>
          </tbody>
        </TModuleLabDetailTable>;
      })
    })
    
    const IHC_Clone = IhcCloneExperiments?.map((data: { blueprintId: number, experements: any[] }, indexCloneIHC: number) => {
      let { experements } = data;
      return experements?.map((exp:any, index: number) => {
        let conclusion = exp?.conclusion;
        let lastSymbol = conclusion?.length ? conclusion[conclusion?.length - 1] : '';
        if(conclusion && !lastSymbol.includes('.')) conclusion = `${conclusion}.`
        if (conclusion) ihcCloneResults.push(conclusion);
        
        return <TModuleLabDetailTable key={exp?.id}>
            {!index && !indexCloneIHC &&  <caption className="capitalize">
            Детализация выполненных иммуногистохимических исследований:
          </caption>}
          {!index && !indexCloneIHC && <thead>
            <tr>
              <th style={{width:'16%'}}>Маркер</th>
              <th>Клон</th>
              <th style={{width:'20%'}}>Показатель экспрессии ОК, %</th>
              <th style={{width:'20%'}}>Показатель экспрессии ИК, %</th>
              <th style={{width:'14%'}}>Результат</th>
            </tr>
          </thead>}
          <tbody>
            <tr>
              <td style={{width:'16%'}}>{!!exp?.name ? exp?.name : '--'}</td>
              <td>{exp?.clone ?? '--'}</td>
              <td style={{width:'20%'}}>{!!exp?.cancerCellLevel ? exp?.cancerCellLevel : '--'}</td>
              <td style={{width:'20%'}}>{!!exp?.immuneCellLevel ? exp?.immuneCellLevel : '--'}</td>
              <td style={{width:'14%'}}>{!!exp?.result ? exp?.result : '--'}</td>
            </tr>
            <tr>
              <td colSpan={1} className="head" style={{width:'16%'}}>Референсные значения</td>
              <td colSpan={4} >{exp?.markerIhcReferences?.map((reference: MarkerReference,index:number) => <div key={`${index}_${reference.content}`}>{reference.content ?? '--'}</div>)}</td>
            </tr>
          </tbody>
        </TModuleLabDetailTable>;
      })
    })

    const IHC_ALK = IhcAlkExperiments?.map((data: { blueprintId: number, experements: any[] }, indexAlk: number) => {
      let { experements } = data;
      return experements?.map((exp:any, index: number) => {
        if(!!exp?.conclusion) ihcAlkResults.push(exp?.conclusion + '.')
        let notes = exp?.notes;
        let lastSymbol = notes?.length ? notes[notes?.length - 1] : '';
        if(notes && !lastSymbol.includes('.')) notes = `${notes}.`
        if (notes) ihcAlkResults.push(notes);
        return <TModuleLabDetailTable key={exp?.id}>
          {!index && !indexAlk && <caption className="capitalize">
            Детализация выполненных иммуногистохимических исследований:
          </caption>}
          {!index && !indexAlk && <thead>
            <tr>
              <th style={{width:'5%'}}>Маркер</th>
              <th style={{width:'5%'}}>Клон</th>
              <th style={{width:'12%'}}>Результат</th>
              <th >Характеристика окрашивания</th>
            </tr>
          </thead>}
          <tbody>
            <tr>
              <td style={{width:'5%'}}>{exp?.name ?? '--'}</td>
              <td style={{width:'5%'}}>{exp?.clone ?? '--'}</td>
              <td style={{width:'12%'}}>{exp?.result ?? '--'}</td>
              <td >{exp?.characteristic ?? '--'}</td>
            </tr>
          </tbody>
        </TModuleLabDetailTable>
      })
    });

    const FISH_AMPL = data?.['fishAmpl']?.map((data: { blueprintId: number, experements: any[] }, indexFAMPL: number) => {
      let { experements } = data;
      return experements?.map((exp:any, index: number) => {
        if(!!exp?.amplification?.conclusion) fishAmplResults.push(`${exp?.amplification?.conclusion}.`)
        let notes = exp?.amplification?.notes;
        let lastSymbol = notes?.length ? notes[notes?.length - 1] : '';
        if(notes && !lastSymbol.includes('.')) notes = `${notes}.`
        if (notes) fishAmplResults.push(notes);
        return <TModuleLabDetailTable key={exp?.id}>
          {!index && !indexFAMPL && <caption className="capitalize">
          Детализация выполненных FISH исследований:
          </caption>}
          {!index && !indexFAMPL && <thead>
            <tr>
              <th>Маркер</th>
              <th>Кол-во проанализ. ядер опухолевых клеток</th>
              <th>Количество сигналов {exp?.name}</th>
              <th>Количество сигналов {exp?.signalName}</th>
              <th>Соотношение {exp?.name}/{exp?.signalName}</th>
              <th>Количество сигналов {`${exp?.name}/клетка`}</th>
              <th>Результат</th>
            </tr>
          </thead>}
          <tbody>
            <tr>
              <td colSpan={2} className="head violet topRadiusL">Реагенты (наборы, зонды)</td>
              <td colSpan={5} className="topRadiusR">{exp?.kit}</td>
            </tr>
          </tbody>
          <tbody>
          <tr>
              <td>{exp?.name}</td>
              <td>{exp?.amplification?.cancerCellNucleusCount ?? '--'}</td>
              <td>{exp?.amplification?.signalMarkerCount ?? '--'}</td>
              <td>{exp?.amplification?.signalCount ?? '--'}</td>
              <td>{exp?.amplification?.proportion ?? '--'}</td>
              <td>{exp?.amplification?.signalToCellCount ?? '--'}</td>
              <td>{exp?.amplification?.result ?? '--'}</td>
            </tr>
          </tbody>
          </TModuleLabDetailTable>})
    });

    const FISH_REAR = data?.['fishRear']?.map((data: { blueprintId: number, experements: any[] }, indexFREAR: number) => {
      let { experements } = data;
      return experements?.map((exp:any, index: number) => {
        if(!!exp?.rearrangement?.conclusion) fishRearResults.push(`${exp?.rearrangement?.conclusion}.`)
        let notes = exp?.rearrangement?.notes;
        let lastSymbol = notes?.length ? notes[notes?.length - 1] : '';
        if(notes && !lastSymbol.includes('.')) notes = `${notes}.`
        if (notes) fishRearResults.push(notes);
        return <TModuleLabDetailTable key={exp?.id}>
          {!index && !indexFREAR && <caption className="capitalize">
          Детализация выполненных FISH исследований:
          </caption>}
          {!index && !indexFREAR && <thead>
            <tr>
              <th>Маркер</th>
              <th>Кол-во проанализ. ядер опухолевых клеток</th>
              <th>Кол-во ядер опухолевых клеток без реаранжировки</th>
              <th>Кол-во ядер с BA-сигналом</th>
              <th>Кол-во ядер с изолированным сигналом</th>
              <th>Ядер опухолевых клеток c реаранжировкой, %</th>
              <th>Результат</th>
            </tr>
          </thead>}
          <tbody>
            <tr>
              <td colSpan={2} className="head violet topRadiusL">Реагенты (наборы, зонды)</td>
              <td colSpan={5} className="topRadiusR">{exp?.kit}</td>
            </tr>
          </tbody>
          <tbody>
          <tr>
          <td>{exp?.name ?? '--'}</td>
              <td>{exp?.rearrangement?.cancerCellCount ?? '--'}</td>
              <td>{exp?.rearrangement?.cancerCellNucleusRearrangedCount ?? '--'}</td>
              <td>{exp?.rearrangement?.splitSignalNucleusCount ?? '--'}</td>
              <td>{exp?.rearrangement?.isolatedSignalNucleusCount ?? '--'}</td>
              <td>{Math.trunc(+exp?.rearrangement?.rearrangedCellPercentage) ?? '--'}</td>
              <td>{exp?.rearrangement?.result ?? '--'}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colSpan={2} className="head violet topRadiusL">Референсные значения</td>
              <td colSpan={5} className="reference">
              {exp?.references?.map((ref: MarkerReference) => <div key={ref.id}>{ref.content}</div>)}
              </td>
            </tr>
          </tbody>
          </TModuleLabDetailTable>})
    });

    return (
      <>
        {PCR}
        {methodConclusionTemplate(pcrResults)}
        {FA}
        {methodConclusionTemplate(faResults)}
        {SS}
        {methodConclusionTemplate(ssResults)}
        {IHC}
        {methodConclusionTemplate(ihcResults)}
        {IHC_Clone}
        {methodConclusionTemplate(ihcCloneResults)}
        {IHC_ALK}
        {methodConclusionTemplate(ihcAlkResults)}
        {FISH_AMPL}
        {methodConclusionTemplate(fishAmplResults)}
        {FISH_REAR}
        {methodConclusionTemplate(fishRearResults)}
      </>)

  }, [modules?.moduleLabDetail])


  return (<TPageContainer ref={ref}>
    {module_default_header}
    {modulePatient}
    {moduleSectionHeader}
    {moduleSensitivity}
    {moduleToxicity}
    {moduleLabDetail}
    {moduleMorphology}
    {moduleSignatory}
    {module_default_footer}
  </TPageContainer>)
});

export default ConclusionToPrint;