import styled from 'styled-components';

export const TWrapper = styled.div`
  width: 100%;
  
  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
