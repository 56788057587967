import { Dispatch } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { loadLocalStorageData, saveLocalStorageData } from '../../utils/localStorageHelpers';
import {
  getLocalizations,
  getLocalizationsFailure,
  getLocalizationsSuccess,
} from './';

export const fetchLocalizations = (access: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getLocalizations());
  const savedData = await loadLocalStorageData('registry/localization/list/');
  if(savedData) return dispatch(getLocalizationsSuccess(savedData));

  try {
    const response = await axiosInstance(access).get('registry/localization/list/');
    await saveLocalStorageData('registry/localization/list/', response.data);
    dispatch(getLocalizationsSuccess(response.data));
  } catch (error: any) {
    dispatch(getLocalizationsFailure(error?.response?.data));
  }
}