import { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { getYear } from 'date-fns';
import { TArrowWr, TTooltipButton, TTooltipForm, TTooltipInput, TCloseButton, TTooltipFormHeader, TTooltipFormContent, TTooltipFormGap } from "../styled";
import { ReactComponent as ArrowRight } from '../../../../icons/arrow-right.svg';
import { useForm } from "react-hook-form";
import { extendedValidations, validations } from '../../../../utils/validations';
import { ReactComponent as CloseIcon } from '../../../../icons/cross-in-circle.svg';
import useOutsideClick from "../../../../hooks/useOutsideClick";


const currentYear = getYear(new Date());

export interface IFormValues {
  start_code: string,
  end_code: string,
  amount: string,
  year: string,
  code: string,
}

interface IBioMaterialEdit {
  block?: any,
  slide?: any,
  setModalCellId: any,
  savedData?: IFormValues,
  isOpenBlock: boolean,
  isOpenSlide: boolean,
  isOpenModal: boolean,
  onAddNewNumber: (type: string, data: any) => void,
  rightOfForm?: number,
  transform?: number,
}

const BioMaterialEditModal: FC<IBioMaterialEdit> = (({ block, slide, setModalCellId, isOpenModal, onAddNewNumber, isOpenBlock, isOpenSlide, rightOfForm, transform, savedData }) => {
  const { register, handleSubmit, setValue, setError, clearErrors, watch, trigger, formState: { errors }, reset, } = useForm<IFormValues>({
    defaultValues: {
      start_code: savedData?.start_code ?? '',
      end_code: savedData?.end_code ?? '',
      amount: savedData?.amount ?? '',
      code: savedData?.code ?? '',
      year: (savedData?.year || currentYear.toString()) ?? '',
    }
  });

  useEffect(() => {
    const subscription = watch(({ start_code, end_code, amount, year, code }, e) => {
      let isError: string[] = [];
      let eventType: string = e.name ?? ''

      //NEGATIVE Case *************************

      // ------- start_code
      if (eventType === 'start_code') {
        if (!amount) setValue('amount', '1');
        if (!start_code) {
          amount !== '1' && setValue('amount', '1');
          end_code !== '' && setValue('end_code', '');
          isError.push('start_code');
        }
        if (end_code && start_code && (+start_code >= +end_code)){
          setValue('code', '');
          setValue('amount', '');
          isError.push('end_code');
          isError.push('amount');
        }
      }
      // ------- end_code
      if (eventType === 'end_code') {
        if (end_code && isNaN(parseInt(end_code?.trim() ?? ''))) {
          setValue('code', '');
          isError.push('end_code');
        };
        if ((end_code && start_code && (+start_code >= +end_code)) ||
          (end_code && start_code && (+end_code - +start_code) > 200)) {
          setValue('code', '');
          isError.push('end_code');
          isError.push('amount');
        };
        if (end_code && start_code && (+start_code >= +end_code)){
          setValue('code', '');
          setValue('amount', '');
          isError.push('end_code');
          isError.push('amount');
        }
      }
      // ------- amount
      if (eventType === 'amount') {
        if (amount && +amount > 200) {
          isError.push('amount');
        }
      }
      // ------- year
      if (eventType === 'year') {
        let isYearRange = year && +year >= 2000 && +year <= currentYear
        if (!isYearRange) isError.push('year');
      }



      //POSITIVE Case *****************************

      // ------- start_code
      if (eventType === 'start_code' && !isError.length) {
        if (start_code && amount && +amount > 1) {
          setValue('end_code', `${+start_code + +amount - 1}`);
        }
      }
      // ------- end_code
      if (eventType === 'end_code' && !isError.length) {
        if (!end_code) {
          setValue('amount', '1');
        };
        if (start_code && end_code) {
          if(+end_code > +start_code) setValue('amount', `${+end_code - +start_code + 1}`)
        };
      }
      // ------- amount
      if (eventType === 'amount' && !isError.length) {
        if (start_code && amount) {
          let result = `${+start_code + +amount - 1}`;
          if (+amount > 1 && end_code !== result) {
            setValue('end_code', result);
          }
          if (amount === '1' && end_code !== '') {
            setValue('end_code', '');
          }
        }
      }

      //Result Code field
      if (start_code && amount) {
        let codeResult = `${start_code}${end_code ? '-' + end_code.slice(-2) : ''}/${year ?
          year.toString().slice(-2) : currentYear.toString().slice(-2)}`;
        if (code !== codeResult) setValue('code', codeResult);
      } else {
        if (code !== '') setValue('code', '');
      }

      clearErrors();

      isError.length && isError.forEach((name: string) => {
        switch (name) {
          case 'start_code': setError('start_code', { type: 'custom' })
            break;
          case 'end_code': setError('end_code', { type: 'custom' })
            break;
          case 'amount': setError('amount', { type: 'custom' })
            break;
          case 'year': setError('year', { type: 'custom' })
            break;
          default: break;
        }

      })
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, setError, errors, clearErrors]);

  const dataType = block ? 'block' : 'slide';

  const onSubmit = handleSubmit((data: any) => {
    data.organization = "РНПЦ ОМР";
    if (slide && slide.slide) data.slide = slide.slide;
    else data.block = block.block;
    if (!Object.keys(errors)?.length) {
      return onAddNewNumber(dataType, data);
    }
  });
  const handleError = async () => {
    const isAllValid = await trigger(['start_code', 'end_code', 'year', 'amount']);
    if (!isAllValid) return;
    if (!errors?.start_code && !errors?.end_code) {
      return setModalCellId('')
    }
  }

  const closeWidget = useCallback(() => {
    setModalCellId('');
      reset({
        start_code: '',
        end_code: '',
        amount: '',
        code: '',
        year: currentYear.toString(),
      }, {
        keepErrors: false,
        keepTouched: false,
        keepValues: false,
        keepDefaultValues: true,
      });
    
    setTimeout(() => {
      clearErrors(['start_code', 'amount'])
    }, 1000);
  }, [clearErrors, reset, setModalCellId]);

  const formNodeRef = useRef<HTMLFormElement>(null) as MutableRefObject<HTMLFormElement>;

  useOutsideClick(formNodeRef, ({ target }) => {
    if (!isOpenModal) return;

    let formId = formNodeRef.current.id;
    let targetId = (target as Element)?.id ?? '';
    if (!targetId.includes(formId)){
       setModalCellId('');
       return reset({...savedData}, {
        keepErrors: true,
      })
      };
  });

  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setIsDisable(false)
      clearErrors(['start_code', "amount"])
    }
    if (Object.keys(errors).length) {
      setIsDisable(true)
    }
  }, [errors, setIsDisable, clearErrors, errors.start_code, errors.end_code]);

  return (
    <TTooltipForm
      ref={formNodeRef}
      onSubmit={onSubmit}
      isOpenBlock={isOpenBlock}
      isOpenSlide={isOpenSlide}
      rightOfForm={rightOfForm}
      transform={transform}
      id='tooltip-'>
      <TTooltipFormHeader>
        <b> Укажите номер, присвоенный лабораторией по принципу стандартной нумерации, например: </b>
        <i>12300, год 2023  →  12300/23</i>
        <i>12300 - 12311, год 2023 → 12300-11/23</i>
      </TTooltipFormHeader>
      <TTooltipFormContent>
        <TTooltipInput
          {...register('start_code', {
            required: true
          })}
          id='tooltip-start_code'
          placeholder='Начальный №'
          width='138px'
          type='number'
          min="1"
          step="1"
          maxLength={12}
          error={!!errors.start_code}
          onChange={async ({ target }) => setValue(`start_code`, target.value.replace(/(\.\d+)+/, ''))}
          isBlockBorder={block}
        />
        <TTooltipInput
          {...register('end_code')}
          id='tooltip-end_code'
          placeholder='Конечный №'
          width='138px'
          type='number'
          min="1"
          step="1"
          maxLength={12}
          error={!!errors?.end_code}
          isBlockBorder={block}
        />
        {/* <TTooltipInput
          {...register('amount', {
            ...extendedValidations.code,
            required: true
          })}
          id='tooltip-amount'
          placeholder='Кол-во'
          width='80px'
          type='number'
          min="1"
          max={200}
          step="1"
          maxLength={4}
          error={!!errors.amount}
          onChange={async ({ target }) => setValue(`amount`, target.value.replace(/(\.\d+)+/, ''))}
          isBlockBorder={block}
        /> */}
        <TTooltipFormGap />
        <TTooltipInput
          {...register('year', {
            ...validations.year, ...extendedValidations.year,
            required: true
          })}
          id='tooltip-year'
          placeholder='Год'
          width='80px'
          type='number'
          defaultValue={currentYear}
          error={!!errors?.year}
          isBlockBorder={block}
          onChange={async () => await trigger("year")}
          onBlur={async ({target}) => {
            let value = target.value.trim();
            if(value?.length === 1){
              value = '200' + value;
            }
            if(value?.length === 2){
              value = '20' + value;
            }
            setValue('year',value);
            await trigger("year");
          }}
        />
        <TArrowWr id='tooltip-arrow_wr'>
          <ArrowRight id='tooltip-arrow_r' />
        </TArrowWr>
        <TTooltipInput
          {...register('code')}
          id='tooltip-code'
          placeholder='Результат'
          width='180px'
          backgroundColorBlock={dataType}
          isBlockBorder={block}
          readOnly
        />
        <TTooltipButton id='tooltip-ok-button' onClick={handleError} disabled={isDisable}>OK</TTooltipButton>
      </TTooltipFormContent>
      <TCloseButton id='tooltip-close-button' onClick={closeWidget}>
        <CloseIcon id='tooltip-close-icon' />
      </TCloseButton>
    </TTooltipForm>
  );
});

export default BioMaterialEditModal;