import styled from 'styled-components';
import Button from '../../shared/Button';

export const TInfo = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray[500]};
  padding: 20px 0;
  margin: 0;
`;

export const TRegistrarList = styled.div`
  margin-bottom: 68px;
  
  & > span {
    font-weight: 500;
    font-size: 16px;
  }
`;

export const TRegistrarBlockWr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;

  & > * {
    margin-bottom: 16px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TLinkButton = styled(Button)`
  display: inline;
`;

export const TDateAndPaymentFieldsWr = styled.div`
  margin-top: 40px;
  position: relative;
  /* & > * {
    margin-bottom: 2px;
  } */
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TDateInputWr = styled.div`
  position: relative;
`;

export const TInputsWr = styled.div`
  display: flex;
  
  & > * {
    margin-right: 3px;
    width: 50%;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const THint = styled.ul<{ focused?: boolean }>`
  position: absolute;
  display: ${({ focused }) => focused ? 'flex' : 'none'};
  flex-direction: column;
  align-items: start;
  width: 300px;
  width: max-content;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray[400]};
  top: -16px;
  right: -180px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 12px;
  border-radius: 10px;
  li {
    margin-left: 12px;
    b {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray[500]};
    }
  } 
  ::before {
    content: '';
    display: block;
    position: absolute;
    left:-12px;
    top:calc(50% - 10px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 14px 10px 0;
    border-color: transparent #fff transparent transparent;
  }
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.07), 1px -1px 6px 0px rgba(0, 0, 0, 0.07);
`;

export const TWrongData = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color:${({theme}) => theme.colors.gray[500]}
`
