import styled from 'styled-components';

export const TNotEditedInput = styled.div<{dataLabel?:string,width?:string}>`
  position: relative;
  display: inline-block;
  width: ${({width}) => width ? width : '100%'};
  border: 1px solid ${({theme}) => theme.colors.gray[400]};
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.gray[500]};
  margin-top: 50px;
  font-weight: 400;
  padding: 18px 16px;
  font-weight: 500;
  box-sizing: border-box;
  height: fit-content;

    b {
      font-weight: 600;
    }
    ::before {
      content: '${props => props.dataLabel}';
      position: absolute;
      top: -31px;
      left: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${({theme}) => theme.colors.gray[500]};
    }
`