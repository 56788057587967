import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TWrapper } from './styled';
import SlideBlockSection from './SlideBlockSection';

const GlassRegistration: FC = () => {
  const { control } = useFormContext();

  const blocks = useWatch({ control, name: 'blocks' });

  return (
    <TWrapper>
      { /* @ts-ignore */}
      {blocks.map((item, index) => (
        <SlideBlockSection key={index} blockIndex={index} />
      ))}
    </TWrapper>
  );
}

export default GlassRegistration;
