import { createSelector } from '@reduxjs/toolkit';
import { IAppState } from '../';
import { ReferralMarkerPriority, MarkerPriority } from './model';

const getState = (state: IAppState) => state.markers;

export const getMarkers = createSelector(getState, state => state.data);

const formattedMarkers = createSelector(getMarkers, markers =>
  markers.reduce((acc, item) => {
    acc[item.drugOrder.drugClass] = acc[item.drugOrder.drugClass] ?? [];
    // @ts-ignore
    acc[item.drugOrder.drugClass].push(item);
    return acc;
  }, []).filter(item => item));

export const getHighPriorityMarkers = createSelector(formattedMarkers, markers =>
  markers.map((group: ReferralMarkerPriority[]) => (
    group.filter((item) => item.priority === MarkerPriority.HIGH)
  )).filter((group) => group.length));

export const getLowPriorityMarkers = createSelector(formattedMarkers, markers =>
  markers.map((group: ReferralMarkerPriority[]) => (
    group.filter((item) => item.priority === MarkerPriority.LOW)
  )).filter((group) => group.length));

export const getDrugByMarkerMap = createSelector(formattedMarkers, (markers:ReferralMarkerPriority[]) => {
  let markersMap = new Map();
  for (let mrk of markers.flatMap((markerGroup:ReferralMarkerPriority) => markerGroup)){
    if(!mrk?.marker?.ulid) continue;
    let existMkr = markersMap.get(mrk.marker.ulid);
    if(existMkr){
      markersMap.set(mrk.marker.ulid,new Set([...existMkr, mrk.drugOrder.drugName]));
      continue;
    }
    markersMap.set(mrk.marker.ulid,[mrk.drugOrder.drugName])
    }
    return markersMap;
})

export const getMarkerTypes = createSelector(getState, state => state.typeList)
