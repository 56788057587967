import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import RestorePasswordForm from '../../components/forms/RestorePasswordForm';
import AuthPageContainer from '../../components/shared/AuthPageContainer/AuthPageContainer';
import { ROUTES } from '../../constants/routes';
import { getErrors, getUser } from '../../store/auth/selectors';
import { restorePassword } from '../../store/auth/thunkActions';

const RestorePasswordPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const errors = useSelector(getErrors);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);

  const { id, token } = useParams<{ token: string, id: string }>();

  const submitForm = useCallback(async ({ password }) => {
    if (isSubmitted || invalidToken) {
      return navigate(ROUTES.SIGN_IN, { replace: true });
    }

    const data:any = {
      password,
      uidb64: id,
      token,
    };

    await dispatch(restorePassword(data));
  }, [invalidToken, isSubmitted, navigate, id, token, dispatch]);

  useEffect(() => {
    if (!id || !token) {
      navigate(ROUTES.RESTORE_PASSWORD_REQUEST, { replace: true });
    }
  }, [id, token, navigate]);

  useEffect(() => {
    if (user) {
      setIsSubmitted(true);
    }
  }, [user, setIsSubmitted]);

  useEffect(() => {
    if (errors && errors.error && typeof errors.error === 'string' && errors.error.includes('The reset link is invalid')) {
      setInvalidToken(true);
    }
  }, [errors, setInvalidToken]);

  return (
    <AuthPageContainer>
      <RestorePasswordForm invalidToken={invalidToken} isSubmitted={isSubmitted} formSubmit={submitForm} errors={errors} />
    </AuthPageContainer>
  );
}

export default RestorePasswordPage;
