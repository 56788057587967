const digitKeys: { [key: string]: string } = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "0": "0",
};

const usageKeys: { [key: string]: string } = {
  Backspace: "Backspace",
  ArrowRight: "ArrowRight",
  ArrowLeft: "ArrowLeft",
  Tab: "Tab",
};

export class InputKeyChecker {
  static checkDigit(key: string, auxKey: boolean): boolean {
    if (
      digitKeys[key] ||
      usageKeys[key] ||
      (key === "v" && auxKey) ||
      (key === "c" && auxKey)
    ) {
      return true;
    }

    return false;
  }
}
