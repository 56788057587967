import { createSlice } from '@reduxjs/toolkit';
import { ErrorsType, Nullable } from '../../types';
import { ReferralsData } from '../referral/model';
import { 
  IMolecularProfiling, 
  IMarkerUpdate, 
  IRegistryMarkerList, 
  IExaminationBlueprintsUpdated,
  ISubBlockUpdated} from './model';


export interface IMolecularProfilingState {
  loading:  boolean;
  errors:   Nullable<ErrorsType>;
  data:     Nullable<IMolecularProfiling>;
  patientRefferals: Nullable<ReferralsData>;
  markersListUpdate: Nullable<IMarkerUpdate[]>;
  registryMarkerList: Nullable<IRegistryMarkerList[]>;
  examinationBlueprintsUpdated: Nullable<IExaminationBlueprintsUpdated>;
  createdSubBlockList: Nullable<ISubBlockUpdated[]>;
  сomplitedReferralNumber: Nullable<string>;
  referralIsRedyToComplite: boolean;
  savedMolProCharacteristic: Nullable<any>;
}

export const initialState: IMolecularProfilingState = {
  loading:  false,
  errors:   null,
  data:     null,
  patientRefferals: null,
  markersListUpdate: null,
  registryMarkerList: null,
  examinationBlueprintsUpdated: null,
  createdSubBlockList: null,
  сomplitedReferralNumber: null,
  referralIsRedyToComplite: false,
  savedMolProCharacteristic: null,
}

const molecularProfilingSlice = createSlice({
  name: 'molecularProfiling',
  initialState,
  reducers: {
    getMolecularProfiling: state => {
      state.loading = true;
      state.сomplitedReferralNumber = null;
    },
    getMolecularProfilingSuccess: (state, { payload }) => {
      state.loading = false;
      state.errors  = null;
      state.data    = payload;
      state.referralIsRedyToComplite = false;
    },
    getMolecularProfilingFailure: (state, { payload }) => {
      state.loading = false;
      state.errors  = payload;
    },
    resetMolecularProfiling: (state) => {
      state.data = null;
      state.patientRefferals = null;
      state.markersListUpdate = null;
      state.examinationBlueprintsUpdated = null;
      state.registryMarkerList = null;
      state.createdSubBlockList = null;
      state.сomplitedReferralNumber = null;
    },

    setReferralIsRedyToCompliteState: (state, { payload }) => {
      state.referralIsRedyToComplite = payload;
    },

    getMolProPatientReferrals: (state => {
      state.loading = true;
    }),
    getMolProPatientReferralsSuccess: (state, { payload }) => {
      state.loading = false;
      state.errors  = null;
      state.patientRefferals = payload;
    },
    getMolProPatientReferralsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors  = payload;
    },
    resetMolProPatientReferrals: (state) => {
      state.patientRefferals = null;
    },

    cancelMolecularProfiling: (state) => {
      state.loading = true;
    },
    cancelMolecularProfilingSuccess: (state, { payload }) => {
      state.loading = false;
      state.errors  = null;
      state.data    = payload;
    },
    cancelMolecularProfilingFailure: (state, { payload }) => {
      state.loading = false;
      state.errors  = payload;
    },
    changeMolProPaymentAndSpeedType: (state => {
      state.loading = true;
    }),
    changeMolProPaymentAndSpeedTypeSuccess: (state, { payload }) => {
      state.loading = false;
      state.errors  = null;
      if(state?.data?.paymentType) state.data.paymentType = payload['paymentType'];
      if(state?.data?.speedType) state.data.speedType = payload['speedType'];
      else state.data = null;
    },
    changeMolProPaymentAndSpeedTypeFailure: (state, { payload }) => {
      state.loading = false;
      state.errors  = payload;
    },
    confirmMolProBioMaterial: (state => {
      state.loading = true;
    }),
    confirmMolProBioMaterialSuccess: (state) => {
      state.loading = false;
      state.errors = null;
      state.data = null;
      state.patientRefferals = null;
    },
    confirmMolProBioMaterialFailure: (state, {payload}) => {
      state.loading = false;
      state.errors = null;
      state.data = payload;
    },

    commentMolProCreate: (state => {
      state.loading = true;
    }),
    commentMolProCreateSuccess: (state) => {
      state.loading = false;
      state.errors = null;
    },
    commentMolProCreateFailure: (state, { payload }) => {
      state.loading = false;
      state.errors  = payload;
    },
    postNewBioMatNumbers: (state => {
      state.loading = true;
    }),
    postNewBioMatNumbersSuccess: (state) => {
      state.loading = false;
      state.errors  = null;
    },
    postNewBioMatNumbersFailure: (state, {payload}) => {
      state.loading = false;
      state.errors  = payload;
    },

    postCreateNewBioMatSubsNumber: (state => {
      state.loading = true;
    }),
    postCreateNewBioMatSubsNumberSuccess: (state, {payload}) => {
      state.createdSubBlockList = payload;
      state.loading = false;
      state.errors  = null;
    },
    postCreateNewBioMatSubsNumberFailure: (state, {payload}) => {
      state.loading = false;
      state.errors  = payload;
    },

    patchUpdateNewBioMatSubs: (state => {
      state.loading = true;
    }),
    patchUpdateNewBioMatSubsSuccess: (state => {
      state.createdSubBlockList = null;
      state.loading = false;
      state.errors  = null;
    }),
    patchUpdateNewBioMatSubsFailure: (state, {payload}) => {
      state.loading = false;
      state.errors  = payload;
    },

    putConfirmMorphologicalGlass: (state => {
      state.loading = true;
    }),
    putConfirmMorphologicalSuccess: (state) => {
      state.loading = false;
      state.errors = null;
    },
    putConfirmMorphologicalFailure: (state, {payload}) => {
      state.loading = false;
      state.errors  = payload;
    },

    createMorphologicalCharacteristic: (state) => {
      state.loading = true;
    },
    createMorphologicalCharacteristicSuccess: (state) => {
      state.loading = false;
    },
    createMorphologicalCharacteristicFailure: (state, {payload}) =>{
      state.loading = false;
      state.errors = null;
      state.data = payload;
    },
    
    confirmMorphologicalStatus: (state => {
      state.loading = true;
    }),
    confirmMorphologicalStatusSuccess: (state) => {
      state.loading = false;
      state.errors = null;
      state.data = null;
      state.patientRefferals = null;
    },
    confirmMorphologicalStatusFailure: (state, {payload}) => {
      state.loading = false;
      state.errors = null;
      state.data = payload;
    },

    updateReferralMarkerList: (state => {
      state.loading = true;
    }),
    updateReferralMarkerListSuccess: (state, {payload}) => {
      state.loading = false;
      state.markersListUpdate = payload;
      state.errors = null;
    },
    updateReferralMarkerListFailure: (state, {payload}) => {
      state.loading = false;
      state.errors = null;
      state.data = payload;
    },
    resetReferralMarkerList: (state => {
      state.loading = false;
      state.errors = null;
      state.markersListUpdate = null;
    }),

    createExamination:(state => {
      state.loading = true;
    }),
    createExaminationSuccess:(state => {
      state.loading = false;
      state.errors = null;
    }),
    createExaminationFailure:(state, {payload}) => {
      state.loading = false;
      state.errors = null;
      state.data = payload;
    },

    updateExaminationBlueprints:(state => {
      state.loading = true;
    }),
    updateExaminationBlueprintsSuccess:(state, {payload}) => {
      state.loading = false;
      state.examinationBlueprintsUpdated = payload;
      state.errors = null;
    },
    updateExaminationBlueprintsFailure:(state, {payload}) => {
      state.loading = false;
      state.errors = payload;
      state.examinationBlueprintsUpdated = null;
    },

    confirmMorphMarkersDataConfirmStatus: (state => {
      state.loading = true;
    }),
    confirmMorphMarkersDataConfirmStatusSuccess: (state) => {
      state.loading = false;
      state.errors = null;
      state.data = null;
      state.patientRefferals = null;
    },
    confirmMorphMarkersDataConfirmStatusFailure: (state, {payload}) => {
      state.loading = false;
      state.errors = null;
      state.data = payload;
    },
    confirmReferralCompliteStatus: (state => {
      state.loading = true;
    }),
    confirmReferralCompliteStatusSuccess: (state, {payload}) => {
      state.loading = false;
      state.errors = null;
      state.data = null;
      state.patientRefferals = null;
      state.сomplitedReferralNumber = payload;
    },
    confirmReferralCompliteStatusFailure: (state, {payload}) => {
      state.loading = false;
      state.errors = null;
      state.data = payload;
      state.сomplitedReferralNumber = null;
    },

    registryMarkerList: (state => {
      state.loading = true;
    }),
    registryMarkerListSuccess: (state, {payload}) => {
      state.loading = false;
      state.errors = null;
      state.registryMarkerList = payload;
    },
    registryMarkerListFailure:(state, {payload}) => {
      state.loading = false;
      state.errors = payload;
      state.registryMarkerList = null;
    },

    registryCreateNextStep:(state => {
      state.loading = true;
    }),
    registryCreateNextStepSuccess: (state) => {
      state.loading = false;
    },
    registryCreateNextStepFailure:(state, {payload}) => {
      state.loading = false;
      state.errors = payload;
    },

    resetSavedMolProCharacteristic: state => {
      state.loading = true;
      state.errors  = null;
      state.savedMolProCharacteristic = null;
    },

    getSavedMolProCharacteristic: state => {
      state.loading = true;
      state.savedMolProCharacteristic = null;
    },
    getSavedMolProCharacteristicSuccess: (state, { payload }) => {
      state.loading = false;
      state.errors  = null;
      state.savedMolProCharacteristic = payload;
    },
    getSavedMolProCharacteristicFailure: (state, { payload }) => {
      state.loading = false;
      state.errors  = payload;
    },
    setSavedMolProCharacteristic: state => {
      state.loading = true;
    },
    setSavedMolProCharacteristicSuccess: (state, { payload }) => {
      state.loading = false;
      state.errors  = null;
      state.savedMolProCharacteristic = payload;
    },
    setSavedMolProCharacteristicFailure: (state, { payload }) => {
      state.loading = false;
      state.errors  = payload;
    },
    
  },
});

export const {
  getMolecularProfiling,
  getMolecularProfilingSuccess,
  getMolecularProfilingFailure,
  resetMolecularProfiling,

  setReferralIsRedyToCompliteState,

  getMolProPatientReferrals,
  getMolProPatientReferralsSuccess,
  getMolProPatientReferralsFailure,
  resetMolProPatientReferrals,

  cancelMolecularProfiling,
  cancelMolecularProfilingSuccess,
  cancelMolecularProfilingFailure,

  changeMolProPaymentAndSpeedType,
  changeMolProPaymentAndSpeedTypeSuccess,
  changeMolProPaymentAndSpeedTypeFailure,

  confirmMolProBioMaterial,
  confirmMolProBioMaterialSuccess,
  confirmMolProBioMaterialFailure,

  commentMolProCreate,
  commentMolProCreateSuccess,
  commentMolProCreateFailure,

  postNewBioMatNumbers,
  postNewBioMatNumbersSuccess,
  postNewBioMatNumbersFailure,

  postCreateNewBioMatSubsNumber,
  postCreateNewBioMatSubsNumberSuccess,
  postCreateNewBioMatSubsNumberFailure,

  patchUpdateNewBioMatSubs,
  patchUpdateNewBioMatSubsSuccess,
  patchUpdateNewBioMatSubsFailure,

  putConfirmMorphologicalGlass,
  putConfirmMorphologicalSuccess,
  putConfirmMorphologicalFailure,

  createMorphologicalCharacteristic,
  createMorphologicalCharacteristicSuccess,
  createMorphologicalCharacteristicFailure,

  confirmMorphologicalStatus,
  confirmMorphologicalStatusSuccess,
  confirmMorphologicalStatusFailure,

  updateReferralMarkerList,
  updateReferralMarkerListSuccess,
  updateReferralMarkerListFailure,

  createExamination,
  createExaminationSuccess,
  createExaminationFailure,

  resetReferralMarkerList,

  updateExaminationBlueprints,
  updateExaminationBlueprintsSuccess,
  updateExaminationBlueprintsFailure,

  confirmMorphMarkersDataConfirmStatus,
  confirmMorphMarkersDataConfirmStatusSuccess,
  confirmMorphMarkersDataConfirmStatusFailure,

  confirmReferralCompliteStatus,
  confirmReferralCompliteStatusSuccess,
  confirmReferralCompliteStatusFailure,

  registryMarkerList,
  registryMarkerListSuccess,
  registryMarkerListFailure,

  registryCreateNextStep,
  registryCreateNextStepSuccess,
  registryCreateNextStepFailure,

  resetSavedMolProCharacteristic,
  getSavedMolProCharacteristic,
  getSavedMolProCharacteristicSuccess,
  getSavedMolProCharacteristicFailure,
  setSavedMolProCharacteristic,
  setSavedMolProCharacteristicSuccess,
  setSavedMolProCharacteristicFailure,

} = molecularProfilingSlice.actions;

export default molecularProfilingSlice.reducer;
