import { Dispatch } from 'react';
import {
  setResearchType,
  setPatient,
  setRegistrar,
  setPaymentType,
  setSpeedType,
  setRegistrarDateOfAcquisition,
  setClinicalData,
  setBiomaterialData,
  setMarkers,
  setCommentary,
  setReferralStatus,
} from '../';
import { Patient } from '../../patient/model';
import { setToSessionStorage } from '../../../utils/sessionStorageHelpers';
import SESSION_STORAGE_KEYS from '../../../constants/sessionStorageKeys';
import { NUMBERING } from '../../../components/forms/BiomaterialForm/constants';
import { formatPhoneNumber, transformBloodId } from '../../../utils/stringHelpers';

const { ROOT, PATIENT, REGISTRAR, CLINICAL, BIO_MATERIAL } = SESSION_STORAGE_KEYS;

export const setReferralResearchType = ({ type }: { type: string }) => (dispatch: Dispatch<unknown>) => {
  setToSessionStorage([ROOT.type, type]);

  dispatch(setResearchType({ type }));
};

export const setReferralPatient = (data: Patient) => (dispatch: Dispatch<unknown>) => {
  const {
    id,
    ulid,
    idPassport,
    middleName,
    country,
    gender,
    dateOfBirth,
    ambulatoryCards,
    firstName,
    lastName,
  } = data;

  setToSessionStorage([PATIENT.id, id], [PATIENT.idPassport, idPassport], [PATIENT.country, country],
    [PATIENT.ulid, ulid],[PATIENT.gender, gender], [PATIENT.dateOfBirth, dateOfBirth], [PATIENT.middleName, middleName],
    [PATIENT.ambulatoryCards, JSON.stringify(ambulatoryCards)], [PATIENT.firstName, firstName], [PATIENT.lastName, lastName],
  );

  dispatch(setPatient(data));
};

export const setReferralRegistrar = (data: any) => (dispatch: Dispatch<unknown>) => {
  const {
    specialistUlid,
    commonOrganization,
    organization,
    organizationCustom,
    department,
    subdepartment,
    fullName,
    firstName,
    lastName,
    middleName,
    phoneNumber,
    email,
    creationDate,
    comment,
    payment,
    speed,
  } = data;
  let firstNameValue = firstName;
  let lastNameValue = lastName;
  let middleNameValue = middleName;

  if (!firstName && fullName) {
    const splitFullName = fullName.split(' ');
    lastNameValue = splitFullName[0];
    firstNameValue = splitFullName[1];
    middleNameValue = splitFullName[2];
  }

  setToSessionStorage([REGISTRAR.commonOrganization, commonOrganization], [REGISTRAR.organization, organization],[REGISTRAR.organizationCustom, organizationCustom],
    [REGISTRAR.department, department], [REGISTRAR.subdepartment, subdepartment], [REGISTRAR.specialistUlid, specialistUlid],
    [REGISTRAR.fullName, fullName],[REGISTRAR.firstName, firstNameValue], [REGISTRAR.lastName, lastNameValue], [REGISTRAR.middleName, middleNameValue],
    [REGISTRAR.phoneNumber, phoneNumber], [REGISTRAR.email, email], [REGISTRAR.creationDate, new Date(creationDate)?.toISOString()], [REGISTRAR.comment, comment],
    [REGISTRAR.payment, payment],[REGISTRAR.speed, speed]
  );

  const paymentType = payment;
  const speedType = speed;
  const registrarEmail = email;
  const dateOfAcquisition = new Date(creationDate)?.toISOString();

  delete data.fullName;
  delete data.specialistUlid;
  delete data.payment;
  delete data.speed;
  delete data.email;
  delete data.creationDate;
  !data.department && delete data.department;
  !data.subdepartment && delete data.subdepartment;

  const formattedData = {
    ulid: specialistUlid,
    email: registrarEmail ? registrarEmail : null,
    userProfile: {
      ...data,
      phoneNumber: phoneNumber ? formatPhoneNumber(phoneNumber) : '',
      firstName: firstNameValue,
      lastName: lastNameValue,
      middleName: middleNameValue,
      organization: organization || commonOrganization,
      organizationCustom: organizationCustom ?? '',
    },
  }

  dispatch(setRegistrar(formattedData));
  dispatch(setPaymentType(paymentType));
  dispatch(setRegistrarDateOfAcquisition(dateOfAcquisition));
  dispatch(setSpeedType(speedType));
};

export const setReferralClinicalData = (data: any) => (dispatch: Dispatch<unknown>) => {
  const {
    localization,
    icd_10,
    description,
    tCategory,
    tSub,
    nCategory,
    nSub,
    mCategory,
    mSub,
    stage,
    stageSub,
    histological,
    tnmNonApplicable,
    tnmUnknown,
    stageNonApplicable,
    stageUnknown
  } = data;

  setToSessionStorage([CLINICAL.localization, localization], [CLINICAL.icd_10, icd_10], [CLINICAL.description, description],
    [CLINICAL.tCategory, tCategory],[CLINICAL.tSub, tSub], [CLINICAL.nCategory, nCategory], [CLINICAL.nSub, nSub],
    [CLINICAL.mCategory, mCategory], [CLINICAL.mSub, mSub], [CLINICAL.stage, stage], [CLINICAL.stageSub, stageSub], [CLINICAL.histological, histological],
    [CLINICAL.tnmNonApplicable, tnmNonApplicable], [CLINICAL.tnmUnknown, tnmUnknown], [CLINICAL.stageNonApplicable, stageNonApplicable], [CLINICAL.stageUnknown, stageUnknown],

  );

  const formattedData = {
    localization,
    clinical: {
      icd_10,
      tCategory,
      tSub: tSub === '--' ? '' : tSub,
      nCategory,
      nSub: nSub === '--' ? '' : nSub,
      mCategory,
      mSub: mSub === '--' ? '' : mSub,
      stage: `${stage}${stageSub}`,
      description,
      tnmNonApplicable,
      tnmUnknown,
      stageNonApplicable,
      stageUnknown
    },
    histological: {
      description: histological,
    }
  }

  dispatch(setClinicalData(formattedData));
};

export const setReferralBiomaterialData = (data: any) => (dispatch: Dispatch<unknown>) => {
  const {
    source,
    organization,
    organizationCustom,
    doctorOrganization,
    doctorCustomOrganization,
    dateOfAcquisition,
    numbering,
    bloodDateOfAcquisition,
    bloodId,
    blocks = [],
  } = data;

  const standardNumbering = numbering === NUMBERING.STANDARD;

  const formattedBlocks = blocks.map((block: any) => ({
    ulid: '',
    patient: '',
    dateOfAcquisition: dateOfAcquisition ? new Date(dateOfAcquisition)?.toISOString() : null,//new Date()?.toISOString(),
    organization: doctorOrganization,
    organizationCustom: doctorCustomOrganization,
    source,
    type: 'Block',
    material: {
      organ: block.organ,
      blockCypher: [{
        organization,
        organizationCustom,
        startCode: standardNumbering ? block.startCode : null,
        endCode: standardNumbering ? block.endCode : null,
        amount: standardNumbering ? block.amount : null,
        year: standardNumbering ? +block.year : null,
        code: standardNumbering ? block.code : block.codeNumber,
      }],
      slides: block.glasses.map((slide: any) => ({
        ulid: '',
        patient: '',
        dateOfAcquisition: dateOfAcquisition ? new Date(dateOfAcquisition)?.toISOString() : null,//new Date()?.toISOString(),
        source,
        type: 'Slide',
        material: {
          organ: block.organ,
          slideCypher: [{
            organization,
            organizationCustom,
            startCode: standardNumbering ? slide.startCode : null,
            endCode: standardNumbering ? slide.endCode : null,
            amount: standardNumbering ? slide.amount : null,
            year: standardNumbering ? +slide.year : null,
            code: standardNumbering ? slide.code : slide.codeNumber,
          }],
        }
      }))
    }
  }));

  const bloodDateOfAcquisitionData = bloodDateOfAcquisition && new Date(bloodDateOfAcquisition).toString() !==  "Invalid Date" ? new Date(bloodDateOfAcquisition).toISOString() : '';

  const formattedBlood = bloodId ? {
    ulid: '',
    patient: '',
    dateOfAcquisition: bloodDateOfAcquisitionData,
    organization: doctorOrganization,
    organizationCustom: doctorCustomOrganization,
    source,
    type: 'Blood',
    material: {
      bloodId: transformBloodId(bloodId),
      organization,
      organizationCustom,
    }
  } : null;

  setToSessionStorage([BIO_MATERIAL.source, source], [BIO_MATERIAL.organization, organization], [BIO_MATERIAL.organizationCustom, organizationCustom],
    [BIO_MATERIAL.doctorOrganization, doctorOrganization],[BIO_MATERIAL.doctorCustomOrganization, doctorCustomOrganization],
    [BIO_MATERIAL.dateOfAcquisition, dateOfAcquisition ? new Date(dateOfAcquisition).toISOString() : ''], [BIO_MATERIAL.numbering, numbering], [BIO_MATERIAL.numbering, numbering],
    [BIO_MATERIAL.bloodDateOfAcquisition, bloodDateOfAcquisitionData], [BIO_MATERIAL.blocksChecked, `${!!blocks.length}`],
    [BIO_MATERIAL.bloodChecked, `${!!bloodDateOfAcquisition}`], [BIO_MATERIAL.bloodId, bloodId], [BIO_MATERIAL.blocks, JSON.stringify(formattedBlocks)]
  );

  const formattedData = [
    ...formattedBlocks,
  ];
  formattedBlood && formattedData.push(formattedBlood);

  dispatch(setBiomaterialData(formattedData));
};

export const setReferralMarkers = (data: number[]) => (dispatch: Dispatch<unknown>) => {
  const formattedData = {
    markerPriority: data,
    status: 'Выбран создателем направления'
  };
  dispatch(
    setCommentary([
      {"text": "Some comment"},
      {"text": "Another comment"}
    ])
  );
  dispatch(setMarkers(formattedData));
  dispatch(setReferralStatus('bio_pending'));
};
