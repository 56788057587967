import { createSlice } from '@reduxjs/toolkit';
import { Patient } from './model';
import { ErrorsType, Nullable } from '../../types';

export interface IPatientInitialState {
  loading: boolean;
  patients: Nullable<Patient[]>;
  patient: Nullable<Patient>;
  errors: Nullable<ErrorsType>;
}

export const initialState: IPatientInitialState = {
  loading: false,
  patients: null,
  patient: null,
  errors: null,
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    getPatient: state => {
      state.loading = true;
    },
    getPatientsSuccess: (state, { payload }) => {
      state.patients = payload;
      state.loading = false;
      state.errors = null;
    },
    getPatientSuccess: (state, { payload }) => {
      state.patient = payload;
      state.loading = false;
      state.errors = null;
    },
    getPatientFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    updatePatient: state => {
      state.loading = true;
    },
    updatePatientSuccess: (state, { payload }) => {
      state.patient = payload;
      state.loading = false;
      state.errors = null;
    },
    updatePatientFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    resetPatient: (state) => {
      state.patient = null;
      state.loading = false;
    },
    resetPatients: (state) => {
      state.patients = null;
      state.patient = null;
      state.loading = false;
      state.errors = null;
    }
  },
});

export const {
  getPatient,
  getPatientSuccess,
  getPatientsSuccess,
  getPatientFailure,
  updatePatient,
  updatePatientSuccess,
  updatePatientFailure,
  resetPatient,
  resetPatients,
} = patientSlice.actions

export default patientSlice.reducer;
