import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Table, TBody, Thead } from "../../../components/shared/BiomaterialTable";
import { FLOW_STEPS_STATUSES } from "../../../fixtures/StepsMolecularProfilingPage";
import editIcon from '../../../icons/edit-pencil.svg';
import cancelIcon from '../../../icons/cross-red.svg';
import Select from "react-select";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { getMarkerTypes } from "../../../store/markers/selectors";
import { fetchServiceUsers } from "../../../store/serviceUsers/thunkActions";
import { fetchMethodTypeList } from "../../../store/markers/thunkActions";
import { useDispatch, useSelector } from "react-redux";
import { checkPermitViewExperement, getTokens, getUser } from "../../../store/auth/selectors";
import { getServiceUsers } from "../../../store/serviceUsers/selectors";
import { Examination, IBiologicalMaterial, IRegistryMarkerList, IStepReferral } from "../../../store/molecularProfiling/model";
import defaultTheme from "../../../styles/theme";
import { TBtnWr, TIndicator, TMenuIcon, TRowWR, TWrapper, customStylesOptions, TSendingErrorMessage, CustomButton } from "./styled";
import { fetchRegistryMarkerList, putExaminationBlueprintsUpdate } from "../../../store/molecularProfiling/thunkActions";
import { resetMolecularProfiling } from "../../../store/molecularProfiling";
import { getMolecularProfilingErrors, registryMarkerList } from "../../../store/molecularProfiling/selectors";
import { Link, useParams } from "react-router-dom";
import { TDateOfCompletion, TRowWr } from "../MarkersValidation/styled";
import { UserRegistryPermissions } from "../../../store/auth/model";
import NoDataToShow from "../NoDataToShow";
import { format } from "date-fns";

interface IComponentProps {
  data?: any;
  [index: string]: any;
}

export interface IBlueprint {
  id: number;
  bluprintNumber?: string;
  examination: number;
  marker: string;
  bio?: any;
  method?: any;
  executor: string;
  status: string;
}



interface IMarkersType {
  id: number;
  ulid: string;
  name: string;
  description: string;
}

export const OptionValue: { [index: string]: string; } = {
  null: 'Метод не удалось опледелить',
  undefined: 'Метод не удалось опледелить',
  '': 'Метод не удалось опледелить',
  'PCR': 'ПЦР',
  'Sanger sequencing': 'Секвениров-е по Сэнгеру',
  'IHC': 'ИГХ',
  'FISH': 'FISH',
  'Fragment analysis': 'Фрагментный анализ',
}

export const statusLocale: { [index: string]: any; } = {
  'New':        { rus: 'Новое',     code: 'New'       },
  'Complete':   { rus: 'Завершено', code: 'COMPLETE'  },
  'Cancelled':  { rus: 'Отменено',  code: 'CANCELLED' },
  'Cancel':     { rus: 'Отменено',  code: 'CANCELLED' },
  'In Progress': { rus: 'В процессе', code: 'IN_PROGRESS' },
}

function shortName(fName: string, lName: string, mName: string) {
  let lastName = lName ? lName[0].toUpperCase() + '.' : '';
  let midName = mName ? mName[0].toUpperCase() + '.' : '';
  return `${fName} ${lastName} ${midName}`;
}

export const methodsForUrl: { [index: number]: string; } = {
  1: 'ihc',
  2: 'fish',
  3: 'pcr',
  4: 'ss',
  5: 'fa',
}


const AnalysisExecution: FC<IComponentProps> = (data) => {
  const { control, handleSubmit, reset, getValues, trigger, formState: { errors }, clearErrors } = useForm();
  const { fields, append } = useFieldArray({ control, name: "examination" });

  //Permissions
  const ableToViewExperement = useSelector(checkPermitViewExperement);

  const dispatch = useDispatch();
  const { id: referralULID } = useParams();
  const tokens = useSelector(getTokens);
  const currentUser = useSelector(getUser);
  const getErrors = useSelector(getMolecularProfilingErrors);
  const markersType: IMarkersType[] = useSelector(getMarkerTypes);
  const markerMachedList: IRegistryMarkerList[] | null = useSelector(registryMarkerList);

  //Ger user profile
  const doctorsList = useSelector(getServiceUsers);

  const [blueprints, setBlueprints] = useState<IBlueprint[]>([]);
  const [rowIndexEdit, setEdit] = useState<boolean | number>(false);
  const [requestError, setRequestError] = useState<null | any>();
  const [isSaveExamination, setSaveExamination] = useState<boolean>(false);

  const isMedicalReportReady = useMemo(() => {
    const examinationBlueprints = data?.examinationReferral?.examinationBlueprints;
    let cancellation = data?.statusCancellation;
    if (!examinationBlueprints?.length || cancellation) return false;

    return examinationBlueprints.every((blueprint: Examination) => !!blueprint?.status && ['Cancelled', 'Complete'].includes(blueprint.status))
  }, [data]);

  const tableHeaders: { id?: number; title: string; }[] = useMemo(() => {
    let ableToEdit = data?.ableToUdateREFERRAL?.updateExamination;
    let headers = [
      { id: 1, title: 'Номер' },
      { id: 2, title: 'Маркeр' },
      { id: 3, title: 'Биоматериал' },
      { id: 4, title: 'Метод' },
      { id: 5, title: 'Исполнитель' },
      { id: 6, title: 'Статус' }
    ]
    if (ableToEdit && !isMedicalReportReady) headers.push({ id: 7, title: '' });
    return headers;
  }, [data?.ableToUdateREFERRAL?.updateExamination,isMedicalReportReady]);

  useEffect(() => {
    if (isSaveExamination) {
      setSaveExamination(false);

      if (!!getErrors) {
        setRequestError(getErrors);
        setTimeout(() => {
          setRequestError(null);
          setEdit(false)
          dispatch(resetMolecularProfiling());
        }, 3000);

      } else {
        dispatch(resetMolecularProfiling());
      }
    }

  }, [getErrors, reset, dispatch, setEdit, setRequestError, isSaveExamination]);


  //load doctors list if this stage is complite
  useLayoutEffect(() => {
    if (!data?.status) return;
    if (FLOW_STEPS_STATUSES[data.status] === 4 && tokens?.access) {
      dispatch(fetchServiceUsers(tokens?.access, { organizationId: 0 })); //0 - all saved data
      dispatch(fetchMethodTypeList(tokens?.access));
      dispatch(fetchRegistryMarkerList(tokens?.access));
    }
  }, [data, dispatch, tokens]);

  useLayoutEffect(() => {
    if (!data?.status) return;

    if (data?.referralMarkers) {
      if (data?.examinationReferral?.examinationBlueprints) {
        const rawData = data?.examinationReferral?.examinationBlueprints;
        const Blueprints = rawData.map((blueprint: any) => {
          let bluprintNumber = `${blueprint.id}`.padStart(6, "0").replace(/(?=(?:.{3})*$)/g, ' ');
          return {
            ...blueprint, bluprintNumber
          };
        })
        setBlueprints(Blueprints ?? []);
      }
    }
  }, [data, setBlueprints]);


  const dateOfCompletion = useMemo(() => {

    if (!data?.stepReferral || !data?.stepReferral?.length) return '';
    let bioComliteObj: IStepReferral = data.stepReferral.find((step: IStepReferral) => step.status === 'analysis_done');
    if (!bioComliteObj) return '';

    let { dateSaved } = bioComliteObj;

    //Completion date
    return format(new Date(dateSaved), 'dd.MM.yyyy - HH:mm:ss');
  }, [data]);

  const [methodOptions, methodList] = useMemo(() => {
    if (!markersType) return [{}, {}];
    //get available markers
    let availableMarkers: string[] = blueprints.map((bluprint: IBlueprint) => bluprint.marker);
    //collection marker by metod option
    let optionSet: { [index: string]: any; } = {};
    //sort methods by id

    let methodTypeList = [...markersType ?? []].sort((a, b) => a.id - b.id) ?? [];
    //set collection
    for (let markerVariant of markerMachedList ?? []) {

      if (availableMarkers.includes(markerVariant?.name)) {
        optionSet[markerVariant?.name] = []
        markerVariant?.methods?.forEach((method: number) => {
          let selectedMethod: IMarkersType = methodTypeList[(method - 1)];
          if (!selectedMethod) return;
          optionSet[markerVariant?.name].push({ id: selectedMethod.id, value: selectedMethod.id, label: OptionValue[selectedMethod.name], disabled: false })
        })
      }
    }

    //get marker name by id for statuses other than new
    let nameByid: { [index: string]: any; } = {};
    for (let markerType of methodTypeList) {
      nameByid[markerType.id] = OptionValue[markerType.name];
    }

    return [optionSet, nameByid];
  }, [markersType, markerMachedList, blueprints]);

  const [executorOptions, defaultExecutorOptions] = useMemo(() => {
    if (!doctorsList) return [];
    const currentUserPermit: UserRegistryPermissions | undefined = currentUser?.userRegistryPermissions;
    const isCurrentUserExecutor = currentUserPermit?.morphologistExecutor || currentUserPermit?.geneticistExecutor;

    //find geneticist Executor and morphologist Executor
    let options: any[] = [];
    let staffUlidArr: string[] = [];

    let optionsDefaultList = doctorsList.filter((user: any) => {
      let userPermissions = user?.userRegistryPermissions ?? {}
      if(user?.isStaff) staffUlidArr.push(user.ulid);
      

      return userPermissions?.morphologistExecutor || userPermissions?.geneticistExecutor || userPermissions?.geneticistCoordinator || user?.isStaff;
    }).map((genetic: any) => {
      let { firstName, lastName, middleName } = genetic.userProfile;
      let label = shortName(lastName, firstName, middleName);
      
      return { value: genetic.ulid, label }
    })?.sort((s1:{value:string, label:string}, s2:{value:string, label:string}) => s1.label.localeCompare(s2.label));

    if (isCurrentUserExecutor) {
      options = optionsDefaultList.filter((option: { value: string; label: string; }) => option.value === currentUser?.ulid);
    }

    if (!isCurrentUserExecutor) {
      options = optionsDefaultList;
    }

    let defaultExecutorMap: { [index: string]: any; } = {};

    optionsDefaultList.forEach((option: any) => {
      defaultExecutorMap[option.value] = option.label;
    });

    options = options.filter((option: { value: string; label: string; }) => !staffUlidArr.includes(option.value));

    return [options, defaultExecutorMap];
  }, [doctorsList, currentUser]);

  const [bioMaterialOptions, defaultBioOptions] = useMemo(() => {
    if (!data?.biologicalMaterial || !data?.molecularProfilingReferral) return [];

    let blood, k1, k2, k3;
    const options = [];
    const biologicalMaterial: IBiologicalMaterial[] = data.biologicalMaterial;

    for (let bio of biologicalMaterial) {
      if (bio.type === 'Blood') {
        blood = bio.ulid;
        continue;
      }

      for (let blockSublocks of bio?.material?.blockSublocks ?? []) {
        if (!blockSublocks.reviewed) continue;

        let priority = blockSublocks?.priority;
        if (priority === 1) k1 = blockSublocks.bioUlid;
        if (priority === 2) k2 = blockSublocks.bioUlid;
        if (priority === 3) k3 = blockSublocks.bioUlid;
      }
    }

    if (!!k1) options.push({ value: 1, label: 'блок К1', disabled: false, ulid: [k1] });
    if (!!k2) options.push({ value: 2, label: 'блок К2', disabled: false, ulid: [k2] });
    if (!!k3) options.push({ value: 3, label: 'блок К3', disabled: false, ulid: [k3] });
    if (!!k1 && !!blood) options.push({ value: 4, label: 'блок К1 + кровь', disabled: false, ulid: [k1, blood] });
    if (!!k2 && !!blood) options.push({ value: 5, label: 'блок К2 + кровь', disabled: false, ulid: [k2, blood] });
    if (!!k3 && !!blood) options.push({ value: 6, label: 'блок К3 + кровь', disabled: false, ulid: [k3, blood] });
    if (!!blood) options.push({ value: 7, label: 'кровь', disabled: false, ulid: [blood] });

    let defaultBioMap: { [index: string]: any; } = {};

    options.forEach((option: any) => {
      defaultBioMap[option.ulid.join('')] = option.label;
    })

    return [options, defaultBioMap];
  }, [data]);

  const handlerEditRow = useCallback((rowIndex: number) => {
    if (rowIndex === rowIndexEdit) {
      reset({}, { keepValues: false })
      return setEdit(false)
    }
    setEdit(rowIndex)
    reset({}, { keepValues: false })
  }, [rowIndexEdit, setEdit, reset]);

  //send examination to server
  const handleSaveExamination = useCallback(async (formData: any) => {
    if (rowIndexEdit === false) return;
    const examData = formData.examination[+rowIndexEdit];
    let bio = examData.bio.ulid;
    let marker = examData.marker;
    let method = examData.method.id;
    let executor = examData.executor.value;
    let updateId = examData.examination;
    let trigger_new_blueprint = false;

    if (tokens?.access) {
      try {
        await dispatch(putExaminationBlueprintsUpdate(tokens?.access, updateId, { bio, marker, method, executor, trigger_new_blueprint }));
        setSaveExamination(true);
      } catch (e) {
        console.log(e)
      }
    }

  }, [rowIndexEdit, tokens, dispatch, setSaveExamination]);

  useEffect(() => {
    if (!blueprints || !blueprints?.length || fields.length) return;

    for (let blueprint of blueprints) {

      append({
        id: blueprint.id,
        bio: blueprint.bio,
        marker: blueprint.marker,
        method: blueprint.method,
        status: blueprint.status,
        executor: blueprint.executor,
        examination: blueprint.id,
        bluprintNumber: blueprint.bluprintNumber,
      })
    }

  }, [blueprints, fields, append]);

  const linkToExamination = useCallback((num: string, meth: number, mark: string) => {
    let examinationPath = parseInt(num.trim().replace(' ', '')) + '_' + methodsForUrl[meth] + '_' + mark.toLowerCase().replace('/', '#')
    return `/molecular-profiling/detail/${referralULID}/${examinationPath}`
  }, [referralULID]);


  if (FLOW_STEPS_STATUSES[data?.status] < 4) return (<></>);

  const customFilter = (option: any, searchText: any) => {
    if (searchText.match('\\[|\\]|\\\\|\\^|\\$|\\.|\\||\\?|\\*|\\+|\\(|\\)')) return false
    if (!searchText) return true;
    const template = `^${searchText}`;
    return option.data.label.match(template);
  };

  return !!fields?.length ? (
    
    <TWrapper onSubmit={handleSubmit(data => handleSaveExamination(data))}>
      <TRowWr direction="start">
        {!!dateOfCompletion && <TDateOfCompletion>{`Дата завершения: ${dateOfCompletion}`}</TDateOfCompletion>}
      </TRowWr>
      <Table>
        <Thead>
          {tableHeaders?.map((item: { id?: number; title: string; }) => <th className="violet" key={item.id}>{item.title}</th>)}
        </Thead>
        <TBody>
          {fields.map((field, index) => {
            let exam = getValues(`examination.${index}`);
            let examErrors = errors?.examination?.[index];
            return (
              <tr key={field.id + `${index}`}>
                <td className="left">{exam.status === 'New' || !ableToViewExperement ? exam.bluprintNumber : <Link to={linkToExamination(exam.bluprintNumber, exam.method, exam.marker)} >{exam.bluprintNumber}</Link>}</td>
                <td className="left">{exam.marker}</td>
                <td className={examErrors?.bio ? 'error left' : 'left'}>
                  {rowIndexEdit !== index && (defaultBioOptions?.[((exam?.bio?.[0]?.ulid ?? '') + (exam?.bio?.[1]?.ulid ?? ''))] ?? '--')}
                  {rowIndexEdit === index &&
                    <Controller
                      control={control}
                      name={`examination.${index}.bio` as const}
                      rules={{ required: true }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          onChange={async (e) => {
                            clearErrors(`examination.${index}.bio`);
                            await trigger([`examination.${index}.method`, `examination.${index}.executor`]);
                            return onChange(e)
                          }}
                          selected={value}
                          options={bioMaterialOptions}
                          classNamePrefix='select'
                          placeholder={'--'}
                          isOptionDisabled={(option: any) => option.disabled}
                          inputRef={ref}
                          noOptionsMessage={() => "нет опций"}
                          styles={customStylesOptions(defaultTheme)}
                        />
                      )}
                    />}
                </td>
                <td className={examErrors?.method ? 'error left' : 'left'}>
                  {rowIndexEdit !== index && (methodList?.[exam.method] ?? '--')}
                  {rowIndexEdit === index &&
                    <Controller
                      control={control}
                      name={`examination.${index}.method` as const}
                      rules={{ required: true }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          onChange={async (e) => {
                            clearErrors(`examination.${index}.method`);
                            await trigger([`examination.${index}.bio`, `examination.${index}.executor`])
                            return onChange(e)
                          }}
                          selected={value}
                          options={methodOptions?.[exam.marker] ?? { id: 0, value: 0, label: '--', disabled: true }}
                          classNamePrefix='select'
                          placeholder={'--'}
                          isOptionDisabled={(option: any) => option.disabled}
                          inputRef={ref}
                          noOptionsMessage={() => "нет опций"}
                          styles={customStylesOptions(defaultTheme)}
                        />
                      )}
                    />}
                </td>
                <td className={examErrors?.executor ? 'error left' : 'left'}>
                  {rowIndexEdit !== index && (defaultExecutorOptions?.[exam?.executor] ?? '--')}
                  {rowIndexEdit === index &&
                    <Controller
                      control={control}
                      name={`examination.${index}.executor` as const}
                      rules={{ required: true }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          onChange={async (e) => {
                            clearErrors(`examination.${index}.executor`);
                            await trigger([`examination.${index}.bio`, `examination.${index}.method`])
                            return onChange(e)
                          }}
                          selected={value}
                          options={executorOptions}
                          classNamePrefix='select'
                          placeholder={'--'}
                          isOptionDisabled={(option: any) => option.disabled}
                          inputRef={ref}
                          noOptionsMessage={() => "нет опций"}
                          styles={customStylesOptions(defaultTheme)}
                          filterOption={(option, value) => customFilter(option, value)}
                        />
                      )}
                    />}
                </td>
                <td className="left">
                  <TRowWR>
                    <TIndicator className={statusLocale[exam.status].code}></TIndicator>
                    <p>{statusLocale[exam.status].rus ?? '--'}</p>
                  </TRowWR>
                </td>
                {data?.ableToUdateREFERRAL?.updateExamination && !isMedicalReportReady && <td>
                  <TMenuIcon
                    src={rowIndexEdit !== index ? editIcon : cancelIcon}
                    disabled={exam.status !== "New"}
                    onClick={() => exam.status === "New" && handlerEditRow(index)}
                  />
                </td>}
              </tr>
            )
          }
          )}
        </TBody>
      </Table>
      {data?.ableToUdateREFERRAL?.updateExamination && !isMedicalReportReady && (
        <TBtnWr>
          <CustomButton type="submit" disabled={!!errors?.examination || rowIndexEdit === false} >Сохранить изменения
            {!!requestError && <TSendingErrorMessage> <i>Ошибка!</i> {requestError?.error} </TSendingErrorMessage>}
          </CustomButton>
        </TBtnWr>
      )}
    </TWrapper>
  ) : <NoDataToShow description="Исследования не проводились"/>
}

export default AnalysisExecution;