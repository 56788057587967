import { createSelector } from '@reduxjs/toolkit';
import { IAppState } from '../';

const getState = (state: IAppState) => state.serviceUsers;

export const getServiceUsersLoading = createSelector(getState, state => state.loading);

export const getServiceUsers = createSelector(getState, state => state.serviceUsers);

export const getSelectedServiceUser = createSelector(getState, state => state.selectedServiceUser);

export const getSelectedServiceUserData = createSelector(getState, state => {
  if(state.serviceUsers?.length && state?.selectedServiceUser){
   return state.serviceUsers?.find(v => (v?.ulid ?? '') === (state?.selectedServiceUser ?? ''))
  }
  return null;
});
