import styled from 'styled-components';

interface ITTextarea {
  error?: boolean | null;
  height?: number;
  bordered?: boolean;
  disabled?:boolean;
}

export const TTextareaWr = styled.div<ITTextarea>`
  width: 100%;
  border-radius: 4px;
  position: relative;
  font-size: 14px;
  line-height: 18px;

  textarea {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    resize: none;
    padding: 18px 32px 18px 16px;
    width: 100%;
    height: ${({ height = 132 }) => height}px;
    border: 1px solid ${({ theme, bordered, error }) => (error ? theme.colors.alert.warning : bordered ? theme.colors.gray[400] : theme.colors.white)};
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme, disabled }) => disabled ? theme.colors.gray[500] : theme.colors.black[200]};
    box-sizing: border-box;
    word-wrap: break-word;
    display: inline-block;

    &:focus, &:focus-visible, &:hover:not(:disabled) {
      border-color: ${({ theme, error }) => error ? theme.colors.alert.warning : theme.colors.alert.border};
      outline: none;
    }

    &::placeholder {
      color: ${({theme}) => theme.colors.gray[200]};
      opacity: 1;
    }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
  input[type="number"] {
    
  }
  :hover,:focus {
    -moz-appearance: number-input;
  }

  &:read-only:hover {
    cursor:default;
    border-color: ${({theme}) => theme.colors.gray[400]};
  }
  }
`;

export const TLabel = styled.label`
  /* margin-bottom: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[500]}; */
  /* margin-top: 3px; */
  margin-bottom: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${({theme}) => theme.colors.gray[500]};
  display:block;
`;

export const TNotEditedTextArea = styled.div<{bordered?:boolean, error?:boolean, height?:number}>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.gray[500]};
  border: 1px solid ${({ theme, bordered, error }) => (error ? theme.colors.alert.warning : bordered ? theme.colors.gray[400] : theme.colors.white)};
  box-sizing: border-box;
  word-wrap: break-word;
  display: inline-block;
  padding: 18px 32px 18px 16px;
  border-radius: 10px;
  width: 100%;
  min-height: ${({ height }) => !!height ? `${height}px` : 'min-content'};
`;
