import { FC, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TInputsWr } from './styled';
import Input from '../../shared/Input';
import { validations } from '../../../utils/validations';
import Radio from '../../shared/Radio';
import { Profile } from '../../../store/auth/model';
import InputSelect from '../../shared/InputSelect';
import { useSelector } from 'react-redux';
import {
  getOrganization,
  getOrganizationsSelectOptions,
} from '../../../store/organizations/selectors';
import { TSubTitle, TTitle } from '../../../containers/CreateReferralPage/styled';

interface IDoctorReferralForm {
  profile: Profile | null;
  userEmail: string;
}

const currentDateObj = new Date();
const dateToCreateNewReferral = currentDateObj.toLocaleDateString();
const PAID_PAYMENT_VALUE = 'Paid';
const FREE_PAYMENT_VALUE = 'Free';
const COMMON_SPEED_VALUE = 'Common';
const URGENT_SPEED_VALUE = 'Urgent';

const DoctorReferralForm: FC<IDoctorReferralForm> = ({ profile, userEmail }) => {
  const { setValue, clearErrors, register, control } = useFormContext();

  const organizationOptions = useSelector(getOrganizationsSelectOptions);
  const organization = useSelector(getOrganization(profile!.organization));

  const departmentOptions = useMemo(() =>
    organization?.organizationDepartment.map(
      ({ id, nameLocalRus }) => ({ value: id, label: nameLocalRus })
    ) ?? [], [organization]);

  useEffect(() => {
      setValue('organization', !!profile?.organization ? profile?.organization : '');
      setValue('department', !!profile?.department ? profile?.department : '');
      setValue('subdepartment', !!profile?.subdepartment ? profile?.subdepartment : '');
      setValue('fullName', `${profile?.lastName} ${profile?.firstName} ${profile?.middleName}`);
      setValue('phoneNumber', !!profile?.phoneNumber ? profile?.phoneNumber : '');
      setValue('email', !!userEmail ? userEmail : '');
      setValue('creationDate', currentDateObj);
      setValue('payment', FREE_PAYMENT_VALUE);
      setValue('speed', COMMON_SPEED_VALUE);
      clearErrors();
    },
    [
      clearErrors,
      setValue,
      profile?.organization,
      profile?.department,
      profile?.lastName,
      profile?.firstName,
      profile?.middleName,
      profile?.phoneNumber,
      userEmail,
      profile?.subdepartment
    ]
  );

  if (!profile) {
    return null;
  }

  return (
    <>
      <TTitle>Данные направителя</TTitle>
      <Controller
        control={control}
        name='organization'
        rules={validations.organization}
        render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
          <InputSelect
            disabled
            inputRef={ref}
            onChange={(value) => onChange(value)}
            value={value}
            options={organizationOptions}
            placeholder='Учреждение здравоохранения'
            error={invalid}
            marginTop={2}
          />
        )}
      />
      {
        profile?.department && (
          <Controller
            control={control}
            name='department'
            rules={validations.department}
            render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
              <InputSelect
                disabled
                inputRef={ref}
                onChange={(value) => onChange(value)}
                value={value}
                options={departmentOptions}
                placeholder='Отделение'
                error={invalid}
                marginTop={2}
              />
            )}
          />
        )
      }
      {profile?.subdepartment && (
        <Input
          disabled
          {...register('subdepartment', validations.subdepartment)}
          placeholder='Лабораторное отделение/Кабинет'
          marginTop={2}
        />
      )}
      <Input
        disabled
        {...register('fullName', validations.fullName)}
        placeholder='ФИО'
        className='mb40'
        marginTop={2}
      />
      <TTitle>Дата фактического направления на исследование</TTitle>
      <Input value={dateToCreateNewReferral} readOnly disabled className='mb40'/>
      <TSubTitle>Форма обслуживания</TSubTitle>
      <TInputsWr>
        <Controller
          control={control}
          name='payment'
          rules={validations.payment}
          render={({ field: { onChange, value } }) => (
            <>
              <Radio selected={value} value={PAID_PAYMENT_VALUE} label='Платно' onChange={onChange} />
              <Radio selected={value} value={FREE_PAYMENT_VALUE} label='Бесплатно' onChange={onChange} />
            </>
          )}
          />
      </TInputsWr>
      <TSubTitle>Приоритет</TSubTitle>
      <TInputsWr>
        <Controller
          control={control}
          name='speed'
          rules={validations.speed}
          render={({ field: { onChange, value } }) => (
            <>
              <Radio selected={value} value={URGENT_SPEED_VALUE} label='Срочно' onChange={onChange} />
              <Radio selected={value} value={COMMON_SPEED_VALUE} label='Планово' onChange={onChange} />
            </>
          )}
        />
      </TInputsWr>
    </>
  );
};

export default DoctorReferralForm;
