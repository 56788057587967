export const T_CATEGORY_LIST = ['TX', 'T0', 'Tis', 'T1', 'T2', 'T3', 'T4'];
export const N_CATEGORY_LIST = ['NX', 'N0', 'N1', 'N2', 'N3'];
export const M_CATEGORY_LIST = ['MX', 'M0', 'M1'];
export const SUB_LIST = ['a', 'b', 'c', '--'];
export const STAGE_LIST = ['I','II','III','IV']

export const CATEGORY_TYPES = {
  TUMOUR: 'tumour',
  NODE: 'node',
  METASTATIC_SPREAD: 'metastatic_spread',
}
