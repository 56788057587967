import { Dispatch } from 'react';
import { AlertTypes } from './model';
import { createAlert, removeAlert } from '.';

export const addAlert = (type:AlertTypes, title:string, message: string) => async (dispatch: Dispatch<unknown>) => {
  let data = {type,title,message}
  dispatch(createAlert(data));
}
export const delAlert = (id:number) => async (dispatch: Dispatch<unknown>) => {
  dispatch(removeAlert(id));
}
