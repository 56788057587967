import styled from 'styled-components';

export const TWrapper = styled.div``;

export const TFormContainer = styled.form`
  /* h3 {
    margin-bottom: 16px;
  }
  h4 {
    margin-bottom: 10px;
  } */
  /* & > *:last-child {
    margin-bottom: 0;
  } */
`;

export const THint = styled.div<{focused?: boolean}>`
  position: absolute;
  display: ${({ focused }) => focused ? 'flex' : 'none'};
  flex-direction: column;
  align-items: start;
  width: 237px;
  /* width: max-content; */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray[400]};
  top: -36px;
  right: 0px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 12px;
  border-radius: 10px;
  li {
    margin-left: 12px;
    b {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray[500]};
    }
  } 
  ::before {
    content: '';
    display: block;
    position: absolute;
    bottom:-8px;
    right: calc(50% - 10px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 14px 0 10px;
    border-color: #fff transparent transparent transparent;
  }
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.07), 1px -1px 6px 0px rgba(0, 0, 0, 0.07);
`;

export const TButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
`;

export const TInputsWr = styled.div`
  display: flex;

  & > * {
    margin-right: 3px;
    width: 50%;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const TInfo = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray[500]};
  padding: 22px 0;
  margin: 0;
`;

export const TFormWrapper = styled.div`
  min-height: 470px;
`
