import { FC } from 'react';
import { columnsReferral } from './ReferallDataTable/constant';
import {TTable} from "./styled";

interface IReferralData {
  patientReferrals?: IReferralData[];
  hasPermit?:boolean;
}


const ReferralData: FC<IReferralData> = ({patientReferrals,hasPermit}) => {

  if (patientReferrals === undefined) {
    return null
  }

  return (
    <div style={{paddingBottom:'90px'}}>
      <TTable items={patientReferrals} columns={columnsReferral()} hasPermit={hasPermit}/>
    </div>
  );
};

export default ReferralData;
