import { FC, useCallback, useState } from 'react';
import { Patient } from '../../../../../store/patient/model';
import {
  TBlockID, TBlockInfo,
  TBlockPatientInfo,
  TButtonChoosePatientWrapper,
  TFoundedPatient,
  TSelectPatientBlockWr
} from './styled';
import { Nullable } from '../../../../../types';
import Button, { SIZE, VARIANT } from '../../../../../components/shared/Button';
import { TRowWr, TTitlePatientData } from '../styled';

interface ISelectPatientBlock {
  patients: Patient[];
  setSelectedPatient: (patient: Patient) => void;
}

const SelectPatientBlock: FC<ISelectPatientBlock> = ({ patients, setSelectedPatient }) => {
  const [selected, setSelected] = useState<Nullable<Patient>>(null);

  const selectPatient = useCallback(() => {
    selected && setSelectedPatient(selected);
  }, [setSelectedPatient, selected]);

  return (
    <TSelectPatientBlockWr>
      <TRowWr direction={"flex-start"}>
        <TTitlePatientData>Найдено несколько пациентов</TTitlePatientData>
      </TRowWr>
      
      {patients.map((patient,index) => (
        <TFoundedPatient key={`${index}_${patient.idPassport}`} selected={selected?.idPassport === patient.idPassport} onClick={() => setSelected(patient)}>
          <TBlockPatientInfo>
            <TBlockID>{patient.idPassport}</TBlockID> <TBlockInfo>{patient?.firstName} {patient?.middleName} {patient?.lastName}</TBlockInfo></TBlockPatientInfo>
        </TFoundedPatient>
      ))}
      <TButtonChoosePatientWrapper>
        <Button disabled={!selected} size={SIZE.SMALL}  variant={VARIANT.BLUE} onClick={selectPatient}>Выбрать пациента</Button>
      </TButtonChoosePatientWrapper>
    </TSelectPatientBlockWr>
  );
}

export default SelectPatientBlock;
