import { FC } from 'react';
import { TInfoBlockTitle, TInfoBlockValue } from './styled';

interface IInfoBlock {
  title: string;
  value: string;
}

const InfoBlock: FC<IInfoBlock> = ({ title, value }) => {
  return (
    <div>
      <TInfoBlockTitle>{title}</TInfoBlockTitle>
      <TInfoBlockValue>{value}</TInfoBlockValue>
    </div>
  );
}

export default InfoBlock;
