import styled from 'styled-components';

const DEFAULT_DAY_COLOR = '#19181A';

export const TWrapper = styled.div<{disabled?: boolean}>`
  margin-top: 2px;
  .datePickerCalendar {
    border-radius: 10px;
    border: solid 1px ${({ theme }) => theme.colors.alert.border};
    overflow: hidden;
    width: 344px;
    height: 368px;
    display: flex;
    justify-content: center;
    
    .react-datepicker__day, .react-datepicker__day-name {
      font-size: 13px;
      line-height: 18px;
      padding: 11px 8px;
      width: 40px;
      box-sizing: border-box;
      margin: 0;
      color: ${DEFAULT_DAY_COLOR};
      border: 1px solid transparent;
    }
    .react-datepicker__day--keyboard-selected {
      background-color: #fff;
      color: ${DEFAULT_DAY_COLOR};
    }
    .react-datepicker__day--today {
      border-color: ${({ theme }) => theme.colors.product.primaryActive};
      font-weight: normal;
    }
    .react-datepicker__day--selected, .react-datepicker__day:hover {
      background-color: ${({ theme }) => theme.colors.product.primaryActive};
      color: #fff;
    }
    .react-datepicker__day, .react-datepicker__day--selected {
      border-radius: 10px 10px 2px 10px;
    }
    .react-datepicker__day--outside-month {
      opacity: 0.4;
    }
    .react-datepicker__day-name {
      font-weight: bold;
      text-transform: capitalize;
    }
    .react-datepicker__header {
      background-color: #fff;
      border: 0;
    }
    .react-datepicker__day--disabled,
    .react-datepicker__day--disabled:hover {
      background-color: #fff;
      opacity: 0.4;
      color: ${DEFAULT_DAY_COLOR};
    }
  }
  .react-datepicker__input-container {
    position: relative;
    box-sizing: border-box;
    border-radius:10px;
    background-color: white;
    height: 62px;
    /* margin-top: 2px; */
    div input {
      position:relative;
      background-color: transparent;
      z-index:1;
    }
    div:last-child {
      svg {
        z-index:5;
        position: absolute;
        right: 3px;
        top: calc(50% - 16px);
        cursor: pointer;
        :hover {
          rect, line{
          stroke: ${({ theme }) => theme.colors.violet[500]};;
        }
        circle, ellipse {
          fill: ${({ theme }) => theme.colors.violet[500]};;
        }}
      }
    }
  }
  .react-datepicker-popper {
    z-index:10;
  }
  button.react-datepicker__close-icon::after {
    content: "×";
    position: absolute;
    right: 5px;
    top: calc(50% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    height: 16px;
    width: 16px;
    font-size: 18px;
    line-height: 0px;
    color: ${({ theme }) => theme.colors.gray[200]};
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    border-radius: 50%;
    z-index: 5;
  }
  button.react-datepicker__close-icon:hover::after{
    color: ${({ theme }) => theme.colors.alert.warning};
    border: 1px solid ${({ theme }) => theme.colors.alert.warning};
  }
`;

export const TLabel = styled.p<{marginBottom?:number}>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[500]};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px` };
`;
