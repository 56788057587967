import { createSlice } from '@reduxjs/toolkit';
import { Tokens, User } from './model';
import { ErrorsType, Nullable } from '../../types';

export interface IAuthInitialState {
  loading: boolean;
  user: Nullable<User>;
  errors: Nullable<ErrorsType>;
  updated: boolean;
  continueSession: boolean;
  tokenExp: Nullable<Number>;
  tokens:Nullable<Tokens>
  isSessionExist: Nullable<Boolean>;
}

export const initialState: IAuthInitialState = {
  loading: false,
  user: null,
  errors: null,
  updated: false,
  continueSession: false,
  tokenExp: null,
  tokens: null,
  isSessionExist: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    request: state => {
      state.loading = true;
    },
    authSessionExistSuccess: (state, { payload }) => {
      state.isSessionExist = payload;
      state.loading = false;
    },
    authSessionExistFailure: (state, { payload }) => {
      state.isSessionExist = false;
      state.loading = false;
      state.errors = payload;
    },
    resetauthSessionExist: (state) => {
      state.isSessionExist = null;
    },
    authorizationSuccess: (state, { payload }) => {
      state.user = payload;
      state.tokens = payload?.tokens ?? null;
      state.loading = false;
      state.continueSession = false;
      state.errors = null;
      state.isSessionExist = null;
    },
    authorizationFailure: (state, { payload }) => {
      state.user = null;
      state.loading = false;
      state.errors = payload;
      state.continueSession = false;
      state.isSessionExist = null;
    },
    continueSessionSuccess: (state, { payload }) => {
      state.user = payload;
      state.tokens = payload.tokens;
      state.loading = false;
      state.continueSession = true;
      state.errors = null;
    },
    setTokenExp: (state, { payload }) => {
      if(!isNaN(payload)) state.tokenExp = +(payload + '000');
      else state.tokenExp = null;
    },
    resetContinueSession: (state) => {
      state.continueSession = false;
    },
    updateProfileReset: (state) => {
      state.updated = false;
      state.loading = false;
      state.errors = null;
    },
    changeProfileRequest:state => {
      state.loading = true;
      state.errors = null;
      state.updated = false;
    },
    changeProfileRequestSuccess: ( state ) => {
      state.loading = false;
      state.errors = null;
      state.updated = true;
    },
    changeProfileRequestFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
      state.updated = false;
    },

    updateProfile:state => {
      state.loading = true;
      state.errors = null;
      state.updated = false;
    },

    updateProfileSuccess: (state, { payload }) => {
      state.user!.profile = payload;
      state.loading = false;
      state.errors = null;
      state.updated = true;
    },
    updateProfileFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
      state.updated = false;
    },

    makeFormRequestSuccess: state => {
      state.user!.userRegistryPermissions.formRequest = true;
      state.loading = false;
      state.errors = null;
    },
  },
});

export const {
  request,
  authSessionExistSuccess,
  authSessionExistFailure,
  resetauthSessionExist,
  authorizationSuccess,
  authorizationFailure,
  continueSessionSuccess,
  resetContinueSession,
  setTokenExp,
  updateProfileReset,
  changeProfileRequest,
  changeProfileRequestSuccess,
  changeProfileRequestFailure,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailure,
  makeFormRequestSuccess,
} = authSlice.actions

export default authSlice.reducer;
