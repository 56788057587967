import styled from 'styled-components';
import Input from "../../../components/shared/Input";
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';

export const TCustomInput = styled(Input)<{ width?: string, background?: string, disabled?:boolean }>`
  width: ${({width}) => width ? width : '100%'};
  padding-bottom: 16px;
  position: relative;
  & > div > div {
    display: ${({disabled}) => disabled ? 'none' : 'flex'};
    right: 0;
    top: -2px;
  }

  & :read-only {
    cursor: default;
  }

  & input {
    padding: 18px;
    background-color: ${({background}) => background ? background : '#fff'};

  }
`

export const TEmptyPersonList = styled.div<{marginTop?:number}>`
  width: 743px;
  padding: 10px 0px 10px 10px;
  color: ${({theme}) => theme.colors.violet[100]};
  border: 1px solid ${({theme}) => theme.colors.violet[100]};
  border-radius: 10px;
  margin-top: ${({marginTop}) => marginTop ? `${marginTop}px` : '0px'};
`;

export const TFormInputWr = styled.div`
  width: 756px;
  display: flex;
  justify-content: space-between;
  align-items: end;
`

export const TErrorList = styled.ul`
  width: 756px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
`


export const TPersonFormWr = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding: 24px 0px 10px;
  gap: 10px;
  width: 756px;

  @-webkit-keyframes fadeIn { 
  0%  { display: none;   opacity: 0;  }
  20% { display: block;  opacity: 0.2;  }
  40% { display: block;  opacity: 0.3;}
  60% { display: block;  opacity: 0.5;}
  80% { display: block;  opacity: 0.9;}
  100% { display: block; opacity: 1;  }
}

@keyframes fadeIn {
  0%  { display: none;   opacity: 0;  }
  20% { display: block;  opacity: 0.2;  }
  40% { display: block;  opacity: 0.3;}
  60% { display: block;  opacity: 0.5;}
  80% { display: block;  opacity: 0.9;}
  100% { display: block; opacity: 1;  }
}

  ul.errors-container {
    list-style: none;
    opacity: 1;
    li {
      color: ${({theme}) => theme.colors.alert.notification};
      pointer-events: none;
   

      display: block;
      -webkit-animation-name: fadeIn;
      -webkit-animation-duration: 1s;
      animation-name: fadeIn;
      animation-duration: 1s;
    }
  }


.enter {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: height 0ms 400ms, opacity 400ms 0ms;
}
.enter-active {
  opacity: 1;
  height: auto;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}
.exit {
  opacity: 1;
  height: auto;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}
.exit-active {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: height 0ms 400ms, opacity 400ms 0ms;
}
`;

export const TPersonFormSelectWr = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  & > div:first-child {
    width: 80%;
  }
`;

export const TPersonFormButton = styled.button<{marginBottom?:number, disabled?:boolean}>`
  background-color: ${({theme,disabled}) => disabled ? 'transparent' : theme.colors.product.primaryActive };
  outline: none;
  border: ${({theme, disabled}) => disabled ? `2px solid ${theme.colors.gray[300]}` : 'none'};
  border-radius: 10px;
  padding: 4px 20px;
  margin-bottom: ${({marginBottom}) => marginBottom ? `${marginBottom}px` : '0px'};;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: ${({theme, disabled}) => disabled ? theme.colors.gray[300] : theme.colors.white};
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer' };
  &:hover {
    background-color: ${({theme, disabled}) => disabled ? 'transparent' : theme.colors.product.primaryHover };
  }

`;

export const TPersonFormManualSelectBtnWr = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({theme}) => theme.colors.gray[500]};
    margin-left:12px;
  }
`;

export const TPersonFormManualInputWr = styled.form`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  label {
    line-height: 35px;
  }
`;


export const TPersonList = styled.ul<{marginTop?:number,active?:boolean,hiddenSroll?:boolean}>`
  position: relative;
  width: 770px;
  box-sizing: border-box;
  max-height: ${({active}) => active ? `${200}px` : 'auto'};
  overflow: ${({hiddenSroll}) => hiddenSroll ? `scroll` : 'hidden'};
  margin: 0;
  padding: 0;
  margin-top: ${({marginTop}) => marginTop ? `${marginTop}px` : '0px'};
  list-style: none;
  box-shadow: 1px 1px 20px rgba(122, 120, 233, 0.2);
  border-radius: 10px;
  box-sizing: border-box;
  & li > :last-child { 
    visibility: ${({active}) => active ? 'visible' : 'collapse'};
    cursor: pointer;
    width: 20px;
    height: 20px;
    padding: 16px;
    border-left: 1px solid gray;
    border-image: linear-gradient(to bottom, transparent, #CECECE, transparent) 1 100%;
    filter: grayscale(90%);
    :hover {
      filter: grayscale(0);
    }
  }
  scroll-behavior: smooth;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: #EFEEFF;
  }

  ::-webkit-scrollbar-thumb {
    background: #B7B5F1;
    box-shadow: inset 0 0 1px #EFEEFF;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  scrollbar-color: #B7B5F1 #EFEEFF;
`;

export const TPersonListElement = styled.li<{active?:boolean}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({theme}) => theme.colors.black[200]};
  padding-left: 14px;
  min-height: 50px;
  height: fit-content;
  width: 756px;
  max-width: 756px;
  :last-child::before {
    background-image: none;
  }

  ::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg,transparent,#CECECE 50%,transparent);
    
  }
  box-sizing: border-box;
`;

export const TPersonListElementText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 700px;
  max-width: 700px;
  span {
    font-weight: 500;
  }
`;

export const TInvertPlusIcon = styled(PlusIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.gray[400]};
  }
`;
