import { FC } from 'react';
import { TTooltip, TTitle, TContent } from './styled';

interface INumberingTooltip {
  standard?: boolean;
}

const NumberingTooltip: FC<INumberingTooltip> = ({ standard }) => {
  return (
    <TTooltip id={standard ? 'standard-numbering-tooltip' : 'nonstandard-numbering-tooltip'} place='top'>
      {standard ? (
        <>
        <TTitle><b>Стандартный номер</b> блока/стекла имеет формат:</TTitle>
        <TContent>
        <ul>
          <li><b>12300/23</b> для единичного номера.</li>
          <li><b>12300-11/23</b> для диапазона.</li>
        </ul>
        </TContent>
        <TTitle>Чтобы зарегистрировать такой номер укажите:</TTitle>
        <TContent>
        <ul>
          <li>начальный номер — 12300</li>
          <li>конечный номер — 12311 (только для диапазона)</li>
          <li>год</li>
        </ul>
        </TContent>
        </>
      ) : (
        <>
          <TTitle><b>Нестандартный номер</b> блока/стекла </TTitle>
          <TContent>может иметь любой уникальный формат, <br /> отличный от стандартного.</TContent>
        </>
      )}
    </TTooltip>
  );
}

export default NumberingTooltip;
