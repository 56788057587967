import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokens } from '../../../store/auth/selectors';
import { format } from 'date-fns';
import { createNextStep, patchMolecularProfilingDataConfirm, postMolecularProfilingComment, postNewBioMaterialNumbers } from '../../../store/molecularProfiling/thunkActions';
import InfoModal from '../../../components/shared/InfoModal';
import Button, { SIZE, VARIANT } from '../../../components/shared/Button';
import { Table, TBody } from '../../../components/shared/BiomaterialTable';
import { TButton } from '../styled';
import {
  CustomButton,
  TBioMaterialDataWr,
  TBtnWr, TCustomError,
  TDateOfCompletion,
  TDescriptionWr,
  TEditButton,
  THeader,
  TRowWr,
  TTableWr,
  TTooltip,
} from './styled';
import { getFromSessionStorage, removeItemSessionStorage, setToSessionStorage } from '../../../utils/sessionStorageHelpers';
import { useLocation } from 'react-router';
import SlideRow from './ui/SlideRow';
import CodeTableCell from './ui/CodeTableCell';
import EditableTableCell from './ui/EditableTableCell';
import { FLOW_STEPS_STATUSES } from '../../../fixtures/StepsMolecularProfilingPage';
import { getMolecularProfilingErrors } from '../../../store/molecularProfiling/selectors';
import Textarea from '../../../components/shared/Textarea';
import { IStepReferral } from '../../../store/molecularProfiling/model';
import { getServiceUsers } from '../../../store/serviceUsers/selectors';
import { fetchServiceUsers } from '../../../store/serviceUsers/thunkActions';
import NotEditedInput from '../../../components/shared/NotEditedInput';

interface IComponentProps {
  data?: any;
  [index: string]: any;
}

interface ITableHeader {
  id?: number;
  title: string;
  class?: string;
  colSpan?: number | undefined;
  rowSpan?: number | undefined;
}

const shortTableHeaders: ITableHeader[] = [
  { id: 1, title: 'Орган', class: 'maxWidth20' },
  { id: 2, title: 'Блоки', class: 'violet' },
  { id: 3, title: 'Гист. стекла', class: 'orange' }
];

const wideTableHeaders: ITableHeader[] = [
  { id: 1, title: 'Орган', class: 'maxWidth20', rowSpan: 2 },
  { id: 2, title: 'Блоки', class: 'violet noBorderRadius', colSpan: 2 },
  { id: 4, title: 'Гист. стекла', class: 'orange', colSpan: 2, },
];

const wideSubHeader: ITableHeader[] = [
  { id: 6, title: 'Оригинальные', class: 'violet noBorderRadius' },
  { id: 7, title: 'Присвоенные *', class: 'violet noBorderRadius' },
  { id: 8, title: 'Оригинальные', class: 'orange noBorderRadius' },
  { id: 9, title: 'Присвоенные', class: 'orange noBorderRadius' }
];

function tooltipData(data: any) {
  if(data?.year) return `${data.startCode}${data.endCode ? ' - ' + data.endCode : ''}, ${data.year}г.`;
  return ''
}

const BioMaterialData: FC<IComponentProps> = (data) => {
  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const path = useLocation();
  const error = useSelector(getMolecularProfilingErrors);
  const doctorsList = useSelector(getServiceUsers);
  // const profile = useSelector(getProfile);

  const [isEditPageActive, setEditPageActive] = useState(false);//trigger edit button
  const [newBioMatNumbers, setNewBioMatNumbers] = useState<{ [key: string | number]: any }>({ block_cyphers: {}, slide_cyphers: {} });
  const [commentary, setCommentary] = useState('');             //commentary
  const [allDataSaved, setAllDataSaved] = useState(false);      //all changes have been saved
  const [isChangesSaved, setChangesSaved] = useState(false);    //changes have been made to the table
  const [toolTipActive, setToolTipActive] = useState('');       //tooltip handler for highlighting icon
  const [showModal, setShowModal] = useState(false);            //trigger show confirm popup
  const [modalCellId, setModalCellId] = useState(null);
  const [blockErrorsArr, setBlockErrorsArr] = useState<string[]>([]);
  const [slideErrorsArr, setSlideErrorsArr] = useState<string[]>([]);
  const [updateKey, setUpdateKey] = useState<number>(Date.now());

  const handlerShowModal = useCallback(() => {
    setShowModal(!showModal)
  }, [showModal]);

  const biologicalMaterial = useMemo(() => data?.biologicalMaterial?.filter((item: any) => item.type === 'Block')?.[0] ?? {}, [data]);
  const blood = useMemo(() => data?.biologicalMaterial?.filter((item: any) => item.type === 'Blood')?.[0] ?? false, [data]);
  const referralULD = useMemo(() => ((path.pathname).split('/')).slice(-2)[0], [path]);
  const currentStatus = useMemo(() => FLOW_STEPS_STATUSES[data?.status], [data?.status]);

  // @ts-ignore
  let errorText = error?.blockCyphers?.[0]?.error?.[0] ?? '';
  let hasError = !!error?.blockCyphers ? true : false;

  // const doctorName = useMemo(()=>{
  //   if(FLOW_STEPS_STATUSES[data.status] === 1){
  //     const { firstName = '', lastName = '', middleName = '', title = '' } = profile ?? {};
  //     return <div className='implementer'><b>{`${lastName} ${firstName} ${middleName}`.trim()}</b>, {title}</div>;
  //   }

  //   return <></>;
  // },[profile,data]);

  const [placeBiomaterialSampling,placeBiomaterialSamplingCustomName] = useMemo(() => {
    const organization = biologicalMaterial?.organization ?? false;
    if (!organization) return ['',''];
    let orgOfficialName;
    const orgID = organization && +organization ? +organization : false;
    const orgName = organization && !orgID && organization ? organization : false;
    const orgNameCustom = biologicalMaterial?.organizationCustom;
    const orgList = data?.organizations ?? [];

    if (orgID) orgOfficialName = orgList.find((org: any) => org.id === orgID);
    if (orgName) orgOfficialName = orgList.find((org: any) => org.nameOfficial === orgName);
    let orgLocalName = orgOfficialName?.nameLocalRus;

    return [orgLocalName,orgNameCustom];
  }, [biologicalMaterial, data]);

  const [placeNumbersAssigned, placeNumbersAssignedCustomName] = useMemo(() => {
    let orgData = '';
    const orgName = biologicalMaterial?.material?.blockCypher?.[0]?.organization;
    const customName = biologicalMaterial?.material?.blockCypher?.[0]?.organizationCustom;
    const orgList = data?.organizations ?? [];

    if (orgName && orgList.length) orgData = orgList.find((org: any) => {
      return org.id === +orgName || org.nameOfficial === orgName || org.nameLocalRus === orgName;
    })?.nameLocalRus;

    return [orgData,customName];
  }, [data?.organizations, biologicalMaterial]);

  const isRpcOMR = useMemo(() => placeNumbersAssigned === 'РНПЦ ОМР', [placeNumbersAssigned]);

  const bloodNumberMask = useMemo(() => {
    const bloodNumber = blood?.material?.bloodId;

    if (!bloodNumber) return '';
    return bloodNumber.toString().padStart(8, '0');
  }, [blood]);


  //delete saved item from session storage
  const deleteSavedItem = useCallback(async () => {
    let savedRawData = await getFromSessionStorage('CHANGES_NOT_SAVED_BioMaterialData')?.[0];

    //close table editing if nothing has been saved at all
    if (!savedRawData) return setEditPageActive(false);

    //find saved changes by ulid
    let parsedRawData = JSON.parse(savedRawData)
    let savedDataIndex = parsedRawData.findIndex((savedItem: any) => savedItem.ulid === referralULD);

    //remove the current element from the setToSessionStorage
    if (savedDataIndex > -1 && parsedRawData.length > 1) {
      parsedRawData.splice(savedDataIndex, 1)
      await setToSessionStorage(['CHANGES_NOT_SAVED_BioMaterialData', JSON.stringify(parsedRawData)]);
    }

    //delete everything saved if it's the only element
    if (savedDataIndex > -1 && parsedRawData.length === 1) {
      await removeItemSessionStorage('CHANGES_NOT_SAVED_BioMaterialData');
    }
  }, [referralULD]);

  //confirm only RPCOMR numbers
  const handleAcceptBiomaterials = useCallback(() => {
    if (tokens?.access && isRpcOMR) {
      dispatch(patchMolecularProfilingDataConfirm(tokens.access, data?.ulid));
      if(commentary) dispatch(postMolecularProfilingComment(tokens.access, data.id, 'morphology', commentary));
      setTimeout(() => dispatch(createNextStep(tokens.access, { referral: data.id, status: 'bio_done' })),1000);
    }

    return setShowModal(false);
  }, [dispatch, data, setShowModal, tokens?.access, isRpcOMR, commentary]);

  //confirm other numbers
  const handleSendBiomaterial = useCallback(() => {
    const block_cyphers = Object.values(newBioMatNumbers.block_cyphers);
    const slide_cyphers = Object.values(newBioMatNumbers.slide_cyphers);

    if (tokens?.access && !isRpcOMR && (block_cyphers.length || slide_cyphers.length)) {
      deleteSavedItem();
      dispatch(postNewBioMaterialNumbers(tokens.access, { block_cyphers, slide_cyphers }));
      dispatch(patchMolecularProfilingDataConfirm(tokens.access, data?.ulid));
      if(commentary) dispatch(postMolecularProfilingComment(tokens.access, data.id, 'morphology', commentary));
      dispatch(createNextStep(tokens.access, { referral: data.id, status: 'bio_done' }));
    };

    return setShowModal(false);
  }, [dispatch, data, tokens?.access, newBioMatNumbers, deleteSavedItem, isRpcOMR, setShowModal,commentary])

  const tadleData = useMemo(() => {
    let headers;
    let blocks;
    const isBioPendigStatus = currentStatus === 1;

    if (isRpcOMR) headers = shortTableHeaders;
    else headers = wideTableHeaders;

    const blocksFiltered = data?.biologicalMaterial?.filter((item: any) => item.type === 'Block');

    if (isBioPendigStatus && blocksFiltered) {
      blocks = blocksFiltered.map(((block: any) => {
        return {
          organ: block.material.blockSlides[0]?.organ ?? '',
          block: block.material.blockCypher[0],
          slide: block.material.blockSlides
        };
      }));
    }
    if (!isBioPendigStatus && blocksFiltered) {
      blocks = blocksFiltered.map(((block: any) => {
        return {
          organ: block.material.blockSlides[0]?.organ ?? '',
          block: block.material.blockCypher,
          slide: block.material.blockSlides
        };
      }));
    };

    if (blocks && blocks.length) return { headers, blocks };
    return { headers };
  }, [data, isRpcOMR, currentStatus]);

  //is it possible to edit this tab
  const isEditDisabled = useMemo(() => {
    if (!data) return;
    let isRefferalCanceled = data?.statusCancellation;
    let isMorphologyPending = currentStatus > 1;

    return isRefferalCanceled || isMorphologyPending;
  }, [data, currentStatus]);


  //handle edit tab button
  const handleEditPage = useCallback(async () => {
    setBlockErrorsArr([]);
    setSlideErrorsArr([]);

    if (isEditPageActive) {
      //delete saved changes
      await deleteSavedItem();

      //clear state
      setNewBioMatNumbers({ block_cyphers: {}, slide_cyphers: {} });
      setCommentary('');
      setAllDataSaved(false);
      setChangesSaved(false);
      setModalCellId(null);
      setTimeout(() => setUpdateKey(Date.now()),100)
      return setEditPageActive(false);
    }

    setModalCellId(null);
    setEditPageActive(true);
  }, [isEditPageActive, deleteSavedItem, setChangesSaved, 
    setModalCellId,setBlockErrorsArr,setSlideErrorsArr,setUpdateKey]);


  //apply saved changes
  useLayoutEffect(() => {

    if (FLOW_STEPS_STATUSES[data.status] > 1 && tokens?.access) {
      dispatch(fetchServiceUsers(tokens?.access, { organizationId: 0 }));
      let comment = data?.referralCommentary.find((comment:any) => comment.stage === 'morphology')?.text ?? '';
      if(comment) setCommentary(comment);
    }


    //load saved data
    let savedRawData = getFromSessionStorage('CHANGES_NOT_SAVED_BioMaterialData')?.[0] ?? false;
    
    //return if nothing saved
    if (!savedRawData) return;

    //find saved changes by ulid
    let parsedRawData = JSON.parse(savedRawData)
    let savedData = parsedRawData.find((savedItem: any) => savedItem.ulid === referralULD);

    if (!savedData || savedData === -1) return;

    setCommentary(savedData?.commentary ?? '')

    
    //check state
    let isNumberState = newBioMatNumbers && (
      !!Object.keys(newBioMatNumbers['block_cyphers'] ?? {}).length ||
      !!Object.keys(newBioMatNumbers['slide_cyphers']).length);
      
    //return if state is not empty
    if (isNumberState) return;

    //set saved changes
    setNewBioMatNumbers({ ...savedData.newBioMatNumbers });
    if(!isRpcOMR) setAllDataSaved(true);
    return setEditPageActive(false);

  }, [newBioMatNumbers, isEditPageActive, setEditPageActive, referralULD, dispatch, tokens?.access, data,setCommentary,isRpcOMR]);

  const allBlockValuesFilled = useCallback(()=>{
    if(isRpcOMR) return true;
    const blockCodeIdMap:{[key: string | number]: string } = {};

    for(let {block} of tadleData?.blocks) blockCodeIdMap[block.block] = block.code;

    for(let newBlk in newBioMatNumbers?.['block_cyphers'] ?? {}){
      if(newBlk) delete blockCodeIdMap[newBlk];
    }

    const blockErrorArr:string[] = Object.values(blockCodeIdMap);

    if(blockErrorArr?.length){
       setBlockErrorsArr(blockErrorArr);
       setChangesSaved(false);
       return false;
    }
    setBlockErrorsArr([]);
    return true;
  },[tadleData,newBioMatNumbers,setBlockErrorsArr,setChangesSaved,isRpcOMR]);


  const allSlideValuesFilled = useCallback(()=>{
    if(isRpcOMR || !!commentary.trim()) return true;

    const slideCodeIdMap:{[key: string | number]: string } = {};
    const slideCyphers = tadleData?.blocks.flatMap((blk: any) => blk.slide.flatMap((slide:any) => slide.slideCypher))
    
    for(let {slide, code} of slideCyphers) slideCodeIdMap[slide] = code;
    
    for(let newBlk in newBioMatNumbers?.['slide_cyphers'] ?? {}){
      if(newBlk) delete slideCodeIdMap[newBlk];
    }

    const slideErrorArr:string[] = Object.values(slideCodeIdMap);

    if(slideErrorArr?.length){
      setSlideErrorsArr(slideErrorArr);
      setChangesSaved(false);
      return false;
    }
    setSlideErrorsArr([]);
    return true;
  },[tadleData,newBioMatNumbers,setSlideErrorsArr,setChangesSaved,isRpcOMR,commentary]);


  //save changes to session storage
  const handleSaveChanges = useCallback(() => {
    let dataForSave;

    if(!isRpcOMR && !allBlockValuesFilled()) return;
    if(!isRpcOMR && !allSlideValuesFilled() && !commentary.trim()?.length) return;

    

    //load saved data
    let savedRawData = getFromSessionStorage('CHANGES_NOT_SAVED_BioMaterialData')?.[0] ?? false;

    //if nothing saved - save state
    if (!savedRawData){ 
      if(isRpcOMR) dataForSave = [{ ulid: referralULD, commentary }]
      else dataForSave = [{ newBioMatNumbers, ulid: referralULD, commentary }]
    };

    if (savedRawData.length) {
      //find saved changes by ulid
      let parsedRawData = JSON.parse(savedRawData)
      let savedDataIndex = parsedRawData.findIndex((savedItem: any) => savedItem.ulid === referralULD);

      //replace data if it already exists for that referral
      if (savedDataIndex !== -1) {
        parsedRawData[savedDataIndex] = { newBioMatNumbers, ulid: referralULD, commentary };
        dataForSave = parsedRawData
      }

      //merge data if some data already exists
      if (savedDataIndex === -1) {
        dataForSave = parsedRawData.concat({ newBioMatNumbers, ulid: referralULD, commentary });
      }
    };

    setToSessionStorage(['CHANGES_NOT_SAVED_BioMaterialData', JSON.stringify(dataForSave)]);
    if(!isRpcOMR) setAllDataSaved(true);
    setChangesSaved(false);
    setEditPageActive(false)
  }, [setChangesSaved, referralULD, newBioMatNumbers, setEditPageActive, commentary, isRpcOMR,allBlockValuesFilled,allSlideValuesFilled]);

  //add new rpc_omr number data to state from popup 
  const handleAddNewNumber = useCallback((type: string, data: any) => {
    let { block_cyphers, slide_cyphers } = newBioMatNumbers;

    if (type === 'block') block_cyphers[data.block] = data;
    if (type === 'slide') slide_cyphers[data.slide] = data;

    setNewBioMatNumbers({ block_cyphers, slide_cyphers });
    setChangesSaved(true);
    allBlockValuesFilled();
  }, [newBioMatNumbers, setNewBioMatNumbers, setChangesSaved, allBlockValuesFilled]);

  const handlerAddNewComment = useCallback((comment:string) => {
    setCommentary(comment.trim());
    if(slideErrorsArr?.length) setSlideErrorsArr([]);
    if(!isChangesSaved) setChangesSaved(true);
    if(allDataSaved) setAllDataSaved(false);
  },[setCommentary,setChangesSaved,isChangesSaved,slideErrorsArr,setSlideErrorsArr,setAllDataSaved,allDataSaved]);


  const [dateOfCompletion, doctorWhoCompleted] = useMemo(() => {

    if (!data?.stepReferral || !data?.stepReferral?.length) return ['', ''];
    let bioComliteObj: IStepReferral = data.stepReferral.find((step: IStepReferral) => step.status === 'bio_done');
    if (!bioComliteObj) return ['', ''];

    let { dateSaved, userUlid } = bioComliteObj;

    //Completion date
    let dateCompletion = format(new Date(dateSaved), 'dd.MM.yyyy - HH:mm:ss');

    //Completion doctor
    let doctorWhoCompleted = <></>;
    let doctorName = doctorsList.find((item) => item.ulid === userUlid)?.userProfile;

    if (doctorName) {
      let { firstName = '', lastName = '', middleName = '', title = '' } = doctorName;
      doctorWhoCompleted = <div className='implementer'><b>{`${lastName} ${firstName} ${middleName}`.trim()}</b>, {title}</div>;
    };

    return [dateCompletion, doctorWhoCompleted];
  }, [data, doctorsList]);

  const formattedDateOfAcquisition = biologicalMaterial?.dateOfAcquisition ? format(new Date(biologicalMaterial?.dateOfAcquisition), 'dd-MM-yyyy') : '';
  const formattedBloodDateOfAcquisition = blood?.dateOfAcquisition ? format(new Date(blood?.dateOfAcquisition), 'dd-MM-yyyy') : null;

  return (
    <TBioMaterialDataWr key={updateKey}>
      <TRowWr>
        <TDateOfCompletion>{!!dateOfCompletion && `Дата завершения: ${dateOfCompletion}`}</TDateOfCompletion>
        {data?.ableToUdateREFERRAL?.updateBiologicalMaterial && !isEditDisabled && (
          <>
            {!isEditPageActive && <TTooltip place='top' id={'editButton'} />}
            <TEditButton
              disabled={isEditDisabled }
              data-for={'editButton'}
              data-tip={'Для присвоения номеров РНПЦ ОМР перейдите в режим редактирования'}
              onClick={handleEditPage}>
              {!isEditPageActive ? 'Редактировать' : 'Отменить'}
            </TEditButton>
          </>)}
      </TRowWr>
      {/* <TRowWr direction='flex-start'><Switcher isChecked={currentStatus > 1} title='Биоматериал получен' /></TRowWr> */}
      <THeader>Блоки и Гист. стекла</THeader>
      <NotEditedInput width={'100%'} label='Тип материала'>{biologicalMaterial?.source ?? ''}</NotEditedInput>
      <NotEditedInput width={'49%'} label='Место забора материала'>
        {placeBiomaterialSamplingCustomName ? placeBiomaterialSamplingCustomName : placeBiomaterialSampling ?? ''}</NotEditedInput>
      <NotEditedInput width={'49%'} label='Дата забора материала'>{formattedDateOfAcquisition ?? ''}</NotEditedInput>
      <NotEditedInput width={'100%'} label='Место изначальной регистрации (оригинальная маркировка)'>
        {placeNumbersAssignedCustomName ? placeNumbersAssignedCustomName : placeNumbersAssigned ?? ''}
      </NotEditedInput>
      <TTableWr>
        <Table>
          <thead>
            <tr>
              {tadleData?.headers.map((item: ITableHeader) => <th key={item.id} colSpan={item?.colSpan} rowSpan={item?.rowSpan} className={item.class}>{item.title}</th>)}
            </tr>
            {!isRpcOMR && <tr>
              {wideSubHeader.map((item: ITableHeader) => <th key={item.id} colSpan={item?.colSpan} rowSpan={item?.rowSpan} className={item.class}>{item.title}</th>)}
            </tr>}
          </thead>
          <TBody>
            {/* all statuses except bio_pending */}
            {tadleData?.blocks && currentStatus > 1 && tadleData.blocks.map((row: any, index: number) => {
              let block = row.block;
              let slide = row.slide[0].slideCypher;

              let mainBlockSlide = (
                <tr key={index}>
                  <td className='wrapWords maxWidth20' >{++index + '. ' + row.organ}</td>
                  <CodeTableCell color={'lightViolet'} block={block[0]} toolTipActive={toolTipActive} setToolTipActive={setToolTipActive} tooltipData={tooltipData} />
                  {!isRpcOMR && <CodeTableCell color={'lightViolet'} block={block[1]} toolTipActive={tooltipData} setToolTipActive={setToolTipActive} tooltipData={tooltipData} />}
                  <CodeTableCell color={'lightOrange'} slide={slide[0]} toolTipActive={tooltipData} setToolTipActive={setToolTipActive} tooltipData={tooltipData} />
                  {!isRpcOMR && <CodeTableCell color={'lightOrange'} slide={slide[1]} toolTipActive={tooltipData} setToolTipActive={setToolTipActive} tooltipData={tooltipData} />}
                </tr>
              );
              if (row.slide.length >= 1) return row.slide.map((item: any, index: number) => {
                if (!index) return mainBlockSlide;
                let slide = item.slideCypher;
                return (
                  <tr key={item.ulid}>
                    <td className='wrapWords maxWidth20'></td>
                    <td></td>
                    {/* {!isRpcOMR && <td></td>} */}
                    {!isRpcOMR && <td></td>}
                    <CodeTableCell color={'lightOrange'} slide={slide[0]} toolTipActive={tooltipData} setToolTipActive={setToolTipActive} tooltipData={tooltipData} />
                    {!isRpcOMR && <CodeTableCell color={'lightOrange'} slide={slide[1]} toolTipActive={tooltipData} setToolTipActive={setToolTipActive} tooltipData={tooltipData} />}
                  </tr>
                )
              })
              return mainBlockSlide;
            })}
            {/* all status bio_pending */}
            {tadleData?.blocks && currentStatus === 1 && tadleData.blocks.map((row: any, index: number) => {
              let block = row.block;
              let slide = row.slide[0].slideCypher[0];
              let cellIdBlock = `block-edit-${block?.code}`;
              let cellIdSlide = `slide-edit-${slide?.code}`;
              let rowData = (
                <SlideRow
                  key={(block?.code || slide?.code) + index}
                  row={row}
                  index={index}
                  block={block}
                  slide={slide}
                  cellIdBlock={cellIdBlock}
                  cellIdSlide={cellIdSlide}
                  modalCellId={isEditPageActive ? modalCellId : ''}
                  setModalCellId={setModalCellId}
                  toolTipActive={toolTipActive}
                  setToolTipActive={setToolTipActive}
                  tooltipData={tooltipData}
                  isEditPageActive={!isRpcOMR}
                  handleAddNewNumber={handleAddNewNumber}
                  newBioMatNumbers={newBioMatNumbers}
                  blockErrorsArr={blockErrorsArr}
                />)

              if (row.slide.length > 1) return row.slide.map((item: any, slideIndex: number) => {
                if (!slideIndex) return rowData;
                let slide = item.slideCypher[0];
                let cellIdSlide = `slide-edit-${slide?.code}`;
                return (
                  <tr key={item.ulid + slide.code}>
                    <td className='wrapWords maxWidth20'></td>
                    <td></td>
                    {!isRpcOMR && <td></td>}
                    {/* {isEditPageActive || allDataSaved ? <TCustomTd></TCustomTd> : null} */}
                    <CodeTableCell
                      slide={slide}
                      color={'lightOrange'}
                      toolTipActive={toolTipActive}
                      setToolTipActive={setToolTipActive}
                      tooltipData={tooltipData}
                    />
                    {!isRpcOMR &&
                      <EditableTableCell
                        slide={slide}
                        color={'lightOrange'}
                        key={'slide' + slide.code}
                        cellIdSlide={cellIdSlide}
                        modalCellId={isEditPageActive ? modalCellId : ''}
                        setModalCellId={setModalCellId}
                        rightOfForm={0}
                        isEditPageActive={!isRpcOMR}
                        handleAddNewNumber={handleAddNewNumber}
                        newBioMatNumbers={newBioMatNumbers}
                      />}
                  </tr>)
              });
              return rowData;
            })}
          </TBody>
        </Table>
      </TTableWr>
      {blood && (
        <>
          <THeader>Кровь</THeader>
          <NotEditedInput width={'49%'} label='Дата забора крови'>{formattedBloodDateOfAcquisition ?? ''}</NotEditedInput>
          <NotEditedInput width={'49%'} label='Номер'>{bloodNumberMask ?? ''}</NotEditedInput>
        </>
      )}
      

      <TDescriptionWr column={true}>
      {((isEditDisabled && commentary) || !isEditDisabled) && <Textarea
          // {...register('commentary', { required: false })}
          key={`commentary_${isEditPageActive}`}
          name='commentary'
          bordered={true}
          label={`Комментарий ${slideErrorsArr?.length ? '*' : ''}`}
          className={`description nothovered ${slideErrorsArr?.length ? 'error' : ''}`}
          placeholder={isRpcOMR ? 'Введите текст' :`Обязателен, если минимум одно поле столбца “присвоенные номера стекол“ осталось незаполненным`}
          readOnly={!isEditPageActive}
          disabled={!isEditPageActive}
          defaultValue={commentary}
          onChange={({target}) => handlerAddNewComment(target.value)}
          error={!!slideErrorsArr?.length || commentary?.length > 999}
          maxLength={1000}
          height={152}
        />}

        {!!doctorWhoCompleted ? doctorWhoCompleted : ''}
      </TDescriptionWr>

      {data?.ableToUdateREFERRAL?.updateBiologicalMaterial && !isEditDisabled && (
        <TBtnWr>
          {!isRpcOMR && <TButton disabled={!isChangesSaved || commentary?.length > 999} onClick={handleSaveChanges}>Сохранить изменения</TButton>}
          <TButton onClick={handlerShowModal} disabled={isEditDisabled || (!isRpcOMR && !allDataSaved)}>Подтвердить наличие блоков</TButton>
        </TBtnWr>)
      }

      <InfoModal
        title='Все номера блоков и Гист. стекол указаны верно? '
        showModal={showModal}
        onModalToggle={handlerShowModal}
        isWarning
        buttons={
          <>
            <CustomButton size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={handlerShowModal}>Хочу проверить</CustomButton>
            <Button size={SIZE.SMALL} onClick={() => isRpcOMR ? handleAcceptBiomaterials() : handleSendBiomaterial()} disabled={hasError}>Да</Button>
          </>
        }
        children={
          <>
            {hasError && <TCustomError>{errorText}</TCustomError>}
          </>
        }
      />
    </TBioMaterialDataWr>
  )
}

export default BioMaterialData;
