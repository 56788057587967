import { FC, MutableRefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import IconSquareButton from "../IconSquareButton";
import { TTable, THead, TBody, TWraper, TInvertPlusIcon, TAddButton, TMenuIcon, TSelectBage, TSelectBageDel, TSelectBageWr, TArrowSelect, TSelectOptions, TAddFieldBtnWr } from "./styled";
import { useFieldArray, useForm } from "react-hook-form";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { CustomOptionForm } from "./CustomOptionForm";

interface IInputItems {
  value: string;
  label: string;
}
interface IOptionData {
  firstColumn: IInputItems[];
  secondColumn: IInputItems[];
}

interface IComponentProps {
  tableHeader?: string[];
  optionData?: IOptionData[];
  isEdit?: boolean;
  isError?: boolean;
  isTextMode?: boolean;
  calbackData?: (value: IOptionData[]) => void;
  defaultData?: IOptionData[];
  defaultPlaceholders?: string[];
  isSelectAllOption?: boolean;
  addCustomOption?: boolean;
  firstColumnTextColor?: boolean;
  secondColumnTextColor?: boolean;
  [index: string]: any;
}


type FormValues = {
  DSForm: {
    firstColumn: IInputItems[];
    secondColumn: IInputItems[];
    [index: string]: any;
  }[];
};

const defaultFormValues = (dafaultPlaceHolder?: string[]) => {
  return {
    DSForm: [{
      firstColumn: [{ value: dafaultPlaceHolder?.[0] ?? '- - -', label: 'empty' }],
      secondColumn: [{ value: dafaultPlaceHolder?.[1] ?? '- - -', label: 'empty' }]
    }]
  }
}


export const DoubleSelect: FC<IComponentProps> = ({ tableHeader, isEdit, isError, optionData, calbackData, defaultData, 
  defaultPlaceholders, isTextMode, isSelectAllOption, addCustomOption, firstColumnTextColor, secondColumnTextColor}) => {
  const { watch, control, getValues, reset } = useForm<FormValues>({
    defaultValues: defaultFormValues(defaultPlaceholders), mode: "onBlur"
  });
  const { fields, append, remove, update, replace } = useFieldArray({ name: "DSForm", control });
  const DoubleSelectValues = watch('DSForm');

  const [openMenu, setOpenMenu] = useState<{ open: boolean, index: number, init: string, count: number }>({ open: false, index: -1, init: 'arrow', count: 1 });
  const [tableKey, resetTableKey] = useState<number>(Date.now());
  const [allAvailableOptions, setAvailableOptions] = useState<IOptionData[]>([]);
  const [openManualInput, setOpenManualInput] = useState<boolean>(false);

  const node = useRef<HTMLUListElement>(null) as MutableRefObject<HTMLUListElement>;

  useOutsideClick(node, () => {
    if (openMenu.open) {
      setOpenMenu((prev) => ({ open: false, index: prev.index, init: 'outside', count: prev.count }));
    }
  });

  useLayoutEffect(() => {
    if (!defaultData || !defaultData?.length) return;
    reset({ DSForm: [...defaultData] });
    // resetTableKey(Date.now());
  }, [defaultData, resetTableKey, reset]);

  useLayoutEffect(() => {

    if ((!defaultData || !defaultData?.length) && optionData?.length && !allAvailableOptions?.length) {
      setAvailableOptions(optionData)
    };
  }, [setAvailableOptions, optionData, allAvailableOptions, defaultData]);


  useEffect(() => {
    if (!calbackData) return;
    return calbackData(DoubleSelectValues)
  }, [calbackData, DoubleSelectValues]);

  // const errorInIndex = useMemo(() => {
  //   if (!isError) return
  //   let indexField = fields.findIndex(value => {
  //     let fc = value.firstColumn.some(value => value.label === 'empty');
  //     let sc = value.secondColumn.some(value => value.label === 'empty');
  //     return fc || sc;
  //   })
  //   return indexField
  // }, [isError, fields]);

  const deleteFieldItem = useCallback((fieldName: string, fieldIndex: number, index: number) => {
    let fildValues = { ...getValues('DSForm')?.[fieldIndex] };
    if (!fildValues?.[fieldName]?.length || fildValues?.[fieldName]?.length === 1) return;

    let copyFields = [...fildValues[fieldName]];
    copyFields.splice(index, 1);
    fildValues[fieldName] = copyFields

    update(fieldIndex ?? 0, { ...fildValues });
  }, [getValues, update]);



  const deleteRow = useCallback((rowIndex: number) => {
    if (isEdit && fields.length === 1) reset(defaultFormValues(defaultPlaceholders));
    if (isEdit && fields.length > 1) return remove(rowIndex);
    return;
  }, [remove, fields, reset, isEdit, defaultPlaceholders]);

  const showOptionsMenu = useCallback(async (fileldIndex: number) => {
    let isLastRow = fields.length === fileldIndex + 1;
    let fildData = fields?.[fileldIndex] ?? {};
    let isEmptyFirstCol = fildData?.firstColumn?.length === 1 && fildData?.firstColumn?.[0].label === 'empty';
    let isEmptySecondCol = fildData?.secondColumn?.length === 1 && fildData?.secondColumn?.[0].label === 'empty';
    if (isLastRow && isEmptyFirstCol && isEmptySecondCol && !openMenu.open && isEdit) {
      if (openMenu.init === 'arrow') {
        setOpenMenu((prev) => ({ open: true, index: fileldIndex, init: 'arrow', count: ++prev.count }))
      }
      if (openMenu.init === 'outside') {
        setOpenMenu((prev) => ({ open: !!(prev.count % 2), index: fileldIndex, init: 'arrow', count: ++prev.count }))
      }
    }
  }, [openMenu, setOpenMenu, isEdit, fields]);

  const addFieldFromOptions = useCallback(async (opt?: IOptionData) => {
    if (!!opt) {
      let fieldIndex = openMenu.index;
      if (!Number.isInteger(fieldIndex) && (fieldIndex !== 0 || !(fieldIndex >= 0))) return;
      update(fieldIndex ?? 0, { ...opt });
    } else replace(allAvailableOptions ?? []);

    setOpenMenu({ open: false, index: -1, init: 'outside', count: 1 });
    resetTableKey(Date.now());
  }, [openMenu.index, update, setOpenMenu, resetTableKey, replace, allAvailableOptions]);

  const addNewRow = useCallback(() => {
    let fildValues = getValues('DSForm') ?? [];
    let isEmptyFiels = fildValues.filter((value: IOptionData) => {
      let firstColumn = value?.firstColumn?.every((fc: IInputItems) => fc.label === 'empty')
      let secondColumn = value?.secondColumn?.every((fc: IInputItems) => fc.label === 'empty')
      return firstColumn || secondColumn;
    })

    if (!isEdit || isEmptyFiels.length) return
    append({
      firstColumn: [{ value: defaultPlaceholders?.[0] ?? '- - -', label: 'empty' }],
      secondColumn: [{ value: defaultPlaceholders?.[1] ?? '- - -', label: 'empty' }]
    })
  }, [isEdit, append, getValues, defaultPlaceholders]);

  const manualInputDataHandler = useCallback((manualInputData:IOptionData) => {
    let fildValues = getValues('DSForm') ?? [];
    let lastTableFieldIndex = fildValues.length - 1;
    let isLastFieldEmpty = fildValues[lastTableFieldIndex]?.firstColumn?.[0]?.label === 'empty' || 
    fildValues[lastTableFieldIndex]?.secondColumn?.[0]?.label === 'empty';

    if(isLastFieldEmpty) update(lastTableFieldIndex,manualInputData);
    if(!isLastFieldEmpty) append(manualInputData);
  },[append,getValues,update]);


  return <TWraper>
    <TTable className={openMenu.open ? 'isMenuOpen' : ''}>
      {!!tableHeader?.length && (
        <THead className="violet"><tr className="center">{tableHeader.map(headName => <th key={headName}>{headName}</th>)}
          {isEdit && <td></td>}
        </tr></THead>
      )}
      <TBody key={tableKey}>
        {fields.map((fileld, fileldIndex) => (
          <tr key={fileld.id} className={isError && fileldIndex === openMenu.index ?  'isMenuOpen error' : ''}>
            <td className={firstColumnTextColor ? 'violetText' : ''}>
              <TSelectBageWr disabled={!!isEdit} className={isTextMode && fileld.firstColumn[0].label !== 'empty' ? 'alignLeft' : ''}>
                {isEdit && !isTextMode && fileld.firstColumn.map((option, index) => (
                  <TSelectBage key={`${index}_${option.value}_${fileld.firstColumn.length}`}>
                    {option.value}
                    <TSelectBageDel disabled={false} onClick={() => deleteFieldItem('firstColumn', fileldIndex, index)} />
                  </TSelectBage>
                ))}
                {(!!isTextMode || !isEdit) && fileld.firstColumn.map(option => option.value).toString()}
              </TSelectBageWr>
            </td>
            <td className={secondColumnTextColor ? 'violetText' : ''}>
              <TSelectBageWr disabled={!!isEdit}>
                {isEdit && !isTextMode && fileld.secondColumn.map((option, index) => (
                  <TSelectBage key={`${index}_${option.value}_${fileld.secondColumn.length}`}>
                    {option.value}
                    <TSelectBageDel disabled={false} onClick={() => deleteFieldItem('secondColumn', fileldIndex, index)} />
                  </TSelectBage>
                ))}
                {(!!isTextMode || !isEdit) && fileld.secondColumn.map(option => ` ${option.value}`).toString()}
              </TSelectBageWr>
              {isEdit && <TArrowSelect
                open={!!openMenu?.open && fileldIndex === openMenu.index}
                onClick={() => showOptionsMenu(fileldIndex)}
              />}
            </td>
            {isEdit && <td className="delete-row">
              <TMenuIcon disabled={!isEdit || (fileldIndex === 0)} onClick={() => deleteRow(fileldIndex)} />
            </td>}
          </tr>
        ))}
      </TBody>
    </TTable>
    {!!openMenu?.open && (
      <TSelectOptions ref={node}>
        {optionData?.map((opt: IOptionData, index: number) => (
          <li key={`${index}_selOpt`} onClick={() => addFieldFromOptions(opt)} className="optionDataRow">
            <div className="fistColumn">{opt.firstColumn.map(o => o.value).toString()}</div>
            <div className="secondColumn">{opt.secondColumn.map(o => ` ${o.value}`).toString()}</div>
          </li>
        ))}
        {isSelectAllOption && (optionData?.length ?? 0) > 1 && (
          <li className="optionDataRow" onClick={() => addFieldFromOptions()} key={'selectAll'}>
            <div className="fistColumn selectAll">Выбрать всех</div>
            <div className="secondColumn"></div>
          </li>
        )}
      </TSelectOptions>
    )}
    {!openManualInput && isEdit && <TAddFieldBtnWr>
      <TAddButton disabled={!isEdit}>
        <IconSquareButton disabled={!isEdit} invert={!isEdit} onClick={addNewRow}>
          <TInvertPlusIcon />
        </IconSquareButton>
        Добавить строку
      </TAddButton>
      {addCustomOption && <TAddButton disabled={!isEdit} linkStyle={true} onClick={() => isEdit ? setOpenManualInput(true) : null}>
        Указать иное
      </TAddButton>}
    </TAddFieldBtnWr>}
    {openManualInput && <CustomOptionForm  openManualInput={openManualInput} closeManualInput={() => setOpenManualInput(false)} setManualInputData={manualInputDataHandler}/>}
  </TWraper>
}

export default DoubleSelect;