export const getFromSessionStorage = (...keys: string[]) =>
  keys.length ? keys.reduce((acc: string[], key) => [...acc, sessionStorage.getItem(key) || ''], []) : [];

export const setToSessionStorage = (...pairs: [string | undefined, string | number | undefined][]) => {
  pairs.length && pairs.forEach((pair) => {
    const [key, value] = pair ?? [];
    key && sessionStorage.setItem(key, `${value ?? ""}`);
  })
};

export const resetSessionStorage = () => {
  sessionStorage.clear();
};

export const removeItemSessionStorage = (...keys: string[]) => {
  keys.length && keys.forEach( key => sessionStorage.removeItem(key))
};
