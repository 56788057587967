import { createSlice } from '@reduxjs/toolkit';
import { ServiceUser } from './model';
import { ErrorsType, Nullable } from '../../types';

export interface IServiceUsersInitialState {
  loading: boolean;
  serviceUsers: ServiceUser[];
  selectedServiceUser: Nullable<ServiceUser>;
  errors: Nullable<ErrorsType>;
}

export const initialState: IServiceUsersInitialState = {
  loading: false,
  serviceUsers: [],
  selectedServiceUser: null,
  errors: null,
};

const serviceUsersSlice = createSlice({
  name: 'serviceUsers',
  initialState,
  reducers: {
    getServiceUsers: state => {
      state.loading = true;
    },
    getServiceUsersSuccess: (state, { payload }) => {
      state.serviceUsers = payload;
      state.loading = false;
    },
    getServiceUsersFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload
    },
    setSelectedServiceUser: (state, { payload }) => {
      state.selectedServiceUser = payload;
    },
    resetSelectedServiceUsers: (state) => {
      state.serviceUsers = [];
      state.selectedServiceUser = null;
      state.loading = false;
      state.errors = null;
    }
  },
});

export const {
  getServiceUsers,
  getServiceUsersSuccess,
  getServiceUsersFailure,
  setSelectedServiceUser,
  resetSelectedServiceUsers
} = serviceUsersSlice.actions

export default serviceUsersSlice.reducer;
