import { FC } from 'react';
import Step from './Step';
import { TWrapper } from './styled';

export interface IProgressStep {
  className?: string;
  progressSteps: string[];
  activeIndex: number;
}

const ProgressStep: FC<IProgressStep> = ({ className, progressSteps, activeIndex }) => {
  return (
    <TWrapper className={className}>
      {progressSteps.map((item, index) => {
        const currentIndex = index + 1;
        return (
          <Step
            key={item}
            index={currentIndex}
            title={item}
            completed={currentIndex < activeIndex}
            active={currentIndex === activeIndex}
          />
        )
      })}
    </TWrapper>
  );
};

export default ProgressStep;
