import styled from 'styled-components';

export const TWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
`;


export const TDataWr = styled.div`
  box-sizing: border-box;
  max-width: 730px;
  min-height: 680px;
  
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 1px 1px 20px rgba(122, 120, 233, 0.2);
  border-radius: 10px;
  padding: 56px 87px;
  margin: 0 auto;
`;

export const TTitle = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 50px;
    color: ${({ theme }) => theme.colors.black[200]};
    margin: 34px 0 56px 40px;
`;

export const TUsernameBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const TAvatarWr = styled.img`
  width: 96px;
  height: 96px;
  margin-right: 28px;
  border-radius: 50%;
`;

export const TName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black[200]};
`;

export const TUserData = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TUserDataBock = styled.div`
  width: 50%;
  div {
    margin-top: 15px;
  }
`;

export const TButtonContainer = styled.div`
  margin: 46px auto 0;
  & > * {
    width: 100%;
  }
`;

