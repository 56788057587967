import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { THeaderColWr, TRowColWr, THeaderRowWr, TRowWr, TWrapper } from './styled';
import Pagination from '../Pagination';
import { IMolProRequest } from '../../../store/molecularProfiling/model';

export interface IColumn<T> {
  header: string | ((options?: any) => ReactNode | string | number);
  columnWidth: string;
  renderer: (item: T, options?: any) => ReactNode | string | number;
  order?: { name: string };
  position?: string;
  margin?: string;
  id: number;
}

interface ITable<T> {
  columns: IColumn<T>[];
  items: T[];
  hideIfEmpty?: boolean;
  tableHeight?: string;
  goToPage: (page: number) => void;
  totalPages?: any;
  currentPage?: any;
  onClick: (ulid: any) => void;
  headerColor?:string;
  textColor?:string;
}

const Table: FC<ITable<any>> = ({
  columns,
  items,
  hideIfEmpty,
  tableHeight = '100vh',
  goToPage,
  totalPages,
  currentPage,
  onClick,
  headerColor,
  textColor,
  ...props
}) => {
  const [checkedList, setCheckedList] = useState<{ id: number, checked: boolean }[]>([]);

  useEffect(() => {
    const mappedList = items?.map(({ id }) => ({ id, checked: false })) ?? [];
    setCheckedList(mappedList);
  }, [items]);

  const onCheck = useCallback((item) => {
    const mappedList = checkedList.map(({ id, checked }) => id === item.id ? item : { id, checked });
    setCheckedList(mappedList);
  }, [checkedList]);

  const isChecked = useCallback((itemId) => {
    return checkedList.find(({ id }) => id === itemId)?.checked;
  }, [checkedList]);

  const onCheckAll = useCallback((isCheckedAll) => {
    const mappedList = checkedList.map(({ id }) => ({ id, checked: isCheckedAll }));
    setCheckedList(mappedList);
  }, [checkedList]);

  const checkedStatus = useMemo(() => {
    const filteredCheckedList = checkedList.filter(({ checked }) => checked);
    if (filteredCheckedList.length === 0) {
      return 0;
    }
    if (filteredCheckedList.length === checkedList.length) {
      return 2
    }
    if (filteredCheckedList.length > 0) {
      return 1;
    }
  }, [checkedList]);

  if (hideIfEmpty && !items?.length) {
    return null;
  }

  return (
    <>
      <TWrapper {...props}>
        <THeaderRowWr headerColor={headerColor}>
          {columns.map(({ id, header, columnWidth, position, margin }) => (
            <THeaderColWr textColor={textColor} key={id} columnWidth={columnWidth} position={position ?? 'flex-start'} margin={margin ?? '0'}>
              {typeof header === 'string' ? header : header({ cb: onCheckAll, checkedStatus: checkedStatus })}
            </THeaderColWr>
          ))}
        </THeaderRowWr>
        <div>
          {items.map((item) => { 
            let requestReferral = item?.requestReferral || item?.examination?.examination?.referral?.requestReferral
            let isCancelationRequestItem = !!requestReferral && !!requestReferral?.filter((data:IMolProRequest) => data?.status === 'Cancellation' && data?.pending && !data?.confirmation)?.length;
            return (
            <TRowWr
              key={item.ulid}
              onClick={() => onClick(item)}
              bgColor={isCancelationRequestItem ? '#FFCFEC' : undefined}
            >
              {columns.map(({ header, columnWidth, position, renderer, margin }) => (
                <TRowColWr columnWidth={columnWidth} position={position ?? 'flex-start'} margin={margin ?? '0'} key={`col_${header}`}>
                  {renderer(item, { cb: onCheck, checked: isChecked(item.id) })}
                </TRowColWr>
              ))}
            </TRowWr>
          )})}
        </div>
      </TWrapper>
      
      {!!totalPages && <Pagination goToPage={goToPage} totalPages={totalPages} currentPage={currentPage}/>}
    </>
  );
};

export default Table;
