import styled from 'styled-components';

export const TWrapper = styled.div`
  & > * {
    margin-bottom: 32px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const TMKB10Wrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  height: 300px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    background: #EFEEFF;
  }
  ::-webkit-scrollbar-thumb {
    background: #B7B5F1;
    box-shadow: inset 0 0 1px #EFEEFF;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  scrollbar-color: #B7B5F1 #EFEEFF;
`
