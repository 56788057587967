import { FC, useCallback, useLayoutEffect, useMemo, useState } from "react";
import { AnalysisExperiment } from "../../../../store/analysis/model";
import Pcr from "./Methods/Pcr";
import Ss from "./Methods/Ss";
import FishApml from "./Methods/FishApml";
import Fa from "./Methods/Fa";
import FishRear from "./Methods/FishRear";
import Ihc from "./Methods/Ihc";
import IhcAlk from "./Methods/IhcAlk";
import { TSectionTitle } from "../styled";
import { useNavigate } from "react-router-dom";

interface IComponentProps {
  executorMap?: any;
  defaultData?: any;
  referralID: string;
  [index: string]: any;
}

export const LabDetail: FC<IComponentProps> = ({referralID, defaultData, executorMap }) => {
  const navigate = useNavigate();
  const goToExperement = useCallback((experimentID:number,experimentMethod:string,experimentMarker:string) => {
    let pathToExamination = `${experimentID}_${experimentMethod}_${experimentMarker.toLowerCase().replace('/','#')}`;
    navigate(`/molecular-profiling/detail/${referralID}/${pathToExamination}`);
  }, [navigate, referralID]);

  const [experimentData,setExperimentData] = useState<any>(defaultData);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());

  useLayoutEffect(() => {
    if(!!defaultData && defaultData?.moduleLabDetail){
      setExperimentData(defaultData.moduleLabDetail);
      setResetFormKey(Date.now());
    }
  },[defaultData,setResetFormKey,setExperimentData]);

  
  const [IhcAlkExperiments,IhcExperiments] = useMemo(() => {
    if(!experimentData?.['ihc']) return [[],[]];
    const ihcData = experimentData?.['ihc'] ?? []
    const ihc = []
    const ihcAlk = []
    for(let {blueprintId,experements,advisors} of ihcData){
      if(experements[0]?.name === 'ALK') ihcAlk.push({blueprintId,experements,advisors})
      else ihc.push({blueprintId,experements,advisors})
    }

    return [ihcAlk,ihc]
  },[experimentData]);

  return (
    <div key={`${resertFormKey}_experiments`}>
      {!!experimentData?.['pcr'] && <>
      <TSectionTitle>
        Детализация выполненных исследований методом ПЦР
      </TSectionTitle>
      {experimentData?.['pcr']
        ?.map((data: { blueprintId: number, experements: AnalysisExperiment[], advisors: any[] },index:number) => {
          let { blueprintId, experements, advisors } = data;
          return <Pcr 
                    key={(index + blueprintId + experements?.length) + 'pcr' + index} 
                    experements={experements} 
                    blueprintId={blueprintId} 
                    referralID={referralID} 
                    executorMap={executorMap}
                    goToExperement={goToExperement}
                    advisors={advisors}
                  />
        })}</>}

      {!!experimentData?.['fa'] && <>
      <TSectionTitle>
        Детализация выполненных исследований методом фрагментного анализа
      </TSectionTitle>
      {experimentData?.['fa']
        ?.map((data: { blueprintId: number, experements: AnalysisExperiment[], advisors: any[] },index:number) => {
          let { blueprintId, experements, advisors } = data;
          return <Fa 
                    key={(index + blueprintId + experements?.length) + 'fa' + index} 
                    experements={experements} 
                    blueprintId={blueprintId} 
                    referralID={referralID} 
                    executorMap={executorMap}
                    goToExperement={goToExperement}
                    advisors={advisors}
                  />
        })}
      </>}

      {!!experimentData?.['ss'] && <>
      <TSectionTitle>
        Детализация выполненных исследований методом молекулярного секвенирования по Сэнгеру
      </TSectionTitle>
      {experimentData?.['ss']
        ?.map((data: { blueprintId: number, experements: AnalysisExperiment[], advisors: any[] },index:number) => {
          let { blueprintId, experements, advisors } = data;
          return <Ss 
                    key={(index + blueprintId + experements?.length) + 'ss' + index} 
                    experements={experements} 
                    blueprintId={blueprintId} 
                    referralID={referralID} 
                    executorMap={executorMap}
                    goToExperement={goToExperement}
                    advisors={advisors}
                  />
        })}
      </>}

      {(!!experimentData?.['ihc'] || !!experimentData?.['ihcAlk']) && <>
      <TSectionTitle>Детализация выполненных иммуногистохимических исследований</TSectionTitle>
      {IhcExperiments
        ?.map((data: { blueprintId: number, experements: any[], advisors: any[] },index:number) => {
          let { blueprintId, experements, advisors } = data;
          return <Ihc 
                    key={(index + blueprintId + experements?.length) + 'ihc' + index} 
                    experements={experements} 
                    blueprintId={blueprintId} 
                    referralID={referralID} 
                    executorMap={executorMap}
                    goToExperement={goToExperement}
                    advisors={advisors}
                  />
        })}
      {IhcAlkExperiments
        ?.map((data: { blueprintId: number, experements: any[], advisors: any[] },index:number) => {
          let { blueprintId, experements, advisors  } = data;
          return <IhcAlk 
                    key={(index + blueprintId + experements?.length) + 'ihcAlk' + index} 
                    experements={experements} 
                    blueprintId={blueprintId} 
                    referralID={referralID} 
                    executorMap={executorMap}
                    goToExperement={goToExperement}
                    advisors={advisors}
                  />
        })}
      </>}

      {(!!experimentData?.['fishAmpl'] || !!experimentData?.['fishRear']) && <>
      <TSectionTitle>Детализация выполненных FISH исследований</TSectionTitle>
      {experimentData?.['fishAmpl']
        ?.map((data: { blueprintId: number, experements: any[], advisors: any[] },index:number) => {
          let { blueprintId, experements, advisors } = data;
          return <FishApml 
                    key={(index + blueprintId + experements?.length) + 'fishAmpl' + index} 
                    experements={experements} 
                    blueprintId={blueprintId} 
                    referralID={referralID} 
                    executorMap={executorMap}
                    goToExperement={goToExperement}
                    advisors={advisors}
                  />
        })}
      {experimentData?.['fishRear']
        ?.map((data: { blueprintId: number, experements: any[], advisors: any[] },index:number) => {
          let { blueprintId, experements, advisors } = data;
          return <FishRear 
                    key={(index + blueprintId + experements?.length) + 'fishRear' + index} 
                    experements={experements} 
                    blueprintId={blueprintId} 
                    referralID={referralID} 
                    executorMap={executorMap}
                    goToExperement={goToExperement}
                    advisors={advisors}
                  />
        })}
      </>}
    </div>)
}

export default LabDetail;