import { FC, RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions, getProfile, getProfileUpdated, getTokens, getUserEmail, getUserUlid } from "../../store/auth/selectors";
import {
  SupportContactBlock,
  SupportContactWrapper, TBlock,
  TButton, TButtonBlock, TButtonsContainer, TEditButton, TModalContent,
  TModalList, TModalSubtitle, TModalTitle, TRowWr, TSuccessBlock, TTitle,
  TTitleContainer, TUID, TWrapper
} from './styled';
import {ReactComponent as Success} from '../../icons/successl-modal.svg';
import {ReactComponent as Bell} from '../../icons/bell.svg';
import SupportContactComponent from "../../components/shared/SupportContactComponent/SupportContactComponent";
import Modal from "../../components/shared/Modal";
import Button, { SIZE, VARIANT } from "../../components/shared/Button";
import { useReactToPrint } from "react-to-print";
import { makeFormRequest, putProfile, updateProfileType } from "../../store/auth/thunkActions";
import { fetchOrganizations } from "../../store/organizations/thunkActions";
import { getOrganizations } from "../../store/organizations/selectors";
import { Organization } from "../../store/organizations/model";
import PersonalInfoForm from "../../components/forms/PersonalInfoForm";
import { formatPhoneNumber } from "../../utils/stringHelpers";
import StatementToPrint from "../../components/documents/StatementToPrint";


const StartPersonalInfoPage: FC = () => {
  const dispatch = useDispatch();

  const permissions = useSelector(getPermissions);
  const tokens = useSelector(getTokens);
  
  const profileUpdated = useSelector(getProfileUpdated);
  const profile = useSelector(getProfile);
  const userUlid = useSelector(getUserUlid);
  const userEmail = useSelector(getUserEmail);
  
  const organizations = useSelector(getOrganizations);

  
  const {
    ulid = '',
    firstName = '',
    lastName = '',
    middleName = '',
    dateOfBirth = '',
    organization = '',
    specialization = '',
    department = '',
    subdepartment = '',
    title = '',
    phoneNumber = '',
    picture
  } = profile ?? {};

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isSavedDataModal, setSavedDataModal] = useState(false);
  const [isSavedDataModalShow, setSavedDataModalShow] = useState(false);
  const [isEmailConfirmed, setIsEmailConfirmed] = useState((!organization && permissions?.guest && !permissions?.formRequest) || false);
  const [disabledEdit, setdisabledEdit] = useState(true);
  const [savedUserProfile, setSavedUserProfile] = useState<updateProfileType>();
  
  const sendFormRequest = useCallback(() => {
    !permissions?.formRequest &&
    dispatch(makeFormRequest(tokens?.access ?? ""));
  }, [permissions?.formRequest, dispatch, tokens?.access]);
  
  const printTemplateRef = useRef() as RefObject<HTMLInputElement>;
  const afterPrintHandler = useCallback(async () => {
    ulid && savedUserProfile && dispatch(putProfile(ulid, tokens?.access ?? "", savedUserProfile));
    await setTimeout(() => sendFormRequest(),600);
    await setTimeout(() => setSavedUserProfile(undefined),1000);
    
  },[setSavedUserProfile,sendFormRequest,ulid,savedUserProfile,dispatch,tokens?.access]);

  const handlePrint = useReactToPrint({
    content: () => printTemplateRef?.current,
    onAfterPrint: () => {
      afterPrintHandler();
    },
  }) ?? (() => null);
  
  const handleEditPage = useCallback(() => {
    if (!permissions?.guest || !!permissions?.formRequest) return;
    if (disabledEdit) {
      setdisabledEdit(false)
      setSavedUserProfile(undefined)
    } else {
      setdisabledEdit(true)
    }
  }, [permissions, disabledEdit, setdisabledEdit,setSavedUserProfile]);

  const confirmedModalClose = useCallback(() => {
    handleEditPage()
    setIsEmailConfirmed(false);
  }, [setIsEmailConfirmed,handleEditPage]);

  const successModalToggle = useCallback(() => {
    if (permissions?.guest) setIsSuccessful(!isSuccessful);
  }, [isSuccessful, permissions, setIsSuccessful]);

  const savedDataModalClose = useCallback(() => {
    setSavedDataModalShow(true);
    setSavedDataModal(false);
  }, [setSavedDataModalShow,setSavedDataModal]);

  const print = useCallback(() => {
    if (!permissions?.guest || permissions?.formRequest) return handlePrint();
    handlePrint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedUserProfile]);

  useEffect(() => {
    permissions?.formRequest && profileUpdated && !isSavedDataModalShow && setSavedDataModal(true);
  }, [profileUpdated,setSavedDataModal,isSavedDataModalShow,permissions?.formRequest]);

  useLayoutEffect(() => {
    if (!organizations?.length) {
      tokens?.access && dispatch(fetchOrganizations(tokens.access));
    }
  }, [tokens?.access, dispatch, organizations]);

  const [organizationName, departmentName] = useMemo(() => {
    const foundOrganization: Organization =
      organizations.find(({id}) => id === (organization || savedUserProfile?.organization)) ||
      ({} as Organization);
    const organizationLocalName = foundOrganization.nameLocalRus ?? "";
    const departmentLocalName = foundOrganization
      .organizationDepartment?.find(
        ({id}) => id === (department || savedUserProfile?.department)
      )?.nameLocalRus ?? "";
    return [organizationLocalName, departmentLocalName];
  }, [organization, organizations, department,savedUserProfile]);

  const submitForm = useCallback((data: updateProfileType) => {
    const formattedData = {
      ...data,
      phoneNumber: formatPhoneNumber(data.phoneNumber),
    };

    
    setdisabledEdit(true)
    setSavedUserProfile(formattedData)

  }, [setdisabledEdit,setSavedUserProfile]);

  return (
  <TBlock>
    <TWrapper>
    <TTitleContainer>
    <TTitle>Заполните данные профиля</TTitle>
    </TTitleContainer>
    {userUlid && <TUID userUlid={userUlid}>ID {userUlid}</TUID>}
    <TRowWr direction='flex-end'><TEditButton disabled={!!permissions?.formRequest || isEmailConfirmed || !disabledEdit} onClick={handleEditPage}>Редактировать</TEditButton></TRowWr>
    <PersonalInfoForm
          firstName={firstName}
          lastName={lastName}
          middleName={middleName}
          dateOfBirth={dateOfBirth}
          organization={organization}
          specialization={specialization}
          department={department}
          subdepartment={subdepartment}
          title={title}
          email={userEmail}
          formSubmit={submitForm}
          phoneNumber={phoneNumber}
          disabledEdit={disabledEdit}
          organizationsList={organizations}
          picture={picture}
          activeSendToPrint={!!Object.keys(savedUserProfile ?? {})?.length || !!permissions?.formRequest}
          onPrintClick={() => !permissions?.formRequest ? successModalToggle() : print()}
        />
    </TWrapper>
    <SupportContactWrapper>
        <SupportContactBlock>
          <SupportContactComponent/>
        </SupportContactBlock>
      </SupportContactWrapper>
      {/* MODALS */}
      <Modal isOpen={isEmailConfirmed} handleClose={confirmedModalClose} hasCloseButton height={620}>
          <TModalContent>
            <TSuccessBlock successEmail>
              <Success/>
            </TSuccessBlock>
            <TModalTitle>Ваш email успешно подтвержден!</TModalTitle>
            <TModalSubtitle emailConfirmed>
              Для получения релевантных пользовательских прав Вам необходимо:
            </TModalSubtitle>
            <TModalList>
              <li>указать свои персональные данные в профиле</li>
              <li>
                распечатать заполненную форму (кнопка печати активируется после
                заполнения всех обязательных полей)
              </li>
              <li>
                передать распечатанную форму на подпись лицу, ответственному за
                интеграцию Genetic Lab в Вашем учреждении
              </li>
            </TModalList>
            <TButtonBlock>
              <TButton size={SIZE.MEDIUM} onClick={confirmedModalClose}>
                Понятно
              </TButton>
            </TButtonBlock>
          </TModalContent>
        </Modal>

      <Modal isOpen={isSavedDataModal && !isSavedDataModalShow} handleClose={savedDataModalClose} hasCloseButton height={430}>
          <TModalContent>
            <TSuccessBlock successEmail>
              <Success/>
            </TSuccessBlock>
            <TModalTitle>Ваши данные успешно сохранены</TModalTitle>
            <TModalSubtitle dataDone>
              Передайте распечатанную форму на подпись лицу, отвественному за интеграцию Genetic Lab в Вашем учреждении
            </TModalSubtitle>
            <TButtonBlock>
              <TButton size={SIZE.MEDIUM} onClick={savedDataModalClose}>
                Понятно
              </TButton>
            </TButtonBlock>
          </TModalContent>
        </Modal>

        <Modal isOpen={isSuccessful} handleClose={successModalToggle} hasCloseButton width={577} height={!!permissions?.formRequest ? 450 : 400}>
          <TSuccessBlock sendToPrinter>
            <Bell/>
          </TSuccessBlock>
          <TModalContent>
            <TModalTitle>Печать заявления</TModalTitle>
            <TModalSubtitle align='center' saveData>
              {!!permissions?.formRequest ?
                (`Внесение изменений  больше недоступно. \n
          Распечатайте и передайте распечатанную форму на подпись лицу, ответственному за интеграцию Genetic Lab в Вашем учреждении`) : (
                  `После печати заявления внесение изменений в профиль будет недоступно`)}
            </TModalSubtitle>
            <TButtonsContainer>
              <Button
                variant={VARIANT.TRANSPARENT}
                onClick={successModalToggle}
                size={SIZE.SMALL}
              >
                Вернуться
              </Button>
              <Button 
                onClick={() => {
                successModalToggle();
                return print()}} 
                size={SIZE.SMALL}>
                Распечатать
              </Button>
            </TButtonsContainer>
          </TModalContent>
        </Modal>

      {/* Document for print */}
      <StatementToPrint
          ref={printTemplateRef}
          firstName={firstName ? firstName : savedUserProfile?.firstName ?? ''}
          lastName={lastName ? lastName : savedUserProfile?.lastName  ?? ''}
          middleName={middleName ? middleName : savedUserProfile?.middleName  ?? ''}
          dateOfBirth={dateOfBirth ? dateOfBirth : savedUserProfile?.dateOfBirth  ?? ''}
          organization={organizationName ?? ''}
          specialization={specialization ? specialization : savedUserProfile?.specialization ?? ''}
          department={departmentName  ?? ''}
          title={title ? title : savedUserProfile?.title  ?? ''}
          email={userEmail ?? ''}
          phoneNumber={phoneNumber ? phoneNumber : savedUserProfile?.phoneNumber ?? ''}
        />
    </TBlock>)
}

export default StartPersonalInfoPage;