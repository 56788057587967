import { FC, useCallback, useLayoutEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { getYear } from 'date-fns';
import { TWrapper } from './styled';
import { NUMBERING } from '../constants';
import SlideBlock from '../SlideBlock';

interface ISlideBlockSection {
  blockIndex: number;
}


const currentYear = getYear(new Date());

const SlideBlockSection: FC<ISlideBlockSection> = ({ blockIndex }) => {
  const { control } = useFormContext();
  const PREFIX = `blocks.${blockIndex}.glasses`;

  const { fields, append, remove } = useFieldArray({
    control,
    name: PREFIX
  });

  const numberingFieldValue = useWatch({ control, name: 'numbering' });

  const appendGlass = useCallback(() => {
    append({
      startCode: '',
      endCode: '',
      amount: '1',
      year: `${currentYear}`,
      code: '',
      codeNumber: '',
    }, { shouldFocus: false });
  }, [append]);
  
  const addSlide = useCallback(() => {
    appendGlass();
  }, [appendGlass]);
  
  useLayoutEffect(() => {
    if(!fields?.length){
      appendGlass();
    }
  },[fields,appendGlass]);

  return (
    <TWrapper>
      {fields.map((item: any, index: any) => (
        <SlideBlock
          standardNumbering={numberingFieldValue === NUMBERING.STANDARD}
          key={item.id}
          slideIndex={index}
          blockIndex={blockIndex}
          onSlideRemove={remove}
          onSlideAdd={addSlide}
          disabledRemove={fields.length < 2}
          isFirstSlide={index === 0}
        />
      ))}

    </TWrapper>
  );
}

export default SlideBlockSection;
