import styled from 'styled-components';
import Tooltip from '../../../shared/Tooltip';

export const TTooltip = styled(Tooltip)`
  width: max-content;
  max-width: max-content;
  padding: 26px 30px 28px 30px !important;
  font-size: 12px;
`;

export const TTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* color: ${({ theme }) => theme.colors.black[200]}; */
`;
export const TContent = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* color: ${({ theme }) => theme.colors.black[200]}; */
  /* color:red; */
`;
