import styled from 'styled-components';

interface ITInput {
  error?: boolean | null;
  hasIconRight?: boolean;
  hasIconLeft?: boolean;
  bordered?: boolean;
  className?:string;
  marginTop?:number;
  readOnly?:boolean;
}

const TInputWr = styled.div`
  width: 100%;
  position: relative;
  font: inherit;
`;

const TInput = styled.input<ITInput>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  box-sizing: border-box;
  padding: 18px ${({hasIconRight}) => hasIconRight ? '64px' : '30px'} 18px ${({hasIconLeft}) => hasIconLeft ? '62px' : '16px'};
  border: 1px solid ${({
                         error,
                         bordered,
                         theme
                       }) => error ? theme.colors.alert.warning : bordered ? theme.colors.gray[400] : 'transparent'};
  outline: none;
  color: ${({theme, readOnly}) => readOnly ? theme.colors.gray[500] : theme.colors.black[200]};
  margin-top: ${({marginTop}) => marginTop ? `${marginTop}px` : 0};

  &::placeholder {
    color: ${({theme}) => theme.colors.gray[200]};
    opacity: 1;
  }

  &:disabled {
    color: #777777;
    /* border-color: #CECECE; */
  }

  &:not(:disabled):hover {
    border-color: #B7B5F1;
  }
  &:active,:focus {
    border-color: #9578E9;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: number-input;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & :read-only {
    cursor:default;
  }
`;

const TLabel = styled.label`
  margin-bottom: 12px;
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${({theme}) => theme.colors.gray[500]};
`;

const TIconWr = styled.div<{ withLabel: boolean, isLeft?: boolean }>`
  position: absolute;
  left: ${({isLeft}) => isLeft ? '14px' : 'unset'};
  right: ${({isLeft}) => isLeft ? 'unset' : '24px'};
  top: ${({withLabel}) => withLabel ? '24px' : 0};
  bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TError = styled.span`
  padding: 0 12px;
  color: ${({theme}) => theme.colors.alert.warning};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export {TInputWr, TIconWr, TError, TInput, TLabel};
