import { FC } from 'react';
import { TCircle, TStepWrapper, TTitle } from './styled';

interface IStep {
  index: number;
  active: boolean;
  completed: boolean;
  title: string;
  canceled?: boolean;
  done?: boolean;
}

const Step: FC<IStep> = ({ index,done, title, active, completed, canceled }) => {
  return (
    <TStepWrapper active={active} completed={completed} canceled={canceled} done={!!done}>
      <TCircle></TCircle>
      <TTitle>{title}</TTitle>
    </TStepWrapper>
  );
};

export default Step;
