import { FC, useMemo } from 'react';
import { getYear, getMonth } from 'date-fns';
import { TMonthSelect, TWrapper, TYearSelect } from './styled';
import { MONTHS } from '../../../../constants/locales';

const months = Object.keys(MONTHS);

export interface IDayPickerHeader {
  className?: string;
  month: Date;
  onMonthSelect: (value: number) => void;
  onYearSelect: (value: number) => void;
  yearsRange?: {
    from?: number;
    to?: number;
  };
}

const DayPickerHeader: FC<IDayPickerHeader> = ({ className, onMonthSelect, onYearSelect, month, yearsRange }) => {
  // @ts-ignore
  const monthsOptions = useMemo(() => months.map((label, index) => ({ label: MONTHS[label.toUpperCase()], value: index })), []);
  const yearsOptions = useMemo(() => {
    const currentYear = getYear(new Date());
    let yearsList: number[] = [currentYear];

    if (!yearsRange) {
      return yearsList.map((item) => ({ value: item, label: `${item}` }));
    }

    if (yearsRange.from && yearsRange.from >= 1) {
      let i = 1;
      while (i <= yearsRange.from) {
        yearsList = [...yearsList, currentYear - i];
        i += 1;
      }
      yearsList.reverse();
    }

    if (yearsRange.to && yearsRange.to >= 1) {
      let i = 1;
      while (i <= yearsRange.to) {
        yearsList = [...yearsList, currentYear + i];
        i += 1;
      }
    }
    return yearsList.map((item) => ({ value: item, label: `${item}` }));
  }, [yearsRange]);

  return (
    <TWrapper className={className}>
      <TMonthSelect onChange={(value) => onMonthSelect(+value)} value={getMonth(month)} options={monthsOptions} />
      <TYearSelect
        isYearSelect
        onChange={(value) => onYearSelect(+value)}
        value={getYear(month)}
        options={yearsOptions}
      />
    </TWrapper>
  );
};

export default DayPickerHeader;
