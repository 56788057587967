import styled from 'styled-components';
import Button from '../../shared/Button';

export const TForm = styled.form`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 35px;
  grid-row-gap: 20px;
  position: relative;
`;

export const TButton = styled(Button)`
  margin-top: 6px;
  justify-self: end;
  align-self: flex-end;
  grid-column : span 3;
`;

export const TPictureBlock = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
`

export const TPhotoBlock = styled.div`
  width: 114px;
  height: 114px;
  border: 1px solid #777;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const TLabel = styled.div`
  width: 200px;
  font-size: 20px;
  color: #7A78E9;
  small {
    display: block;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.alert.warning};
  }
`

export const TBlockUploadPhoto = styled.div`
  display: flex;
  margin-bottom: 44px;
`

export const TBlockPhoto = styled.div`
  align-items: center;
  justify-content: center;
  margin: auto
`

export const TInput = styled.input`
  color: white;
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin-top: 1rem;
`

export const TLabelBlock = styled.div`
  display: flex;
  align-items: center;
  padding-left: 28px;
  justify-content: center;
`
export const TPhoto = styled.img`
  width: 116px;
  height: 116px;
  object-fit: cover;
  border-radius: 50%;
`