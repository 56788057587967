export const formatPhoneNumber = (phone: string) => phone.replace(/[^0-9+]/g, '');

export const transformBloodId = (id: string) =>
  id.length < 9 ? id.padStart(9, '0') : parseInt(id, 10);

//convert a Unicode string to a string in which each 16-bit unit occupies only one byte
export const toBinary = (string:string) => {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = string.charCodeAt(i);
    }
    const charCodes = new Uint8Array(codeUnits.buffer);
    let result = '';
    for (let i = 0; i < charCodes.byteLength; i++) {
      result += String.fromCharCode(charCodes[i]);
    }
    return result;
  }

export const fromBinary = (binary:any) => {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  const charCodes = new Uint16Array(bytes.buffer);
  let result = '';
  for (let i = 0; i < charCodes.length; i++) {
    result += String.fromCharCode(charCodes[i]);
  }
  return result;
}

export const capitalizeFirstLetter = (text:string = '') => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
// string to camelCase from snake_case
export const snakeToCamelCase = (str:string) => str.replace(/([-_][a-z])/gi, ($1) => {
  return $1.toUpperCase().replace('-', '').replace('_', '');
});

// string to snake_case from camelCase
export const camelToSnakeCase = (str:string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const objectToSnakeCase = (obj:any,exclude:string[] = []) => {
  const isArray = function (a:any[]) {
    return Array.isArray(a);
  };
  const isObject = function (o:any) {
    return o === Object(o) && !isArray(o) && typeof o !== 'function';
  };
  if (isObject(obj)) {
    const n: { [index: string | number]: any; } = {};

    Object.keys(obj)
      .forEach((k:string) => {
        if(exclude.includes(k)) n[k] = obj[k]
        else n[camelToSnakeCase(k)] = objectToSnakeCase(obj[k],exclude);
      });

    return n;
  } else if (isArray(obj)) {
    return obj.map((i:any) => {
      return objectToSnakeCase(i,exclude);
    });
  }

  return obj;
}