import {IAppState} from "../index";
import {createSelector} from "@reduxjs/toolkit";


const getState = (state: IAppState) => state.research;

export const getResearchesList = createSelector(getState, state => state.data);
export const getResearchListLoading = createSelector(getState, state => state.loading);
export const getResearchFilterData = createSelector(getState, state => state.filter);
export const getResearchCurrentPage = createSelector(getState, state => state.filter.currentPage);
export const getResearchTotalPageCount = createSelector(getState, state => state.count);
export const getResearchTotalPages = createSelector(getState, state => state.pages);
export const getResearchTabTypes = createSelector(getState, state => state.types);
