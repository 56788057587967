import { FunctionComponent, ButtonHTMLAttributes } from 'react';
import { TButton, TButtonText } from './styled';
import { SIZE, VARIANT } from './const';

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: HTMLCollection | string | JSX.Element | JSX.Element[];
  variant?: VARIANT;
  disabled?: boolean;
  size?: SIZE;
  className?: string;
}

const Button: FunctionComponent<IButton> = ({ children, ...props }) => (
  <TButton {...props}>
    <TButtonText>{children}</TButtonText>
  </TButton>
);

export default Button;
