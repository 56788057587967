import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as CancelIcon } from '../../../icons/cross-red.svg';
import { ReactComponent as ArrowSelect } from '../../../icons/arrow-select.svg';
import Input from '../Input';

export const TWraper = styled.div`
  padding-bottom: 34px;
   table.isMenuOpen tbody {
    border-bottom-right-radius: 0px;
  }
`;

export const TTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border: 1px solid ${({ theme }) => theme.colors.gray[500]};
  border-radius: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  

  .blue {
    background-color: ${({ theme }) => theme.colors.blue[50]};
  }

  .violet {
    background-color: ${({ theme }) => theme.colors.violet[100]};
  }

  .violetText {
      color: ${({ theme }) => theme.colors.violet[500]};
  }

  tr.center th{
    text-align: center;
  }

  tr.center td{
    text-align: center;
  }

  th {
    text-align: left;
    padding-left: 16px;
    color: ${({ theme }) => theme.colors.gray[600]};
    :last-child {
      border-top-right-radius: 10px;
    }
  }

  th, td {
    position: relative;
    height: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-image: linear-gradient( to bottom, transparent,#CECECE,transparent) 1 100%;
    border-right: none;
    :first-child {
      border-left: none;
    }
    ::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(206, 206, 206, .2);
      background-image: linear-gradient(90deg,transparent,#CECECE 50%,transparent);
    }
  }

  td:last-child {
    border-bottom-right-radius: 10px;
  }
  tr:last-child td:before {
    background-image: none;
    background-color: transparent;
  }
`;

export const THead = styled.thead`
  height: 51px;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  /* color: ${({ theme }) => theme.colors.gray[500]}; */
  .center {
    text-align: center;
  }
  background-color: ${({ theme }) => theme.colors.violet[100]};
 
  td:empty {
    width: 60px;
    max-width: 60px;
    min-width: 60px;

}

& :first-child{
  & :first-child{
    border-bottom-left-radius: 0px;
    border-top-left-radius: 10px;
    }
  & :last-child {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 10px;
    }
  }
`;

export const TBody = styled.tbody`
  box-sizing: border-box;
  position: relative;
  height:auto;
  tr {
    height:auto;
    min-height: 62px;
    width: 100%;
    position: relative;
  }
  td {
    position: relative;
    border: 1px solid transparent;
    border-right-color: rgba(206, 206, 206, .2);
    :first-child div{
      padding: 12px 0px 12px 16px;
      width: calc(100% - 16px);
      &.alignLeft{
        display: flex;
        justify-content: flex-start;
      }
    }
    :nth-child(2) div{
      /* padding: 12px 0; */
      padding: 12px 0px 12px 16px;
      width: calc(100% - 16px);
      text-align: center;
    }
    .error {
      border-color: red;
    }
    
  }
  tr.isMenuOpen {
    
    td:nth-child(1){
      border: 1px solid  ${({ theme }) => theme.colors.product.primaryActive};
      &.error {
        border-color: red;
      }
    }
    td:nth-child(2){
      border: 1px solid  ${({ theme }) => theme.colors.product.primaryActive};
      border-left: none;
      &.error {
        border-color: red;
      }
    }
  }
  tr:last-of-type {
    td::before {
      background-image: none;
    }
  }
  tr:first-child td:last-child{
    border-bottom-right-radius: 0px;
  }
  td.delete-row{
    text-align: center;
    vertical-align: middle;
    width: 60px;
    max-width: 60px;
    min-width: 60px;
  }
  & td.error {
      border-color: red;
    }
`;



export const TInvertPlusIcon = styled(PlusIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const TAddButton = styled.div<{ disabled?: boolean, linkStyle?: boolean }>`
  display: flex;
  align-items: center;
  margin: 20px 0;
  color: ${({ theme, disabled }) => disabled ? theme.colors.gray[200] : theme.colors.gray[500]};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: ${({ linkStyle }) => linkStyle ? 'underline' : 'none'};
  cursor: ${({ linkStyle }) => linkStyle ? 'pointer' : 'default'};
  
  & ${TInvertPlusIcon} {
    path {
      stroke: ${({ theme, disabled }) => disabled ? theme.colors.gray[200] : theme.colors.white};
    }
  }
  
  & > *:first-child {
    margin-right: 16px;
    width: 40px;
    height: 40px;
  }
`;

export const TArrowSelect = styled(ArrowSelect) <{ open?: boolean }>`
  display: block;
  position: absolute;
  right: 17px;
  bottom: calc(50% - 8px);
  width: 16px;
  height: 16px;
  transform: ${({ open }) => open ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const TMenuIcon = styled(CancelIcon) <{ disabled?: boolean, size?: number, left?: number, right?: number }>`
  display: inline-block;
  align-self: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  width:  ${({ size }) => size ? `${size}px` : '22px'};
  height: ${({ size }) => size ? `${size}px` : '22px'};
  margin-left:  ${({ left }) => left ? `${left}px` : '0px'};
  margin-right: ${({ right }) => right ? `${right}px` : '0px'};
  background-color: ${({ theme }) => theme.colors.gray[100]};
  path {
    stroke: ${({ theme }) => theme.colors.gray[600]};
  }
  :hover {
    path {
      stroke: ${({ theme, disabled }) => disabled ? theme.colors.gray[600] : theme.colors.alert.warning};
    }
  }
`;

export const TSelectBage = styled.span<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  width: max-content;
  height: 36px;
  padding: 0 11px;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  margin-right: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray[100]};
  border-radius: 22px;
`;

export const TSelectBageDel = styled(CancelIcon) <{ disabled?: boolean }>`
  display: ${({ disabled }) => disabled ? 'none' : 'inline-block'};
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border-radius: 50%;
  border:1px solid ${({ theme }) => theme.colors.product.primaryActive};
  margin-left: 8px;
  path {
    stroke: ${({ theme }) => theme.colors.product.primaryActive};
  }
  :hover {
    border-color: ${({ theme }) => theme.colors.alert.warning};
    path {
      stroke: ${({ theme }) => theme.colors.alert.warning};
    }
  }
`;

export const TSelectBageWr = styled.div<{ disabled?: boolean; }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center; 
   /* ${({ disabled }) => disabled ? 'flex-start' : 'center'}; */
  justify-content: center;
  /* ${({ disabled }) => disabled ? 'flex-start' : 'center'}; */
`;

export const TSelectOptions = styled.ul`
  position: absolute;
  display: block;
  table-layout: fixed;
  margin-right: 20px;
  margin-top: -0px;
  box-sizing: border-box;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.product.primaryActive};
  padding: 19px 11px;
  width: calc(100% - 106px) !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: -1px 8px 15px -2px rgba(0,0,0,0.79);
  -webkit-box-shadow: -1px 8px 15px -2px rgba(0,0,0,0.79);
  -moz-box-shadow: -1px 8px 15px -2px rgba(0,0,0,0.79);

  li.optionDataRow {
    display: flex;
    align-items: stretch;
    width: 100%;
    position: relative;
  }
  
  li.optionDataRow:hover{
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};  
    div.fistColumn, div.secondColumn {
      background-color: ${({ theme }) => theme.colors.product.primaryActive};
    };
    div:first-child { border-top-left-radius: 10px; }
    div:last-child { border-top-right-radius: 10px; }
    
    div:first-child { border-bottom-left-radius: 10px; }
    div:last-child { border-bottom-right-radius: 10px; }  
  }
  div.fistColumn {
    border: 1px solid transparent;
    border-right-color: rgba(206, 206, 206, .2);
    &.selectAll {
      border-right-color: transparent;
      b {
        cursor: pointer;
      }
    }
  }
   div.fistColumn, div.secondColumn {
    align-self: stretch;
    width: 48%;
    padding: 12px 16px;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

`;

export const TAddFieldBtnWr = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TForm = styled.div`
  /* display: flex;
  flex-direction: column;
  flex-wrap: wrap; */
  position: relative;
`;

export const TFieldWr = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  border: 1px solid ${({ theme }) => theme.colors.gray[100]}; 
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: none;
  box-shadow: 6px 12px 20px 0px rgba(88, 88, 88, 0.3);
`;

export const TCustomInput = styled(Input)<{ width?: string, background?: string, disabled?:boolean }>`
  width: ${({width}) => width ? width : '100%'};
  padding-bottom: 16px;
  position: relative;
  font-size: 14px;
  margin: 0;
  label {
    font-size: 14px;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
  & > div > div {
    display: ${({disabled}) => disabled ? 'none' : 'flex'};
    right: 0;
    top: -2px;
  }

  & :read-only {
    cursor: default;
  }

  & input {
    padding: 18px;
    background-color: ${({background}) => background ? background : '#fff'};
  }
`;

export const TAddOptionButton = styled.button<{marginBottom?:number, disabled?:boolean}>`
  margin-top: 16px;
  margin-bottom: ${({marginBottom}) => marginBottom ? `${marginBottom}px` : '0px'};
  padding: 4px 20px;
  color: ${({theme, disabled}) => disabled ? theme.colors.gray[300] : theme.colors.white};
  background-color: ${({theme,disabled}) => disabled ? 'transparent' : theme.colors.product.primaryActive };
  border: ${({theme, disabled}) => disabled ? `2px solid ${theme.colors.gray[300]}` : 'none'};
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  outline: none;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer' };
  &:hover {
    background-color: ${({theme, disabled}) => disabled ? 'transparent' : theme.colors.product.primaryHover };
  }

`;