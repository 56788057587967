import { FC } from 'react';
import { TTitle } from './styled';

interface IFormTitle {
  className?: string;
  smallSizeText?: boolean;
}

const FormTitle: FC<IFormTitle> = ({ children, className, ...props }) => (
  <TTitle className={className} {...props}>{children}</TTitle>
);

export default FormTitle;
