import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  TButton,
  TFieldsContainer,
  TCreateAccountText,
  TCreateAccountLink,
  TForgotPasswordLink,
  TFormTitle,
  TInput,
  TFormErrorText,
  TModalText,
} from './styled';
import { getErrorMessage, validations } from '../../../utils/validations';
import FormContainer from '../../shared/FormContainer';
import { ROUTES } from '../../../constants/routes';
import InputPassword from '../../shared/InputPassword';
import { ErrorsType, Nullable } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { checkAnotherSessionExist } from '../../../store/auth/selectors';
import InfoModal from '../../shared/InfoModal';
import { ReactComponent as WarnIcon } from '../../../icons/warn-red-circle.svg';
import Button, { SIZE, VARIANT } from '../../shared/Button';
import { checkSessionBeforeSignIn } from '../../../store/auth/thunkActions';
import { resetauthSessionExist } from '../../../store/auth';

interface ILoginForm {
  formSubmit: (data: { email: string; password: string; }) => void;
  errors?: Nullable<ErrorsType>;
  loading?: boolean;
}

const defaultValues = {
  email: '',
  password: '',
}

const LoginForm: FC<ILoginForm> = ({ formSubmit, errors, loading }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState, clearErrors,reset } = useForm({ mode: 'onSubmit', defaultValues});
  const isAnotherSessionExist = useSelector(checkAnotherSessionExist);
  const [showModal, setShowModal] = useState(false);
  const [userCredentials, setUserCredentials] = useState<{email: string; password: string;}>(defaultValues);

  const onModalToggle = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);


  const onSubmitLogin = useCallback((data:{email: string; password: string;}) => {
    clearErrors(['email', 'password'])
    formSubmit(data)
  },[formSubmit,clearErrors]);

  const onCheckAnotherSession = useCallback((data:{email: string; password: string;}) => {
    setUserCredentials(data);
    dispatch(checkSessionBeforeSignIn(data));
  },[setUserCredentials,dispatch]);

  const setFormDefaults = useCallback(() => {
    setShowModal(false);
    dispatch(resetauthSessionExist());
    setUserCredentials(defaultValues);
    reset(defaultValues);
  },[dispatch,reset,setUserCredentials,setShowModal]);

  useEffect(() => {

    if(isAnotherSessionExist === true) setShowModal(true);

    if(isAnotherSessionExist === false){
      let userData = {...userCredentials};
      if(!!userData?.email && !!userData?.password){
        setFormDefaults();
        onSubmitLogin(userData);
      };
    }

  },[isAnotherSessionExist,setShowModal,onSubmitLogin,userCredentials,setFormDefaults]);

  const handleModalAnswer = useCallback((answer:boolean) => {
    let userData = {...userCredentials}
    if(answer){
      setFormDefaults();
      !!userData?.email && !!userData?.password && onSubmitLogin(userData);
    }
    if(!answer) setFormDefaults();
  },[setFormDefaults,userCredentials,onSubmitLogin]);

  return (
    <FormContainer onSubmit={handleSubmit(onCheckAnotherSession)}>
      <TFormTitle>Вход в систему</TFormTitle>
      <TFieldsContainer>
        <TInput marginTop={12}
          bordered
          label='Email'
          placeholder='Введите email'
          {...register('email', validations.email)}
          error={!!formState.errors.email || !!(errors?.['email'])}
          errorText={getErrorMessage(formState.errors, 'email', errors)}
        />
        <InputPassword marginTop={12}
          register={register('password', { ...validations.password })}
          label='Пароль'
          placeholder='Введите пароль'
          error={!!formState.errors.password || !!(errors?.['password'])}
          errorText={getErrorMessage(formState.errors, 'password', errors)}
        />
        {errors?.['detail'] && <TFormErrorText>{getErrorMessage(formState.errors, 'detail', errors)}</TFormErrorText>}
        {/* {errors?.['email'] && <TFormErrorText>{getErrorMessage(formState.errors, 'email', errors)}</TFormErrorText>} */}
        <TButton
          disabled={formState.isSubmitting || loading}
        >
          Войти в систему
        </TButton>
        <TCreateAccountText>У меня нет учетной записи.</TCreateAccountText>
        <TCreateAccountLink to={ROUTES.SIGN_UP}>Создать</TCreateAccountLink>
        <TForgotPasswordLink to={ROUTES.RESTORE_PASSWORD_REQUEST}>Забыли пароль?</TForgotPasswordLink>
      </TFieldsContainer>
      <InfoModal
        title='Активные сеансы'
        showModal={showModal}
        onModalToggle={onModalToggle}
        isWarning={true}
        icon={<WarnIcon />}
        buttons={
          <>
          <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={() => handleModalAnswer(false)}>Нет</Button>
          <Button size={SIZE.SMALL} onClick={() => handleModalAnswer(true)}>Да</Button>
        </>
        }
      >
      <TModalText> Мы обнаружили у вас другую активную сессию.  Хотите выйти на всех устройствах кроме этого?</TModalText>
    </InfoModal>
    </FormContainer>
  );
};

export default LoginForm;
