import { FC } from 'react';
import { TContainer, TBackgroundWr, TSimpleLoader } from './styled';

export interface ILoader {
  transparent?: boolean;
  enabled?: boolean;
}

const Loader: FC<ILoader> = ({ transparent, children, enabled, ...props }) => (
  <TContainer>
    {children}
    {enabled && (
      <TBackgroundWr transparent={transparent} enabled={enabled} {...props}>
        <TSimpleLoader data-container='loadingIndicator'>
          <div>
            <div />
          </div>
        </TSimpleLoader>
      </TBackgroundWr>
    )}
  </TContainer>
);

export default Loader;
