import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { format } from 'date-fns';

import { fetchAnalysisByBluprint, patchExperimentUpdate, postSaveExperiment, resetAnalysisByBluprint } from "../../../../store/analysis/thunkActions";
import { checkPermitCreateSSExperement, getProfile, getTokens } from "../../../../store/auth/selectors";
import arrowSelectIcon from '../../../../icons/arrow-select.svg';
import currentStatusIcon from '../../../../icons/attention.svg';
import defaultStatusIcon from '../../../../icons/done-gray.svg';
import { TEditButton, TRowWr } from "../../MarkersValidation/styled";
import Textarea from "../../../../components/shared/Textarea";
import { Table, TBody } from "../../../../components/shared/BiomaterialTable";
import defaultTheme from "../../../../styles/theme";
import { TCheckbox, TDetails, TDetailsContent, TSummary, TSummaryContent, TSummaryIcon } from "../../../../components/shared/Details/styled";
import { TWrapper, TExecutor, TButtonWrapper, CustomButton, customStylesOptions, TOtherExecutors, TDateOfCompletion } from "../styled";
import { GeneMutation, IAnalisysByMethod, MarkerGene, MarkerConclusion, Result, SaveAnalysisExperiment, GenesSs, SaveExperimentUpdate } from "../../../../store/analysis/model";
import { getAndOverWriteEperement } from "../../../../store/molProConclusion/thunkActions";
import { ReactComponent as WarnIcon } from '../../../../icons/warn-red-circle.svg';
import InfoModal from "../../../../components/shared/InfoModal";
import Button, { SIZE, VARIANT } from "../../../../components/shared/Button";
import { IBlueprintAdvisors, IExamExecutors } from "../CreateExperiment";

interface IExecutorOptions {
  value: string;
  label: string;
  disabled: boolean;
  title: string;
  fullName: string;
}

interface IComponentProps {
  flowStepsActiveIndex: any;
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  executorOptions?: IExecutorOptions[];
  isActive:boolean;
  defaultValues?: any;
  ableToUpdate?: boolean;
  isReferralComplite: boolean;
  referralULID: string;
  examExecutors: IExamExecutors;
  [index: string]: any;
}

interface ISelectOptions {
  value: string | number;
  label: string | number;
  disabled?: boolean;
  gene_ss_identifier?: number;
  genes_ss?: number;
}


const ExperimentSS: FC<IComponentProps> = ({ referralULID, isReferralComplite, flowStepsActiveIndex, analisysByMethod, isActive, defaultValues, ableToUpdate, examExecutors }) => {
  const { register, control, watch, handleSubmit, formState: { errors },setError, reset, clearErrors, resetField, setValue, getValues } = useForm();

  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const profile = useSelector(getProfile);
  //Permissions
  const ableToCreateSSExperement = useSelector(checkPermitCreateSSExperement);

  const [isEdit, setEdit] = useState<boolean>(false);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const [conclusionKey, setConclusionKey] = useState<number>(Date.now());

  const [conclusionOptions, setConclusionOptions] = useState<ISelectOptions[]>([]);
  //mutations result options by mutation name
  const [ssResultOptions,  setSsResultOptions]  = useState<{[index: string]: ISelectOptions[];}>({undefined:[{ label: '--', value: '', disabled: true}]});

  const [isConclusionDisabled,setConclusionDisabled] = useState<boolean>(true);

  const watchShowManualConclusion = watch('conclusionResult');

  const [executorfullName, executorTitle] = useMemo(()=> {
    if(examExecutors?.blueprintExecutors?.length){
      let data = examExecutors?.blueprintExecutors?.[0]
      let {firstName = '', lastName = '',middleName = '',specialization = ''} = data;
      return [`${lastName} ${firstName} ${middleName}`.trim(), specialization];
    }
    if(!profile) return ['','']
    let {firstName = '', lastName = '',middleName = '',title = ''} = profile;
    const executorfullName = `${lastName} ${firstName} ${middleName}`.trim();
    return [executorfullName,title];
  },[profile,examExecutors]);

  const analyticalSensSS = useMemo(() => {
    if (!analisysByMethod?.markerSs) return;
    const markerSs = analisysByMethod?.markerSs;
    const minSense = markerSs?.analyticalSensMin;
    const maxSense = markerSs?.analyticalSensMax;
    //analytical sensitivity options
    let analyticalSensOptions:ISelectOptions[] = [];
    for (let index = minSense; !(index > maxSense); index++){
      analyticalSensOptions.push({ label: index.toString(), value: index, disabled:false })
    }

    return analyticalSensOptions;
  }, [analisysByMethod?.markerSs]);

  const setDefaultValues = useCallback(() => {
    if(!!defaultValues && Object.keys(defaultValues)?.length){
      let {analyticalSensitivity:sens, notes, experimentSsConclusions, experimentSsRuns  = []} = defaultValues ?? {}
      let conclusionResult = experimentSsConclusions?.length ? experimentSsConclusions?.map((text:{id:number,conclusion:string}) => ({value:text.conclusion, label:text.conclusion})) : [];
      const defaults:{ [index: string]: any; } = {
        analyticalSensitivity: sens ? { label: sens?.toString(), value: sens, disabled: false } : undefined,
        description: notes ?? '',
        conclusionResult
      }
      for(let exp of experimentSsRuns){
        defaults[`geneSsIdentifier:${exp.identifierId}__geneSs:${exp.geneId}`] = {
          value:exp.result, 
          label:exp.result, 
          disabled:false, 
          gene_ss_identifier:exp.identifierId, 
          // genes_ss:exp.gene
          genes_ss:exp.geneId
        };
      }
      
    reset({...defaults});

    setResetFormKey(Date.now());
    setConclusionDisabled(!ableToUpdate);
    return true;
    }
  

return false;
},[defaultValues,setResetFormKey,reset,setConclusionDisabled,ableToUpdate]);


  useLayoutEffect(()=>{

    if(!analisysByMethod?.markerSs?.genesSs?.[0]?.geneSsConclusions) return;
    
    const ConclusionList:ISelectOptions[] = [];
    const markerConclusions: MarkerConclusion[] = analisysByMethod?.markerSs?.genesSs?.flatMap((gen: GenesSs) => gen.geneSsConclusions);
    const uniqOptionNames = new Set<string>();

    for(let conclusion of markerConclusions){

      // let content = `${conclusion.content.replaceAll(' ',"\u2004")};\u2004 `;
      let content = conclusion.content?.replaceAll(/[" ", ;]/g,"\u2004");

      if(uniqOptionNames.has(content)) continue;
      else uniqOptionNames.add(content);

      let conclusionOption = { value: content, label: content };
      ConclusionList.push(conclusionOption);
    }

    setConclusionOptions(ConclusionList) ;

    //mutations result options by mutation name
    if(!analisysByMethod?.markerSs?.genesSs) return;
    let ssOptionsMap:{[index: string]: ISelectOptions[];} = {};
    let genesSs:MarkerGene[] = analisysByMethod?.markerSs?.genesSs;
    if(!genesSs?.length) return;
    for(let mutationSs of genesSs){
      for(let mutation of mutationSs?.geneSsIdentifier ?? []){
          let mutationOptions:ISelectOptions[] = mutation.results.map((result:Result) => {
            return ({value:result.name, label:result.name, disabled:false, gene_ss_identifier:mutation.id, genes_ss:mutationSs.id} )});
          ssOptionsMap[`geneSsIdentifier:${mutation.id}__geneSs:${mutationSs.id}`] = mutationOptions;
        }
    }

    setSsResultOptions(ssOptionsMap);
    setDefaultValues();
  },[analisysByMethod,setSsResultOptions,setConclusionOptions,setDefaultValues]);


  useEffect(() => {
    const subscription = watch((value:any, { name, type }) => {
      if(type === 'change' && name?.includes('geneSsIdentifier') && value){
        let fieldValues = {...value};
        //remove extra fields
        delete fieldValues.conclusionResult;
        delete fieldValues.analyticalSensitivity;
        delete fieldValues.description;
        //convert form field values to array
        const resultFieldsValues:ISelectOptions[]  = Object.values(fieldValues);

        //all results filled
        const isAllResultsFilled = resultFieldsValues.every(option => !!option && !!option?.value);
        if(isAllResultsFilled){ 
          setConclusionDisabled(false);
          setConclusionKey(Date.now());
        };
        
        //reset conclusion and description
        resetField('conclusionResult', undefined);
        setConclusionKey(Date.now());
      }
      if(type === 'change' && (name?.includes('conclusionResult') || name?.includes('description'))){
        let hasConclResultValues = !!(value?.['conclusionResult'] ?? [])?.length;
        let hasDescrResultValues = !!(value?.['description'] ?? [])?.length && (value?.['description'] ?? [])?.length < 999;
        let isMaxDescrValue = (value?.['description'] ?? [])?.length > 999;
        if(hasConclResultValues || (hasDescrResultValues && !isMaxDescrValue)){
          clearErrors(['conclusionResult','description']);
        } 
        if(!hasConclResultValues && !hasDescrResultValues){
          setError('conclusionResult',{ type: 'custom', message: 'Заполните поле заключения аналитической чувствительности' });
          setError('description',{ type: 'custom', message: 'Заполните поле заключения аналитической чувствительности' });
        }
        if(!!isMaxDescrValue){
          setError('description',{ type: 'custom', message: 'Заполните поле заключения аналитической чувствительности' });
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch,setConclusionDisabled,isConclusionDisabled,conclusionOptions,
    setValue,resetField,setConclusionKey,clearErrors,errors,setError]);


  //send examination to server
  const handleSaveExperiment = useCallback(async(formData:any) => {
    clearErrors();
    const {analyticalSensitivity,conclusionResult,description,...mutationResults} = formData;
    const analysis = analisysByMethod.id;
    const number = analisysByMethod.analysisSsExperiments.length + 1;
    const analytical_sensitivity:number = analyticalSensitivity.value;

    // const originalDescription = getDoctorDiscription(description ?? '');
    const сonclusionSelectedValues = conclusionResult?.map((option: ISelectOptions) => option.value) ?? [];
    // const conclusions = originalDescription.trim() ? сonclusionSelectedValues.concat(originalDescription) : сonclusionSelectedValues;
    const conclusions = [...сonclusionSelectedValues];
    const notes = description;

    let defaultIdForUpdateMap:{[index:string | number]: number } = {}

    if(!!defaultValues?.experimentSsRuns?.length){
      let experimentRuns = defaultValues?.experimentSsRuns ?? [];
      for(let exp of experimentRuns){
        defaultIdForUpdateMap[exp.identifierId] = exp.id;
      }
    }

    const experiment_runs = Object.values(mutationResults).map((result:any) => {
      let gene_ss = result?.genes_ss ?? null;
      let result_ss = result.value
      let identifier_ss = result?.gene_ss_identifier ?? null;
      let id = defaultIdForUpdateMap?.[identifier_ss]
      if(id) return { id, gene_ss,identifier_ss, result_ss}
      return {gene_ss,identifier_ss,result_ss}
    });

    if(!analytical_sensitivity) return setError('analyticalSensitivity',{ type: 'custom', message: 'Заполните поле аналитической чувствительности' });

    const saveExperimentData:SaveAnalysisExperiment = {analysis,number,analytical_sensitivity,experiment_runs,conclusions,notes,date_completed: format(new Date(), 'yyyy-MM-dd HH:mm:ss')};

    if(!defaultValues && tokens?.access){
      try {
        reset({},{keepValues:false})
        setResetFormKey(Date.now())
        await dispatch(postSaveExperiment(tokens?.access, 'ss', saveExperimentData));
      } catch (e) {
        console.log(e)
      }
    }

    if(!!defaultValues && tokens?.access && ableToUpdate) {
      let data:{acceptance:boolean; [index:string]: any} = {...saveExperimentData,acceptance: true, number:defaultValues?.number}
      delete data['date_completed']
      let dataToUpdate:SaveExperimentUpdate = data;
      await dispatch(patchExperimentUpdate(tokens?.access, 'ss', defaultValues.id, dataToUpdate));
      await dispatch(getAndOverWriteEperement(tokens?.access, referralULID));
    }
    dispatch(resetAnalysisByBluprint());
    tokens?.access && setTimeout(() => dispatch(fetchAnalysisByBluprint(tokens?.access, 'ss', analisysByMethod?.blueprint)), 100);
    setEdit(false)

  },[analisysByMethod,setEdit,dispatch,setResetFormKey,reset,ableToUpdate,tokens?.access,
    setError,clearErrors,defaultValues,referralULID]);

  //handle edit tab button
  const handleEditPage = useCallback(async () => {
    if(!ableToCreateSSExperement) return;
    if(isEdit){
      if(!setDefaultValues()){
        reset({}, { keepValues: false });
        setResetFormKey(Date.now());
      }
      return setEdit(false);
    }
    setEdit(true)
  },[isEdit,setEdit,reset,setResetFormKey,ableToCreateSSExperement,setDefaultValues]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const onModalToggle = useCallback(() => setShowConfirmModal(!showConfirmModal), [setShowConfirmModal,showConfirmModal]);

  const dateOfCompletion = useMemo(() => {
    if (!defaultValues?.dateCompleted) return '';

    return format(new Date(defaultValues?.dateCompleted), 'dd.MM.yyyy - HH:mm:ss');
  }, [defaultValues]);
  
  return (
    <TDetails open={isActive || !!defaultValues?.acceptance}>
      <TSummary>
        <TSummaryContent isSelected={true}>
          <TCheckbox disabled={true} checked={defaultValues?.acceptance ?? false}/>
            {!!flowStepsActiveIndex && `Попытка №${defaultValues?.number ?? flowStepsActiveIndex.index}`}
          <TSummaryIcon className="arrow" src={arrowSelectIcon} />
        </TSummaryContent> <TSummaryIcon src={!Object?.keys(defaultValues ?? {})?.length ? currentStatusIcon : defaultStatusIcon}/>
      </TSummary>
      <TDetailsContent>
        {!isReferralComplite && <TRowWr direction='space-between'>
        <TDateOfCompletion>{!!dateOfCompletion && `Дата завершения: ${dateOfCompletion}`}</TDateOfCompletion>
        {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate ? <TEditButton disabled={false} onClick={handleEditPage}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton> : ableToCreateSSExperement && <TEditButton disabled={!isActive} onClick={handleEditPage}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton>}
        </TRowWr>}
        <TWrapper onSubmit={handleSubmit(data => handleSaveExperiment(data))} key={resertFormKey}>
          <Table>
            {/* analytical sensitivity */}
            <TBody>
              <tr>
                <td colSpan={4} className="head violet topRadius">Аналитическая чувствительность метода, %</td>
              </tr>
              <tr>
                <td colSpan={4} className={!!errors?.analyticalSensitivity ? 'error' : ''}>
                  <Controller
                    control={control}
                    name={'analyticalSensitivity'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        onChange={onChange}
                        selected={value}
                        options={analyticalSensSS}
                        placeholder={'Выберите значение из выпадающего списка'}
                        styles={customStylesOptions(defaultTheme)}
                        isDisabled={!isEdit}
                        defaultValue={getValues('analyticalSensitivity')}
                      />
                    )}
                  />
                </td>
              </tr>
            </TBody>
            {/* header */}
            <TBody>
              <tr className="borderTop violet">
                <td className="head">Маркер</td>
                <td className="head">Референсная последовательность</td>
                <td className="head">Экзон / Кодон / Патогенный вариант</td>
                <td className="head noRadius">Результат исследования</td>
              </tr>
            </TBody>
            {/* genes */}
            {!!analisysByMethod?.markerSs && analisysByMethod.markerSs.genesSs.map((gen: MarkerGene, index: number) => (
              
              <TBody key={index + 'genes' + gen.id}>
                {gen?.geneSsIdentifier?.map((mutation: GeneMutation, mutIndex: number) => (
                  <tr key={mutIndex + 'mutation' + gen.id}>
                    {!mutIndex && <td rowSpan={gen?.geneSsIdentifier?.length}>{gen.name}</td>}
                    {!mutIndex && <td rowSpan={gen?.geneSsIdentifier?.length} className="borderRight">{gen.reference ?? '--'}</td>}
                    <td >{mutation.name}</td>
                    <td className={!!errors?.[`geneSsIdentifier:${mutation.id}__geneSs:${gen.id}`] ? 'error' : ''}>
                      <Controller
                        control={control}
                        name={`geneSsIdentifier:${mutation.id}__geneSs:${gen.id}`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={(option) => {
                              option['genes_ss'] = gen.id;
                              option['gene_ss_identifier'] = mutation.id;
                              onChange(option)}}
                            selected={value}
                            options={ssResultOptions[`geneSsIdentifier:${mutation.id}__geneSs:${gen.id}`]}
                            placeholder={'--'}
                            isOptionDisabled={(option: any) => option.disabled}
                            isDisabled={!isEdit}
                            defaultValue={getValues(`geneSsIdentifier:${mutation.id}__geneSs:${gen.id}`)}
                            styles={customStylesOptions(defaultTheme)}
                          />
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </TBody>
            ))} 
            {/* conclusion */}
            <TBody>
              <tr>
                <td colSpan={4} className="head violet">
                  Клинико-лабораторное заключение
                </td>
              </tr>
              <tr>
                <td colSpan={4} className={!!errors?.conclusionResult ? 'error noBorderRadius' : 'noBorderRadius'}>
                  <Controller
                    key={`my_unique_select_key__${conclusionKey}`}
                    control={control}
                    name={'conclusionResult'}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        isMulti
                        onChange={onChange}
                        selected={value}
                        options={watchShowManualConclusion?.length === 2 ? [] : conclusionOptions}
                        placeholder={'Выберите значение из выпадающего списка'}
                        isDisabled={isConclusionDisabled || !isEdit}
                        noOptionsMessage={() => "нет опций, возможно выбрать только 2 варианта"}
                        isSearchable={watchShowManualConclusion?.length !== 2}
                        defaultValue={getValues('conclusionResult')}
                        styles={customStylesOptions(defaultTheme)}
                      />
                    )}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={4} className={!!errors?.description  ? 'error ' : ''}>
                  <Textarea
                    {...register('description',{ required: !watchShowManualConclusion?.length, maxLength: 999 })}
                    key={conclusionKey}
                    placeholder={'И/Или введите текст вручную, если это требуется'}
                    className='conclusionDescription'
                    defaultValue={getValues('description') ?? ''}
                    height={85}
                    readOnly={!isEdit}
                    disabled={!isEdit}
                    maxLength={1000}
                  />
                </td>
              </tr>
          </TBody>
        </Table>

        {(!isActive || !!defaultValues) && !!executorfullName && <TExecutor>
            <b>{executorfullName ?? 'Исполнитель не определен'}</b>
            {executorTitle ? `, ${executorTitle}` : ''}
          </TExecutor>}
        {(!isActive || !!defaultValues) && !!examExecutors?.blueprintAdvisory?.length && <TOtherExecutors height='fit-content'>
            {examExecutors?.blueprintAdvisory?.map((advisor: IBlueprintAdvisors) => (
              <div key={`advisorSS${advisor?.fullName}`}><b>{advisor?.fullName ?? 'Исполнитель не определен'}</b>
              {advisor?.specialization ? `, ${advisor?.specialization}` : ''}</div>
            ))}        
        </TOtherExecutors>}

        {!isReferralComplite && <TButtonWrapper>
          {/* {ableToCreateSSExperement && isActive && <Button size={SIZE.SMALL} variant={VARIANT.BLUE}  disabled={(!isConclusionDisabled && !isEdit) || !isActive}>
              Завершить попытку
            </Button>} */}
          {ableToCreateSSExperement && isActive && <Button size={SIZE.SMALL} variant={!isEdit ? VARIANT.DEFAULT : VARIANT.BLUE} type="submit" disabled={!isEdit}>
              Завершить попытку
            </Button>}
            {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate && <CustomButton type="button" onClick={onModalToggle} disabled={!isEdit}>
              Подтвердить изменения
            </CustomButton>}
        </TButtonWrapper>}
        <InfoModal
            title='Внесенные изменения будут отображены в заключении. '
            showModal={showConfirmModal}
            isWarning={true}
            icon={<WarnIcon />}
            onModalToggle={onModalToggle}
            buttons={<>
              <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onModalToggle}>Вернуться</Button>
              <Button  size={SIZE.SMALL} type="submit" onClick={handleSubmit(data => handleSaveExperiment(data))}>Продолжить</Button>
            </>}
          />
        </TWrapper>
        
      </TDetailsContent>
    </TDetails>
  )
}

export default ExperimentSS;