import { FC } from 'react';
import { TNotEditedInput } from './styled';

export interface INotEditedInput {
  label?: string;
  width?:string;
  className?: string;
  placeholder?: string;
}

const NotEditedInput: FC<INotEditedInput> = ({ className, label, placeholder, width, children }) => {
  return <TNotEditedInput width={width} className={className} dataLabel={label}>{!!children ? children : (!!placeholder ? placeholder : 'Нет данных')}</TNotEditedInput>
}

export default NotEditedInput;