import {FC} from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  TBottomLinkWr,
  TButton,
  TDescription,
  TFieldsContainer,
  TFormTitle,
  TLink,
  TTooltipPasswordPrompt
} from './styled';
import { SIZE } from '../../shared/Button';
import { signUpType } from '../../../store/auth/thunkActions';
import { getErrorMessage, extendedValidations, validations } from '../../../utils/validations';
import FormTitle from '../../shared/FormTitle';
import FormContainer from '../../shared/FormContainer';
import { ROUTES } from '../../../constants/routes';
import InputPassword from '../../shared/InputPassword';
import { ErrorsType, Nullable } from '../../../types';

interface IRestorePasswordForm {
  formSubmit: (data: signUpType) => void;
  errors?: Nullable<ErrorsType>;
  isSubmitted: boolean;
  invalidToken: boolean;
}

const RestorePasswordForm: FC<IRestorePasswordForm> = ({ formSubmit, invalidToken, errors, isSubmitted }) => {
  const { register, handleSubmit, formState, control } = useForm({ mode: 'onSubmit' });

  const password = useWatch({ control, name: 'password' });

  return (
    <FormContainer onSubmit={handleSubmit(formSubmit)}>
      {
        !isSubmitted && !invalidToken ? (
          <>
            <TFormTitle>Восстановление пароля</TFormTitle>
            <TDescription>
              Для восстановления доступа в систему введите новый пароль и подтвердите его
            </TDescription>
            <TTooltipPasswordPrompt id = 'password-prompt' place = 'top' overridePosition={({ left, top }) => ({ left: left - 50, top: top + 10 })}>
              <p>
                Требования к паролю: <br/>
                - минимум 8 символов <br/>
                - латинские буквы и цифры <br/>
                - минимум одна заглавная буква <br/>
              </p>
            </TTooltipPasswordPrompt>
            <TFieldsContainer>
              <div data-for = 'password-prompt' data-tip = ''  >
              <InputPassword marginTop={12}
                register={register('password', { ...validations.password, ...extendedValidations.password })}
                label='Придумайте новый пароль'
                error={!!formState.errors.password || !!(errors?.['password'])}
                errorText={getErrorMessage(formState.errors, 'password', errors)}
              />
              </div>
              <InputPassword marginTop={12}
                register={register('rePassword', { ...validations.rePassword, validate: value =>
                    value === password})}
                label='Введите пароль повторно'
                error={!!formState.errors.rePassword || !!(errors?.['rePassword'])}
                errorText={getErrorMessage(formState.errors, 'rePassword', errors)}
              />
              <TButton disabled={formState.isSubmitting} size={SIZE.LARGE}>Сохранить пароль</TButton>
            </TFieldsContainer>
            <TBottomLinkWr>
              <TLink to={ROUTES.SIGN_IN}>Отменить восстановление</TLink>
            </TBottomLinkWr>
          </>
        ) : (
          !invalidToken ? (
            <>
              <FormTitle smallSizeText>Пароль успешно изменен</FormTitle>
              <TDescription>
                Поздравляем! Теперь Вы можете войти в систему Genetic Lab
              </TDescription>
              <TFieldsContainer>
                <TButton size={SIZE.LARGE}>Войти в систему</TButton>
              </TFieldsContainer>
            </>
          ) : (
            <>
              <FormTitle smallSizeText>Токен невалиден</FormTitle>
              <TDescription invalidToken>
                Ссылка для сброса пароля недействительна.<br/>
                Истек срок действия или ссылка уже использовалась ранее. Пожалуйста,<br/> запросите новый сброс пароля
              </TDescription>
              <TFieldsContainer>
                <TButton size={SIZE.LARGE}>OK</TButton>
              </TFieldsContainer>
            </>
          )
        )
      }
    </FormContainer>
  );
};

export default RestorePasswordForm;
