import Select from 'react-select';
import styled from 'styled-components';
import { CSSProperties } from 'react';
import { defaultThemeType } from '../../../styles/theme';

const customStyles = (theme: defaultThemeType, props?: Record<string, unknown>): Record<string, unknown> => ({
  control: (state: CSSProperties, { isDisabled }: { isDisabled: boolean }) => ({
    ...state,
    outline: 'none',
    transition: 'all 0.2s ease-in-out 0s',
    boxShadow: 'none',
    cursor: 'default',
    padding: '18px 22px 18px 16px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
    borderRadius: '10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: props?.error ? theme.colors.alert.warning : props?.bordered ? theme.colors.gray[500]: 'transparent',
    height: '62px',
    flexWrap: 'nowrap',
    backgroundColor: '#fff',
    'div[class$="singleValue"]': {
      color: isDisabled ? theme.colors.gray[500] : theme.colors.black[200],
    },
    '&:hover': {
      borderColor: isDisabled ? theme.colors.gray[500] : theme.colors.alert.border,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    },
  }),
  option: (state: CSSProperties, { isSelected }: { isSelected: boolean }) => ({
    ...state,
    height: 'auto',
    textAlign: 'left',
    backgroundColor: theme.colors.white,
    border: 'none',
    outline: 'none',
    color: isSelected ? theme.colors.product.primaryActive : theme.colors.black[200],
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out 0s',
    padding: '8px 14px 8px 12px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    '&:hover': {
      color: theme.colors.black[200],
    },
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
    borderRadius: '10px',
    background: theme.colors.white,
    maxHeight: 'none',
    margin: '0 auto',
    zIndex: 12,
  }),
  menuList: (base: CSSProperties) => ({
    ...base,
    borderRadius: '10px',
    background: theme.colors.white,
    padding: '0',
    maxHeight: '224px',
  }),
  dropdownIndicator: (
    state: CSSProperties,
    { selectProps: { menuIsOpen } }: { selectProps: { menuIsOpen: boolean } },
  ) => ({
    ...state,
    cursor: 'pointer',
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'all 0.2s ease-in-out 0s',
    textAlign: 'center',
    margin: menuIsOpen ? '0 0 0 -8px' : '8px 0 0 0',
    width: '30px',
    height: '30px',
  }),
  container: () => ({
    position: 'relative',
  }),
  indicatorsContainer: () => ({}),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    padding: 0,
  }),
  placeholder: (base: CSSProperties) => ({
    ...base,
    color: theme.colors.gray[200],
  }),
});

const TSelect = styled(Select)<{isDisabled?:boolean, isSearchable?:boolean}>`
  color: ${(isDisabled)=> isDisabled ? '#777777' : '#1A1A22' };
  margin:0;
  [class$=ValueContainer] {
    margin-bottom: 2px;
    align-items: center;
  }
  [class$=singleValue] {
    font-size: 16px;
    line-height: 16px;
  }
`;

const TLabel = styled.p<{marginTop?: number}>`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[500]};
  font-weight: 500;
  margin-bottom: ${({marginTop}) => marginTop ? `${marginTop}px` : '12px'};
`;

export { customStyles, TSelect, TLabel };
