import { FunctionComponent, ButtonHTMLAttributes, ReactNode } from 'react';
import { TButton, TIcon, TIconDisabled, TIconHover } from './styled';

export interface IIconButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactNode;
  iconHover?: ReactNode;
  iconDisabled?: ReactNode;
  disabled?: boolean;
  className?: string;
  size?: number;
}

const IconButton: FunctionComponent<IIconButton> = ({ children, iconHover, iconDisabled, icon, size = 32, ...props }) => (
  <TButton {...props} isHoverIcon={!!iconHover} isDisabledIcon={!!iconDisabled} size={size}>
    <TIcon>{icon}</TIcon>
    <TIconHover>{iconHover}</TIconHover>
    <TIconDisabled>{iconDisabled}</TIconDisabled>
  </TButton>
);

export default IconButton;
