import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { format } from 'date-fns';

import { fetchAnalysisByBluprint, patchExperimentUpdate, postSaveExperiment, resetAnalysisByBluprint } from "../../../../store/analysis/thunkActions";
import { checkPermitCreatePCRExperement, getProfile, getTokens } from "../../../../store/auth/selectors";
import arrowSelectIcon from '../../../../icons/arrow-select.svg';
import currentStatusIcon from '../../../../icons/attention.svg';
import defaultStatusIcon from '../../../../icons/done-gray.svg';
import { TEditButton, TRowWr } from "../../MarkersValidation/styled";
import Textarea from "../../../../components/shared/Textarea";
import { Table, TBody } from "../../../../components/shared/BiomaterialTable";
import defaultTheme from "../../../../styles/theme";
import { TCheckbox, TDetails, TDetailsContent, TSummary, TSummaryContent, TSummaryIcon } from "../../../../components/shared/Details/styled";
import { TWrapper, TExecutor, TButtonWrapper, CustomButton, customStylesOptions, TOtherExecutors, TDateOfCompletion } from "../styled";
import { GeneMutation, IAnalisysByMethod, MarkerGene, MarkerConclusion, Result, SaveAnalysisExperiment, SaveExperimentUpdate } from "../../../../store/analysis/model";
import { getAndOverWriteEperement } from "../../../../store/molProConclusion/thunkActions";
import InfoModal from "../../../../components/shared/InfoModal";
import Button, { SIZE, VARIANT } from "../../../../components/shared/Button";
import { ReactComponent as WarnIcon } from '../../../../icons/warn-red-circle.svg';
import { IBlueprintAdvisors, IExamExecutors } from "../CreateExperiment";

interface IExecutorOptions {
  value: string;
  label: string;
  disabled: boolean;
  title: string;
  fullName: string;
}

interface IComponentProps {
  flowStepsActiveIndex?: any;
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  executorOptions?: IExecutorOptions[];
  isActive: boolean;
  defaultValues?: any;
  ableToUpdate?: boolean;
  isReferralComplite: boolean;
  referralULID: string;
  examExecutors: IExamExecutors;
  [index: string]: any;
}

interface ISelectOptions {
  value: string | number;
  label: string | number;
  disabled?: boolean;
  mutation_pcr?: number;
  gene_pcr?: number;
}

const ExperimentPCR: FC<IComponentProps> = ({ referralULID,isReferralComplite,flowStepsActiveIndex, analisysByMethod, isActive, defaultValues, ableToUpdate,examExecutors }) => {
  const { register, control, watch, handleSubmit, formState: { errors }, setError, reset, clearErrors, resetField, setValue, getValues } = useForm();

  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const profile = useSelector(getProfile);
  //Permissions
  const ableToCreatePCRExperement = useSelector(checkPermitCreatePCRExperement);

  const [isEdit, setEdit] = useState<boolean>(false);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const [conclusionKey, setConclusionKey] = useState<number>(Date.now());

  const [conclusionOptions, setConclusionOptions] = useState<ISelectOptions[]>([]);
  //mutations result options by mutation name
  const [pcrResultOptions, setPcrResultOptions] = useState<{ [index: string]: ISelectOptions[]; }>({ undefined: [{ label: '--', value: '', disabled: true }] });

  const [isConclusionDisabled, setConclusionDisabled] = useState<boolean>(true);

  const watchShowManualConclusion = watch('conclusionResult');

  const [executorfullName, executorTitle] = useMemo(()=> {
    if(examExecutors?.blueprintExecutors?.length){
      let data = examExecutors?.blueprintExecutors?.[0]
      let {firstName = '', lastName = '',middleName = '',specialization = ''} = data;
      return [`${lastName} ${firstName} ${middleName}`.trim(), specialization];
    }
    if(!profile) return ['','']
    let {firstName = '', lastName = '',middleName = '',title = ''} = profile;
    const executorfullName = `${lastName} ${firstName} ${middleName}`.trim();
    return [executorfullName,title];
  },[profile,examExecutors]);

  const analyticalSensPCR = useMemo(() => {
    if (!analisysByMethod?.markerPcr) return;
    const markerPcr = analisysByMethod?.markerPcr;
    const minSense = markerPcr?.analyticalSensMin;
    const maxSense = markerPcr?.analyticalSensMax;
    //analytical sensitivity options
    let analyticalSensOptions: ISelectOptions[] = [];
    for (let index = minSense; !(index > maxSense); index++) {
      analyticalSensOptions.push({ label: index.toString(), value: index, disabled: false })
    }

    return analyticalSensOptions;
  }, [analisysByMethod?.markerPcr]);

  const setDefaultValues = useCallback(() => {
    
    if(!!defaultValues && Object.keys(defaultValues)?.length){
      let {analyticalSensitivity:sens, notes, conclusion, experimentRuns = []} = defaultValues ?? {}
      let conclusionResult = conclusion?.length ? conclusion?.split('\n\r')?.map((text:string) => ({value:text, label:text})) : [];

      const defaults:{ [index: string]: any; } = {
        analyticalSensitivity: sens ? { label: sens?.toString(), value: sens, disabled: false } : undefined,
        description: notes ?? '',
        conclusionResult
      }

      for(let exp of experimentRuns){
        defaults[`mutationPcr:${exp.mutationPcrId}__genePcr:${exp.genePcrId}`] = {
          "value":exp.resultPcr,
          "label":exp.resultPcr,
          "mutation_pcr":exp.mutationPcrId,
          "gene_pcr":exp.genePcrId,
          "disabled":false,
          "id":exp.id
        };
      }
      reset({...defaults});

      setResetFormKey(Date.now());
      setConclusionDisabled(!ableToUpdate);
      return true;
  }
  return false;
  },[defaultValues,setResetFormKey,reset,setConclusionDisabled,ableToUpdate]);


  useLayoutEffect(() => {

    if (!analisysByMethod?.markerPcr?.markerPcrConclusions) return;

    const ConclusionList: ISelectOptions[] = [];
    const markerConclusions: MarkerConclusion[] = analisysByMethod.markerPcr.markerPcrConclusions;
    const uniqOptionNames = new Set<string>();

    for (let conclusion of markerConclusions) {
      // let content = `${conclusion.content.replaceAll(' ', "\u2004")};\u2004 `;
      let content = conclusion.content;
      

      if (uniqOptionNames.has(content)) continue;
      else uniqOptionNames.add(content);

      let conclusionOption = { value: content, label: content };

      ConclusionList.push(conclusionOption);
    }
    
    setConclusionOptions(ConclusionList);

    //mutations result options by mutation name
    if (!analisysByMethod?.markerPcr?.genesPcr) return;
    let pcrOptionsMap: { [index: string]: ISelectOptions[]; } = {};
    let genesPcr: MarkerGene[] = analisysByMethod?.markerPcr?.genesPcr;
    if (!genesPcr?.length) return;

    for (let mutationPcr of genesPcr) {
      let gene_pcr = +mutationPcr.id;

      for (let mutation of mutationPcr?.mutations ?? []) {
        let mutationOptions: ISelectOptions[] = mutation.results.map((result: Result) => (
          { value: result.name, label: result.name, disabled: false, mutation_pcr: mutation.id, gene_pcr }));
        pcrOptionsMap[mutation.name] = mutationOptions;
      }
    }

    setPcrResultOptions(pcrOptionsMap);  
    setDefaultValues();
}, [analisysByMethod, setPcrResultOptions, setConclusionOptions, setDefaultValues, defaultValues]);

  useEffect(() => {
    const subscription = watch((value: any, { name, type }) => {
      if (type === 'change' && name?.includes('mutationPcr') && value) {
        let fieldValues = { ...value };
        //remove extra fields
        delete fieldValues.conclusionResult;
        delete fieldValues.analyticalSensitivity;
        delete fieldValues.description;
        //convert form field values to array
        const resultFieldsValues: ISelectOptions[] = Object.values(fieldValues);

        //all results filled
        const isAllResultsFilled = resultFieldsValues.every(option => !!option && !!option?.value);
        if (isAllResultsFilled) {
          setConclusionDisabled(false);
          setConclusionKey(Date.now());
        };

        //reset conclusion and description
        resetField('conclusionResult', undefined);
        setConclusionKey(Date.now());
      }
      if(type === 'change' && (name?.includes('conclusionResult') || name?.includes('description'))){
        let hasConclResultValues = !!(value?.['conclusionResult'] ?? [])?.length;
        let hasDescrResultValues = !!(value?.['description'] ?? [])?.length && (value?.['description'] ?? [])?.length < 999;
        let isMaxDescrValue = (value?.['description'] ?? [])?.length > 999;
        if(hasConclResultValues || (hasDescrResultValues && !isMaxDescrValue)){
          clearErrors(['conclusionResult','description']);
        } 
        if(!hasConclResultValues && !hasDescrResultValues){
          setError('conclusionResult',{ type: 'custom', message: 'Заполните поле заключения аналитической чувствительности' });
          setError('description',{ type: 'custom', message: 'Заполните поле заключения аналитической чувствительности' });
        }
        if(!!isMaxDescrValue){
          setError('description',{ type: 'custom', message: 'Заполните поле заключения аналитической чувствительности' });
        }
      }

    });

    return () => subscription.unsubscribe();
  }, [watch, setConclusionDisabled, isConclusionDisabled, conclusionOptions,
    setValue, resetField, setConclusionKey, clearErrors, errors, setError,getValues]);


  //send examination to server
  const handleSaveExperiment = useCallback(async (formData: any) => {
    clearErrors();
    const { analyticalSensitivity, conclusionResult, description, ...mutationResults } = formData;
    const analysis = analisysByMethod.id;
    const number = analisysByMethod.analysisExperiments.length + 1;
    const analytical_sensitivity: number = analyticalSensitivity.value;

    const сonclusionSelectedValues = conclusionResult?.map((option: ISelectOptions) => option.value) ?? [];

    let defaultIdForUpdateMap:{[index:string | number]: number } = {}

    if(!!defaultValues?.experimentRuns?.length){
      let experimentRuns = defaultValues?.experimentRuns ?? [];
      for(let exp of experimentRuns){
        defaultIdForUpdateMap[exp.mutationPcrId] = exp.id;
      }
    }

    const conclusion = [...сonclusionSelectedValues].join('\n\r');
    const notes = description;

    const experiment_runs = Object.values(mutationResults).map((result: any) => {
      let gene_pcr = +result?.gene_pcr ?? null;
      let result_pcr = result.value
      let mutation_pcr = +result?.mutation_pcr ?? null;
      let id = defaultIdForUpdateMap?.[mutation_pcr]
      if(id) return { id, gene_pcr, mutation_pcr, result_pcr }
      return { gene_pcr, mutation_pcr, result_pcr }
    });

    if (!analytical_sensitivity) return setError('analyticalSensitivity', { type: 'custom', message: 'Заполните поле аналитической чувствительности' });

    const saveExperimentData: SaveAnalysisExperiment = { analysis, number, analytical_sensitivity, experiment_runs, conclusion, notes, date_completed: format(new Date(), 'yyyy-MM-dd HH:mm:ss') };

    if (!defaultValues && tokens?.access) {
      try {
        reset({}, { keepValues: false });
        setResetFormKey(Date.now());
        await dispatch(postSaveExperiment(tokens?.access, 'pcr', saveExperimentData));
      } catch (e) {
        console.log(e);
      }
    }
    if(!!defaultValues && tokens?.access && ableToUpdate) {
      let data:{acceptance:boolean; [index:string]: any} = {...saveExperimentData,acceptance: true, number:defaultValues?.number}
      delete data['date_completed'];
      let dataToUpdate:SaveExperimentUpdate = data;
      await dispatch(patchExperimentUpdate(tokens?.access, 'pcr', defaultValues.id, dataToUpdate));
      await dispatch(getAndOverWriteEperement(tokens?.access, referralULID));
    }

    dispatch(resetAnalysisByBluprint());
    tokens?.access && setTimeout(() => dispatch(fetchAnalysisByBluprint(tokens?.access, 'pcr', analisysByMethod?.blueprint)), 100);
    setEdit(false)
  }, [analisysByMethod, setEdit, dispatch, setResetFormKey, reset, ableToUpdate,
     tokens?.access, setError, clearErrors, defaultValues,referralULID]);



  //handle edit tab button
  const handleEditPage = useCallback(async () => {
    if (!ableToCreatePCRExperement) return;
    if (isEdit) {
      if(!setDefaultValues()){
        reset({}, { keepValues: false });
        setResetFormKey(Date.now());
      }
      return setEdit(false);
    }
    setEdit(true)
  }, [isEdit, setEdit, reset, setResetFormKey, ableToCreatePCRExperement, setDefaultValues]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const onModalToggle = useCallback(() => setShowConfirmModal(!showConfirmModal), [setShowConfirmModal,showConfirmModal]);

  const dateOfCompletion = useMemo(() => {
    if (!defaultValues?.dateCompleted) return '';

    return format(new Date(defaultValues?.dateCompleted), 'dd.MM.yyyy - HH:mm:ss');
  }, [defaultValues]);

  return (
    <TDetails open={isActive || !!defaultValues?.acceptance}>
      <TSummary>
        <TSummaryContent isSelected={true}>
          <TCheckbox disabled={true} checked={defaultValues?.acceptance ?? false}/>
          {!!flowStepsActiveIndex && `Попытка №${defaultValues?.number ?? flowStepsActiveIndex.index}`}
          <TSummaryIcon className="arrow" src={arrowSelectIcon} />
        </TSummaryContent> <TSummaryIcon src={!Object?.keys(defaultValues ?? {})?.length ? currentStatusIcon : defaultStatusIcon} />
      </TSummary>
      <TDetailsContent>
      {!isReferralComplite && <TRowWr direction='space-between'>
          <TDateOfCompletion>{!!dateOfCompletion && `Дата завершения: ${dateOfCompletion}`}</TDateOfCompletion>
          {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate ? <TEditButton disabled={false} onClick={handleEditPage}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton> : ableToCreatePCRExperement && <TEditButton disabled={!isActive} onClick={handleEditPage}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton>}
        </TRowWr>}
        <TWrapper onSubmit={handleSubmit(data => handleSaveExperiment(data))} key={resertFormKey}>
          <Table>
            {/* analytical sensitivity */}
            <TBody>
              <tr>
                <td colSpan={4} className="head violet topRadius">Аналитическая чувствительность метода, %</td>
              </tr>
              <tr>
                <td colSpan={4} className={!!errors?.analyticalSensitivity ? 'error noBorderRadius' : 'noBorderRadius'}>
                  <Controller
                    control={control}
                    name={'analyticalSensitivity'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        onChange={onChange}
                        selected={value}
                        options={analyticalSensPCR}
                        placeholder={'Выберите значение из выпадающего списка'}
                        styles={customStylesOptions(defaultTheme)}
                        isDisabled={!isEdit}
                        defaultValue={getValues('analyticalSensitivity')}
                      />
                    )}
                  />
                </td>
              </tr>
            </TBody>
            {/* header */}
            <TBody>
              <tr className="borderTop violet">
                <td className="head noRadius">Маркер</td>
                <td className="head noRadius">Референсная последовательность</td>
                <td className="head noRadius">Патогенный вариант/детектируемое нарушение</td>
                <td className="head noRadius">Результат исследования</td>
              </tr>
            </TBody>
            {/* genes */}
            {!!analisysByMethod?.markerPcr && analisysByMethod.markerPcr.genesPcr.map((gen: MarkerGene, index: number) => (

              <TBody key={index + 'genes' + gen.id}>
                {gen?.mutations?.map((mutation: GeneMutation, mutIndex: number) => (
                  <tr key={mutIndex + 'mutation' + gen.id}>
                    {!mutIndex && <td rowSpan={gen?.mutations?.length}>{gen.name}</td>}
                    {!mutIndex && <td rowSpan={gen?.mutations?.length} className="borderRight">{gen.reference ?? '--'}</td>}
                    <td >{mutation.name}</td>
                    <td className={!!errors?.[`mutationPcr:${mutation.id}__genePcr:${gen.id}`] ? 'error noBorderRadius' : 'noBorderRadius'}>
                      <Controller
                        control={control}
                        name={`mutationPcr:${mutation.id}__genePcr:${gen.id}`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={onChange}
                            selected={value}
                            options={pcrResultOptions[mutation.name]}
                            placeholder={'--'}
                            styles={customStylesOptions(defaultTheme)}
                            isOptionDisabled={(option: any) => option.disabled}
                            isDisabled={!isEdit}
                            defaultValue={getValues(`mutationPcr:${mutation.id}__genePcr:${gen.id}`)}
                          />
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </TBody>
            ))}
            {/* conclusion */}
            <TBody>
              <tr>
                <td colSpan={4} className="head violet">
                  Клинико-лабораторное заключение
                </td>
              </tr>
              <tr>
                <td colSpan={4} className={!!errors?.conclusionResult ? 'error noBorderRadius' : 'noBorderRadius'}>
                  <Controller
                    key={`my_unique_select_key__${conclusionKey}`}
                    control={control}
                    name={'conclusionResult'}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        isMulti
                        onChange={onChange}
                        selected={value}
                        options={watchShowManualConclusion?.length === 2 ? [] : conclusionOptions}
                        placeholder={'Выберите значение из выпадающего списка'}
                        isDisabled={isConclusionDisabled || !isEdit}
                        noOptionsMessage={() => "нет опций, возможно выбрать только 2 варианта"}
                        isSearchable={watchShowManualConclusion?.length !== 2}
                        defaultValue={getValues('conclusionResult')}
                        styles={customStylesOptions(defaultTheme)}
                      />
                    )}
                  />
                </td>
              </tr>
                <tr>
                  <td colSpan={4} className={!!errors?.description ? 'error ' : ''}>
                    <Textarea
                      {...register('description',{ required: !watchShowManualConclusion?.length, maxLength: 999 })}
                      key={conclusionKey}
                      placeholder={'И/Или введите текст вручную, если это требуется'}
                      className='conclusionDescription'
                      defaultValue={getValues('description') ?? ''}
                      height={85}
                      readOnly={!isEdit}
                      disabled={!isEdit}
                      maxLength={1000}
                    />
                  </td>
                </tr>
            </TBody>
          </Table>

          {(!isActive || !!defaultValues) && !!executorfullName && <TExecutor>
            <b>{executorfullName ?? 'Исполнитель не определен'}</b>
            {executorTitle ? `, ${executorTitle}` : ''}
          </TExecutor>}
          {(!isActive || !!defaultValues) && !!examExecutors?.blueprintAdvisory?.length && <TOtherExecutors height='fit-content'>
            {examExecutors?.blueprintAdvisory?.map((advisor: IBlueprintAdvisors) => (
              <div key={`advisorPCR${advisor?.fullName}`}><b>{advisor?.fullName ?? 'Исполнитель не определен'}</b>
              {advisor?.specialization ? `, ${advisor?.specialization}` : ''}</div>
            ))} 
          </TOtherExecutors>}

          {!isReferralComplite && <TButtonWrapper>
            {/* {ableToCreatePCRExperement && isActive && <Button size={SIZE.SMALL} variant={VARIANT.BLUE} type="submit" disabled={(!isConclusionDisabled && !isEdit) || !isActive}>
              Завершить попытку
            </Button>} */}
            {ableToCreatePCRExperement && isActive && <Button size={SIZE.SMALL} variant={!isEdit ? VARIANT.DEFAULT : VARIANT.BLUE} type="submit" disabled={!isEdit}>
              Завершить попытку
            </Button>}
            {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate && <CustomButton type="button" onClick={onModalToggle} disabled={!isEdit}>
              Подтвердить изменения
            </CustomButton>}
          </TButtonWrapper>}
          <InfoModal
            title='Внесенные изменения будут отображены в заключении. '
            showModal={showConfirmModal}
            isWarning={true}
            icon={<WarnIcon />}
            onModalToggle={onModalToggle}
            buttons={<>
              <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onModalToggle}>Вернуться</Button>
              <Button  size={SIZE.SMALL} type="submit" onClick={handleSubmit(data => handleSaveExperiment(data))}>Продолжить</Button>
            </>}
          />
        </TWrapper>

      </TDetailsContent>
    </TDetails>
  )
}

export default ExperimentPCR;