import { createGlobalStyle, css } from 'styled-components';
// import MontserratRegular  from './Montserrat-Regular.woff';
// import MontserratMedium   from './Montserrat-Medium.woff';
// import MontserratSemiBold from './Montserrat-SemiBold.woff';
// import MontserratBold     from './Montserrat-Bold.woff';
import MontserratRegular  from './ttf/Montserrat-Regular.ttf';
import MontserratMedium   from './ttf/Montserrat-Medium.ttf';
import MontserratSemiBold from './ttf/Montserrat-SemiBold.ttf';
import MontserratBold     from './ttf/Montserrat-Bold.ttf';

export const fonts = css`
  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratRegular}) format('truetype'), url('.Montserrat-Regular.woff')  format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratMedium}) format('truetype'), url('.Montserrat-Medium.woff')  format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratSemiBold}) format('truetype'), url('.Montserrat-SemiBold.woff')  format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratBold}) format('truetype'), url('.Montserrat-Bold.woff')  format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }
`;

export default createGlobalStyle`
  ${fonts}
`;
