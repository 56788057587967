import { FC, useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Select from "react-select";
import { Table, Thead, TBody } from "../../../../components/shared/BiomaterialTable";
import defaultTheme from "../../../../styles/theme";
import { customStylesOptions } from "../../Analysis/styled";
import { TCneckboxWr, TEditButton, TRowWr, TSectionTitle, TTableWrapper, TWrapper } from "../styled";
import Checkbox from "../../../../components/shared/Checkbox";
import { IConclusionData, IToxicityData } from "../../../../store/molProConclusion/model";
import { useDispatch, useSelector } from "react-redux";
import { getTokens } from "../../../../store/auth/selectors";
import { postOverWriteConclusionModules } from "../../../../store/molProConclusion/thunkActions";
import Textarea from "../../../../components/shared/Textarea";



interface IComponentProps {
  referralID: string;
  defaultData: IConclusionData | null;
  stepComplite: boolean;
  hasPermit: boolean;
  [index: string]: any;
}

const toxicityOptions = [{ value: 'High', label: 'Высокая' }, { value: 'Low', label: 'Низкая' }]
// const toxicityOptionsMap: { [index: string]: any; } = {
//   High: { value: 'High', label: 'Высокая' },
//   Low: { value: 'Low', label: 'Низкая' },
// }

export const Toxicity: FC<IComponentProps> = ({ stepComplite, defaultData, hasPermit, referralID }) => {

  const { control, register, handleSubmit, formState: { errors }, setValue, getValues, clearErrors, trigger, setError } = useForm({ mode: "onSubmit" });

  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [noSpecify, setNoSpecify] = useState<boolean>(false);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const [savedMarkerIDValues, setSavedMarkerIDValues] = useState<{ [index: number]: any | undefined; }>(defaultData?.moduleToxicity?.restoreDefaultData ?? {});

  const toxicityMarkerData = useMemo(() => {
    let toxicityData = defaultData?.moduleToxicity?.toxicityData
    if (toxicityData && !!toxicityData?.length) return toxicityData;
    return [];
  }, [defaultData?.moduleToxicity?.toxicityData]);


  const handleSaveData = useCallback(async (formData?: any, isNoSpecify?: boolean) => {
    let resultValidate = await trigger();

    if (stepComplite || !hasPermit || !resultValidate) return;
    const dataForSave: { [index: string]: any; } = { ...defaultData ?? {} };
    let {comments, ...toxicityFormData} = formData ?? getValues();
    let isEmptyToxisity = Object.values(toxicityFormData)?.every((data: any | undefined) => !data);
    let isEmptyComments = !comments && !comments?.trim();

    if(isEmptyToxisity && isEmptyComments && !(noSpecify || isNoSpecify)) return setError('comments', { type: 'custom', message: 'Укажите причину в комментарии' })
    
    if (noSpecify || isNoSpecify) {
      dataForSave['moduleToxicity'] = { noSpecify: true, toxicityData: toxicityMarkerData, comments: '' }
    } else {
      dataForSave['moduleToxicity'] = { restoreDefaultData: toxicityFormData,comments, noSpecify: false, toxicityData: toxicityMarkerData };
    }

    if (tokens?.access) dispatch(postOverWriteConclusionModules(tokens?.access, referralID, dataForSave));

    setEdit(false);
  }, [setEdit, stepComplite, hasPermit, defaultData, toxicityMarkerData, dispatch, tokens, getValues, noSpecify, referralID,trigger, setError]);

  const restoreDefaults = useCallback(async () => {
    let data = defaultData?.moduleToxicity?.restoreDefaultData;
    let commentsData = defaultData?.moduleToxicity?.comments;

    if (data) {
      const savedMarkerValues: { [index: string]: any; } = {};
      for (let mrkID in data) {
        setValue(`${mrkID}`, data[mrkID])
        savedMarkerValues[mrkID] = data[mrkID]
      }
      setSavedMarkerIDValues(savedMarkerValues);
      setValue('comments',commentsData)
    }

  }, [defaultData?.moduleToxicity, setValue, setSavedMarkerIDValues]);

  const handleEditPage = useCallback(async () => {
    if (stepComplite || !hasPermit) return;
    if (isEdit) {
      restoreDefaults();
      //toxicityMarkerData.forEach((txm: IToxicityData) => setValue(txm.markerName, undefined));
      clearErrors()
      setResetFormKey(Date.now());
      return setEdit(false);
    }
    setResetFormKey(Date.now());
    restoreDefaults();
    setEdit(true)
  }, [isEdit, setEdit, setResetFormKey, stepComplite, hasPermit, restoreDefaults,clearErrors]);

  const noSpecifyHandler = useCallback(async () => {
    for (let txm of toxicityMarkerData) setValue(`${txm.marker}`, undefined);

    if (!noSpecify) {
      await handleSaveData(undefined, true);
      setNoSpecify(true);
      setSavedMarkerIDValues({})
      setEdit(false)
      clearErrors()
      return setResetFormKey(Date.now());
    }
    setNoSpecify(false);
    setSavedMarkerIDValues({})

    return setResetFormKey(Date.now());
  }, [noSpecify, handleSaveData, setResetFormKey, toxicityMarkerData, setValue, setSavedMarkerIDValues,setEdit,clearErrors]);

  useLayoutEffect(() => {
    for (let mrk of defaultData?.moduleToxicity?.toxicityData ?? []) {
      register(`${mrk.marker}`, undefined);
    }
    if (defaultData?.moduleToxicity?.noSpecify) {
      setNoSpecify(true);
      setSavedMarkerIDValues({})
      setResetFormKey(Date.now());
      setValue('comments','')
    }
    if (defaultData?.moduleToxicity?.restoreDefaultData) restoreDefaults();
    return () => {
      setSavedMarkerIDValues({})
      setValue('comments','')
      setResetFormKey(Date.now());
    }
  }, [toxicityMarkerData, defaultData, restoreDefaults, setValue, toxicityMarkerData, 
    register, setNoSpecify, setResetFormKey,setSavedMarkerIDValues]);

  const isShowComments = useMemo(() => {
    if(!stepComplite) return true;
    let comments = defaultData?.moduleToxicity?.comments;
    if(comments) return !!(comments?.trim());
    return false;

  },[defaultData,stepComplite]);

  return (
    <>
      {!stepComplite && <TCneckboxWr>
        <Checkbox smallSize label='Не указывать' checked={!!noSpecify} onChange={() => hasPermit ? noSpecifyHandler() : null} />
      </TCneckboxWr>}
      <TTableWrapper key={resertFormKey} onSubmit={handleSubmit(data => handleSaveData(data))}>
        <TRowWr direction="flex-end" >
          {!stepComplite && hasPermit && <TEditButton type="button" onClick={handleEditPage} active={!isEdit} disabled={stepComplite || !!noSpecify}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton>}
          {isEdit && !stepComplite &&
            <TEditButton type="submit" disabled={!isEdit} active={isEdit}>
              Сохранить
            </TEditButton>
          }
        </TRowWr>
        <TSectionTitle>
          Потенциальная токсичность лекарственных средств
        </TSectionTitle>
        <TWrapper className="pb34">
          <Table>
            <Thead>
              <td className="center violet">Протестированный маркер</td>
              <td className="center violet">Агент</td>
              <td className="center violet">Токсичность</td>
            </Thead>
            <TBody>
              {!!toxicityMarkerData?.length && toxicityMarkerData.map((txm: IToxicityData, index: number) => {
                return (
                  <tr key={`${txm.marker}_${resertFormKey}`} className="tableData">
                    <td>{txm.markerName}</td>
                    <td>{txm.toxicityAgentsNames.join(', ')}</td>
                    <td className={`center ${errors?.[txm.marker] && 'error'} toxity_select`}>
                      {!!noSpecify && '--'}
                      {!noSpecify && !isEdit && (savedMarkerIDValues[txm.marker]?.label ?? '--')}
                      {!noSpecify && isEdit &&
                        <Controller
                          key={`${txm.marker}_${resertFormKey}`}
                          control={control}
                          name={`${txm.marker}`}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={(data:any) => {
                                clearErrors();
                                return onChange(data)}}
                              selected={value}
                              options={toxicityOptions}
                              defaultValue={savedMarkerIDValues?.[txm.marker]}
                              placeholder={'--'}
                              styles={customStylesOptions(defaultTheme)}
                              isDisabled={!isEdit}
                              noOptionsMessage={() => "нет опций"}
                            />
                          )}
                        />}
                    </td>
                  </tr>
                )
              })}
            </TBody>
          </Table>
        </TWrapper>
      </TTableWrapper>
        {isShowComments && <Textarea
          key={`comments_${resertFormKey}`}
          {...register('comments')}
          label='Примечание'
          placeholder={'Комментарий'}
          onChange={({ target }) => {
            setValue('comments', target.value);
            if(target?.value?.trim()) clearErrors()
          }}
          disabled={!isEdit}
          readOnly={!isEdit}
          className='nothovered'
          maxLength={300}
          defaultValue={getValues('comments')}
          error={!!errors?.comments}
          bordered
        />}
    </>)
}

export default Toxicity;