import {
  TMKB10Wrapper
} from "../styled";
import MainRowComponent from "./MainRowComponent";
import {MKB10List} from "./styled";


const Mkb10TreeComponent = (props: any) => {
  const {items, selectMKB10Option} = props;

  return (
    <>
      <TMKB10Wrapper>
        <MKB10List>
          {items?.map((item: any) => {
            const key = `${item.code}_${item.nameRus}`

            return <MainRowComponent key={key} item={item} selectMKB10Option={selectMKB10Option}/>
            })}
        </MKB10List>
      </TMKB10Wrapper>
    </>
  )
};

export default Mkb10TreeComponent;