import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  TPersonList, TPersonListElement, TPersonListElementText, TEmptyPersonList, TPersonFormWr,
  TPersonFormButton, TPersonFormSelectWr, TPersonFormManualSelectBtnWr, TPersonFormManualInputWr,
  TCustomInput, TInvertPlusIcon, TErrorList, TFormInputWr
} from "./styled";
import { ReactComponent as CrossIcon } from "../../../icons/cross-in-circle.svg";
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import InputSelect from "../../../components/shared/InputSelect";
import IconSquareButton from "../../../components/shared/IconSquareButton";
import { Controller, useForm } from "react-hook-form";

interface IAdvisorOptions {
  value: string;
  label: string;
  specialization: string;
  full_name: string;
  blueprint: number;
}

interface IAdvisorUpdate {
  advisor_ulid: string;
  full_name: string;
  specialization: string;
  blueprint: number;
}

interface IComponentProps {
  advisorOptions?: IAdvisorOptions[];
  setSelectedAdvisorList: (data: IAdvisorUpdate[]) => void;
  itemIDByDefault: number;
  [index: string]: any;
}

const regexManualInputPattern = new RegExp(/[^a-zA-Zа-яА-ЯЁё'-.()\s]/g);

function checkForPattern(value: string) {
  return new Promise(function (resolve) {
    return resolve(!regexManualInputPattern.test(value));
  })
};


const Concilium: FC<IComponentProps> = ({ advisorOptions, setSelectedAdvisorList, itemIDByDefault }) => {
  const { getValues, handleSubmit, control, formState: { errors, dirtyFields }, reset, resetField, watch, setError, clearErrors } = useForm({
    mode: "onChange",
    defaultValues: {
      full_name: '',
      specialization: '',
      advisor: '',
      blueprint: itemIDByDefault
    }
  });

  const [openManualInput, setOpenManualInput] = useState(false);
  const [formKey, setFormKey] = useState(Date.now());
  const [advisorList, setAdvisorList] = useState<IAdvisorOptions[]>([]);

  const watchAdvisorSelect = watch('advisor');
  const watchFullNameInput = watch('full_name')
  const watchSpecializationInput = watch('specialization')

  const selectedAdvisorsUlid = useMemo(() => {
    return advisorList?.map((option: IAdvisorOptions) => option.value) ?? []
  }, [advisorList]);

  const isAdvisorListFull = useMemo(() => {
    return advisorList.length >= 6;
  }, [advisorList]);

  const advisoUpdatedList = useMemo(() => {
    let updatedData: IAdvisorUpdate[] = advisorList.map((option: IAdvisorOptions) => {
      let { value: advisor_ulid, full_name, specialization, blueprint } = option;
      return { advisor_ulid, full_name, specialization, blueprint };
    });

    return updatedData;
  }, [advisorList]);

  useEffect(() => {
    setSelectedAdvisorList(advisoUpdatedList)
  }, [advisoUpdatedList, setSelectedAdvisorList]);




  const toggleManualInputOpen = useCallback(async () => {
    if (openManualInput) {
      setOpenManualInput(false)
    } else {
      setOpenManualInput(true)
    }

    reset();
    setFormKey(Date.now())
  }, [openManualInput, setOpenManualInput, reset, setFormKey]);

  const onSelectSubmit = useCallback(() => {
    let advisorULID = getValues('advisor')//data?.advisor ?? '';
    let advisorData = advisorOptions?.find((option) => option.value === advisorULID);

    if (advisorData) setAdvisorList([...advisorList, advisorData]);
    reset();
    setFormKey(Date.now());
    setOpenManualInput(false);

  }, [setOpenManualInput, reset, setAdvisorList, advisorList, advisorOptions, getValues]);

  const onManualSubmit = useCallback((data: any) => {
    let { full_name, specialization, blueprint = itemIDByDefault } = data;
    if (full_name && specialization) setAdvisorList([...advisorList, { value: '', label: full_name, specialization: specialization, full_name, blueprint }])
    reset();
    setFormKey(Date.now());
    setOpenManualInput(false);
  }, [setOpenManualInput, reset, advisorList, itemIDByDefault]);

  const manualInputHandler = useCallback(({ value }: any) => (value?.trimLeft() ?? value)?.replace(regexManualInputPattern, ""), []);



  useEffect(() => {
    if (openManualInput) {
      let message = '';
      if (dirtyFields?.full_name && advisorList?.some((v: IAdvisorOptions) => v.full_name === watchFullNameInput)) {
        message = 'ФИО: этот исполнитель уже есть в списке';
      }
      if (message) setError('full_name', { type: 'custom', message });


      if (watchAdvisorSelect) {
        clearErrors(['full_name', 'specialization']);
        resetField('full_name');
        resetField('specialization');
        setOpenManualInput(false);
      }
    }

  }, [watchFullNameInput, watchSpecializationInput, openManualInput,
    errors, setError, dirtyFields, watchAdvisorSelect, resetField,
    clearErrors, advisorList]);

  return <div>

    {!advisorList?.length && <TEmptyPersonList marginTop={34}>Здесь будут отображаться другие исполнители</TEmptyPersonList>}

    {!!advisorList?.length && (
      <TPersonList active={true} hiddenSroll={advisorList?.length > 4} marginTop={34}>
        {advisorList?.map((advisor: IAdvisorOptions, itemIndex: number) => (
          <TPersonListElement key={advisor.label}>
            <TPersonListElementText>
              {advisor?.full_name ?? '--'}, <span>{advisor?.specialization ?? '--'}</span>
            </TPersonListElementText>
            <CrossIcon onClick={() => setAdvisorList(advisorList?.filter((value: IAdvisorOptions, index: number) => index !== itemIndex))} />
          </TPersonListElement>
        ))}
      </TPersonList>
    )}
    <TPersonFormWr key={formKey}>
      <TPersonFormSelectWr>
        <Controller
          control={control}
          name="advisor"
          rules={{ required: !openManualInput }}
          render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
            <InputSelect
              onChange={(value) => onChange(value)}
              value={getValues('advisor')}
              options={advisorOptions?.filter((option: IAdvisorOptions) => !selectedAdvisorsUlid.includes(option.value)) ?? []}
              placeholder='--'
              label='Выбрать из списка'
              error={invalid}
              bordered={true}
              isSearchable
              disabled={isAdvisorListFull}
            />)}
        />
        <TPersonFormButton disabled={isAdvisorListFull || !watchAdvisorSelect} marginBottom={8} type="button" onClick={() => onSelectSubmit()}>Добавить</TPersonFormButton>
      </TPersonFormSelectWr>

      {!openManualInput && <TPersonFormManualSelectBtnWr>
        <IconSquareButton onClick={toggleManualInputOpen} invert={isAdvisorListFull} disabled={isAdvisorListFull}>
          {isAdvisorListFull ? <TInvertPlusIcon /> : <PlusIcon />}
        </IconSquareButton>
        <span>Ввести вручную</span>
      </TPersonFormManualSelectBtnWr>}

      {openManualInput && <TPersonFormManualInputWr onSubmit={handleSubmit(onManualSubmit)}>
        <TFormInputWr>
          <Controller
            control={control}
            name="full_name"
            rules={{
              required: { value: openManualInput, message: 'ФИО: это поле обязательно' },
              minLength: { value: 3, message: 'ФИО: это поле должно содержать больше трех символов' },
              maxLength: { value: 50, message: 'ФИО: превышена максимальная длина поля' },
              validate: {
                checkPattern: async (v: string) => !!(await checkForPattern(v)),
                empty: async (v: string) => !!(v.trim())
              }
            }}
            render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
              <TCustomInput
                disabled={!openManualInput}
                bordered
                placeholder='Владимиров Алексей Сергеевич'
                label='ФИО *'
                onChange={(value) => onChange(value)}
                onKeyUp={async ({ target }) => onChange(await manualInputHandler(target))}
                value={value}
                width={'39%'}
                type="text"
                error={!!errors?.full_name || invalid}
              />)}
          />
          <Controller
            control={control}
            name="specialization"
            rules={{
              required: { value: openManualInput, message: 'Должность: это поле обязательно' },
              minLength: { value: 3, message: 'Должность: это поле должно содержать больше трех символов' },
              maxLength: { value: 50, message: 'Должность: превышена максимальная длина поля' },
              validate: {
                checkPattern: async (v: string) => !!(await checkForPattern(v)),
                empty: async (v: string) => !!(v.trim())
              }
            }}
            render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
              <TCustomInput
                disabled={!openManualInput}
                bordered
                label='Должность *'
                placeholder='Морфолог'
                onChange={(value) => onChange(value)}
                onKeyUp={async ({ target }) => onChange(await manualInputHandler(target))}
                value={value}
                width={'39%'}
                type="text"
                error={!!errors?.specialization || invalid}
              />)}
          />
          <TPersonFormButton disabled={!watchFullNameInput.trim() || !watchSpecializationInput.trim() || !!errors?.specialization || !!errors?.full_name} marginBottom={24} type="submit">Добавить</TPersonFormButton>
        </TFormInputWr>
      <TErrorList className="errors-container">
        {Object.entries(errors).map((value: [field: string, error: any]) => {
          let inputLabel = value[0] === 'full_name' ? 'ФИО' : 'Должность';
          let { message, type } = value[1]
          let text = message;
          if (type === 'checkPattern') text = `${inputLabel}: не используйте цифры и знаки кроме ' - . ( )`;
          if (type === 'empty') text = `${inputLabel}: это поле не может быть пустым`;
          return <li key={`${message} ${type}`}>{text}</li>
        })
        }
      </TErrorList>
      </TPersonFormManualInputWr>}


    </TPersonFormWr>

  </div>
}

export default Concilium;