import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RestorePasswordRequestForm from '../../components/forms/RestorePasswordRequestForm';
import AuthPageContainer from '../../components/shared/AuthPageContainer/AuthPageContainer';
import { restorePasswordRequest } from '../../store/auth/thunkActions';
import { getErrors, getUser } from '../../store/auth/selectors';

const RestorePasswordRequestPage: FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const errors = useSelector(getErrors);

  const submitForm = useCallback(async ({ email }) => {
    await dispatch(restorePasswordRequest(email));
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setIsSubmitted(true);
    }
  }, [user, setIsSubmitted]);

  return (
    <AuthPageContainer>
      <RestorePasswordRequestForm isSubmitted={isSubmitted} formSubmit={submitForm} errors={errors} />
    </AuthPageContainer>
  );
}

export default RestorePasswordRequestPage;
