import { ThemeProvider } from 'styled-components';
import { FC } from 'react';
import GlobalStyles from '../../styles/global';
import GlobalFonts from '../../styles/fonts/fonts';
import defaultTheme from '../../styles/theme';

const ThemeContainer: FC = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <GlobalFonts />
      {children}
    </ThemeProvider>
  );
}

export default ThemeContainer;
