import { createSlice } from '@reduxjs/toolkit';
import { ErrorsType, Nullable } from '../../types';
import { 
  IConclusionData,
  IModuleDefaultDepartment, 
  IModuleDefaultTitle, 
 } from './model';

 export interface IMolProConclusionState {
  loading:  boolean;
  errors:   Nullable<ErrorsType>;
  modDefaultDepartment: Nullable<IModuleDefaultDepartment[]>;
  modDefaultTitle: Nullable<IModuleDefaultTitle[]>;
  conclusionData: Nullable<IConclusionData>;
 }

 export const initialState:IMolProConclusionState = {
    loading:  false,
    errors:   null,
    modDefaultDepartment: null,
    modDefaultTitle: null,
    conclusionData: null,
 }

 const molProConclusionSlice = createSlice({
  name: 'molProConclusion',
  initialState,
  reducers: {
    getModuleDefaultDepartment: state => {
      state.loading = true;
    },
    getModuleDefaultDepartmentSuccess: (state, { payload }) => {
      state.loading = false;
      state.errors  = null;
      state.modDefaultDepartment = payload;
    },
    getModuleDefaultDepartmentFailure: (state, { payload }) => {
      state.loading = false;
      state.errors  = payload;
    },
    getModuleDefaultTitle: state => {
      state.loading = true;
    },
    getModuleDefaultTitleSuccess: (state, { payload }) => {
      state.loading = false;
      state.errors  = null;
      state.modDefaultTitle = payload;
    },
    getModuleDefaultTitleFailure: (state, { payload }) => {
      state.loading = false;
      state.errors  = payload;
    },
    getConclusionData: state => {
      state.loading = true;
    },
    getConclusionDataSuccess: (state, { payload }) => {
      state.loading = false;
      state.errors  = null;
      state.conclusionData = payload;
    },
    getConclusionDataFailure: (state, { payload }) => {
      state.loading = false;
      state.errors  = payload;
    },
    resetConclusionData: state => {
      state.loading = false;
      state.errors  = null;
      state.conclusionData = null;
    },
  }
 });

 export const {
  getModuleDefaultDepartment,
  getModuleDefaultDepartmentSuccess,
  getModuleDefaultDepartmentFailure,
  getModuleDefaultTitle,
  getModuleDefaultTitleSuccess,
  getModuleDefaultTitleFailure,
  getConclusionData,
  getConclusionDataSuccess,
  getConclusionDataFailure,
  resetConclusionData,
 } = molProConclusionSlice.actions;

 export default molProConclusionSlice.reducer;