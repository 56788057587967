import { FC } from 'react';
import {
  TReferralCard,
  TReferralCardTitle,
  TReferralCardDate, TReferralCardStatus, TReferralCardId,
} from './styled';
import { REFERRAL_STATUS } from '../../../../../constants/referral';

interface IReferralCard {
  id: string;
  title: string;
  date: string;
  status: string;
  ulid: string;
  goToMolecularProfilingDetails: (id:string) => void;
  ableToViewREFERRAL?:boolean;
}

const ReferralCard: FC<IReferralCard> = ({ id, status, date, title, ulid, goToMolecularProfilingDetails,ableToViewREFERRAL }) => {
  return (
    <TReferralCard isActive={!!ableToViewREFERRAL} onClick={() => ableToViewREFERRAL ? goToMolecularProfilingDetails(ulid) : null}>
      <TReferralCardId>{id}</TReferralCardId>
      <TReferralCardTitle>{title}</TReferralCardTitle>
      <TReferralCardDate>{date}</TReferralCardDate>
      <TReferralCardStatus status={status}><span className='status'></span><span className='content'>{REFERRAL_STATUS[status]}</span></TReferralCardStatus>
    </TReferralCard>
  );
}

export default ReferralCard;
