import {useMemo} from "react";

const usePrepareMKB10List = (items: any) => {
  const preparedMKB10List = useMemo(() => {
    let result: any[] = [];
    items?.forEach((item: any) => {
      item?.icd10Parent.forEach((chield: any) => {
        result.push(`${chield.code} ${chield.nameRus}`)

        chield?.icd10Children.forEach((mkb: any) => {
          result.push(`${mkb.code} ${mkb.nameRus}`)
        })
      })
    })

   return result
  }, [items])

    return {
    preparedMKB10List
    };
};

export default usePrepareMKB10List;