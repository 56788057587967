import { FC, useMemo } from "react";
import { MarkerReference } from "../../../../../store/analysis/model";
import { TExpWR, THeaderTable, THeaderTableRow, THeaderTableRowContent, THeaderTableRowTitle, TLabTable } from "../styled";
import { useSelector } from "react-redux";
import { checkPermitViewExperement } from "../../../../../store/auth/selectors";

interface IAdvisor {
  advisorUlid: string
  fullName: string
  specialization: string
}
interface IComponentProps {
  experements: any[];
  blueprintId: number;
  referralID: string;
  executorMap: any;
  goToExperement: (
    experimentID:number,
    experimentMethod:string,
    experimentMarker:string) => void;
  advisors?:IAdvisor[];
  [index: string]: any;
}

const headers = ['Маркер', 'Кол-во проанализ. ядер опухолевых клеток',
  'Кол-во ядер опухолевых клеток без реаранжировки', 'Кол-во ядер с BA - сигналом', 'Кол-во ядер с изолированным сигналом',
  'Ядер опухолевых клеток c реаранжировкой, %']

export const FishRear: FC<IComponentProps> = ({ advisors, blueprintId, experements, executorMap, goToExperement }) => {
  //Permissions
  const ableToViewExperiment = useSelector(checkPermitViewExperement);
  //Formeted experimen number
  const experimentNumber = useMemo(() => blueprintId?.toString()?.padStart(6, "0")?.replace(/(?=(?:.{3})*$)/g, ' '),[blueprintId]);
  return <>
    {experements?.map(exp => (
      <TExpWR key={(exp?.id + blueprintId) + 'experement'}>
        <THeaderTable>
          <THeaderTableRow>
            <THeaderTableRowTitle>Исследование: </THeaderTableRowTitle>
            {ableToViewExperiment ? <THeaderTableRowContent className="link" onClick={() => goToExperement(blueprintId,'fish',exp?.name)}>{experimentNumber}</THeaderTableRowContent> :<div>{experimentNumber}</div>}
          </THeaderTableRow>
          <THeaderTableRow>
            <THeaderTableRowTitle>Исполнитель: </THeaderTableRowTitle>
            <THeaderTableRowContent>{`${executorMap?.[exp?.executor]?.fullName}, ${executorMap?.[exp?.executor]?.title}`}</THeaderTableRowContent>
          </THeaderTableRow>
          <THeaderTableRow>
            <THeaderTableRowTitle>Другие: </THeaderTableRowTitle>
            <THeaderTableRowContent>
              <div>{`${executorMap?.[exp?.labAssistant]?.fullName}, ${executorMap?.[exp?.labAssistant]?.title}`}</div>
              {advisors?.map((adv:IAdvisor) => <div key={adv?.advisorUlid}>{adv?.fullName}, {adv?.specialization}</div>)}</THeaderTableRowContent>
          </THeaderTableRow>
        </THeaderTable>
        <TLabTable>
          <tbody>
            <tr>
              <td colSpan={2} className="head violet topRadiusL">Реагенты (наборы, зонды)</td>
              <td colSpan={4} className="topRadiusR">{exp?.kit}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              {headers?.map((header: string) => <td key={header} className="head">{header}</td>)}
            </tr>
            <tr>
              <td>{exp?.name ?? '--'}</td>
              <td>{exp?.rearrangement?.cancerCellCount ?? '--'}</td>
              <td>{exp?.rearrangement?.cancerCellNucleusRearrangedCount ?? '--'}</td>
              <td>{exp?.rearrangement?.splitSignalNucleusCount ?? '--'}</td>
              <td>{exp?.rearrangement?.isolatedSignalNucleusCount ?? '--'}</td>
              <td>{Math.trunc(+exp?.rearrangement?.rearrangedCellPercentage) ?? '--'}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colSpan={2} className="head violet">Референсные значения</td>
              <td colSpan={4} className="">
                {exp?.references?.map((ref: MarkerReference) => <div key={ref.id}>{ref.content}</div>)}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="head violet">Результат исследования</td>
              <td colSpan={4}>
                {exp?.rearrangement?.result ?? '--'}
              </td>
            </tr>
            <tr>
              <td colSpan={6} className={'head violet'}>Клинико-лабораторное заключение</td>
            </tr>
            {!!exp?.rearrangement?.conclusion?.length && <tr>
              <td colSpan={6} className={`${!exp?.rearrangement?.notes && 'bottomRadiusL'}`}>
                {exp?.rearrangement?.conclusion ?? '--'}.
              </td>
            </tr>}
            {!!exp?.rearrangement?.notes && <tr>
              <td colSpan={6} className="bottomRadiusR">{exp?.rearrangement?.notes ?? '--'}</td>
            </tr>}
          </tbody>
        </TLabTable>
      </TExpWR>))}
  </>
}

export default FishRear;