import { createSelector } from '@reduxjs/toolkit';
import { IAppState } from '../';

const getState = (state: IAppState) => state.auth;

export const getUser = createSelector(getState, state => state.user);
export const getLoading = createSelector(getState, state => state.loading);
export const getProfileUpdated = createSelector(getState, state => state.updated);
export const getErrors = createSelector(getState, state => state.errors);

export const checkAnotherSessionExist = createSelector(getState, state => state.isSessionExist);

export const getUserUlid = createSelector(getUser, user => user?.ulid ?? '');
export const getUserEmail = createSelector(getUser, user => user?.email ?? '');
// export const getTokens = createSelector(getUser, user => user?.tokens ?? null);
export const getTokens = createSelector(getState, state => state?.tokens ?? null);
export const getPermissions = createSelector(getUser, user => user?.userRegistryPermissions ? {...user?.userRegistryPermissions, isStaff: user?.isStaff ?? false} : null);
export const getProfile = createSelector(getUser, user => ( user?.profile  || user?.userProfile) ?? null);

export const isSessionContinued = createSelector(getState, state => state.continueSession);
export const getTokenExpiration = createSelector(getState, state => state.tokenExp);

export const checkPermitViewREFERRAL = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {registrar,labAssistant,advisory,doctor,signatory,geneticistCoordinator,geneticistExecutor,morphologistBasic,morphologistExecutor} = {...permissions}; 
  let isPermissionReceived = isAdmin || [registrar,labAssistant,advisory,doctor,signatory,geneticistCoordinator,geneticistExecutor,morphologistBasic,morphologistExecutor].some(
    (permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitCreateREFERRAL = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {registrar,doctor} = {...permissions};
  let isPermissionReceived = isAdmin || [registrar,doctor].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitUdateREFERRAL = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  let updateClinicalData = false;
  let updateBiologicalMaterial = false;
  let updateMorphology = false;
  let updateMarkerValidation = false;
  let updateExamination = false;
  let updateCoclusion = false;  
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {registrar,geneticistCoordinator,geneticistExecutor,morphologistBasic,morphologistExecutor} = {...permissions}; 

  updateMorphology = isAdmin || [morphologistBasic].some((permit:boolean | undefined) => !!permit);
  updateExamination = isAdmin || [geneticistCoordinator,geneticistExecutor,morphologistExecutor].some((permit:boolean | undefined) => !!permit);
  updateClinicalData = isAdmin || [geneticistCoordinator].some((permit:boolean | undefined) => !!permit);
  updateMarkerValidation = isAdmin || [geneticistCoordinator].some((permit:boolean | undefined) => !!permit);
  updateBiologicalMaterial = isAdmin || [registrar].some((permit:boolean | undefined) => !!permit);

  return {updateClinicalData,updateBiologicalMaterial,updateMorphology,updateMarkerValidation,updateExamination,updateCoclusion};
});

export const checkCancelREFERRAL = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {geneticistCoordinator} = {...permissions}; 
  let isPermissionReceived = isAdmin || [geneticistCoordinator].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkNotCancelRequestREFERRAL = createSelector(getUser, user => {
  if(!user) return false;
  // const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {geneticistExecutor,morphologistExecutor,morphologistBasic} = {...permissions}; 
  let isPermissionReceived = [geneticistExecutor,morphologistExecutor,morphologistBasic].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});


export const checkPermitViewResearch = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {registrar,labAssistant,advisory,signatory,geneticistCoordinator,geneticistExecutor,morphologistExecutor} = {...permissions};
  let isPermissionReceived = isAdmin || [registrar, labAssistant, advisory, signatory,geneticistCoordinator,geneticistExecutor,morphologistExecutor].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitViewExperement = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {labAssistant,signatory,advisory,geneticistCoordinator,geneticistExecutor,morphologistExecutor} = {...permissions}; 
  let isPermissionReceived = isAdmin || [labAssistant,signatory,advisory,geneticistCoordinator,geneticistExecutor,morphologistExecutor].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitCreatePCRExperement = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {geneticistCoordinator,geneticistExecutor} = {...permissions}; 
  let isPermissionReceived = isAdmin || [geneticistCoordinator,geneticistExecutor].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitUpdateExperement = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {geneticistCoordinator} = {...permissions}; 
  let isPermissionReceived = isAdmin || [geneticistCoordinator].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitCreateSSExperement = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {geneticistCoordinator,geneticistExecutor} = {...permissions}; 
  let isPermissionReceived = isAdmin || [geneticistCoordinator,geneticistExecutor].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitCreateFAExperement = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {geneticistCoordinator,geneticistExecutor} = {...permissions}; 
  let isPermissionReceived = isAdmin || [geneticistCoordinator,geneticistExecutor].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitCreateIHCExperement = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {geneticistCoordinator,morphologistExecutor} = {...permissions}; 
  let isPermissionReceived = isAdmin || [geneticistCoordinator,morphologistExecutor].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitUpdateIHCBio = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {morphologistExecutor,geneticistCoordinator} = {...permissions}; 
  let isPermissionReceived = isAdmin || [morphologistExecutor,geneticistCoordinator].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitCreateFISHExperement = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {geneticistCoordinator,geneticistExecutor} = {...permissions}; 
  let isPermissionReceived = isAdmin || [geneticistCoordinator,geneticistExecutor].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitUpdateFISHBio = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {geneticistCoordinator,geneticistExecutor} = {...permissions}; 
  let isPermissionReceived = isAdmin || [geneticistCoordinator,geneticistExecutor].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitConfirmANALYSIS = createSelector(getUser, user => {
  if(!user) return false;
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  if(permissions?.guest && !permissions?.isValidated) return false;

  let {geneticistCoordinator} = {...permissions}; 
  let isPermissionReceived = isAdmin || [geneticistCoordinator].some((permit:boolean | undefined) => !!permit);

  return isPermissionReceived;
});

export const checkPermitUpdateANALYSIS = createSelector(getUser, user => {
  const isAdmin = user?.isStaff;
  const permissions = user?.userRegistryPermissions;
  let ExperimentReview  = false;
  let Toxicity          = false;
  let Sensitivity       = false;
  let Inspection        = false;
  let CloseConslusion   = false;
  
  if(!user || (permissions?.guest && !permissions?.isValidated)) return {ExperimentReview,Toxicity,Sensitivity,Inspection,CloseConslusion};

  let {geneticistCoordinator} = {...permissions}; 

  ExperimentReview =  isAdmin || [geneticistCoordinator].some((permit:boolean | undefined) => !!permit);
  Toxicity =          isAdmin || [geneticistCoordinator].some((permit:boolean | undefined) => !!permit);
  Sensitivity =       isAdmin || [geneticistCoordinator].some((permit:boolean | undefined) => !!permit);
  Inspection =        isAdmin || [geneticistCoordinator].some((permit:boolean | undefined) => !!permit);
  CloseConslusion =   isAdmin || [geneticistCoordinator].some((permit:boolean | undefined) => !!permit);

  return {ExperimentReview,Toxicity,Sensitivity,Inspection,CloseConslusion};
});
