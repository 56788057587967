import styled from 'styled-components';
import Tooltip from "../Tooltip";

const TRoot = styled.label<{ active: boolean, disabled?: boolean, error?: boolean, width?: string }>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  border-radius: 10px;
  padding: 16px 20px;
  background-color: white;
  color: ${({ theme, active, disabled }) => active  ? theme.colors.black[200] : (disabled ? theme.colors.gray[300] : theme.colors.gray[400])};
  border: 1px solid ${({ theme, error }) => error ? theme.colors.alert.warning : theme.colors.white};
  width: ${({ width = '100%' }) => width};
  box-sizing: border-box;
  margin-top: 2px;
  height: 62px;

  &:hover {
    border-color: ${({ theme, disabled }) => disabled ? theme.colors.white : theme.colors.alert.border};
  }
`;

const TFakeRadio = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  box-sizing: border-box;
  position: relative;

  &:before {
    top: 3px;
    left: 3px;
    border-radius: 50%;
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: transparent;
  }
`;

const TLabelWr = styled.div<{icon?:boolean}>`
  margin-left: 22px;
  line-height: 26px;
  font-weight: 500;
  font-size: 16px;
  width: ${({icon}) => icon ? '90%' : ''};
`;

const TInput = styled.input<any>`
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;

  &:hover + ${TFakeRadio}, &:checked + ${TFakeRadio} {
    border-color: ${({ theme, activeColor }) => activeColor || theme.colors.alert.checkedRadioButton};
  }

  &:checked + ${TFakeRadio}:before {
    background-color: ${({ theme, activeColor }) => activeColor || theme.colors.alert.checkedRadioButton};

  }

  &:checked:hover + ${TFakeRadio} {
    border-color: ${({ theme, activeColor }) => activeColor || theme.colors.alert.checkedRadioButton};
    background-color: transparent;
  }

  &:checked:disabled + ${TFakeRadio} {
    border-color: ${({ theme }) => theme.colors.alert.checkedRadioButton};
    background-color: transparent;
  }
  
  &:disabled + ${TFakeRadio} {
    border-color: ${({ theme }) => theme.colors.gray[200]};
  }
`;

const CustomTooltip = styled(Tooltip)`
  width: 235px !important;
  padding: 14px 16px !important;
  
  & div > ul {
    padding-inline-start: 20px !important;
  }
`

const IconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  left: 640px;
`

export { TInput, TFakeRadio, TLabelWr, TRoot, CustomTooltip, IconWrapper };
