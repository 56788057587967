import { FC, RefObject, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkPermitUpdateANALYSIS, getTokens } from "../../../store/auth/selectors";
import { fetchSavedConclusionModules } from "../../../store/molProConclusion/thunkActions";
import { getSavedConclusionData } from "../../../store/molProConclusion/selectors";
import { TDateOfCompletion, TDetailsItemWr, TWrapper } from "./styled";
import ConclusionTab from "./ConclusionTab";
import { capitalizeFirstLetter, snakeToCamelCase } from "../../../utils/stringHelpers";
import FormHeaders from "./FormHeaders/FormHeaders";
import Patient from "./Patient/Patient";
import PotentialSensitivity from "./PotentialSensitivity/PotentialSensitivity";
import Toxicity from "./Toxicity/Toxicity";
import ResponsiblePersons from "./ResponsiblePersons/ResponsiblePersons";
import { ServiceUser } from "../../../store/serviceUsers/model";
import { fetchServiceUsers } from "../../../store/serviceUsers/thunkActions";
import { getServiceUsers } from "../../../store/serviceUsers/selectors";
import ConclusionToPrint from "../../../components/documents/ConclusionToPrint";
import { IncpectorsList } from "../../../store/molProConclusion/model";
// import { useReactToPrint } from "react-to-print";
import MorphlogyConclusion from "./MorphlogyConclusion/MorphlogyConclusion";

import { Examination, IStepReferral } from "../../../store/molecularProfiling/model";
import { fetchAnalysisByBluprintList } from "../../../store/analysis/thunkActions";
import LabDetail from "./LabDetail/LabDetail";
import { setReferralIsRedyToCompliteState } from "../../../store/molecularProfiling";
import { format } from "date-fns";

interface IComponentProps {
  data?: any;
  [index: string]: any;
}

const Сonclusion: FC<IComponentProps> = (data: IComponentProps) => {
  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const savedConclusionData = useSelector(getSavedConclusionData);
 
  const doctorsList = useSelector(getServiceUsers);
  //Permissions
  const ableToUpdateANALYSIS = useSelector(checkPermitUpdateANALYSIS);
  //Ready to print
  const printTemplateRef = useRef() as RefObject<HTMLInputElement>;
  
  const [modulesReadyToPrint, setModulesReadyToPrint] = useState<{ [index: string]: string }>({});
  // const handlePrint = useReactToPrint({
  //   documentTitle: `${savedConclusionData?.moduleDefault?.restoreDefaultData?.formTitle ?? 'Заключение'}, для направления ${data?.referralId}`,
  //   content: () => printTemplateRef?.current,
  //   // onAfterPrint: () => sendFormRequest(),
  // }) ?? (() => null);


  const referralID = useMemo(() => data?.ulid ?? 0, [data?.ulid]);
  const isStepComplite = useMemo(() => {
    let complite = !!data?.statusCancellation || data?.status === 'referral_completed';
    return !!complite
  },[data]);

  const dateOfCompletion = useMemo(() => {
    let compliteStep = data?.stepReferral?.find((step: IStepReferral) => step.status === 'referral_completed');
    if (!compliteStep?.dateSaved) return '';

    return format(new Date(compliteStep?.dateSaved), 'dd.MM.yyyy - HH:mm:ss');
  }, [data?.stepReferral]);

  const conclusionModules = useMemo(() => {
    if (data?.conclusionModules) return data?.conclusionModules
      ?.map((moduleName: string) => snakeToCamelCase(moduleName))
      ?.filter((name: string) => !['moduleClinic'].includes(name)) ?? [];
    return []
  }, [data?.conclusionModules]);

  const signatoryListData = useMemo(() => {
    if (!doctorsList?.length) return [];

    const signatoryList = doctorsList?.filter((user: ServiceUser) => user?.userRegistryPermissions?.signatory);
    let signatoryListData: IncpectorsList[] = [];

    for (let user of signatoryList) {
      let { firstName = '', lastName = '', middleName = '', title = ''} = user.userProfile;
      let ulid = user?.ulid ?? '';
      let normalizeTitle = capitalizeFirstLetter(title);
      let normelizeLastName = capitalizeFirstLetter(lastName);
      let FIO = `${firstName[0].toUpperCase()}.${middleName ? middleName[0].toUpperCase() + '.' : ''} ${normelizeLastName}`
      let firstColumn = [{ value: normalizeTitle, label: normalizeTitle, ulid  }];
      let secondColumn = [{ value: FIO, label: FIO, ulid }];
      signatoryListData.push({ firstColumn, secondColumn });
    }
    return signatoryListData;
  }, [doctorsList]);

  useLayoutEffect(() => {
    if (tokens?.access) {
      dispatch(fetchServiceUsers(tokens?.access, { organizationId: 0 }));
      dispatch(fetchSavedConclusionModules(tokens?.access, referralID));
    }
  }, [tokens?.access, dispatch, referralID]);

  const checkAllIsDone = useMemo(() => {
    let isDone = false;
    let conclusionDataFromServer:{[index: string]: any;} | null = {...savedConclusionData ?? {}} ;

    if(!conclusionDataFromServer || !Object.keys(conclusionDataFromServer ?? {})?.length) return isDone;
    
    let availableModules: string[] = conclusionModules?.filter((name:string) => !['moduleLabDetail','moduleSensitivity','moduleClinic','modulePatient'].includes(name)) ?? [];
    
    delete conclusionDataFromServer?.referralUlid;

    let savedModules = Object?.keys(conclusionDataFromServer ?? {})

    isDone = availableModules?.every((moduleName:string) => {
      let hasDefaultData = conclusionDataFromServer?.[moduleName]?.noSpecify || conclusionDataFromServer?.[moduleName]?.restoreDefaultData;
      return savedModules.includes(moduleName) && hasDefaultData;
    });


    if(isDone){
      setTimeout(() => dispatch(setReferralIsRedyToCompliteState(true)), 600)
      setModulesReadyToPrint(conclusionDataFromServer);
    }

    return isDone;
  }, [savedConclusionData, setModulesReadyToPrint,conclusionModules,dispatch]);

  // delete below

  const [getMethodByBluprint, setMethodByBluprint] = useState<{ [index: string]: string }>({});

  useLayoutEffect(() => {
    if (!data?.examinationReferral) return;
    const methodByBluprintMap: { [index: string]: string } = {};
    const markerMmethods: { [index: number]: string; } = {
      1: 'ihc',
      2: 'fish',
      3: 'pcr',
      4: 'ss',
      5: 'fa',
    }
    let examinationBlueprints:string[] = [''];
    
    examinationBlueprints = data?.examinationReferral?.examinationBlueprints
      .filter((blp: Examination) => blp.status === 'Complete')
      .map((blp: Examination) => {
        methodByBluprintMap[blp?.ulid ?? ''] = markerMmethods[blp.method];

        if(markerMmethods[blp.method] === 'ihc'){
          if(blp.marker?.toLowerCase() === 'alk') return `ihc/analysis/${blp.ulid}`;
          // return `ihc/ihc/analysis/${blp.ulid}`;
          return `ihc/analysis/${blp.ulid}`;
        }

        return `${markerMmethods[blp.method]}/analysis/${blp.ulid}`;
      });
    
    if(data?.statusCancellation){
      examinationBlueprints = data?.examinationReferral?.examinationBlueprints
      .filter((blp: Examination) => blp.status === 'Cancelled')
      .map((blp: Examination) => {
        methodByBluprintMap[blp?.ulid ?? ''] = markerMmethods[blp.method];

        if(markerMmethods[blp.method] === 'ihc'){
          // if(blp.marker?.toLowerCase() === 'alk') return `ihc/alk/analysis/${blp.ulid}`;
          if(blp.marker?.toLowerCase() === 'alk') return `ihc/analysis/${blp.ulid}`;
          // return `ihc/ihc/analysis/${blp.ulid}`;
          return `ihc/analysis/${blp.ulid}`;
        }
        return `${markerMmethods[blp.method]}/analysis/${blp.ulid}`;
      });
    }

    if(!Object.keys(getMethodByBluprint)?.length){
       setMethodByBluprint(methodByBluprintMap);
    };

    if (tokens?.access) {
      dispatch(fetchServiceUsers(tokens?.access, { organizationId: 0 }));
      if (examinationBlueprints?.length) dispatch(fetchAnalysisByBluprintList(tokens?.access, examinationBlueprints));
    }

  }, [data, dispatch, tokens?.access, checkAllIsDone,referralID,getMethodByBluprint]);


  const defaultExecutorOptions = useMemo(() => {
    if (!doctorsList) return [];
    function shortName(fName: string, lName: string, mName: string) {
      let lastName = lName ? lName[0].toUpperCase() + '.' : '';
      let midName  = mName ? mName[0].toUpperCase() + '.' : '';
      return `${fName} ${lastName} ${midName}`;
    }
    //find user who created attempt
    const options = doctorsList
      .map((genetic: any) => {
        let { firstName, lastName, middleName, title } = genetic?.userProfile ?? {};
        let label = shortName(firstName, lastName, middleName);
        let fullName = `${lastName} ${firstName} ${middleName}`.trim();
        return { value: genetic.ulid, label, title, fullName, disabled: false }
      });

    let defaultExecutorMap: { [index: string]: any; } = {};

    options.forEach((option: any) => {
      defaultExecutorMap[option.value] = { name: option.label, title: option.title, fullName: option.fullName };
    });

    return defaultExecutorMap;
  },[doctorsList]); 

  return (
    <TWrapper>
      <TDateOfCompletion>{!!dateOfCompletion && `Дата завершения: ${dateOfCompletion}`}</TDateOfCompletion>
      <TDetailsItemWr>
        {/* modulePatient */}
        {conclusionModules.includes('modulePatient') && <ConclusionTab title={'Пациент и клинические данные'}>
          <Patient defaultData={savedConclusionData} />
        </ConclusionTab>
        }

        {/* moduleLabDetail */}
        {conclusionModules.includes('moduleLabDetail') && 
        <ConclusionTab title={'Детализация выполненных исследований'}>
          <LabDetail 
            referralID={referralID} 
            defaultData={savedConclusionData } 
            executorMap={defaultExecutorOptions}
          />
        </ConclusionTab>
        }

        {/* moduleSensitivity */}
        {conclusionModules.includes('moduleSensitivity') && !(savedConclusionData?.moduleSensitivity?.noSpecify && isStepComplite) && <ConclusionTab title={'Потенциальная чувствительность'}>
          <PotentialSensitivity
            stepComplite={isStepComplite}
            referralID={referralID}
            hasPermit={ableToUpdateANALYSIS?.Sensitivity}
            defaultData={savedConclusionData}
          />
        </ConclusionTab>
        }

        {/* moduleToxicity */}
        {conclusionModules.includes('moduleToxicity') && !(savedConclusionData?.moduleToxicity?.noSpecify && isStepComplite) && <ConclusionTab title={'Токсичность'}>
          <Toxicity
            stepComplite={isStepComplite}
            referralID={referralID}
            hasPermit={ableToUpdateANALYSIS?.Toxicity}
            defaultData={savedConclusionData}  
          />
        </ConclusionTab>}

        {/* moduleMorphology */}
        {conclusionModules.includes('moduleMorphology') && 
          <ConclusionTab title={'Клинико-лабораторное заключение'}>
            <MorphlogyConclusion 
              stepComplite={isStepComplite} 
              referralID={referralID}
              hasPermit={ableToUpdateANALYSIS?.Sensitivity}
              defaultData={savedConclusionData}
            />
          </ConclusionTab>
         }

        {/* moduleDefault */}
        {conclusionModules.includes('moduleDefault') && (
          <ConclusionTab title={'Общие положения'}>
            <FormHeaders
              hasPermit={ableToUpdateANALYSIS?.ExperimentReview}
              stepComplite={isStepComplite}
              referralID={referralID}
              defaultData={savedConclusionData ?? undefined}
            />
          </ConclusionTab>)
        }

        {/* moduleSignatory */}
        {conclusionModules.includes('moduleSignatory') && <ConclusionTab title={'Ответственные лица и дата выдачи'}>
          <ResponsiblePersons
            referralData={data} 
            signatoryList={signatoryListData} 
            calbackDataForSend={() => null} 
            stepComplite={isStepComplite}
            hasPermit={ableToUpdateANALYSIS?.Inspection}
            referralID={referralID}
            defaultData={savedConclusionData}  
          />
        </ConclusionTab>}
      </TDetailsItemWr>

      {!!checkAllIsDone && <div style={{ display: isStepComplite ? "none" : "block" }}><ConclusionToPrint ref={printTemplateRef} modules={modulesReadyToPrint}/></div>}
      {/* {ableToUpdateANALYSIS?.CloseConslusion &&  */}
        {/* <TButtonWrapper>
          {!!checkAllIsDone && <CustomButton type="button" disabled={false} onClick={() => handlePrint()}>
          Распечатать бланк
        </CustomButton>}
        </TButtonWrapper> */}
        {/* } */}
    </TWrapper>
  )
}

export default Сonclusion;