import { FunctionComponent, useCallback } from 'react';
import ReactModal from 'react-modal';
import { CloseReason } from './const';
import { Styles, TCloseButton, TModal, TModalContent } from './styled';
import { ReactComponent as CloseIcon } from '../../../icons/cross-in-circle.svg';

interface IModal {
  isOpen: boolean;
  shouldCloseOnOverlayClick?: boolean;
  hasCloseButton?: boolean;
  handleClose: (closeReason: CloseReason) => void;
  width?: number | string;
  height?: number | string;
}

const Modal: FunctionComponent<IModal> = ({
  isOpen,
  shouldCloseOnOverlayClick,
  hasCloseButton,
  handleClose,
  children,
  width = 570,
  height = 'auto',
}) => {
  ReactModal.setAppElement(document.getElementById('root') || 'body');
  const handleOverlayClick = useCallback(
    (event) => {
      if (event.target === event.currentTarget && handleClose) {
        handleClose(CloseReason.OVERLAY_CLICK);
      }
    },
    [handleClose],
  );

  return (
    <>
      <Styles />
      <TModal
        isOpen={isOpen}
        onRequestClose={handleOverlayClick}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        contentLabel='Modal'
      >
        {hasCloseButton && (
          <TCloseButton onClick={() => handleClose(CloseReason.CLOSE_BTN_CLICK)}>
            <CloseIcon />
          </TCloseButton>
        )}
        <TModalContent style={{ width, height }}>{children}</TModalContent>
      </TModal>
    </>
  );
};

export default Modal;
