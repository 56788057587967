import { FC, ReactNode } from 'react';
import { TBody, TContainer, THeader } from './styled';
import useHeaderSize from '../../../hooks/useHeaderSize';

export interface IPageContainer {
  className?: string;
  header?: ReactNode;
}

const PageContainer: FC<IPageContainer> = ({ className, header, children }) => {
  const headerHeight = useHeaderSize()?.headerSize;

  return (
    <TContainer id='app-container'  headerHeight={headerHeight} className={className}>
      <THeader id='app-header'>{header}</THeader>
      <TBody  id='app-body' headerHeight={headerHeight}>{children}</TBody>
    </TContainer>
  );
};

export default PageContainer;
