import { FC, ReactNode, useCallback } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import {
  HeaderItem,
  HeaderWrapper,
  ItemsWrapper, RowItem,
  RowItemWrapper,
  RowWrapper,
  TTable,
  TTableWrapper,
  TWrapper,
} from './styled';
import { useDispatch } from 'react-redux';
import { resetConclusionData } from '../../../../../store/molProConclusion';


export interface IColumn {
  header: string | JSX.Element |  ((options?: any) => ReactNode | string | number);
  columnWidth?: string;
  renderer: (item: any ) => ReactNode | string | number;
  order?: { name: string };
  position?: string;
  margin?: string;
  id: number;
}

interface ITable<T> {
  columns: IColumn[];
  items: T[];
  hasPermit?:boolean;
}

const TableHeader = ({id, header}:any) => {
  return (
    <HeaderItem key={id}>{header}</HeaderItem>
  )
}

const ReferralTable: FC<ITable<any>> = ({columns,items,hasPermit,...props}) => {

  const { id: referralULID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getNextReferral = useCallback((new_uld:string)=>{
    if(!hasPermit) return;
    if(new_uld || new_uld !== referralULID){
      dispatch(resetConclusionData());
      navigate(`/molecular-profiling/detail/${new_uld}/referral`);
    };
  },[referralULID,navigate,hasPermit,dispatch]);

  return (
    <>
      <TWrapper>
        <TTableWrapper>
          <TTable>
            <HeaderWrapper>
              {columns.map(({id, header}) =>(
                <TableHeader key={'header'+ id} id={id} header={header}/>
                  ))}
            </HeaderWrapper>
            <RowWrapper>
              <ItemsWrapper>
                   {items.map((item) => {
                     return (
                      <RowItemWrapper onClick={() => getNextReferral(item.ulid)} key={item.ulid} selected={item.ulid === referralULID}>
                       {columns.map(({ renderer },index) => (
                         <RowItem key={index + item.ulid} selected={item.ulid === referralULID}>
                           {renderer(item)}
                         </RowItem>
                       ))}
                     </RowItemWrapper>
                   )})}
               </ItemsWrapper>
            </RowWrapper>
          </TTable>
        </TTableWrapper>
      </TWrapper>
    </>
  );
};

export default ReferralTable;
