import styled from 'styled-components';
import Button from '../../shared/Button';
import { Link } from 'react-router-dom';

export const TFieldsContainer = styled.div`
  padding: 0 52px;
`;

export const TBottomLinkWr = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 49px;
  width: 100%;
`;

export const TLink = styled(Link)`
  color: ${({ theme }) => theme.colors.gray[500]};
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    color: ${({ theme }) => theme.colors.product.primaryHover};
  }
`;

export const TButton = styled(Button)`
  width: 100%;
  height: 44px;
  font-size: 20px;
  margin-top: 56px;
  margin-bottom: 67px;
  font-weight: 500;
`;

export const TSubtitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black[200]};
  margin-bottom: 50px;
`;

export const TDescription = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray[500]};
  margin: 0 52px 40px;
  width: 364px;
  height: 96px;
`;

export const TTextEmail = styled.span`
  color: ${({theme}) => theme.colors.gray[500]};
  font-weight: 700;
`
