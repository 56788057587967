import { FC } from 'react';
import { TTitle } from './styled';

export interface IPageTitle {
  className?: string;
}

const PageTitle: FC<IPageTitle> = ({ className, children }) => {
  return (
    <TTitle className={className}>
      {children}
    </TTitle>
  );
};

export default PageTitle;
