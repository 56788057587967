import { Dispatch } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { getCountries, getCountriesFailure, getCountriesSuccess } from './';
import { loadLocalStorageData, saveLocalStorageData } from '../../utils/localStorageHelpers';

export const fetchCountries = (access: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getCountries());
  const savedData = await loadLocalStorageData('registry/country/list/');
  if(savedData) return dispatch(getCountriesSuccess(savedData));

  try {
    const response = await axiosInstance(access).get('registry/country/list/');
    await saveLocalStorageData('registry/country/list/', response.data);
    dispatch(getCountriesSuccess(response.data));
  } catch (error: any) {
    dispatch(getCountriesFailure(error?.response?.data));
  }
}