import { FieldError } from 'react-hook-form/dist/types/errors';
import { ErrorsType, Nullable } from '../types';
import { getYear } from 'date-fns';
const currentYear = getYear(new Date());

export const SERVER_ERRORS: Record<string, string> = {
  'service user with this email already exists.': 'Пользователь с таким email уже существует',
  'This field may not be blank.': 'Это поле не может быть пустым',
  'Password must contain at least 1 digit': 'Пароль должен содержать хотя бы 1 цифру',
  'Password must contain at least 1 letter.': 'Пароль должен содержать хотя бы 1 букву',
  'Password must NOT contain special characters.': 'Пароль не должен содержать спец символы',
  'Ensure this field has at least 8 characters.': 'Убедитесь что пароль содержит хотя бы 8 символов',
  'Password do not match': 'Пароли не совпадают',
  'This password is too common.': 'Пароль слишком простой',
  'Invalid credentials.': 'Введен неверный email или пароль',
  'Enter a valid email address.': 'Введите правильный email',
}

const validations = {
  fullName: { required: true },
  firstName: { required: true, minLength: 2, pattern: /^[a-zA-Zа-яА-ЯёЁ.-\s]*$/, maxLength: 40},
  lastName: { required: true, minLength: 2,pattern: /^[a-zA-Zа-яА-ЯёЁ.-\s]*$/, maxLength: 40 },
  middleName: { required: true },
  username: { required: true },
  email: { required: true, pattern: /\S+@\S+\.\S+/ },
  phoneNumber: { required: true },
  specialization: { required: true },
  title: { required: true },
  organization: { required: true },
  department: { required: true },
  subdepartment: { required: true },
  birthDate: { required: true },
  password: { required: true },
  rePassword: { required: true },
  idPassport: { required: true },
  country: {},
  gender: { required: true },
  idAmbulatoryCard: { required: true, pattern: /^[0-9]{5}[\\/][0-9]{2}$/ },
  payment: { required: true },
  speed: { required: true },
  localization: { required: true },
  icd_10: { required: true },
  clinical: { required: true },
  stage: { required: true },
  histological: { required: true },
  creationDate: { required: true },
  startCode: { required: true, maxLength: 25 },
  blockOrgan: { required: true, minLength: 1, maxLength: 80 },
  source: { required: true },
  year: { required: true},
  cancerousCellPercentage: {required: true,  valueAsNumber: true, validate: {
    positive: (v:any) => parseInt(v) >= 0,
    lessThanHundred: (v:any) => parseInt(v) <= 100,
  },setValueAs: (v:any) => parseInt(v)},
  description: {required: true}
};

const extendedValidations = {
  password: {
    minLength: 8,
    maxLength: 16,
    pattern: /^(?=.*[A-Z])(?=.*[a-z0-9~!@#$%^&*()_{}":;'[\]])[a-zA-Z0-9~!@#$%^&*()_{}":;'[\]]{8,}$/gi,
  },
  phoneNumber: {
    pattern: /^\+375(\s+)?\(?[0-9]{2}\)?(\s+)?[0-9]{3} [0-9]{2} [0-9]{2}$/,
  },
  code: {
    maxLength: 3,
    max: 200,
    min: 1
  },
  blockCode: {
    maxLength: 12,
  },
  idPassport: {
    minLength: 7,
    pattern: /^[A-Z0-9]+$/
  },
  idPassportBY: {
    minLength: 14,
    pattern: /^([0-9]{7}[A-Z]{1}[0-9]{3}[A-Z]{2}[0-9]{1})$/,
  },
  year: {
    min: 2000,
    max: currentYear
  }
};

const defaultErrorMessages: Record<string, string> = {
  required: '',
};

const errorMessages: Record<string, Record<string, string>> = {
  // email: {
  //   pattern: 'Email должен быть формата i.ivanov@example.com',
  // },
  password: {
    minLength: 'Пароль должен содержать минимум 8 символов',
    pattern: 'Пароль не соответствует требованиям',
    required: 'Пароль обязателен'
  },
  rePassword: {
    validate: 'Пароли не совпадают',
    required: 'Пароль обязателен'
  },
  subdepartment: {
    pattern: 'Обязательное поле'
  },
  year: {
    validate: 'Год указан не верно',
    min: 'Год не может быть меньше 2018',
    max: 'Вы не можете задать год который еще не наступил'
  },
  email: {
    validate: 'Email указан не верно',
    required: 'Email обязателен',
    pattern: 'Email должен быть формата i.ivanov@example.com',
  },
  firstName: {
    required: 'Имя обязательно',
    pattern: 'Только буквы',
    maxLength: 'Длинна до 40 сим-лов'
  },
  lastName: {
    required: 'Фамилия обязательна',
    pattern: 'Только буквы',
    maxLength: 'Длинна до 40 сим-лов'
  },
  fullName: {
    required: 'Это поле не может быть пустым'
  },
  organ: {
    required: 'Это поле не может быть пустым',
    minLength: 'Название слишком короткое',
    maxLength: 'Превышено допустимое количество символов'
  }
};

const getServerErrorsList = (serverErrors: string[] | string) => {
  if (typeof serverErrors === 'string') {
    return SERVER_ERRORS[serverErrors] ?? serverErrors;
  }

  return serverErrors.reduce((errors: string[], error) =>
    SERVER_ERRORS[error] ? [...errors, SERVER_ERRORS[error]] : [...errors, error], []);
}

const getErrorMessage = (formErrors: Record<string, FieldError>, fieldName: string, serverErrors?: Nullable<ErrorsType>): string => {
  const { type } = formErrors[fieldName] ?? {};

  // const transformedFieldName = transformSnakeCaseToCamelCase(fieldName);

  return (
    errorMessages[fieldName] && errorMessages[fieldName][type]) ?? defaultErrorMessages[type] ??
    (serverErrors && serverErrors[fieldName] && getServerErrorsList(serverErrors[fieldName])) ?? '';
};

export { validations, getErrorMessage, extendedValidations };
