import styled from 'styled-components';

export const TLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 50px;
  height: 26px;
  box-sizing: border-box;
`

export const CustomSpan = styled.span<{isChecked: boolean, isError?: boolean}>`
  position: absolute;
  top: 0;
  left: 3px;
  right: 0;
  bottom: 0;
  cursor: default;
  background-color: ${({theme, isChecked, isError}) => isChecked ? theme.colors.alert.checkedRadioButton : (isError ? theme.colors.alert.warning : theme.colors.gray[200])};
  border-radius: 20px;

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.white};
    left:${({isChecked}) => isChecked ? '22px' : '3px'}
    }
  
`
export const CustomInput = styled.input`
  display: none;
  width: 0;
  height: 0;
  opacity: 0;
`

export const TTile = styled.div<{isChecked: boolean}>`
  margin-left: 66px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${({theme, isChecked}) => isChecked ? theme.colors.black[200] : theme.colors.gray[200]};
  position: absolute;
  width: max-content;
`;

