import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TButtonWr, TDateOfCompletion, TEditButton, TPaymentWr, TReferralSideDataWr, TRowWr, TSaveButton, TSaveButtonWr, TSpeedWr } from './styled';
import { format } from 'date-fns';
import { SIZE, VARIANT } from '../../../components/shared/Button';
import Radio from '../../../components/shared/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { getTokens } from '../../../store/auth/selectors';
import { patchReferralChangePaymentAndSpeedType } from '../../../store/molecularProfiling/thunkActions';
import { removeItemSessionStorage, setToSessionStorage } from '../../../utils/sessionStorageHelpers';
import { Department, Organization } from '../../../store/organizations/model';
import { getServiceUsers } from '../../../store/serviceUsers/selectors';
import NotEditedInput from '../../../components/shared/NotEditedInput';

interface IComponentProps {
  data?:any;
  [index:string]: any;
}

const ReferralSideData: FC<IComponentProps> = (data) => {

  // const doctorProfile = data?.doctorReferral?.userProfile;
  const PAID_PAYMENT_VALUE = 'Paid';
  const FREE_PAYMENT_VALUE = 'Free';
  const COMMON_SPEED_VALUE = 'Common';
  const URGENT_SPEED_VALUE = 'Urgent';
  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);

  const [ isEdit, setEdit] = useState(false);
  const [paymentValue, setPaymentValue] = useState('');
  const [speedValue, setSpeedValue]     = useState('');
  const doctorsList = useSelector(getServiceUsers);

  //set current paymnt type
  useEffect(() => {
    setPaymentValue(data?.paymentType);
    setSpeedValue(data?.speedType);
    return () => {
      setPaymentValue('');
      setSpeedValue('');
    };
  },[data]);

  //handle edit button
  const handleEditPayment = useCallback(() => {
    setEdit(!isEdit);
    if(isEdit){ 
      setPaymentValue(data?.paymentType);
      setSpeedValue(data?.speedType);
    };
  },[isEdit,setEdit,data,setPaymentValue,setSpeedValue]);

  //set payment value by radio button
  const handlePaymentValue = useCallback((paymentValue) => {
     setPaymentValue(paymentValue);
  },[setPaymentValue]);
  //set payment value by radio button
  const handleSpeedValue = useCallback((speedValue) => {
    setSpeedValue(speedValue);
  },[setSpeedValue]);

  //send to server
  const handleSaveChanges = useCallback(() => {
    tokens?.access && dispatch(patchReferralChangePaymentAndSpeedType(tokens.access, data.ulid, paymentValue, speedValue));
    return setTimeout(()=>{
        setEdit(false);
        removeItemSessionStorage('CHANGES_NOT_SAVED_ReferralSideData');
    },1000);

  },[dispatch,tokens, paymentValue,speedValue, data]);

  //save changes
  useEffect(()=> {
    if(isEdit){
      if(paymentValue !== data?.paymentType || speedValue !== data?.speedType){
        let notSavedData = JSON.stringify([{'paymentType':paymentValue, 'speedType':speedValue}]);
        setToSessionStorage(['CHANGES_NOT_SAVED_ReferralSideData',notSavedData]);
      }
    }else {
      removeItemSessionStorage('CHANGES_NOT_SAVED_ReferralSideData');
    }
  },[paymentValue,speedValue,isEdit,data]);

  const getUserOrgDep = useCallback((profile:any) => {
    if(!data?.organizations || !profile) return {org: '', dep: ''};
    let orgData, depData;
    let orgId = profile?.organization ?? '';
    let depId = profile?.department ?? '';
    let orgList = data.organizations ?? [];
    if(orgId && orgList.length ) orgData = orgList.find((org:Organization) => org.id === +orgId);
    if(orgData && depId) depData = orgData.organizationDepartment.find((dep:Department) => dep.id === depId);

    return {org: orgData?.nameLocalRus ?? '', dep: depData?.nameLocalRus ?? ''};

  },[data?.organizations]);

  const doctorCustomOrg = useMemo(() => data?.doctorReferral?.userProfile?.organizationCustom,[data?.doctorReferral?.userProfile]);

  const getUserFullName = useCallback((profile:any,addTitle?:boolean) => {
    if(!profile) return '';
    let firstName   = profile['firstName']  ?? '';
    let middleName  = profile['middleName'] ?? '';
    let lastName    = profile['lastName']   ?? '';
    let title       = addTitle ? `, ${profile['title'] ?? ''}` : '';

    return `${lastName} ${firstName} ${middleName}${title}`;
  },[]);

  const doctorOrgOrDep = useMemo(() => getUserOrgDep(data?.doctorReferral?.userProfile),[data?.doctorReferral?.userProfile, getUserOrgDep]);

  const [fullDoctorName,creatorName] = useMemo(() => {
    let doctorProfile = data?.doctorReferral?.userProfile;
    let doctorName = getUserFullName(doctorProfile);

    let referralCreatorID = data?.referralCreator;
    let referralCreatorProfile = doctorsList?.find((item) => item.ulid === referralCreatorID)?.userProfile;
    let isSamePerson = doctorProfile?.ulid === referralCreatorProfile?.ulid;
    let referralCreatorName = isSamePerson ? '' : getUserFullName(referralCreatorProfile, true);

    return [doctorName,referralCreatorName];
  },[data,doctorsList, getUserFullName])




  const paymentTypeDefault = useMemo(() => {
    if(!data?.paymentType) return '';
    if(data?.paymentType === FREE_PAYMENT_VALUE) return 'Бесплатно';
    return 'Платно';
  },[data?.paymentType,FREE_PAYMENT_VALUE])

  const speedTypeDefault = useMemo(() => {
    if(!data?.speedType) return '';
    if(data?.speedType === COMMON_SPEED_VALUE) return 'Планово';
    return 'Срочно';
  },[data?.speedType,COMMON_SPEED_VALUE])

  const createdDate = useMemo(() =>{
    if(!data?.dateCreated) return '';
    return format(new Date(data.dateCreated), 'dd.MM.yyyy - HH:mm:ss')
  },[data?.dateCreated])

  const createdDateOfAcquisition= useMemo(() =>{
    if(!data?.dateOfAcquisition) return '';
    return format(new Date(data.dateOfAcquisition), 'dd.MM.yyyy')
  },[data?.dateOfAcquisition])

  const isEditable = useMemo(() => {
    let statuses = ['markers_validation_pending'];
    return !data.statusCancellation && statuses?.includes(data.status) && data?.ableToUdateREFERRAL?.updateClinicalData
  }, [data]);


  return (
    <TReferralSideDataWr>
      <TRowWr direction={'space-between'}>
        <TDateOfCompletion>{!!createdDate && `Дата создания в системе: ${createdDate}`}</TDateOfCompletion>
      {isEditable && (
        <TButtonWr>
          <TEditButton disabled={!isEditable} active={isEdit} onClick={handleEditPayment}>{isEdit ? 'Отменить' : 'Редактировать'}</TEditButton>
        </TButtonWr>
      )}
      </TRowWr>
        <NotEditedInput width={'49%'} label='Учреждение здравоохранения'>{doctorCustomOrg || (doctorOrgOrDep.org ?? '')}</NotEditedInput>
        <NotEditedInput width={'49%'} label='Отделение'>{doctorOrgOrDep.dep ?? ''}</NotEditedInput>
        <NotEditedInput width={'49%'} label='ФИО врача'>{fullDoctorName ?? ''}</NotEditedInput>
        <NotEditedInput width={'49%'} label='Дата направления на исследование'>{createdDateOfAcquisition ?? ''}</NotEditedInput>
        { !isEdit && <NotEditedInput width={'49%'} label='Оплата'>{paymentTypeDefault ?? ''}</NotEditedInput>}
        { !isEdit && <NotEditedInput width={'49%'} label='Приоритет'>{speedTypeDefault ?? ''}</NotEditedInput>}
        

      {!!creatorName && <NotEditedInput width={'100%'} label='ФИО регистратора'>{creatorName ?? ''}</NotEditedInput>}
      {isEditable && isEdit && 
        <>
        <TPaymentWr label={'Оплата'}>
          <Radio selected={paymentValue} value={PAID_PAYMENT_VALUE} label='Платно' onChange={handlePaymentValue} width={'49%'}/>
          <Radio selected={paymentValue} value={FREE_PAYMENT_VALUE} label='Бесплатно' onChange={handlePaymentValue} width={'49%'}/>
        </TPaymentWr>
        <TSpeedWr label={'Приоритет'}>
          <Radio selected={speedValue} value={URGENT_SPEED_VALUE} label='Срочно' onChange={handleSpeedValue} width={'49%'}/>
          <Radio selected={speedValue} value={COMMON_SPEED_VALUE} label='Планово' onChange={handleSpeedValue} width={'49%'}/>
        </TSpeedWr>
        </>
      }
      {isEditable  && <TSaveButtonWr>
        <TSaveButton 
          disabled={!isEdit || (paymentValue === data?.paymentType && speedValue === data?.speedType)} 
          onClick={handleSaveChanges} 
          variant={VARIANT.TRANSPARENT} size={SIZE.SMALL}>Сохранить изменения</TSaveButton>
      </TSaveButtonWr>}
    </TReferralSideDataWr>
  )
}

export default ReferralSideData;
