import { createSelector } from '@reduxjs/toolkit';
import { IAppState } from '../';
import { transformBloodId } from '../../utils/stringHelpers';

const getState = (state: IAppState) => state.referral;

export const getReferral = createSelector(getState, state => state.referral);
export const getReferralLoading = createSelector(getState, state => state.loading );
export const getReferralsData = createSelector(getState, state => state.referrals);

export const getFilterData = createSelector(getState, state => state.filter);

export const getReferrals = createSelector(getReferralsData, referrals => referrals.data ?? []);
export const getReferralCurrentPage = createSelector(getReferralsData, referrals => referrals.currentPage);
export const getReferralsTotalPages = createSelector(getReferralsData, referrals => referrals.pages);
export const getReferralsTabTypes = createSelector(getReferralsData, referrals => referrals.types);

export const getBloodId = createSelector(getState, state =>
  state.bloodId !== null ? transformBloodId(`${state.bloodId}`) : null);

export const getSelectedLocalization = createSelector(getReferral, referral => referral.diagnosis?.localization);

export const getCreatedReferralId = createSelector(getState, state => state.createdReferralId);
export const getCreatedReferralUlid = createSelector(getState, state => state.createdReferralUlid);
export const getValidateError = createSelector(getState, state => state.errors);
