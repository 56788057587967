const prepareTooltipData = (tooltip_id: string) => {
  switch (tooltip_id) {
    case 'molecular_profiling':
      return (
        <div>
          <p>
            Молекулярное профилирование включает возможность проведения исследований следующими методами:
          </p>
          <ul>
            <li>
              ПЦР
            </li>
            <li>
              Фрагментный анализ
            </li>
            <li>
              ИГХ
            </li>
            <li>
              FISH
            </li>
            <li>
              Секвенирование по Сэнгеру
            </li>
          </ul>
          <p>
            Данные исследования являются специфическими
            и требуют указания <b>локализации</b> опухоли и исследуемых <b>маркеров</b>.
            Также обязательным является наличие парафиновых <b>блоков</b>.
          </p>
        </div>
      )
    case 'assessment_of_hereditary_risks':
      return (
        <p>
          Создание данного типа направления пока недоступно
        </p>
      )
    case 'molecular_diagnostics':
      return (
        <p>
          Создание данного типа направления пока недоступно
        </p>
      )
  }

};

export default prepareTooltipData;