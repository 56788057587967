export const resetLocalStorageData = () => {
  localStorage.clear();
}

export const removeItemLocalStorage = (...keys: string[]) => {
  keys.length && keys.forEach( key => localStorage.removeItem(key));
}

export const loadLocalStorageData = async (name:string) => {
  
  try {
    const serialState = await localStorage.getItem(name);
    if (serialState === null) {
      return undefined;
    }
    return JSON.parse(serialState);
  } catch (err) {
    console.log('localStorage is not load data',err);
    return undefined;
  }
};

export const saveLocalStorageData = async (name:string, data:any) => {

  try {
    
    const serialState = JSON.stringify(data);
    await localStorage.setItem(name, serialState);
  } catch(err) {
      console.log('localStorage is not saved data',err);
      return undefined;
  }
};