import {useEffect, useState} from "react";
import {CustomCopyedTooltip, CustomSupportEmail, SupportWrapper} from "./styles";
let timeoutID: any;
const SupportContactComponent = () => {
  const supportEmail = 'genx.support@devnull.express';
  const [copySuccess, setCopySuccess] = useState('');
  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess(copyMe);
    }
    catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  useEffect(() => {
    if(copySuccess) {
      timeoutID = setTimeout(() => {
        setCopySuccess('')
      }, 1000)
    }
    return timeoutID && clearTimeout(timeoutID)
  }, [copySuccess])

  return (
    <SupportWrapper>Чтобы сообщить нам о возникшей проблеме или внести предложение по улучшению системы, отправьте письмо на адрес &nbsp;
      <CustomSupportEmail onClick={() => copyToClipBoard(supportEmail)}>{supportEmail}
        {copySuccess && <CustomCopyedTooltip>
            Скопировано
        </CustomCopyedTooltip>
        }
      </CustomSupportEmail>
    </SupportWrapper>

  )
};

export default SupportContactComponent;