import { Dispatch } from "react";
import { getResearch, getResearchesFailure, getResearchesSuccess } from "./index";
import AxiosInstance from "../../api/axiosInstance";
import { Filter } from "../referral/model";

let lastQuery = '';
let lastData:any = [];


export const fetchResearch = (access: string, params?: Filter) => async (dispatch: Dispatch<unknown>) => {

  let { ulid, searchQuery, nextPage, pageSize, tab, dateCreatedGte, 
    dateCreatedLte, status, cancelled, priority, type, bio, executor, method, marker } = params ?? {};
  const paramsList: string[] = [];
  const safetyParamsList: string[] = [];

  if (ulid) {
    paramsList.push(`patient__ulid=${ulid}`);
    safetyParamsList.push(`patient__ulid=${ulid}`);
  }
  if (searchQuery) {
    let searchQueryData = searchQuery.split('=')?.[1];
    if (searchQueryData) {
      paramsList.push(searchQuery);
      safetyParamsList.push(searchQuery);
    };

  }
  if (nextPage) {
    paramsList.push(`page=${nextPage}`);
    safetyParamsList.push(`page=1`);
  }
  if (pageSize) {
    paramsList.push(`page_size=${pageSize}`);
    safetyParamsList.push(`page_size=${pageSize}`);
  }
  if (tab && !searchQuery) {
    paramsList.push(`tab=${tab}`);
    safetyParamsList.push(`tab=${tab}`);
  }
  if (dateCreatedGte) {
    paramsList.push(`date_created_gte=${dateCreatedGte}`);
    safetyParamsList.push(`date_created_gte=${dateCreatedGte}`);
  }
  if (dateCreatedLte) {
    paramsList.push(`date_created_lte=${dateCreatedLte}`);
    safetyParamsList.push(`date_created_lte=${dateCreatedLte}`);
  }
  if (status) {
    paramsList.push(`status=${status}`);
    safetyParamsList.push(`status=${status}`);
  }
  if (cancelled === 'True') {
    paramsList.push(`cancelled=${cancelled}`);
    safetyParamsList.push(`cancelled=${cancelled}`);
  }
  if (priority) {
    paramsList.push(`priority=${priority}`);
    safetyParamsList.push(`priority=${priority}`);
  }
  if (type) {
    paramsList.push(`type=${type}`);
    safetyParamsList.push(`type=${type}`);
  }
  if (bio) {
    paramsList.push(`bio=${bio}`);
    safetyParamsList.push(`bio=${bio}`);
  }
  if (executor) {
    paramsList.push(`executor=${executor}`);
    safetyParamsList.push(`executor=${executor}`);
  }
  if (method) {
    paramsList.push(`method=${method}`);
    safetyParamsList.push(`method=${method}`);
  }
  if (marker) {
    paramsList.push(`marker=${marker}`);
    safetyParamsList.push(`marker=${marker}`);
  }




  //check for dublicate requests
  if(lastQuery !== `registry/blueprint/list/?${paramsList.join('&')}`){
    lastQuery = `registry/blueprint/list/?${paramsList.join('&')}`
  } else {
    return lastData?.length ? dispatch(getResearchesSuccess(lastData)) : null;
  } 

  async function getFilteredListByParams(params: string[]) {
    const response = await AxiosInstance(access).get(`registry/blueprint/list/?${params.join('&')}`)
    return response;
  }


  try {
    dispatch(getResearch());
    const response = await getFilteredListByParams(paramsList)
    lastData = response.data;
    dispatch(getResearchesSuccess(response.data));
  } catch (error: any) {
    try {
      const response = await getFilteredListByParams(safetyParamsList)
      lastData = response.data;
      dispatch(getResearchesSuccess(response.data));
    } catch (error: any) {
      dispatch(getResearchesFailure(error?.response?.data))
    }
  }


};