import { createSlice } from '@reduxjs/toolkit';
import { Organization } from './model';
import { ErrorsType, Nullable } from '../../types';

export interface IOrganizationInitialState {
  loading: boolean;
  data: Organization[];
  errors: Nullable<ErrorsType>;
}

export const initialState: IOrganizationInitialState = {
  loading: false,
  data: [],
  errors: null,
};

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    getOrganizations: state => {
      state.loading = true;
    },
    getOrganizationsSuccess: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.errors = null;
    },
    getOrganizationsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
  },
});

export const { getOrganizations, getOrganizationsSuccess, getOrganizationsFailure } = organizationsSlice.actions

export default organizationsSlice.reducer;
