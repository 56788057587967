import {useSelector} from "react-redux";
import {getValidateError} from "../../../../../store/referral/selectors";
import {useCallback} from "react";

type PreparedErrorDataType = {
  error: boolean,
  error_content: string | null
}

const usePrepareAmbulatoryCardsError = () => {
  const ambulatoryCardsErrors = useSelector(getValidateError);
  const preparedAmbulatoryCardsError = useCallback((index: number) => {
    let PreparedErrorData: PreparedErrorDataType = {
      error: false,
      error_content: ''
    }
    // @ts-ignore
    if(!ambulatoryCardsErrors?.payload?.data) return null

      // @ts-ignore
    if (ambulatoryCardsErrors?.payload?.data[index]?.isDuplicate === 'True') {
        PreparedErrorData = {
          error: true,
          error_content: 'Номера амбулаторных карт дублируются',
        }
      }

      // @ts-ignore
    if (ambulatoryCardsErrors?.payload?.data[index]?.errorContent) {
      PreparedErrorData = {
          error: true,
          // @ts-ignore
          error_content: ambulatoryCardsErrors?.payload?.data[index].errorContent
        }
      }

    return PreparedErrorData
  }, [ambulatoryCardsErrors]);

  return {
    preparedAmbulatoryCardsError
  }
};

export default usePrepareAmbulatoryCardsError;