import { FC } from 'react';
import {
  TPasswordRequirementsHint,
} from './styled';

interface IPasswordHint {
  opened: boolean;
}

const PasswordHint: FC<IPasswordHint> = ({ opened }) => {
  if (!opened) return null;

  return (
    <TPasswordRequirementsHint>
      Требования к паролю:
      <ul>
        <li>- минимум 8 символов</li>
        <li>- латинские буквы и цифры</li>
        <li>- минимум одна заглавная буква</li>
      </ul>
    </TPasswordRequirementsHint>
  );
};

export default PasswordHint;
