import styled from 'styled-components';
import Button from '../../../../../components/shared/Button';

export const TSelectPatientBlockWr = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  
  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-bottom: 0;
    margin-top: 20px;
  }
`;

export const TFoundedPatient = styled.div<{ selected: boolean }>`
  width: 100%;
  box-sizing: border-box;
  padding: 18px 30px;
  background: #FFFFFF;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme, selected }) => selected ? theme.colors.alert.border : 'transparent'};
  ${({ selected }) => selected && 'box-shadow: 1px 1px 20px rgba(122, 120, 233, 0.2)'};
`;

export const TButtonChoosePatientWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`

export const CustomButton = styled(Button)`
  /* color: ${({theme}) => theme.colors.white};
  border: 2px solid ${({theme}) => theme.colors.blue[300]}; */
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  
  /* &:hover:enabled{
    color: ${({theme}) => theme.colors.blue[300]};
    border: 2px solid ${({theme}) => theme.colors.blue[300]};
  } */
`

export const TBlockPatientInfo = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.black[200]}
`

export const TBlockInfo = styled.div`
  margin-left: 20px;
`

export const TBlockID = styled.div`
`
