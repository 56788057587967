import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import IconSquareButton from "../../../components/shared/IconSquareButton";
import { IBlockMap, ISubBlockSelect } from "./MorphologicalCharacteristic";
import {
  TAddNewSubButtonText, TAddNewSubButtonWr, TArrowIcon, TGlassMessage,
  TInfoTextWrapper, TInvertCheckIcon, TInvertCrossIcon, TInvertPlusIcon, TPromptMessage,
  TSubBlockFormWr, TSubBlockTab, TSubBlockTabNumber, TSubBlockTabNumberContent,
  TSubBlockTabContentList, TSubBlockTabContent, TCheckbox, TAddnewSubWr, TAddNewSubInput, 
  TAddNewSubControlWr, TAddNewSubRemoveItem, TAddNewSubButtonErrorText, TErrorCrossIcon
} from "./styled";

interface IComponentProps {
  blockList: IBlockMap;
  selectedSubValues: { [index: string]: ISubBlockSelect };
  subCheckedHandler: (data: any) => void;
  subConfirmHandler: (type:string) => void;
  hasPermit: boolean;
  isDone: boolean;
  isSaved: boolean;
  disabled: boolean;
  isDifferenceInNumbers: boolean;
  [index: string]: any;
}

interface IAddNewSubProps {
  addNewSub: (newSub: ISubBlockSelect) => void;
  allSubsForCheck: { [index: string]: ISubBlockSelect };
  disabled: boolean;
  isNewSubEdit: (isEdit: boolean) => void;
  hasPermit: boolean;
}

const AddNewSub: FC<IAddNewSubProps> = ({addNewSub,allSubsForCheck,disabled,isNewSubEdit, hasPermit}) => {
  const { register, unregister, setValue, getValues, setError, clearErrors, formState: { errors }, watch } = useForm({ defaultValues: { newSubNumber: '' } });
  const [isNewSubClicked, setNewSubClicked] = useState<boolean>(false);

  const [limitNewSub,limitSelected,originBlockId,originalBlockCode,existingCyphers] = useMemo(() => {
    const allSubs = Object.values(allSubsForCheck);
    let limitNewSub = false;
    let limitSelected = false;
    let originBlockId = null;
    let originalBlockCode = '';
    let existingCyphers = new Set();

    //year prefix required
    // let splitByPrefix = allSubs?.[0]?.cypher?.split('/') ?? [''];
    // let prefixValue = splitByPrefix?.[splitByPrefix.length - 1];
    // let prefix = prefixValue ? `/${prefixValue}` : '';

    if(!allSubs.length) return [limitNewSub,limitSelected,originBlockId,originalBlockCode,existingCyphers];
    let newSubs = 0;
    let selectedSubs = 0;

    for( let sub of allSubs){
      originBlockId = sub.originBlockId;
      originalBlockCode = sub.originalBlockCode;
      existingCyphers.add(sub.cypher);
      if(sub.new) ++newSubs;
      if(sub.selected) ++selectedSubs;
    }

    if(newSubs >= 3) limitNewSub = true;
    if(selectedSubs >= 3) limitSelected = true;

    return [limitNewSub, limitSelected, originBlockId,originalBlockCode,existingCyphers];
  },[allSubsForCheck])

  const onSubmit = useCallback((data: string) => {
    if(!!errors['newSubNumber']) clearErrors('newSubNumber');
    let message;
    let subNumberValue = data.trim();
    if(!subNumberValue) message = 'Поле не может быть пустым';

    //year prefix required
    // let subNumberSplitByPrefix = subNumberValue ? subNumberValue.split(prefix) : [];
    // if(!subNumberValue.includes(prefix)) message = `Убедитесь что правильно указали год блока ${prefix}`
    // if(subNumberSplitByPrefix.length && subNumberSplitByPrefix?.[subNumberSplitByPrefix.length - 1]){ 
    //   message = `Убедитесь что правильно указали год блока ${prefix}`
    // }

    if(message){ 
      setError('newSubNumber', { type: "focus", message }, { shouldFocus: true });
      return;
    }

    setNewSubClicked(false);
    setValue('newSubNumber','');

    if(originBlockId) return addNewSub({originBlockId, originalBlockCode, cypher: data, new: true, selected: !limitSelected});

  }, [clearErrors,originBlockId,originalBlockCode,limitSelected,setValue,setError,errors,addNewSub]);

useEffect(() => {
  const subscription = watch((value, { name, type }) => {
    
    if(name === 'newSubNumber' && type === 'change'){
      let subNumberValue = value['newSubNumber'] ?? '';
      let isForbiddenSymbols = subNumberValue.match(/[^\s\dA-ZА-Я\\( \\)\\.\\,\\/\\-]/gi);
      let isStartWithNumberOrLetters = subNumberValue.match(/^[0-9A-ZА-Я]/gi);
      let message
      if(isForbiddenSymbols?.length){
        message = `Ипользование символа ${isForbiddenSymbols.toString()} запрещено`
      } 
      if(subNumberValue.length > 20)    message = 'Номер не должен привышать 20 символов';
      if(!subNumberValue.trim().length) message = 'Поле не может быть пустым';
      if(subNumberValue.includes(' '))  message = 'Поле не может содержать пробелы';
      if(!isStartWithNumberOrLetters)    message = 'Номер Блока должен начинаться с цифры либо буквы';
      if(existingCyphers.has(subNumberValue))    message = 'Этот номер Блока уже существует';

      if(message) setError('newSubNumber', { type: "focus", message }, { shouldFocus: true });
      else clearErrors('newSubNumber');
    }
  });
  return () => subscription.unsubscribe();
},[watch,setError,errors,clearErrors,existingCyphers]);

  const handleCloseInput = useCallback(() => {
    setNewSubClicked(false);
    clearErrors('newSubNumber');
    unregister('newSubNumber');
  },[clearErrors,setNewSubClicked,unregister]);

  useEffect(() => {
    if(isNewSubClicked && disabled) handleCloseInput();
    isNewSubEdit(isNewSubClicked);
  },[disabled,isNewSubClicked,handleCloseInput,isNewSubEdit]);


  if (isNewSubClicked) return (
    <TAddnewSubWr>
      <TAddNewSubControlWr>
        <TCheckbox
          onChange={() => handleCloseInput()}
          checked={isNewSubClicked}
          disabled={disabled}
        />
        <TAddNewSubInput
          {...register('newSubNumber', { required: true })}
          type="text"
          placeholder="Укажите иной номер или диапазон"
          role="presentation"
          autoComplete="off"
          autoFocus
          error={!!errors?.['newSubNumber']}
        />
        <TAddNewSubRemoveItem onClick={handleCloseInput}>
          <TInvertCrossIcon />
        </TAddNewSubRemoveItem>

      </TAddNewSubControlWr>
      {!errors?.['newSubNumber'] && (
        <TAddNewSubButtonWr onClick={() => { return !limitNewSub || !limitSelected ? onSubmit(getValues('newSubNumber')) : null}}>
          <IconSquareButton disabled={limitNewSub || limitSelected} invert={limitNewSub || limitSelected } type="button">
            <TInvertCheckIcon active={(limitNewSub || limitSelected ? 'active' : '')} />
          </IconSquareButton>
          <TAddNewSubButtonText active={!limitNewSub}>Добавить</TAddNewSubButtonText>
        </TAddNewSubButtonWr>
      )}
      {!!errors?.['newSubNumber'] && (
        <TAddNewSubButtonWr>
          <IconSquareButton invert={true} type="button">
            <TErrorCrossIcon/>
          </IconSquareButton>
          <TAddNewSubButtonErrorText>{errors.newSubNumber.message}</TAddNewSubButtonErrorText>
        </TAddNewSubButtonWr>
      ) }
    </TAddnewSubWr>
  );

  return (
    <li>
      {hasPermit && <TAddNewSubButtonWr onClick={() => { return !(limitNewSub || limitSelected || disabled ) ? setNewSubClicked(true) : null}}>
        <IconSquareButton disabled={limitNewSub || limitSelected || disabled} invert={limitNewSub || limitSelected || disabled} type="button">
          <TInvertPlusIcon active={(limitNewSub || limitSelected || disabled ? 'active' : '')}/>
        </IconSquareButton>
        <TAddNewSubButtonText>Добавить блок</TAddNewSubButtonText>
      </TAddNewSubButtonWr>}
    </li>)
}



const SubBlockSelector: FC<IComponentProps> = ({ 
  blockList, selectedSubValues, subCheckedHandler, 
  subConfirmHandler, hasPermit, isDone, isSaved, disabled,isDifferenceInNumbers }) => {

  const [checkedLimitDone, setCheckedLimit] = useState<boolean>(false);
  // const [hasNewSubEdit, setNewSubEdit] = useState<boolean>(false);
  const [updateListKey, setUpdateListKey] = useState<number>(Date.now());

  //check sub checked limit and update blockMap
  useEffect(() => {
    let selectedSubLength = Object.keys(selectedSubValues).length;

    if (selectedSubLength >= 3) setCheckedLimit(true);
    if (checkedLimitDone && selectedSubLength < 3) setCheckedLimit(false);

  }, [selectedSubValues, setCheckedLimit, checkedLimitDone]);

  // const isSubsSelected: boolean = useMemo(() => {
  //   let subValues = Object.values(selectedSubValues)
  //   if(!subValues?.length) return false;
  //   return !!subValues?.some((value: ISubBlockSelect) => value.selected);
  // },[selectedSubValues]);

  const handleCheckedSubs = useCallback((checked: boolean, subData: ISubBlockSelect) => {
    let copySubData: { [key: string]: any } = { ...subData }
    copySubData['selected'] = checked;
    subCheckedHandler(copySubData);
    // setNewSubEdit(false);
    setUpdateListKey(Date.now());
  }, [subCheckedHandler,setUpdateListKey]);

  // const submitType = useMemo(() => {
  //   let type = 'blockCandidatesSaved';
  //   if(isSaved && !isDone && !isDifferenceInNumbers) type = 'blockCandidatesDone';
  //   return type;
  // },[isDone,isSaved,isDifferenceInNumbers]);

  const isTabOpen = useMemo(() => {
    let setectedBlockNumbers = Object.values(selectedSubValues)?.map((subVal: ISubBlockSelect) => subVal.originalBlockCode) ?? [];
    return setectedBlockNumbers; 
  },[selectedSubValues])


  return (
    <div>
      <TInfoTextWrapper>
        <TGlassMessage>2. Выберите блоки-кандидаты на исследование</TGlassMessage>
        <TPromptMessage>
          Выберите из списка или укажите иные номера, соотносящиеся с выбранным диапазоном
        </TPromptMessage>
      </TInfoTextWrapper>
      <TSubBlockFormWr>
        {!!blockList && Object.entries(blockList).map(([blockNumber, blockSubs],index,list) => (
          // <TSubBlockTab key={`${index}_${blockNumber}`} open={list.length === 1} >
          <TSubBlockTab key={`${index}_${blockNumber}`} open={isTabOpen.includes(blockNumber) && !disabled} >
            <TSubBlockTabNumber>
              <TArrowIcon className="arrow" />
              <TSubBlockTabNumberContent>{blockNumber}</TSubBlockTabNumberContent>
            </TSubBlockTabNumber>
            <TSubBlockTabContentList>
              {Object.entries(blockSubs).map(([subNumber, subData]) => (
                <li key={subNumber}>
                  <TCheckbox
                    onChange={({ target }) => handleCheckedSubs(target.checked, subData)}
                    checked={subData?.selected}
                    disabled={disabled || (checkedLimitDone && !subData?.selected)}
                  />
                  <TSubBlockTabContent>{subNumber}</TSubBlockTabContent>
                  </li>
              ))}
              <AddNewSub
                allSubsForCheck={blockSubs} 
                addNewSub={subCheckedHandler}
                disabled={checkedLimitDone || disabled}
                isNewSubEdit={(isEdit) => isEdit}
                key={updateListKey}
                hasPermit={hasPermit}
              />
            </TSubBlockTabContentList>
          </TSubBlockTab>
        ))}
        {/* {(isDifferenceInNumbers || !isDone || !isSaved) && hasPermit && (
        <TButtonWrapper>
          <CustomButton 
          type="submit" 
          disabled={(disabled && !isSaved) || !isSubsSelected || hasNewSubEdit} 
          onClick={() => !hasNewSubEdit ? subConfirmHandler(submitType) : null}>
            {isSaved && !isDone && !isDifferenceInNumbers ? 'Сформировать таблицу' : 'Сохранить изменения'}
          </CustomButton>
        </TButtonWrapper>
        )} */}
      </TSubBlockFormWr>
    </div>

  )
}

export default SubBlockSelector;