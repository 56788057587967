import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

const useOutsideClick = (ref: MutableRefObject<HTMLElement>, handler: (e: MouseEvent) => void) => {
  const savedHandler = useRef(handler);

  const memoizedCallback = useCallback(
    (e: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(e.target as Element)) {
        savedHandler.current(e);
      }
    },
    [ref],
  );

  useEffect(() => {
    savedHandler.current = handler;
  });

  useEffect(() => {
    document.addEventListener('mousedown', memoizedCallback);
    return () => {
      document.removeEventListener('mousedown', memoizedCallback);
    };
  }, [ref, handler, memoizedCallback]);
};

export default useOutsideClick;
